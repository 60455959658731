import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails, UserProfile } from 'src/app/core/store/loginUser/loginUser.model';

@Injectable({
  providedIn: 'root',
})
export class SetQueryParamsService {
  userRoleDetails?: UserRoleDetails;
  userProfile?: UserProfile;
  constructor(private http: HttpClient,
    private store: Store
  ) {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.userProfile = loginUser.userProfile;
      }
    })
  }

  setContactTypeIdQueryParams() {
    // default user role to load data on table grid 
    return '?IsUnderwriter=true';
    // if (UserRoleDetails.setRole) {
    //   if (UserRoleDetails.IsUW) {
    //     return '?IsUnderwriter=true';
    //   } else if (UserRoleDetails.IsSuperUser) {
    //     return '?IsUnderwriter=true';
    //   } else if (UserRoleDetails.IsReadOnly && ClubAndBranchInfo.Clubs) {
    //     if (ClubAndBranchInfo.Clubs.length > 1) {
    //       return '?IsUnderwriter=true';
    //     }
    //     return '?ClubAgencyContactId=' + UserRoleDetails.AgencyContactId;
    //     //  return "?IsReadOnly=true&ClubAgencyContactId=" + UserRoleDetails.AgencyContactId;
    //   } else if (UserRoleDetails.IsTMMReadOnly) {
    //     return (
    //       '?IsTMMReadOnly=true&ClubAgencyContactId=' +
    //       UserRoleDetails.AgencyContactId
    //     );
    //   } else if (UserRoleDetails.IsClubAdmin) {
    //     return '?ClubAgencyContactId=' + UserRoleDetails.AgencyContactId;
    //   } else if (UserRoleDetails.IsAgent) {
    //     return (
    //       '?BranchAgencyContactId=' +
    //       UserRoleDetails.AgencyContactId +
    //       '&AgentContactId=' +
    //       UserRoleDetails.AgentContactId
    //     );
    //   }
    // }
  }

  setGetaQuoteQueryParams(isW2C ? : boolean) {
    if (this.userRoleDetails && this.userProfile) {
      if (this.userRoleDetails.IsUW) {
        return (
          '?b=' +
          btoa(this.getGetaQuoteBranchId()) +
          '&a=' +
          btoa(this.userRoleDetails.UWUserId) +
          '&e=' +
          btoa('TMMASuperAdmin') +
          '&n=' +
          btoa(this.userProfile.LoggedUser) +
          '&g=' +
          btoa(this.userRoleDetails.ContactEmail)
        );
      }
      else if (this.userRoleDetails.IsSuperUser) {
        return (
          '?b=' +
          btoa(this.getGetaQuoteBranchId()) +
          '&a=' +
          btoa(this.userRoleDetails.SuperUserContactId) +
          '&e=' +
          btoa('TMMAAgent') +
          '&n=' +
          btoa(this.userProfile.LoggedUser) +
          '&g=' +
          btoa(this.userRoleDetails.ContactEmail)
        );
      }
      else if (this.userRoleDetails.IsClubAdmin) {
        return (
          '?b=' + ((isW2C) ? btoa(this.getGetaQuoteBranchId()) : btoa(sessionStorage.getItem('selectedClubKey') || '') ) +
          '&a=' +
          btoa(this.userRoleDetails.ClubAdminContactId) +
          '&e=' +
          btoa('ClubSuperUser') +
          '&n=' +
          btoa(this.userProfile.LoggedUser) +
          '&g=' +
          btoa(this.userRoleDetails.ContactEmail)
        );
      }
      else if (this.userRoleDetails.IsAgent) {
        return (
          '?b=' + ((isW2C) ? btoa(this.getGetaQuoteBranchId()) : btoa(sessionStorage.getItem('selectedClubKey') || '') ) +
          '&a=' +
          btoa(this.userRoleDetails.AgentContactId) +
          '&e=' +
          btoa('ClubAgent') +
          '&n=' +
          btoa(this.userProfile.LoggedUser) +
          '&g=' +
          btoa(this.userRoleDetails.ContactEmail)
        );
      }
    }

    return '';
  }

  setGetaQuoteGridQueryParams(policyId: any,) {
    if (this.userRoleDetails && this.userProfile) {
      if (this.userRoleDetails.IsUW) {
        return (
          '?b=' +
          btoa(this.getGetaQuoteBranchId()) +
          '&a=' +
          btoa(this.userRoleDetails.UWUserId) +
          '&n=' +
          btoa(this.userProfile.LoggedUser) +
          '&g=' +
          btoa(this.userRoleDetails.ContactEmail) +
          '&e=' +
          btoa('TMMASuperAdmin') +
          '&q=' +
          btoa(policyId.toString())
        );
      } else if (this.userRoleDetails.IsSuperUser) {
        return (
          '?b=' +
          btoa(this.getGetaQuoteBranchId()) +
          '&a=' +
          btoa(this.userRoleDetails.SuperUserContactId) +
          '&n=' +
          btoa(this.userProfile.LoggedUser) +
          '&g=' +
          btoa(this.userRoleDetails.ContactEmail) +
          '&e=' +
          btoa('TMMAAgent') +
          '&q=' +
          btoa(policyId.toString())
        );
      } else if (this.userRoleDetails.IsClubAdmin) {
        return (
          '?b=' +
          btoa(this.getGetaQuoteBranchId()) +
          '&a=' +
          btoa(this.userRoleDetails.ClubAdminContactId) +
          '&n=' +
          btoa(this.userProfile.LoggedUser) +
          '&g=' +
          btoa(this.userRoleDetails.ContactEmail) +
          '&e=' +
          btoa('ClubSuperUser') +
          '&q=' +
          btoa(policyId.toString())
        );
      } else if (this.userRoleDetails.IsAgent) {
        return (
          '?b=' +
          btoa(this.getGetaQuoteBranchId()) +
          '&a=' +
          btoa(this.userRoleDetails.AgentContactId) +
          '&n=' +
          btoa(this.userProfile.LoggedUser) +
          '&g=' +
          btoa(this.userRoleDetails.ContactEmail) +
          '&e=' +
          btoa('ClubAgent') +
          '&q=' +
          btoa(policyId.toString())
        );
      }
    }

    return '';
  }

  getAgentDetailsForAPIRequest() {
    let agentDetailsObj = {
      AgentDetails: {
        BranchContactId: 0,
        BranchAgentContactId: 0,
        Role: '',
      },
    };
    if (this.userRoleDetails) {
      if (this.userRoleDetails.IsUW) {
        agentDetailsObj.AgentDetails.BranchContactId =
          this.getGetaQuoteBranchId();
        agentDetailsObj.AgentDetails.BranchAgentContactId =
          this.userRoleDetails.UWUserId;
        agentDetailsObj.AgentDetails.Role = 'TMMASuperAdmin';
      } else if (this.userRoleDetails.IsSuperUser) {
        agentDetailsObj.AgentDetails.BranchContactId =
          this.getGetaQuoteBranchId();
        agentDetailsObj.AgentDetails.BranchAgentContactId =
          this.userRoleDetails.SuperUserContactId;
        agentDetailsObj.AgentDetails.Role = 'TMMAAgent';
      } else if (this.userRoleDetails.IsClubAdmin) {
        agentDetailsObj.AgentDetails.BranchContactId =
          this.getGetaQuoteBranchId();
        agentDetailsObj.AgentDetails.BranchAgentContactId =
          this.userRoleDetails.ClubAdminContactId;
        agentDetailsObj.AgentDetails.Role = 'ClubSuperUser';
      } else if (this.userRoleDetails.IsAgent) {
        agentDetailsObj.AgentDetails.BranchContactId =
          this.getGetaQuoteBranchId();
        agentDetailsObj.AgentDetails.BranchAgentContactId =
          this.userRoleDetails.AgentContactId;
        agentDetailsObj.AgentDetails.Role = 'ClubAgent';
      }
    }

    return agentDetailsObj;
  }

  getGetaQuoteBranchId(): any {
    if (this.userRoleDetails) {
      return this.userRoleDetails.BranchContactId;
    }
  }

  getURLParameter(sParam: any) {
    let sPageURL = window.location.search.substring(1);
    let sURLVariables = sPageURL.split('&');
    for (let i = 0; i < sURLVariables.length; i++) {
      let sParameterName = sURLVariables[i].split('=');
      if (sParameterName[0] == sParam) {
        return sParameterName[1];
      }
    }
  }
}
