/// <reference path="../../../../src/app/core/services/loader.service.ts" />
/// <reference path="policy-details-get-refunds/manage-refunds.ts" />
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManageRefundsComponent } from './policy-details-get-refunds/manage-refunds';
import { PolicySummaryComponent } from './app.component';
import { HttpCoreService } from 'src/app/core/services/http-core.service';
import { NgxBraintreeService } from './ngx-braintree.service';
import { NgxBraintreeDirective } from './ngx-braintree.directive';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe } from '@angular/common';
import { PortalCodeService } from '../../../../src/app/core/portalcode/portalcode';
import { ToastrModule } from 'ngx-toastr';
import { InterceptorsService } from 'src/app/core/services/interceptors.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LogPublishersService } from 'projects/policyDetails/src/app/shared/logging/log-publisher.service';
import { AppConfig } from 'projects/policyDetails/src/app/shared/config/app.config';
import { CustomNgbDateParserFormatter } from 'src/app/core/services/customNgbDateParserFormatter';
import { CommonPipeModule } from 'src/app/core/pipes/app.module';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { NavigationPreventionService } from 'src/app/core/services/navigation-prevention.service';
export { PolicySummaryComponent } from './app.component';

export function initConfig(config: AppConfig) {
  return () => config.load();
}

//import { PolicyDetailsGetRefundsComponent } from './policy-details-get-refunds/policy-details-get-refunds';
@NgModule({
  declarations: [PolicySummaryComponent, ManageRefundsComponent, NgxBraintreeDirective],
  imports: [
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonPipeModule,
    LoaderModule,
    BrowserAnimationsModule,
    NgbModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
  ],
  providers: [
    HttpCoreService,
    OktaCustomAuthService,
    CurrencyPipe,
    PortalCodeService,
    LogPublishersService,
    NavigationPreventionService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    { provide: NgxBraintreeService, useClass: NgxBraintreeService },
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new CustomNgbDateParserFormatter('dd/MM/yyyy'),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true,
    },
  ],
})
export class PolicySummaryModule {}
