import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AgentsState, BranchesState, RolesState } from "./user.reducer";

export const selectBranchesState = createFeatureSelector<BranchesState>('branches');
export const selectAgentsState = createFeatureSelector<AgentsState>('agents');
export const selectRolesState = createFeatureSelector<RolesState>('roles');

export const selectBranches = createSelector(
  selectBranchesState,
  (state: BranchesState) => state.branches
);

export const selectRoles = createSelector(
  selectRolesState,
  (state: RolesState) => state.roles
);

export const selectAgents = createSelector(
  selectAgentsState,
  (state: AgentsState) => state.agents
);

export const isBranchesPresent = createSelector(
  selectBranches,
  (branches) => !!branches
);

export const isAgentsPresent = createSelector(
  selectAgents,
  (agents) => !!agents
);

export const isRolesPresent = createSelector(
  selectRoles,
  (roles) => !!roles
);