<div class="mt-4">
  <div id="accordion">
    <div class="card">
      <div class="card-header" id="headingOne">
        <h5 class="mb-0">
          <button class="btn btn-link accordion-note" data-toggle="collapse" data-target="#collapseOne"
            aria-expanded="false" (click)="isPolicyMovementCollapsed = !isPolicyMovementCollapsed"
            [attr.aria-expanded]="!isPolicyMovementCollapsed" aria-expanded="false" aria-controls="collapseOne">
            <i [ngClass]="{
                rotate0: !isPolicyMovementCollapsed,
                rotate90: isPolicyMovementCollapsed
              }" class="fas fa-chevron-circle-right mr-2 rotate90"></i>
            <span>Policy Movement</span>
          </button>
        </h5>
      </div>
      <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion"
        *ngIf="isPolicyMovementCollapsed">
        <div class="card-body">
          <table id="datatable1" class="table table-striped table-bordered table-dashboard" style="width: 100%">
            <thead>
              <tr>
                <th>Date</th>
                <th>Type</th>
                <th>Change Mode</th>
                <th>Premium</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of policymovement">
                <td>
                  {{ (item.date) | utcToShortDatePipe }}
                </td>
                <td>
                  {{ item.type === 'NB' ? 'NE' : item.type != 'CN' ? item.type : 'CA' }}
                </td>
                <td>{{ item.status }}</td>
                <td class="text-right">
                  {{ getPriceFixed(item.amount, 2) | currency }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>