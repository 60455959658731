<div  *ngIf="submitted && control?.invalid && (control.dirty || control.touched)" class="invalid-feedback d-block">
  <ng-container *ngIf="control.errors?.['required'] && !rawMessage">Please enter the {{ label }}.</ng-container>
  <ng-container *ngIf="control.errors?.['required'] && rawMessage">{{ rawMessage }}</ng-container>
  <ng-container *ngIf="control.errors?.['maxlength']">Maximum length exceeded.</ng-container>
  <ng-container *ngIf="control.errors?.['invalidNumber']">Please enter a valid {{ totalDigits }} digit {{ label }}</ng-container>
  <ng-container *ngIf="control.errors?.['email']">Please enter a valid {{ label }}</ng-container>
  <ng-container *ngIf="control.errors?.['pattern']">Numbers and special characters are not allowed</ng-container>
  <!-- Add more error messages as needed -->
</div>

