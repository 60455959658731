export class ExpPromoHeaders {
  public static Id = 'Id';
  public static PromoCode = 'Promo Code';
  public static Description = 'Description';
  public static EffectiveDate = 'Effective Date';
  public static ExpirationDate = 'Expiration Date';
  public static DiscountRate = 'DiscountRate';
  public static ClubName = 'Club Name';
  public static Status = 'Status';
}

export class DeletedPromoHeaders {
  public static Id = 'Id';
  public static PromoCode = 'Promo Code';
  public static Description = 'Description';
  public static ActivityType = 'Type';
  public static ActivityDate = 'Date';
  public static ActivityBy = 'User';
}
