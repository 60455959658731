<section class="menu-bar">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 navbar-ex">
        <nav class="navbar navbar-expand-lg navbar-light justify-content-end">
          <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navb">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navb">
            <ul class="navbar-nav mr-auto">
              <ng-container *ngFor="let itemMenu of headerNavigation">
                <ng-container *ngIf=" itemMenu.primaryMenuItems.navigationText != 'Manage User' && itemMenu.primaryMenuItems.navigationText != 'Get a Quote' ">
                  <li class="nav-item" *ngIf=" itemMenu.primaryMenuItems.navigationText != 'Reports' ">
                    <a class="nav-link" href="{{ itemMenu.primaryMenuItems.navigationLink }}">{{
                      itemMenu.primaryMenuItems.navigationText }}</a>
                  </li>
                  <li class="nav-item" *ngIf=" itemMenu.primaryMenuItems.navigationText == 'Reports' ">
                    <a class="nav-link" href="{{ itemMenu.primaryMenuItems.navigationLink }}" target="_blank">{{
                      itemMenu.primaryMenuItems.navigationText }}</a>
                  </li>
                </ng-container>
              </ng-container>
            </ul>
            <ul class="user-options">
              <ng-container *ngIf="!isReadOnly">
                <li>
                  <div class="dropdown dropdown-custom-menu" (click)="$event.stopPropagation()">
                    <ng-container *ngFor="let itemClub of listClubs; let in = index">
                      <ng-container *ngIf="itemClub.key == selectedClubKey">
                        <a class="btn border border-gray btn-sm dropdown-toggle" href="javascript:void(0);"
                          role="button" id="dropdownMenuLink" (click)="toggleDropdown()" aria-haspopup="true"
                          aria-expanded="false">
                          <span class="dropdown-value">{{ itemClub?.link_Url.title }}</span>
                        </a>
                      </ng-container>
                    </ng-container>
                    <div class="dropdown-menu dropdown-menu-right" [ngClass]="isShow ? 'show' : 'hide'"
                      aria-labelledby="dropdownMenuLink">
                      <ng-container *ngFor="let itemClub of listClubs; let in = index">
                        <a class="dropdown-item border-bottom" href="javascript:void(0);"
                          (click)="changeNavDropdown(itemClub.key, in)">{{ itemClub?.link_Url.title }}</a>
                      </ng-container>
                    </div>
                  </div>
                </li>
              </ng-container>
              <ng-container *ngFor="let itemMenu of headerNavigation">
                <ng-container *ngIf=" itemMenu.primaryMenuItems.navigationText == 'Manage User' || itemMenu.primaryMenuItems.navigationText == 'Get a Quote' ">
                  <ng-container *ngIf=" itemMenu.primaryMenuItems.navigationText == 'Manage User' "> <li>
                      <a href="{{ itemMenu.primaryMenuItems.navigationLink }}" class="btn btn-sm btn-primary">Manage
                        Users</a>
                    </li>
                  </ng-container>
                  <ng-container *ngIf=" itemMenu.primaryMenuItems.navigationText == 'Get a Quote' && headerGetaQuoteVisable ">
                    <ng-container *ngIf="headerGetaQuote == 'javascript:void(0);'">
                      <li>
                        <a href="javascript:void(0);" class="btn btn-sm btn-primary {{
                            headerGetaQuoteClass
                          }}">{{ itemMenu.primaryMenuItems.navigationText }}</a>
                      </li>
                    </ng-container>
                    <ng-container *ngIf="headerGetaQuote !== 'javascript:void(0);'">
                      <li>
                        <a href="{{ headerGetaQuote }}" class="btn btn-sm btn-primary">{{
                          itemMenu.primaryMenuItems.navigationText }}</a>
                      </li>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</section>