import { Component, OnInit, ViewChild, TemplateRef, ElementRef, ViewContainerRef, Renderer2, Input, EventEmitter, Output, } from '@angular/core';
import { Utility } from '../shared/utility';
import { DataBusService } from '../services/databus.service';
import { SiteNameEnum } from '../shared/enum/sitename-enum';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { MessageBusService } from '../services/messagebus.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PolicyDetailService } from '../app.services';
import { EndorsementGenericService } from '../shared/services/endorsement-generic.service';
import { VeriskComponent } from './verisk/verisk.component';
import { DialogService } from '../dialog/dialog.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { SitecoreItemService } from '../services/sitecore-item.service';
import { DomSanitizer } from '@angular/platform-browser';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { EventEmitterService } from '../shared/services/event-emitter.service';
import { TravelerUpdateService } from '../shared/services/traveller-update.service';
import * as CryptoJS from 'crypto-js';
import { Store } from '@ngrx/store';
import { selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { isEndorsementDataPresent, selectCurrentEndorsement } from 'src/app/core/store/endorsement/endorsement.selectors';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { EndPoint } from 'src/app/core/services/endpoint';
import { NgDialogAnimationService } from './dialog.service';
import { AssessmentDetail, AssessmentDetails, MedicalDeclaration, MedicalPremium } from './verisk/verisk.model';
import { MedicalService } from './verisk/medical.service';
import { LocalCacheService } from './verisk/localCache.service';
import { MapperService } from 'src/app/core/services/mapper.services';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';
import { GlobalService } from './verisk/global.service';

@Component({
  selector: 'policyDetails-policy-details-pre-existing',
  templateUrl: './policy-details-pre-existing.component.html',
  styleUrls: ['./policy-details-pre-existing.component.css'],
})
export class PolicyDetailsPreExistingComponent implements OnInit {
  private policyDetails: any = {};
  public isSavingRecord = false;
  public isSavingForDependent = false;
  public buttonClicked: any = "";
  public userSelectionIndex = 1;
  public isRentalBase: boolean = false;
  prpolicytype!: boolean;
  public medicalLabeling : any = {};
  public whiteLebeling: any = {
    //Pre-ExistingCondition
    PreExistingHeading: 'Update Medical Cover',
    QuestionsText: 'Which of these travellers has existing medical condition?',
    TravellersText: 'Adult Travellers',
    AdultTravellersYes: 'Yes',
    AdultTravellersNo: 'No',
    AdultTravellersAssess: 'Assess',
    ReassesButtonText: 'Update Medical Assessment',
    ContinuebtnText: 'Continue',
    PreviousbtnText: 'Previous',
    DependentsHeading: 'Dependents',
    DependentsDesc:
      'Children & Grand Children 25 or under go free as long as they travel with you the whole time, are financially dependent on their parents, not working full time, and do not require a medical assessment.',
    AgeText: 'Age',
    PreexistingText: 'medical conditions',
    PreexistingYes: 'Yes',
    PreexistingNo: 'No',
    PreexistingDescription: `Do you or any listed traveller have other Medical Conditions not found in the automatically covered conditions that you need to declare?`,
    NotCoveredResultTxt1:
      'We are sorry, but we are no longer able to provide cover for the insureds previously declared conditions of ',
    NotCoveredResultTxt2:
      ' The change made to the policy has resulted in a reassessment of the medical conditions, and as such is outside of the underwriter risk assessment threshold.',
    ClubMemberLength: 10,
    RACQMemberLength: 16,
    OnlyNumberPattern: /^[0-9]+$/i,
    CarRentalNotificationText: `This option is not available for Car rental excess.`,
    confirmAlertQ1Part1:
      'We are sorry but we cannot offer any travel insurance cover on this policy as you have declared a traveller that is either:',
    confirmAlertQ1Part2: ' travelling against medical advice; or',
    confirmAlertQ1Part3:
      ' travelling for medical treatment. We will have to cancel the policy.',
    confirmAlertQ2:
      'By answering yes to this question you understand that no cover is available for any condition or related condition where you are awaiting surgery, treatment, investigation or procedures.',
    ZeroPremiumAutoAccept:
      'There is no additional charge for the medical condition.',
    ContinueAssessmentCompleteTxt: "Please select either Yes or No to continue. By selecting a ‘Yes’ , you are required to answer a pre-medical assessment questionnaire.",
  };
  public showResult = {
    showAssessmantresult1: false,
    showAssessmantresult2: false,
    showAssessmantresult3: false,
    showAssessmantresult4: false,
    showAssessmantresult5: false,
  };
  public isVeriskShow: boolean = false;
  public veriskTraveler = "";
  public noEligibleTraveller: boolean = false;
  public adultTravelerStatusChecked: boolean = false;
  public additionalTravelerStatusChecked: boolean = false;
  public medicalAssessmentStatus = '';
  public adultTravelerQuestionStatus: any = {
    question1: 'No',
    question2: 'No',
    question3: 'No',
    question4: 'No',
    question5: 'No',
  };
  public AdditionalTravelerQuestionStatus: any = {
    question1: 'No',
    question2: 'No',
    question3: 'No',
    question4: 'No',
    question5: 'No',
  };
  isLoader: false = false;
  cancelMessageFail: false = false;
  public currentTraveler: any;
  public travelerName: string | undefined;
  public isMedicalScoreVisible = 'true';
  public travelerMedCondDetails: object[] = [];
  public users: any = [];
  public depedentAssessmantStatus = [];
  public cancelMessageEror: any;
  public veriskLoaded = 'No';
  public isLoadeing = 'Yes';
  public siteName: string = '';
  public siteNameEnum: any;
  public modalRef: NgbModalRef | any;
  public viewModalRef: NgbModalRef | any;
  public veriskModalRef: NgbModalRef | any;
  public modelAlertRef: NgbModalRef | any;
  public warningRef: NgbModalRef | any;
  public display = 'none';
  public isEditable = false;
  public script: any;
  public veriskResult: any;
  public questionerValue = {
    Question1Answer: '',
    Question2Answer: '',
    Question3Answer: '',
    Question4Answer: '',
  };
  // public isReassesButtonEnabled = false;
  public reassesFlag: string;
  public viewtravelerName = '';
  public currentPolicyInStore : any;
  public isUpdateENCalled: boolean  = false;
  private endorsementCalled = false;
  public declineToolTipText: string = "";
  userRoleDetails?: UserRoleDetails;
  @Input() isEditBtnRequired: boolean = true;
  @Output() onPrexExistingUpdate: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @ViewChild('dialogContentTemplate', { static: true })
  dialogContent!: TemplateRef<ElementRef>;
  @ViewChild('viewContentTemplate', { static: true })
  viewContent!: TemplateRef<ElementRef>;
  @ViewChild('veriskDialog', { static: true })
  veriskDialog!: TemplateRef<ElementRef>;
  @ViewChild('cancelAlertTemplate', { static: true })
  dialogalertBox!: TemplateRef<ElementRef>;
  @ViewChild('warningAlertTemplate', { static: true }) warningBox!: ElementRef;
  constructor(
    private itemService: SitecoreItemService,
    public dialog: DialogService,
    private renderer: Renderer2,
    public viewContainerRef: ViewContainerRef,
    private dataBusService: DataBusService,
    private policyService: PolicyDetailService,
    private ngxXml2jsonService: NgxXml2jsonService,
    private modalService: NgbModal,
    private portalCodeService: PortalCodeService,
    private messageService: MessageBusService,
    private endorsementGenericService: EndorsementGenericService,
    protected html_sanitizer: DomSanitizer,
    private loaderService: LoaderService,
    private logService: LogService,
    private eventEmitter: EventEmitterService,
    private travelerUpdateService: TravelerUpdateService,
    private store: Store,
    private dialogReference: NgDialogAnimationService,
    private el: ElementRef,
    private medicalService: MedicalService,
    private localCacheService: LocalCacheService,
    private mapperService: MapperService,
    private storeService : StoreGenericService,
    private globalService: GlobalService
  ) {
    this.siteNameEnum = SiteNameEnum;
    this.reassesFlag = '';
  }

  ngOnInit() {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
      }
    });
    //Policy Store
    this.store.select(selectCurrentPolicy).subscribe(async pol =>{
      this.currentPolicyInStore = pol;
      if(pol != null)
      {
        this.populatePolicyData(this.currentPolicyInStore);
        this.getMedicalLabeling();
        if(this.isEditBtnRequired){
          await this.policyService.refreshMedicalToken(sessionStorage.getItem('portalcode') || '');
        }
        
      }
    });

    //Endorsement Store
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.isUpdateENCalled = present;
    });

    this.store.select(selectCurrentEndorsement).subscribe(en => {
      if(en != null) {
        this.policyDetails = en;
        this.populatePolicyData(en);

        if (this.endorsementCalled) {
          this.endorsementCalled = false;
          this.getMedicalLabeling();
          this.userSelectionIndex = 1;
        }
      }
    });
  }

  getMedicalLabeling() {
    this.policyService.fetchStaticList(EndPoint.PAS_STATIC_MEDICALLABELING).subscribe((resp) => {
      if(resp){
        this.medicalLabeling = resp[this.siteName.toUpperCase()];
        this.declineToolTipText = this.medicalLabeling?.DeclineToolTipText;
      }
    });
  }

  checkCommonMedicalDetails(traveler: any) {
    if (this.medicalLabeling) {
      this.LoadMedicalAssessmentLebeling(this.medicalLabeling);
      this.viewModal(traveler.FirstName, traveler.LastName, traveler.MedicalDeclarations);
    }
  }

  LoadMedicalAssessmentLebeling(data: any) {
    if (!data) {
    } else {
      this.whiteLebeling.AssessmentPreviousbtnText = data.AssessmentPreviousbtnText;
      this.whiteLebeling.AssessmentText = data.AssessmentText;
      this.whiteLebeling.AssessmentQuestionsText = data.AssessmentQuestionsText;
      this.whiteLebeling.Question1Text = data.Question1Text;
      this.whiteLebeling.Question1YesText = data.Question1YesText;
      this.whiteLebeling.Question1NoText = data.Question1NoText;
      this.whiteLebeling.Question1ToolTipText = data.Question1ToolTipText;
      this.whiteLebeling.Question2ToolTipText = data.Question2ToolTipText;
      this.whiteLebeling.Question2Text = data.Question2Text;
      this.whiteLebeling.Question2YesText = data.Question2YesText;
      this.whiteLebeling.Question2NoText = data.Question2NoText;
      this.whiteLebeling.Question3Text = data.Question3Text;
      this.whiteLebeling.Question3YesText = data.Question3YesText;
      this.whiteLebeling.Question3NoText = data.Question3NoText;
      this.whiteLebeling.Question4Text = data.Question4Text;
      this.whiteLebeling.Question4YesText = data.Question4YesText;
      this.whiteLebeling.Question4NoText = data.Question4NoText;
      this.whiteLebeling.Question5Text = data.Question5Text;
      this.whiteLebeling.Question5YesText = data.Question5YesText;
      this.whiteLebeling.Question5NoText = data.Question5NoText;
      this.whiteLebeling.NextTravellerBtnText = data.NextTravellerBtnText;
      this.whiteLebeling.PaymentBtnText = data.PaymentBtnText;
      this.whiteLebeling.ReviewValidationText = data.ReviewValidationText;
      this.whiteLebeling.ReviewTextW2C = data.ReviewTextW2C;
      this.whiteLebeling.ConfirmDeclarationTextW2C = data.ConfirmDeclarationTextW2C;
      this.whiteLebeling.ReturnToMedicalScreeningText = data.ReturnToMedicalScreeningText;
      this.whiteLebeling.ShowAssessmantresult2Text = data.ShowAssessmantresult2Text;
      this.whiteLebeling.ShowAssessmantresult3Text = data.ShowAssessmantresult3Text;
      this.whiteLebeling.ShowAssessmantResult1Text = data.ShowAssessmantResult1Text;
      this.whiteLebeling.ResultFor = data.ResultFor;
      this.whiteLebeling.ConfirmAlertText = data.ConfirmAlertText;
      this.whiteLebeling.Question4ToolTipText = data.IndicateAdditionalPremium;
      this.whiteLebeling.DeclaredMedicalCondition = data.DeclaredMedicalCondition;
      this.whiteLebeling.IndicateAdditionalPremium = data.IndicateAdditionalPremium;
      this.whiteLebeling.AdditionalPremium = data.AdditionalPremium;
      this.whiteLebeling.MedicalScore = data.MedicalScore;
      this.whiteLebeling.VeriskResult = data.VeriskResult;
      this.whiteLebeling.AfterTaxAndDiscount = data.AfterTaxAndDiscount;
      this.whiteLebeling.Accept = data.Accept;
      this.whiteLebeling.Decline = data.Decline;
      this.whiteLebeling.updateMedicalAssessment = data.updateMedicalAssessment;
      this.whiteLebeling.EmailInfoText = data.EmailInfoText;
      this.whiteLebeling.VeriskWidget = data.VeriskWidget;
      this.whiteLebeling.NotCoveredTxt = data.NotCoveredTxt;
      this.whiteLebeling.NotCoveredResultTxt = data.NotCoveredResultTxt;
      this.whiteLebeling.NotEligibleTxt = data.NotEligibleTxt;
      this.whiteLebeling.TravellerExcludedTxt = data.TravellerExcludedTxt;
      this.whiteLebeling.TravelerQuestion1Txt = data.TravelerQuestion1Txt;
      this.whiteLebeling.TravelerQuestion2Txt = data.TravelerQuestion2Txt;
      this.whiteLebeling.TravelerQuestion3Txt = data.TravelerQuestion3Txt;
      this.whiteLebeling.ReturnToHome = data.ReturnToHome;
      this.whiteLebeling.ContinueAssessmentCompleteTxt = data.ContinueAssessmentCompleteTxt;
      this.whiteLebeling.TravellerAnswerYesTo = data.TravellerAnswerYesTo;
      this.whiteLebeling.OptionsValidationText = data.OptionsValidationText;
    }
  }

  sameAsHtml(html_content: any) {
    return this.html_sanitizer.bypassSecurityTrustHtml(html_content);
  }

  isReassesButtonEnabled(index: number, isDependent: boolean = false) {
    return true;
  }

  ngOnDestroy() {
    // this.renderer.removeChild(window.document.body, this.script);
  }

  findMaxRegionId(countryCode: any, countryData: any) {
    let regionId = '0';
    this.state.selectedCountries = [];
    countryCode.forEach((code: any) => {
      if (countryData.Countries) {
        let selectedCountry: any = countryData.Countries.find(
          (c: any) => c.CountryCode == code,
        );
        if (selectedCountry) {
          this.state.selectedCountries.push({
            CountryCode: selectedCountry.CountryCode,
            CountryName: selectedCountry.CountryName,
            RatingRegionName: selectedCountry.RatingRegionName,
            HelixScore: selectedCountry.HelixScore,
            RegionId: selectedCountry.RegionId,
          });
        }
      }
    });

    regionId = this.getMaxHelixScore();
    return regionId;
  }
  getMaxRegionId() {
    let regionId = 0;
    let regionIdArr = [];
    if (this.state.selectedCountries.length > 0) {
      for (let val of this.state.selectedCountries) {
        regionIdArr.push(val.HelixScore);
      }
    }
    regionIdArr.sort(function (a, b) {
      return a - b;
    });
    regionId = regionIdArr[regionIdArr.length - 1];
    sessionStorage.setItem('RegionId', regionId.toString());
    return regionId;
  }
  getMaxHelixScore() {
    let helixScore = '0';
    let helixScoreArr = [];
    if (this.state.selectedCountries.length > 0) {
      for (let val of this.state.selectedCountries) {
        helixScoreArr.push(val.HelixScore);
      }
    }
    helixScoreArr.sort(function (a, b) {
      return a - b;
    });
    helixScore = helixScoreArr[helixScoreArr.length - 1];
    sessionStorage.setItem('HelixScore', this.state.helixScore.toString());
    return helixScore;
  }
  openAlertModel() {
    this.modelAlertRef = this.modalService.open(this.dialogalertBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }
  closeAlertChildModal() {
    if (this.modelAlertRef) {
      this.modelAlertRef.close();
    }
  }
  openWarningModel() {
    this.warningRef = this.modalService.open(this.warningBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }
  closeWarningChildModal() {
    if (this.warningRef) {
      this.warningRef.close();
    }
  }

  openModal(openDelayed?: boolean) {
    if (this.isRentalBase) {
      this.openWarningModel();
    } else {
      this.userSelectionIndex = 1;
      if (openDelayed) {
        setTimeout(() => {
          this.modalRef = this.modalService.open(this.dialogContent, {
            size: 'xl',
            backdrop: 'static',
            keyboard: false,
          });
        }, 1000);
      } else {
        this.modalRef = this.modalService.open(this.dialogContent, {
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
        });
      }
    }
  }
  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  isReassessmentNeededIfDestinationChanged() {
    let isResumeRquired = false;
    if (this.state.Traveler1) {
      if (
        (this.state.Traveler1.Rescore ||
          this.state.Traveler1.RecalculateMedicalPremium) &&
        this.getMedicalStatusByContactId(this.state.Traveler1.ContactId) !=
          'Not Covered'
      )
        isResumeRquired = true;
    }
    if (!isResumeRquired && this.state.Traveler2) {
      if (
        (this.state.Traveler2.Rescore ||
          this.state.Traveler2.RecalculateMedicalPremium) &&
        this.getMedicalStatusByContactId(this.state.Traveler2.ContactId) !=
          'Not Covered'
      )
        isResumeRquired = true;
    }
    if (
      !isResumeRquired &&
      this.state.Dependents &&
      this.state.Dependents.length > 0
    ) {
      let assesNeededDependents: Array<any> = this.state.Dependents.filter(
        (v: any) => v.Rescore == true,
      );
      if (assesNeededDependents && assesNeededDependents.length > 0) {
        assesNeededDependents.forEach((dep) => {
          if (
            (dep.Rescore || dep.RecalculateMedicalPremium) &&
            this.getMedicalStatusByContactId(dep.ContactId) != 'Not Covered'
          )
            isResumeRquired = true;
        });
      }
    }
    return isResumeRquired;
  }

  getMedicalStatusByContactId(contactId: string) {
    if (this.state.assesmentsDetails) {
      let assesse = this.state.assesmentsDetails.find(
        (assessee: any) => assessee.ContactId === contactId,
      );
      if (assesse) return assesse.Status;
    }
    return '';
  }
  addDependent(FirstName: any, LastName: any, age: any, ContactId: any) {
    this.users.push({
      FName: FirstName,
      LName: LastName,
      Age: age,
      dependentStatusChecked: true,
      ContactId: ContactId,
      errorFlag: false,
      showInvalidDependentDateError: false,
    });
  }

  getMedicalPremiumForTraveller(endorsementData: any) {
    return (
      Utility.typeSafeHandle(endorsementData, 'Num2', 'int') +
      Utility.typeSafeHandle(endorsementData, 'Num3', 'int') +
      Utility.typeSafeHandle(endorsementData, 'Num4', 'int') +
      Utility.typeSafeHandle(endorsementData, 'Num5', 'int') +
      Utility.typeSafeHandle(endorsementData, 'Num6', 'int')
    );
  }
  returnToHome() {
    if (this.modalRef) {
      this.modalRef.close();
    }
    if (this.veriskModalRef) {
      this.veriskModalRef.close();
    }
    this.userSelectionIndex = 1;
  }

  continueToAssess() {
    this.finishMedicalValidation();
  }

  finishMedicalValidation() {
    let validated = this.validatePreExistingMedical();
    const guid = sessionStorage.getItem('movementGuid');
    if (!guid) {
      return;
    }

    if (validated) {
      this.state.assesseeValidation = false;
      if (this.modalRef) {
        this.modalRef.close();
      }
      this.userSelectionIndex = 1;
      this.dataBusService.publishPolicyDetails(true, guid);
    } else {
      this.state.assesseeValidation = true;
    }
  }

  validatePreExistingMedical() {
    let isValidate = false;
    let assessee = this.state.assesmentsDetails.find(
      (assessee: any) =>
        assessee.selectedUserIndex == 1 && assessee.IsDependent == false,
    );
    if (assessee) {
      isValidate =
        this.isTravelerValidated(this.adultTravelerStatusChecked, 0) &&
        (this.additionalTravelerStatusChecked
          ? this.isTravelerValidated(this.additionalTravelerStatusChecked, 1)
          : true);
    } else {
      isValidate = this.isTravelerValidated(this.adultTravelerStatusChecked, 0);
    }

    if (isValidate) {
      let index = 0;
      for (let user of this.users) {
        isValidate = this.isTravelerValidated(
          user.dependentStatusChecked,
          index,
          true,
        );
        index++;
        if (!isValidate) {
          return isValidate;
        }
      }
    }
    return isValidate;
  }

  isTravelerValidated(status: any, index: any, isDependent: any = false) {
    if (status) {
      let assessee = this.state.assesmentsDetails.find(
        (assessee: any) =>
          assessee.selectedUserIndex == index &&
          assessee.IsDependent == isDependent,
      );
      if (assessee && assessee.Status != 'Pending') {
        return true;
      }
      return false;
    }
    return true;
  }

  acceptMedicalAssesment(status: string) {
    const guid = sessionStorage.getItem('movementGuid');
    if (!guid) {
      return;
    }
    this.buttonClicked = status;
    this.isSavingRecord = true;
    this.medicalService
    .acceptMedical({
      movementGuid: guid,
      travelerGuid: this.veriskTraveler,
      xmlResult: this.veriskResult,
      isUpdated: true,
      status: status,
    })
    .subscribe((response) => {
      if (response) {
        this.callEndorsement();
      }
    }, error => {
      this.resetLoaderState();
    });
  }

  async callEndorsement(closePage: boolean = false) {
    const model = await this.mapperService.mapEndorsementModel(this.policyDetails, "revision", "MedicalUpdated");
    this.policyService.updateEndorsement(model).subscribe(resp => {
      this.endorsementCalled = true;
      this.storeService.setENToStore(resp);
      sessionStorage.setItem('movementGuid', resp.movementGuid.toString());
      this.loaderService.hide();
      if (closePage) {
        if (this.modalRef) {
          this.modalRef.close();
        }
        this.userSelectionIndex = 1;
      }
      this.resetLoaderState();
    })
    
    if (this.state.assesseeValidation) {
      setTimeout(() => {
        this.state.assesseeValidation = false;
      }, 100);
    }
  }

  DecreptData(key: any, ciphertextB64: any) {
    var keys = CryptoJS.enc.Utf8.parse(key); // Convert into WordArray (using Utf8)
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]); // Use zero vector as IV
    var decrypted = CryptoJS.AES.decrypt(ciphertextB64, keys, { iv: iv }); // By default: CBC, PKCS7
    return decrypted.toString(CryptoJS.enc.Utf8); // Convert into string (using Utf8)                    // Convert into string (using Utf8)
  }

  editAdditionalPremium(index: any, isDependent: any = false) {
    this.buttonClicked = String(index);
    this.isSavingRecord = true;
    this.isSavingForDependent = isDependent;
    const policyGuid = sessionStorage.getItem("policyGuid");
    const movementGuid = sessionStorage.getItem("movementGuid")

    if (!policyGuid || !movementGuid) {
      this.resetLoaderState();
      return;
    }

    const traveler = this.filterAssessee(index, isDependent);
    const targetTraveler = this.policyDetails.travelers.find((resp: any) => resp.travelerId == traveler.ContactId);
    if (targetTraveler) {
      sessionStorage.setItem("currentAssessment", JSON.stringify(targetTraveler.role));
    }
    
    this.createAssessmentDetails(this.policyDetails.policyGuid, this.policyDetails);
    const detail = this.globalService.getCurrentAssessmentDetails();
    if (!detail) {
      this.resetLoaderState();
      return;
    }

    const medicalPremium: MedicalPremium = {
      movementGuid: movementGuid,
      travelerGuid: traveler.ContactId,
      xmlResult: traveler.FormText,
      isUpdated: true,
    };
    
    this.medicalService
      .addMedicalPremium(medicalPremium)
      .subscribe((response) => {
        response.xml = medicalPremium.xmlResult;
        this.localCacheService.saveSessionStorage(
          'medicalPremium',
          response,
        );
        detail.xmlResult = traveler.FormText;
        detail.medicalPremium = response;
        const assessmentDetails =
          this.localCacheService.getSessionStorage('assessmentDetails');
        const currentAssessment =
          this.localCacheService.getSessionStorage('currentAssessment');
        if (
          currentAssessment === 'primary' ||
          currentAssessment === 'secondary'
        ) {
          assessmentDetails[currentAssessment] = detail;
        } else if (currentAssessment === 'dependent') {
          assessmentDetails.dependent.map((dependent: any) => {
            if (dependent.travelerId === traveler.ContactId) {
              dependent.xmlResult = detail.xmlResult;
              dependent.medicalPremium = detail.medicalPremium;
            }
          });
        }
        this.localCacheService.saveSessionStorage(
          'assessmentDetails',
          assessmentDetails,
        );
        this.veriskTraveler = traveler.ContactId;
        this.LoadMedicalAssessmentLebeling(this.medicalLabeling);
        this.handleAfterClose(true);
      }, error => {
        console.log(error);
        this.resetLoaderState();
      });
  }

  updateAssesmentsDetailsObj(
    action: any,
    selectedUserIndex: any,
    isDependent: any,
  ) {
    if (action == 'Add') {
    } else if (action == 'Remove') {
      let index = this.state.assesmentsDetails.findIndex(
        (assessee: any) =>
          assessee.userSelectionIndex == selectedUserIndex &&
          assessee.IsDependent == isDependent,
      );
      this.state.assesmentsDetails.splice(index, 1);
    } else if (action == 'Update') {
      this.syncUserName(selectedUserIndex, isDependent);
    }

    sessionStorage.setItem(
      'assesmentsDetails',
      JSON.stringify(this.state.assesmentsDetails),
    );
  }
  goToMedicalCondition() {
    this.isVeriskShow = false;
    sessionStorage.setItem('veriskLoaded', 'No');
    this.veriskLoaded = 'No';
    if (
      (this.state.Traveler1.FirstName &&
        this.getAssesseeStatus(0) === 'Not Covered' &&
        !this.state.Traveler2.FirstName &&
        this.users.length == 0) ||
      (this.getAssesseeStatus(0) === 'Not Covered' &&
        this.getAssesseeStatus(1) == 'Not Covered')
    ) {
      this.noEligibleTraveller = true;
      this.userSelectionIndex = 7;
      //this.scrollToTop();
    } else if (
      (this.state.Traveler1.FirstName &&
        this.getAssesseeStatus(0) === 'Not Covered' &&
        !this.state.Traveler2.FirstName &&
        this.users.length > 0 &&
        this.currentTraveler === 'AdultTraveler') ||
      (this.getAssesseeStatus(0) === 'Not Covered' &&
        this.getAssesseeStatus(1) == 'Not Covered')
    ) {
      this.noEligibleTraveller = true;
      this.userSelectionIndex = 7;
      //this.scrollToTop();
    } else {
      this.userSelectionIndex = 1;
    }
  }
  persistQuestionairValue() {
    this.questionerValue = {
      Question1Answer: '',
      Question2Answer: '',
      Question3Answer: '',
      Question4Answer: '',
    };
    //if (this.currentTraveler === 'AdultTraveler') {
    this.adultTravelerQuestionStatus.question1 = this.state.question1Selected
      ? 'Yes'
      : 'No';
    this.adultTravelerQuestionStatus.question2 = this.state.question2Selected
      ? 'Yes'
      : 'No';
    this.adultTravelerQuestionStatus.question3 = this.state.question3Selected
      ? 'Yes'
      : 'No';
    this.adultTravelerQuestionStatus.question4 = this.state.question4Selected
      ? 'Yes'
      : 'No';
    this.questionerValue.Question1Answer =
      this.adultTravelerQuestionStatus.question1;
    this.questionerValue.Question2Answer =
      this.adultTravelerQuestionStatus.question2;
    this.questionerValue.Question3Answer =
      this.adultTravelerQuestionStatus.question3;
    this.questionerValue.Question4Answer =
      this.adultTravelerQuestionStatus.question4;
  }
  continueToNextTraveler() {
    
    if(this.isVeriskShow) {
      this.isSavingRecord = true;
      const declaration: MedicalDeclaration = {
        movementGuid: sessionStorage.getItem('movementGuid')!,
        travelerGuid: this.veriskTraveler,
        declarations: [
          this.state.question1Selected ? 'yes' : 'no',
          this.state.question2Selected ? 'yes' : 'no',
          this.state.question3Selected ? 'yes' : 'no',
          this.state.question4Selected ? 'yes' : 'no',
        ]
      };
      this.isVeriskShow = false;
      this.medicalService.medicalDeclaration(declaration).subscribe(response => {
        this.openVeriskDialog();
      }, (error) => {
        this.openVeriskDialog();
        this.isSavingRecord = false;
      },)
      return;
    }
    this.persistQuestionairValue();
    //this.updatePhoneNumberAcorToAutoclub(this.siteName)
    if (this.siteName === this.siteNameEnum.W2C) {
      if (
        this.state.question1Selected ||
        this.state.question2Selected ||
        (this.state.question1Selected && this.state.question2Selected)
      ) {
        // Scenario 5:
        //needs to be set tooltip value for q1 and q2 in sitecore
        //  An information box appears to display "We are sorry but we can't cover
        //  for travellers who are: Traveller against medical advice or for medical treatment".
        //show default result page to user
        // let selectedAssessee = this.filterAssessee(this.state.currentSelectedTravelerIndex, this.state.currentSelectedTravelerIsDependent);
        // selectedAssessee.Status = this.whiteLebeling.NotCoveredTxt;
        this.changeAssesseeStatus(
          this.state.currentSelectedTravelerIndex,
          this.whiteLebeling.NotCoveredTxt,
          this.state.currentSelectedTravelerIsDependent,
        );

        sessionStorage.setItem(
          'assesmentsDetails',
          JSON.stringify(this.state.assesmentsDetails),
        );
        this.userSelectionIndex = 6;
      } else if (
        !this.state.question1Selected &&
        !this.state.question2Selected &&
        this.state.question4Selected
      ) {
        this.loadComponent();
      }
      else if (!this.state.question4Selected) {
        // scenarrio 3 Q4=No
        this.showResult.showAssessmantresult5 = false;
        this.showResult.showAssessmantresult4 = true;
      }
      // else if (!this.state.question5Selected) {
      //   // Scenario 4: Q5=No
      //   this.showResult.showAssessmantresult5 = false;
      //   this.showResult.showAssessmantresult4 = false;

      //   this.changeAssesseeStatus(this.state.currentSelectedTravelerIndex, "Condition Covered", this.state.currentSelectedTravelerIsDependent);

      //   sessionStorage.setItem("assesmentsDetails", JSON.stringify(this.state.assesmentsDetails));
      //   this.userSelectionIndex = 8;
      //   ///////this.scrollToTop();

      // }
      else {
        //verisk calling
        this.loadComponent();
      }
    } else {
      //below code will work for all autoclubs except W2C
      if (!this.state.question4Selected) {
        this.showResult.showAssessmantresult3 = true;
      } else {
        this.showResult.showAssessmantresult3 = false;

        if (
          !this.state.question1Selected &&
          !this.state.question2Selected &&
          !this.state.question3Selected &&
          this.state.question4Selected
        ) {
          //scenarrio 1   --no,no,no,yes => verisk
          // will be done after verisk api
          this.loadComponent();
        } else if (
          !this.state.question1Selected &&
          !this.state.question2Selected &&
          this.state.question3Selected &&
          this.state.question4Selected
        ) {
          //scenarrio 2 when case((no && no && yes && yes) => verisk
          // will be done after verisk api
          this.loadComponent();
        } else if (
          !this.state.question1Selected &&
          this.state.question2Selected &&
          !this.state.question3Selected &&
          this.state.question4Selected
        ) {
          //scenarrio 3 when case((no && yes && no && yes) => versik
          // will be done after verisk api
          this.loadComponent();
        } else if (
          !this.state.question1Selected &&
          this.state.question2Selected &&
          this.state.question3Selected &&
          this.state.question4Selected
        ) {
          //scenarrio 3 when case((no && yes && yes && yes) => versik
          // will be done after verisk api
          this.loadComponent();
        } else if (
          this.state.question1Selected &&
          this.state.question4Selected
        ) {
          //scenarrio 5 when case((yes || yes) && last Q4 = yes ) => result page
          //show default result page to user
          // let selectedAssessee = this.filterAssessee(this.state.currentSelectedTravelerIndex, this.state.currentSelectedTravelerIsDependent);
          // selectedAssessee.Status = this.whiteLebeling.NotCoveredTxt;
          this.changeAssesseeStatus(
            this.state.currentSelectedTravelerIndex,
            this.whiteLebeling.NotCoveredTxt,
            this.state.currentSelectedTravelerIsDependent,
          );
          sessionStorage.setItem(
            'assesmentsDetails',
            JSON.stringify(this.state.assesmentsDetails),
          );
          this.userSelectionIndex = 6;
          ////////this.scrollToTop();
        }
      }
    }
  }
  closeVeriskDialog() {
    this.display = 'none';
    this.isVeriskShow = false;
    this.userSelectionIndex = 1;
    this.openModal();
  }
  openVeriskModal() {
    this.closeModal();
  }

  openQuoteModal() {
    this.closeModal();
    const ref = this.dialog.open(VeriskComponent, { data: null });

    ref.afterClosed.subscribe((result) => {
      this.openModal();
      if (result != undefined) {
        this.isLoadeing = 'Yes';
      } else {
        this.isLoadeing = 'No';
      }
    
    });
  }

  loadDetailsOnPostback() {
    //console.log("In loadDetailsOnPostback");
    if (sessionStorage.getItem('veriskLoaded') == 'Yes') {
      try {
        this.veriskLoaded = sessionStorage.getItem('veriskLoaded') || '';
        sessionStorage.setItem('veriskLoaded', 'No');
        //console.log("In loadDetailsOnPostback Step1");

        this.state.currentSelectedTravelerIndex = parseInt(
          sessionStorage.getItem('currentSelectedTravelerIndex') || '',
        );
        if (sessionStorage.getItem('currentSelectedTravelerIsDependent')) {
          this.state.currentSelectedTravelerIsDependent = JSON.parse(
            sessionStorage.getItem('currentSelectedTravelerIsDependent') || '',
          );
        }
        let assessee = this.filterAssessee(
          this.state.currentSelectedTravelerIndex,
          this.state.currentSelectedTravelerIsDependent,
        );
        this.state.selectedAssesee = assessee.asseseeName;
        this.state.selectedContactId = assessee.ContactId;

        //console.log("In loadDetailsOnPostback Step2");
        this.state.isVeriskError = false;
        //this.getVeriskResult(this.state.PolicyDetails.PolicyTxnId, this.state.selectedContactId)// ((document.getElementById("VeriskScroce") as HTMLInputElement).value);
        //  alert(`/assets/veriskresult/${this.state.PolicyDetails.PolicyTxnId}_${this.state.selectedContactId}.json`);
        setTimeout(() => {
          let fileName = `${this.state.PolicyDetails.PolicyTxnId}_${this.state.selectedContactId}.json`;
          this.policyService
            .readMockupData(`/assets/veriskresult/${fileName}`)
            .subscribe(
              (response) => {
                if (response) {
                  //console.log("In loadDetailsOnPostback Step3");
                  this.veriskResult = response;
                  sessionStorage.setItem(
                    'veriskResult',
                    JSON.stringify(this.veriskResult),
                  );
                  //console.log("coming data from verisk veriskResult :" + this.veriskResult);
                  if (this.veriskResult) {
                    let veriskOutput: any = this.veriskResult;
                    //console.log("In  veriskOutput.IsSuccessful: " + veriskOutput.IsSuccessful);
                    if (veriskOutput && veriskOutput.IsSuccessful) {
                      this.openModal();
                      this.userSelectionIndex = 5;
                      //console.log("In  veriskOutput.IsSuccessful (true): " + veriskOutput.IsSuccessful);
                      //console.log("In  veriskOutput.Errors.length: " + veriskOutput.Errors.length);

                      this.state.additionalPremium =
                        veriskOutput.AdditionalPremiumDatas[0].AdditionalPremiumAmount;
                      this.state.medicalConditions =
                        veriskOutput.AdditionalPremiumDatas[0].MedicalConditionName;
                      this.state.medicalScore =
                        veriskOutput.AdditionalPremiumDatas[0].MedicalScore;
                      //console.log("VeriskOutput covered result:" + JSON.stringify(veriskOutput.AdditionalPremiumDatas[0]));

                      if (
                        veriskOutput.Errors &&
                        veriskOutput.Errors.length > 0 &&
                        veriskOutput.Errors[0] != ''
                      ) {
                        this.medicalAssessmentStatus = 'NOTCOVERED';
                        //this.scrollToTop();
                        //console.log("VeriskOutput Not covered result:" + veriskOutput.Errors[0]);
                        this.changeAssesseeStatus(
                          this.state.currentSelectedTravelerIndex,
                          'Not Covered',
                          this.state.currentSelectedTravelerIsDependent,
                        );
                      } else {
                        // this.medicalAssessmentStatus = 'ACCEPTDECLINED';
                        //console.log("this.state.isVeriskError - " + this.userSelectionIndex);
                        //console.log("this.state.isVeriskError - " + this.state.isVeriskError);
                        //console.log("In loadDetailsOnPostback Step4");
                      }
                    } else {
                      if (
                        veriskOutput &&
                        veriskOutput.Errors &&
                        veriskOutput.Errors.length > 0
                      ) {
                        let status = Utility.typeSafeHandle(
                          veriskOutput,
                          'Status',
                        );
                        if (!status) {
                          //console.log("In veriskOutput.Errors:" + veriskOutput.IsSuccessful);
                          this.state.isVeriskError = true;
                          this.state.veriskError = JSON.stringify(
                            veriskOutput.Errors[0],
                          );
                          this.openAlertModel();
                        } else {
                          this.userSelectionIndex = 1;
                        }
                      } else {
                        this.state.isVeriskError = true;
                        this.state.veriskError = JSON.stringify(veriskOutput);
                        this.openAlertModel();
                      }
                    }
                  } else {
                    this.state.isVeriskError = true;
                    this.state.veriskError = 'No result found!';
                    this.openAlertModel();
                  }
                  return response;
                }
              },
              (error) => {
                //console.log("Error while reading file: " + fileName);
              },
            );
        }, 500);
        // , error => {
        //   this.cancelMessageEror = error.Message;
        //   this.openAlertModel();
        // });
      } catch (error) {
        this.state.isVeriskError = true;
        this.state.veriskError = JSON.stringify(error);
        this.openAlertModel();
      }
    }
  }
  viewModal(fname: any, lname: any, contact: any) {
    this.viewtravelerName = fname + ' ' + lname;
    this.setPreExistingMedicalQuestionAnswers(contact);
    this.viewModalRef = this.modalService.open(this.viewContent, {
      size: 'xl',
      backdrop: 'static',
      keyboard: false,
    });
  }
  closeViewModal() {
    this.viewModalRef.close();
  }

  loadComponent() {
    this.itemService.siteName == this.siteName;
    if (sessionStorage.getItem('itemServiceResponseData')) {
      const itemData: any = JSON.parse(
        sessionStorage.getItem('itemServiceResponseData') || '',
      );
    }
  }

  getScreeningData(formText: any) {
    if (!formText || formText == '') {
      return '';
    } else {
      let formXml = formText;
      const parser = new DOMParser();
      const xml = parser.parseFromString(formXml, 'text/xml');
      const obj: any = this.ngxXml2jsonService.xmlToJson(xml);
      return obj['ScreeningPath']['SystemData']['ScreeningData'];
    }
  }

  changeSelection(index: number, status: string) {
    switch (index) {
      case 1:
        this.state.question1Selected = status === 'Yes' ? true : false;
        break;
      case 2:
        this.state.question2Selected = status === 'Yes' ? true : false;
        break;
      case 3:
        this.state.question3Selected = status === 'Yes' ? true : false;
        break;
      case 4:
        this.state.question4Selected = status === 'Yes' ? true : false;
        break;
      // case 5:
      //   this.state.question5Selected = status === 'Yes' ? true : false;
      //   break;
      default:
        break;
    }
  }
  getFullName(fName: any, mName: any, lName: any) {
    return Utility.getFullName(fName, mName, lName, true);
  }

  showMedAssessVeriskModal: boolean = false;

  openVeriskDialog(): void {
    const policyGuid = sessionStorage.getItem("policyGuid");
    const movementGuid = sessionStorage.getItem("movementGuid")
    
    if (!policyGuid || !movementGuid) {
      this.isSavingRecord = false;
      return;
    }
    this.policyService.getData(policyGuid, movementGuid).subscribe((resp) => {
      const traveler = resp.travelers.find((traveler: any) => traveler.travelerId === this.veriskTraveler);
      const currentAssessment = traveler.role;

      this.createAssessmentDetails(policyGuid, resp);

      sessionStorage.setItem("currentAssessment", JSON.stringify(currentAssessment));
      sessionStorage.setItem("quoteDetail", JSON.stringify(resp));
      sessionStorage.setItem("veriskTravelerGuid", JSON.stringify(this.veriskTraveler));
  
      this.showMedAssessVeriskModal = true;
      const dialogRef = this.dialogReference.open(VeriskComponent, {
        direction: 'rtl',
        width: '100%',
        animation: { to: 'left' },
        position: { rowEnd: '0' },
      });
      this.toggleOverlayContainer();
  
      dialogRef.afterClosed().subscribe((result) => {
        this.handleAfterClose();
      });
      this.closeModal();
      this.isSavingRecord = false;
    }, error => {
      this.isSavingRecord = false;
    });
  }

  createAssessmentDetails(policyGuid: string, policy: any) {
    const assessmentDetails: AssessmentDetails = {};
    const dependentAssessments: AssessmentDetail[] = [];

    policy.travelers.forEach((traveler: any) => {
      if (traveler.role == 'primary') {
        assessmentDetails.primary = this.createTravelerAssessment(policyGuid, traveler);
      } else if (traveler.role == 'secondary') {
        assessmentDetails.secondary = this.createTravelerAssessment(policyGuid, traveler);
      } else {
        dependentAssessments.push(this.createTravelerAssessment(policyGuid, traveler));
      }
    })

    if (dependentAssessments.length > 0) {
      assessmentDetails.dependent = dependentAssessments;
    }

    sessionStorage.setItem("assessmentDetails", JSON.stringify(assessmentDetails));
  }

  createTravelerAssessment(policyGuid: string, traveler: any): AssessmentDetail {
    const assessment = (traveler.medicalInfo == null || traveler.medicalInfo.xml == null) ? '' : traveler.medicalInfo.xml;
    const assessmentDetail: AssessmentDetail = {
      transactionId: policyGuid,
      role: traveler.role,
      travelerId: traveler.travelerId,
      xmlResult: assessment,
      dateOfBirth: traveler.dateOfBirth
    };
    return assessmentDetail;
  }

  toggleOverlayContainer(): void {
    const overlayContainer = this.el.nativeElement.ownerDocument.querySelector(
      '.cdk-overlay-container',
    );
    if (this.showMedAssessVeriskModal) {
      this.renderer.setStyle(overlayContainer, 'display', 'block');
    } else {
      this.renderer.setStyle(overlayContainer, 'display', 'none');
    }
  }

  handleAfterClose(fromRecalculate?: boolean) {
    const result = this.localCacheService.getSessionStorage('assessmentDetails');
    const currentAssessment = this.localCacheService.getSessionStorage('currentAssessment');

    let traveler = result.primary;
    if (currentAssessment == 'secondary') {
      traveler = result.secondary;
    } else if (currentAssessment == 'dependent') {
      traveler = result.dependent.find(((traveler: any) => traveler.travelerId === this.veriskTraveler));;
    }

    this.veriskResult = traveler.xmlResult;

    let conditions: any[] = [];
    if (traveler.medicalPremium != null && traveler.medicalPremium.medicalConditions != null && traveler.medicalPremium.medicalConditions.length > 0) {
      let declaredConditions = traveler.medicalPremium.medicalConditions.split('|');
      if (declaredConditions.length > 1) {
        conditions = declaredConditions;
      } else {
        conditions.push(traveler.medicalPremium.medicalConditions);
      }
    } else {
      setTimeout(() => {
        this.openModal();
      }, 1000);
      
      this.callEndorsement();
      this.resetLoaderState();
      return;
    }

    if (!fromRecalculate) {
      this.openModal(true);
    }

    // Handle if verisk was just closed.
    if (!traveler.medicalPremium) {
      this.userSelectionIndex = 1;
      return;
    }
    
    setTimeout(() => {
      const assesse = this.policyDetails.travelers.find((assessmentTraveler: any) => assessmentTraveler.travelerId == traveler.travelerId);
      this.userSelectionIndex = 5;
      this.state.isVeriskError = false;
      this.medicalAssessmentStatus = traveler.medicalPremium.isNotCovered ? 'NOTCOVERED' : 'ACCEPTDECLINED';
      this.state.additionalPremium = traveler.medicalPremium.totalAdditionalPremiumAmount;
      this.state.medicalConditions = conditions;
      if (assesse != null) {
        this.state.selectedAssesee = assesse.firstName + " " + assesse.lastName;
      }
      this.isMedicalScoreVisible = 'false';
      this.resetLoaderState();
    }, 100);
    if (!fromRecalculate) {
      this.toggleOverlayContainer();
    }
  }

  resetLoaderState() {
    this.isSavingRecord = false;
    this.isSavingForDependent = false;
    this.buttonClicked = '';
  }

  checkAssessOfTralver1(travelerType: any, reassesFlag: string = '') {
    this.reassesFlag = reassesFlag;
    this.state.isShowSendMail = false;
    this.state.isShowSendMailToggle = true;

    this.state.currentSelectedTravelerIndex = 0;
    this.state.currentSelectedTravelerIsDependent = false;

    if (this.medicalLabeling) {
      this.isVeriskShow = true;
      this.veriskTraveler = this.state.Traveler1.ContactId;
      const traveler = this.policyDetails.travelers.find((traveler: any) => traveler.travelerId === this.veriskTraveler);
      this.LoadMedicalAssessmentLebeling(this.medicalLabeling);
      this.setPreExistingMedicalQuestionAnswers(traveler.medicalDeclarations);
    }

    // this.setPreExistingMedicalQuestionAnswers(this.state.Traveler1.ContactId);

    //this.updateAssesmentsDetailsObj("Update", 0, false);

    // this.state.question1Selected = false;
    // this.state.question2Selected = false;
    // this.state.question3Selected = false;
    // this.state.question4Selected = false;
    // this.state.question5Selected = false;

    //this.addChargeFlagForButtonToggle = 'No';
    //this.adultTravelerStatusChecked = status;
    this.currentTraveler = travelerType;
    if (this.adultTravelerStatusChecked) {
      this.userSelectionIndex = 2;
      //this.scrollToTop();
      this.travelerName = Utility.combineStrings(
        this.state.Traveler1.FirstName,
        this.state.Traveler1.LastName,
      );
      this.travelerMedCondDetails.push({
        nameOfTraveler: this.travelerName,
        travelerMedCondStatus: this.adultTravelerStatusChecked ? 'Yes' : 'No',
      });
      ////console.log("Contact ID of :" + this.travelerName + " " + this.state.Traveler1.ContactId);
      this.addAssesseeDetails(
        this.travelerName,
        this.state.Traveler1.Age,
        0,
        false,
        this.state.Traveler1.ContactId,
      );
      sessionStorage.setItem(
        'travelerMedCondDetails',
        JSON.stringify(this.travelerMedCondDetails),
      );
    } else {
      sessionStorage.setItem('travelerMedCondStatus', 'No');
    }
  }
  checkAssessAddTravlr2(addtravelerType: any, reassesFlag: string = '') {
    this.reassesFlag = reassesFlag;
    this.state.isShowSendMail = false;
    this.state.isShowSendMailToggle = true;

    this.state.currentSelectedTravelerIndex = 1;
    this.state.currentSelectedTravelerIsDependent = false;
    if (this.medicalLabeling) {
      this.isVeriskShow = true;
      this.veriskTraveler = this.state.Traveler2.ContactId;
      const traveler = this.policyDetails.travelers.find((traveler: any) => traveler.travelerId === this.veriskTraveler);
      this.LoadMedicalAssessmentLebeling(this.medicalLabeling);
      this.setPreExistingMedicalQuestionAnswers(traveler.medicalDeclarations);
    }

    //this.updateAssesmentsDetailsObj("Update", 1, false);

    // this.state.question1Selected = false;
    // this.state.question2Selected = false;
    // this.state.question3Selected = false;
    // this.state.question4Selected = false;
    // this.state.question5Selected = false;

    //this.addChargeFlagForButtonToggle = 'No';
    this.currentTraveler = addtravelerType;
    ////console.log('dependentStatus......' + addTravelStatus);
    //this.additionalTravelerStatusChecked = addTravelStatus;
    if (this.additionalTravelerStatusChecked) {
      this.userSelectionIndex = 2;
      //this.scrollToTop();
      this.travelerName = Utility.combineStrings(
        this.state.Traveler2.FirstName,
        this.state.Traveler2.LastName,
      );
      ////console.log("Contact ID of :" + this.travelerName + " " + this.state.Traveler2.ContactId);
      this.addAssesseeDetails(
        this.travelerName,
        this.state.Traveler2.Age,
        1,
        false,
        this.state.Traveler2.ContactId,
      );
    } else {
      //nothing
    }
  }

  checkAssessDepTravlr3(dependetType: any, idx: any, reassesFlag: string = '') {
    this.reassesFlag = reassesFlag;
    this.state.isShowSendMail = false;
    this.state.isShowSendMailToggle = true;

    this.state.currentSelectedTravelerIndex = idx;
    this.state.currentSelectedTravelerIsDependent = true;

    if (this.medicalLabeling) {
      this.isVeriskShow = true;
      this.veriskTraveler = this.users[idx].ContactId;
      const traveler = this.policyDetails.travelers.find((traveler: any) => traveler.travelerId === this.veriskTraveler);
      this.LoadMedicalAssessmentLebeling(this.medicalLabeling);
      this.setPreExistingMedicalQuestionAnswers(traveler.medicalDeclarations);
    }
    // this.setPreExistingMedicalQuestionAnswers(this.users[idx].ContactId);

    // this.state.question1Selected = false;
    // this.state.question2Selected = false;
    // this.state.question3Selected = false;
    // this.state.question4Selected = false;
    // this.state.question5Selected = false;

    //this.addChargeFlagForButtonToggle = 'No';
    this.currentTraveler = dependetType;
    ////console.log('dependentStatus....' + dependentStatus.value + dependent);
    if (this.users[idx].dependentStatusChecked) {
      this.userSelectionIndex = 2;
      //this.scrollToTop();
      this.travelerName = Utility.combineStrings(
        this.users[idx].FName,
        this.users[idx].LName,
      );
      ////console.log("Contact ID of :" + this.travelerName + " " + dependent.ContactId);
      this.addAssesseeDetails(
        this.travelerName,
        this.users[idx].Age,
        idx,
        true,
        this.users[idx].ContactId,
      );
    } else {
      //nothing
    }
    // this.users[idx]['dependentStatusChecked'] = dependentStatus;
    //const currentObj = this.users[idx];
    // currentObj['dependentStatusChecked'] = dependentStatus;
    // alert(JSON.stringify(this.users));
    sessionStorage.setItem('dependentDetails', JSON.stringify(this.users));
  }

  // updateAssementStatus(selectedUserIndex, isDependent, status) {
  //   this.state.assesmentsDetails = [...this.state.assesmentsDetails].map(assesee => {
  //     if (assesee.selectedUserIndex === selectedUserIndex && assesee.IsDependent == isDependent) {
  //       return {
  //         ...assesee, asseseeName: name, Status: status
  //       }
  //     }
  //     else {
  //       return { ...assesee };
  //     }
  //   });
  // }
  changeAssesseeStatus(selectedUserIndex: any, status: any, isDependent: any) {
    if (this.state.assesmentsDetails) {
      this.state.assesmentsDetails = [...this.state.assesmentsDetails].map(
        (assesee) => {
          if (
            assesee.selectedUserIndex === selectedUserIndex &&
            assesee.IsDependent == isDependent
          ) {
            return {
              ...assesee,
              additionalPremium: this.state.additionalPremium,
              medicalConditions: this.state.medicalConditions,
              Status: status,
            };
          } else {
            return { ...assesee };
          }
        },
      );
    }
    sessionStorage.setItem(
      'assesmentsDetails',
      JSON.stringify(this.state.assesmentsDetails),
    );
  }
  addAssesseeDetails(
    fullName: any,
    age: any,
    index: any,
    IsDependent: any = false,
    ContactId: any = 0,
    status: any = undefined,
    medicalConditions: any = undefined,
    additionalPremium: any = undefined,
    formText = undefined,
    rescore = undefined,
    recalculateMedicalPremium = undefined,
    medicalResult = '',
    medicalScore = '',
  ) {
    var assesseeObj: any = this.assesseeStatusObject();
    assesseeObj.selectedUserIndex = index;
    assesseeObj.IsDependent = IsDependent;
    assesseeObj.asseseeName = fullName;
    assesseeObj.ContactId = ContactId;
    assesseeObj.Age = age;
    assesseeObj.FormText = formText;
    assesseeObj.Rescore = rescore;
    assesseeObj.RecalculateMedicalPremium = recalculateMedicalPremium;
    assesseeObj.MedicalResult = medicalResult;
    assesseeObj.MedicalScore = medicalScore;
    if (additionalPremium != undefined) {
      assesseeObj.additionalPremium = additionalPremium;
    }
    if (status != undefined) {
      assesseeObj.Status = status;
    }
    if (medicalConditions != undefined) {
      assesseeObj.medicalConditions = medicalConditions;
    }
    ////console.log("addAssesseeDetails Name " + fullName + "Contact ID:" + ContactId);
    let assessee = this.state.assesmentsDetails.find(
      (assessee: any) =>
        assessee.selectedUserIndex == index &&
        assessee.IsDependent == IsDependent,
    );
    if (assessee) {
      ////console.log('In AddAssesseeDetails already exists: ' + JSON.stringify(assessee));
      this.syncUserName(index, IsDependent);
    } else {
      //console.log('In AddAssesseeDetails new one: ' + JSON.stringify(assesseeObj));
      this.state.assesmentsDetails.push(assesseeObj);
    }
    sessionStorage.setItem(
      'assesmentsDetails',
      JSON.stringify(this.state.assesmentsDetails),
    );
    ////console.log("End AddAssesseeDetails");
  }

  syncUserName(selectedUserIndex: any, isDependent: any) {
    let name = '';
    if (isDependent) {
      let user = this.users[selectedUserIndex];
      name = `${user.FName} ${user.LName}`;
    } else {
      name =
        selectedUserIndex == 0
          ? `${this.state.Traveler1.FirstName} ${this.state.Traveler1.LastName}`
          : `${this.state.Traveler2.FirstName} ${this.state.Traveler2.LastName}`;
    }
    if (this.state.assesmentsDetails) {
      this.state.assesmentsDetails = [...this.state.assesmentsDetails].map(
        (assesee) => {
          if (
            assesee.selectedUserIndex === selectedUserIndex &&
            assesee.IsDependent == isDependent
          ) {
            return {
              ...assesee,
              asseseeName: name,
            };
          } else {
            return { ...assesee };
          }
        },
      );
    }
  }

  isDeclarationContinueDisabled() {
    return (this.state.question1Selected || !this.state.question4Selected);
  }

  getAssesseeStatus(index: number, isDependent: boolean = false) {
    if (this.state.assesmentsDetails) {
      let assesse = this.policyDetails.travelers.find((traveler: any) => traveler.role == 'primary');
      if (index == 1 && !isDependent) {
        assesse = this.policyDetails.travelers.find((traveler: any) => traveler.role == 'secondary');
      } else if (isDependent) {
        const dependents = this.policyDetails.travelers.filter((traveler: any) => traveler.role == 'dependent');
        if (!dependents || dependents.count == 0) {
          return 'Pending';
        }
        assesse = dependents[index];
      }
      if (!assesse || !assesse.medicalInfo) {
        return 'Pending';
      }
      return assesse.medicalInfo.status;
    }
    return 'Pending';
  }

  filterAssessee(index: any, isDependent: any = false) {
    if (this.state.assesmentsDetails) {
      let assesse = this.state.assesmentsDetails.find(
        (assessee: any) =>
          assessee.selectedUserIndex === index &&
          assessee.IsDependent == isDependent,
      );
      if (assesse) {
        return assesse;
      }
      return this.assesseeStatusObject();
    }
  }

  assesseeStatusObject() {
    return {
      asseseeName: '',
      Age: 0,
      Status: 'Pending',
      IsDependent: false,
      medicalConditions: [],
      additionalPremium: 0,
      selectedUserIndex: 0,
      ContactId: 0,
      Rescore: false,
      FormText: '',
      RecalculateMedicalPremium: false,
      MedicalResult: '',
      MedicalScore: '',
    };
  }

  getAssessBtn1(value: any) {
    //debugger;
    sessionStorage.setItem('adultTravelerMedCond1', value);
    this.adultTravelerStatusChecked = value == 'Yes' ? true : false;
    this.state.assesseeValidation = false;
    if (value == 'Yes') {
      this.state.showAssessBtn1 = true;
      this.state.showAssessCondition1 = false;
    } else {
      this.state.showAssessBtn1 = false;
    }
  }

  getAssessBtn2(value: any) {
    sessionStorage.setItem('adultTravelerMedCond2', value);
    this.additionalTravelerStatusChecked = value == 'Yes' ? true : false;
    this.state.assesseeValidation = false;
    if (value == 'Yes') {
      this.state.showAssessBtn2 = true;
      this.state.showAssessCondition2 = false;
    } else {
      this.state.showAssessBtn2 = false;
      // this.showAssessCondition2 = true;
    }
  }

  getAssessBtn3(value: any, idx: any) {
    this.state.assesseeValidation = false;
    sessionStorage.setItem('dependentMedCond', value);
    this.users[idx].dependentStatusChecked = value == 'Yes' ? true : false;
    if (value == 'Yes') {
      this.state.showAssessBtn3[idx] = true;
      this.state.showAssessCondition3[idx] = false;
    } else {
      this.state.showAssessBtn3[idx] = false;
    }
    sessionStorage.setItem(
      'showAssessBtn3',
      JSON.stringify(this.state.showAssessBtn3),
    );
    sessionStorage.setItem('dependentDetails', JSON.stringify(this.users));
  }

  goToMedicalConditionAfterVerisk() {
    this.loaderService.show();
    this.callEndorsement(true);
  }

  updateMedicalConditionWhenNotCovered() {
    const traveler = this.policyDetails.travelers.find((traveler: any) => traveler.travelerId === this.veriskTraveler);
    this.LoadMedicalAssessmentLebeling(this.medicalLabeling);
    this.setPreExistingMedicalQuestionAnswers(traveler.medicalDeclarations);
    this.userSelectionIndex = 2;
    this.isVeriskShow = true;
  }

  needToUpdateMedicalAssessment(index: number, isDependent: boolean = false) {
    if (this.state.assesmentsDetails) {
      let assesse = this.policyDetails.travelers.find((traveler: any) => traveler.role == 'primary');
      if (index == 1 && !isDependent) {
        assesse = this.policyDetails.travelers.find((traveler: any) => traveler.role == 'secondary');
      } else if (isDependent) {
        const dependents = this.policyDetails.travelers.filter((traveler: any) => traveler.role == 'dependent');
        if (!dependents || dependents.count == 0) {
          return '';
        }
        assesse = dependents[index];
      }
      return assesse?.medicalInfo?.rescore;
    }
    return false;
  }

  checkforRSXLogging() {
    let value;
    //Check for RSXLogging
    // if (sessionStorage.getItem('itemServiceResponseData')) {
    //   const itemData: any = JSON.parse(
    //     sessionStorage.getItem('itemServiceResponseData') || '',
    //   );
    //   const itemId = this.itemService.getItemServiceID('GenericSettings');
    //   const genericSettingsData = itemData[itemId];
    //   value =
    //     genericSettingsData != null &&
    //     genericSettingsData.IsRSXLogActive != null &&
    //     genericSettingsData.IsRSXLogActive.toLowerCase() == 'true'
    //       ? true
    //       : false;
    // }
    return value;
  }

  updateTravellersDetails() {
    var updatedTraveller = JSON.parse(
      sessionStorage.getItem('updatedContact') || '',
    );
    if (updatedTraveller != null) {
      if (this.state.Traveler1 != null) {
        this.state.Traveler1.FirstName = updatedTraveller.firstName;
        this.state.Traveler1.LastName = updatedTraveller.lastName;
        let dob: any = this.setPostDateFormat(updatedTraveller.dob);
        this.state.Traveler1.Age = Utility.ageFromDateOfBirth(new Date(dob));
      }
      if (
        this.state.Traveler2 != null &&
        updatedTraveller.secondaryTraveller.length > 0
      ) {
        this.state.Traveler2.FirstName =
          updatedTraveller.secondaryTraveller[0].SecondaryfirstName;
        this.state.Traveler2.LastName =
          updatedTraveller.secondaryTraveller[0].SecondaryLastName;
        let dob1: any = this.setPostDateFormat(
          updatedTraveller.secondaryTraveller[0].dob1,
        );
        this.state.Traveler2.Age = Utility.ageFromDateOfBirth(new Date(dob1));
      } else if (
        this.state.Traveler2 != null &&
        updatedTraveller.secondaryTraveller.length == 0
      ) {
        this.state.Traveler2.FirstName = '';
        this.state.Traveler2.LastName = '';
        this.state.Traveler2.Age = '';
      }
    }
  }

  assignContactValues(data: any) {
    var updatedTraveller = JSON.parse(
      sessionStorage.getItem('updatedContact') || '',
    );
    data.Traveler1.FirstName = updatedTraveller.firstName;
    data.Traveler1.LastName = updatedTraveller.lastName;
    data.Traveler1.Addresses[0].StreetAddress = updatedTraveller.address;
    data.Traveler1.Addresses[0].StateCode = updatedTraveller.state;
    data.Traveler1.Addresses[0].ZipCode = updatedTraveller.zipCode;
    data.Traveler1.Addresses[0].City = updatedTraveller.city;
    data.Traveler1.EmailAccts[0].EmailAddress = updatedTraveller.email;
    data.Traveler1.BirthDate = this.setPostDateFormat(updatedTraveller.dob);
    data.Traveler1.Age = Utility.ageFromDateOfBirth(
      new Date(data.Traveler1.BirthDate),
    );
    data.Traveler1.PhoneNumbers[0].PhoneNum = this.removeFormatting(
      updatedTraveller.phoneNumber,
    );

    data.RequestDetails.Ages[0] = data.Traveler1.Age;

    if (updatedTraveller.memberShip == '') {
      delete data.QuoteDetails.MembershipNumber;
    } else {
      data.QuoteDetails.MembershipNumber = updatedTraveller.memberShip;
    }

    if (
      updatedTraveller.secondaryTraveller.length > 0 &&
      updatedTraveller.secondaryTraveller[0].SecondaryfirstName != '' &&
      updatedTraveller.secondaryTraveller[0].SecondaryfirstName != null &&
      updatedTraveller.secondaryTraveller[0].SecondaryLastName != '' &&
      updatedTraveller.secondaryTraveller[0].SecondaryLastName != null
    ) {
      let traveler2: any;
      if (data.Traveler2 && data.Traveler2.FirstName) {
        traveler2 = Object.assign({}, data.Traveler2);
      } else {
        traveler2 = Object.assign({}, data.Traveler1);
        traveler2.ContactId = '';
      }

      traveler2.FirstName =
        updatedTraveller.secondaryTraveller[0].SecondaryfirstName;
      traveler2.LastName =
        updatedTraveller.secondaryTraveller[0].SecondaryLastName;
      traveler2.BirthDate = this.setPostDateFormat(
        updatedTraveller.secondaryTraveller[0].dob1,
      );
      traveler2.Age = Utility.ageFromDateOfBirth(new Date(traveler2.BirthDate));
      data.RequestDetails.Ages[1] = traveler2.Age;
      data.Traveler2 = traveler2;
    } else {
      if (data.Traveler2 != undefined) {
        data.Traveler2 = [];
        data.RequestDetails.Ages.splice(1, 1);
      }
    }
  }

  removeFormatting(inputStr: any) {
    if (inputStr) {
      return inputStr.replace(/[^a-zA-Z0-9]/g, '');
    }
  }

  setPostDateFormat(date: any) {
    if (
      date == '' ||
      date == null ||
      date === undefined ||
      typeof date != 'object'
    ) {
      return;
    }
    let ngbDate = date;
    let datevalue =
      ngbDate['year'].toString() +
      '-' +
      (ngbDate['month'].toString().length == 1
        ? '0' + ngbDate['month'].toString()
        : ngbDate['month'].toString()) +
      '-' +
      (ngbDate['day'].toString().length == 1
        ? '0' + ngbDate['day'].toString()
        : ngbDate['day'].toString());
    return datevalue;
  }

  checkForUpdatedContact(obj: any) {
    if (
      sessionStorage.getItem('updatedContact') &&
      sessionStorage.getItem('isContactModified')
    ) {
      var updatedContactTxnId = JSON.parse(
        sessionStorage.getItem('isContactModified') || '',
      ).PolicyTxnId;
      if (updatedContactTxnId == sessionStorage.getItem('movementGuid')) {
        this.travelerUpdateService.updateTravellersDetails(obj);
      }
    }
  }

  closePreExistingModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  //LTX
  populatePolicyData(currentPolicyInStore: any) {
    if (currentPolicyInStore) {
      this.isLoadeing = 'No';
      this.siteName = currentPolicyInStore.autoClub;

      this.state.PolicyDetails.PolicyTxnId = currentPolicyInStore.movementGuid.toString();
      this.state.PolicyDetails.PolicyNum = currentPolicyInStore.policyNumber;
      this.state.PolicyDetails.PolicyID = currentPolicyInStore.policyGuid.toString();

      this.setPolicyDetails(currentPolicyInStore);

      this.loadDetailsOnPostback();
      
      // this.loadCommonMedicalDetails();

      if(currentPolicyInStore.productType.toLowerCase() == "Rental Base".toLowerCase()) {
        this.isRentalBase = true;
      } 
      else {
          this.isRentalBase = false;
      }
    }
    if (currentPolicyInStore.movements) {
      let policyMovement = currentPolicyInStore.movements;
      let prPolicy = policyMovement.find(
        (ele: any) =>
          ele.TxnTypeCode == 'RW' &&
          ele.TxnStatusCode === 'PR' &&
          ele.Status !== 'Completed',
      );
      this.prpolicytype = prPolicy ? false : true;
    }
  } 

  setPolicyDetails(currentPolicyInStore: any) {
    this.state.assesmentsDetails = [];
    let medicalConditions: any = [];
    let dependents: any = [];
    let additionalPremium = 0;
    let fullName = '';
    let medicalAssessStatus = '';
    if ( currentPolicyInStore != null ){
      this.state.FromDate = currentPolicyInStore.fromDate;
      this.state.ToDate = currentPolicyInStore.toDate;

      //Traveler 1
      let traveler1 = (currentPolicyInStore.travelers !=null && currentPolicyInStore.travelers.length > 0) ? currentPolicyInStore.travelers.find((x: { role: string; }) => x.role == "primary") : null;
      if(traveler1 != null){
        this.state.Traveler1.FirstName = (traveler1 != null) ? traveler1.firstName : "";
        this.state.Traveler1.LastName = (traveler1 != null) ? traveler1.lastName : "";
        fullName = Utility.getFullName( this.state.Traveler1.FirstName, '', this.state.Traveler1.LastName, );
        let trvOneAge = Utility.formattedDate(traveler1.dateOfBirth);
        this.state.Traveler1.Age = (traveler1 != null) ? Utility.ageFromDateOfBirth(trvOneAge, ) : 0;
        this.state.Traveler1.BirthDate = (traveler1 != null) ? traveler1.dateOfBirth : "";
        this.state.Traveler1.ContactId = (traveler1 != null) ? traveler1.travelerId : "";
        // this.state.Traveler1.IsOldVeriskXML = (traveler1 != null && traveler1.medicalInfo != null && traveler1.medicalInfo.xml != null) ? true : false;
        // this.state.Traveler1.RecalculateMedicalPremium = this.getAssessmentData( currentPolicyInStore, this.state.Traveler1.ContactId, 'RC');
        this.state.Traveler1.RecalculateMedicalPremium = (traveler1 != null && traveler1.medicalInfo != null) ? traveler1.medicalInfo.rescore : false ;
        this.state.Traveler1.Rescore = (traveler1 != null && traveler1.medicalInfo != null) ? traveler1.medicalInfo.rescore : false ;
        this.state.Traveler1.FormText = (traveler1 != null && traveler1.medicalInfo != null && traveler1.medicalInfo.xml != null) ? traveler1.medicalInfo.xml : "";
        this.state.Traveler1.MedicalResult = (traveler1 != null && traveler1.medicalInfo != null) ? traveler1.medicalInfo.status : "" ;
        this.state.Traveler1.MedicalScore = (traveler1 != null && traveler1.medicalInfo != null) ? traveler1.medicalInfo.score : 0 ;
        this.state.Traveler1.MedicalDeclarations = traveler1.medicalDeclarations;
  
        this.setPreExistingMedicalQuestionAnswers(traveler1.medicalDeclarations);
  
        if ( this.state.question1Selected == false && this.state.question2Selected == false && this.state.question3Selected == false && this.state.question4Selected == false ) {
          this.state.Traveler1.IsQuestionAnswer = false;
        } 
        else {
          this.state.Traveler1.IsQuestionAnswer = true;
        }
  
        if ( !this.state.Traveler1.MedicalResult || this.state.Traveler1.MedicalResult == undefined || this.state.Traveler1.MedicalResult === 'None' ) {
          medicalAssessStatus = 'Pending';
          this.adultTravelerStatusChecked = false;
          this.state.showAssessBtn1 = false;
        } 
        else {
          this.adultTravelerStatusChecked = true;
          this.state.showAssessBtn1 = true;
          medicalAssessStatus = this.state.Traveler1.MedicalResult;
          medicalConditions = (traveler1 != null && traveler1.medicalInfo != null) ? traveler1.medicalInfo.medicalConditions : "" ;
          // additionalPremium = this.getAssessmentData( currentPolicyInStore, this.state.Traveler1.ContactId, 'P', );
        }
        // this.eventEmitter.onTrvlUpdated(true);
        this.addAssesseeDetails( fullName, this.state.Traveler1.Age, 0, false, this.state.Traveler1.ContactId, medicalAssessStatus, medicalConditions, additionalPremium, this.state.Traveler1.FormText, this.state.Traveler1.Rescore, this.state.Traveler1.RecalculateMedicalPremium, this.state.Traveler1.MedicalResult, this.state.Traveler1.MedicalScore, );
      }
      
      //Traveler 2
      let traveler2 = (currentPolicyInStore.travelers !=null && currentPolicyInStore.travelers.length > 0) ? currentPolicyInStore.travelers.find((x: { role: string; }) => x.role == "secondary") : null;
      if (traveler2 != null) {
        this.state.Traveler2.FirstName = (traveler2 != null) ? traveler2.firstName : "";
        this.state.Traveler2.LastName = (traveler2 != null) ? traveler2.lastName : "";
        fullName = Utility.getFullName( this.state.Traveler2.FirstName, '', this.state.Traveler2.LastName, );
        let trvTwoAge = Utility.formattedDate(traveler2.dateOfBirth);
        this.state.Traveler2.Age = (traveler2 != null) ? Utility.ageFromDateOfBirth(trvTwoAge, ) : 0;
        this.state.Traveler2.BirthDate = (traveler2 != null) ? traveler2.dateOfBirth : "";
        this.state.Traveler2.ContactId = (traveler2 != null) ? traveler2.travelerId : "";
        // this.state.Traveler2.IsOldVeriskXML = (traveler2 != null && traveler2.medicalInfo != null && traveler2.medicalInfo.xml != null) ? true : false;
        this.state.Traveler2.RecalculateMedicalPremium =  (traveler2 != null && traveler2.medicalInfo != null) ? traveler2.medicalInfo.rescore : false ;
      
        this.state.Traveler2.Rescore = (traveler2 != null && traveler2.medicalInfo != null) ? traveler2.medicalInfo.rescore : false ;
        this.state.Traveler2.FormText = (traveler2 != null && traveler2.medicalInfo != null && traveler2.medicalInfo.xml != null) ? traveler2.medicalInfo.xml : "";
        this.state.Traveler2.MedicalResult = (traveler2 != null && traveler2.medicalInfo != null) ? traveler2.medicalInfo.status : "" ;
        this.state.Traveler2.MedicalScore = (traveler2 != null && traveler2.medicalInfo != null) ? traveler2.medicalInfo.score : 0 ;
        this.state.Traveler2.MedicalDeclarations = traveler2.medicalDeclarations;

        this.setPreExistingMedicalQuestionAnswers(traveler2.medicalDeclarations);

        if ( this.state.question1Selected == false && this.state.question2Selected == false && this.state.question3Selected == false && this.state.question4Selected == false ) {
          // && this.state.question5Selected == false
          this.state.Traveler2.IsQuestionAnswer = false;
        } 
        else {
          this.state.Traveler2.IsQuestionAnswer = true;
        }

        if ( !this.state.Traveler2.MedicalResult || this.state.Traveler2.MedicalResult == undefined || this.state.Traveler2.MedicalResult === 'None' ) {
          medicalAssessStatus = 'Pending';
          this.additionalTravelerStatusChecked = false;
          this.state.showAssessBtn2 = false;
        } 
        else {
          this.additionalTravelerStatusChecked = true;
          this.state.showAssessBtn2 = true;
          medicalAssessStatus = this.state.Traveler2.MedicalResult;
          medicalConditions = (traveler2 != null && traveler2.medicalInfo != null) ? traveler2.medicalInfo.medicalConditions : "" ;
          // additionalPremium = this.getAssessmentData( currentPolicyInStore, this.state.Traveler2.ContactId, 'P', );
        }
        // this.eventEmitter.onTrvlUpdated(true);
        this.addAssesseeDetails( fullName, this.state.Traveler2.Age, 1, false, this.state.Traveler2.ContactId, medicalAssessStatus, medicalConditions, additionalPremium, this.state.Traveler2.FormText, this.state.Traveler2.Rescore, this.state.Traveler2.RecalculateMedicalPremium, this.state.Traveler2.MedicalResult, this.state.Traveler2.MedicalScore, );
    } 
    }
    
    else {
      this.state.Traveler2 = {
        MedicalScore: '0',
        MedicalResult: '',
        Age: null,
        ContactId: null,
        EntityTypeId: 2,
        LastName: null,
        FirstName: null,
        MiddleName: '',
        BirthDate: null,
        Addresses: [],
        NameTypeIds: [4],
        Rescore: false,
        FormText: '',
        RecalculateMedicalPremium: false,
        IsOldVeriskXML: false,
        IsQuestionAnswer: false
      };
    }
    
    let dependentList = (currentPolicyInStore.travelers !=null && currentPolicyInStore.travelers.length > 0) ? currentPolicyInStore.travelers.filter((x: { role: string; }) => x.role == "dependent") : null;
    if (dependentList != null && dependentList.length > 0) {
      this.users = [];
      let userIndex = 0;
      dependentList.forEach((element: any) => {
        let dependent: any = Object.assign({}, this.dependent);
        dependent.FirstName = (element != null && element.firstName != null) ? element.firstName : "";
        dependent.LastName = (element != null && element.lastName != null) ? element.lastName : "";
        fullName = Utility.getFullName( dependent.FirstName, '', dependent.LastName, );
        dependent.Age = (element != null && element.dateOfBirth != null) ? Utility.ageFromDateOfBirth(element.dateOfBirth) : 0;
        dependent.BirthDate = (element != null && element.dateOfBirth != null) ? element.dateOfBirth : "";
        dependent.ContactId = (element != null && element.travelerId != null) ? element.travelerId : "";

        dependent.RecalculateMedicalPremium = (element != null && element.medicalInfo != null) ? element.medicalInfo.rescore : false ;
        dependent.Rescore = (element != null && element.medicalInfo != null) ? element.medicalInfo.rescore : false ;
        dependent.FormText =  (element != null && element.medicalInfo != null && element.medicalInfo.xml != null) ? element.medicalInfo.xml : "";
        dependent.MedicalResult = (element != null && element.medicalInfo != null) ? element.medicalInfo.status : "" ;
        dependent.MedicalScore = (element != null && element.medicalInfo != null) ? element.medicalInfo.score : 0 ;
        dependent.MedicalDeclarations = dependent.medicalDeclarations;
        
        this.setPreExistingMedicalQuestionAnswers(dependent.medicalDeclarations);
        if ( this.state.question1Selected == false && this.state.question2Selected == false && this.state.question3Selected == false && this.state.question4Selected == false ) {
          dependent.IsQuestionAnswer = false;
        } 
        else {
          dependent.IsQuestionAnswer = true;
        }
        dependents.push(dependent);

        this.users.push({ FName: dependent.FirstName, LName: dependent.LastName, Age: dependent.Age, dependentStatusChecked: '', ContactId: dependent.ContactId, errorFlag: false, showInvalidDependentDateError: false, });

        if ( !dependent.MedicalResult || dependent.MedicalResult == undefined || dependent.MedicalResult === 'None' ) {
          medicalAssessStatus = 'Pending';
          this.users[userIndex].dependentStatusChecked = false;
          this.state.showAssessBtn3[userIndex] = false;
        } 
        else {
          this.users[userIndex].dependentStatusChecked = true;
          this.state.showAssessBtn3[userIndex] = true;
          medicalAssessStatus = dependent.MedicalResult;
          medicalConditions = dependent.MedicalResult;
          // additionalPremium = this.getAssessmentData( currentPolicyInStore, dependent.ContactId, 'P', );
        }

        this.addAssesseeDetails(
          fullName,
          dependent.Age,
          userIndex,
          true,
          dependent.ContactId,
          medicalAssessStatus,
          medicalConditions,
          additionalPremium,
          dependent.FormText,
          dependent.Rescore,
          dependent.RecalculateMedicalPremium,
          dependent.MedicalResult,
          dependent.MedicalScore,
        );

        userIndex++;
      });
      this.state.Dependents = dependents;
      for ( let i = 0; i < dependentList.length; i++ ) {
        this.state.Dependents[i]['IsDependent'] = true;
        this.state.Dependents[i]['selectedUserIndex'] = i;
      }
    } 
    else {
      this.state.Dependents = dependents;
    }

    if ( Utility.typeSafeHandle(currentPolicyInStore, 'AdditionalPolicyDetailsGTA.WinterSports') ) {
      this.state.veriskIsWinterSport = Utility.typeSafeHandle( currentPolicyInStore, 'AdditionalPolicyDetailsGTA.WinterSports', );
      this.state.isSingleTrip = Utility.typeSafeHandle(currentPolicyInStore, 'PolicyTypeDescription') === 'Single Trip';
    }

    this.onPrexExistingUpdate.emit( this.isReassessmentNeededIfDestinationChanged(), );
  }

  setPreExistingMedicalQuestionAnswers(declaration: any) {
      if ( declaration && declaration.AnswerOne) {
        this.state.question1Selected = declaration.AnswerOne?.toUpperCase() === 'NO' ? false : true;
        this.state.question2Selected = declaration.AnswerTwo?.toUpperCase() === 'NO' ? false : true;
        this.state.question3Selected = declaration.AnswerThree?.toUpperCase() === 'NO' ? false : true;
        this.state.question4Selected = declaration.AnswerFour?.toUpperCase() === 'NO' ? false : true;
      }
      else {
        this.state.question1Selected = false;
        this.state.question2Selected = false;
        this.state.question3Selected = false;
        this.state.question4Selected = false;
      }
  }

  state: any = {
    FromDate: '',
    ToDate: '',
    helixScore: '0',
    selectedCountries: [],
    regionId: 0,
    veriskAge: '',
    veriskUserName: '',
    VeriskRootUrl: '',
    TripDuration: 0,
    veriskRegionsId: '1',
    veriskIsWinterSport: '',
    medicalScreening: '',
    isSingleTrip: true,
    redirectUrl: '',
    veriskError: '',
    isVeriskError: false,
    selectedContactId: 0,
    netPremium: 0,
    assementStatus: {},
    assesmentsDetails: [],
    additionalPremium: 0,
    medicalScore: '0',
    medicalConditions: [],
    selectedAssesee: '',
    acceptedMedicalPremium: 0,
    currentSelectedTravelerIsDependent: false,
    currentSelectedTravelerIndex: 0,
    isShowSendMailToggle: false,
    isShowSendMail: false,
    assessmantStatus: '',
    assessmantStatus1: '',
    assesseeValidation: false,
    showAssessBtn1: false,
    showAssessBtn2: false,
    showAssessBtn3: [],
    showAssessCondition1: false,
    showAssessCondition2: false,
    showAssessCondition3: [],
    question1Selected: false,
    question2Selected: false,
    question3Selected: false,
    question4Selected: false,
    // question5Selected: false,
    PolicyDetails: {
      PolicyID: '0',
      PolicyTxnId: '0',
      QuoteNum: '---',
      PolicyNum: 0,
    },
    Traveler1: {
      MedicalScore: '0',
      MedicalResult: '',
      Age: null,
      ContactId: null,
      EntityTypeId: 2,
      LastName: null,
      FirstName: null,
      MiddleName: '',
      BirthDate: null,
      Addresses: [],
      NameTypeIds: [4],
      EmailAccts: [
        {
          IsDefault: true,
          EmailAddress: null,
          EmailTypeId: 1,
        },
      ],
      Rescore: false,
      FormText: '',
      RecalculateMedicalPremium: false,
      IsOldVeriskXML: false,
      IsQuestionAnswer: false,
    },
    Traveler2: {
      MedicalScore: '0',
      MedicalResult: '',
      Age: null,
      ContactId: null,
      EntityTypeId: 2,
      LastName: null,
      FirstName: null,
      MiddleName: '',
      BirthDate: null,
      Addresses: [],
      NameTypeIds: [4],
      Rescore: false,
      FormText: '',
      RecalculateMedicalPremium: false,
      IsOldVeriskXML: false,
      IsQuestionAnswer: false,
    },
    Dependents: [],
  };

  dependent = {
    MedicalScore: '0',
    MedicalResult: '',
    Age: null,
    ContactId: null,
    EntityTypeId: 2,
    LastName: null,
    FirstName: null,
    MiddleName: '',
    BirthDate: null,
    Addresses: [],
    NameTypeIds: [2],
    Rescore: false,
    FormText: '',

    RecalculateMedicalPremium: false,
    IsOldVeriskXML: false,
    IsQuestionAnswer: false,
  };
}
