/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { DashBoardModule } from 'projects/dashboard/src/app/app.module';
import { LoginModule } from 'projects/entraid-login/src/app/app.module';
import { ManageBranchesModule } from 'projects/manage-branches/src/app/app.module';
import { ManageUsersModule } from 'projects/manageusers/src/app/app.module';
import { PolicyModule } from 'projects/policyDetails/src/app/app.module';
import { PolicySummaryModule } from 'projects/policySummary/src/app/app.module';
import { PromoCodeModule } from 'projects/promo-code/src/app/app.module';
import { PolicyPaymentsModule } from 'projects/policy-payments/src/app/app.module';
import { environment } from './environments/environment';
import { PaymentGatewayModule } from 'projects/paymentGateWay/src/app/app.module';

if (environment.production) {
  enableProdMode();
}

var url = window.location.pathname;
platformBrowserDynamic().bootstrapModule(LoginModule);
/*else if (url.toLowerCase() == '/refunds') {
  platformBrowserDynamic().bootstrapModule(RefundsModule);
} else if (url.toLowerCase() == '/refundsreceipt') {
  platformBrowserDynamic().bootstrapModule(RefundRecieptModule);
} else if (url.toLowerCase() == '/managebranches') {
  platformBrowserDynamic().bootstrapModule(ManageBranchesModule);
} else if (url.toLowerCase() == '/promocode') {
  platformBrowserDynamic().bootstrapModule(PromoCodeModule);
} else if (url.toLowerCase() == '/manageusers') {
  platformBrowserDynamic().bootstrapModule(ManageUsersModule);
} else if (url.toLowerCase() == '/policy-detail') {
  platformBrowserDynamic().bootstrapModule(PolicyModule);
} else if (url.toLowerCase() == '/policysummary') {
  platformBrowserDynamic().bootstrapModule(PolicySummaryModule);
} else if (url.toLowerCase() == '/policypayments') {
  platformBrowserDynamic().bootstrapModule(PolicyPaymentsModule);
} else if (url.toLowerCase() == '/paymentsummary') {
  platformBrowserDynamic().bootstrapModule(PaymentGatewayModule);
}*/
