<div class="text-right mt-4" [style.display]="displayPromoAlert" *ngIf="!isUpdateENCalled">
  <div class="alert alert-success alert-dismissible fade show text-left" role="alert" *ngIf="message">
    {{ message }}
  </div>
</div>
<div class="text-right mt-4" [style.display]="displayPromoAlert" *ngIf="isUpdateENCalled">
  <div class="alert alert-success alert-dismissible fade show text-left" role="alert" *ngIf="message">
    {{ message }}
    <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="text-right mt-4 abc" [style.display]="displayPromoAlertRemove">
  <div class="alert alert-danger text-left" role="alert" *ngIf="messageRemovePromo">
    {{ messageRemovePromo }}
    <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="text-right mt-4 promo-area" *ngIf="!isUpdateENCalled" style="min-height: 10px"></div>
<div class="text-right mt-4 promo-area" *ngIf="isUpdateENCalled">
  <a class="text-right mt-1 promo" href="#" data-toggle="modal" *ngIf="isPromocode"
    [style.display]="displayPromoAlertAfterPromo" (click)="openModal()">Add a Promocode?</a>
  <a class="text-right mt-1 promo" href="#" data-toggle="modal" *ngIf="isPromocode"
    [style.display]="displayPromoAlertUpdatePromo" (click)="openModal()">Update Promocode?</a>
  <a class="text-right mt-1 promo" href="#" data-toggle="modal" *ngIf="isPromocode"
    [style.display]="displayPromoAlertRemovePromo" (click)="checkPromocodeAuthenticity('remove')">Remove Promocode?</a>
</div>

<div class="card-summary">
  <h1 class="card-gray-header-summary">Summary:</h1>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row">
        <div class="col-md-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">{{
            policyAllData?.productAlias?.toUpperCase()
            }}</span>
        </div>
        <div class="col-md-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <!-- <strong *ngIf="isLoadeing==='No'">{{(policySummary && policySummary.BasePremium !==undefined ? toFixed(policySummary.BasePremium, 2) : '0') | currency}} </strong> -->
          <strong *ngIf="isLoadeing === 'No'">{{
            (policySummary!=undefined && policySummary.product !== undefined ? toFixed(policySummary.product, 2) : '0') | currency
            }}
          </strong>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">{{ selectedExcessName }} {{ excessLebel }}</span>
        </div>
        <div class="col-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <!-- <strong *ngIf="isLoadeing==='No'">{{(policySummary && policySummary.ExcessPremium && policySummary.ExcessPremium !==undefined ? toFixed(policySummary.ExcessPremium, 2) : '0') | currency}}</strong> -->
          <strong *ngIf="isLoadeing === 'No'">{{
            (policySummary!=undefined && policySummary.excess !== undefined ? toFixed(policySummary.excess, 2) : '0' ) | currency }}</strong>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'"> Cruise</span>
        </div>
        <div class="col-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <strong *ngIf="isLoadeing === 'No'">{{ (policySummary != undefined && policySummary.cruise !== undefined ? toFixed(policySummary.cruise, 2) : '0' ) | currency }}</strong>
          <!-- <strong *ngIf="isLoadeing==='No'">{{(cruiseValue && cruiseValue !== undefined ? toFixed(cruiseValue, 2) : '0') | currency}}</strong> -->
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">Skiing</span>
        </div>
        <div class="col-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <strong *ngIf="isLoadeing === 'No'">{{ (policySummary!=undefined && policySummary.skiing !== undefined ? toFixed(policySummary.skiing, 2) : '0' ) | currency }}</strong>
          <!-- <strong *ngIf="isLoadeing==='No'">{{(skingValue && skingValue !==undefined ? toFixed(skingValue, 2) : '0') | currency}}</strong> -->
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">Medical Premium</span>
        </div>
        <div class="col-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <!-- <strong *ngIf="isLoadeing==='No'">{{(policySummary && policySummary.MedicalPremium !==undefined ? toFixed(policySummary.MedicalPremium, 2) : '0') | currency}}</strong> -->
          <strong *ngIf="isLoadeing === 'No'">{{ (policySummary && policySummary!=undefined && policySummary.medical && policySummary.medical !== undefined ? toFixed(policySummary.medical, 2) : '0' ) | currency }}</strong>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">Membership Discount</span>
        </div>
        <div class="col-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <!-- <strong *ngIf="isLoadeing==='No'">-{{replaceMinus((memberShipDiscountValue !== undefined ? toFixed(memberShipDiscountValue, 2) : "0").toString()) | currency}}</strong> -->
          <strong *ngIf="isLoadeing === 'No'">{{ policySummary!=undefined && policySummary.membershipDiscount && replaceMinus( (policySummary.membershipDiscount !== undefined ? toFixed(policySummary.membershipDiscount, 2) : '0' ).toString() ) | currency }}</strong>
        </div>
      </div>
    </li>
    <li class="list-group-item" *ngIf=" maxTripLengthAmountValue && maxTripLengthAmountValue != undefined && !policyAllData.isSingleTrip">
      <div class="row">
        <div class="col-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'"> {{ maxTripLengthAmountDiscrip && maxTripLengthAmountDiscrip !== undefined ? maxTripLengthAmountDiscrip : 'Max Trip Length' }} </span>
        </div>
        <div class="col-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <strong *ngIf="isLoadeing === 'No'"> {{ (maxTripLengthAmountValue && maxTripLengthAmountValue !== undefined ? toFixed(maxTripLengthAmountValue, 2) : '0' ) | currency }} </strong>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">Promo Code</span>
        </div>
        <div class="col-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <!-- <strong *ngIf="isLoadeing==='No'">-{{replaceMinus((promocodeDiscountValue !== undefined ? toFixed(promocodeDiscountValue, 2) : "0").toString()) | currency}}</strong> -->
          <strong *ngIf="isLoadeing === 'No'">{{ policySummary != null && policySummary.promoCodeDiscount && replaceMinus( (policySummary.promoCodeDiscount !== undefined ? toFixed( policySummary.promoCodeDiscount, 2 ) : '0' ).toString() ) | currency }}</strong>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">GST</span>
        </div>
        <div class="col-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <!-- <strong *ngIf="isLoadeing==='No'">{{(policySummary && policySummary.GST !== undefined ? toFixed(policySummary.GST, 2) : '0') | currency}}</strong> -->
          <strong *ngIf="isLoadeing === 'No'">{{ (policySummary !== undefined && policySummary && policySummary.gst !== undefined ? toFixed(policySummary.gst, 2) : '0' ) | currency }}</strong>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-8">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">Stamp Duty</span>
        </div>
        <div class="col-4 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <!-- <strong *ngIf="isLoadeing==='No'">{{(policySummary && policySummary.StampDuty !==undefined ? toFixed(policySummary.StampDuty, 2) : '0') | currency}}</strong> -->
          <strong *ngIf="isLoadeing === 'No'">{{ (policySummary && policySummary != undefined && policySummary.stampDuty != undefined ? toFixed(policySummary.stampDuty, 2) : '0' ) | currency }}</strong>
        </div>
      </div>
    </li>
    <li class="list-group-item" *ngIf="showAdditionalAmount">
      <div class="row">
        <div class="col-6 col-md-8">
          <span *ngIf="isLoadeing === 'Yes'"></span>
          <span class="card-gray-header-summary p-0" *ngIf="isLoadeing === 'No'">Total</span>
        </div>
        <div class="col-6 col-md-4 text-right">
          <span *ngIf="isLoadeing === 'Yes'"></span>
          <span class="card-gray-header-summary p-0" *ngIf="isLoadeing === 'No'"> {{ (policySummary != null && policySummary.totalCost !== undefined ? toFixed(policySummary.totalCost, 2) : '0' ) | currency }}</span>
        </div>
      </div>
    </li>
  </ul>
</div>
<div class="card-total" *ngIf="!showAdditionalAmount">
  <div class="row">
    <div class="col-6 col-md-8">
      <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
      <strong *ngIf="isLoadeing === 'No'">Total</strong>
    </div>

    <div class="col-6 col-md-4 text-right">
      <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
      <strong *ngIf="isLoadeing === 'No'"> {{ (policySummary && policySummary.totalCost !== undefined ? toFixed(policySummary.totalCost, 2) : '0' ) | currency }}</strong>
    </div>
  </div>
</div>
<div class="card-total" *ngIf="
    showAdditionalAmount == true &&
    additionalAmountData &&
    additionalAmountData !== undefined &&
    additionalAmountData !== null
  ">
  <div class="row">
    <div class="col-6 col-md-8">
      <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
      <strong *ngIf="isLoadeing === 'No'">Additional Amount</strong>
    </div>

    <div class="col-6 col-md-4 text-right">
      <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
      <strong *ngIf="isLoadeing === 'No'">
        {{ toFixed(additionalAmountData, 2) | currency }}</strong>
    </div>
  </div>
</div>
<ng-template #dialogContentTemplate>
  <!-- Modal body -->
  <div class="modal-body">
    <div class="mx-auto container-fluid">
      <div class="text-center mb-4 mt-4">
        <h5 class="mb-4">Add a Promo Code</h5>
      </div>
      <div *ngIf="isLoader">
        <div class="clearfix"></div>
        <div class="overlay-custom-container"></div>
        <div class="loader"></div>
      </div>
      <div class="row">
        <div class="col-md-4">&nbsp;</div>
        <div class="form-group col-md-offset-2 col-md-4">
          <select id="inputState" class="form-control" [disabled]="promoCodeInput && promoCodeInput.length > 0"
            (change)="selectionChange($event)">
            <option disabled [selected]="true">Please Select Promo Code</option>
            <option *ngFor="let item of listCopne">{{ item.promoCode }}</option>
          </select>
        </div>
      </div>
      <div class="text-center mb-2 mt-2">OR</div>
      <!-- main app container -->
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="promoCodeSearchForm" (ngSubmit)="onSubmit()" class="">
            <div class="form-row">
              <div class="form-group col-md-3">
                <input type="text" formControlName="promoCode" class="form-control" placeholder="Promo Code" [ngClass]="{
                    'is-invalid': submitted && f['promoCode'].errors
                  }" />
                <div *ngIf="submitted && f['promoCode'].errors" class="invalid-feedback">
                  <div *ngIf="f['promoCode'].errors['required']">
                    Promo code is required
                  </div>
                </div>
              </div>
              <div class="form-group col-md-3">
                <input type="text" formControlName="discountRate" class="form-control" placeholder="Discount" [ngClass]="{
                    'is-invalid': submitted && f['discountRate'].errors
                  }" />
                <div *ngIf="submitted && f['discountRate'].errors" class="invalid-feedback">
                  <div *ngIf="f['discountRate'].errors['required']">
                    Discount is required
                  </div>
                  <div *ngIf="f['discountRate'].errors['max']">
                    Discount Rate cannot be more than 99.
                  </div>
                  <div *ngIf="f['discountRate'].errors['invalidNumber']">
                    Please enter a valid discount
                  </div>
                </div>
              </div>
              <div class="form-group col-md-4">
                <div class="input-group">
                  <input class="form-control" id="effectiveDate" name="effectiveDate" formControlName="effectiveDate"
                    placeholder="Effective Date" ngbDatepicker #effectiveDate="ngbDatepicker" [ngClass]="{
                      'is-invalid': submitted && f['effectiveDate'].errors
                    }" autocomplete="off" />
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary calendar" type="button"
                      (click)="effectiveDate.toggle()"></button>
                  </div>
                </div>
                <div *ngIf="submitted && f['effectiveDate'].errors" class="invalid-feedback">
                  <div *ngIf="f['effectiveDate'].errors['required']">
                    Effective Date is required
                  </div>
                  <div *ngIf="f['effectiveDate'].errors['invalidDate']">
                    Please enter a valid date
                  </div>
                </div>
              </div>
              <!-- <div class="form-group col-auto">
                            <input type="text" formControlName="expirationDate" class="form-control" placeholder="Expiration Date"
                            [ngClass]="{ 'is-invalid': submitted && f.expirationDate.errors }" />
                            <div *ngIf="submitted && f.expirationDate.errors" class="invalid-feedback">
                                <div *ngIf="f.expirationDate.errors.required">Expiration Date is required</div>
                            </div>
                        </div> -->
              <div class="form-group col-md-2">
                <button class="btn btn-primary">Search</button>
              </div>
            </div>
          </form>
          <small *ngIf="
              submitted &&
              promoCodeSearchForm.hasError('atLeastOneRequired') &&
              searchedPromoCodeList.length == 0 &&
              noDataFound == false
            " class="text-danger text-center">{{ promoCodeSearchForm.getError('atLeastOneRequired').text }}
          </small>
        </div>
      </div>
      <br />
      <div class="row" *ngIf="searchedPromoCodeList.length > 0">
        <div class="col-md-12">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col">Promo Code</th>
                <th scope="col">Discount</th>
                <th scope="col">Effective Date</th>
                <th scope="col">Expiration Date</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of searchedPromoCodeList">
                <td scope="row">{{ item.promoCode }}</td>
                <td>{{ item.discountRate }}</td>
                <td>{{ item.effectiveDate | date: 'dd/MM/yyyy ' }}</td>
                <td>{{ item.expirationDate | date: 'dd/MM/yyyy ' }}</td>
                <td>
                  <button type="button" class="btn btn-primary btn-sm" data-dismiss="modal"
                    (click)="checkPromocodeAuthenticity('add', item.promoCode)">
                    Apply Code
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div style="color: red; text-align: center">
        <span>{{ errorMessage }}</span>
      </div>
      <div *ngIf="searchedPromoCodeList.length === 0 && noDataFound" style="color: blue; text-align: center">
        <h2>No Data Found</h2>
      </div>
    </div>
  </div>
  <div class="text-center mb-4 mt-4">
    <!-- <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal">Cancel</button> -->
    <button type="button" class="btn btn-secondary mr-2" (click)="closeModal()">
      Cancel
    </button>
  </div>
</ng-template>