import { Component, EventEmitter, inject, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ClubsDropdownComponent } from '../clubs-dropdown/clubs-dropdown.component';
import { NgForOf, NgIf } from '@angular/common';
import { filter } from 'rxjs/operators';
import { FormErrorComponent } from '../form-error/form-error.component';
import { ManageBranchesService } from '../ManageBranchs.service';
import { SharedModule } from '../shared.module';
import { SelectAddressDropdownComponent } from '../select-address-dropdown/select-address.dropdown.component';
import { BranchDetails } from '../app.model';
import { SharedService } from '../shared.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserProfile } from 'src/app/core/store/loginUser/loginUser.model';

@Component({
  selector: 'app-branch-modal',
  standalone: true,
  imports: [
    SharedModule,
    FormErrorComponent,
    ReactiveFormsModule,
    ClubsDropdownComponent,
    SelectAddressDropdownComponent,
    NgIf,
    NgForOf,
  ],
  templateUrl: './branch-modal.component.html',
  styleUrl: './branch-modal.component.css',
})
export class BranchModalComponent {
  @Input() openModalEvent!: EventEmitter<any>;
  @Output() onBranchSuccess: EventEmitter<any> = new EventEmitter<any>();

  userProfile?: UserProfile;
  private modalService = inject(NgbModal);
  branchGuid?: string;
  closeResult = '';
  addBranchForm!: FormGroup;
  selectedClub?: any;
  selectedClubCode?: string;
  submitted: boolean = false;
  action: string = 'add';
  states: any[] = [
    { id: 1, code: 'NSW', name: 'New South Wales' },
    { id: 2, code: 'QLD', name: 'Queensland' },
    { id: 3, code: 'SA', name: 'South Australia' },
    { id: 4, code: 'TAS', name: 'Tasmania' },
    { id: 5, code: 'VIC', name: 'Victoria' },
    { id: 6, code: 'WA', name: 'Western Australia' },
    { id: 7, code: 'ACT', name: 'Australian Capital Territory' },
    { id: 8, code: 'NT', name: 'Northern Territory' },
    { id: 9, code: 'CXR', name: 'Christmas Island' },
    { id: 10, code: 'CCK', name: 'Cocos (Keeling) Island' },
    { id: 11, code: 'JBT', name: 'Jervis Bay' },
    { id: 12, code: 'LHI', name: 'Lorde Howe Island' },
    { id: 13, code: 'XNS', name: 'XNone' },
  ];

  @ViewChild('addBranchModal') addBranchModal!: TemplateRef<any>;

  constructor(private manageBranchService: ManageBranchesService, private sharedService: SharedService,
    private store: Store
  ) {
  }

  ngOnInit(): void {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userProfile = loginUser.userProfile;
      }
    });
    if (this.openModalEvent) {
      this.openModalEvent.subscribe((data: any) => {
        this.action = data.action;
        this.addBranchForm = this.manageBranchService.addBranch();

        if (this.action === 'edit') {
          this.populate(data.data);
        } else {

        }

        this.open(this.addBranchModal);
      });
    }
  }

  open(content: TemplateRef<any>) {
    this.submitted = false;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  populate(data: any) {
    // maybe fetch data from branch service?
    // missing email, fax, ABN and suburb is blank

    this.branchGuid = data.branchGuid;

    let state = this.states.find(state => state.id == data.stateId);

    const addressGroup = this.getAddressGroup();
    addressGroup.get('StreetAddress')?.setValue(data.address);
    addressGroup.get('StateCode')?.setValue(state.code);
    addressGroup.get('ZipCode')?.setValue(data.postcode);
    addressGroup.get('City')?.setValue(data.suburb);

    const phoneGroup = this.getPhoneGroup();
    phoneGroup.get('PhoneNum')?.setValue(data.phoneNumber);

    const faxGroup = this.getFaxGroup();
    faxGroup.get('PhoneNum')?.setValue(data.faxNumber);

    const emailGroup = this.getEmailAccounts();
    emailGroup.get('EmailAddress')?.setValue(data.emailAddress);

    this.addBranchForm.patchValue({
      GTAContact: {
        selectAutoClub: data.autoClubGuid,
        OrgFullName: data.name,
        Addresses: [addressGroup.value],
        PhoneNumbers: [phoneGroup.value],
        Fax: [faxGroup.value],
        EmailAccts: [emailGroup.value],
        ABN: data.abn,
      },
    });
    this.selectedClubCode = data.autoClubGuid;
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  onAddressSelected(addressData: any) {
    const addressGroup = this.getAddressGroup();
    addressGroup.get('StreetAddress')?.setValue(addressData.StreetLine);
    addressGroup.get('StateCode')?.setValue(addressData.State);
    addressGroup.get('ZipCode')?.setValue(addressData.Postcode);
    addressGroup.get('City')?.setValue(addressData.Suburb);
  }

  onSubmit() {
    this.submitted = true;
    const validateFields = [
      'GTAContact.OrgFullName',
      'GTAContact.Addresses.0.StreetAddress',
      'GTAContact.Addresses.0.City',
      'GTAContact.Addresses.0.StateCode',
      'GTAContact.Addresses.0.ZipCode',
      'GTAContact.PhoneNumbers.0.PhoneNum',
      'GTAContact.Fax.0.PhoneNum',
      'GTAContact.EmailAccts.0.EmailAddress',
      'GTAContact.ABN',
    ];

    this.markRequiredControlsAsTouched(this.addBranchForm, validateFields);

    if (!this.validateSpecificFields(this.addBranchForm, validateFields)) {
      return;
    }

    let loggedUser = this.userProfile ? this.userProfile.LoggedUser.toUpperCase() : '';
    let params: BranchDetails = {
      autoClubGuid: this.selectedClub.autoClubGuid,
      name: this.addBranchForm.value.GTAContact.OrgFullName,
      address: this.addBranchForm.value.GTAContact.Addresses[0].StreetAddress,
      city: this.addBranchForm.value.GTAContact.Addresses[0].City,
      suburb: this.addBranchForm.value.GTAContact.Addresses[0].City,
      stateId: 1,
      postCode: this.addBranchForm.value.GTAContact.Addresses[0].ZipCode,
      phoneNumber: this.addBranchForm.value.GTAContact.PhoneNumbers[0].PhoneNum,
      faxNumber: this.addBranchForm.value.GTAContact.Fax[0].PhoneNum,
      emailAddress: this.addBranchForm.value.GTAContact.EmailAccts[0].EmailAddress,
      abn: this.addBranchForm.value.GTAContact.ABN,
      modifiedBy: loggedUser,
    };

    if (this.action === 'edit') {
      params.branchGuid = this.branchGuid;
    }

    this.manageBranchService.saveBranch(params).subscribe((resp) => {
      this.submitted = false;
      this.sharedService.emitRefreshTable();
      this.modalService.dismissAll();
      this.addBranchForm.reset();

      let message = this.action === 'add' ? 'Branch has been added successfully...' : 'Branch has been updated successfully...';

      this.onBranchSuccess.emit(message);
    });
  }

  // check specific fields, not all initialized
  validateSpecificFields(formGroup: FormGroup, fields: string[]): boolean {
    let isValid = true;
    fields.forEach(field => {
      const control = this.getNestedControl(formGroup, field);
      if (control && control.invalid) {
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
        isValid = false;
      }
    });
    return isValid;
  }

  markRequiredControlsAsTouched(formGroup: FormGroup, fields: string[]): void {
    fields.forEach(field => {
      const control = this.getNestedControl(formGroup, field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
      } 
    });
  }

  getNestedControl(formGroup: FormGroup, path: string): AbstractControl | null {
    const paths = path.split('.');
    let control: AbstractControl | null = formGroup;
    for (const p of paths) {
      if (control instanceof FormGroup) {
        control = control.get(p);
      } else if (control instanceof FormArray) {
        const index = Number(p);
        control = control.at(index);
      }
    }
    return control;
  }

  onClubChanged(club: any) {
    this.selectedClub = club;
  }

  isInvalid(controlName: string): boolean {
    const control = this.getNestedControl(this.addBranchForm, controlName);
    return control ? this.submitted && control.invalid && (control.dirty || control.touched) : false;
  }

  getAddressGroup(): FormGroup {
    return (this.addBranchForm.get('GTAContact.Addresses') as FormArray).at(0) as FormGroup;
  }

  getPhoneGroup(): FormGroup {
    return (this.addBranchForm.get('GTAContact.PhoneNumbers') as FormArray).at(0) as FormGroup;
  }

  getFaxGroup(): FormGroup {
    return (this.addBranchForm.get('GTAContact.Fax') as FormArray).at(0) as FormGroup;
  }

  getEmailAccounts(): FormGroup {
    return (this.addBranchForm.get('GTAContact.EmailAccts') as FormArray).at(0) as FormGroup;
  }

  protected readonly filter = filter;
}
