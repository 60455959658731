import * as policies from '../../../assets/configurations/policy.json';
import * as quotes from '../../../assets/configurations/quotes.json';
import * as promocode from '../../../assets/configurations/promocode.json';
import * as branches from '../../../assets/configurations/branches.json';
import * as states from 'src/assets/configurations/masterdata/states.json';
import * as medicalLabeling from 'src/assets/configurations/masterdata/medicallabeling.json';
import * as validation from 'src/assets/configurations/masterdata/validation.json';
import * as cancelReasons from 'src/assets/configurations/masterdata/cancelReason.json';
import * as products from 'src/assets/configurations/masterdata/products.json';
import * as productLabeling from 'src/assets/configurations/masterdata/productLabeling.json';
import * as themes from 'src/assets/configurations/masterdata/themecolor.json';

export const urls = [
  {
      url: '/PASDataService/DataTable/?gridname=Policy',
      lastParams: 'gridname=Policy',
      json: policies
  },
  {
    url: '/PASDataService/DataTable/?gridname=Quotes',
    lastParams: 'gridname=Quotes',
    json: quotes
  },
  {
    url: '/PASDataService/DataTable/',
    lastParams: 'gridname=PromoCode',
    json: promocode
  },
  {
    url: '/PASDataService/DataTable/',
    lastParams: 'gridname=ManageBranches',
    json: branches
  },
  {
    url: '/PASDataService/StaticList/',
    lastParams: 'States',
    json: states
  },
  {
    url: '/PASDataService/StaticList/',
    lastParams: 'MedicalLabeling',
    json: medicalLabeling
  },
  {
    url: '/PASDataService/StaticList/',
    lastParams: 'ProductLabeling',
    json: productLabeling
  },
  {
    url: '/PASDataService/ValidationMessages/',
    lastParams: 'Validation',
    json: validation
  },
  {
    url: '/PASDataService/Configurations/',
    lastParams: 'Products',
    json: products
  },
  {
    url: '/PASDataService/ColorPicker/',
    lastParams: 'Theme',
    json: themes
  },
  {
    url: '/PASDataService/CancelReasonList/',
    lastParams: 'CancelReasons',
    json: cancelReasons
  },
];