import { Component, OnInit, ViewChild, TemplateRef, ElementRef, } from '@angular/core';
import { PolicyDetailService } from '../app.services';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceService } from 'src/app/core/services/price.service';
import { DataBusService } from '../services/databus.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { Store } from '@ngrx/store';
import { isPolicyDataPresent,selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';


@Component({
  selector: 'policyDetails-policy-details-version',
  templateUrl: './policy-details-versions.component.html',
  styleUrls: ['policy-details-versions.component.css'],
})
export class PolicyDetailsVersionComponent implements OnInit {
  policyId!: string;
  policyMovement: any[] = [];
  premiumChanged = '';
  movementGuid: any;
  modalRef!: NgbModalRef;
  isLoader: boolean = false;
  versionData: any;
  @ViewChild('dialogContentTemplate', { static: true })
  dialogContent!: TemplateRef<ElementRef>;
  isVerionCollapsed = false;
  paymentType: any;
  paymentStatus: any;
  paymentAmount: any;
  paymentConfirmation: any;
  paymentObj: any;
  uwData1: any;
  versionNum: any;
  policyGuid!: string;
  public currentPolicyInStore : any;
  public IsPolicyInStore: boolean  = false;

  constructor(
    private policyService: PolicyDetailService,
    private modalService: NgbModal,
    private priceService: PriceService,
    private dataBusService: DataBusService,
    private loaderService: LoaderService,
    private logService: LogService,
    private store: Store,
    private storeService : StoreGenericService
  ) {}
  ngOnInit() {

    //Policy Store
    this.store.select(isPolicyDataPresent).subscribe(present => {
      this.IsPolicyInStore = present;
    });

    this.store.select(selectCurrentPolicy).subscribe(pol =>{
      this.currentPolicyInStore = pol;
      if(pol != null)
      {
        this.populatePolicyData(this.currentPolicyInStore);
      }
    });
  }

  openModal( type: string, movementGuid: string, version: number, premium: string ) {
    this.premiumChanged = type !== 'NB' ? premium : '';
    this.movementGuid = movementGuid;
    this.versionNum = version;
    this.policyGuid = this.policyId;
    this.loaderService.show();
    sessionStorage.setItem('IsPolicyVersionSelected', 'true');
    sessionStorage.setItem('hideAdditional', 'true');
    this.policyService.getVersionData(this.policyId, movementGuid).subscribe(
      (data: any) => {
        this.loaderService.hide();
        this.versionData = data;
        this.storeService.setPolicyToStore(data);
        this.modalRef = this.modalService.open(this.dialogContent, {
          size: 'xl',
          backdrop: 'static',
          keyboard: false,
        });
      },
      (err) => {
        this.loaderService.hide();
      },
    );
  }

  closeModal() {
    this.loaderService.show();
    let status = sessionStorage.getItem('cancelStatus');
    if (status == 'true') {
      sessionStorage.setItem( 'movementGuid', sessionStorage.getItem('originalMovementGuid') || '', );
    }
    this.policyService.getVersionData(this.policyId, sessionStorage.getItem('originalMovementGuid')).subscribe(
      (data: any) => {
        if(data){
          this.storeService.setPolicyToStore(data);
        }
        this.loaderService.hide();
        sessionStorage.setItem('hideAdditional', 'false');
        sessionStorage.setItem('policyUpdateClick', 'true');
        sessionStorage.removeItem('IsPolicyVersionSelected');
      });
    this.modalRef.close();
  }

  formatprice(value: any) {
    let formattedvalue = this.priceService.getPriceFixed(value, 2);
    return formattedvalue;
  }
  getPriceFixed(num: any, fixed: any) {
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  //LTX
  populatePolicyData(currentPolicyInStore: any) {
    if(currentPolicyInStore.movements && currentPolicyInStore.movements.length > 0){
      let allMovements = currentPolicyInStore.movements;
      let filteredMovement = (allMovements.length > 0) ? allMovements.filter( (x: { status: string; }) => x.status != "Pending") : allMovements;
      let sortedMovements = filteredMovement.sort(function (a: { version: number }, b: { version: number }) { return a.version - b.version });
      this.policyMovement = sortedMovements;
    }
  }

  openPolicyDetailsVersion() {
    this.policyId = sessionStorage.getItem('policyGuid') || ''; 
    this.isVerionCollapsed = !this.isVerionCollapsed;
  }
}
