import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PolicyDetailService } from '../app.services';
import { LoaderService } from 'src/app/core/services/loader.service';

@Injectable({
  providedIn: 'root',
})
export class DataBusService {

  policyGuid: string;
  // policyTxnId: string;
  originalPolicyLoading = false;
  currentPolicyLoading = false;
  currentPolicyId: any = null;
  public _policyDefaultData = <any>{ value: 'Default Data' };
  public policyDataSource = new BehaviorSubject(this._policyDefaultData);
  policyData = this.policyDataSource.asObservable();

  public originalPolicyDataSource = new BehaviorSubject(
    this._policyDefaultData,
  );
  originalPolicyData = this.originalPolicyDataSource.asObservable();

  constructor(
    private httpService: PolicyDetailService,
    private loaderService: LoaderService,
  ) {
    this.policyGuid = sessionStorage.getItem('policyGuid') || '';
  }

  publishPolicyDetails(isLoader = false, movementGuid?: any) {
    if (!this.currentPolicyLoading) {
      this.currentPolicyLoading = true;
      this.currentPolicyId = sessionStorage.getItem('policyGuid') || '';
      this.httpService.getData(this.currentPolicyId, movementGuid).subscribe((resp) => {
        if (isLoader) {
          this.loaderService.hide();
        }
        if (resp && resp.movements.length > 0) {
          var sortedMovements = resp.movements.sort(function (a: { version: number }, b: { version: number }) { return a.version - b.version });
          let nBMovementGuid = sortedMovements.findLast((x: { transactionStatus: string; }) => x.transactionStatus == "IN" || x.transactionStatus == "CN")?.movementId;
          if (nBMovementGuid == undefined) {
            nBMovementGuid = sortedMovements.findLast((x: { type: string; }) => x.type == "NB")?.movementId
          }
          sessionStorage.setItem('originalMovementGuid', nBMovementGuid);
        }
        sessionStorage.setItem('movementGuid', resp.movementGuid);

        this.policyDataSource.next(resp);
        this.currentPolicyLoading = false;
      });
    }
  }
  policyVersionData(data: any) {
    this.policyDataSource.next(data);
  }

  updatePolicyData(data: any) {
    this.policyDataSource.next(data);
  }

  setOriginalPolicyData(data: any) {
    if (sessionStorage.getItem('originalMovementGuid') === sessionStorage.getItem('movementGuid')
    ) {
      this.originalPolicyDataSource.next(data);
    }
  }

  setOriginalPolicyDataNew(isCoi = false) {
    // mandatory file to get original data before endorsement payment completed
    const originalTxnId = sessionStorage.getItem('originalMovementGuid');
    const originalTxnData = sessionStorage.getItem('originalPolicyTxnData');
    if (!this.originalPolicyLoading) {
      this.originalPolicyLoading = true;
      if (
        originalTxnData &&
        this.checkOriginalTxnId(originalTxnId, originalTxnData)
      ) {
        let resp = JSON.parse(originalTxnData);
        if (isCoi) {
          if (resp.TxnTypeCode && resp.PolicyTypeId) {
            this.httpService.coiObj.next({
              typeId: resp.PolicyTypeId,
              business: resp.TxnTypeCode,
            });
          }
        }
        this.originalPolicyDataSource.next(resp);
        this.originalPolicyLoading = false;
      } else {
        this.httpService.getData(originalTxnId).subscribe((resp) => {
          if (isCoi) {
            if (resp.TxnTypeCode && resp.PolicyTypeId) {
              this.httpService.coiObj.next({
                typeId: resp.PolicyTypeId,
                business: resp.TxnTypeCode,
              });
            }
          }
          sessionStorage.setItem('originalPolicyTxnData', JSON.stringify(resp));
          this.originalPolicyDataSource.next(resp);
          this.originalPolicyLoading = false;
        });
      }
    }
  }

  checkOriginalTxnId(id: any, policySessionData: any) {
    if (id && policySessionData) {
      let data = JSON.parse(policySessionData);
      if (id == data.PolicyTxnId.toString()) {
        return true;
      }
    }
    return false;
  }

  setOriginalPolicyDataFromResponse(isCoi = false, resp: any) {
    sessionStorage.setItem('originalPolicyTxnData', JSON.stringify(resp));
    if (isCoi) {
      if (resp.TxnTypeCode && resp.PolicyTypeId) {
        this.httpService.coiObj.next({
          typeId: resp.PolicyTypeId,
          business: resp.TxnTypeCode,
        });
      }
    }
    this.originalPolicyDataSource.next(resp);
  }

  mediatorForPublishPolicy(isLoader = false) {
    // this.currentPolicyId = sessionStorage.getItem("movementGuid");
    if (
      this.currentPolicyId != null &&
      sessionStorage.getItem('movementGuid') &&
      this.currentPolicyId == sessionStorage.getItem('movementGuid')
    ) {
      // already processed
    } else {
      this.publishPolicyDetails(isLoader);
    }
  }

}
