import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PolicyDetailService } from '../app.services';

@Injectable({
  providedIn: 'root',
})
export class MessageBusService {
  private message = new BehaviorSubject(false);
  messageData = this.message.asObservable();

  constructor(private httpService: PolicyDetailService) {
    //this.policyTxnId = sessionStorage.getItem("policytxnid");
  }

  publishMessage(flag: any) {
    this.message.next(flag);
  }
}
