import { FormGroup, AbstractControl } from '@angular/forms';

export class customValidator {
  static MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  static ageRangeValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      let yearDiff = new Date().getFullYear() - control.value.year;
      if (control.value !== undefined && (yearDiff <= 17 || yearDiff > 75)) {
        return { ageRange: true };
      }
      return null;
    }
  }

  static ageRangeValidatorForDependent(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      if (typeof control.value !== 'object') {
        return { invalidNumber: true };
      }
      const yearDiff = new Date().getFullYear() - control.value.year;
      if (
        control.value !== undefined &&
        ((yearDiff < 1 && yearDiff != 0) || yearDiff > 25)
      ) {
        return { ageRange: true };
      }
      return null;
    }
  }

  static onlyTenNumbers(number: any): any {
    if (number.pristine) {
      return null;
    }

    if (
      number.value == '' ||
      number.value == null ||
      number.value == undefined
    ) {
      return null;
    }

    const pattern = /^[0-9]{10}$/;
    number.markAsTouched();

    let val = number.value.replace(/[^a-zA-Z0-9]/g, '');
    if (pattern.test(val)) {
      return null;
    }
    return {
      invalidNumber: true,
    };
  }

  static onlyElevenNumbers(number: any): any {
    if (number.pristine) {
      return null;
    }

    if (
      number.value == '' ||
      number.value == null ||
      number.value == undefined
    ) {
      return null;
    }
    const pattern = /^[0-9]{11}$/;
    number.markAsTouched();

    if (pattern.test(number.value)) {
      return null;
    }
    return {
      invalidNumber: true,
    };
  }

  // Validates phone number
  static numberValidator(number: any): any {
    if (number.pristine) {
      return null;
    }

    //  const NUMBER_REGEXP = /^\d{9}$/;
    //  const NUMBER_REGEXP = /^([0-9]{9})$/;
    const NUMBER_REGEXP = /^([1,2,3,4,5,7,8,9])([0-9]{8})$/;
    const NUMBER_REGEXP_1 = /^(0)([0-9]{9})$/;
    const NUMBER_REGEXP_2 = /^(61)([0-9]{9})$/;
    number.markAsTouched();

    if (NUMBER_REGEXP_1.test(number.value)) {
      return null;
    } else if (NUMBER_REGEXP_2.test(number.value)) {
      return null;
    }
    // } else if ( NUMBER_REGEXP.test(number.value)) {
    //     return null;
    // }
    return {
      invalidNumber: true,
    };
  }

  static DateValidator(dateInput: any): any {
    // const Date_Regex = /(\d{4})-(\d{1,2})-(\d{1,2})/;
    const Date_Regex =
      /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
    if (dateInput.pristine) {
      return null;
    }
    if (dateInput.value != null && dateInput.value !== undefined) {
      dateInput.markAsTouched();
      if (dateInput.value != null && typeof dateInput.value === 'object') {
        return null;
      }

      if (Date_Regex.test(dateInput.value)) {
        return null;
      }
      return {
        invalidDate: true,
      };
    }
    return null;
  }

  static conditionalValidation(
    firstControlName: string,
    secondControlName: string,
    thirdControlName: string,
  ) {
    return (formGroup: FormGroup) => {
      const first = formGroup.controls[firstControlName];
      const second = formGroup.controls[secondControlName];
      const third = formGroup.controls[thirdControlName];
      // set error on matchingControl if validation fails
      if (first.value || second.value || third.value) {
        if (!first.value) {
          first.setErrors({ required: true });
        }
        if (!second.value) {
          second.setErrors({ required: true });
        }
        if (!third.value) {
          third.setErrors({ required: true });
        }
      } else {
        first.setErrors(null);
        second.setErrors(null);
        third.setErrors(null);
      }
    };
  }

  // changes by PRS [for blank function]
  static requiredWithoutBlank(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      if (control.value.trim().length > 0) {
        return null;
      } else {
        return { required: true };
      }
    }
  }

  public static onlyAlphabets(event: any) {
    const pattern = /^[a-zA-Z]+$/i;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
