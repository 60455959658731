import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { LocalCacheService } from './localCache.service';
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private loadingMaskFeatures: string[] = [];
  showLoadingMask = false;

  private emailUrl = `https://ca-dev-tmma-ltx-api.delightfulocean-5e7289f6.australiaeast.azurecontainerapps.io/api/quote/document/email`;

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(
    private http: HttpClient,
    private localCacheService: LocalCacheService,
  ) {}

  showLoading(featureName: string) {
    this.loadingMaskFeatures.push(featureName);
    console.log('showLoading', this.loadingMaskFeatures);
    if (this.loadingMaskFeatures.length > 0) {
      this.showLoadingMask = true;
    }
  }

  hideLoading(featureName: string) {
    const newLoadingList = this.loadingMaskFeatures.filter(
      (feature) => feature !== featureName,
    );
    this.loadingMaskFeatures = newLoadingList;
    console.log('hideLoading', this.loadingMaskFeatures);
    if (this.loadingMaskFeatures.length === 0) {
      this.showLoadingMask = false;
    }
  }

  clearLoading() {
    this.loadingMaskFeatures = [];
    this.showLoadingMask = false;
  }

  sendEmail(emailId: any): Observable<any> {
    this.showLoading('sendEmail');
    const emailRequest = {
      quoteId:
        this.localCacheService.getSessionStorage('quoteDetail')?.quoteId ?? '',
      email: emailId,
    };
    return this.http
      .post<any>(this.emailUrl, emailRequest, this.httpOptions)
      .pipe(
        tap((_) => {
          this.hideLoading('sendEmail');
          console.log('Email sent');
        }),
        catchError(this.handleError<any>('sendEmail')),
      );
  }

  getCurrentAssessmentDetails(): any {
    try {
      const assessmentDetails =
      this.localCacheService.getSessionStorage('assessmentDetails');
      const currentAssessment =
        this.localCacheService.getSessionStorage('currentAssessment');
      if (currentAssessment === 'primary' || currentAssessment === 'secondary') {
        return assessmentDetails[currentAssessment];
      } else if (currentAssessment === 'dependent') {
        const travelerGuid = this.localCacheService.getSessionStorage('veriskTravelerGuid');
        const targetDependent = assessmentDetails.dependent.find((traveler: any) => traveler.travelerId === travelerGuid);
        if (targetDependent) {
          return targetDependent
        } else {
          return {};
        }
    }
    return {};
    } catch(e) {
      return null;
    }
  }

  getCurrentAssessmentName(): string {
    const currentAssessment =
      this.localCacheService.getSessionStorage('currentAssessment');
    if (currentAssessment === 'primary') {
      return (
        this.localCacheService.getSessionStorage('travelerDetails').adult1
          .lastName +
        ' ' +
        this.localCacheService.getSessionStorage('travelerDetails').adult1
          .firstName
      );
    } else if (currentAssessment === 'secondary') {
      return (
        this.localCacheService.getSessionStorage('travelerDetails').adult2
          .lastName +
        ' ' +
        this.localCacheService.getSessionStorage('travelerDetails').adult2
          .firstName
      );
    } else if (currentAssessment === 'dependent') {
      const dependentIndex =
        this.localCacheService.getSessionStorage('dependentIndex');
      return (
        this.localCacheService.getSessionStorage('travelerDetails').dependents[
          dependentIndex
        ].lastName +
        ' ' +
        this.localCacheService.getSessionStorage('travelerDetails').dependents[
          dependentIndex
        ].firstName
      );
    }
    return '';
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  public handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      this.clearLoading();

      throwError(
        () => new Error(`Custom error message. Status code: ${error.status}`),
      );
      // Let the app keep running by returning an empty result.
      return of({
        error: true,
        errorMessage: `An error occurred with status code: ${error.status}`,
      } as T);
    };
  }

  /** Log a CountryService message with the MessageService */
  private log(message: string) {
    console.log(`CountryService: ${message}`);
  }
}
