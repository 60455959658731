import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CancelEventEmitterService {
  private source1 = new Subject<any>();
  private source2 = new Subject<any>();
  public refundEvent$ = this.source1.asObservable();
  public paymentEvent$ = this.source2.asObservable();
  invokeFirstComponentFunction = new EventEmitter();
  subsVar!: Subscription;

  constructor() {}

  onClick() {
    this.source1.next({});
    this.source2.next({});
  }
}
