import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule, HttpHandler, } from '@angular/common/http';
import { DateService } from '../../../../src/app/core/services/date-updated.service';
import { SetQueryParamsService } from 'src/app/core/role/setqueryparams';
import { Observable } from 'rxjs';
import { EndPoint } from 'src/app/core/services/endpoint';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { BehaviorSubject } from 'rxjs';
import { CxOneModel } from 'src/app/core/cxone/cxone.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentGatewayService {

  userRoleDetails?: UserRoleDetails;
  private cxOneCallStatus = new BehaviorSubject<CxOneModel>({ isCallEnabled :  false , maskCallTriggered : false});

  cxOneCallStatus$ = this.cxOneCallStatus.asObservable();

  constructor(
    private http: HttpClient,
    private handler: HttpHandler,
    private httpCli: HttpClientModule,
    private newDateService: DateService,
    private setQueryParamsService: SetQueryParamsService,
    private store: Store,
  ) {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
      }
    })
  }

  utcToAestDate(utcDate: string) {
    let aestTime = this.newDateService.getDateAsOfAESTFromDate(
      this.newDateService.getMomentFromDate(utcDate),
    );
    return new Date(aestTime);
  }

  bindEndorsement(request: any): Observable<any> {
    let httpHeaders = {
      headers: new HttpHeaders({
      }),
    };
    return this.http.post(`${EndPoint.PAS_BIND_EN}`, request, httpHeaders);
  }

  updateCxOneCallStatus(cxOneModel: CxOneModel) {
    this.cxOneCallStatus.next(cxOneModel);
  }
}
