import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, } from '@angular/forms';
import { EndPoint } from '../../../../src/app/core/services/endpoint';
import { DateService } from 'src/app/core/services/date-updated.service';
import { SetQueryParamsService } from 'src/app/core/role/setqueryparams';
import { PortalCodeEnum } from 'src/app/core/enum/sitename-enum';

@Injectable({
  providedIn: 'root',
})
export class PolicyPaymentsService {
  // issueEndorsementGTA = new IssueEndorsementGTA();
  public portalCodeEnum: any;

  constructor(
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    private newDateService: DateService,
    private setQueryParamsService: SetQueryParamsService,
  ) {
    this.portalCodeEnum = PortalCodeEnum;
  }

  httpHeaders = {
    headers: new HttpHeaders({
    }),
  };

  policyPaymentsReactiveForm() {
    return this._formBuilder.group({
      Payments: this._formBuilder.array([this.createPolicyPayments()]),
    });
  }

  getPolicyDetailbyId(policyGuid: string, movementGuid: string) {
    return this.http.get<any>( EndPoint.PAS_POLICY_MOVEMENT + '/' + policyGuid.toString() + '?movementGuid=' + movementGuid.toString(), this.httpHeaders);
  }

  createPolicyPayments() {
    if (sessionStorage.getItem('portalcode') == this.portalCodeEnum.RACQ) {
      return new FormGroup({
        PaymentType: new FormControl('CC', Validators.nullValidator),
      });
    } else {
      return new FormGroup({
        PaymentType: new FormControl('', Validators.required),
      });
    }
  }

  createPaymentRecieptForm(){
    var form =  this._formBuilder.group({ items : this._formBuilder.array([]), })
    const controlArray = (<FormArray>( form.get('items') )) as FormArray;
    controlArray.push(this.createItem());
    return form;
  }

  createItem(): FormGroup {
    return this._formBuilder.group({
      CashReceiptNumber: [
        ''
      ]
    });
  }

  // savePaymentRecieptIssueReinstatementGTARequest(
  //   issueReinstatementGTA: IssueReinstatementGTA,
  // ): Observable<any> {
  //   return this.http.put<any>(
  //     EndPoint.OCTAAPI_ENDPOINT + 'IssueReinstatementGTA',
  //     issueReinstatementGTA,
  //     this.httpHeaders,
  //   );
  // }

  // savePaymentRecieptRewriteGTARequest(
  //   issueRewriteGTA: IssueRewriteGTA,
  // ): Observable<any> {
  //   issueRewriteGTA.FormSetId = '10728';
  //   return this.http.put<any>(
  //     EndPoint.OCTAAPI_ENDPOINT + 'IssueRewriteGTA',
  //     issueRewriteGTA,
  //     this.httpHeaders,
  //   );
  // }

  // paymentData() {
  //   return new FormGroup({
  //     MethodType: new FormControl(''),
  //     CashReceiptNumber: new FormControl('', Validators.compose([
  //       Validators.required
  //     ])),
  //     Amount: new FormControl('', Validators.required),
  //     PaymentStatus: new FormControl(''),
  //   });
  // }

  // savePaymentRecieptRequest(
  //   issueEndorsementGTA: IssueEndorsementGTA,
  // ): Observable<any> {
  //   let agentDetailsObj =
  //     this.setQueryParamsService.getAgentDetailsForAPIRequest();

  //   issueEndorsementGTA.FormSetId = CommonParams.FormSetId;
  //   issueEndorsementGTA.BranchName = ClubAndBranchInfo.BranchName;

  //   issueEndorsementGTA.CreateActivityLogItem.PolicyTxnId =
  //     issueEndorsementGTA.PolicyTxnId.toString();
  //   issueEndorsementGTA.CreateActivityLogItem.ActivityType =
  //     'issueEndorsementGTA';
  //   issueEndorsementGTA.CreateActivityLogItem.ActivityDate =
  //     CommonParams.ActivityDate;
  //   issueEndorsementGTA.CreateActivityLogItem.Description =
  //     'issueEndorsementGTA';
  //   issueEndorsementGTA.CreateActivityLogItem.LoggedInUser =
  //     UserProfile.LoggedUser;
  //   issueEndorsementGTA.AgentDetails = agentDetailsObj.AgentDetails;

  //   return this.http.put<any>(
  //     EndPoint.OCTAAPI_ENDPOINT + 'IssueEndorsementGTA',
  //     issueEndorsementGTA,
  //     this.httpHeaders,
  //   );
  // }

  // getSiteCoreAgencyTotalList() {
  //   return this.http.get(EndPoint.API_ENDPOINT + '/PASDataService/DataTable');
  // }

  // resendCoi(objReqData: object): Observable<any> {
  //   let httpHead = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json; charset=utf-8',
  //     }),
  //   };
  //   return this.http.put<any>(
  //     `${EndPoint.OCTAAPI_ENDPOINT}ProcessFormSet`,
  //     objReqData,
  //     httpHead,
  //   );
  // }

  utcToAestDate(utcDate: string) {
    let aestTime = this.newDateService.getDateAsOfAESTFromDate(
      this.newDateService.getMomentFromDate(utcDate),
    );
    return new Date(aestTime);
  }

  // createActivityLogItem(activityType: any, activityDescription: any) {
  //   let current = new Date();
  //   current = new Date(this.utcToAestDate(current.toUTCString()));

  //   let reqObj = {
  //     PolicyTxnId: sessionStorage.getItem('movementGuid'),
  //     ActivityType: activityType,
  //     ActivityDate: current,
  //     Description: activityDescription,
  //     LoggedInUser: UserProfile.LoggedUser,
  //   };

  //   return this.http.post(
  //     `${EndPoint.OCTAAPI_ENDPOINT}CreateActivityLogItem`,
  //     reqObj,
  //     this.httpHeaders,
  //   );
  // }

  // getPolicyStatus(policyId: string, policyTxnNum: Number) {
  //   return this.http.get<any>(
  //     EndPoint.OCTAAPI_ENDPOINT +
  //       'GetPolicyStatus/' +
  //       policyId +
  //       '/' +
  //       policyTxnNum,
  //     this.httpHeaders,
  //   );
  // }

  getPolicyCompairData(id: any) {
    return this.http.get<any>(
      EndPoint.LTX_ENDPOINT + EndPoint.CompareEndorsment + id.toString(),
      this.httpHeaders,
    );
  }

  bindEndorsement(request: any): Observable<any> {
    return this.http.post(`${EndPoint.PAS_BIND_EN}`, request, this.httpHeaders);
  }

}
