import {
  Component,
  ViewContainerRef,
  Renderer2,
  OnInit,
  OnDestroy,
  Inject,
  AfterViewInit,
  NgZone,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { EndPoint } from 'src/app/core/services/endpoint';
import { LocalCacheService } from './localCache.service';
import { GlobalService } from './global.service';
import { MedicalService } from './medical.service';
import { MedicalPremium } from './verisk.model';
import { NgxXml2jsonService } from 'ngx-xml2json';
import { environment } from 'src/environments/environment';

interface AngularComponentReference {
  component: any;
  zone: any;
  loadAngularFunction: (eventType: any, result: any, status: any) => void;
}

declare global {
  interface Window {
    angularComponentReference?: AngularComponentReference;
    AUTH_DATA: { uri: string };
  }
}

@Component({
  selector: 'verisk',
  templateUrl: './verisk.component.html',
  styleUrls: ['./verisk.component.scss'],
})
export class VeriskComponent implements OnInit, AfterViewInit {
  requestObj: any;
  link: any;
  script: any;
  isWidgetEnabled: boolean = false;
  private siteName = sessionStorage.getItem('portalcode') || '';
  // private scriptTag: HTMLScriptElement;

  constructor(
    public dialogRef: MatDialogRef<VeriskComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private renderer: Renderer2,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private document: Document,
    private el: ElementRef,
    private localCacheService: LocalCacheService,
    private medicalService: MedicalService,
    private globalService: GlobalService,
    private ngxXml2jsonService: NgxXml2jsonService,
  ){
    this.siteName = (this.siteName != '') ? this.siteName : sessionStorage.getItem('portalcode') || '';
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onFinishVerisk(eventType: any, result: any, status: string) {
    if (status == 'success') {
      this.onSuccessVerisk(eventType, result);
    } else {
      this.onErrorVerisk(eventType, result);
    }
  }
  onSuccessVerisk(eventType: any, result: any) {
    switch (eventType) {
      case 'COMPLETE_SCREENING':
        const resultObject = JSON.parse(decodeURIComponent(result));
        const detail = this.globalService.getCurrentAssessmentDetails();
        const travelerId = this.localCacheService.getSessionStorage('veriskTravelerGuid');
        const movementGuid = sessionStorage.getItem('movementGuid');
        if (!movementGuid) {
          return;
        }
        const medicalPremium: MedicalPremium = {
          movementGuid: movementGuid,
          travelerGuid: travelerId,
          xmlResult: resultObject[0].Result,
          isUpdated: true,
        };
        
        this.medicalService
          .addMedicalPremium(medicalPremium)
          .subscribe((response) => {
            response.xml = medicalPremium.xmlResult;
            this.localCacheService.saveSessionStorage(
              'medicalPremium',
              response,
            );
            detail.xmlResult = resultObject[0].Result;
            detail.medicalPremium = response;
            const assessmentDetails =
              this.localCacheService.getSessionStorage('assessmentDetails');
            const currentAssessment =
              this.localCacheService.getSessionStorage('currentAssessment');
            if (
              currentAssessment === 'primary' ||
              currentAssessment === 'secondary'
            ) {
              assessmentDetails[currentAssessment] = detail;
            } else if (currentAssessment === 'dependent') {
              assessmentDetails.dependent.map((dependent: any) => {
                if (dependent.travelerId === travelerId) {
                  dependent.xmlResult = detail.xmlResult;
                  dependent.medicalPremium = detail.medicalPremium;
                }
              });
            }
            this.localCacheService.saveSessionStorage(
              'assessmentDetails',
              assessmentDetails,
            );
            
            this.dialogRef.close(response);
          });
        break;
      case 'SAVE_SCREENING':
        console.log(`Save screening: ${result}`);
        break;
      default:
        console.log(eventType + ' ' + result);
        break;
    }
    return result;
  }
  onErrorVerisk(eventType: any, errors: any) {
    switch (eventType) {
      case 'SAVE_SCREENING':
        for (var error of errors) {
          switch (error.code) {
            case 'SAVE_SCREENING_INVALID':
              console.log(`Save screening invalid: ${error.detail}`);
              this.dialogRef.close(error);
              break;
            case 'SAVE_SCREENING_UNEXPECTED':
              console.log(`Save screening unexpected: ${error.detail}`);
              break;
            default:
              console.log(`Save screening: ${error.detail}`);
              break;
          }
        }
        break;
      case 'RESUME_SCREENING':
        for (var error of errors) {
          switch (error.code) {
            case 'RESUME_SCREENING_INVALID':
              console.log(`Resume screening invalid: ${error.detail}`);
              break;
            case 'RESUME_SCREENING_UNEXPECTED':
              console.log(`Resume screening unexpected: 
  ${error.detail}`);
              break;
            case 'RESUME_SCREENING_EXPIRED':
              console.log(`Resume screening expired: ${error.detail}`);
              break;
            default:
              console.log(`Save screening: ${error.detail}`);
              break;
          }
        }
        break;
      default:
        console.log(`Default: Code: ${errors[0].code}; Message: 
  ${errors[0].detail};`);
        break;
    }
    return errors;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.cleanupScript();
  }

  cleanupScript() {
    if (this.script) {
      this.renderer.removeChild(this.document.head, this.link);
      this.renderer.removeChild(this.document.head, this.script);
    }
  }
  ngOnInit() {}

  ngAfterViewInit() {
    this.medicalService.decryptXMLData().subscribe((result) => {
      if (result == undefined || result == '') {
        this.buildParameters(result);
      } else {
        let formXml = result.DecryptedXml;
        const parser = new DOMParser();
        const xml = parser.parseFromString(formXml, 'text/xml');
        const obj: any = this.ngxXml2jsonService.xmlToJson(xml);
        this.buildParameters(
          obj.Screening.ScreeningPath.SystemData.ScreeningData,
        );
      }
    });
  }

  buildParameters(previousScreeningData: any) {
    this.siteName = (this.siteName != '') ? this.siteName : sessionStorage.getItem('portalcode') || '';
    
    window['angularComponentReference'] = {
      component: this,
      zone: this.ngZone,
      loadAngularFunction: (eventType: any, result: any, status: any) =>
        this.onFinishVerisk(eventType, result, status),
    };

    (<any>(
      window
    )).AUTH_DATA.uri = `${EndPoint.LTX_ENDPOINT}/api/pas/token/verisk/`+ this.siteName;

    (<any>window).AUTH_DATA.data = { grant_type: 'password' };

    (<any>window).USER_SETTINGS =
      this.medicalService.buidMedicalVeriskUserSettings(previousScreeningData);

    (<any>window).ROOT_URL = environment.VERISK_GATEWAY_URL;

    this.link = this.renderer.createElement('link');
    this.link.rel = 'stylesheet';

    this.link.href = environment.VERISK_CSS_URL;

    //// Render in header
    this.renderer.appendChild(this.document.head, this.link);
    //// Render in body
    //this.renderer.appendChild(window.document.body, this.link);
    //// Render in element
    //this.renderer.appendChild(this.el.nativeElement, this.link);

    this.script = this.renderer.createElement('script');
    this.script.type = 'text/javascript';
    this.script.src = environment.VERISK_BLACKBOX_URL;
    //this.script.async = true;
    //this.script.defer = true;

    //// Render in header
    this.renderer.appendChild(this.document.head, this.script);
    //// Render in body
    //this.renderer.appendChild(window.document.body, this.script);
    //// Render in element
    //this.renderer.appendChild(this.el.nativeElement, this.script);
  }
}
