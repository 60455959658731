import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EndPoint } from '../core/services/endpoint';
import { CommonParams } from '../core/commonparams';

@Injectable({
  providedIn: 'root'
})
export class AutoClubService {

  constructor(private http: HttpClient) { }
  httpHeaders = {
    headers: new HttpHeaders({
    }),
  };

  getAutoClub() {
    return this.http.get<any>(
      `${EndPoint.LTX_ENDPOINT}api/pas/configuration/autoclubs`, this.httpHeaders,
    );
  }
}
