import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'nameFormat' })
export class NameFormatPipe implements PipeTransform {
  transform(name: string, type: number): any {
    let manupulatedName = '';
    if (name && type) {
      const splitedName = name.split(',');
      if (splitedName.length < 2 || name == null || name == '') {
        return name;
      }
      switch (type) {
        case 1:
          // example doe, john
          manupulatedName =
            splitedName[0].trim() + ', ' + splitedName[1].trim();
          return manupulatedName;
        case 2:
          // example john doe
          manupulatedName = splitedName[1].trim() + ' ' + splitedName[0].trim();
          return manupulatedName;
        case 3:
          // example John Doe
          manupulatedName = splitedName[1].trim() + ' ' + splitedName[0].trim();
          manupulatedName = manupulatedName
            .split(' ')
            .map(function (word, index) {
              return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            })
            .join(' ');
          return manupulatedName;
        default:
          return name;
      }
    } else {
      return name;
    }
  }
}
