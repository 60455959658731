/// <reference path="app.service.ts" />
/// <reference path="../../../../src/app/core/services/utility.ts" />
import { Component, HostListener, Input } from '@angular/core';
import { HeaderNavigationService } from './app.service';
import { OktaCustomAuthService } from 'src/app/shared/okta/oktaauth.service';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { SetQueryParamsService } from 'src/app/core/role/setqueryparams';
import { AutoClubUrllist, LinkUrl } from './app.model';
import { CommonParams } from 'src/app/core/commonparams';
import { DataSetterService } from 'src/app/core/services/data-setter.service';
import { debug } from 'console';
import { BranchesService } from 'src/app/shared/branches.service';
import { stringify } from 'querystring';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { Store } from '@ngrx/store';

@Component({
  selector: 'nav-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class NavComponent {
  @HostListener('document:click', ['$event'])
  onClickOutside($event: any) {
    this.isShow = false;
  }
  readonly defaultClubKey: any = '0';
  readonly clubKey = 'selectedClubKey';
  title = 'nav';
  selectedClubKey: any = this.defaultClubKey;
  headerGetaQuoteVisable: any;
  headerNavigation: any = [];
  headerLeftNavigation: any = [];
  headerGetaQuote: any = [];
  listClubs: any = [];
  listBranches: any = [];
  isReadOnly: any = false;
  isShow = false;
  headerGetaQuoteClass: any;
  userRoleDetails?: UserRoleDetails;

  constructor(
    private headerNavigationService: HeaderNavigationService,
    public oktaCustomAuth: OktaCustomAuthService,
    private portalCodeService: PortalCodeService,
    private setQueryParamsService: SetQueryParamsService,
    private dataSetterService: DataSetterService,
    private branchService: BranchesService,
    private store: Store
  ) { }

  ngOnInit() {
    this.getSecurityRoleValues();
  }

  async getSecurityRoleValues(): Promise<void> {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser?.userRoleDetails;
        if (this.userRoleDetails) {
          this.isReadOnly = this.userRoleDetails.IsReadOnly ? this.userRoleDetails.IsReadOnly : this.userRoleDetails.IsTMMReadOnly ? this.userRoleDetails.IsTMMReadOnly : false;
        }
        if (sessionStorage.getItem(this.clubKey)) {
          this.selectedClubKey = sessionStorage.getItem(this.clubKey);
        } else {
          this.selectedClubKey = this.defaultClubKey;
          sessionStorage.setItem(this.clubKey, this.selectedClubKey);
        }
        const email = loginUser?.userProfile.ContactEmail;

        if (!email || email.length == 0) {
          return;
        }
        this.populateNavDate(this.userRoleDetails.AutoClubURLList, this.userRoleDetails.NavigationMenuItems);
      }
    });
  }

  async populateNavDate(autoClubList: any, navMenuItems: any) {
    let clubGuid: string | undefined;
    if (!this.userRoleDetails?.IsUW) {
      if (autoClubList != null && autoClubList.length == 1) {
        clubGuid = autoClubList[0].autoClubGuid
      }

      if( clubGuid != null && autoClubList.length == 1) {
        await this.branchService.getBranchDetail(this.userRoleDetails?.AgentContactId, clubGuid).toPromise()
        .then((branches: any) => {
          if (branches.responseMessage.length > 0) {
            let modifiedBranches = this.dataSetterService.setBranches(branches.responseMessage, true)
            this.listClubs = modifiedBranches;
          }
        })
        .catch((err) => {
        });
      }
      else {
        let autoClubs = this.dataSetterService.setAutoClubs(autoClubList, false);
        this.listClubs = autoClubs;
      }
    }
    else {
      let autoClubs = this.dataSetterService.setAutoClubs(autoClubList, false);
      this.listClubs = autoClubs;
    }
    this.headerNavigation = [];
    for (let nav of navMenuItems) {
      if (nav.primaryMenuItems.active) {
        this.headerNavigation.push(nav)
      }
    }
    this.setupGetAQuote(this.getIndexOfSelectedClub(this.selectedClubKey)!);
  }

  // AddNodeForSelectClub() {
  //   var club: LinkUrl = {
  //     title: "Select AutoClub",
  //     url: ""
  //   }
  //   var selectClub: AutoClubUrllist = {
  //     key: this.defaultClubKey,
  //     value: "Select AutoClub",
  //     link_Url: club
  //   };

  //   this.listClubs.push(selectClub);
  // }

  // GetHeaderNavigation(): void {
  //   this.headerGetaQuote = 'javascript:void(0);';
  //   this.headerGetaQuoteClass = 'headergetqquote';
  //   this.headerNavigationService.headerNavigation(this.userRoleDetails?.AgencyContactId).subscribe((resp) => {
  //     sessionStorage.setItem('pasNavbarSetting', JSON.stringify(resp));
  //     this.headerNavigation = resp;
  //     this.headerNavigation = this.formatTableData(this.headerNavigation);
  //     this.headerGetaQuote = this.headerNavigation.NavigationItems.find(
  //       (f: any) => f.PrimaryMenuItems.NavigationText == 'Get a Quote',
  //     );
  //     if (this.headerGetaQuote != undefined) {
  //       sessionStorage.setItem(
  //         'headerGetaQuote',
  //         this.headerGetaQuote.PrimaryMenuItems.NavigationLink,
  //       );
  //       this.headerGetaQuote =
  //         this.headerGetaQuote.PrimaryMenuItems.NavigationLink +
  //         this.setQueryParamsService.setGetaQuoteQueryParams();
  //       this.headerGetaQuoteVisable =
  //         this.portalCodeService.setAgencyorBranchDefaultGetqQuote(this.userRoleDetails);
  //       this.headerGetaQuoteClass = '';
  //     }
  //   });
  // }

  setupGetAQuote(index: number): void {
    if (this.selectedClubKey == this.defaultClubKey) {
      this.headerGetaQuoteVisable = false;
      return;
    }

    this.headerGetaQuote = 'javascript:void(0);';
    this.headerGetaQuoteClass = 'headergetqquote';
    this.headerGetaQuote = this.headerNavigation.find(
      (f: any) => f.primaryMenuItems.navigationText == 'Get a Quote',
    );
    if (this.headerGetaQuote != undefined) {
      let url = this.listClubs.length > 0 && this.listClubs[index].link_Url.url != undefined ? this.listClubs[index].link_Url.url : this.userRoleDetails?.AutoClubURLList[0].link_Url.url;
      let isW2C = this.userRoleDetails?.AutoClubURLList.length > 1;
      this.headerGetaQuote = url + this.setQueryParamsService.setGetaQuoteQueryParams(isW2C);
      this.headerGetaQuoteVisable = this.portalCodeService.setAgencyorBranchDefaultGetqQuote(this.userRoleDetails);
      this.headerGetaQuoteClass = '';
    }
  }

  formatTableData(tabledata: any): void {
    let id = 0;
    for (let item of tabledata.NavigationItems) {
      for (let key in item) {
        if (key == 'Active') {
          item[key] = this.getHighlighted(item[key]);
        }
      }
    }

    return tabledata;
  }

  getHighlighted(highlightedurl: any) {
    if (highlightedurl == window.location.pathname) {
      return 'nav-link active';
    }

    return 'nav-link';
  }

  getIndexOfSelectedClub(selectedKey: string): number | undefined {
    for (let i = 0; i < this.listClubs.length; i++) {
      if (this.listClubs[i].key == selectedKey) {
        return i;
      }
    }
  }

  changeNavDropdown(clubKey: any, index: any) {
    // this.portalCodeService.setAgencyorBranch(clubKey);
    sessionStorage.setItem(this.clubKey, clubKey);
    this.selectedClubKey = clubKey;
    this.isShow = false;
    this.setupGetAQuote(index);
  }

  toggleDropdown() {
    this.isShow = !this.isShow;
  }
}
