import {
  NgbDateParserFormatter,
  NgbDateStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date';
import { DatePipe } from '@angular/common';

export class CustomNgbDateParserFormatter extends NgbDateParserFormatter {
  datePipe: any = new DatePipe('en-US');
  constructor(private dateFormatString: string) {
    super();
  }
  format(date: NgbDateStruct): any {
    if (date === null) {
      return '';
    }
    try {
      return this.datePipe.transform(
        new Date(date.year, date.month - 1, date.day),
        this.dateFormatString,
      );
    } catch (e) {
      return '';
    }
  }
  formatDate(d: NgbDate): any {
    if (d === null) {
      return null;
    }

    return [
      d.day < 10 ? '0' + d.day : d.day,
      d.month < 10 ? '0' + d.month : d.month,
      d.year,
    ].join('-');
  }

  parse(value: string): NgbDateStruct {
    let returnVal: NgbDateStruct | any;
    if (!value) {
      returnVal = null;
    } else {
      try {
        let dateParts = this.datePipe.transform(value, 'MM-dd-yyyy').split('-');
        returnVal = {
          year: parseInt(dateParts[2]),
          month: parseInt(dateParts[0]),
          day: parseInt(dateParts[1]),
        };
      } catch (e) {
        returnVal = null;
      }
    }
    return returnVal;
  }
}
