/// <reference path="app.component.ts" />
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe, PercentPipe, DatePipe } from '@angular/common';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { ManageUsersComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { InterceptorsService } from '../../../../src/app/core/services/interceptors.service';
import { LoaderService } from '../../../../src/app/core/services/loader.service';
import { LogPublishersService } from '../../../../projects/policyDetails/src/app/shared/logging/log-publisher.service';
import { HttpCoreService } from '../../../../src/app/core/services/http-core.service';
import { CommonPipeModule } from 'src/app/core/pipes/app.module';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { BranchesTableComponent } from 'projects/manage-branches/src/app/branches-table/branches-table.component';
import { AgentsTableComponent } from './agents-table/agents-table.component';
import { DeactivateConfirmModalComponent } from './deactivate-confirm-modal/deactivate-confirm-modal.component';
import { ConfirmModalComponent } from 'projects/manage-branches/src/app/confirm-modal/confirm-modal.component';
import { AddAgentModalComponent } from './add-agent-modal/add-agent-modal.component';
export { ManageUsersComponent } from './app.component';

@NgModule({
  declarations: [ManageUsersComponent],
  imports: [
    BranchesTableComponent,
    AgentsTableComponent,
    DeactivateConfirmModalComponent,
    AddAgentModalComponent,
    ConfirmModalComponent,
    BrowserModule,
    FormsModule,
    CommonPipeModule,
    LoaderModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    DataTablesModule,
  ],
  providers: [
    CurrencyPipe,
    PercentPipe,
    DatePipe,
    LoaderService,
    HttpCoreService,
    LogPublishersService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true,
    },
  ],
})
export class ManageUsersModule {}
