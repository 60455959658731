import { Injectable } from '@angular/core';
import {
  LogPublisher,
  LogConsole,
  LogLocalStorage,
  LogWebApi,
} from './log-publishers';
import { HttpCoreService } from 'src/app/core/services/http-core.service';
// import { AppConfig } from '../config/app.config';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LogPublishersService {
  public publishers: LogPublisher[] = [];
  constructor(
    //private coreService: HttpCoreService,
    // private config: AppConfig,
  ) {
    //this.buildPublishers();
  }

  buildPublishers() {
    /*let logPub: LogPublisher;
    logPub = new LogWebApi(this.coreService);
    logPub.location = `${environment.sitecoreBaseAddress}/GTPAPI/SitecoreLog`;

    this.publishers.push(logPub);*/
  }
}
