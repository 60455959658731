<app-loader></app-loader>
<div class="mt-4 *ngIf='policyMovement.length > 0'">
  <div id="accordion">
    <div class="card">
      <div class="card-header" id="policyversion">
        <h5 class="mb-0">
          <button
            class="btn btn-link accordion-note"
            data-toggle="collapse"
            data-target="#policy-version"
            (click)="openPolicyDetailsVersion()"
            [attr.aria-expanded]="!isVerionCollapsed"
            aria-expanded="false"
            aria-controls="policy-version"
          >
            <i
              [ngClass]="{
                rotate0: !isVerionCollapsed,
                rotate90: isVerionCollapsed
              }"
              class="fas fa-chevron-circle-right mr-2 rotate90"
            ></i>
            <span>Policy Versions</span>
          </button>
        </h5>
      </div>
      <div
        id="policy-version"
        class="collapse p-2"
        aria-labelledby="policyversion"
        data-parent="#accordion"
      >
        <table
          id="datatable1"
          class="table table-striped table-bordered table-dashboard m-0"
          style="width: 100%"
        >
          <thead>
            <tr>
              <th>Policy Version</th>
              <th>Transaction</th>
              <th>Date</th>
              <th>Premium</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of policyMovement">
              <td>{{ item.version }}</td>
              <td> {{ item.type === 'NB' ? 'NE' : item.type != 'CN' ? item.type : 'CA' }} </td>
              <td>{{ item.date | utcToShortDatePipe }}</td>
              <td class="text-right">
                {{ getPriceFixed(item.amount, 2) | currency }}
              </td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-primary px-2"
                  (click)="openModal(item.type, item.movementId, item.version, item.amount )">
                  view
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
<ng-template #dialogContentTemplate>
  <!-- <div class="modal fade"  data-backdrop="static" data-keyboard="false"> -->
  <div class="modal-header">
    <a href="javascript:;" aria-label="Close" (click)="closeModal()">
      <i class="fas fa-arrow-alt-circle-left ml-3 mr-2 font-size-20"></i>
      <span class="font-size-20">Return to Policy Summary</span>
    </a>
  </div>
  <div class="modal-body">
    <policy-version-modal
      [premiumChanged]="premiumChanged"
      [movementGuid]="movementGuid"
      [versionData]="versionData"
      [policyGuid]="policyGuid"
      [versionNum]="versionNum"
    ></policy-version-modal>
  </div>
  <div class="modal-footer align-items-center justify-content-center">
    <div class="row">
      <div class="col-sm-12">
        <button
          type="button"
          class="btn btn-secondary btn-full-sm mx-auto"
          (click)="closeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>
