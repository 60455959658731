/// <reference path="../commonparams.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ClubAndBranchInfo } from '../commonparams';

@Injectable({
  providedIn: 'root',
})
export class RoleStatus {
  constructor(private http: HttpClient) {}

  checkRoleStatus(userRole: any) {
    if (
      userRole.IsUW == true ||
      userRole.IsSuperUser == true ||
      userRole.IsReadOnly == true ||
      userRole.IsTMMReadOnly == true ||
      userRole.IsClubAdmin == true
    ) {
      return true;
    }
    return false;
  }

  checkUWStatusQueryParams(userRole: any) {
    if (userRole.setRole) {
      if (userRole.IsUW) {
        return 'true';
      } else if (userRole.IsSuperUser) {
        return 'true';
      } else if (userRole.IsReadOnly && ClubAndBranchInfo.Clubs) {
        if (ClubAndBranchInfo.Clubs.length > 1) {
          return 'true';
        }
        return 'false';
      }
    }

    return 'false';
  }

  checkUWStatus(userRole: any) {
    if (userRole.IsUW == true) {
      return 'true';
    }
    return 'false';
  }

  checkSuperUserSatus(userRole: any) {
    if (userRole.IsSuperUser == true) {
      return 'true';
    }
    return 'false';
  }

  checkReadOnlytatus(userRole: any) {
    if (userRole.IsReadOnly == true) {
      return 'true';
    }
    return 'false';
  }

  checkTMMReadOnlytatus(userRole: any) {
    if (userRole.IsTMMReadOnly == true) {
      return 'true';
    }
    return 'false';
  }

  checkClubAdminStatus(userRole: any) {
    if (userRole.IsClubAdmin == true) {
      return 'true';
    }
    return 'false';
  }

  checkEmailNoExitStatus(userRole: any) {
    if (
      userRole.IsUW == true ||
      userRole.IsSuperUser == true ||
      userRole.IsReadOnly == true ||
      userRole.IsTMMReadOnly == true ||
      userRole.IsClubAdmin == true ||
      userRole.IsClubAdmin == true ||
      userRole.IsAgent == true
    ) {
      return false;
    }
    return true;
  }
}
