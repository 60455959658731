<div class="card-gray">
  <h1 class="card-gray-header">DEPENDENTS</h1>
  <div class="card-edit" *ngIf="isUpdateENCalled && isEditBtnRequired">
    <a href="javascript:void(0);">
      <i class="far fa-edit" data-toggle="modal" (click)="openModal()"></i>
    </a>
  </div>
  <ul class="list-group list-group-flush" *ngFor="let item of dependentsList">
    <li class="list-group-item">
      <div class="row">
        <div class="col-6" style="word-break: break-word">
          {{ item.firstName | nameFormat: 3 }} {{ item.lastName | nameFormat: 3 }},
          {{ getAgeFromDOB(item.dateOfBirth) }}
        </div>
        <div class="col-6 text-right">
          {{ item.dateOfBirth }}
        </div>
      </div>
    </li>
  </ul>
  <div class="col-12" [hidden]="!isMessageVisible">
    <div class="alert alert-success mt-3" role="alert">
      {{ msg }}
    </div>
  </div>
</div>

<ng-template #dialogContentTemplate>
  <div *ngIf="isLoader">
    <div class="clearfix"></div>
    <div class="overlay-custom-container position-absolute"></div>
    <div class="loader"></div>
  </div>
  <!-- Modal body -->
  <form [formGroup]="dependentForm" (ngSubmit)="updateDependent()">
    <div class="modal-body">
      <div class="col-md-12">
        <h5 class="mb-4 text-center">Update Dependents Details</h5>
        <div *ngIf="DuplicateDependents" class="invalid-feedback" style="display: block !important"> {{ DuplicateDependentsErrorMsg }} </div>
        <div class="form-row mt-3">
          <div class="w-100" formArrayName="items" *ngFor="let item of items.controls; let i = index">
            <div class="form-row w-100" [formGroupName]="i">
               <div class="form-group col-md-12 col-lg-4">
                <input type="text" class="form-control" length="50" formControlName="firstName" placeholder="First Name" 
                       [ngClass]="{ 'is-invalid': submitted != null && !submitted && item.get('firstName')?.errors }">
                <div *ngIf="submitted != null && !submitted && item.get('firstName')?.errors" class="invalid-feedback">
                  <div *ngIf="item.get('firstName')?.errors?.['required']">Please enter the First Name of the dependent.</div>
                  <div *ngIf="item.get('firstName')?.errors?.['invalidCharacters']">First Name of the dependent cannot contain special characters or numbers.</div>
                </div>
              </div>
              <div class="form-group col-md-12 col-lg-4">
                <input type="text" class="form-control" length="50" formControlName="lastName" placeholder="Last Name"
                       [ngClass]="{ 'is-invalid': submitted != null && !submitted && item.get('lastName')?.errors }">
                <div *ngIf="submitted != null && !submitted && item.get('lastName')?.errors" class="invalid-feedback">
                  <div *ngIf="item.get('lastName')?.errors?.['required']">Please enter the Last Name of the dependent.</div>
                  <div *ngIf="item.get('lastName')?.errors?.['invalidCharacters']"> Last Name of the dependent cannot contain special characters or numbers.</div>
                </div>
              </div>
              <div class="form-group col-md-12 col-lg-3">
                <div class="input-group date-field nopadding" id="">
                  <input type="text" class="form-control input-sm col-12"
                    formControlName="dob" 
                    ngbDatepicker
                    #d3="ngbDatepicker" 
                    placeholder="Date of Birth" 
                    [minDate]="minDate" 
                    [maxDate]="maxDate"
                    (click)="d3.toggle()" 
                    [ngClass]="{ 'is-invalid': submitted != null && !submitted && item.get('dob')?.errors }" />
                  <div class="input-group-append">
                    <span class="input-group-text"><i class="far fa-calendar-alt" (click)="d3.toggle()"
                        type="button"></i></span>
                  </div>
                </div> {{item.errors}}
                <div *ngIf=" submitted != null && !submitted && item.get('dob')?.errors " class="error-msg">
                  <div *ngIf="item.get('dob')?.errors?.['required']"> Please enter the date of birth </div>
                  <div *ngIf="item.get('dob')?.errors?.['ageRange']"> Please enter the age between 0 to 25 years </div>
                  <div *ngIf="item.get('dob')?.errors?.['invalidDate']"> Please enter a valid date of birth </div>
                </div>
              </div>
              <div class="form-group col-md-12 col-lg-1 text-center mt-1">
                <a><i class="far fa-trash-alt" (click)="deleteIndividualDependentRow(i)"></i></a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 pl-2 mt-2">
            <button type="button" class="btn btn-primary" (click)="editNewRow()">
              Add more Dependents
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mb-4 mt-2 btn-footer">
      <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeModal1()">
        Cancel
      </button>
      <button type="submit" class="btn btn-primary btn-full-sm" [disabled]="disabledSavedependent && items.length == 0">
        Save Changes
      </button>
    </div>
  </form>
</ng-template>
<ng-template #confirmMessageTemplate>
  <div class="" id="myModal-confirm">
    <div class="modal-body">
      <div class="col-md-10 mx-auto text-center">
        <div class="alert my-2">{{ cancelMessageSuccess }}</div>
      </div>
    </div>
    <div class="text-center mb-4 btn-footer">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeAlertChildModal()">
        Ok
      </button>
    </div>
  </div>
</ng-template>