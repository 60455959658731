/// <reference path="policy-status/policy-status.service.ts" />
/// <reference path="../../../../src/app/shared/okta/oktaauth.service.ts" />
// / <reference path="../../../../src/app/core/services/customngbdateparserformatter.ts" />
/// <reference path="../../../../src/app/core/role/setqueryparams.ts" />
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { ManageComponent } from './manage/manage.component';
import { PolicyStatusComponent } from './policy-status/policy-status.component';
import { PolicyDashboardGridComponent } from './policy-dashboard-grid/policy-dashboard-grid.component';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { CurrencyPipe, PercentPipe, DatePipe } from '@angular/common';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
import { CustomNgbDateParserFormatter } from '../../../../src/app/core/services/customNgbDateParserFormatter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { InterceptorsService } from '../../../../src/app/core/services/interceptors.service';
import { SetQueryParamsService } from '../../../../src/app/core/role/setqueryparams';
import { UtcToShortDatePipe } from '../../../../src/app/core/pipes/moment.pipe';
import { FormatNumberPipe } from '../../../../src/app/core/pipes/format-number.pipe';
import { SessionStorageService } from '../../../../src/app/core/services/remove-session-storage';
import { HttpCoreService } from '../../../../src/app/core/services/http-core.service';
import { LogPublishersService } from '../../../../projects/policyDetails/src/app/shared/logging/log-publisher.service';
import { NavModule } from 'projects/nav/src/app/app.module';
import { HeaderModule } from 'projects/header/src/app/app.module';
export { AppComponent } from './app.component';
import { LoaderModule } from 'src/app/core/loader/loader.module';
@NgModule({
  declarations: [
    AppComponent,
    ManageComponent,
    PolicyStatusComponent,
    PolicyDashboardGridComponent,
    UtcToShortDatePipe,
  ],
  imports: [ 
    BrowserModule,
    FormsModule,
    LoaderModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    DataTablesModule,
    NavModule,
    HeaderModule
  ],
  providers: [
    HttpCoreService,
    CurrencyPipe,
    FormatNumberPipe,
    PercentPipe,
    DatePipe,
    OktaCustomAuthService,
    SetQueryParamsService,
    SessionStorageService,
    LogPublishersService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true,
    },
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new CustomNgbDateParserFormatter('dd/MM/yyyy'),
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DashBoardModule {}
