import { Injectable } from "@angular/core";
import { AutoClubUrllist, LinkUrl } from "projects/nav/src/app/app.model";

@Injectable({
    providedIn: 'root',
})
export class DataSetterService {
    readonly defaultClubKey: any = '0';
    readonly defaultId: number = 0;
    readonly defaultGuid: any = null;
    public listClubs: any = [];
    public listBranches: any = [];
    public club: LinkUrl = { title: "Select AutoClub", url: "" }
    public selectClub: AutoClubUrllist = {
        // autoClubId: this.defaultId,
        autoClubGuid: this.defaultGuid,
        key: this.defaultClubKey,
        value: "Select AutoClub",
        link_Url: this.club
    };

    public branch: LinkUrl = { title: "Select Branch", url: "" }
    public selectBranch: AutoClubUrllist = {
        // autoClubId: this.defaultId,
        autoClubGuid: this.defaultGuid,
        key: this.defaultClubKey,
        value: "Select Branch",
        link_Url: this.branch
    };

    constructor() { }

    setAutoClubs(autoClubs: any, removeDefault: boolean) {
        this.listClubs = [];
        autoClubs.forEach((value: any, i: any) => {
            this.listClubs.push(value);
        });
        if (removeDefault) {
            this.listClubs = this.listClubs.filter((x: { key: string; }) => (x.key != '0'));
        }
        else{
            this.listClubs.push(this.selectClub);
        }
        this.listClubs =  this.listClubs.sort((a: { key: string; }, b: { key: any; }) => a.key.localeCompare(b.key));
        return this.listClubs;
    }

    setBranches(branches: any, removeDefault: boolean) {
        this.listBranches = [];
        this.listBranches.push(this.selectBranch);
        if (branches != null && branches.length > 0) {
            branches.forEach((value: any, i: any) => {
                let obj = {
                    "id": i + 1,
                    "key": value.branchGuid,
                    "value": value.autoClubCode,
                    "link_Url": {
                        "title": value.name
                    }
                }
                this.listBranches.push(obj);
            });
        }
        this.listBranches = this.listBranches.sort(function (a: { id: number }, b: { id: number }) { return a.id - b.id });
        if (removeDefault) {
            this.listBranches = this.listBranches.filter((x: { id: number; }) => x.id != 0)
        }
        return this.listBranches;
    }
}