/// <reference path="promo-code.service.ts" />
/// <reference path="../../../../src/app/core/services/date.service.ts" />
import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
  Form,
} from '@angular/forms';
import {
  NgbModal,
  NgbModalRef,
  NgbDateParserFormatter,
  NgbCalendar,
  NgbDate,
} from '@ng-bootstrap/ng-bootstrap';
import { PromoCodeService } from './promo-code.service';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe, CurrencyPipe } from '@angular/common';
type ColumnField = Array<{ title: string; data?: string; render?: any }>;
// import { forkJoin } from 'rxjs/observable/forkJoin';
import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
// import { isNull, isNullOrUndefined } from 'util';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { NgbDateFRParserFormatter } from './ngb-date-fr-parser-formatter';
import { Utility } from 'projects/policyDetails/src/app/shared/utility';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum } from 'projects/policyDetails/src/app/enum/page-name-enum';
import { ExportService } from 'src/app/core/export/export.service';
import { CommonUtility } from 'src/app/_helpers/common.utility';
import { DateService } from 'src/app/core/services/date-updated.service';
import {
  DeletedPromoHeaders,
  ExpPromoHeaders,
} from 'src/app/core/export/export.configs';
import { formatDate } from '@angular/common';
import { ActivityType } from 'src/app/core/enum/activitytype-enum';
import { LoaderService } from 'src/app/core/services/loader.service';
import { subscribe } from 'diagnostics_channel';
import { error } from 'console';
import { ContactProducerPromoCode } from './app.model';
import { GridService } from 'src/app/shared/grid.service';
import { tap } from 'rxjs';
import { SearchPromoCode } from './app.model';
import { HeaderNavigationService } from 'projects/nav/src/app/app.service';
import { DataSetterService } from 'src/app/core/services/data-setter.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails, UserProfile } from 'src/app/core/store/loginUser/loginUser.model';
export class Constants {
  static readonly DATE_FMT = 'dd-MM-yyyy';
}

@Component({
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
  ],
  selector: 'promo-code-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class PromoCodeComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('content', { static: true })
  content!: ElementRef;

  @ViewChild('contentsubjectivity', { static: true })
  contentsubjectivity!: ElementRef;

  @ViewChild('contentdataTableFilter', { static: true })
  contentdataTableFilter!: ElementRef;

  @ViewChild('alert', { static: true })
  alert!: ElementRef;

  @ViewChild('confirmationBox', { static: true })
  confirmationBox!: ElementRef;

  @ViewChild('warningBox', { static: true })
  warningBox!: ElementRef;

  @ViewChild('contentmodalEditPromoCode', { static: true })
  contentmodalEditPromoCode!: ElementRef;

  @ViewChild('deleteConfirmationBox', { static: true })
  deleteConfirmationBox!: ElementRef;

  @ViewChildren(DataTableDirective)
  datatableElement!: QueryList<DataTableDirective>;

  userRoleDetails?: UserRoleDetails;
  userProfile?: UserProfile;
  ngForm!: FormGroup;
  addPromoCodeForm!: FormGroup;
  editPromoCodeForm!: FormGroup;
  _dataTableDataList: any = [];
  dataArray: any = [];
  text: any = [];
  filterArray: any = [];
  columnArray: ColumnField = [];
  dtOptions: any = [];
  department = [];
  country = [];
  classification = [];
  listClubs: any = [];
  selectedClub: any = null;
  isSearchFormError: boolean = false;
  isInputInvalid: boolean = false;
  isTMMASuperAdmin: boolean = false;
  selectedClubCode: string = "";

  datemodelfrom: any;
  datemodelto: any;

  dataTableProperties: any = {};
  obj: any = {
    title: '',
    data: '',
  };

  _objDataTabledata: any = {
    title: '',
    apiaction: '',
    data: [],
  };

  columnDefValue: any = [
    {
      targets: [],
      orderable: false,
    },
  ];

  hoveredDate!: NgbDate | any;
  fromDate: NgbDate | any;
  toDate: NgbDate | any;

  modalData: any = {};
  searchVal: any = '';
  response: any;
  isLoaderPromoCode: boolean = false;
  isAddPromoCodeLoader: boolean = false;
  isEditPromoCodeLoader: boolean = false;
  isDeleteLoader: boolean = false;
  submitted = false;
  editSubmitted = false;
  gridNameField: any = 'PromoCode';
  expirationDateErr = false;
  currentClub: any;
  currentClubId: any;
  noSearchValueSelectedError: boolean = false;
  minValidDate: { year: any; month: any; day: any } | any;
  minValidDateEdit: { year: any; month: any; day: any } | any;
  expMinValidDate: { year: any; month: any; day: any } | any;
  expMaxValidDate: { year: any; month: any; day: any } | any;
  expMaxValidDateEdit: { year: any; month: any; day: any } | any;
  loggedUser: any = {};
  userName: string = '';
  exportPromoError: boolean = false;
  exportPromoErrorMsg: string = '';
  exportExpiryDays: string = '';
  exportExpiryPromoMsg: string = '';
  originalPromoCode: any;
  originalPromoName: string = '';
  promocodeExportType: string = 'expired';
  exportDeletedPromoMsg: string = 'Export deleted promocodes';
  promoCodeLogList: any = [];
  contactProducerPromoCodesForm: any = [];
  promoCodesArray: any = [];
  public defaultAutoClub: string = "";
  constructor(
    private promoCodeService: PromoCodeService,
    private renderer: Renderer2,
    private modalService: NgbModal,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private pipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private fb: FormBuilder,
    public oktaCustomAuth: OktaCustomAuthService,
    private dateService: DateService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private portalCodeService: PortalCodeService,
    private logService: LogService,
    private exportService: ExportService,
    private loaderService: LoaderService,
    private gridService: GridService,
    private navService: HeaderNavigationService,
    private dataSetterService: DataSetterService,
    private store: Store
  ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), 'd', 10);

    //get AEST min valid date
    let currentDate = new Date();
    let purchaseDateAESTFormat = new Date(
      Utility.utcToAestDate(currentDate.toISOString()),
    );
    this.minValidDate = {
      year: purchaseDateAESTFormat.getFullYear(),
      month: purchaseDateAESTFormat.getMonth() + 1,
      day: purchaseDateAESTFormat.getDate(),
    };
    this.expMinValidDate = {
      year: this.minValidDate.year,
      month: this.minValidDate.month,
      day: this.minValidDate.day,
    };
  }

  ngOnInit(): void {
    this.submitted = false;
    this.addPromoCodeForm = this.promoCodeService.AddPromoCodeForm();
    this.contactProducerPromoCodesForm = (<FormArray>(this.addPromoCodeForm.get('ContactProducerPromoCodes'))) as FormArray;
    this.isLoaderPromoCode = true;
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.userProfile = loginUser.userProfile;
        this.listClubs = this.dataSetterService.setAutoClubs(this.userRoleDetails?.AutoClubURLList, true);
        this.navService.handelUnauthorizedUrl("/promocode", this.userRoleDetails.NavigationMenuItems);
        if (this.userRoleDetails.IsUW) {
          this.isTMMASuperAdmin = true;
        }
        this.getSitecoreDataTableDataList();
      }
    });
    // this.promoCodeService.getAutoClub().subscribe(
    //   (resp: any) => {

    //     this.listClubs = resp;
    //     this.getSitecoreDataTableDataList();
    //   });

  }

  convertDateToTimestamp(date: any) {
    if (date && typeof date != 'object') {

      let arr = date.split('/');
      date = {
        year: parseInt(arr[2], 10),
        month: parseInt(arr[1], 10),
        day: parseInt(arr[0], 10),
      };
    }
    let formateDate = date.year + '-' + (date.month < 10 ? '0' + date.month : date.month) + '-' + (date.day < 10 ? '0' + date.day : date.day);
    let timeStamp = new Date(formateDate.toString()).getTime();
    return timeStamp;
  }


  //Api Method
  getSitecoreDataTableDataList(): void {
    this.isLoaderPromoCode = true;
    this.promoCodeService
      .getSiteCoreAgencyTotalList(this.gridNameField)
      .subscribe(
        (resp) => {
          this.text = resp;
          this.text.DataGridAllList.forEach(
            (item: {
              DataGridRenderingParameters: {
                IsWebDisplay: boolean;
                GridNameField: any;
                GridName: any;
                ApiAction: any;
                ExportExpiryDays: any;
                SearchFilter: any;
              };
            }) => {
              if (
                item.DataGridRenderingParameters.IsWebDisplay == true
                && item.DataGridRenderingParameters.GridNameField ==
                this.gridNameField
              ) {
                this._objDataTabledata = {
                  title: item.DataGridRenderingParameters.GridName,
                  apiaction: item.DataGridRenderingParameters.ApiAction,
                  ExportExpiryDays: item.DataGridRenderingParameters.ExportExpiryDays
                };
                if (this.filterArray.length == 0 && item.DataGridRenderingParameters.SearchFilter !== undefined && item.DataGridRenderingParameters.SearchFilter.length > 0) {
                  for (let filter of item.DataGridRenderingParameters.SearchFilter) {
                    var tempItem = {
                      KeyText: filter.KeyText,
                      Value: '',
                      Description: filter.Description,
                      FieldType: filter.FieldType,
                      ColumnType: filter.ColumnType,
                    };
                    this.filterArray.push(tempItem);
                  }
                }
                this.setClubSelectedItem();
                this.loadGrid(item);
              }
            },
          );
        },
        (err) => {
          this.isLoaderPromoCode = false;
          this.logService.createErrorLog(
            {},
            err,
            'GetSiteCoreAgencyTotalList',
            PageNameEnum.PromoCode,
            LogLevel.Error,
          );
        },
      );
  }

  loadGrid(item: any) {
    var req = this.createSearchReq();
    if (this.datatableElement && this.datatableElement.first && this.datatableElement.first.dtInstance) {
      this.datatableElement.first.dtInstance.then(dtInstance => {
        dtInstance.ajax.url(null);
        var obsPolicies = this.getOctaAgencyPortalApiServiceList(req);
        dtInstance.ajax.url(this.gridService.getAjaxMethod(obsPolicies, req, PageNameEnum.PromoCode)).load();
      });
    } else {
      this.dtOptions = [];
      this.dtOptions.push(this.buildDtOptions(item, req));
    }
  }

  createSearchReq() {
    let reqObj: SearchPromoCode = {};
    if (this.filterArray[1].Value) {
      reqObj.promoCode = this.filterArray[1].Value.trim();
    }
    if (this.filterArray[2].Value) {
      const dt = this.filterArray[2].Value;
      const fdate = `${dt.year}-${String(dt.month).padStart(2, '0')}-${String(dt.day).padStart(2, '0')}`;
      reqObj.EffectiveDate = fdate;
    }
    if (this.filterArray[3].Value) {
      const dt = this.filterArray[3].Value;
      const ldate = `${dt.year}-${String(dt.month).padStart(2, '0')}-${String(dt.day).padStart(2, '0')}`;
      reqObj.ExpirationDate = ldate;
    }
    if (!!(this.filterArray[0].Value) && this.filterArray[0].Value.length !== 0) {
      reqObj.autoClubGuid = this.filterArray[0].Value;
    }
    reqObj.loginAgentGuid = this.userRoleDetails?.AgentContactId;
    return reqObj;
  }

  exportReadyPromo(promoList: any) {
    let todaysDate = new Date();
    // this.exportExpiryDays =
    //   this._objDataTabledata &&
    //     this._objDataTabledata.ExportExpiryDays != null
    //     ? this._objDataTabledata.ExportExpiryDays
    //     : '';

    // let expiryDays =
    //   this.exportExpiryDays.length >= 1 &&
    //     Number(this.exportExpiryDays) != undefined &&
    //     !isNaN(Number(this.exportExpiryDays))
    //     ? Number(this.exportExpiryDays)
    //     : 0;
    let formattedDate = formatDate(
      new Date(todaysDate.setDate(todaysDate.getDate())),
      'dd-MM-yyyy',
      'en-US',
    );
    let expirationFilterDate =
      this.dateService.getMomentFromDDMMYYYY(formattedDate);

    //filter promo based on dates and create array for Export
    promoList.forEach(
      (x: any) => {
        if (this.dateService.getMomentFromDDMMYYYY(x.ExpirationDate) <=
          expirationFilterDate) {
          x.Status = 'Expired';
        }
        if (x.isActive === false) {
          x.Status = 'Deleted';
        }
      }
    );
    var promoCount = promoList != null ? promoList.length : 0;
    this.exportExpiryPromoMsg =
      promoCount +
      ' PromoCodes expired or to expire in next ' +
      this.exportExpiryDays +
      ' days';
    return promoList;
  }

  buildDtOptions(item: any, req: any): any {
    this.columnArray = [];
    let targetArray = [];
    let targetArrayStyle = [];
    this.columnDefValue = [];
    let columnindex = -1;

    for (let col of item.Columns) {
      columnindex++;
      if (col.IsWebDisplay == true && col.IsPopup == true) {
        let rowindex = 0;
        this.columnArray.push({
          title: col.DisplayName,
          data: col.FieldName,
          render: function (data: any, type: any, full: any) {
            let columnvalue = '';
            let promoCodeId = '';
            for (let item in full) {
              if (col.FieldName == item) {
                columnvalue = full[item];
              } else if ('promoCodeGuid' == item) {
                promoCodeId = full[item];
              }
            }

            return (
              '<a href="javascript:void(0);"  ispopup="' +
              col.IsPopup +
              '" apiaction="' +
              item.DataGridRenderingParameters.ApiAction +
              '" columnvalue="' +
              columnvalue +
              '" column="' +
              col.FieldName +
              '" columnText="' +
              col.DisplayName +
              '" promoCodeId="' +
              columnvalue +
              '" >' +
              col.DisplayName +
              '</a>'
            );
          },
        });

        if (col.Sortable == true) {
          targetArray.push(columnindex);
        }
      } else if (
        col.IsWebDisplay == true &&
        col.Linked1Url != null &&
        col.Linked1Url.Url != ''
      ) {
        let rowindex = 0;
        this.columnArray.push({
          title: col.DisplayName,
          data: col.FieldName,
          render: function (data: any, type: any, full: any) {
            let columnvalue = '';
            let quoteNo = '';
            let PolicyTxnId = '';
            for (let item in full) {
              if (col.FieldName == item) {
                columnvalue = full[item];
              } else if (item == 'PolicyTxnId') {
                PolicyTxnId = full[item];
              }
            }

            return (
              '<a href="javascript:void(0);"  linkedurl1="' +
              col.Linked1Url.Url +
              '"  policyTxnId="' +
              PolicyTxnId.toString() +
              '"  columnvalue="' +
              columnvalue +
              '" column="' +
              col.FieldName +
              '" columnDisplay="' +
              quoteNo +
              '" >' +
              columnvalue +
              '</a>'
            );
          },
        });

        if (col.Sortable == true) {
          targetArray.push(columnindex);
        }
      } else if (col.IsWebDisplay == true) {
        if (col.Sortable == true) {
          targetArray.push(columnindex);
        }
        this.columnArray.push({
          title: col.DisplayName,
          data: col.FieldName,
        });
      }

      if (
        col.FieldName == 'EffectiveDate' ||
        col.FieldName == 'ExpirationDate'
      ) {
        targetArrayStyle.push(columnindex);
      }
    }


    if (targetArray.length > 0) {
      this.columnDefValue.push({
        targets: targetArray,
        orderable: false,
        className: 'text-center',
      });
    }
    if (targetArrayStyle.length > 0) {
      this.columnDefValue.push({
        targets: targetArrayStyle,
        width: '71px',
      });
    }

    var dtOptions = {
      columns: this.columnArray,
      responsive:
        item.DataGridRenderingParameters.Responsive != ''
          ? item.DataGridRenderingParameters.Responsive
          : true,
      autoWidth:
        item.DataGridRenderingParameters.AutoWidth != ''
          ? item.DataGridRenderingParameters.AutoWidth
          : true,
      searchingOption:
        item.DataGridRenderingParameters.Searching != ''
          ? item.DataGridRenderingParameters.Searching
          : true,
      searching: true,
      bLengthChange: false,
      pagingType: 'full_numbers',
      iDisplayLength: 10,
      columnDefs: this.columnDefValue,
      gridDisplayName: item.DataGridRenderingParameters.GridName,
      gridName: item.DataGridRenderingParameters.Name,
      name: item.DataGridRenderingParameters.Name,
      singleSearchFilter: this.searchVal,
      order: [0, 'asc'],
      dom: 'Bfrtip',
      buttons: [
        //'pdf',
        //'excel'
      ],
      language: {
        paginate: {
          previous: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
          next: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
          first: '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
          last: '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
        },
      },
    };

    var obsPolicies = this.getOctaAgencyPortalApiServiceList(req);
    return this.gridService.buildDtOptionsWithPaging(dtOptions, obsPolicies, req, PageNameEnum.Dashboard);
  }

  transformData(obj: any) {
    var upBy = obj.UpdatedBy != null ? obj.UpdatedBy : '';
    var upDt: any =
      obj.UpdatedDate != null
        ? this.pipe.transform(
          obj.UpdatedDate,
          Constants.DATE_FMT,
        )
        : '';

    var crBy = obj.CreatedBy != null ? obj.CreatedBy : '';
    var crDt: any =
      obj.CreatedDate != null
        ? this.pipe.transform(
          obj.CreatedDate,
          Constants.DATE_FMT,
        )
        : '';

    obj.UpdatedByDate =
      upBy.length > 1 && upDt.length > 1
        ? upBy + '/' + upDt
        : upBy.length > 1
          ? upBy
          : upDt.length > 1
            ? upDt
            : '';
    obj.CreatedByDate =
      crBy.length > 1 && crDt.length > 1
        ? crBy + '/' + crDt
        : crBy.length > 1
          ? upBy
          : crDt.length > 1
            ? crDt
            : '';
    return obj;
  }

  getOctaAgencyPortalApiServiceList(req: any) {
    var that = this;
    this._dataTableDataList = [];
    return that.promoCodeService.getOctaAgencyPortalApiServiceList(req).pipe(tap((res) => {
      var reformatted = res.response.map(this.transformData);
      res.response = this.formatTableData(JSON.parse(JSON.stringify(reformatted)));
      that._objDataTabledata.data = res.response;
      that._dataTableDataList.push(that._objDataTabledata);
      this.isLoaderPromoCode = false;
    })
    );
  }

  setClubSelectedItem() {
    if (this.listClubs.length == 1) {
      let searchFiltercontext = this.filterArray;
      for (let i = 0; i < searchFiltercontext.length; i++) {
        if (
          searchFiltercontext[i].FieldType.toLowerCase() == 'select' &&
          searchFiltercontext[i].KeyText.toLowerCase() == 'clubname'
        ) {
          this.filterArray[i].Value = this.listClubs[0].autoClubGuid;
        }
      }
      this.selectedClub = this.listClubs[0].autoClubGuid;
    }
  }

  DateFormate(date: string | null) {
    if (date && date != null) {
      let d = date.split('/');
      let day = d[1].length < 2 ? '0' + d[1] : d[1];
      let month = d[0].length < 2 ? '0' + d[0] : d[0];
      let year = d[2];
      let formated = year + '-' + day + '-' + month;
      return formated;
    } else {
      return null;
    }
  }

  //DataTableFilter Method
  displayToConsole(
    event: Event,
    item: any,
    tableindex: any,
    filterType: any,
  ): void {
    if (filterType == 'search') {
      this.noSearchValueSelectedError = false;
      let noOffieldshasValue: Array<any> = this.filterArray.filter(
        (row: any) => row.Value,
      );
      if (noOffieldshasValue.length == 0) {
        this.noSearchValueSelectedError = true;
        setTimeout((v: any) => {
          this.noSearchValueSelectedError = false;
        }, 9000);
        return;
      }
      // let calendarFields: Array<any> = item.searchFilter.filter(row => (row.KeyText == "effectiveDate" || row.KeyText == "expirationDate") && row.error);
      // if (calendarFields.length) {
      //   this.isInputInvalid = true;
      //   return;
      // }

      try {
        // if (!this.dtOptions[0].searchFilter.find(v => v.KeyText == 'promoCode').Value
        // && !this.dtOptions[0].searchFilter.find(v => v.KeyText == 'ClubName').Value) {
        //   //this.isSearchFormError = true;
        //   return;
        // }
      } catch (e) { }
    }
    this.isLoaderPromoCode = true;
    for (let itemfilter of this.filterArray) {
      if (filterType == 'allClear') {
        itemfilter.Value = '';
        itemfilter.error = false;
      }
    }
    if (this.listClubs.length == 1) {
      let searchFiltercontext = this.filterArray;
      for (let i = 0; i < searchFiltercontext.length; i++) {
        if (
          searchFiltercontext[i].FieldType.toLowerCase() == 'select' &&
          searchFiltercontext[i].KeyText.toLowerCase() == 'clubname'
        ) {
          this.filterArray[i].Value = this.listClubs[0].autoClubGuid;
        }
      }
      this.selectedClub = this.listClubs[0].autoClubGuid;
    }
    this.loadGrid({});
  }

  filterById(tableindex: any, item: any): void {
    let itemindex = 0;
    let length = 0;
    let itemtableindex = tableindex;
    this.datatableElement.forEach(
      (dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          if (tableindex == index) {
            dtInstance.columns().every(function (columnIndex: number) {
              if (dtInstance.search() !== dtInstance[item.singleSearchFilter]) {
                dtInstance.search(item.singleSearchFilter.toLowerCase());
              }
            });

            dtInstance.draw();
          }
        });
      },
    );
  }

  open(content: any, rowdata: any) {
    const modal: NgbModalRef = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    this.modalData = rowdata;
    modal.result.then(
      (result) => {
        //console.log(result);
      },
      (reason) => {
        //console.log(reason);
      },
    );
  }

  //Common Method
  formatTableData(tabledata: any): void {
    let id = 0;
    for (let item of tabledata) {

      id++;
      item.ID = id;
      item.EffectiveDate = item.effectiveDate;
      item.ExpirationDate = item.expirationDate;
      item.DiscountRate = (item.discountRate * 100);
      item.PromoCode = item.promoCode;
      item.ClubName = item.clubName;
      item.CreatedDate = (item.modifiedBy || '') + '/' + this.pipe.transform(item.createdDate, Constants.DATE_FMT);
      item.ModifiedDate = (item.modifiedBy || '') + '/' + this.pipe.transform(item.modifiedDate, Constants.DATE_FMT);
      item.PromoCodeId = item.promoCodeGuid;
      item.PromoCodeDescription = item.promoCodeDescription;

      if (item.CreatedDate == null) {
        item.CreatedDate = '';
      }
      if (item.ModifiedDate == null) {
        item.ModifiedDate = '';
      }
      if (item.EffectiveDate == null) {
        item.EffectiveDate = '';
      }

      if (item.ExpirationDate == null) {
        item.ExpirationDate = '';
      }

      for (let key in item) {
        if (key == 'EffectiveDate') {
          item[key] = this.pipe.transform(item[key], Constants.DATE_FMT);
        } else if (key == 'ExpirationDate') {
          item[key] = this.pipe.transform(item[key], Constants.DATE_FMT);
          // } else if (key == 'CreatedByDate') {
          //   item[key] = this.pipe.transform(item[key], Constants.DATE_FMT);
          // } else if (key == 'UpdatedByDate') {
          //   item[key] = this.pipe.transform(item[key], Constants.DATE_FMT);
        } else if (key == 'PolicyExp') {
          item[key] = this.pipe.transform(item[key], Constants.DATE_FMT);
        } else if (key == 'DiscountRate') {
          item.DiscountRateValue = item[key];
          item[key] = this.getPercent(item[key]);
        }
      }
    }

    return tabledata;
  }

  formatTableRowData(tabledata: any): void {
    let efffectiveDate = tabledata.find(
      (item: any) => item.EffectiveDate == undefined,
    );
    let expirationDate = tabledata.find(
      (item: any) => item.ExpirationDate == undefined,
    );

    if (efffectiveDate == null) {
      tabledata.EffectiveDate = '12-23-2019';
    } else if (expirationDate == null) {
      tabledata.ExpirationDate = '12-23-2019';
    }
    for (let key in tabledata) {
      if (key == 'EffectiveDate') {
        tabledata[key] = this.pipe.transform(
          tabledata[key],
          Constants.DATE_FMT,
        );
      } else if (key == 'ExpirationDate') {
        tabledata[key] = this.pipe.transform(
          tabledata[key],
          Constants.DATE_FMT,
        );
      } else if (key == 'CreatedDate') {
        tabledata[key] = this.pipe.transform(
          tabledata[key],
          Constants.DATE_FMT,
        );
      } else if (key == 'ModifiedDate') {
        tabledata[key] = this.pipe.transform(
          tabledata[key],
          Constants.DATE_FMT,
        );
      } else if (key == 'PolicyEff') {
        tabledata[key] = this.pipe.transform(
          tabledata[key],
          Constants.DATE_FMT,
        );
      } else if (key == 'PolicyExp') {
        tabledata[key] = this.pipe.transform(
          tabledata[key],
          Constants.DATE_FMT,
        );
      } else if (key == 'DiscountRate') {
        tabledata[key] = tabledata[key];
        tabledata[key] = this.getPercent(tabledata[key]);
      }
    }

    return tabledata;
  }

  getFormattedPrice(price: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'code',
      minimumFractionDigits: 0,
    }).format(price);
  }

  getPercent(price: number) {
    return new Intl.NumberFormat('en-US').format(price) + '%';
  }

  setPercent(price: number) {
    return new Intl.NumberFormat('en-US').format(price);
  }

  getColumnTableData(columnfield: string, tabledata: any): any {
    let columnvalue = '';
    for (let item in tabledata) {
      if (columnfield == item) {
        columnvalue = tabledata[item];
      }
    }

    return columnvalue;
  }

  getPopUpData(promoCodeId: any, apiaction: string): any {
    this.modalData = {};
    let i = 0;
    for (let itemDataTable of this._dataTableDataList) {
      if (apiaction.toLowerCase() == itemDataTable.apiaction.toLowerCase()) {
        for (let itemRowData of itemDataTable.data) {
          if (promoCodeId == itemRowData.promoCodeGuid) {
            let shallowCopyObj = JSON.parse(JSON.stringify(itemRowData));
            if (shallowCopyObj) {
              let discountRateFormatted = (shallowCopyObj.discountRate * 100);
              shallowCopyObj.discountRate = discountRateFormatted;
            }
            this.modalData = shallowCopyObj;
          }
        }
      }
    }

    return this.modalData;
  }

  getPopUpDatabyColumn(
    columnValue: any,
    columnkeyText: string | number,
    apiaction: string,
  ): any {
    this.modalData = {};
    let i = 0;
    for (let itemDataTable of this._dataTableDataList) {
      if (apiaction.toLowerCase() == itemDataTable.apiaction.toLowerCase()) {
        for (let itemRowData of itemDataTable.data) {
          if (columnValue == itemRowData[columnkeyText]) {
            this.modalData = itemRowData;
          }
        }
      }
    }

    return this.modalData;
  }

  //ng Method
  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (
        event.target.attributes['ispopup'] != undefined &&
        event.target.attributes['ispopup'].value == 'true'
      ) {
        let promoCodeId = event.target.attributes['promoCodeId'].value;
        let columnText = event.target.attributes['columnText'].value;
        let apiaction = event.target.attributes['apiaction'].value;
        let data: any;
        data = this.getPopUpData(promoCodeId, apiaction);
        // Load current club selection
        if (columnText == 'Edit') {
          this.isAddPromoCodeLoader = false;
          this.onClickEditPopupGrid(data);
          this.open(this.contentmodalEditPromoCode, {
            Heading: 'Edit Promo Code',
            ItemRowData: data,
          });
          this.currentClubId = null;
          setTimeout(() => {
            if (data && data.autoClubGuid) {
              this.currentClubId = data.autoClubGuid;
            }
          }, 0);
        }
        else {
          this.isDeleteLoader = false;
          this.open(this.deleteConfirmationBox, {
            Heading: 'Delete Promo Code',
            ItemRowData: data,
          });
        }
      } else if (
        event.target.attributes['linkedurl1'] != undefined &&
        event.target.attributes['linkedurl1'].value != ''
      ) {
        let policytxnid = event.target.attributes['policytxnid'].value;
        let linkedurl1 = event.target.attributes['linkedurl1'].value;
        sessionStorage.setItem('policytxnid', policytxnid.toString());
        location.href = location.protocol + '//' + location.host + linkedurl1;
      }
    });
  }

  clubChange(event: any) {
    let value = event.target.value;
    this.currentClubId = value;
  }

  ngOnDestroy(): void { }

  onClickEditPopupGrid(item: { autoClubGuid: any; promocodeGuid: any; PromoCodeId: any; ContactProducerId: any; promoCode: any; promoCodeDescription: any; discountRate: any; effectiveDate: any; expirationDate: any; }) {
    this.originalPromoName = '';
    this.originalPromoCode = CommonUtility.shallowCpyJSONTrimObj(item);
    this.editSubmitted = false;
    let effectiveDate = Utility.utcToAestDate(item.effectiveDate);
    this.minValidDateEdit = {
      year: effectiveDate.getFullYear(),
      month: effectiveDate.getMonth() + 1,
      day: effectiveDate.getDate(),
    };
    this.editPromoCodeForm = this.promoCodeService.AddPromoCodeForm();

    this.promoCodesArray = this.editPromoCodeForm.get('ContactProducerPromoCodes') as FormArray;
    const promoCodeGroup = this.promoCodesArray.controls[0];

    // Set values for the FormGroup
    promoCodeGroup.patchValue({
      promoCodeGuid: item.promocodeGuid || item.PromoCodeId || '',
      autoClubGuid: item.autoClubGuid || null,
      promoCode: item.promoCode || '',
      promoCodeDescription: item.promoCodeDescription || '',
      discountRate: item.discountRate || '',
      effectiveDate: item.effectiveDate ? this.dateService.setFormDateFormat(item.effectiveDate) : '12/12/2024',
      expirationDate: item.expirationDate ? this.dateService.setFormDateFormat(item.expirationDate) : '12/12/2024',
    });

    // this.editPromoCodeForm.controls[
    //   'ContactProducerPromoCodes'
    // ].value.controls.promoCodeGuid.setValue(
    //   item.promocodeGuid != undefined ? item.promocodeGuid : '',
    // );
    // this.editPromoCodeForm.controls[
    //   'ContactProducerPromoCodes'
    // ].value[0].controls.autoClubId.setValue(
    //   item.AutoClubId != undefined ? item.AutoClubId : 0,
    // );
    // this.editPromoCodeForm.controls[
    //   'ContactProducerPromoCodes'
    // ].value[0].controls.promoCode.setValue(
    //   item.PromoCode != undefined ? item.PromoCode : '',
    // );
    // this.editPromoCodeForm.controls[
    //   'ContactProducerPromoCodes'
    // ].value[0].controls.PromoCodeDescription.setValue(
    //   item.PromoCodeDescription != undefined ? item.PromoCodeDescription : '',
    // );
    // this.editPromoCodeForm.controls[
    //   'ContactProducerPromoCodes'
    // ].value[0].controls.discountRate.setValue(
    //   item.DiscountRateValue != undefined ? item.DiscountRateValue : '',
    // );
    // this.editPromoCodeForm.controls[
    //   'ContactProducerPromoCodes'
    // ].value[0].controls.effectiveDate.setValue(
    //   item.EffectiveDate != undefined
    //     ? this.dateService.setFormDateFormat(item.EffectiveDate)
    //     : '',
    // );

    // this.editPromoCodeForm.controls[
    //   'ContactProducerPromoCodes'
    // ].value[0].controls.expirationDate.setValue(
    //   item.ExpirationDate != undefined
    //     ? this.dateService.setFormDateFormat(item.ExpirationDate)
    //     : '',
    // );
    //add IsDeleted flag since it is mandatory for the API, otherwise it will fail
  }

  selectionChange(event: any) {
    let value = event.target.value;
    this.selectedClub = value;
  }

  maxEffDateAdd() {
    let dt = this.addPromoCodeForm.value.ContactProducerPromoCodes[0].expirationDate;
    if (dt) {
      return {
        year: dt.year,
        month: dt.month,
        day: dt.day,
      };
    } else {
      return this.expMaxValidDate
    }
  }

  maxEffDateEdit() {
    let dt = this.editPromoCodeForm.value.ContactProducerPromoCodes[0].expirationDate;
    if (dt) {
      return {
        year: dt.year,
        month: dt.month,
        day: dt.day
      }
    } else {
      return this.expMaxValidDateEdit;
    }
  }

  minExpDateAdd() {
    if (this.addPromoCodeForm.value.ContactProducerPromoCodes[0].effectiveDate != undefined) {
      let dt = this.addPromoCodeForm.value.ContactProducerPromoCodes[0].effectiveDate;
      return {
        year: dt.year,
        month: dt.month,
        day: dt.day
      }
    } else {
      return this.minValidDate;
    }
  }

  minExpDateEdit() {
    if (this.editPromoCodeForm.value.ContactProducerPromoCodes[0].effectiveDate != undefined) {
      let dt = this.editPromoCodeForm.value.ContactProducerPromoCodes[0].effectiveDate;
      return {
        year: dt.year,
        month: dt.month,
        day: dt.day
      };
    } else {
      return this.minValidDateEdit;
    }
  }

  addPromoCodeFormSubmit(eventtype: string | undefined) {

    eventtype = eventtype == undefined ? 'add' : eventtype;

    if (eventtype == 'add') {
      //add IsDeleted flag since it is mandatory for the API, otherwise it will fail
      //without this adding the second promo code breaks
      if (this.addPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].IsDeleted === undefined || this.addPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].IsDeleted === null) {
        this.addPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].IsDeleted
      }
      this.isAddPromoCodeLoader = true;
      this.submitted = true;

      if (!this.selectedClub || this.selectedClub == undefined || this.selectedClub == 0) {
        this.isAddPromoCodeLoader = false;
        return;
      }

      if (this.addPromoCodeForm.invalid) {
        this.isAddPromoCodeLoader = false;
        return false;
      }
    }
    else if (eventtype == 'edit') {

      this.isEditPromoCodeLoader = true;
      this.editSubmitted = true;
      if (this.editPromoCodeForm.invalid) {
        this.isEditPromoCodeLoader = false;
        return false;
      }
    }
    this.userName = this.userProfile ? this.userProfile.LoggedUser : '';
    this.originalPromoName = '';

    // let selectedClubName = this.listClubs.find( (d: { id: any }) => d.id == this.selectedClub);

    // let promoCode = eventtype == 'add' ? this.addPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].promoCode : this.editPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].promoCode

    // let discountValue = eventtype == 'add' ? this.addPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].discountRate : this.editPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].discountRate

    // let effDate = eventtype == 'add' ? this.dateService.setDefaultDateFormat( this.addPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].effectiveDate ) : this.dateService.setDefaultDateFormat( this.editPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].effectiveDate );

    // let expDate = eventtype == 'add' ? this.dateService.setDefaultDateFormat( this.addPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].expirationDate ) : this.dateService.setDefaultDateFormat( this.editPromoCodeForm.get('ContactProducerPromoCodes')?.value[0].expirationDate );

    //check if object changed
    if (eventtype == 'edit') {
      let newPromoObj = CommonUtility.shallowCpyJSONTrimObj(this.editPromoCodeForm.value.ContactProducerPromoCodes[0]);

      if (newPromoObj.promoCodeGuid == this.originalPromoCode.promoCodeGuid && newPromoObj.promoCode != this.originalPromoCode.promoCode) {
        this.originalPromoName = this.originalPromoCode.promoCode;
      }
    }

    this.promoCodeService.savePromoCodeRequest(eventtype == 'add' ? this.addPromoCodeForm.value : this.editPromoCodeForm.value, eventtype == 'add' ? this.selectedClub : this.currentClubId, this.userName).subscribe({
      next: (resp: any) => {
        this.expirationDateErr = false;
        if (
          resp.responseMessage != undefined &&
          resp.errorMessages.length == 0 &&
          resp.status == true
        ) {
          this.modalService.dismissAll();
          this.getSitecoreDataTableDataList();
          this.submitted = false;
          this.addPromoCodeForm.reset({ ContactProducerPromoCodes: [{ autoClubGuid: '0' }] });
          if (this.listClubs.length == 1) {
            this.selectedClub = this.listClubs[0].autoClubGuid;
          }
          else {
            this.selectedClub = "";
          }

          this.open(this.confirmationBox, {
            Msg: `Promo Code has been ${eventtype == 'add' ? eventtype + 'ed' : eventtype} successfully`,
          });
        } else if (
          resp.responseMessage != undefined &&
          resp.errorMessages.length > 0
        ) {
          this.modalService.dismissAll();
          this.open(this.warningBox, { Error: resp.ResponseStatus.Message });
        } else {

          this.modalService.dismissAll();
          this.open(this.warningBox, { Error: resp.message });
        }

        this.isEditPromoCodeLoader = false;
        this.isAddPromoCodeLoader = false;
      },
      error: (err) => {
        // let ob = eventtype == 'add' ? this.addPromoCodeForm.value : this.editPromoCodeForm.value;
        // this.logService.createErrorLog( ob, err, 'SavePromoCodeRequest', PageNameEnum.PromoCode, LogLevel.Error, );
        this.isAddPromoCodeLoader = false;
        this.isEditPromoCodeLoader = false;
      },
    }
    );
  }

  onClickDeletePromoCode(item: {
    autoClubGuid: any;
    promoCodeGuid: any;
    promoCode: any;
    discountRate: any;
    clubName: any;
    effectiveDate: any;
    expirationDate: any;
  }) {
    this.originalPromoName = '';
    this.isDeleteLoader = true;
    let deletePromoCode: any[] = [];

    let deletePromeCodeObject = {
      promoCodeGuid: item.promoCodeGuid,
      modifiedBy: this.userName,
      isActive: false,
    }

    deletePromoCode.push(deletePromeCodeObject);

    this.promoCodeService
      .deletePromoCodeRequest(deletePromoCode[0])
      .subscribe({
        next:
          (resp) => {
            if (
              resp.responseMessage != undefined &&
              (resp.errorMessages.length == 0 || resp.errorMessages == null) &&
              resp.status == true
            ) {
              this.modalService.dismissAll();
              //Create Activity log on Sucessful Deletion
              // this.writeActivity(
              //   item.promoCode,
              //   'delete',
              //   item.discountRate,
              //   item.clubName,
              //   item.autoClubId,
              //   item.effectiveDate,
              //   item.expirationDate,
              // );
              this.open(this.confirmationBox, {
                Msg: 'Promo Code has been deleted successfully ',
              });
              this.getSitecoreDataTableDataList();
            } else if (
              resp.ResponseStatus != undefined &&
              resp.ResponseStatus.ErrorCode != null
            ) {
              this.modalService.dismissAll();
              this.open(this.warningBox, { Error: resp.ResponseStatus.Message });
            } else {
              this.modalService.dismissAll();
              this.open(this.warningBox, { Error: resp.ErrorMessages[0] });
            }
            this.isDeleteLoader = false;
          }
        , error:
          (err) => {
            this.logService.createErrorLog(
              deletePromoCode[0],
              err,
              'DeletePromoCodeRequest',
              PageNameEnum.PromoCode,
              LogLevel.Error,
            );
          },
      }
      );
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  //calender
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      date.equals(this.toDate) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate, input: string): NgbDate | any {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  addPromoCodeFormReset() {
    this.addPromoCodeForm.reset({ ContactProducerPromoCodes: [{ autoClubGuid: "0" }] });
    if (this.listClubs.length == 1) {
      this.selectedClub = this.listClubs[0].autoClubGuid;
    }
    else {
      this.selectedClub = "";
    }
    this.submitted = false;
    setTimeout(() => {
      this.listClubs = this.listClubs;
    }, 500);
  }

  exportPromocodesToExpire() {
    let reqObj =
    {
      isPaginationEnabled: false,
      pageNo: 0,
      pageSize: 0,
      promoCode: "",
      autoClubGuid: null,
      effectiveDate: "",
      expirationDate: "",
      isExpired: true,
      loginAgentGuid: this.userRoleDetails?.AgentContactId

    }
    if (this.promocodeExportType == 'expired') {
      this.promoCodeService.getOctaAgencyPortalApiServiceList(reqObj).subscribe((res) => {
        res.response = this.formatTableData(JSON.parse(JSON.stringify(res.response)));
        let promoList = this.exportReadyPromo(res.response);
        promoList = promoList.map(function (promo: {
          promoCodeGuid: any;
          PromoCode: any;
          PromoCodeDescription: any;
          EffectiveDate: any;
          ExpirationDate: any;
          DiscountRate: any;
          ClubName: any;
          Status: any
        }) {
          var obj: any = {};
          obj[ExpPromoHeaders.Id] = promo.promoCodeGuid;
          obj[ExpPromoHeaders.PromoCode] = promo.PromoCode;
          obj[ExpPromoHeaders.Description] = promo.PromoCodeDescription;
          obj[ExpPromoHeaders.EffectiveDate] = promo.EffectiveDate;
          obj[ExpPromoHeaders.ExpirationDate] = promo.ExpirationDate;
          obj[ExpPromoHeaders.DiscountRate] = promo.DiscountRate;
          obj[ExpPromoHeaders.ClubName] = promo.ClubName;
          obj[ExpPromoHeaders.Status] = promo.Status;
          return obj;
        });
        if (promoList.length >= 1) {
          this.exportService.downloadExpiredPromoCSV(promoList);
        } else {
          let message = 'No expiring promocodes found';
          this.showPromoExportError(message);
        }
      });
    }
  }

  showPromoExportError(message: string) {
    this.exportPromoError = true;
    this.exportPromoErrorMsg = message;
    setTimeout((v: any) => {
      this.exportPromoError = false;
    }, 30000);
    return;
  }

  // writeActivity(
  //   promoCode: any,
  //   type: any,
  //   discount: string,
  //   selectedClub: any,
  //   contactId: any,
  //   effDate: string,
  //   expDate: string,
  // ) {
  //   let activityType =
  //     type == 'add'
  //       ? ActivityType.PCCreate
  //       : type == 'edit'
  //         ? ActivityType.PCEdit
  //         : type == 'delete'
  //           ? ActivityType.PCDelete
  //           : '';
  //   let activityDescription = `promoCode ${promoCode} of ${selectedClub} with ${discount} discount and dates ${effDate} to ${expDate} ${type == 'add' ? 'created' : type == 'edit' ? 'modified' : type == 'delete' ? 'deleted' : ''}${type != 'delete' && this.originalPromoName.length > 1 ? ' from ' + this.originalPromoName : ''}`;
  //   this.promoCodeService.createActivityLogItem(
  //     activityType,
  //     activityDescription,
  //     contactId,
  //   );
  // }
}


