<header>
  <div id="top-header" class="header-bg" [class.header-bg-racv]="isRACV">
    <section class="container">
      <div
        class="container h-100 d-flex justify-content-between align-items-center"
      >
        <a class="navbar-brand py-3" href="/dashboard" *ngIf="isShow">
          <img [src]="heroImageUrl" class="header-logo" [alt]="heroImageAlt" />
        </a>
        <div
          class="d-flex flex-md-row-reverse flex-column justify-content-center"
          *ngIf="isShow"
        >
          <div class="contact-block select-site">
            <div class="contact-text">
              <div class="dropdown user-dropdown show">
                <a
                  class="dropdown-toggle user-name"
                  href="javascript:void(0);"
                  role="button"
                  id="dropdownMenuLink"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  WELCOME {{ loggedUser }}
                  <img
                    class="ml-2 user-name-arrow"
                    src="assets/images/down-arrow.svg"
                    alt="Logo"
                  />
                </a>
                <div
                  class="dropdown-menu top-link"
                  aria-labelledby="dropdownMenuLink"
                >
                  <a
                    class="dropdown-item"
                    href="javascript:void(0);"
                    (click)="logout()"
                  >
                    <i class="fas fa-sign-out-alt"></i>
                    <span>Logout</span>
                  </a>
                </div>
              </div>
              <div>{{ roleText }}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</header>
<div class="clearfix"></div>
