export enum SiteNameEnum {
  W2C = 'World 2 Cover',
  RACV = 'Royal Automobile Club of Victoria',
  RAC = 'The Royal Automobile Club of WA',
  RAA = 'Royal Automobile Association of South Australia',
  RACQ = 'Royal Automobile Club of Queensland',
  AANT = 'Automobile Association of the Northern Territory',
  RACT = 'ROYAL AUTOMOBILE CLUB OF TASMANIA',
}

export enum PortalCodeEnum {
  W2C = 'W2C',
  RACV = 'RACV',
  RAC = 'RAC',
  RAA = 'RAA',
  RACQ = 'RACQ',
  AANT = 'AANT',
  RACT = 'RACT',
}

export enum RoleTypeEnum {
  IsUnderwriter = 'IsUnderwriter',
  IsSuperUser = 'IsSuperUser',
  IsClubAdmin = 'IsClubAdmin',
  IsAgent = 'IsAgent',
  IsReadOnly = 'IsReadOnly',
  IsTMMReadOnly = 'IsTMMReadOnly',
}

export enum RoleTypeTextEnum {
  IsUnderwriter = 'Super Admin',
  IsSuperUser = 'Super User',
  IsReadOnly = 'Club Read Only',
  IsClubAdmin = 'Club Admin',
  IsAgent = 'Club Agent',
  IsTMMReadOnly = 'W2c ReadOnly',
}

export enum OktaGropPortalCodeEnum {
  TMMASuperAdmin = 'TMMASuperAdmin',
  TMMAAgent = 'TMMAAgent',
  W2C = 'ReadOnly',
  RACV = 'RACV',
  RAC = 'RAC',
  RAA = 'RAA',
  RACQ = 'RACQ',
  AANT = 'AANT',
  RACT = 'RACT',
}
