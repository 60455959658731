<div class="card-gray">
  <h1 class="card-gray-header">TRAVELLERS</h1>
  <div class="card-edit">
    <a href="javascript:void(0);" *ngIf="isUpdateENCalled && isEditBtnRequired">
      <i class="far fa-edit" [ngClass]="{ 'spinner-border spinner-border-sm': isLoader }" data-toggle="modal"
        (click)="openModal()"></i>
    </a>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item item-list">
      <div class="row">
        <div class="col-12 box-label-sm">Primary</div>
      </div>
      <div class="row">
        <div class="col-8">
          {{ policyTravellerDetail.PrimaryTravellerFirstName+" "+policyTravellerDetail.PrimaryTravellerLastName }} ,
          {{ PrimaryTravelerAge }}
        </div>
        <div class="col-4 text-right word-break">
          {{ policyTravellerDetail.PrimaryTravellerAddress }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-8">
          {{ policyTravellerDetail.PrimaryTravellerDOB }}
        </div>
        <div class="col-4 text-right word-break">
          <a href="" target="_top">{{ policyTravellerDetail.PrimaryTravellerEmailID }}</a>
          <br />
          {{ policyTravellerDetail.PrimaryTravellerPhoneNum | formatNumber }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-4">
          {{ policyTravellerDetail.MembershipNumber }}
        </div>
      </div>
    </li>
  </ul>
  <hr class="m-0" />
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row">
        <div class="col-12 box-label-sm">Secondary</div>
      </div>
      <div class="row" *ngIf="policyTravellerDetail.SecondaryTravellerFirstName">
        <div class="col-6">
          {{ policyTravellerDetail.SecondaryTravellerFirstName+" "+policyTravellerDetail.SecondaryTravellerLastName }},
          {{ SecondaryTravellerAge }}
        </div>
        <div class="col-6 text-right">
          {{ policyTravellerDetail.SecondaryTravellerDOB}}
        </div>
        <div class="col-12" [hidden]="!isMessageVisible">
          <div class="alert alert-success mt-3" role="alert">
            {{ msg }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

<ng-template #dialogContentTemplate>
  <div *ngIf="isLoader">
    <div class="clearfix"></div>
    <div class="overlay-container position-absolute"></div>
    <div class="loader"></div>
  </div>

  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="col-md-12">
        <h5 class="mb-4 text-center">Update Traveller Details</h5>
        <div *ngIf="DuplicateUser" class="invalid-feedback" style="display: block !important">
          {{ errorMessages.duplicateUserErrorMsg }}
        </div>
        <h2 class="mt-3 mb-3 modal-label">Adult Travellers</h2>
        <div class="form-row mt-3">
          <div class="form-group col-md-12 col-lg-4">
            <input type="text" class="form-control" length="50" formControlName="firstName" placeholder="First Name"
              [value]="contactForm.get('firstName')?.value"
              [ngClass]="{ 'is-invalid': submitted && f['firstName'].errors }" />
            <div *ngIf="submitted && f['firstName'].errors" class="invalid-feedback">
              <div *ngIf="f['firstName'].errors['required']">
                Please enter the First Name of the Primary Traveller.
              </div>
              <div *ngIf="f['firstName']?.errors?.['invalidCharacters']">
                First Name of Primary Traveller cannot contain special characters or numbers.
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 col-lg-4">
            <input type="text" class="form-control" length="50" formControlName="lastName" placeholder="Last Name"
              [value]="contactForm.get('lastName')?.value | nameFormat: 3"
              [ngClass]="{ 'is-invalid': submitted && f['lastName'].errors }" />
            <div *ngIf="submitted && f['lastName'].errors" class="invalid-feedback">
              <div *ngIf="f['lastName'].errors['required']">
                Please enter the Last Name of the Primary Traveller.
              </div>
              <div *ngIf="f['lastName']?.errors?.['invalidCharacters']">
                Last Name of Primary Traveller cannot contain special characters or numbers.
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 col-lg-4">
            <div class="input-group date-field nopadding" id="">
              <input type="text" class="form-control input-sm col-12" name="dp" formControlName="dob" ngbDatepicker
                #d3="ngbDatepicker" placeholder="Date of Birth" [minDate]="{ year: 1901, month: 1, day: 1 }"
                [ngClass]="{ 'is-invalid': submitted && f['dob'].errors }" />
              <span class="input-group-text">
                <i class="far fa-calendar-alt" (click)="d3.toggle()" type="button"></i>
              </span>
            </div>
            <div *ngIf="submitted && f['dob'].errors" class="error-msg">
              <div *ngIf="f['dob'].errors?.['required']"> Please enter the date of birth </div>
              <div
                *ngIf=" f['dob'].errors?.['ageRange'] && (coverageTypeName == 'ANNUAL MULTI-TRIP' || coverageTypeName == 'ANNUAL MULTI TRIP')">
                Please enter the age between 18 to {{ ageLength }} years for annual multi trip plan </div>
              <div
                *ngIf=" f['dob'].errors?.['ageRange'] && (coverageTypeName !== 'ANNUAL MULTI-TRIP' && coverageTypeName !== 'ANNUAL MULTI TRIP' )">
                Please enter the age between 18 to {{ ageLength }} years </div>
              <div *ngIf="f['dob'].errors?.['invalidDate']">
                Please enter a valid date of birth
              </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-3" formArrayName="secondaryTraveller"
          *ngFor=" let item of items.controls; let i = index ">
          <div class="form-row w-100" [formGroupName]="i">
            <div class="form-group col-md-12 col-lg-4">
              <input type="email" class="form-control" length="50" formControlName="SecondaryfirstName"
                placeholder="First Name"
                [ngClass]="{ 'is-invalid': secondrySubmit != null && !secondrySubmit && submitted && item.get('SecondaryfirstName')?.errors }" />
              <div
                *ngIf=" secondrySubmit != null && !secondrySubmit && submitted && item.get('SecondaryfirstName')?.errors"
                class="invalid-feedback">
                <div *ngIf="item.get('SecondaryfirstName')?.errors?.['required']">
                  First Name is required
                </div>
                <div *ngIf="item.get('SecondaryfirstName')?.errors?.['invalidCharacters']">
                  First Name of Secondary Traveller cannot contain special characters or numbers.
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-4">
              <input type="email" class="form-control" length="50" formControlName="SecondaryLastName"
                placeholder="Last Name"
                [ngClass]="{ 'is-invalid': secondrySubmit != null && !secondrySubmit && submitted && item.get('SecondaryLastName')?.errors }" />
              <div
                *ngIf=" secondrySubmit != null && !secondrySubmit && submitted && item.get('SecondaryLastName')?.errors "
                class="invalid-feedback">
                <div *ngIf="item.get('SecondaryLastName')?.errors?.['required']">
                  Last Name is required
                </div>
                <div *ngIf="item.get('SecondaryLastName')?.errors?.['invalidCharacters']">
                  Last Name of Secondary Traveller cannot contain special characters or numbers.
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-3">
              <div class="input-group date-field nopadding" id="">
                <input type="text" class="form-control input-sm col-12" name="dp" formControlName="dob1" ngbDatepicker
                  #d4="ngbDatepicker" placeholder="Date of Birth" [minDate]="{ year: 1901, month: 1, day: 1 }"
                  [ngClass]="{ 'is-invalid': secondrySubmit != null && !secondrySubmit && submitted && item.get('dob1')?.errors }" />
                <div class="input-group-append">
                  <span class="input-group-text">
                    <i class="far fa-calendar-alt" (click)="d4.toggle()" type="button"></i>
                  </span>
                </div>
                <div *ngIf=" secondrySubmit != null && !secondrySubmit && submitted && item.get('dob1')?.errors"
                  class="error-msg">
                  <div *ngIf="item.get('dob1')?.errors?.['required']"> Please enter the secondary traveller’s date of
                    birth </div>
                  <div *ngIf="item.get('dob1')?.errors?.['ageRange']"> Please enter the age between 18 to {{ ageLength
                    }} years </div>
                  <div *ngIf="item.get('dob1')?.errors?.['invalidDate']">
                    Please enter a valid date of birth
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-1 text-center mt-1">
              <a><i class="far fa-trash-alt" (click)="deleteIndividualRow(i)"></i></a>
            </div>
          </div>
        </div>
        <div class="form-row mt-3" *ngIf="items.controls.length == 0">
          <button (click)="addNewRow()">+</button>
        </div>

        <h2 class="mt-3 mb-3 modal-label">Contact Info Of Primary Traveller</h2>
        <div class="form-row mt-3">
          <div class="form-group col-md-12">
            <input type="text" class="form-control" placeholder="Street Address" (keyup)="doFilterForAddressify()"
              formControlName="address" [ngClass]="{ 'is-invalid': submitted && f['address'].errors }" />
            <app-combo-box *ngIf="filteredAddress.length != 0" [list]="filteredAddress"
              (selectedAddr)="setSelectedAddr($event)"></app-combo-box>
            <div *ngIf="submitted && f['address'].errors" class="invalid-feedback">
              <div *ngIf="f['address'].errors['required']"> Please enter the Street Address. </div>
            </div>
            <div *ngIf="submitted && f['address'].errors?.['maxlength']" class="invalid-feedback"> Address can be max 60
              characters long. </div>
          </div>

          <div class="form-group col-md-12 col-lg-4">
            <input type="text" class="form-control" formControlName="city" placeholder="Suburb"
              [ngClass]="{ 'is-invalid': submitted && f['city'].errors }" />
            <div *ngIf="submitted && f['city'].errors" class="invalid-feedback">
              <div *ngIf="f['city'].errors?.['required']"> Please enter the Suburb. </div>
            </div>
          </div>

          <div class="form-group col-md-12 col-lg-4">
            <select class="form-control" formControlName="state"
              [ngClass]="{ 'is-invalid': submitted && f['state'].errors }">
              <option *ngFor="let prf of stateData" [ngValue]="prf.code"> {{ prf.code }} </option>
            </select>

            <div *ngIf="submitted && f['state'].errors" class="invalid-feedback">
              <div *ngIf="f['state'].errors?.['required']"> Please enter the State. </div>
            </div>
          </div>

          <div class="form-group col-md-12 col-lg-4">
            <input type="text" class="form-control" formControlName="zipCode" placeholder="Postal Code"
              [ngClass]="{ 'is-invalid': submitted && f['zipCode'].errors }" />
            <div *ngIf="submitted && f['zipCode'].errors" class="invalid-feedback">
              <div *ngIf="f['zipCode'].errors?.['required']"> Please enter the Postal Code. </div>
              <div *ngIf="f['zipCode'].errors?.['invalidPostalCode']"> Please enter a valid postal code </div>
            </div>
          </div>

          <div class="form-group col-md-12 col-lg-4">
            <input type="email" class="form-control" placeholder="Email Address" formControlName="email"
              [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" />
            <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
              <div *ngIf="f['email'].errors?.['required']"> Please enter the email address. </div>
              <div *ngIf="f['email'].errors?.['email']"> Please enter a valid email address. </div>
            </div>
          </div>

          <div class="form-group col-md-12 col-lg-4">
            <input type="email" class="form-control" formControlName="confirmEmail" placeholder="Confirm Email Address"
              [ngClass]="{ 'is-invalid': submitted && f['confirmEmail'].errors }" />
            <div *ngIf="submitted && f['confirmEmail'].errors" class="invalid-feedback">
              <div *ngIf="f['confirmEmail'].errors['required']"> Please enter the confirm email address. </div>
              <div *ngIf="f['confirmEmail'].errors['mustMatch']"> Confirm email address does not match with email
                address </div>
            </div>
          </div>

          <div class="form-group col-md-12 col-lg-4">
            <input type="text" class="form-control" placeholder="Mobile/Phone Number" formControlName="phoneNumber"
              [ngClass]="{ 'is-invalid': submitted && f['phoneNumber'].errors }" />
            <div *ngIf="submitted && f['phoneNumber'].errors" class="invalid-feedback">
              <div *ngIf="f['phoneNumber'].errors?.['required']"> Phone Number is required </div>
              <div *ngIf=" f['phoneNumber'].touched && f['phoneNumber'].errors?.['invalidNumber'] "> Please enter a
                valid Phone Number </div>
            </div>
          </div>
        </div>

        <div class="form-row mt-3">
          <div class="form-group col-md-12 col-lg-4">
            <input type="text" class="form-control" formControlName="memberShip" placeholder="Membership Number"
              [ngClass]="{ 'is-invalid': submitted && f['memberShip'].errors }" />
            <div *ngIf="submitted && f['memberShip'].errors" class="invalid-feedback">
              <div *ngIf=" f['memberShip'].touched && f['memberShip'].errors?.['invalidNumber'] ">
                Please enter a valid 25-digit Membership Number
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center mb-4 mt-2 btn-footer">
      <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeModal1()">
        Cancel
      </button>
      <button class="btn btn-primary btn-full-sm">Save Changes</button>
    </div>
  </form>
</ng-template>

<ng-template #updateTravellerNotificationTemplate>
  <div>
    <div class="modal-body">
      <div class="col-md-10 mx-auto text-center">
        <div class="alert my-2">{{ updateMessage }}</div>
      </div>
    </div>
    <div class="text-center mb-4 btn-footer">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeAlertChildModal()">
        Ok
      </button>
    </div>
  </div>
</ng-template>

<!-- <ng-template #warningPIBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="d('Cross click')">
      &times;
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ PIModificationWarningMsg }}</div>
      </div>
    </div>
  </div>
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary" (click)="closePIWarningModal()" data-dismiss="modal">
      Cancel
    </button>
    <button type="button" class="btn btn-primary pull-right" (click)="onSubmit()">
      Save
    </button>
  </div>
</ng-template> -->