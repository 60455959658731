import { Injectable } from '@angular/core';
import { AppConfig } from '../shared/config/app.config';
import { HttpCoreService } from '../../../../../src/app/core/services/http-core.service';
import { Utility } from '../shared/utility';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public baseUrl = '';

  constructor(private config: AppConfig) {}

  getDestinationUrl() {
    let destinationUrl = '';
    let url = Utility.getBaseUrl();
    try {
      this.config.getConfig('url_mapping').forEach((element: any) => {
        //debugger;
        if (element.sourceUrl.includes(url)) {
          destinationUrl = element.destinationUrl;
          throw 'break-foreach';
        }
      });
    } catch (e) {}
    return destinationUrl;
  }

  getSiteName() {
    let URLFOR = '';
    let baseUrl = Utility.getBaseUrl();
    try {
      this.config.getConfig('register_domains').forEach((element: any) => {
        element.urls.forEach((url: any) => {
          if (baseUrl.includes(url)) {
            URLFOR = element.name;
            throw 'break-foreach';
          }
        });
      });
    } catch (e) {}
    return URLFOR;
  }
}
