import { Injectable } from '@angular/core';
import { EndPoint } from 'src/app/core/services/endpoint';
import { Observable } from 'rxjs';
import { catchError, timeout, finalize } from 'rxjs/operators';
import { CommonParams } from 'src/app/core/commonparams';
import { HttpCoreService } from '../../../../../../src/app/core/services/http-core.service';
import { Utility } from '../utility';
import { SetQueryParamsService } from 'src/app/core/role/setqueryparams';
import { SitecoreItemService } from '../../services/sitecore-item.service';
import { data } from 'jquery';

@Injectable({
  providedIn: 'root',
})
export class EndorsementGenericService {
  public policyData: any;
  public policyMasterData: any;
  public countryData: any;
  public contactData: any;
  public IsDomestic!: boolean;
  public policyIdUsed!: string;
  public NewBusinessSub: any;
  public policyTxnId!: string;

  constructor(
    private httpService: HttpCoreService,
    private setQueryParamsService: SetQueryParamsService,
    private itemService: SitecoreItemService,
  ) { }

  // async prepareRequestForEndorsement(policyTxnId: any) {
  //   //debugger;
  //   // for dummy commit
  //   //console.log("In prepareRequestForEndorsement policyTxnId: " + policyTxnId);

  //   // if( this.policyIdUsed && this.policyIdUsed == policyTxnId) {
  //   //   console.log('already fetched');
  //   // } else {
  //   //   this.policyIdUsed = policyTxnId;
  //   //   this.policyData = await this.httpService.getRequest(`${EndPoint.OCTAAPI_ENDPOINT}GetPolicy/${policyTxnId}`).toPromise();
  //   // }
  //   this.policyData = await this.httpService
  //     .getRequest(
  //       `${EndPoint.OCTAAPI_ENDPOINT}GetPolicy/${policyTxnId.toString()}`,
  //     )
  //     .toPromise();

  //   let storedMasterData;
  //   await Utility.asyncSessionStorage.getItem('pMaster').then((data: any) => {
  //     storedMasterData = JSON.parse(data);
  //   });
  //   let storedPolicyTypeIdData;
  //   await Utility.asyncSessionStorage.getItem('pTypeId').then((data: any) => {
  //     storedPolicyTypeIdData = JSON.parse(data);
  //   });

  //   if (
  //     !storedMasterData ||
  //     storedPolicyTypeIdData != this.policyData.PolicyTypeId
  //   ) {
  //     ///console.log("In prepareRequestForEndorsement called policyData");
  //     this.policyMasterData = await this.httpService
  //       .getRequest(
  //         `${EndPoint.OCTAAPI_ENDPOINT}PolicyMasterData/${this.policyData.PolicyTypeId}/GTA`,
  //       )
  //       .toPromise();
  //     //console.log("In prepareRequestForEndorsement called policyMasterData");
  //     if (this.policyMasterData) {
  //       Utility.asyncSessionStorage
  //         .setItem('pMaster', JSON.stringify(this.policyMasterData))
  //         .then((data) => {
  //           //console.log('Policy Master Data stored in session');
  //         });
  //       Utility.asyncSessionStorage
  //         .setItem('pTypeId', this.policyData.PolicyTypeId)
  //         .then((data) => {
  //           //console.log('Policy TypeId Data stored in session');
  //         });
  //     }
  //   } else {
  //     this.policyMasterData = storedMasterData;
    
  //   }
    
  //   let storedCountryData;
  //   await Utility.asyncSessionStorage.getItem('mCountry').then((data: any) => {
  //     storedCountryData = JSON.parse(data);
  //   });
  //   if (!storedCountryData) {
  //     this.countryData = await this.httpService
  //       .getRequest(`${EndPoint.OCTAAPI_ENDPOINT}GetCountryList`)
  //       .toPromise();
  //     if (this.countryData) {
  //       Utility.asyncSessionStorage
  //         .setItem('mCountry', JSON.stringify(this.countryData))
  //         .then((data) => {
  //           //console.log('Country Data stored in session');
  //         });
  //     }
  //   } else {
  //     this.countryData = storedCountryData;
  //   }
  //   let preparedReq = await this.prepareDefaultRequestObject(
  //     this.policyData,
  //     this.policyMasterData,
  //     this.countryData,
  //   );
  //   //console.log("In prepareRequestForEndorsement req object prepared: " + JSON.stringify(preparedReq));
  //   return preparedReq;
  // }

  // updateEndorsement(updateRequestObj: any): Observable<any> {
  //   let agentDetailsObj =
  //     this.setQueryParamsService.getAgentDetailsForAPIRequest();
  //   updateRequestObj.AgentDetails = agentDetailsObj.AgentDetails;
  //   this.policyTxnId = updateRequestObj.PolicyTxnId.toString();
  //   updateRequestObj.IsTravelerToUpdate =
  //     updateRequestObj.IsTravelerToUpdate != undefined
  //       ? updateRequestObj.IsTravelerToUpdate
  //       : true;
  //   updateRequestObj.IsRSXLogActive = this.checkforRSXLogging();
  //   //console.log("Update Endorsement Req: " + JSON.stringify(updateRequestObj));
  //   return this.httpService.putRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}UpdatePolicyDetailsGTA`,
  //     updateRequestObj,
  //   );
  // }

  async prepareDefaultRequestObject(
    policyData: any,
    policyMasterData: any,
    countryData: any,
  ) {
    this.requestDetails = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.RequestDetails',
    );

    this.NewBusinessSub = {
      CountryCode: policyData.CountryCode,
      CurrencyCode: policyData.CurrencyCode,
      PolicyProducerContactId: policyData.PolicyProducerContactId,
      StateId: policyData.StateId,
    };

    //  if (!this.IsDomestic) {
    let region = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.QuoteDetails.Region',
    );
    this.IsDomestic = region.split('|')[0] == 'Domestic' ? true : false;
    //  }

    this.quoteDetails = Object.assign({}, this.quoteDetails);
    let selectedQuoteElement = this.filterElementForPolicyMaster(
      'SelectedQuoteName',
      policyMasterData,
    );
    this.quoteDetails.QuoteName = this.filterFromElementValue(
      selectedQuoteElement.ElementId,
      policyData,
      policyMasterData,
      selectedQuoteElement.ResponseType,
    );

    let selectedExcessElement = this.filterElementForPolicyMaster(
      'Excess',
      policyMasterData,
    );
    this.quoteDetails.Excess = this.filterFromElementValue(
      selectedExcessElement.ElementId,
      policyData,
      policyMasterData,
      selectedExcessElement.ResponseType,
    );

    let selectedCruiseElement = this.filterElementForPolicyMaster(
      'IsCruise',
      policyMasterData,
    );
    this.quoteDetails.IsCruise = this.filterFromElementValue(
      selectedCruiseElement.ElementId,
      policyData,
      policyMasterData,
      selectedCruiseElement.ResponseType,
      'IsCruise',
    );

    let selectedSkiingElement = this.filterElementForPolicyMaster(
      'IsSking',
      policyMasterData,
    );
    this.quoteDetails.IsSkiing = this.filterFromElementValue(
      selectedSkiingElement.ElementId,
      policyData,
      policyMasterData,
      selectedSkiingElement.ResponseType,
      'IsSking',
    );

    let selectedMTLElement = this.filterElementForPolicyMaster(
      'MaxTripLength',
      policyMasterData,
    );
    this.quoteDetails.MultiTripLength = this.filterFromElementValue(
      selectedMTLElement.ElementId,
      policyData,
      policyMasterData,
      selectedMTLElement.ResponseType,
    );

    let selectedPTypeElement = this.filterElementForPolicyMaster(
      'ProductType',
      policyMasterData,
    );
    this.quoteDetails.ProductType = this.filterFromElementValue(
      selectedPTypeElement.ElementId,
      policyData,
      policyMasterData,
      selectedPTypeElement.ResponseType,
      'ProductType',
    );

    let selectedNODElement = this.filterElementForPolicyMaster(
      'NumOfDays',
      policyMasterData,
    );
    this.quoteDetails.NumOfDays = this.filterFromElementValue(
      selectedNODElement.ElementId,
      policyData,
      policyMasterData,
      selectedNODElement.ResponseType,
    );

    let selectedRBEElement = this.filterElementForPolicyMaster(
      'RentalBaseExcess',
      policyMasterData,
    );
    this.quoteDetails.RentalBaseExcess = this.filterFromElementValue(
      selectedRBEElement.ElementId,
      policyData,
      policyMasterData,
      selectedRBEElement.ResponseType,
    );

    let selectedRegionElement = this.filterElementForPolicyMaster(
      'RegionName',
      policyMasterData,
    );
    this.quoteDetails.Region = this.filterFromElementValue(
      selectedRegionElement.ElementId,
      policyData,
      policyMasterData,
      selectedRegionElement.ResponseType,
    );

    let selectedQ1Element = this.filterElementForPolicyMaster(
      'Question1',
      policyMasterData,
    );
    this.quoteDetails.Question1 = this.filterFromElementValue(
      selectedQ1Element.ElementId,
      policyData,
      policyMasterData,
      selectedQ1Element.ResponseType,
    );

    let selectedQ2Element = this.filterElementForPolicyMaster(
      'Question2',
      policyMasterData,
    );
    this.quoteDetails.Question2 = this.filterFromElementValue(
      selectedQ2Element.ElementId,
      policyData,
      policyMasterData,
      selectedQ2Element.ResponseType,
    );

    let selectedQ3Element = this.filterElementForPolicyMaster(
      'Question3',
      policyMasterData,
    );
    this.quoteDetails.Question3 = this.filterFromElementValue(
      selectedQ3Element.ElementId,
      policyData,
      policyMasterData,
      selectedQ3Element.ResponseType,
    );

    let selectedQ4Element = this.filterElementForPolicyMaster(
      'Question4',
      policyMasterData,
    );
    this.quoteDetails.Question4 = this.filterFromElementValue(
      selectedQ4Element.ElementId,
      policyData,
      policyMasterData,
      selectedQ4Element.ResponseType,
    );

    let selectedQ5Element = this.filterElementForPolicyMaster(
      'Question5',
      policyMasterData,
    );
    this.quoteDetails.Question5 = this.filterFromElementValue(
      selectedQ5Element.ElementId,
      policyData,
      policyMasterData,
      selectedQ5Element.ResponseType,
    );

    let selectedMembershipNumberElement = this.filterElementForPolicyMaster(
      'MembershipNumber',
      policyMasterData,
    );
    this.quoteDetails.MembershipNumber = this.filterFromElementValue(
      selectedMembershipNumberElement.ElementId,
      policyData,
      policyMasterData,
      selectedMembershipNumberElement.ResponseType,
    );

    this.quoteDetails.BranchName = ''; //NTA

    //this.traveler1.LastName = Utility.typeSafeHandle(policyData, "AdditionalPolicyDetailsGTA.PrimaryTravellerName", "find").split(',')[0].trim();
    // Traveler1 details
    this.traveler1 = Object.assign({}, this.traveler1);
    this.traveler1.FirstName = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.PrimaryTravellerName',
      'find',
    )
      .split(',')[1]
      .trim();
    this.traveler1.LastName = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.PrimaryTravellerName',
      'find',
    )
      .split(',')[0]
      .trim();
    this.traveler1.Age = Utility.ageFromDateOfBirth(
      Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.PrimaryTravellerDOB',
      ),
    );
    this.traveler1.BirthDate = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.PrimaryTravellerDOB',
    );
    this.traveler1.ContactId = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.PrimaryTravellerContactID',
      'string',
    );
    // this.traveler1.TravellerPhoneNum = Utility.typeSafeHandle(policyData, "AdditionalPolicyDetailsGTA.PrimaryTravellerPhoneNum", "string");
    this.traveler1.EmailAccts = [
      {
        IsDefault: true,
        EmailAddress: Utility.typeSafeHandle(
          policyData,
          'AdditionalPolicyDetailsGTA.PrimaryTravellerEmailID',
        ),
        EmailTypeId: 1,
      },
    ];
    let phoneNo = '0000000000';
    if (
      Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.PrimaryTravellerPhoneNum',
      )
    ) {
      phoneNo = Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.PrimaryTravellerPhoneNum',
      );
    }
    this.traveler1.PhoneNumbers = [
      {
        IsDefault: true,
        PhoneTypeId: 1,
        PhoneNum: phoneNo,
      },
    ];
    this.address.StateCode = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.StateCode',
    );
    this.address.CountryCode = policyData.CountryCode;
    this.address.StreetAddress = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.StreetAddress',
    );
    this.address.ZipCode = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.ZipCode',
    );
    this.address.City = Utility.typeSafeHandle(
      policyData,
      'AdditionalPolicyDetailsGTA.City',
    );

    //this.traveler1.PhoneNumbers = [
    //  {
    //    "IsDefault": true,
    //    "PhoneTypeId": 1,
    //    "PhoneNum": Utility.typeSafeHandle(policyData, "AdditionalPolicyDetailsGTA.PrimaryTravellerPhoneNum"),
    //  }
    //];

    // Traveler2 details
    //debugger;
    if (
      Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.SecondaryTravellerName',
      )
    ) {
      this.traveler2 = Object.assign({}, this.traveler2);
      this.traveler2.FirstName = Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.SecondaryTravellerName',
        'find',
      )
        .split(',')[1]
        .trim();
      this.traveler2.LastName = Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.SecondaryTravellerName',
        'find',
      )
        .split(',')[0]
        .trim();
      this.traveler2.Age = Utility.ageFromDateOfBirth(
        Utility.typeSafeHandle(
          policyData,
          'AdditionalPolicyDetailsGTA.SecondaryTravellerDOB',
        ),
      );
      this.traveler2.BirthDate = Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.SecondaryTravellerDOB',
      );
      this.traveler2.ContactId = Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.SecondaryTravellerContactID',
        'string',
      );
    }

    let dependents: any = [];
    if (
      Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.DependentTravellers',
      )
    ) {
      Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.DependentTravellers',
        'array',
      ).forEach((element: any) => {
        let dependent = Object.assign({}, this.dependent);
        dependent.FirstName = Utility.typeSafeHandle(
          element,
          'DependentTravellerName',
          'split',
        )
          .split(',')[1]
          .trim();
        dependent.LastName = Utility.typeSafeHandle(
          element,
          'DependentTravellerName',
          'split',
        )
          .split(',')[0]
          .trim();
        dependent.Age = Utility.ageFromDateOfBirth(
          Utility.typeSafeHandle(element, 'DependentTravellerDOB'),
        );
        dependent.BirthDate = Utility.typeSafeHandle(
          element,
          'DependentTravellerDOB',
        );
        dependent.ContactId = Utility.typeSafeHandle(
          element,
          'DependentTravellerContactID',
          'string',
        );
        dependent.Addresses = [this.address];
        dependents.push(dependent);
      });
    }

    this.updateRequestObj = Object.assign({}, this.updateRequestObj);

    this.updateRequestObj.QuoteDetails = this.quoteDetails;
    this.updateRequestObj.RequestDetails = this.requestDetails;
    this.updateRequestObj.NewBusinessSub = this.NewBusinessSub;
    this.updateRequestObj.PolicyId = policyData.PolicyId.toString();
    this.updateRequestObj.VersionNum = 1;
    this.updateRequestObj.PolicyTxnId = policyData.PolicyTxnId.toString();
    this.updateRequestObj.PolicyNum = policyData.PolicyNum;
    this.updateRequestObj.SubCompanyCode = policyData.SubCompanyCode;
    this.updateRequestObj.UWQuoteID = policyData.UWQuotes[0].UWQuoteId;
    this.updateRequestObj.WritingCompanyId = policyData.WritingCompanyId;
    this.updateRequestObj.QuoteDescription = 'Endorsement';
    this.updateRequestObj.IsExcess = Utility.isYes(
      Utility.typeSafeHandle(policyData, 'AdditionalPolicyDetailsGTA.Excess'),
    );
    this.updateRequestObj.Traveler1 = Object.assign(
      {},
      this.updateRequestObj.Traveler1,
    );
    this.updateRequestObj.Traveler1 = this.traveler1;
    this.updateRequestObj.Traveler1.Addresses = [];
    this.updateRequestObj.Traveler1.Addresses = [this.address];
    if (
      Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.SecondaryTravellerName',
      )
    ) {
      this.updateRequestObj.Traveler2 = Object.assign(
        {},
        this.updateRequestObj.Traveler2,
      );
      this.updateRequestObj.Traveler2 = this.traveler2;
      this.updateRequestObj.Traveler2.Addresses = [];
      this.updateRequestObj.Traveler2.Addresses = [this.address];
    }
    if (
      Utility.typeSafeHandle(
        policyData,
        'AdditionalPolicyDetailsGTA.DependentTravellers',
      )
    ) {
      this.updateRequestObj.Dependents = [];
      this.updateRequestObj.Dependents = dependents;
    }
    return this.updateRequestObj;

    // } catch (error) {
    //     console.log(JSON.stringify(error));
    //     return null;
    // }
  }

  // async getContactMasterData() {
  //   let storedContactMatserData;
  //   await Utility.asyncSessionStorage.getItem('mContact').then((data: any) => {
  //     storedContactMatserData = JSON.parse(data);
  //   });
  //   if (!storedContactMatserData) {
  //     storedContactMatserData = await this.httpService
  //       .getRequest(`${EndPoint.OCTAAPI_ENDPOINT}ContactMasterData`)
  //       .toPromise();
  //     if (storedContactMatserData) {
  //       Utility.asyncSessionStorage
  //         .setItem('mContact', JSON.stringify(storedContactMatserData))
  //         .then((data) => {
  //           //console.log('Contact Data stored in session');
  //         });
  //     }
  //     return storedContactMatserData;
  //   } else {
  //     return storedContactMatserData;
  //   }
  // }

  getInsuredContact() {
    return {
      ContactId: this.policyData.PolicyProducerContactId,
      LastName: Utility.random10DigitNumber(),
      NameTypeId: 0,
      OrgFullName: this.policyData.SubCompanyName,
      EntityTypeId: 2,
      Addresses: [this.address],
    };
  }
  // getNewBusinessSub() {
  //   let busClass = this.policyMasterData.ClassesOfBusiness.find(
  //     (bclass: any) => bclass.Code.toUpperCase() == 'TVL',
  //   );
  //   let subBusClass = this.policyMasterData.SubClassesOfBusiness.find(
  //     (sbclass: any) => sbclass.ParentId == busClass.Id,
  //   );
  //   return {
  //     PolicyId: this.policyData.PolicyId.toString(),
  //     PolicyTxnId: this.policyData.PolicyTxnId.toString(),
  //     PolicyTxnNum: this.policyData.PolicyNum,
  //     InsuredContactId: this.policyData.InsuredContactId,
  //     PolicyProducerContactId: this.policyData.PolicyProducerContactId,
  //     SubCompanyCode: this.policyData.SubCompanyCode,
  //     PolicyTypeId: this.policyData.PolicyTypeId,
  //     TermTypeId: this.policyData.TermTypeId,
  //     User1Id: this.policyData.User1Id,
  //     User2Id: this.policyData.User2Id,
  //     NextRenewalOptionId: this.policyData.NextRenewalOptionId,
  //     SubmissionTypeId: this.policyData.SubmissionTypeId,
  //     PolicyYear: this.policyData.PolicyYear,
  //     CountryCode: this.policyData.CountryCode,
  //     StateId: this.policyData.StateId,
  //     CurrencyCode: this.policyData.CurrencyCode,
  //     TxnPayPlanId: this.policyData.TxnPayPlanId,
  //     TxnBillToContactId: this.policyData.TxnBillToContactId,
  //     BusClassId: busClass.Id,
  //     BusSubClassId: subBusClass.Id,
  //     DateReceived: new Date(),
  //     CreateActivityLogItem: {
  //       ActivityDate: new Date(),
  //       ActivityType: 'PNEWBUSINESS',
  //       CreationDate: new Date(),
  //       Description: 'Web Portal Application entered',
  //       LoggedInUser: UserProfile.LoggedUser,
  //     },
  //   };
  // }

  // LTX Call 
  createEndorsementCall(reqObj: object) {
    return this.httpService.postRequest(
      `${EndPoint.EN_CREATE}`, reqObj);
  }

  // createEndorsement(reqObj: object) {
  //   if (sessionStorage.getItem('endorsementrespObj') === null) {
  //     return this.httpService.postRequest(
  //       `${EndPoint.OCTAAPI_ENDPOINT}CreateNewEndorsementGTA`,
  //       reqObj,
  //     );
  //   } else {
  //     let item = JSON.parse(sessionStorage.getItem('endorsementrespObj') || '');

  //     const observablerespobj = new Observable((observer) => {
  //       setTimeout(() => {
  //         observer.next(item);
  //       }, 1000);
  //     });
  //     return observablerespobj;
  //   }
  // }

  // createReinstatementGTA(reqObj: object) {
  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}CreateReinstatementGTA`,
  //     reqObj,
  //   );
  // }

  getAddressDetailsByAddressify(address: any) {
    return this.httpService
      .getRequest(
        `https://api.addressify.com.au/addresspro/info?api_key=82c0f158-98f2-411b-beb8-145fd6031e50&term=${address}`,
      )
      .toPromise();
  }

  filterElementForPolicyMaster(elementCode: any, policyMasterData: any) {
    return policyMasterData.UserConfigurableDataElements.find(
      (element: any) => element.ElementCode === elementCode,
    );
  }

  filterFromElementValue(
    elementId: any,
    policyData: any,
    masterData: any,
    dataType: any,
    elementCode: any = '',
  ) {
    let result: any;

    if (!elementId) {
      //console.log(`ElementId is blank`);
      result = null;
    }

    let filterData =
      policyData.UpdateUserConfigurableDataElement.ElementUpdateRequests.find(
        (element: any) => element.ElementId === elementId,
      );
    if (!filterData) {
      //console.log(`elementId: ${elementId} of PolicyData not available in: ` + JSON.stringify(policyData.UpdateUserConfigurableDataElement.ElementUpdateRequests));
      result = null;
    } else if (filterData) {
      switch (dataType.trim()) {
        case 'TXT':
          result = Utility.typeSafeHandle(
            filterData,
            'FreeTextValue',
            'string',
          );
          break;
        case 'INT':
          result = Utility.typeSafeHandle(filterData, 'IntegerValue', 'int');
          break;
        case 'DEC':
          result = Utility.typeSafeHandle(filterData, 'DecimalValue', 'int');
          break;
        case 'RB':
          {
            let responseValue = this.filterDataElementResponseValue(
              elementCode,
              masterData,
              filterData,
            );
            result = Utility.isYes(responseValue);
          }
          break;
        case 'SLT':
          {
            let responseValue = this.filterDataElementResponseValue(
              elementCode,
              masterData,
              filterData,
            );
            result = responseValue;
          }
          break;
        default:
          break;
      }
      return result;
    }
    return result;
  }

  filterFromPolicyElementValue(elementName: any, policyData: any) {
    let result: any;

    if (!elementName) {
      //console.log(`ElementName is blank`);
      result = null;
    }

    let filterData =
      policyData.UpdateUserConfigurableDataElement.ElementUpdateRequests.find(
        (element: any) => element.ElementName === elementName,
      );
    if (!filterData) {
      //console.log(`elementId: ${elementName} of PolicyData not available in: ` + JSON.stringify(policyData.UpdateUserConfigurableDataElement.ElementUpdateRequests));
      result = null;
    } else if (filterData) {
      switch (filterData.ResponseType.trim()) {
        case 'FreeTextResponse':
          result = filterData.FreeTextValue;
          break;
        default:
          break;
      }
      return result;
    }
    return result;
  }

  filterDataElementResponseValue(
    elementCode: any,
    masterData: any,
    filterData: any,
  ) {
    let responseValue = '';
    let element = this.filterElementForPolicyMaster(elementCode, masterData);
    if (filterData && filterData.SelectedDataElementResponseIds.length > 0) {
      responseValue = Utility.typeSafeHandle(
        element.DataElementResponses.find(
          (el: any) =>
            el.DataElementResponseId ==
            filterData.SelectedDataElementResponseIds[0],
        ),
        'ResponseValue',
      );
    } else {
      //console.log("SelectedDataElementResponseIds[0] of PolicyData not available in :" + JSON.stringify(element.DataElementResponses));
    }
    return responseValue;
  }

  // checkforRSXLogging() {
  //   let value = false;
  //   let itemData = this.checkForItemServiceData();
  //   if (itemData != undefined) {
  //     //Check for RSXLogging
  //     const genericSettingsItemId =
  //       this.itemService.getItemServiceID('GenericSettings');
  //     const genericSettingsData = itemData[genericSettingsItemId];
  //     value =
  //       genericSettingsData != null &&
  //         genericSettingsData.IsRSXLogActive != null &&
  //         genericSettingsData.IsRSXLogActive.toLowerCase() == 'true'
  //         ? true
  //         : false;
  //   }
  //   return value;
  // }

  // checkForItemServiceData() {
  //   let itemData: any;
  //   if (
  //     sessionStorage.getItem('itemServiceResponseData') &&
  //     sessionStorage.getItem('itemServiceResponseData') != '{}'
  //   ) {
  //     return JSON.parse(
  //       sessionStorage.getItem('itemServiceResponseData') || '',
  //     );
  //   } else {
  //     this.callItemData();
  //     itemData = JSON.parse(
  //       sessionStorage.getItem('itemServiceResponseData') || '',
  //     );
  //   }
  //   return itemData;
  // }

  // async callItemData() {
  //   const initialLoad = [
  //     'StateList',
  //     'PASURL',
  //     'StepThreeAPICall',
  //     'GenericSettings',
  //     'CoverageDefaultSettings',
  //     'ThemeColor',
  //     'TripTypeLebeling',
  //   ];

  //   let itemIdsData = initialLoad.map((currentItemName) => {
  //     if (
  //       currentItemName == 'StateList' ||
  //       currentItemName == 'StepThreeAPICall'
  //     ) {
  //       return {
  //         Name: currentItemName,
  //         ItemId: this.itemService.getItemServiceID(currentItemName),
  //         IsChildren: true,
  //       };
  //     } else {
  //       return {
  //         Name: currentItemName,
  //         ItemId: this.itemService.getItemServiceID(currentItemName),
  //       };
  //     }
  //   });
  //   const payload: any = {
  //     step: 'policyDataPasId' + this.policyTxnId.toString(),
  //     data: itemIdsData,
  //   };
  //   const res: any = await this.itemService
  //     .getDataByScItemService(payload)
  //     .toPromise();
  //   if (res) {
  //     sessionStorage.setItem(
  //       'itemServiceResponseData',
  //       JSON.stringify(Utility.lowerCaseObjectKeys(res)),
  //     );
  //   }
  // }

  updateRequestObj: any = {
    PolicyId: null,
    VersionNum: null,
    PolicyTxnId: null,
    PolicyNum: null,
    SubCompanyCode: null,
    UWQuoteID: 0,
    WritingCompanyId: 0,
    QuoteDescription: '',
    IsExcess: false,
  };
  requestDetails: any = {
    FromDate: '',
    ToDate: '',
    Ages: [],
    DependentsCount: 0,
    IsSingleTrip: true,
    StateCode: '',
    CountrOrRegionNames: [],
    PromoCode: '',
    PortalCode: '',
    IsClubMember: true,
    MedicalScore: 0,
  };
  quoteDetails: any = {
    QuoteName: null,
    MultiTripLength: '',
    ProductType: '',
    IsSkiing: null,
    IsCruise: null,
    Excess: null,
    Region: null,
    RentalBaseExcess: null,
    NumOfDays: null,
    BranchName: '',
    Question1: '',
    Question2: '',
    Question3: '',
    Question4: '',
    Question5: '',
    MembershipNumber: '',
  };
  traveler1: any = {
    Age: null,
    ContactId: null,
    EntityTypeId: 2,
    LastName: null,
    FirstName: null,
    MiddleName: '',
    BirthDate: null,
    Addresses: [],
    NameTypeIds: [4],
    PhoneNumbers: [
      {
        IsDefault: true,
        PhoneTypeId: 1,
        PhoneNum: null,
      },
    ],
    EmailAccts: [
      {
        IsDefault: true,
        EmailAddress: null,
        EmailTypeId: 1,
      },
    ],
  };
  traveler2: any = {
    Age: null,
    ContactId: null,
    EntityTypeId: 2,
    LastName: null,
    FirstName: null,
    MiddleName: '',
    BirthDate: null,
    Addresses: [],
    NameTypeIds: [4],
  };
  address: any = {
    AddressId: '',
    CountryCode: null,
    ZipCode: null,
    StreetAddress: null,
    StateCode: null,
    City: null,
    AddressTypes: [5, 3],
  };
  dependent: any = {
    Age: null,
    ContactId: null,
    EntityTypeId: 2,
    LastName: null,
    FirstName: null,
    MiddleName: '',
    BirthDate: null,
    Addresses: [],
    NameTypeIds: [2],
  };
}
