import { Injectable } from '@angular/core';
import { LogLevel } from '../enum/log-level-enum';
import { LogEntry } from './log-entry';
import { LogPublishersService } from './log-publisher.service';
import { LogPublisher } from './log-publishers';
import { PageNameEnum } from '../../enum/page-name-enum';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;

  public publishers!: LogPublisher[];

  constructor(private publishersService: LogPublishersService) {}

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;
    if (
      (level >= this.level && level !== LogLevel.Off) ||
      this.level === LogLevel.All
    ) {
      ret = true;
    }
    return ret;
  }

  public createInfoLog(
    request: any,
    apiName: any,
    pageName: any,
    logLevel: any,
  ) {
    let additionalInfo: any[] = [];
    let obj = {
      RequestObj: request,
    };
    additionalInfo[0] = JSON.stringify(obj);
    this.logError(
      apiName + ' Called on Page : ' + pageName,
      logLevel,
      additionalInfo,
    );
  }

  public createErrorLog(
    request: any,
    error: any,
    apiName: any,
    pageName: any,
    logLevel: any,
  ) {
    let additionalInfo: any[] = [];
    let obj = {
      RequestObj: request,
      ErrorObj: error,
    };
    additionalInfo[0] = JSON.stringify(obj);
    this.logError(
      apiName +
        ' API FAILURE with ErrorStatus : ' +
        error.status +
        ' on Page : ' +
        pageName,
      logLevel,
      additionalInfo,
    );
  }

  private logError(message: any, logLevel: any, additional: any = []) {
    this.writeToLog(message, logLevel, additional);
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {
      let entry: LogEntry = new LogEntry();
      entry.error = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;
      entry.payLoad = entry.buildLogString();

      this.publishers = this.publishersService.publishers;

      for (let logger of this.publishers) {
        logger.log(entry).subscribe();
      }
    }
  }
}
