import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Config, ConfigColumns } from 'datatables.net';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { SharedService } from '../shared.service';
import { Subscription, of, tap, catchError } from 'rxjs';
import { ManageBranchesService } from '../ManageBranchs.service';
import { LoaderService } from '../../../../../src/app/core/services/loader.service';
import { GridService } from '../../../../../src/app/shared/grid.service'
import { PageNameEnum } from '../../../../../projects/policyDetails/src/app/enum/page-name-enum';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
@Component({
  selector: 'app-branches-table',
  standalone: true,
  imports: [DataTablesModule],
  templateUrl: './branches-table.component.html',
  styleUrl: './branches-table.component.css',
})
export class BranchesTableComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false }) dtElement!: DataTableDirective;
  @Input() tableColumns: ConfigColumns[] = [];
  @Output() openEditModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() openDeactivateModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() openReactivateModal: EventEmitter<any> = new EventEmitter<any>();

  selectedClub?: any;
  userRoleDetails?: UserRoleDetails;
  dtOptions: Config = {};
  clubChangedSubscription: Subscription;
  addBranchSubmitSubscription: Subscription;
  private loadedData: any[] = [];

  constructor(
    private sharedService: SharedService,
    private manageBranchesService: ManageBranchesService,
    private loader: LoaderService,
    private gridService: GridService,
    private store: Store
  ) {
    this.clubChangedSubscription = new Subscription();
    this.addBranchSubmitSubscription = new Subscription();
  }

  ngOnInit(): void {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
      }
    });

    this.addBranchSubmitSubscription = this.sharedService.addBranchSubmit$.subscribe(
      () => {
        this.refreshTable();
      },
    );

    this.clubChangedSubscription = this.sharedService.clubDropdownChanged$.subscribe(
      (data) => {
        // this is not called anymore
        // its because this subscription is not set yet but the dropdown emit is sent quickly.
        this.refreshTable(data);
      },
    );

    let dtOptions = {
      lengthChange: false,
      pagingType: 'full_numbers',
      language: {
        paginate: {
          next: `<i class="fas fa-angle-right"></i>`,
          previous: '<i class="fas fa-angle-left"></i>',
          last: '<i class="fas fa-angle-double-right"></i>',
          first: '<i class="fas fa-angle-double-left"></i>'
        }
      },
      columns: this.tableColumns,
      initComplete: () => {
        this.bindClickEvents();
      },
    };

    var obsBranches = of({ itemsCount: 0, response: [] });
    this.dtOptions = this.gridService.buildDtOptionsWithPaging(dtOptions, obsBranches, {}, PageNameEnum.Dashboard);
  }

  getBranches(req: any) {
    return this.manageBranchesService.getBranches(req).pipe(tap((resp) => {
      const dataWithIndex = resp.response.map((item: any, index: number) => {
        return { ...item, id: index + 1 }; // Add incremental id
      });
      this.loadedData = resp.response = dataWithIndex;
      this.loader.hide();
    }),
      catchError(err => {
        this.loader.hide();
        return err;
      }))
  }

  bindClickEvents() {
    const $tableBody = $('#branch-table tbody');

    $tableBody.off('click', '.edit-btn');
    $tableBody.off('click', '.activate-btn');

    $tableBody.on('click', '.edit-btn', (event) => {
      const id = $(event.currentTarget).data('guid');
      this.onEditClick(id);
    });

    $tableBody.on('click', '.activate-btn', (event) => {
      const id = $(event.currentTarget).data('guid');
      const action = $(event.currentTarget).data('action');
      this.onDeactivateClick({ id, action });
    });
  }

  onEditClick(guid: string) {
    let branch = this.loadedData.find(branch => branch.branchGuid === guid);
    this.openEditModal.emit(branch);
  }

  onDeactivateClick(data: any) {
    this.openDeactivateModal.emit(data);
  }

  refreshTable(selectedValue?: any): void {
    if (selectedValue) {
      this.selectedClub = selectedValue;
    }

    if (this.dtElement) {
      this.dtElement.dtInstance.then(dtInstance => {
        var req = { autoClubGuid: this.selectedClub.autoClubGuid, RecordType: "I", loginAgentGuid: this.userRoleDetails?.AgentContactId };
        var obsBranches = this.getBranches(req);
        this.loader.show();
        dtInstance.ajax.url(this.gridService.getAjaxMethod(obsBranches, req, PageNameEnum.Dashboard)).load();
      });
    }
  }

  ngOnDestroy(): void {
    this.clubChangedSubscription.unsubscribe();
    this.addBranchSubmitSubscription.unsubscribe();
  }

}