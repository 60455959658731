import { createAction, props } from '@ngrx/store';
import { LoginUser, } from './loginUser.model';

export const loginUser = createAction(
    'Login User',
    props<{ loginUser: LoginUser }>()
)

export const logoutUser = createAction(
    'Logout User',
    props<{ updateAgents: Partial<LoginUser> }>()
)
