/// <reference path="policy-status.service.ts" />
/// <reference path="../../../../../src/app/shared/okta/oktaauth.service.ts" />
import { Component, OnInit } from '@angular/core';
import { PolicyStatusService } from './policy-status.service';
import { ErrorService } from '../../../../../src/app/core/services/error.service';
import { from } from 'rxjs';
import { first } from 'rxjs/operators';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { LogService } from '../../../../../projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from '../../../../../projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum } from '../../../../../projects/policyDetails/src/app/enum/page-name-enum';

@Component({
  selector: 'dashboard-policy-status',
  templateUrl: './policy-status.component.html',
  styleUrls: ['./policy-status.component.css'],
})
export class PolicyStatusComponent implements OnInit {
  sitecoreData: any = [];
  octadata: any = [];
  policyStatus: any = {};
  isLoaderPolicyStatus: boolean = false;

  constructor(
    private policyStatusService: PolicyStatusService,
    private errorService: ErrorService,
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe,
    private logService: LogService,
  ) { }

  ngOnInit() {
  }

  getSitecoreMetricsData(duration: any): void {
    this.policyStatusService.getSiteCoreMetricList().subscribe(
      (resp) => {
        this.sitecoreData = resp;
        this.policyStatus = {};
        this.policyStatus.Heading = this.sitecoreData.Heading;
        this.policyStatus.LeftDashboardPolicyData = [];
        this.policyStatus.RightDashboardPolicyData = [];
        this.policyStatus.Duration = this.sitecoreData.Duration;

        for (let itemText of this.policyStatus.Duration) {
          if (itemText.Month == duration) {
            itemText.SelectedValue = itemText.Month;
          } else {
            itemText.SelectedValue = '';
          }
        }

        for (let itemText of this.sitecoreData.DashboardPolicyData) {
          for (let key in this.octadata) {
            if (key == itemText.KeyText) {
              if (key == 'PercentConversion') {
                this.policyStatus.RightDashboardPolicyData.push({
                  KeyText: itemText.KeyText,
                  Description: itemText.APILabel,
                  Count: this.getFormattedPercent(this.octadata[key]),
                });
              } else if (key == 'CommissionsPaid') {
                this.policyStatus.RightDashboardPolicyData.push({
                  KeyText: itemText.KeyText,
                  Description: itemText.APILabel,
                  Count: this.octadata[key],
                });
              } else if (key == 'TotalGrossWrittenPremium') {
                this.policyStatus.RightDashboardPolicyData.push({
                  KeyText: itemText.KeyText,
                  Description: itemText.APILabel,
                  Count: this.octadata[key],
                });
              } else {
                if (
                  key == 'PendingEndorsementPolicies' ||
                  key == 'ExpiringPolicyCount'
                ) {
                  this.policyStatus.LeftDashboardPolicyData.push({
                    KeyText: itemText.KeyText,
                    Description: itemText.APILabel,
                    Count: this.octadata[key],
                  });
                } else {
                  this.policyStatus.RightDashboardPolicyData.push({
                    KeyText: itemText.KeyText,
                    Description: itemText.APILabel,
                    Count: this.octadata[key],
                  });
                }
              }
            }
          }
        }

        this.isLoaderPolicyStatus = false;
      },
      (err) => {
        if (err.statusText == 'Unauthorized') {
          this.errorService.errorTracker();
        }
        this.logService.createErrorLog(
          {},
          err,
          'GetSiteCoreMetricList',
          PageNameEnum.Dashboard,
          LogLevel.Error,
        );
      },
    );
  }

  getFormattedPercent(percent: number) {
    return this.percentPipe.transform(percent, '1.1-1');
  }

  getFormattedPrice(price: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'code',
      minimumFractionDigits: 0,
    }).format(price);
  }
}
