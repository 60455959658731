import { createFeatureSelector, createSelector } from "@ngrx/store";
import { EndorsementState } from "./endorsement.reducer";

export const selectEndorsementState = createFeatureSelector<EndorsementState>('endorsement');

export const selectCurrentEndorsement = createSelector(
   selectEndorsementState,
  (state: EndorsementState) => state.endorsement
);

export const isEndorsementDataPresent = createSelector(
  selectCurrentEndorsement,
  (endorsement) => !!endorsement
);