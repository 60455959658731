import { createReducer, on, Action } from "@ngrx/store";
import { LoginUser, } from "./loginUser.model";
import { loginUser, logoutUser, } from "./loginUser.actions";

export interface LoginUserState {
    loginUser: LoginUser | null;
}

export const initialLoginState: LoginUserState = {
    loginUser: null
};

const _loginUserReducer = createReducer(
    initialLoginState,
    on(loginUser, (state, { loginUser }) => (
        {
            loginUser: loginUser
        }
    )),
    on(logoutUser, (state) => ({
        loginUser: null
    }))
);

export function loginUserReducer(state: LoginUserState | undefined, action: Action) {
    return _loginUserReducer(state, action);
}
