// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  envName: 'local',
  $G_Username: '',
  assetsPath: './assets/apps/ngGTPPAS/assets',
  BraintreeClientToken: 'sandbox_q7g6vg96_2j44s6txx9wphmdz',
  XAPIKEY : '938500DB-4FAF-43B2-A2CE-E50F4E522B81',
  ENTRA_ID_REDIRECT_URI: 'https://pasdev.tmaainsurance.com/',
  LTX_END_POINT: 'https://ca-dev-aue-ltx-pas.ashygrass-2b5d408a.australiaeast.azurecontainerapps.io/',
  ADDRESIFYAPIKEY : '0E4644FE-85E4-4685-930F-DBF5F8629DFA',
  ADDRESIFY_AUTOCOMPLETE_URL : "https://api.addressify.com.au/addresspro/autocomplete?api_key=",
  ADDRESSIFY_ADDRESSPRO_URL : "https://api.addressify.com.au/addresspro/info?api_key=",
  VERISK_GATEWAY_URL : 'https://gatewayuat.verisk.com/rating/au/travel/',
  VERISK_CSS_URL : 'https://blackbox30-traveluat.verisk.com.au/Content/BootstrapVerisk.css',
  VERISK_BLACKBOX_URL : 'https://blackbox30-traveluat.verisk.com.au/Scripts/blackbox-3.1.0.js',
  veriskCredentails : {
    RACV: {
      username: 'BB3TokioMarineRACVAuTravelUAT',
      vrrInternal: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQkIzVG9raW9NYXJpbmVSQUNWQXVUcmF2ZWxVQVQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJCbGFja0JveEN1c3RvbWVyIiwiY2xpZW50SWQiOiI5MCIsImlzcyI6IkJCLk1haW50ZW5hbmNlVG9vbC5UZXN0IiwiYXVkIjoiVlJSLkJCMy5VQVQifQ.SfvUKkbBe25K5su1d_l_3H4-FC73qCwvnx1xctzP9xw',
    },
    W2C : {
      username: 'BB3TokioMarineW2CAusTravelUAT',
      vrrInternal: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIFdvcmxkIDIgQ292ZXIgQXVzIFRyYXZlbCBCQjMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJCbGFja0JveEN1c3RvbWVyIiwiY2xpZW50SWQiOiI5MCIsImlzcyI6IkJCLk1haW50ZW5hbmNlVG9vbC5UZXN0IiwiYXVkIjoiVlJSLkJCMy5VQVQifQ.rvPId_lRYlGK8EsVrO61sSaB3K1ntylJliXmtSSZ0jo',
    },
    RACQ : {
      username: 'BB3TokioMarineRACQAusTravelUAT',
      vrrInternal: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIFJBQ1EgQXVzIFRyYXZlbCBCQjMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJCbGFja0JveEN1c3RvbWVyIiwiY2xpZW50SWQiOiI5MCIsImlzcyI6IkJCLk1haW50ZW5hbmNlVG9vbC5UZXN0IiwiYXVkIjoiVlJSLkJCMy5VQVQifQ.0S5yWsdgTsxWWkJlhOrxU5Kh_adoA6WOvwuOxgAOcjE',
    },
    RAA : {
      username: 'BB3TokioMarineRAAAusTravelUAT',
      vrrInternal: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIFJBQSBBdXMgVHJhdmVsIEJCMyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkJsYWNrQm94Q3VzdG9tZXIiLCJjbGllbnRJZCI6IjkwIiwiaXNzIjoiQkIuTWFpbnRlbmFuY2VUb29sLlRlc3QiLCJhdWQiOiJWUlIuQkIzLlVBVCJ9.ycHDNvsPIjlZCl4TLg13RSqOjUnOTOCScKxb5UQtPQo',
    },
    RACT : {
      username: 'BB3TokioMarineRACTAuTravelUAT',
      vrrInternal: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiQkIzVG9raW9NYXJpbmVSQUNUQXVUcmF2ZWxVQVQiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJCbGFja0JveEN1c3RvbWVyIiwiY2xpZW50SWQiOiI5MCIsImlzcyI6IkJCLk1haW50ZW5hbmNlVG9vbC5UZXN0IiwiYXVkIjoiVlJSLkJCMy5VQVQifQ.sVVSEhd5C15YW3Oc8PZDR9j3kt6ZRX3udQPG1yRLO-4',
    },
    AANT : {
      username: 'BB3TokioMarineAANTAusTravelUAT',
      vrrInternal: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIEFBTlQgQXVzIFRyYXZlbCBCQjMiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiJCbGFja0JveEN1c3RvbWVyIiwiY2xpZW50SWQiOiI5MCIsImlzcyI6IkJCLk1haW50ZW5hbmNlVG9vbC5UZXN0IiwiYXVkIjoiVlJSLkJCMy5VQVQifQ.TCowkhi2ikRqmyZvxnvbxqXEHn5ygY0noDuUEczD8kE',
    },
    RAC : {
      username: 'BB3TokioMarineRACAusTravelUAT',
      vrrInternal: 'eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiVG9raW8gTWFyaW5lIFJBQyBBdXMgVHJhdmVsIEJCMyIsImh0dHA6Ly9zY2hlbWFzLm1pY3Jvc29mdC5jb20vd3MvMjAwOC8wNi9pZGVudGl0eS9jbGFpbXMvcm9sZSI6IkJsYWNrQm94Q3VzdG9tZXIiLCJjbGllbnRJZCI6IjkwIiwiaXNzIjoiQkIuTWFpbnRlbmFuY2VUb29sLlRlc3QiLCJhdWQiOiJWUlIuQkIzLlVBVCJ9.on3bhXonuWyeeMq6iusOi3kO10_AP7LR9N74KjMf2w0',
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.