/// <reference path="../enum/sitename-enum.ts" />
/// <reference path="../commonparams.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { ClubAndBranchInfo, CommonParams } from '../commonparams';
import {
  SiteNameEnum,
  PortalCodeEnum,
  OktaGropPortalCodeEnum,
} from '../enum/sitename-enum';

@Injectable({
  providedIn: 'root',
})
export class PortalCodeService {
  public static AccountId = 'GTA';

  constructor(private http: HttpClient) { }
  httpHeaders = {
    headers: new HttpHeaders({
    }),
  };

  getPortalCode(agencyContactId: any) {
    if (ClubAndBranchInfo.Clubs != '') {
      let ClubContactNum: any;

      if (agencyContactId) {
        ClubContactNum = ClubAndBranchInfo.Clubs.find(
          (c: any) => c.ContactId == agencyContactId,
        );
      } else if (sessionStorage.getItem('AgencyContactId')) {
        ClubContactNum = ClubAndBranchInfo.Clubs.find(
          (c: any) => c.ContactId == sessionStorage.getItem('AgencyContactId'),
        );
      }

      let contactFullName =
        ClubContactNum != undefined && ClubContactNum.ContactNum != undefined
          ? ClubContactNum.FullName
          : '';
      let portalcode = this.filterPortal(contactFullName);
      return portalcode;
    }

    return '';
  }

  // getPortalContactNum(agencyContactId: any) {
  //   if (ClubAndBranchInfo.Clubs != '') {
  //     let ClubContactNum: any;

  //     if (UserRoleDetails.AgencyContactId) {
  //       ClubContactNum = ClubAndBranchInfo.Clubs.find(
  //         (c: any) => c.ContactId == UserRoleDetails.AgencyContactId,
  //       );
  //     } else if (sessionStorage.getItem('AgencyContactId')) {
  //       ClubContactNum = ClubAndBranchInfo.Clubs.find(
  //         (c: any) => c.ContactId == sessionStorage.getItem('AgencyContactId'),
  //       );
  //     }

  //     let ContactNum =
  //       ClubContactNum != undefined && ClubContactNum.ContactNum != undefined
  //         ? ClubContactNum.ContactNum
  //         : '';
  //     return ContactNum;
  //   }

  //   return '';
  // }

  // setPortalContactNumForManageUser(agencyContactId : any) {
  //   if (ClubAndBranchInfo.Clubs != '') {
  //     let clubContactNum: any;
  //     if (UserRoleDetails.AgencyContactId > 0) {
  //       clubContactNum = ClubAndBranchInfo.Clubs.find(
  //         (c: any) => c.ContactId == UserRoleDetails.AgencyContactId,
  //       );
  //       let ContactNum =
  //         clubContactNum != undefined && clubContactNum.ContactNum != undefined
  //           ? clubContactNum.ContactNum
  //           : '';
  //       return ContactNum;
  //     }
  //   }

  //   return '';
  // }

  // getPortalContactID(agencyContactId: any) {
  //   if (ClubAndBranchInfo.Clubs != '') {
  //     let ClubContactID: any;

  //     if (UserRoleDetails.AgencyContactId) {
  //       ClubContactID = ClubAndBranchInfo.Clubs.find(
  //         (c: any) => c.ContactId == UserRoleDetails.AgencyContactId,
  //       );
  //     } else if (sessionStorage.getItem('AgencyContactId')) {
  //       ClubContactID = ClubAndBranchInfo.Clubs.find(
  //         (c: any) => c.ContactId == sessionStorage.getItem('AgencyContactId'),
  //       );
  //     }

  //     let contactId =
  //       ClubContactID != undefined && ClubContactID.ContactNum != undefined
  //         ? ClubContactID.ContactId
  //         : '1456249';
  //     return contactId;
  //   }

  //   return '1456249';
  // }

  // getPortalSiteName(agencyContactId: any) {
  //   if (ClubAndBranchInfo.Clubs != '') {
  //     let ClubContactNum: any;
  //     if (UserRoleDetails.AgencyContactId) {
  //       ClubContactNum = ClubAndBranchInfo.Clubs.find(
  //         (c: any) => c.ContactId == UserRoleDetails.AgencyContactId,
  //       );
  //     } else if (sessionStorage.getItem('AgencyContactId')) {
  //       ClubContactNum = ClubAndBranchInfo.Clubs.find(
  //         (c: any) => c.ContactId == sessionStorage.getItem('AgencyContactId'),
  //       );
  //     }
  //     let contactFullName =
  //       ClubContactNum != undefined && ClubContactNum.ContactNum != undefined
  //         ? ClubContactNum.FullName
  //         : '';
  //     let portalcode = this.filterPortal(contactFullName);
  //     return portalcode;
  //   }

  //   return '';
  // }

  sortByAlphabeticalOrder(a: any, b: any): any {
    if (
      a.FullName.toLowerCase() != 'select autoclub' &&
      b.FullName.toLowerCase() != 'select autoclub' &&
      a.FullName.toLowerCase() != '-- select branch --' &&
      b.FullName.toLowerCase() != '-- select branch --' &&
      a.FullName.toLowerCase() != '-- select agent name --' &&
      b.FullName.toLowerCase() != '-- select agent name --' &&
      a.FullName.toLowerCase() != 'select branch name' &&
      b.FullName.toLowerCase() != 'select branch name '
    ) {
      if (a.FullName.toLowerCase() > b.FullName.toLowerCase()) return 1;
      if (a.FullName.toLowerCase() < b.FullName.toLowerCase()) return -1;
      return 0;
    }
  }

  sortRole(a: any, b: any) {
    if (
      a.RoleName.toLowerCase() != 'select role' &&
      b.RoleName.toLowerCase() != 'select role'
    ) {
      if (a.RoleName.toLowerCase() > b.RoleName.toLowerCase()) return 1;
      if (a.RoleName.toLowerCase() < b.RoleName.toLowerCase()) return -1;
      return 0;
    }
  }

  filterDropDownPortalList() {
    if (ClubAndBranchInfo.Clubs != '') {
      let clubList = [];
      clubList.push({
        ContactId: '',
        ContactNum: '',
        FullName: 'Select AutoClub',
      });

      for (let item of ClubAndBranchInfo.Clubs) {
        clubList.push(item);
      }

      clubList = clubList.filter(
        (c) =>
          c.FullName == 'Select AutoClub' ||
          c.FullName == SiteNameEnum.W2C ||
          c.FullName == SiteNameEnum.RACV ||
          c.FullName == SiteNameEnum.RACQ ||
          c.FullName == SiteNameEnum.RAC ||
          c.FullName == SiteNameEnum.RAA ||
          c.FullName == SiteNameEnum.AANT ||
          c.FullName.toLowerCase() == SiteNameEnum.RACT.toLowerCase(),
      );

      return clubList;
    }

    return '';
  }

  filterPortalListForBranches() {
    if (ClubAndBranchInfo.Clubs != '') {
      let clubList = ClubAndBranchInfo.Clubs.filter(
        (c: any) =>
          c.FullName == SiteNameEnum.RACV ||
          c.FullName == SiteNameEnum.RACQ ||
          c.FullName == SiteNameEnum.RAC ||
          c.FullName == SiteNameEnum.RAA ||
          c.FullName == SiteNameEnum.AANT ||
          c.FullName.toLowerCase() == SiteNameEnum.RACT.toLowerCase(),
      );
      clubList.sort(this.sortByAlphabeticalOrder);
      return clubList;
    }

    return '';
  }

  filterPortalList() {
    if (ClubAndBranchInfo.Clubs != '') {
      let clubList = ClubAndBranchInfo.Clubs.filter(
        (c: any) =>
          c.FullName == SiteNameEnum.W2C ||
          c.FullName == SiteNameEnum.RACV ||
          c.FullName == SiteNameEnum.RACQ ||
          c.FullName == SiteNameEnum.RAC ||
          c.FullName == SiteNameEnum.RAA ||
          c.FullName == SiteNameEnum.AANT ||
          c.FullName.toLowerCase() == SiteNameEnum.RACT.toLowerCase(),
      );
      clubList.sort(this.sortByAlphabeticalOrder);
      return clubList;
    }

    return '';
  }

  filterPortal(contactFullName: any) {
    contactFullName = String(contactFullName).toLowerCase();
    switch (contactFullName) {
      case SiteNameEnum.W2C.toLowerCase():
        return PortalCodeEnum.W2C;
      case SiteNameEnum.RACV.toLowerCase():
        return PortalCodeEnum.RACV;
      case SiteNameEnum.RACQ.toLowerCase():
        return PortalCodeEnum.RACQ;
      case SiteNameEnum.RAC.toLowerCase():
        return PortalCodeEnum.RAC;
      case SiteNameEnum.RAA.toLowerCase():
        return PortalCodeEnum.RAA;
      case SiteNameEnum.AANT.toLowerCase():
        return PortalCodeEnum.AANT;
      case SiteNameEnum.RACT.toLowerCase():
        return PortalCodeEnum.RACT;
    }

    return '';
  }

  filterPortalSIteNameByPortalCode(portalCode: any) {
    switch (portalCode) {
      case OktaGropPortalCodeEnum.TMMASuperAdmin:
        return SiteNameEnum.W2C;
      case PortalCodeEnum.W2C:
        return SiteNameEnum.W2C;
      case PortalCodeEnum.RACV:
        return SiteNameEnum.RACV;
      case PortalCodeEnum.RACQ:
        return SiteNameEnum.RACQ;
      case PortalCodeEnum.RAC:
        return SiteNameEnum.RAC;
      case PortalCodeEnum.RAA:
        return SiteNameEnum.RAA;
      case PortalCodeEnum.AANT:
        return SiteNameEnum.AANT;
      case PortalCodeEnum.RACT:
        return SiteNameEnum.RACT;
    }

    return '';
  }

  filterGroupPortal(contactFullName: any) {
    switch (contactFullName) {
      case SiteNameEnum.W2C:
        return PortalCodeEnum.W2C;
      case SiteNameEnum.RACV:
        return PortalCodeEnum.RACV;
      case SiteNameEnum.RACQ:
        return PortalCodeEnum.RACQ;
      case SiteNameEnum.RAC:
        return PortalCodeEnum.RAC;
      case SiteNameEnum.RAA:
        return PortalCodeEnum.RAA;
      case SiteNameEnum.AANT:
        return PortalCodeEnum.AANT;
      case SiteNameEnum.RACT:
        return PortalCodeEnum.RACT;
    }

    return '';
  }

  getAgencyIDPortalSiteName(portalCode: any) {
    let fullName = this.filterPortalSIteNameByPortalCode(portalCode);
    if (fullName != '') {
      let contactId = ClubAndBranchInfo.Clubs.find(
        (c: any) => c.FullName == fullName,
      );
      let portalContactID =
        contactId != undefined && contactId.ContactNum != undefined
          ? contactId.ContactId
          : '';
      return portalContactID;
    }

    return '';
  }

  getAgencyNumPortalSiteName(portalCode: any): any {
    let fullName = this.filterPortalSIteNameByPortalCode(portalCode);
    if (fullName != '') {
      let contactId = ClubAndBranchInfo.Clubs.find(
        (c: any) => c.FullName == fullName,
      );
      let portalContactID =
        contactId != undefined && contactId.ContactNum != undefined
          ? contactId.ContactNum
          : '';
      return portalContactID;
    }

    return '';
  }

  getAgencyId(contactNum: any) {
    let contactIdObj = ClubAndBranchInfo.Clubs.find(
      (c: any) => c.ContactNum == contactNum,
    );
    let contactId =
      contactIdObj != undefined && contactIdObj.ContactNum != undefined
        ? contactIdObj.ContactId
        : '';
    return contactId;
  }

  getAgencyNum(agencyID: any) {
    let contactIdObj = ClubAndBranchInfo.Clubs.find(
      (c: any) => c.ContactId == agencyID,
    );
    let contactId =
      contactIdObj != undefined && contactIdObj.ContactNum != undefined
        ? contactIdObj.ContactNum
        : '';
    return contactId;
  }

  filterWorld2CoverAgencyID(contactNum: any) {
    let contactId = ClubAndBranchInfo.Clubs.find(
      (c: any) => c.ContactNum == contactNum,
    );
    let fullName =
      contactId != undefined && contactId.ContactId != undefined
        ? contactId.FullName
        : '';
    return fullName == SiteNameEnum.W2C ? false : true;
  }

  checkWorld2CoverAgencyID(agencyId: any) {
    let contactId = ClubAndBranchInfo.Clubs.find(
      (c: any) => c.ContactId == agencyId,
    );
    let fullName =
      contactId != undefined && contactId.ContactNum != undefined
        ? contactId.FullName
        : '';
    return fullName == SiteNameEnum.W2C ? false : true;
  }

  // filterDropDownList(userRoleDetails: UserRoleDetails) {
  //   if (UserRoleDetails.setRole) {
  //     if (UserRoleDetails.IsUW || UserRoleDetails.IsSuperUser) {
  //       let list: any = [];
  //       list = this.filterDropDownPortalList();
  //       list.sort(this.sortByAlphabeticalOrder);
  //       return list;
  //     } else if (UserRoleDetails.IsClubAdmin || UserRoleDetails.IsAgent) {
  //       let branchList = [];
  //       branchList.push({
  //         ContactId: '',
  //         ContactNum: '',
  //         FullName: 'Select Branch',
  //       });
  //       for (let item of ClubAndBranchInfo.Branches) {
  //         if (item.IsInactive == false) {
  //           branchList.push(item);
  //         }
  //       }
  //       branchList.sort(this.sortByAlphabeticalOrder);
  //       return branchList;
  //     }
  //   }
  //   return '';
  // }

  // setAgencyorBranch(contactId: any, userRoleDetails:UserRoleDetails) {
  //   if (UserRoleDetails.setRole) {
  //     if (
  //       UserRoleDetails.IsUW ||
  //       UserRoleDetails.IsSuperUser ||
  //       UserRoleDetails.IsReadOnly
  //     ) {
  //       sessionStorage.setItem('AgencyContactId', contactId);
  //     } else if (UserRoleDetails.IsClubAdmin || UserRoleDetails.IsAgent) {
  //       sessionStorage.setItem('BranchContactId', contactId);
  //     }
  //   }
  // }

  // setAgencyorBranchDefaultSelectedIndex(userRoleDetails:UserRoleDetails) {
  //   if (UserRoleDetails.setRole) {
  //     if (UserRoleDetails.IsAgent) {
  //       return UserRoleDetails.BranchContactId != undefined &&
  //         UserRoleDetails.BranchContactId != ''
  //         ? UserRoleDetails.BranchContactId
  //         : '';
  //     }
  //   }

  //   return '';
  // }

  setAgencyorBranchDefaultGetqQuote(userRoleDetails?: UserRoleDetails) {
    if (userRoleDetails) {
      if (
        userRoleDetails.IsSuperUser ||
        userRoleDetails.IsUW ||
        userRoleDetails.IsClubAdmin
      ) {
        if (
          sessionStorage.getItem('selectedClubKey') == undefined ||
          sessionStorage.getItem('selectedClubKey') == ''
        ) {
          return false;
        }
      }
    }

    return true;
  }
  getPortalCodeBaseUrl(clubName: any, autoClubURLList: any) {
    if (autoClubURLList) {
      for (let item of autoClubURLList) {
        if (clubName == item.key) {
          return item.link_Url.url;
        }
      }
    }

    return '';
  }

  getGroupIdBasePortalCode(agencyContactId: any, roleTypeCode: any) {
    let portalCodeGroupList: any;
    let url = location.protocol + '//' + location.host;

    if (
      url == 'https://pas.tmmatravel.com.au/' ||
      url == 'https://pas.tmmatravel.com.au'
    ) {
      portalCodeGroupList = this.filterProdGroupList();
    } else {
      portalCodeGroupList = this.filterGroupList();
    }

    if (
      ClubAndBranchInfo.Clubs != '' &&
      agencyContactId != '' &&
      (roleTypeCode == 'ClubSuperUser' ||
        roleTypeCode == 'ClubAgent' ||
        roleTypeCode == '')
    ) {
      let ClubContactNum: any;
      ClubContactNum = ClubAndBranchInfo.Clubs.find(
        (c: any) => c.ContactNum == agencyContactId,
      );
      let contactFullName =
        ClubContactNum != undefined && ClubContactNum.ContactNum != undefined
          ? ClubContactNum.FullName
          : '';
      let portalcode = this.filterPortal(contactFullName);
      let groupId = portalCodeGroupList.find(
        (f: any) => f.groupCode == portalcode,
      );
      return groupId.id;
    } else {
      let groupId = portalCodeGroupList.find(
        (f: any) => f.groupCode == roleTypeCode,
      );
      return groupId.id;
    }
  }

  filterGroupList() {
    let groupList = [
      {
        id: '00gpepis5c0bjRuL90h7',
        profile: {
          name: 'GlobalTravel_AANT',
          description: 'Global Travel Club Admins and Agents for AANT',
        },
        groupCode: 'AANT',
      },
      {
        id: '00gpepfbt8hlxF7l80h7',
        profile: {
          name: 'GlobalTravel_RAA',
          description: 'Global Travel Club Admins and Agents for RAA',
        },
        groupCode: 'RAA',
      },
      {
        id: '00gpepjuyulfPPHhb0h7',
        profile: {
          name: 'GlobalTravel_RAC',
          description: 'Global Travel Club Admins and Agents for RAC',
        },
        groupCode: 'RAC',
      },
      {
        id: '00gpepjvr7wmjMPmU0h7',
        profile: {
          name: 'GlobalTravel_RACQ',
          description: 'Global Travel Club Admins and Agents for RACQ',
        },
        groupCode: 'RACQ',
      },
      {
        id: '00gpepid21Lu9DXuh0h7',
        profile: {
          name: 'GlobalTravel_RACV',
          description: 'Global Travel Club Admins and Agents for RACV',
        },
        groupCode: 'RACV',
      },
      {
        id: '00gpepjvp1jdzCM6i0h7',
        profile: {
          name: 'GlobalTravel_ReadOnly',
          description: 'Global travel read only users for DEV/QA/UAT',
        },
        groupCode: 'ReadOnly',
      },
      {
        id: '00gpepd4aghe0hydi0h7',
        profile: {
          name: 'GlobalTravel_SuperAdmins',
          description: 'Global travel UW for DEV/QA/UAT',
        },
        groupCode: 'TMMASuperAdmin',
      },
      {
        id: '00gpepjbxrdV1ghjz0h7',
        profile: {
          name: 'GlobalTravel_SuperUsers',
          description: 'Global travel Super Users for DEV/QA/UAT',
        },
        groupCode: 'TMMAAgent',
      },
    ];

    return groupList;
  }

  filterProdGroupList() {
    let groupList = [
      {
        id: '00g3dcp49pABaWAGR4x6',
        profile: {
          name: 'GlobalTravel_AANT',
          description: 'Global Travel Club Admins and Agents for AANT',
        },
        groupCode: 'AANT',
      },
      {
        id: '00g3daqd2WJalzzx24x6',
        profile: {
          name: 'GlobalTravel_RAA',
          description: 'Global Travel Club Admins and Agents for RAA',
        },
        groupCode: 'RAA',
      },
      {
        id: '00g3daqcx9oChL9VH4x6',
        profile: {
          name: 'GlobalTravel_RAC',
          description: 'Global Travel Club Admins and Agents for RAC',
        },
        groupCode: 'RAC',
      },
      {
        id: '00g3ddh0kU0eadtS94x6',
        profile: {
          name: 'GlobalTravel_RACQ',
          description: 'Global Travel Club Admins and Agents for RACQ',
        },
        groupCode: 'RACQ',
      },
      {
        id: '00g16we6xdMmuuHoI4x6',
        profile: {
          name: 'GlobalTravel_RACV',
          description: 'Global Travel Club Admins and Agents for RACV',
        },
        groupCode: 'RACV',
      },
      {
        id: '00g16vwtxwma2zgcF4x6',
        profile: {
          name: 'GlobalTravel_ReadOnly',
          description: 'Global travel read only users for DEV/QA/UAT',
        },
        groupCode: 'ReadOnly',
      },
      {
        id: '00g16vhavr9zmQ4Yq4x6',
        profile: {
          name: 'GlobalTravel_SuperAdmins',
          description: 'Global travel UW for DEV/QA/UAT',
        },
        groupCode: 'TMMASuperAdmin',
      },
      {
        id: '00g16w1pfpBK9lm9u4x6',
        profile: {
          name: 'GlobalTravel_SuperUsers',
          description: 'Global travel Super Users for DEV/QA/UAT',
        },
        groupCode: 'TMMAAgent',
      },
    ];

    return groupList;
  }


}
