/// <reference path="../services/preextingbus.service.ts" />
import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, } from '@angular/forms';
import { PolicyDetailService } from '../app.services';
import { NgbModal, NgbModalRef, NgbDateParserFormatter, } from '@ng-bootstrap/ng-bootstrap';
import { Utility } from '../shared/utility';
import { EndorsementGenericService } from '../shared/services/endorsement-generic.service';
import { customValidator } from './validator';
import { SitecoreItemService } from '../services/sitecore-item.service';
import { NgbDateFRParserFormatter } from './ngb-date-fr-parser-formatter';
import { DateService } from 'src/app/core/services/date-updated.service';
import { FormatNumberPipe } from 'src/app/core/pipes/format-number.pipe';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { isPolicyDataPresent, selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { isEndorsementDataPresent, selectCurrentEndorsement } from 'src/app/core/store/endorsement/endorsement.selectors';
import { EndPoint } from 'src/app/core/services/endpoint';
import { MapperService } from 'src/app/core/services/mapper.services';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';

@Component({
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
  ],
  selector: 'policyDetails-policy-details-travellers',
  templateUrl: './policy-details-travellers.component.html',
  styleUrls: ['./policy-details-travellers.component.css'],
})

export class PolicyDetailsTravellersComponent implements OnInit {
  autoClubCode: string = 'W2C';
  coverageTypeName: string = '';
  ageLength: string = '75';
  contactForm!: FormGroup;
  secondrySubmit: any = null;
  submitted: any = false;
  prpolicytype!: boolean;
  updatedTravel1: any;
  isLoadingAddressify = false;
  filteredAddress: any = [];
  errorMsg!: string;
  isLoader: boolean = false;
  modalRef!: NgbModalRef;
  modelAlertRef!: NgbModalRef;
  modelAlertPIRef!: NgbModalRef;
  policytxnid = '';
  stateData: any;
  coverageType: string = '';
  DuplicateUser: boolean = false;
  updateDetailsNotification: string = 'Travellers Detail updated successfully.';
  addSecondaryTravellerQuantityNotification: string = 'New Traveller added to the policy. Please confirm with customer if there is Medical assessment required!!';
  deleteSecondaryTravellerQuantityNotification: string = 'Secondary traveller has been removed successfully';
  updateMessage!: string;
  secondaryTraveller: any = [];
  memberShipNumber: any;
  PrimaryTravelerAge: any;
  SecondaryTravellerAge: any;
  msg: string = 'Traveller detail updated successfully';
  isMessageVisible: boolean = false;
  Traveler1Value: string = '';
  Traveler2Value: string = '';
  baseContact: any;
  siteName: string = '';
  validationMessages: any;
  public errorMessages: any = {
    duplicateUserErrorMsg: ""
  };
  public PIModificationWarningMsg: string = '';
  public currentPolicyInStore: any;
  public IsPolicyInStore: boolean = false;
  public currentENInStore: any;
  public IsENInStore: boolean = false;
  public stateList: any[] = [];
  public isUpdateENCalled: boolean = false;

  public policyTravellerDetail: any = {
    PrimaryTravellerFirstName: '',
    PrimaryTravellerLastName: '',
    PrimaryTravellerDOB: '',
    PrimaryTravellerAddress: '',
    PrimaryTravellerEmailID: '',
    SecondaryTravellerFirstName: '',
    SecondaryTravellerLastName: '',
    SecondaryTravellerDOB: '',
  };

  address: any = {
    AddressId: '',
    CountryCode: null,
    ZipCode: null,
    StreetAddress: null,
    StateCode: null,
    City: null,
    AddressTypes: [5, 3],
  };

  @Input() isEditBtnRequired: boolean = true;
  @ViewChild('dialogContentTemplate', { static: true })
  dialogContent: TemplateRef<ElementRef>;
  @ViewChild('updateTravellerNotificationTemplate', { static: true })
  dialogalertBox!: TemplateRef<ElementRef>;
  @ViewChild('warningPIBox', { static: true })
  warningPIBox!: TemplateRef<ElementRef>;

  constructor(
    public formBuilder: FormBuilder,
    public dtaService: PolicyDetailService,
    public sitecoreItemService: SitecoreItemService,
    private modalService: NgbModal,
    private el: ElementRef,
    public endorsementGenericService: EndorsementGenericService,
    private newDateService: DateService,
    private formatNumberPipe: FormatNumberPipe,
    protected html_sanitizer: DomSanitizer,
    private store: Store,
    private storeService: StoreGenericService,
    private mapperService: MapperService
  ) {
    this.dialogContent = this.el.nativeElement;
    this.policytxnid = sessionStorage.getItem('movementGuid') || '';
  }

  get secondaryTravellers(): FormArray {
    return this.contactForm.get('secondaryTraveller') as FormArray;
  }

  get f() {
    return this.contactForm.controls;
  }

  get items(): FormArray {
    return this.contactForm.get('secondaryTraveller') as FormArray;
  }

  ngOnInit() {
    this.DuplicateUser = false;

    this.dtaService.fetchStaticList(EndPoint.PAS_STATIC_STATES).subscribe((resp) => {
      if (resp.length > 0) {
        this.stateList = resp;
      }
    });

    this.contactForm = this.formBuilder.group(
      {
        firstName: ['', Validators.compose([Validators.required, customValidator.noSpecialCharactersValidator])],
        lastName: ['', Validators.compose([Validators.required, customValidator.noSpecialCharactersValidator])],
        dob: ['', Validators.compose([Validators.required, customValidator.DateValidator,]),],
        secondaryTraveller: this.formBuilder.array([]),
        address: ['', Validators.required],
        state: ['', Validators.required],
        zipCode: ['', Validators.required],
        city: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
        confirmEmail: ['', Validators.required],
        phoneNumber: ['', Validators.compose([Validators.required, customValidator.numberValidator,])],
        memberShip: ['', Validators.compose([customValidator.memberShip])],
      },
      {
        validators: [
          customValidator.MustMatch('email', 'confirmEmail'),
          this.postalValidationForState('state', 'zipCode'),
          this.customDOB('dob'),
        ],
      },
    );

    this.contactForm.valueChanges.subscribe((form) => {
      if (form.phoneNumber) {
        this.contactForm.patchValue(
          { phoneNumber: this.formatNumberPipe.transform(form.phoneNumber) },
          { emitEvent: false, });
      }
    });

    //Policy Store
    this.store.select(isPolicyDataPresent).subscribe(present => {
      this.IsPolicyInStore = present;
    });

    this.store.select(selectCurrentPolicy).subscribe(pol => {
      this.currentPolicyInStore = pol;
      if (pol != null) {
        this.populatePolicyData(this.currentPolicyInStore);
      }
    });

    //Endorsement Store
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.isUpdateENCalled = present;
    });

    this.store.select(selectCurrentEndorsement).subscribe(en => {
      this.currentENInStore = en;
      if (en != null) {
        this.populatePolicyData(this.currentENInStore);
      }
    });
  }

  sortStateDropdownData(a: any, b: any) {
    if (a.ItemName.toLowerCase() > b.ItemName.toLowerCase()) return 1;
    if (b.ItemName.toLowerCase() > a.ItemName.toLowerCase()) return -1;

    return 0;
  }

  createItem(): FormGroup {
    return this.formBuilder.group(
      {
        SecondaryfirstName: ['', Validators.compose([
          Validators.required,
          customValidator.noSpecialCharactersValidator,
        ]),],
        SecondaryLastName: ['', Validators.compose([
          Validators.required,
          customValidator.noSpecialCharactersValidator,
        ]),],
        dob1: [
          '',
          Validators.compose([
            Validators.required,
            customValidator.DateValidator,
          ]),
        ],
      },
      {
        validators: [this.customDOB('dob1')],
      },
    );
  }

  sameAsHtml(html_content: any) {
    return this.html_sanitizer.bypassSecurityTrustHtml(html_content);
  }

  addNewRow() {
    this.secondaryTraveller = this.contactForm.get(
      'secondaryTraveller',
    ) as FormArray;
    this.secondaryTraveller.controls.length = 0;
    this.secondaryTraveller.push(this.createItem());
    this.secondrySubmit = null;
  }

  deleteIndividualRow(index: number) {
    this.DuplicateUser = false;
    const controlArray = (<FormArray>(
      this.contactForm.get('secondaryTraveller')
    )) as FormArray;
    controlArray.removeAt(index);
    this.secondrySubmit = null;
    // this.checkForPIDetailsUpdate();
  }

  setPostDateFormat(date: any) {
    if (
      date == '' ||
      date == null ||
      date === undefined ||
      typeof date != 'object'
    ) {
      return;
    }
    let ngbDate = date;
    let datevalue = ngbDate['day'].toString() + '-' + ngbDate['month'].toString() + '-' + ngbDate['year'].toString();
    return datevalue;
  }

  setPostDateFormatDuplicateCheck(date: any) {
    let ngbDate = date;
    if (ngbDate['month'] < 10 && !ngbDate['month'].toString().includes('0')) {
      ngbDate['month'] = '0' + ngbDate['month'];
    }
    if (ngbDate['day'] < 10 && !ngbDate['day'].toString().includes('0')) {
      ngbDate['day'] = '0' + ngbDate['day'];
    }
    let datevalue =
      ngbDate['day'].toString() +
      '-' +
      ngbDate['month'].toString() +
      '-' +
      ngbDate['year'].toString();
    return datevalue;
  }

  openAlertModel() {
    this.modelAlertRef = this.modalService.open(this.dialogalertBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeAlertChildModal() {
    this.modelAlertRef.close();
  }

  ageFromDateOfBirth(dob: any): any {
    const pricingDateString = sessionStorage.getItem('PricingDate');
    if (this.newDateService.isValidDate(dob) && pricingDateString) {
      if (dob && typeof dob === 'string' && (dob as string).length === 10) {
        //assuming we are getting DD/MM/YYYY here
        return this.newDateService.ageInYears(dob, pricingDateString);
      } else {
        const birthDate = this.newDateService.getDDMMYYYYFromDate(dob);
        return this.newDateService.ageInYears(birthDate, pricingDateString);
      }
    }
  }

  customDOB(dob: any) {
    return (formGroup: FormGroup) => {
      const firstControl = formGroup.controls[dob];
      if (firstControl.value && this.ageLength && !firstControl.pristine) {
        this.ageLength = this.autoClubCode.toUpperCase() == 'W2C' ? '75' : (this.coverageTypeName == 'ANNUAL MULTI-TRIP' || this.coverageTypeName == 'ANNUAL MULTI TRIP') ? '75' : '110';
        const maxlength = this.autoClubCode.toUpperCase() == 'W2C' ? 75 : 110;
        let age = this.ageFromDateOfBirth(`${firstControl.value.day}/${firstControl.value.month}/${firstControl.value.year}`,);

        if (
          (this.coverageTypeName == 'ANNUAL MULTI-TRIP' || this.coverageTypeName == 'ANNUAL MULTI TRIP') &&
          firstControl.value !== undefined &&
          (age < 18 || age > 75)
        ) {
          firstControl.setErrors({ ageRange: true });
        } else if (
          firstControl.value !== undefined &&
          (age < 18 || age > maxlength)
        ) {
          firstControl.setErrors({ ageRange: true });
        } else if (Number.isNaN(age)) {
          firstControl.setErrors({ invalidDate: true });
        } else {
          firstControl.setErrors(null);
        }
      }
    };
  }

  removeFormatting(inputStr: any) {
    if (inputStr) {
      return inputStr.replace(/[^a-zA-Z0-9]/g, '');
    }
  }

  //LTX
  async populatePolicyData(data: any) {
    this.coverageTypeName = data.quoteName.toUpperCase();
    this.autoClubCode = data.autoClub;
    this.policytxnid = data.movementGuid;
    this.siteName = data.autoClub;

    await this.fetchValidationMessages();

    if (this.autoClubCode?.toUpperCase() != 'W2C') {
      this.ageLength = '110';
    }

    if (data.travelers != undefined) {
      this.assignInitalTravelerData(data);
    }

    if (data && data.rateDate) {
      sessionStorage.setItem('PricingDate', data.rateDate);
    }

    this.PrimaryTravelerAge = Utility.ageFromDateOfBirth(this.policyTravellerDetail.PrimaryTravellerDOB);

    if (this.policyTravellerDetail.SecondaryTravellerDOB) {
      this.SecondaryTravellerAge = Utility.ageFromDateOfBirth(this.policyTravellerDetail.SecondaryTravellerDOB);
    }

    this.setStatesData();

    if (data.movements) {
      let policyMovement = data.movements;
      let prPolicy = policyMovement.find((ele: any) => ele.TxnTypeCode == 'RW' && ele.TxnStatusCode === 'PR' && ele.Status !== 'Completed');
      this.prpolicytype = prPolicy ? false : true;
    }
  }

  assignInitalTravelerData(data: any): void {
    let travelers: any[] = data.travelers;
    this.policyTravellerDetail.MembershipNumber = data.membershipNumber;
    let isSecondaryTraveler = data.travelers.filter((x: { role: string; }) => x.role == "secondary");
    travelers.forEach(traveler => {
      if (traveler.role === 'primary') {
        this.policyTravellerDetail.PrimaryTravellerFirstName = traveler.firstName;
        this.policyTravellerDetail.PrimaryTravellerLastName = traveler.lastName;
        this.policyTravellerDetail.PrimaryTravellerDOB = traveler.dateOfBirth;
        this.policyTravellerDetail.PrimaryTravellerEmailID = traveler.email || '';
        this.policyTravellerDetail.PrimaryTravellerPhoneNum = traveler.phone || '';
        this.address.CountryCode = null;
        this.address.StreetAddress = traveler.address ? (traveler.address.address1) ? traveler.address.address + traveler.address.address1 : traveler.address.address : '';
        this.address.City = traveler.address.city || '';
        this.address.StateCode = this.stateList.find(x => x.id == traveler.address.stateId).code;
        this.address.ZipCode = traveler.address.postalCode || '';
        this.policyTravellerDetail.PrimaryTravellerAddress = (this.address != null) ? this.address.StreetAddress.trim() + ' ' + this.address.City.trim() + ', ' + this.address.StateCode.trim() + ' ' + this.address.ZipCode.trim() : '';
      }
      else if (traveler.role === 'secondary') {
        this.policyTravellerDetail.SecondaryTravellerFirstName = traveler.firstName;
        this.policyTravellerDetail.SecondaryTravellerLastName = traveler.lastName;
        this.policyTravellerDetail.SecondaryTravellerDOB = traveler.dateOfBirth;
      }
    });
    if (isSecondaryTraveler.length == 0) {
      this.policyTravellerDetail.SecondaryTravellerFirstName = '';
      this.policyTravellerDetail.SecondaryTravellerLastName = '';
      this.policyTravellerDetail.SecondaryTravellerDOB = '';
    }
  }

  async editTravelerData() {
    let ngbDateStruct; let ngbDateStruct1; let year; let day; let month;

    if (this.policyTravellerDetail.PrimaryTravellerDOB) {
      let dateEff = this.policyTravellerDetail.PrimaryTravellerDOB.split('T')[0].split('/');
      day = dateEff[0];
      month = dateEff[1];
      year = dateEff[2];
      ngbDateStruct = { month: Number(month), day: Number(day), year: Number(year) };
    }

    if (this.policyTravellerDetail.SecondaryTravellerDOB) {
      let dateEff = this.policyTravellerDetail.SecondaryTravellerDOB.split('T')[0].split('/');
      day = dateEff[0];
      month = dateEff[1];
      year = dateEff[2];
      ngbDateStruct1 = { month: Number(month), day: Number(day), year: Number(year) };
    }

    if (this.policyTravellerDetail.SecondaryTravellerFirstName) {
      this.addNewRow();
    }

    if (this.policyTravellerDetail.MembershipNumber) {
      this.memberShipNumber = this.policyTravellerDetail.MembershipNumber;
    }

    this.contactForm.patchValue({
      firstName: this.policyTravellerDetail.PrimaryTravellerFirstName != undefined ? this.policyTravellerDetail.PrimaryTravellerFirstName.trim() : '',
      lastName: this.policyTravellerDetail.PrimaryTravellerLastName != undefined ? this.policyTravellerDetail.PrimaryTravellerLastName.trim() : '',
      dob: ngbDateStruct,
      secondaryTraveller: [{
        SecondaryfirstName: this.policyTravellerDetail.SecondaryTravellerFirstName != undefined ? this.policyTravellerDetail.SecondaryTravellerFirstName.trim() : '',
        SecondaryLastName: this.policyTravellerDetail.SecondaryTravellerLastName != undefined ? this.policyTravellerDetail.SecondaryTravellerLastName.trim() : '',
        dob1: ngbDateStruct1
      }],
      address: this.address.StreetAddress != undefined ? this.address.StreetAddress : '',
      state: await this.matchStateName(this.address.StateCode != undefined ? this.address.StateCode : ''),
      city: this.address.City != undefined ? this.address.City : '',
      zipCode: this.address.ZipCode != undefined ? this.address.ZipCode : '',
      email: this.policyTravellerDetail.PrimaryTravellerEmailID,
      confirmEmail: this.policyTravellerDetail.PrimaryTravellerEmailID,
      phoneNumber: this.policyTravellerDetail.PrimaryTravellerPhoneNum != undefined ? this.policyTravellerDetail.PrimaryTravellerPhoneNum : '',
      memberShip: this.memberShipNumber != undefined ? this.memberShipNumber : ''
    });
  }

  openModal() {
    this.editTravelerData();
    this.errorMessages.duplicateUserErrorMsg = "";
    this.modalRef = this.modalService.open(this.dialogContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeModal() {
    this.modalRef.close();
    this.isMessageVisible = true;
    setTimeout(() => {
      this.isMessageVisible = false;
    }, 9000);
  }

  async closeModal1() {
    if (this.currentENInStore != null) {
      await this.editTravelerData();
    }
    this.DuplicateUser = false;
    this.modalRef.close();
  }

  doFilterForAddressify() {
    if (this.contactForm.value.address.length > 1) {
      this.isLoadingAddressify = true;

      this.dtaService.fetchFromAddressify(false, this.contactForm.value.address, this.contactForm.value.state).toPromise().then((data) => {
        this.isLoadingAddressify = false;
        if (data == undefined || data.length == 0) {
          this.errorMsg = 'No record found';
          this.filteredAddress = [];
          this.clearAddressify();
        } else {
          this.errorMsg = '';
          this.filteredAddress = data;
        }
      });
    } else {
      this.isLoadingAddressify = false;
      this.errorMsg = '';
      this.filteredAddress = [];
      this.clearAddressify();
    }
  }

  setSelectedAddr(addr: any) {
    this.dtaService.fetchFromAddressify(true, addr, this.address.StateCode).toPromise().then((data) => {
      let infodata = data;
      if (infodata!.Suburb && infodata!.State) {
        this.contactForm.patchValue({ address: infodata.StreetLine, state: infodata.State, zipCode: infodata.Postcode, city: infodata.Suburb });
      }
    });
    this.filteredAddress.length = 0;
  }

  postalValidationForState(firstControlName: string, secondControlName: string,) {
    return (formGroup: FormGroup) => {
      const numPattern = /^[0-9]+$/i;
      const firstControl = formGroup.controls[firstControlName];
      const secondControl = formGroup.controls[secondControlName];

      if (secondControl.value) {
        if (!numPattern.test(secondControl.value.trim()) || secondControl.value.trim().length !== 4) {
          secondControl.setErrors({ invalidPostalCode: true });
        }

        if (firstControl.value && this.stateData) {
          const selectedState = this.stateData.find((p: any) => p.code == firstControl.value,);
          if (selectedState) {
            let postCodeArray = selectedState.zipCodes.split(',');
            let postCodeRangeCount = 0;
            for (let i of postCodeArray) {
              let postcodeRange = i.split('–');
              if (parseInt(secondControl.value, 10) >= parseInt(postcodeRange[0], 10) && parseInt(secondControl.value, 10) <= parseInt(postcodeRange[1], 10)) {
                secondControl.setErrors(null);
              } else {
                postCodeRangeCount++;
              }
              if (postCodeRangeCount === postCodeArray.length) {
                secondControl.setErrors({ invalidPostalCode: true });
              }
            }
          }
        }
      }
    };
  }

  clearAddressify() {
    this.address.StateCode = '';
    this.address.ZipCode = '';
  }

  async matchStateName(nameMatch: string) {
    if (this.stateData) {
      if (this.stateData.length == 0) {
        await this.setStatesData();
      }
      const selectedState = this.stateData.find((p: { code: string; }) => p.code.substring(0, 2) == nameMatch.substring(0, 2));
      if (selectedState) {
        return selectedState.code;
      }
      else {
        return '';
      }
    }
    else {
      return nameMatch;
    }
  }

  async setStatesData() {
    if (this.stateList && this.stateList.length > 0) {
      this.stateData = this.stateList;
    }
    else {
      await this.dtaService.fetchStaticList(EndPoint.PAS_STATIC_STATES).subscribe((resp) => {
        if (resp.length > 0) {
          this.stateList = resp;
          this.stateData = this.stateList;
        }
      });

    }
  }

  async fetchValidationMessages() {
    let validationResp = await this.dtaService.fetchValidationMessages(EndPoint.PAS_VALIDATION_MESSAGES).toPromise();
    if (validationResp) {

      if (Object.hasOwn(validationResp, this.siteName)) {
        this.validationMessages = validationResp[this.siteName];
        this.errorMessages.duplicateUserErrorMsg = this.validationMessages != null && this.validationMessages.hasOwnProperty('duplicateUserErrorMsg') ? this.validationMessages.duplicateUserErrorMsg : '';
      }
    }
  }

  async onSubmit() {
    var updateRequest: any = {};
    this.DuplicateUser = false;
    this.isLoader = true;
    this.submitted = true;
    this.secondrySubmit = false;
    if (this.contactForm.invalid) {
      this.isLoader = false;
      return;
    }

    //Check if Traveler 1 and Traveler 2 same 
    this.Traveler1Value = (this.contactForm.value.firstName + this.contactForm.value.lastName + this.setPostDateFormatDuplicateCheck(this.contactForm.value.dob)).toUpperCase().trim();
    if (
      this.contactForm.value.secondaryTraveller.length > 0 &&
      this.contactForm.value.secondaryTraveller[0].SecondaryfirstName != '' &&
      this.contactForm.value.secondaryTraveller[0].SecondaryfirstName != null &&
      this.contactForm.value.secondaryTraveller[0].SecondaryLastName != '' &&
      this.contactForm.value.secondaryTraveller[0].SecondaryLastName != null
    ) {
      this.Traveler2Value = (this.contactForm.value.secondaryTraveller[0].SecondaryfirstName + this.contactForm.value.secondaryTraveller[0].SecondaryLastName + this.setPostDateFormatDuplicateCheck(this.contactForm.value.secondaryTraveller[0].dob1,)).toUpperCase().trim();
      if (this.Traveler1Value == this.Traveler2Value) {
        this.isLoader = false;
        this.DuplicateUser = true;
        if (this.errorMessages.duplicateUserErrorMsg == '') {
          this.fetchValidationMessages();
        }
        return;
      }
    }
    let secondaryTravellerExist = this.policyTravellerDetail.SecondaryTravellerFirstName ? true : false;
    this.secondrySubmit = true;

    if (this.currentENInStore) {
      Object.assign(updateRequest, this.currentENInStore);
    }

    //Check if Traveler 1 and Traveler 2 are not same as Dependents 
    if (updateRequest.travelers != undefined) {
      let enDependents = updateRequest.travelers.filter((x: { role: string; }) => x.role == "dependent");
      if (enDependents.length > 0) {
        for (let i in enDependents) {
          var CheckDep = (enDependents[i].firstName + enDependents[i].lastName + enDependents[i].dateOfBirth.substring(0, 10)?.replaceAll('/', '-')).toUpperCase().trim();
          if (this.Traveler1Value == CheckDep ||
            (this.contactForm.value.secondaryTraveller.length > 0 &&
              this.contactForm.value.secondaryTraveller[0].SecondaryfirstName != '' &&
              this.contactForm.value.secondaryTraveller[0].SecondaryfirstName != null &&
              this.contactForm.value.secondaryTraveller[0].SecondaryLastName != '' &&
              this.contactForm.value.secondaryTraveller[0].SecondaryLastName != null &&
              this.Traveler2Value == CheckDep
            )
          ) {
            this.DuplicateUser = true;
            if (this.errorMessages.duplicateUserErrorMsg == '') {
              this.fetchValidationMessages();
            }
            this.isLoader = false;
            return;
          }
        }
      }
    }

    //Create Update Request
    this.createUpdateRequest(updateRequest);

    updateRequest = await this.mapperService.mapEndorsementModel(updateRequest, "revision", "TravelerUpdated");
    this.dtaService.updateEndorsement(updateRequest).subscribe((resdata) => {
      if (resdata) {
        this.storeService.setENToStore(resdata);
        sessionStorage.setItem('movementGuid', resdata.movementGuid);
        this.isLoader = false;
        this.closeModal();
        let updatedSecondaryTvl = resdata.travelers.filter((x: { role: string; }) => x.role == "secondary");
        if (secondaryTravellerExist == false && updatedSecondaryTvl.length > 0) {
          this.updateMessage = this.addSecondaryTravellerQuantityNotification;
        }
        else if (secondaryTravellerExist == true && updatedSecondaryTvl.length == 0) {
          this.updateMessage = this.deleteSecondaryTravellerQuantityNotification;
        }
        else {
          this.updateMessage = this.updateDetailsNotification;
        }
        this.openAlertModel();
      }
      else {
        this.isLoader = false;
        this.msg = 'Traveller detail not updated successfully.';
        this.closeModal();
      }
      this.submitted = false;
    },
      () => {
        this.isLoader = false;
        this.msg = 'There is an issue with backend response. Please retry after some time';
        this.closeModal();
      },
    );
  }

  createUpdateRequest(data: any) {
    let selectedState = (this.stateList != null) ? this.stateList.filter(x => x.code == this.contactForm.value.state) : null;
    data.stateId = (selectedState != null) ? selectedState[0].id : '';;
    data.state = (selectedState != null) ? selectedState[0].code : '';;

    let primaryTraveler = data.travelers.filter((x: { role: string; }) => x.role == "primary")[0];
    primaryTraveler.firstName = this.contactForm.value.firstName;
    primaryTraveler.lastName = this.contactForm.value.lastName;
    primaryTraveler.dateOfBirth = this.setPostDateFormat(this.contactForm.value.dob)?.replaceAll('-', '/');
    primaryTraveler.email = this.contactForm.value.email;
    primaryTraveler.phone = this.removeFormatting(this.contactForm.value.phoneNumber);
    primaryTraveler.address.address = this.contactForm.value.address;
    primaryTraveler.address.postalCode = this.contactForm.value.zipCode;
    primaryTraveler.address.city = this.contactForm.value.city;
    let primaryDOB = this.newDateService.getDateByFormat(primaryTraveler.dateOfBirth);
    data.ages[0] = this.newDateService.travelerAgeInYears(primaryDOB);

    if (this.contactForm.value.memberShip == '') {
      data.membershipNumber = "";
    } else {
      data.membershipNumber = this.contactForm.value.memberShip;
    }

    if (this.contactForm.value.secondaryTraveller.length > 0 && this.contactForm.value.secondaryTraveller[0].SecondaryfirstName != ''
      && this.contactForm.value.secondaryTraveller[0].SecondaryfirstName != null && this.contactForm.value.secondaryTraveller[0].SecondaryLastName != ''
      && this.contactForm.value.secondaryTraveller[0].SecondaryLastName != null) {
      let isSecondaryTraveler = data.travelers.filter((x: { role: string; }) => x.role == "secondary");
      let secondaryTraveler = (isSecondaryTraveler.length > 0) ? isSecondaryTraveler[0] : null;
      let traveler2: any;
      if (secondaryTraveler && secondaryTraveler.firstName) {
        secondaryTraveler.firstName = this.contactForm.value.secondaryTraveller[0].SecondaryfirstName;
        secondaryTraveler.lastName = this.contactForm.value.secondaryTraveller[0].SecondaryLastName;
        secondaryTraveler.dateOfBirth = this.setPostDateFormat(this.contactForm.value.secondaryTraveller[0].dob1)?.replaceAll('-', '/');
        data.ages[1] = this.newDateService.travelerAgeInYears(this.newDateService.getDateByFormat(secondaryTraveler.dateOfBirth));
      }
      else {
        traveler2 = {
          firstName: this.contactForm.value.secondaryTraveller[0].SecondaryfirstName,
          lastName: this.contactForm.value.secondaryTraveller[0].SecondaryLastName,
          role: "secondary",
          medicalDeclarations: {},
          dateOfBirth: this.setPostDateFormat(this.contactForm.value.secondaryTraveller[0].dob1)?.replaceAll('-', '/')
        };
        data.ages[1] = this.newDateService.travelerAgeInYears(this.newDateService.getDateByFormat(traveler2.dateOfBirth));
        data.travelers.push(traveler2);
      }
    }
    else {
      let isSecondaryTraveler = data.travelers.filter((x: { role: string; }) => x.role == "secondary");
      if (isSecondaryTraveler.length > 0) {
        let finalTravelers = data.travelers.filter((x: { role: string; }) => x.role != "secondary");
        data.travelers = finalTravelers;
        data.ages.splice(1, 1);
      }
    }
  }
}
