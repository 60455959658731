import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PolicyDetailsQuoteComponent } from '../policy-details-quote/policy-details-quote.component';
import { DialogService } from '../dialog/dialog.service';
import { DataBusService } from '../services/databus.service';
import { MessageBusService } from '../services/messagebus.service';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { PolicyDetailService } from 'projects/policyDetails/src/app/app.services';
import { Store } from '@ngrx/store';
import { isPolicyDataPresent,selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { isEndorsementDataPresent, selectCurrentEndorsement} from 'src/app/core/store/endorsement/endorsement.selectors';

@Component({
  selector: 'policyDetails-policy-details-travel-care',
  templateUrl: './policy-details-travel-care.component.html',
  styleUrls: ['./policy-details-travel-care.component.css'],
})
export class PolicyDetailsTravelCareComponent implements OnInit {
  modalRef!: NgbModalRef;
  public isLoadeing: string = 'Yes';
  public policyData: any;
  public policyTravelCares: any = {
    Excess: '',
    Cruise: '',
    WinterSports: '',
    Sking: '',
    CoverageType: 'COVERAGE TYPE',
  };
  selectedExcessName = '';
  siteName = '';
  public excessLebel = 'Excess';
  @Input() isEditBtnRequired: boolean = true;
  @ViewChild('dialogQuoteTemplate', { static: true })
  dialogContent!: TemplateRef<ElementRef>;
  public currentPolicyInStore : any;
  public IsPolicyInStore: boolean  = false;
  public currentENInStore : any;
  public IsENInStore: boolean  = false;
  public isUpdateENCalled: boolean  = false;


  constructor(
    public dialog: DialogService,
    public dataService: PolicyDetailService,
    private store: Store
  ) { }

  ngOnInit() {
    //Policy Store
    this.store.select(isPolicyDataPresent).subscribe(present => {
      this.IsPolicyInStore = present;
    });

    this.store.select(selectCurrentPolicy).subscribe(pol =>{
      this.currentPolicyInStore = pol;
      if(pol != null)
      {
        this.populatePolicyData(this.currentPolicyInStore);
      }
    });

    //Endorsement Store
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.isUpdateENCalled = present;
    });
    
    this.store.select(selectCurrentEndorsement).subscribe(en =>{
      this.currentENInStore = en;
      if(en != null)
        {
          this.populatePolicyData(this.currentENInStore);
        }
    });
  }
  
  openQuoteModal() {
    let transactionData  = {
      policy : this.policyData,
      endorsement : this.currentENInStore
    }

    const ref = this.dialog.open(PolicyDetailsQuoteComponent, {
      data: transactionData,
    });

    ref.afterClosed.subscribe((result) => {
      if (result != undefined) {
        this.isLoadeing = 'Yes';
      } else {
        this.isLoadeing = 'No';
      }
      this.isLoadeing = 'No';
    });
  }
  
  closeModal() {
    this.isLoadeing = 'No';
    this.modalRef.close();
  }

  populatePolicyData(data: any) {
    this.excessLebel = 'Excess';

    if (data) {
      this.isLoadeing = 'No';
      this.policyData = data;
      this.policyTravelCares = data;
      this.policyTravelCares.Cruise = this.policyTravelCares.isCruise == undefined ? '' : this.policyTravelCares.isCruise == true ? 'Yes' : 'No';
      this.policyTravelCares.Sking = this.policyTravelCares.isSking == undefined ? '' : this.policyTravelCares.isSking == true ? 'Yes' : 'No';

      let isAgeGreaterThan85 = this.policyData.ages.find((age : any) => age > 85) != null ? true : false;
      if(isAgeGreaterThan85){
        this.policyTravelCares.Sking = 'N/A';
      }

      // this.policyTravelCares.CoverageType = data.productType != undefined ? data.productType?.toUpperCase() : '';
      this.policyTravelCares.CoverageType = (data.productType != undefined && data.productAlias != undefined) ? data.productAlias?.toUpperCase() : data.productType ;

      this.siteName = data.autoClub;
    }
    let quoteName = data.quoteName;
    let IsDomestic = data.productType == 'Domestic' ? true : false;

    if (IsDomestic && quoteName === 'Tier1') {
      this.selectedExcessName = data.excess;
      this.excessLebel = 'Rental Car excess';
    }
  }

}
