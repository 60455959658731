import { Component, EventEmitter, inject, Input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  standalone: true,
  imports: [],
  templateUrl: './confirm-modal.component.html',
  styleUrl: './confirm-modal.component.css',
})
export class ConfirmModalComponent {
  @ViewChild('confirmationBox') confirmationModal!: TemplateRef<any>;
  @Input() confirmModalEvent!: EventEmitter<string>;
  private modalService = inject(NgbModal);
  message: string = '';

  ngOnInit(): void {
    if (this.confirmModalEvent) {
      this.confirmModalEvent.subscribe((message: string) => {
        this.message = message;
        this.open(this.confirmationModal);
      });
    }
  }

  open(content: TemplateRef<any>) {
    this.modalService.open(content, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    }).result.then(
      (result) => {
      },
      (reason) => {
      },
    );
  }
}
