import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe, PercentPipe, DatePipe } from '@angular/common';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
import { CustomNgbDateParserFormatter } from 'src/app/core/services/customNgbDateParserFormatter';
import { PolicyPaymentsComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InterceptorsService } from 'src/app/core/services/interceptors.service';
import { LogPublishersService } from 'projects/policyDetails/src/app/shared/logging/log-publisher.service';
import { HttpCoreService } from 'src/app/core/services/http-core.service';
import { AppConfig } from 'projects/policyDetails/src/app/shared/config/app.config';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { NgxBraintreeService } from 'projects/paymentGateWay/src/app/ngx-braintree.service';
export { PolicyPaymentsComponent } from './app.component';

export function initConfig(config: AppConfig) {
  return () => config.load();
}

@NgModule({
  declarations: [PolicyPaymentsComponent],
  imports: [
    BrowserModule,
    FormsModule,
    LoaderModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    DataTablesModule,
  ],
  providers: [
    CurrencyPipe,
    PercentPipe,
    DatePipe,
    OktaCustomAuthService,
    HttpCoreService,
    LogPublishersService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true,
    },
    { provide: NgxBraintreeService, useClass: NgxBraintreeService },
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new CustomNgbDateParserFormatter('dd/MM/yyyy'),
    },
  ],
})
export class PolicyPaymentsModule {}
