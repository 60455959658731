import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, } from '@angular/forms';
import { PolicyDetailService } from '../app.services';
import { NgbModal, NgbModalRef, NgbDateStruct, NgbDateParserFormatter, } from '@ng-bootstrap/ng-bootstrap';
import { Utility } from '../shared/utility';
import { customValidator } from '../policy-details-travellers/validator';
import { NgbDateFRParserFormatter } from './ngb-date-fr-parser-formatter';
import { DateService } from 'src/app/core/services/date-updated.service';
import { SitecoreItemService } from '../services/sitecore-item.service';
import { Store } from '@ngrx/store';
import { isPolicyDataPresent,selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { isEndorsementDataPresent, selectCurrentEndorsement } from 'src/app/core/store/endorsement/endorsement.selectors';
import { EndPoint } from 'src/app/core/services/endpoint';
import { MapperService } from 'src/app/core/services/mapper.services';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';

@Component({
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
  ],
  selector: 'policyDetails-policy-details-dependents',
  templateUrl: './policy-details-dependents.component.html',
  styleUrls: ['./policy-details-dependents.component.css'],
})
export class PolicyDetailsDependentsComponent implements OnInit {
  dependentForm!: FormGroup;
  modalRef!: NgbModalRef;
  modelAlertRef!: NgbModalRef;
  submitted: any = null;
  prpolicytype!: boolean;
  //  items: FormArray;
  endorseData: any;
  DuplicateDependents: boolean = false;
  DuplicateDependentsErrorMsg!: string;
  DependentTravelerAge: any;
  policytxnid = '';
  public dependentsList: any;
  disabledSavedependent = false;
  public policyData: any;
  isLoader: boolean = false;
  msg: string = 'Dependent detail updated successfully';
  cancelMessageSuccess = 'New Dependents added to the policy. Please confirm with customer if there is Medical assessment required!!';
  isMessageVisible: boolean = false;
  now: Date = new Date();
  minDate: any = {};
  maxDate: NgbDateStruct = {
    year: this.now.getFullYear(),
    month: this.now.getMonth() + 1,
    day: this.now.getDate(),
  };
  public currentENInStore : any;
  public IsENInStore: boolean  = false;

  @Input() isEditBtnRequired: boolean = true;
  @ViewChild('dialogContentTemplate', { static: true })
  dialogContent!: TemplateRef<ElementRef>;
  @ViewChild('confirmMessageTemplate', { static: true })
  dialogalertBox: TemplateRef<ElementRef>;
  public currentPolicyInStore : any;
  public IsPolicyInStore: boolean  = false;
  public isUpdateENCalled : boolean = false;
  public validationMessages : any = {};
  public siteName : string = "";
  public errorMessages : any = {
    duplicateDependentErrorMsg : ""
  };

  constructor(
    public formBuilder: FormBuilder,
    public dtaService: PolicyDetailService,
    private modalService: NgbModal,
    private el: ElementRef,
    private newDateService: DateService,
    public sitecoreItemService: SitecoreItemService,
    private store: Store,
    private mapperService : MapperService,
    private storeService : StoreGenericService
  ) {
    this.policytxnid = sessionStorage.getItem('movementGuid') || '';
    this.dialogalertBox = this.el.nativeElement;

    const minDate = this.newDateService .now() .subtract(26, 'years') .add(1, 'days');
    const maxDate = this.newDateService.now();

    this.minDate = {
      year: minDate.year(),
      month: minDate.month() + 1,
      day: minDate.date(),
    };
    this.maxDate = {
      year: maxDate.year(),
      month: maxDate.month() + 1,
      day: maxDate.date(),
    };
  }

  ngOnInit() {
    this.DuplicateDependents = false;
    this.dependentsList = [];
    
  
    //Policy Store
    this.store.select(isPolicyDataPresent).subscribe(present => {
      this.IsPolicyInStore = present;
    });

    this.store.select(selectCurrentPolicy).subscribe(pol =>{
      this.currentPolicyInStore = pol;
      if(pol != null)
      {
        this.policyData = this.currentPolicyInStore;
        this.populatePolicyData(this.currentPolicyInStore);
      }
    });

     //Endorsement Store
     this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.isUpdateENCalled = present;
    });

    this.store.select(selectCurrentEndorsement).subscribe(en =>{
      this.currentENInStore = en;
      if(en != null)
        {
          this.populatePolicyData(this.currentENInStore);
        }
    });
  }

  get items(): FormArray {
    return this.dependentForm.get('items') as FormArray;
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      firstName: [
        '',
        Validators.compose([
          Validators.required,
          customValidator.requiredWithoutBlank,
          customValidator.noSpecialCharactersValidator
        ]),
      ],
      lastName: [
        '',
        Validators.compose([
          Validators.required,
          customValidator.requiredWithoutBlank,
          customValidator.noSpecialCharactersValidator
        ]),
      ],
      index: [''],
      travelerId: [''],
      dob: [
        '',
        Validators.compose([
          Validators.required,
          customValidator.DateValidator,
          customValidator.ageRangeValidatorForDependent,
        ]),
      ],
    });
  }

  getAgeFromDOB(dob: any) {
    if (!dob) {
      return 0;
    }
    return Utility.ageFromDateOfBirth(dob);
  }

  openModal() {
    this.modalRef = this.modalService.open(this.dialogContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

    this.dependentForm = this.formBuilder.group({ items: this.formBuilder.array([]), });

    const controlArray = (<FormArray>( this.dependentForm.get('items') )) as FormArray;

    if (this.dependentsList != undefined) {
      this.dependentsList.forEach(() => {
        controlArray.push(this.createItem());
      });

      this.dependentsList.forEach((ele: any, index: any) => {
        controlArray.controls[index].get('firstName')?.setValue(ele.firstName.trim());
        controlArray.controls[index].get('lastName')?.setValue(ele.lastName.trim());
        controlArray.controls[index].get('travelerId')?.setValue(ele.travelerId.trim());
        controlArray.controls[index].get('index')?.setValue(index);

        let ngbDateStruct;
        let year;
        let day;
        let month;

        if (ele.dateOfBirth) {
          let dateEff = ele.dateOfBirth.split('T')[0].split('/');
          day = dateEff[0];
          month = dateEff[1];
          year = dateEff[2];
          ngbDateStruct = {
            month: Number(month),
            day: Number(day),
            year: Number(year),
          };
        }
        controlArray.controls[index].get('dob')?.setValue(ngbDateStruct);
      });

      this.disabledSavedependent = (this.dependentsList.length == 0) ? true : false;
    } 
    else {
      this.disabledSavedependent = true;
    }
  }
  
  openAlertModel() {
    this.modelAlertRef = this.modalService.open(this.dialogalertBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeAlertChildModal() {
    this.modelAlertRef.close();
  }

  setPostDateFormat(date: any) {
    if (
      date == '' ||
      date == null ||
      date === undefined ||
      typeof date != 'object'
    ) {
      return;
    }
    let ngbDate = date;
    let datevalue = ngbDate['day'].toString().padStart(2, "0") + '-' + ngbDate['month'].toString().padStart(2, "0") + '-' + ngbDate['year'].toString();
    return datevalue;
  }

  setPostDateFormatDuplicateCheck(date: any) {
    let ngbDate = date;
    if (ngbDate['month'] < 10 && !ngbDate['month'].toString().includes('0')) {
      ngbDate['month'] = '0' + ngbDate['month'];
    }
    if (ngbDate['day'] < 10 && !ngbDate['day'].toString().includes('0')) {
      ngbDate['day'] = '0' + ngbDate['day'];
    }
    let datevalue = ngbDate['day'].toString() + '-' + ngbDate['month'].toString() + '-' + ngbDate['year'].toString();
    return datevalue;
  }

  editNewRow() {
    this.submitted = null;
    this.items.push(this.createItem());
  }

  closeModal() {
    this.modalRef.close();
    this.isMessageVisible = true;
    setTimeout(() => {
      this.isMessageVisible = false;
    }, 9000);
  }

  closeModal1() {
    this.DuplicateDependents = false;
    this.modalRef.close();
  }

  deleteIndividualDependentRow(index: number) {
    this.DuplicateDependents = false;
    const controlArray = (<FormArray>(
      this.dependentForm.get('items')
    )) as FormArray;
    controlArray.removeAt(index);
    this.submitted = null;
  }

  async populatePolicyData(data: any) {
    this.siteName = data.autoClub;
    this.policytxnid =  data.movementGuid;
    await this.fetchValidationMessages();
    if(data.travelers && data.travelers.length > 0){
      let travelers : any [] = data.travelers;
      this.dependentsList = travelers.filter(x => x.role === "dependent");
    }
      
    if (data.movements) {
      let policyMovement = data.movements;
      let prPolicy = policyMovement.find(
        (ele: any) =>
          ele.TxnTypeCode == 'RW' &&
          ele.TxnStatusCode === 'PR' &&
          ele.Status !== 'Completed',
      );
      this.prpolicytype = prPolicy ? false : true;
    }
  }

  async updateDependent() {
    this.DuplicateDependents = false;
    let dependentsCount = 0;
    this.submitted = false;
    var updateRequest: any = {};

    if (this.dependentForm.invalid) {
      return;
    }
    if (this.dependentForm.value.items.length > 0) {
      for (let i in this.dependentForm.value.items) {
        var currentDep = (this.dependentForm.value.items[i].firstName + this.dependentForm.value.items[i].lastName + this.setPostDateFormatDuplicateCheck(this.dependentForm.value.items[i].dob)).toUpperCase().trim();
        for (let j in this.dependentForm.value.items) {
          if (i != j) {
            var CheckDep =(this.dependentForm.value.items[j].firstName + this.dependentForm.value.items[j].lastName + this.setPostDateFormatDuplicateCheck(this.dependentForm.value.items[j].dob) ).toUpperCase().trim();
            if (currentDep == CheckDep) {
              this.DuplicateDependents = true;
              this.DuplicateDependentsErrorMsg = this.errorMessages.duplicateDependentErrorMsg;
              return;
            }
          }
        }
        if (this.DuplicateDependents) {
          this.DuplicateDependentsErrorMsg = this.errorMessages.duplicateDependentErrorMsg;
          return;
        }
      }
    }
    this.isLoader = true;
    this.submitted = true;
    if (this.dependentsList) {
      dependentsCount = this.dependentsList.length;
    }
    if (this.currentENInStore) {
      Object.assign(updateRequest, this.currentENInStore);
    }
    let enDependents = updateRequest.travelers.filter((x: { role: string; }) => x.role == "dependent");

    //Assign -dependents to request object 
    if (updateRequest != null && this.dependentForm.value.items.length > 0) {
      this.dependentForm.value.items.map((traveler : any) => {
          let currentTraveler = updateRequest.travelers.find((trv: any) => trv.travelerId == traveler.travelerId);
          if(currentTraveler != null){
            currentTraveler.firstName = traveler.firstName;
            currentTraveler.lastName = traveler.lastName;
            currentTraveler.dateOfBirth = this.setPostDateFormat(traveler.dob)?.replaceAll('-','/');
          }
          else{
            let obj = {
              firstName : traveler.firstName,
              lastName : traveler.lastName,
              role : "dependent",
              medicalDeclarations : {},
              dateOfBirth : this.setPostDateFormat(traveler.dob)?.replaceAll('-','/')
            };
            updateRequest.travelers.push(obj);
          }
      });
    }
    //Dependents to be Removed
    let travelersToRemove = this.findTravelerToBeRemoved(this.dependentForm.value.items,enDependents);
    let finalTravelers = updateRequest.travelers.filter((traveler: { travelerId: any; }) => !travelersToRemove.includes(traveler.travelerId))
    updateRequest.travelers= finalTravelers;

    updateRequest = await this.mapperService.mapEndorsementModel(updateRequest, "revision", "DependentsUpdated");
      updateRequest.dependentsCount = this.dependentForm.value.items.length ?? 0;
      this.dtaService.updateEndorsement(updateRequest).subscribe( (resdata) => {
          if (resdata) {
            this.storeService.setENToStore(resdata);
            if (resdata.travelers) {
              let dependents = resdata.travelers.filter((x: { role: string; }) => x.role == "dependent");
              if (dependents && dependents.length > dependentsCount) {
                this.cancelMessageSuccess = 'New Dependents added to the policy. Please confirm with customer if there is Medical assessment required!!';
              }
              else if (dependents.length < dependentsCount) {
                this.cancelMessageSuccess = 'Dependent has been removed successfully ';
              }
              else {
                this.cancelMessageSuccess = 'Dependents details updated successfully ';
              }
            }
            this.isLoader = false;
            sessionStorage.setItem('movementGuid', resdata.movementGuid.toString(),);
            this.closeModal();
          } 
          else {
            this.isLoader = false;
            this.msg = 'Dependent not updated successfully.';
            this.closeModal();
          }
          this.isLoader = false;
          this.closeModal();
          this.openAlertModel();
        },() => {
          this.isLoader = false;
          this.msg = 'Something went wrong! Please retry after some time.';
          this.closeModal();
        },
      );
  }

  findTravelerToBeRemoved(travelerList1: any[], travelerList2: any[]) {
    let ids1 = travelerList1.map(obj => obj.travelerId);
    let ids2 = travelerList2.map(obj => obj.travelerId);

    // Find ids in list2 that are not in list1
    let extraIds = ids2.filter(id => !ids1.includes(id));

    // Find objects in list2 corresponding to extra ids
    let travelersToRemove = travelerList2.filter(obj => extraIds.includes(obj.travelerId));
    return travelersToRemove.map(obj => obj.travelerId)
  }

  async fetchValidationMessages() {
    let validationResp = await this.dtaService.fetchValidationMessages(EndPoint.PAS_VALIDATION_MESSAGES).toPromise();
    if (validationResp) {

      if (Object.hasOwn(validationResp, this.siteName)) {
        this.validationMessages = validationResp[this.siteName];
        this.errorMessages.duplicateDependentErrorMsg = this.validationMessages != null && this.validationMessages.hasOwnProperty('duplicateDependentsErrorMsg') ? this.validationMessages.duplicateDependentsErrorMsg : '';
      }
    }
  }
}
