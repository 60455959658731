<app-loader></app-loader>
<section class="mb-5">
  <div class="container">
    <h2 class="mt-4 mb-3 box-label">Manage Branches</h2>

    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <app-clubs-dropdown [isParentComponent]="true" (clubChanged)="onClubChanged($event)"  />
        </div>
      </div>
      <div class="col-md-4">
        <button class="btn btn-primary" type="button" (click)="openAddModal()">
          Add a Branch
        </button>
      </div>
    </div>
    <app-branches-table [tableColumns]="tableColumns" (openEditModal)="openEditModal($event)"
      (openDeactivateModal)="openUpdateStatusModal($event)" />

    <app-branch-modal [openModalEvent]="openBranchModalEvent" (onBranchSuccess)="onBranchSuccess($event)" />

    <app-confirm-modal [confirmModalEvent]="confirmModalEvent" />

    <app-deactivate-confirm-modal [updateStatusModalEvent]="updateStatusModalEvent"
      (updateConfirmEvent)="onUpdateConfirm($event)" />
  </div>
</section>