import { environment } from 'src/environments/environment';

export class UserProfile {
  // public static auth = JSON.parse(sessionStorage.getItem('auth') || '');
  private static auth = {
    accessToken: 'Bearer ' + localStorage.getItem('accessToken'),
    user: this.getAuthData().name ?? '',
    email: this.getAuthData().username ?? '',
  };

  static getAuthData() {
    const account = localStorage.getItem('entraIdAccount');

    if (!account) {
      return {};
    }
    const json = JSON.parse(account)
    return json;
  }
}
