/// <reference path="app.model.ts" />
/// <reference path="app.model.ts" />
/// <reference path="../../../../src/app/core/role/setroletext.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators, FormControl, } from '@angular/forms';
import { EndPoint } from '../../../../src/app/core/services/endpoint';
import { AddPromoCode, DeletePromoCode } from './app.model';
import { RoleTextService } from '../../../../src/app/core/role/setroletext';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';

@Injectable({
  providedIn: 'root',
})
export class PromoCodeService {

  httpHeaders = {};
  loggedUser: any = {};
  userName: string = '';
  userRoleDetails?: UserRoleDetails;

  constructor(
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    private roleTextService: RoleTextService,
    private store: Store,
  ){
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.httpHeaders = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8'
          }),
        };
      }
    })
  }

  // Add PromoCode: FormArray;
  AddPromoCodeForm() {
    return this._formBuilder.group({
      ContactProducerPromoCodes: this._formBuilder.array([
        this.CreateProducerPromoCodes(),
      ]),
    });
  }

  CreateProducerPromoCodes() {
    return new FormGroup({
      promoCodeGuid: new FormControl('', Validators.maxLength(64)),
      autoClubGuid: new FormControl('0',
        Validators.compose([Validators.required])
      ),
      promoCode: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9\\s-]+$')])),
      promoCodeDescription: new FormControl('', Validators.pattern('^[a-zA-Z0-9\\s-]+$')),
      discountRate: new FormControl('', Validators.compose([Validators.required, Validators.max(99), Validators.pattern(/^\d+(\.\d+)?$/)])),
      effectiveDate: new FormControl(null, Validators.compose([Validators.required])),
      expirationDate: new FormControl(null, Validators.compose([Validators.required])),
      isActive: new FormControl(null),
    });
  }

  getOctaAgencyPortalApiServiceList(searchPromoCode: any) {
    // let contactID;
    // if (UserRoleDetails.AgencyContactId > 0)
    //   contactID = UserRoleDetails.AgencyContactId;
    // else contactID = UserRoleDetails.AgentContactId;
    // ;
    return this.http.post<any>(
      EndPoint.LTX_ENDPOINT + EndPoint.PromoCodeSearch,
      searchPromoCode,
      this.httpHeaders,
    );
  }

  getSiteCoreAgencyTotalList(gridname: any) {
    return this.http.get(
      EndPoint.API_ENDPOINT + '/PASDataService/DataTable/?gridname=' + gridname,
    );
  }

  savePromoCodeRequest(addPromoCode: AddPromoCode, id: any, loggedUser: any,): Observable<any> {
    let ngbEffectiveDate: any = addPromoCode.ContactProducerPromoCodes[0].effectiveDate;

    let ngbExpirationDate: any = addPromoCode.ContactProducerPromoCodes[0].expirationDate;

    addPromoCode.ContactProducerPromoCodes[0].autoClubGuid = id;

    if (typeof ngbEffectiveDate !== 'string') {
      addPromoCode.ContactProducerPromoCodes[0].effectiveDate = ngbEffectiveDate['year'].toString() + '-' + (ngbEffectiveDate['month'] < 10 ? '0' + ngbEffectiveDate['month'] : ngbEffectiveDate['month']).toString() + '-' + (ngbEffectiveDate['day'] < 10 ? '0' + ngbEffectiveDate['day'] : ngbEffectiveDate['day']).toString();
    }

    if (typeof ngbExpirationDate !== 'string') {
      addPromoCode.ContactProducerPromoCodes[0].expirationDate = ngbExpirationDate['year'].toString() + '-' + (ngbExpirationDate['month'] < 10 ? '0' + ngbExpirationDate['month'] : ngbExpirationDate['month']).toString() + '-' + (ngbExpirationDate['day'] < 10 ? '0' + ngbExpirationDate['day'] : ngbExpirationDate['day']).toString();
    }

    if (addPromoCode.ContactProducerPromoCodes[0].autoClubGuid == null || addPromoCode.ContactProducerPromoCodes[0].autoClubGuid == undefined) {
      addPromoCode.ContactProducerPromoCodes[0].modifiedBy = loggedUser;
    }
    if (addPromoCode.ContactProducerPromoCodes[0].autoClubGuid != null || addPromoCode.ContactProducerPromoCodes[0].autoClubGuid != undefined) {
      addPromoCode.ContactProducerPromoCodes[0].modifiedBy = loggedUser;
    }

    if (addPromoCode.ContactProducerPromoCodes[0].discountRate > "") {
      let discountRate = (Number(addPromoCode.ContactProducerPromoCodes[0].discountRate) / 100).toString();
      addPromoCode.ContactProducerPromoCodes[0].discountRate = discountRate;
    }
    var cleandData = removeNullValues(addPromoCode.ContactProducerPromoCodes[0]);

    return this.http.post<any>(EndPoint.PAS_ADDPROMO, removeNullValues(cleandData), { headers: this.httpHeaders });
  }

  deletePromoCodeRequest(deletePromoCode: DeletePromoCode,) : Observable<any> {
    return this.http.delete<any>(
      EndPoint.LTX_ENDPOINT +
      'api/pas/promocode/deactive',
      {
        headers: this.httpHeaders,
        body: deletePromoCode
      },
    );
  }

  getAutoClub(): Observable<any> {
    return this.http.get<any>(EndPoint.LTX_ENDPOINT + "api/pas/configuration/autoclubs", {
      headers: this.httpHeaders,
    });
  }
}

function removeNullValues<T extends object>(obj: T): Partial<T> {
  return Object.keys(obj).reduce((acc, key) => {
    const typedKey = key as keyof T;
    if (obj[typedKey] != null && obj[typedKey] != '' && obj[typedKey] != undefined) { // Check for both null and undefined
      acc[typedKey] = obj[typedKey];
    }
    return acc;
  }, {} as Partial<T>);
}


