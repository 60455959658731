<app-loader></app-loader>
<section class="mb-5" *ngIf="dtOptions.length > 0">
  <div class="container">
    <div class="form-row mt-4 mb-4">
      <div class="col-md-4 col-lg-7">
        <h2 class="mb-3 box-label">Promo Codes</h2>
      </div>
    </div>

    <form #formRef="ngForm">
      <div class="form-row mt-4">
        <ng-container>
          <div class="form-group col-md-3" *ngFor="let filter of filterArray; let in = index">
            <ng-container *ngIf=" filterArray[in].FieldType.toLowerCase() == 'textbox' ">
              <input type="text" [(ngModel)]="filterArray[in].Value" name="name{{ in }}" class="form-control"
                #name="ngModel" required placeholder="{{ filterArray[in].Description }}" [ngClass]="{
                  'is-invalid':
                    isSearchFormError && !filterArray[in].Value
                }" />
              <span class="invalid-feedback" *ngIf="isSearchFormError && !filterArray[in].Value">Please enter
                a Promo code</span>
            </ng-container>
            <ng-container
              *ngIf=" filterArray[in].FieldType.toLowerCase() == 'calendar' && filterArray[in].KeyText.toLowerCase() == 'effectivedate' ? true : false ">
              <div class="input-group">
                <input class="form-control" placeholder="{{ filterArray[in].Description }}" name="name{{ in }}"
                  [(ngModel)]="filterArray[in].Value" ngbDatepicker #effectiveDate="ngbDatepicker" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="effectiveDate.toggle()"
                    type="button"></button>
                </div>
              </div>
            </ng-container>

            <ng-container
              *ngIf=" filterArray[in].FieldType.toLowerCase() == 'calendar' && filterArray[in].KeyText.toLowerCase() == 'expirationdate' ? true : false ">
              <div class="input-group">
                <input class="form-control" placeholder="{{ filterArray[in].Description }}" name="name{{ in }}"
                  [(ngModel)]="filterArray[in].Value" ngbDatepicker #expirationDate="ngbDatepicker" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" type="button"
                    (click)="expirationDate.toggle()"></button>
                </div>
              </div>
            </ng-container>

            <ng-container
              *ngIf="filterArray[in].FieldType.toLowerCase() == 'select' && filterArray[in].KeyText.toLowerCase() == 'clubname' ? true : false ">
              <div class="input-group">
                <select id="inputSearchClubs" name="name{{ in }}" class="form-control"
                  [(ngModel)]="filterArray[in].Value">
                  <option *ngIf="this.listClubs.length > 1" value=""> -- {{ filterArray[in].Description }} -- </option>
                  <option *ngFor="let item of listClubs" [value]="item.autoClubGuid">
                    {{ item.link_Url?.title }}
                  </option>
                </select>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <div class="form-group col-md-12 mt-1 text-center btn-footer">
          <button class="btn btn-primary btn-full-sm" id="apply" type="submit"
            (click)="displayToConsole($event, dtOptions[0], 0, 'search')">
            Search
          </button>
          <button class="btn btn-secondary btn-full-sm" id="clear" type="submit"
            (click)="displayToConsole($event, dtOptions[0], 0, 'allClear')">
            Clear
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-1 text-center btn-footer">
          <div *ngIf="noSearchValueSelectedError">
            <p class="alert alert-danger mt-3">
              <strong>Please select at least one field in the promocodes to see the results.</strong>
            </p>
          </div>
        </div>
      </div>
    </form>

    <!-- Add Promo Section -->
    <h2 class="mt-3 mb-3 box-label">Add Promo Code</h2>
    <form [formGroup]="addPromoCodeForm">
      <div class="card bg-light" formArrayName="ContactProducerPromoCodes">
        <div class="card-body" *ngFor="
            let item of contactProducerPromoCodesForm.controls;
            let i = index
          ">

          <div class="form-row" [formGroupName]="i">
            <div class="form-group col-md-12 col-lg-4">
              <input type="text" class="form-control" id="PromoCode" placeholder="Promo Code Number"
                formControlName="promoCode" [ngClass]="{
                  'is-invalid': submitted && contactProducerPromoCodesForm.controls[0].get('promoCode').errors
                }" autocomplete="off" />
              <div *ngIf="submitted && contactProducerPromoCodesForm.controls[0].get('promoCode').errors"
                class="invalid-feedback">
                <div *ngIf="contactProducerPromoCodesForm.controls[0].get('promoCode').errors?.required">
                  Please enter a Promo code Number
                </div>
                <div *ngIf="contactProducerPromoCodesForm.controls[0].get('promoCode').errors?.maxlength">
                  Promo Code cannot be more than 20 characters.
                </div>
                <div *ngIf="contactProducerPromoCodesForm.controls[0].get('promoCode').errors?.pattern">
                  Promo Code cannot contain special characters.
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-4">
              <input type="text" class="form-control" id="PromoCodeDescription" placeholder="Promo Code Description"
                formControlName="promoCodeDescription" [ngClass]="{
                  'is-invalid':
                    submitted && contactProducerPromoCodesForm.controls[0].get('promoCodeDescription').errors
                }" value="" />
              <div *ngIf="submitted && contactProducerPromoCodesForm.controls[0].get('promoCodeDescription').errors"
                class="invalid-feedback">
                <div *ngIf="contactProducerPromoCodesForm.controls[0].get('promoCodeDescription').errors.required"
                  autocomplete="off">
                  Promo Code Description is required
                </div>
                <div *ngIf="contactProducerPromoCodesForm.controls[0].get('promoCodeDescription').errors?.pattern">
                  Promo Code Description cannot contain special characters.
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-4">
              <select id="inputClubs" [ngClass]="{
                  'is-invalid':
                    submitted &&
                    (selectedClub == undefined || selectedClub == 0)
                }" class="form-control" [value]="selectedClub" (change)="selectionChange($event)">
                <option *ngIf="this.listClubs.length > 1" value="" disabled="true" [selected]="true">
                  Select Club
                </option>
                <option selected="" *ngFor="let item of listClubs" [value]="item.autoClubGuid">
                  {{ item.link_Url?.title }}
                </option>
              </select>
              <div class="col-12 text-danger p-0" *ngIf="
                  submitted && (selectedClub == undefined || selectedClub == 0)
                ">
                <small>Please select club</small>
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-4">
              <input type="text" class="form-control" id="DiscountRate" placeholder="Discount Rate"
                formControlName="discountRate" [ngClass]="{
                  'is-invalid': submitted  && contactProducerPromoCodesForm.controls[0].get('discountRate').errors
                }" value="" autocomplete="off" />
              <div *ngIf="submitted && contactProducerPromoCodesForm.controls[0].get('discountRate').errors"
                class="invalid-feedback">
                <div *ngIf="contactProducerPromoCodesForm.controls[0].get('discountRate').errors.required">
                  Please enter a Discount for the Promo code
                </div>
                <div *ngIf="contactProducerPromoCodesForm.controls[0].get('discountRate').errors?.max">
                  Discount Rate cannot be more than 99.
                </div>
                <div *ngIf="contactProducerPromoCodesForm.controls[0].get('discountRate').errors?.pattern">
                  Discount Rate must have numbers.
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-4">
              <div class="input-group">
                <input class="form-control" id="EffectiveDate" placeholder="Effective Date"
                  formControlName="effectiveDate" [ngClass]="{
                    'is-invalid':
                      submitted && contactProducerPromoCodesForm.controls[0].get('effectiveDate').errors
                  }" ngbDatepicker #effectiveDate="ngbDatepicker" autocomplete="off"
                  [minDate]="isTMMASuperAdmin ? null : minValidDate" [maxDate]="maxEffDateAdd()" />

                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" type="button"
                    (click)="effectiveDate.toggle()"></button>
                </div>

                <div *ngIf="submitted && contactProducerPromoCodesForm.controls[0].get('effectiveDate').errors"
                  class="invalid-feedback">
                  <div *ngIf="contactProducerPromoCodesForm.controls[0].get('effectiveDate').errors.required">
                    Please select the Effective date
                  </div>
                  <div *ngIf="contactProducerPromoCodesForm.controls[0].get('effectiveDate').errors.ngbDate">
                    Please enter a valid date
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-4">
              <div class="input-group">
                <input class="form-control" id="ExpirationDate" placeholder="Expiration Date"
                  formControlName="expirationDate" [ngClass]="{
                    'is-invalid ':
                      (submitted || expirationDateErr) &&
                      contactProducerPromoCodesForm.controls[0].get('expirationDate').errors
                  }" ngbDatepicker #expirationDate="ngbDatepicker" autocomplete="off" [minDate]="minExpDateAdd()" />
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" type="button"
                    (click)="expirationDate.toggle()"></button>
                </div>
                <div *ngIf="submitted && contactProducerPromoCodesForm.controls[0].get('expirationDate').errors"
                  class="invalid-feedback">
                  <div *ngIf="contactProducerPromoCodesForm.controls[0].get('expirationDate').errors.required">
                    Please select the Expiration Date the Promo code needs to be
                    valid to
                  </div>
                  <div *ngIf="contactProducerPromoCodesForm.controls[0].get('expirationDate').errors.ngbDate">
                    Please enter a valid date
                  </div>
                </div>
                <!-- <div *ngIf="expirationDateErr" class="text-danger p-0 col-12">
                  <small>Please enter a valid date</small>
                </div> -->
              </div>
            </div>
            <div class="form-group col-md-12 col-lg-4">
              <button class="btn btn-primary pull-right" (click)="addPromoCodeFormSubmit('add')">
                <span [ngClass]="{
                    'spinner-border spinner-border-sm': isAddPromoCodeLoader
                  }" role="status" aria-hidden="false"></span>
                Save
              </button>
              <button class="btn btn-secondary pull-right" (click)="addPromoCodeFormReset()">
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- Expired Promo Export Section -->
    <div class="row form-group text-right col-md-12 mt-4 btn-footer" style="margin-bottom: -11px; padding: 0px">
      <div class="col-md-8 pull-right" style="padding: 0px; margin-top: 9px; left: 132px">
        Promocodes Export:
      </div>
      <div class="col-md-3 pull-right" style="padding: 0px; margin-top: 8px; left: 10px; top: -3px">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="promocodeExportType">
          <mat-radio-button value="expired" [matTooltip]="exportExpiryPromoMsg" style="margin-top: 4px; margin-right: 11px">
            <span class="wrap-mat-radio-label">Expired/Deleted</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-md-1 pull-right" style="padding: 0px">
        <button class="btn btn-primary" id="apply" type="submit" (click)="exportPromocodesToExpire()">
          <i class="fas fa-file-export"></i>
        </button>
      </div>
    </div>
    <div class="row mt-3" *ngIf="exportPromoError" style="margin-bottom: -21px">
      <p class="offset-md-3 col-md-6 alert alert-danger mt-3 text-center">
        <strong>{{ exportPromoErrorMsg }}</strong>
      </p>
    </div>

    <!-- Promo Grid Section -->
    <ng-container>
      <div *ngIf="dtOptions.length == 0 || isLoaderPromoCode == true" class="text-center mt-4" style="min-height: 100%">
        <br />
        <br />
        <span [ngClass]="{ 'spinner-border text-primary': isLoaderPromoCode }" role="status" aria-hidden="false"></span>
      </div>
    </ng-container>
    <div class="mt-4" [style]="isLoaderPromoCode == true ? 'height:0px;width:100%;overflow:hidden':''">
      <table datatable [dtOptions]="dtOptions[0]" attr.name="{{ dtOptions[0].gridName }} "
        class="table table-striped table-bordered dataTable no-footer dtr-inline w-100" style="width: 100%">
        <thead>
          <tr>
            <th *ngFor="let column of dtOptions[0].columns">{{ column.title }}</th>
          </tr>
        </thead>
      </table>
    </div>
  </div>
</section>

<ng-template #contentmodalEditPromoCode let-modal let-c="close" let-d="dismiss">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">{{ modalData.Heading }}</h4>
    <button type="button" class="close" data-dismiss="modal" (click)="modal.dismiss('Cross click')">
      &times;
    </button>
  </div>
  <!-- main app container -->
  <form [formGroup]="editPromoCodeForm">
    <div class="card bg-light" formArrayName="ContactProducerPromoCodes" style="min-height: 200px">
      <div class="card-body" *ngFor=" let item of promoCodesArray.controls; let i = index ">
        <div class="form-row" [formGroupName]="i">
          <div class="form-group col-md-12 col-lg-4">
            <input type="text" class="form-control" id="PromoCode" placeholder="Promo Code Number"
              formControlName="promoCode" [ngClass]="{ 'is-invalid': editSubmitted && item.get('promoCode')?.errors }"
              value="" autocomplete="off" />

            <div *ngIf="editSubmitted && item.get('promoCode')?.errors" class="invalid-feedback">

              <div *ngIf="item.get('promoCode')?.errors?.['required']"> Promo Code is required </div>
              <div *ngIf="item.get('promoCode')?.errors?.maxlength"> Promo Code cannot be more than 20 characters.</div>
            </div>
          </div>
          <div class="form-group col-md-12 col-lg-4">
            <input type="text" class="form-control" id="PromoCodeDescription" placeholder="Promo Code Description"
              formControlName="promoCodeDescription"
              [ngClass]="{ 'is-invalid': editSubmitted && item.get('promoCodeDescription')?.errors }" value=""
              autocomplete="off" />
            <div *ngIf="editSubmitted && item.get('promoCodeDescription')?.errors" class="invalid-feedback">
              <div *ngIf="item.get('promoCodeDescription')?.errors?.required">
                Promo Code Description is required
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 col-lg-4">
            <select id="inputClubs" [ngClass]="{
                          'is-invalid':
                          editSubmitted && (currentClubId == undefined || currentClubId == '')
                        }" class="form-control" [value]="currentClubId" (change)="clubChange($event)">
              <option selected="" *ngFor="let item of listClubs" [value]="item.autoClubGuid">
                {{ item.link_Url?.title }}
              </option>
            </select>
            <div class="col-12 text-danger p-0" *ngIf="
                      editSubmitted && (currentClubId == undefined || currentClubId == '')
                        ">
              <small>Please select club</small>
            </div>
          </div>
          <div class="form-group col-md-12 col-lg-4">
            <input type="text" class="form-control" id="DiscountRate" placeholder="Discount Rate"
              formControlName="discountRate"
              [ngClass]="{ 'is-invalid': editSubmitted && item.get('discountRate')?.errors }" value=""
              autocomplete="off" />
            <div *ngIf="editSubmitted &&  item.get('discountRate')?.errors" class="invalid-feedback">
              <div *ngIf="item.get('discountRate')?.errors?.['required']"> Discount Rate is required </div>
              <div *ngIf="item.get('discountRate')?.errors?.max"> Discount Rate cannot be more than 99. </div>
            </div>
          </div>
          <div class="form-group col-md-12 col-lg-4">
            <div class="input-group">
              <input type="text" class="form-control" id="EffectiveDate" placeholder="Effective Date"
                formControlName="effectiveDate"
                [ngClass]="{ 'is-invalid': editSubmitted && item.get('effectiveDate')?.errors }" value="" ngbDatepicker
                #effectiveDate="ngbDatepicker" autocomplete="off" [minDate]="isTMMASuperAdmin ? null : minValidDateEdit"
                [maxDate]="maxEffDateEdit()" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="effectiveDate.toggle()"
                  type="button"></button>
              </div>

              <div *ngIf="editSubmitted && item.get('effectiveDate')?.errors" class="invalid-feedback">
                <div *ngIf="item.get('effectiveDate')?.errors?.required">
                  Effective Date is required
                </div>
                <div *ngIf="item.get('effectiveDate').errors.ngbDate">
                  Please enter a valid date
                </div>
              </div>
            </div>
          </div>
          <div class="form-group col-md-12 col-lg-4">
            <div class="input-group">
              <input type="text" class="form-control" id="ExpirationDate" placeholder="Expiration Date"
                formControlName="expirationDate"
                [ngClass]="{ 'is-invalid': editSubmitted && item.get('expirationDate')?.errors }" value="" ngbDatepicker
                #expirationDate="ngbDatepicker" autocomplete="off" [minDate]="minExpDateEdit()" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" type="button"
                  (click)="expirationDate.toggle()"></button>
              </div>
              <div *ngIf="editSubmitted && item.get('expirationDate')?.errors" class="invalid-feedback">
                <div *ngIf="item.get('expirationDate')?.errors?.required">
                  Expiration Date is required
                </div>
                <div *ngIf="item.get('expirationDate').errors.ngbDate">
                  Please enter a valid date
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal footer -->
    <div class="modal-footer" style="justify-content: flex-end">
      <div class="form-group col-md-12 col-lg-4 text-right">
        <button class="btn btn-primary" (click)="addPromoCodeFormSubmit('edit')">
          <span [ngClass]="{
              'spinner-border spinner-border-sm': isEditPromoCodeLoader
            }" role="status" aria-hidden="false"></span>
          Save
        </button>
      </div>
    </div>
  </form>
</ng-template>

<!-- Confirmation Box POP UP --->
<ng-template #warningBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="d('Cross click')">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Error }} ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModal()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>

<!-- Delete Confirmation Box POP UP --->
<ng-template #deleteConfirmationBox let-modal let-c="close" let-d="dismiss" data-backdrop="static"
  data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="d('Cross click')">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>Are You Sure want to delete this Promo Code ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModal()">
      No
    </button>
    <button type="submit" class="btn btn-primary pull-right mr-2"
      (click)="onClickDeletePromoCode(modalData.ItemRowData)">
      <span [ngClass]="{ 'spinner-border spinner-border-sm': isDeleteLoader }" role="status" aria-hidden="false"></span>

      Yes
    </button>
  </div>
</ng-template>

<!-- Confirmation Box POP UP --->
<ng-template #confirmationBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-thumbs-up" aria-hidden="true"></i> Confirmation
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="d('Cross click')">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Msg }}</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModal()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>