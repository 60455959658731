import { Component, OnInit, ViewChild, TemplateRef, ElementRef, HostListener, Input, } from '@angular/core';
import { FormGroup, FormBuilder, Validators, } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbDateParserFormatter, } from '@ng-bootstrap/ng-bootstrap';
import { DataBusService } from '../services/databus.service';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { PolicyDetailService } from '../app.services';
import { EndorsementGenericService } from '../shared/services/endorsement-generic.service';
import { MessageBusService } from '../services/messagebus.service';

import { NgbDateFRParserFormatter } from './ngb-date-fr-parser-formatter';
import { SitecoreItemService } from '../services/sitecore-item.service';
import { SiteNameEnum } from 'src/app/core/enum/sitename-enum';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum, SubComponentNameEnum, } from 'projects/policyDetails/src/app/enum/page-name-enum';
import { Utility } from '../shared/utility';
import moment from 'moment';
import { DateService } from 'src/app/core/services/date-updated.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { isPolicyDataPresent, selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { selectCurrentEndorsement, isEndorsementDataPresent } from 'src/app/core/store/endorsement/endorsement.selectors';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';
import { MapperService } from 'src/app/core/services/mapper.services';
import { EndPoint } from 'src/app/core/services/endpoint';

@Component({
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
  ],
  selector: 'policyDetails-policy-details-trip',
  templateUrl: './policy-details-trip.component.html',
  styleUrls: ['./policy-details-trip.component.css'],
})
export class PolicyDetailsTripComponent implements OnInit {
  showHide: boolean;
  Arra: any;
  agentRole = '';
  submitted = false;
  modalRef!: NgbModalRef;
  prpolicytype!: boolean;
  updatedTravel: any;
  travelerForm!: FormGroup;
  public policyTripDetail: any;
  public Duration: any;
  public countrycod: any;
  policytxnid = '';
  siteName = '';
  isLoader: boolean = false;
  public isSalesAgentPresent = false;
  msg: string = 'Trip details updated successfully';
  isMessageVisible: boolean = false;
  countryList: any[] = [];
  dropdownList: any[] = [];
  itemList: any[] = [];
  settings = {};
  selectedItems: any[] = [];
  isEditable = false;
  errorMsg: any;
  isMatchedDisabled: boolean = false;
  validationMessages: any;
  isDateValidatedErr: boolean = false;
  isDateValidatedErr_return: boolean = false;
  errorMsgDate!: string;
  errorMsgDate_return!: string;
  selectedCountryIsDomestic: boolean = false;
  isTravelstrartdtElapsed: boolean = false;
  minvalidDate: any;
  maxValidDate: any;
  maxFromDate : any;
  public toggleMultiple: boolean = false;
  dateTimeoutObj: any = { from: null, to: null };
  isAnnualMultiTrip: boolean = false;
  getSelectedContList: any = [];
  public covidpopshow: boolean = false;
  public PandemicAcknowledgementLabel: string = '';
  public dNTCountryListByError: any;
  public dNTCountryList: any;
  public dNTTravelMessage: any;
  public currentPolicyInStore: any;
  public IsPolicyInStore: boolean = false;
  public currentENInStore: any;
  public IsENInStore: boolean = false;
  public isUpdateENCalled: boolean = false;
  userRoleDetails?: UserRoleDetails

  @Input() isEditBtnRequired: boolean = true;
  @ViewChild('dialogContentTemplate', { static: true })
  dialogContent!: TemplateRef<ElementRef>;

  get f() {
    return this.travelerForm.controls;
  }

  constructor(
    public formBuilder: FormBuilder,
    private modalService: NgbModal,
    private endorsementGenericService: EndorsementGenericService,
    private dataBusService: DataBusService,
    public dataService: PolicyDetailService,
    private messageService: MessageBusService,
    private itemService: SitecoreItemService,
    private portalCodeService: PortalCodeService,
    private logService: LogService,
    private dateService: DateService,
    private store: Store,
    private storeService: StoreGenericService,
    private mapperService : MapperService
  ) {
    let current = new Date();
    current = new Date(this.dataService.utcToAestDate(current.toUTCString()));
    this.minvalidDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate(),
    };
    this.policytxnid = sessionStorage.getItem('movementGuid') || '';
    this.showHide = false;

  }

  async ngOnInit() {
    await this.getMasterData();
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
      }
    });

    //Policy Store
    this.store.select(selectCurrentPolicy).subscribe(pol => {
      this.currentPolicyInStore = pol;
      if (pol != null) {
        this.populatePolicyData(this.currentPolicyInStore);
      }
    });

    //Endorsement Store
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.isUpdateENCalled = present;
    });

    this.store.select(selectCurrentEndorsement).subscribe(en => {
      this.currentENInStore = en;
      if (en != null) {
        this.populatePolicyData(this.currentENInStore);
      }
    });


    this.travelerForm = this.formBuilder.group({
      CountryName: [[], Validators.required],
      TripFromDate: ['', Validators.required],
      TripToDate: ['', Validators.required],
    });

    this.settings = {
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      searchPlaceholderText: 'Search Fields',
      allowSearchFilter: true,
      text: 'Select Fields',
      enableSearchFilter: true,
      itemsShowLimit: 5,
    };
  }

  async fetchValidationMessages() {
    let validationResp = await this.dataService.fetchValidationMessages(EndPoint.PAS_VALIDATION_MESSAGES).toPromise();
    if(validationResp){
      if(Object.hasOwn(validationResp, this.siteName)){
        this.validationMessages = validationResp[this.siteName];
        this.dNTTravelMessage = this.validationMessages != null && this.validationMessages.hasOwnProperty('dNTTravelMessage') ? this.validationMessages.dNTTravelMessage : '';
      }
    }
  }

  setPostDateFormat(date: any): any {
    if (date == null || date === undefined || typeof date != 'object') {
      return;
    }
    let ngbDate = date;
    if (ngbDate) {
      let datevalue =
        ngbDate['year'].toString() +
        '/' +
        (ngbDate['month'].toString().length == 1
          ? '0' + ngbDate['month'].toString()
          : ngbDate['month'].toString()) +
        '/' +
        (ngbDate['day'].toString().length == 1
          ? '0' + ngbDate['day'].toString()
          : ngbDate['day'].toString());
      return datevalue;
    }
  }

  validatedateFormat(inputText: any) {
    var trigger = inputText;
    //regexp = new RegExp('/(\d{4})-(\d{2})-(\d{2})/'),
    if (trigger != undefined && typeof trigger == 'object') {
      return true;
    }
    let regexp = new RegExp(
      /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
    );
    let result = regexp.test(trigger);
    return result ? true : false;
  }

  setMaxDateForTodate() {
    let tripfromDate: any = new Date(
      this.setPostDateFormat(this.travelerForm.value.TripFromDate),
    );
    let maxDateObj = new Date(
      new Date(tripfromDate).setDate(new Date(tripfromDate).getDate() + 365),
    );
    this.maxValidDate = {
      year: maxDateObj.getFullYear(),
      month: maxDateObj.getMonth() + 1,
      day: maxDateObj.getDate(),
    };
    let a = this.maxValidDate;
  }

  onInputFromDate() {
    this.dateTimeoutObj.from = null;
    if (this.validatedateFormat(this.travelerForm.value.TripFromDate))
      this.dateTimeoutObj.from = setTimeout(() => {
        this.dateValidate();
        this.validateTravelToDate();
      }, 2000);
  }
  
  onInputToDate() {
    this.dateTimeoutObj.to = null;
    if (this.validatedateFormat(this.travelerForm.value.TripToDate))
      this.dateTimeoutObj.to = setTimeout(() => {
        this.validateTravelToDate();
      }, 2000);
  }

  // createActivityLogItem(msg: any) {
  //   this.dataService
  //     .createActivityLogItem('Tripdetailupd', msg)
  //     .subscribe(() => { });
  // }

  enableDepartureDateForAdmin() {
    return Utility.isTrue(this.userRoleDetails?.IsUW);
  }

  @HostListener('document:keydown.enter', ['$event']) onKeydownHandler(
    event: KeyboardEvent | any,
  ) {
    if (event.target['value'] == undefined || event.target['value'] == '') {
      return;
    }
    let obj = this.countryList.find((item) => {
      let ctvv = event.target['value'].toLowerCase();
      return item.countryName.toLowerCase().includes(ctvv);
    });

    if (obj !== undefined) {
      let dd = false;
      this.selectedItems.forEach((data) => {
        if (data.countryName == obj.countryName) {
          dd = true;
        }
      });
      if (dd) {
      } else {
        // set value for null or blank whicherver suits
        this.selectedItems.push(obj);
        event.target['value'] = '';
      }
      this.isCountryMatched(this.selectedItems);
    }
  }

  closeModal() {
    this.modalRef.close();
    this.isMessageVisible = true;
    setTimeout(() => {
      this.isMessageVisible = false;
    }, 9000);
    this.selectedItems = [];
  }

  closeModal1() {
    this.modalRef.close();
    this.selectedItems = [];
  }

  clearSearch() { }

  //Function to format NotAllowedContriesMessage
  formatNotAllowedCountriesMessage() {
    let notAllowedContryList = this.notAllowedTravelDestinationList();
    let countries = '';
    for (let item of notAllowedContryList) {
      countries += countries == '' ? item.countryName : ', ' + item.countryName;
    }

    let param = {
      DNTCountryList: this.dNTCountryList,
      DNTCountryListByError: this.dNTCountryListByError,
      DNTCountryErrorMessage: this.dNTTravelMessage,
    };
    
    this.validationMessages = this.dataService.getValidationMessage( this.siteName, param, );
    return this.validationMessages.tripDetailValidationMessages.doNotTravelDestinationMsg.replace( '{destinations}', countries, );
  }

  //LTX 
  async populatePolicyData(data: any) {
    var countries = await this.storeService.fetchCountriesDataFromStore();
    this.policyTripDetail = data;
    this.policytxnid = data.movementGuid;
    this.siteName = data.autoClub;
    this.isAnnualMultiTrip = (data.isSingleTrip === false);
    this.Duration = this.dateService.daysDifference(data.fromDate, data.toDate);

    await this.fetchValidationMessages();

    let dntCountries = countries.filter((e: any) => e.isDNTCountry == true);
    let cautionCountries  = countries.filter((e: any) => e.isCautionCountry  == true);
    this.dNTCountryListByError = dntCountries != null && dntCountries.length > 0 ? dntCountries.map(function(item: { [x: string]: any; }) {return item['countryName'].toUpperCase(); }) : [];
    this.dNTCountryList = cautionCountries != null && cautionCountries.length > 0 ? cautionCountries.map(function(item: { [x: string]: any; }) {return item['countryName'].toUpperCase(); }) : [];

    let param: any = {
      DNTCountryList: this.dNTCountryList,
      DNTCountryListByError: this.dNTCountryListByError,
      DNTCountryErrorMessage: this.dNTTravelMessage,
    };

    this.validationMessages = this.dataService.getValidationMessage( this.siteName, param );

    if (data.movements) {
      let policyMovement = data.movements;
      let prPolicy = policyMovement.find((ele: any) => ele.TxnTypeCode == 'RW' && ele.TxnStatusCode === 'PR' && ele.Status !== 'Completed',);
      this.prpolicytype = prPolicy ? false : true;
    }

    if (data && data.productType == 'Domestic') {
      this.selectedCountryIsDomestic = true;
    }

    else {
      this.selectedCountryIsDomestic = false;
    }

    let destinationList: any[] = [];
    
    if (countries && countries.length > 0) {
      var destinations = data.destinations;
      if (destinations.length > 0) {
        this.countrycod = destinations.forEach((dest: any) => {
          var country = null;
          if(dest.countryName != null){
            country = countries.find((country: any) => country.countryCode == dest.countryCode && country.countryName == dest.countryName);
          }
          else{
            country = countries.find((country: any) => country.countryCode == dest.countryCode);
          }
          
          destinationList.push(country.countryName);
        });
      }
    }

    this.countrycod = destinationList.join(",");
  }

  async getMasterData() {
    if (!sessionStorage.getItem('mCountry')) {
      if(this.userRoleDetails?.AgentContactId != null){
        let response = await this.dataService.getCountryLTX().toPromise().catch((err) => {
          this.logService.createErrorLog({}, err, 'GetCountryList', PageNameEnum.Dashboard, LogLevel.Error,);
        });
        if (response) {
          sessionStorage.setItem('mCountry', JSON.stringify(response));
        }
      }    
    }
  }

  async openModal() {
    this.isLoader = true;
    this.errorMsgDate = '';
    this.errorMsgDate_return = '';
    this.getCountryList();

    this.modalRef = this.modalService.open(this.dialogContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });

    let current = new Date();
    let currentDate: any = new Date(this.dataService.utcToAestDate(current.toUTCString()),);
    let ngbDateStruct: any;
    let ngbDateStruct2;
    let year;
    let day;
    let month;

    if (this.policyTripDetail.fromDate) {
      let dateEff = this.policyTripDetail.fromDate.split('T',)[0].split('/');
      day = dateEff[0];
      month = dateEff[1];
      year = dateEff[2];
      ngbDateStruct = {
        month: Number(month),
        day: Number(day),
        year: Number(year),
      };
    }

    if (this.policyTripDetail.toDate) {
      let dateEff = this.policyTripDetail.toDate.split('T',)[0].split('/');
      day = dateEff[0];
      month = dateEff[1];
      year = dateEff[2];
      ngbDateStruct2 = {
        month: Number(month),
        day: Number(day),
        year: Number(year),
      };
    }

    this.travelerForm.setValue({ CountryName: this.selectedItems, TripFromDate: ngbDateStruct, TripToDate: ngbDateStruct2, });

    let tripfromDate: any = new Date(this.setPostDateFormat(ngbDateStruct));
    let tripfromDate_ = tripfromDate.toDateString();
    if (!this.userRoleDetails?.IsUW) {
      if (Date.parse(tripfromDate_) < Date.parse(currentDate) && !this.enableDepartureDateForAdmin()) {
        this.isTravelstrartdtElapsed = true;
      }
    } else {
      let purchaseDate = sessionStorage.getItem('PurchaseDate') || '';
      let purchaseDateAusFormat = new Date(
        this.dataService.utcToAestDate(purchaseDate),
      );
      this.minvalidDate = {
        year: purchaseDateAusFormat.getFullYear(),
        month: purchaseDateAusFormat.getMonth() + 1,
        day: purchaseDateAusFormat.getDate(),
      };
      
      let maxValidDate = this.addMonthsToDate(18, purchaseDate);
      let newMaxDate = {
        year: Number(maxValidDate.format('YYYY')),
        month: Number(maxValidDate.format('M')),
        day: Number(maxValidDate.format('D')),
      }
      this.maxFromDate = newMaxDate;
    }
    try {
      this.setMaxDateForTodate();
    } catch (e) { }
  }

  async getCountryList() {
    let countries = await this.storeService.fetchCountriesDataFromStore();
    this.countryList = countries;
    if (this.countryList.length > 0) {
      let valId = 1;
      for (let val of this.countryList) {
        val['itemName'] = val.countryName;
        val['id'] = valId;
        valId = valId + 1;
      }
      let cntry = this.countrycod.split(',');
      if (cntry.length === 1) {
        let country = this.countryList.find((ele) => ele.countryName.toLowerCase() == cntry[0].trim().toLowerCase(),);
        if (country !== undefined) {
          this.onItemSelect(country);
          this.selectedItems.push(country);
        }
        this.travelerForm.controls['CountryName'].setValue(this.selectedItems);
      } 
      else if (cntry.length > 1) {
        for (let val of cntry) {
          let country = this.countryList.find((ele) => ele.countryName.toLowerCase() == val.trim().toLowerCase(),);
          if (country !== undefined) {
            this.onItemSelect(country);
            this.selectedItems.push(country);
          }
        }
        this.travelerForm.controls['CountryName'].setValue(this.selectedItems);
      }
    }
    this.isLoader = false;
  }

  onItemSelect(item: any) {
    this.isCountryMatched(item);
    this.errorMsg = '';
    this.isMatchedDisabled = false;
    this.isCountryMatched(this.selectedItems);
    this.getSelectedContList = [];
    //Setting selected countryList for (IRAN, CUBA and NORTH KOREA) error message
    this.selectedItems.forEach((contList) => {
      this.getSelectedContList.push(contList.countryName);
      this.dataService.setDestinationCountry(this.getSelectedContList);
    });

  }

  OnItemDeSelect(item: any) {
    if (item && item.countryName == undefined) {
      this.errorMsg = 'Please select country';
      this.isMatchedDisabled = true;
    } else {
      this.errorMsg = '';
      this.isMatchedDisabled = false;
    }
    if (this.selectedItems && this.selectedItems.length === 0) {
      this.errorMsg = 'Please select country';
      this.isMatchedDisabled = true;
    } else {
      this.errorMsg = '';
      this.isMatchedDisabled = false;
    }
    this.isCountryMatched(this.selectedItems);
    this.getSelectedContList = [];
    this.selectedItems.forEach((contList) => {
      this.getSelectedContList.push(contList.countryName);
      this.dataService.setDestinationCountry(this.getSelectedContList);
    });
  }

  onSelectAll(items: any) {
    this.isCountryMatched(items);
  }

  onDeSelectAll(items: any) {
    this.isCountryMatched(items);
    if (items && items.length == 0) {
      this.errorMsg = 'Please select country';
      this.isMatchedDisabled = true;
    } else {
      this.errorMsg = '';
      this.isMatchedDisabled = false;
    }
  }

  onGroupSelect(item: any) {
    this.isCountryMatched(this.selectedItems);
    this.errorMsg = '';
    this.isMatchedDisabled = false;
    this.isCountryMatched(this.selectedItems);
  }

  onGroupDeSelect(item: any) {
    this.isCountryMatched(this.selectedItems);
    this.errorMsg = '';
    this.isMatchedDisabled = false;
    this.isCountryMatched(this.selectedItems);
  }

  toggleMultipleCountry() {
    this.toggleMultiple = !this.toggleMultiple;
  }

  isCountryMatched(x: any) {
    if (x != null) {
      let countriesSelected = this.countryList.filter(x => this.selectedItems.map(y => y.id).includes(x.id));
      let isDomesticArray = countriesSelected.find((data: any) => data.isDomestic == true);
      let isInternationalArray = countriesSelected.find( (data: any) => data.isDomestic == false);
      if (isDomesticArray && isInternationalArray) {
        if ( this.policyTripDetail.isSingleTrip == false && this.siteName != 'W2C' ) {
          // new requirement to bypass this for clubs other than W2C and trip is annual multi
          console.log('only valid for this trip type and club');
        } 
        else {
          this.errorMsg = 'Cannot select Domestic and International Travel Destinations at the same time.';
          this.isMatchedDisabled = true;
        }
      }
      else if (isDomesticArray && this.siteName == 'W2C' && this.policyTripDetail && this.policyTripDetail.isSingleTrip == false) {
        this.errorMsg = 'We don’t offer cover for Domestic AMT for W2C.';
        this.isMatchedDisabled = true;
      }
      else {
        this.errorMsg = '';
        this.isMatchedDisabled = false;
      }
    }
  }

  dateValidate() {
    let ff = this.validatedateFormat(this.travelerForm.value.TripFromDate);

    if (this.isAnnualMultiTrip) {
      //check for leap year added along with year increase INC0280488
      let calculatedDate = new Date(
        this.setPostDateFormat(this.travelerForm.value.TripFromDate),
      );
      let isLeapYear = moment(
        this.travelerForm.value.TripFromDate.year.toString(),
      ).isLeapYear();
      if (isLeapYear) {
        let currentMonth = this.travelerForm.value.TripFromDate.month + 1;
        if (currentMonth > 2) {
          calculatedDate = new Date(
            calculatedDate.setDate(calculatedDate.getDate() + 364),
          );
        } else {
          calculatedDate = new Date(
            calculatedDate.setDate(calculatedDate.getDate() + 365),
          );
        }
      } else {
        calculatedDate = new Date(
          calculatedDate.setDate(calculatedDate.getDate() + 364),
        );
      }
      if (moment(calculatedDate.getFullYear().toString()).isLeapYear()) {
        let currentMonth = calculatedDate.getMonth() + 1;
        if (currentMonth > 2) {
          calculatedDate = new Date(
            calculatedDate.setDate(calculatedDate.getDate() + 1),
          );
        }
      }
      this.travelerForm.value.TripToDate.day = calculatedDate.getDate();
      this.travelerForm.value.TripToDate.month = calculatedDate.getMonth() + 1;
      this.travelerForm.value.TripToDate.year = calculatedDate.getFullYear();
      this.travelerForm.controls['TripToDate'].setValue(
        this.travelerForm.value.TripToDate,
      );
    }

    if (!ff) {
      this.errorMsgDate = 'Invalid Date format';
      this.isDateValidatedErr = true;
      return false;
    } else {
      this.errorMsgDate = '';
    }
    let currentDate: any = new Date();
    currentDate = new Date(
      this.dataService.utcToAestDate(currentDate.toUTCString()),
    ).toDateString();
    let tripfromDate: any = new Date(
      this.setPostDateFormat(this.travelerForm.value.TripFromDate),
    );
    let tripfromDate_ = tripfromDate.toDateString();
    let tripToDate: any = new Date(
      this.setPostDateFormat(this.travelerForm.value.TripToDate),
    );
    let tripToDate_ = tripToDate.toDateString();
    let tripfromDate548: any = new Date(
      new Date().setDate(new Date().getDate() + 548),
    );
    tripfromDate548 = new Date(
      this.dataService.utcToAestDate(tripfromDate548.toUTCString()),
    ).toDateString();
    this.setMaxDateForTodate();
    if (
      Date.parse(tripfromDate_) < Date.parse(currentDate) &&
      !this.userRoleDetails?.IsUW
    ) {
      this.errorMsgDate =
        'Leaving on date cannot be a date before the current date';
      this.isDateValidatedErr = true;
      return false;
    } else if (
      Date.parse(tripfromDate_) > Date.parse(tripfromDate548) 

    ) {
      this.errorMsgDate =
        'Leaving on date can not be more than 548 days from current date.';
      this.isDateValidatedErr = true;
    } else if (Date.parse(tripfromDate_) > Date.parse(tripToDate_)) {
      this.errorMsgDate_return =
        'Returning Date cannot be before leaving on date.';
      this.isDateValidatedErr_return = true;
    } else if (Date.parse(tripfromDate_) >= Date.parse(currentDate)) {
      this.errorMsgDate = '';
      this.errorMsgDate_return = '';
      this.isDateValidatedErr = false;
      return true;
    } else {
      this.errorMsgDate = '';
      this.isDateValidatedErr = false;
      return true;
    }
  }

  validateTravelToDate() {
    let ff = this.validatedateFormat(this.travelerForm.value.TripToDate);
    if (!ff) {
      this.errorMsgDate_return = 'Invalid Date format';
      this.isDateValidatedErr_return = true;
      return false;
    } else {
      this.errorMsgDate_return = '';
    }
    let tripfromDate: any = new Date(
      this.setPostDateFormat(this.travelerForm.value.TripFromDate),
    );
    let tripfromDate_ = tripfromDate.toDateString();
    let tripToDate: any = new Date(
      this.setPostDateFormat(this.travelerForm.value.TripToDate),
    );
    let tripToDate_ = tripToDate.toDateString();
    let fromDateYearAhead = new Date(
      new Date(tripfromDate).setDate(new Date(tripfromDate).getDate() + 365),
    ).toDateString();
    if (Date.parse(tripToDate_) > Date.parse(fromDateYearAhead)) {
      this.errorMsgDate_return =
        'Returning on date cannot be more than 365 days from leaving on date.';
      this.isDateValidatedErr_return = true;
      return false;
    } else if (Date.parse(tripfromDate_) > Date.parse(tripToDate_)) {
      this.errorMsgDate_return =
        'Returning Date cannot be before leaving on date.';
      this.isDateValidatedErr_return = true;
      return false;
    } else if (Date.parse(tripToDate_) === Date.parse(tripfromDate_)) {
      this.errorMsgDate_return = 'Leaving date and returning cannot be same';
      this.isDateValidatedErr_return = true;
      return false;
    } else {
      this.isDateValidatedErr_return = false;
      this.errorMsgDate_return = '';
      return true;
    }
  }

  //Function to check if user selected not allowed contries in destination list
  hasNotAllowedTravelDestination() {
    let notAllowedContryList = this.notAllowedTravelDestinationList();
    return (notAllowedContryList && notAllowedContryList.length > 0);
  }

  //Function to get not allowed contries in destination list
  notAllowedTravelDestinationList() {
    let countriesSelected = this.countryList.filter(x => this.selectedItems.map(y => y.id).includes(x.id));
    let notAllowedContryList = countriesSelected.filter(function (item) {
      return item.allowTravel == false;
    });
    return notAllowedContryList;
  }

  async updateTripDetails() {
    if (!this.hasNotAllowedTravelDestination()) {
      if (!this.isTravelstrartdtElapsed) if (!this.dateValidate()) return;
      if (!this.validateTravelToDate()) return;
      if (this.isDateValidatedErr === false && !this.isDateValidatedErr_return) {
        this.submitted = true;
        this.isLoader = true;
        if (this.travelerForm.invalid) {
          this.isLoader = false;
          return;
        }
        else {
          var updateRequest: any = {};
          if (this.currentENInStore) {
            Object.assign(updateRequest, this.currentENInStore);
          }
          updateRequest.fromDate =  this.dateService.setDateToDDMMYYYY(this.setPostDateFormat(this.travelerForm.value.TripFromDate));
          updateRequest.toDate = this.dateService.setDateToDDMMYYYY(this.setPostDateFormat(this.travelerForm.value.TripToDate));

          let updatedCountry = [];
          for (let val of this.selectedItems) {
            var country = this.countryList.find((country) => country.countryName.toLowerCase() == val.countryName.toLowerCase());
            var obj: any = { countryCode: '', countryName: '', ratingRegionName: '' };
            obj.countryCode = country.countryCode;
            obj.countryName = country.countryName;
            obj.ratingRegionName = country.ratingRegionName;
            updatedCountry.push(obj);
          }
          updateRequest.destinations = updatedCountry;

          if (this.prpolicytype == true) {
            updateRequest = await this.mapperService.mapEndorsementModel(updateRequest, "revision","TripUpdated");
            this.dataService.updateEndorsement(updateRequest).subscribe((enResponse) => {
              if (enResponse) {
                this.isLoader = false;
                this.msg = 'Trip details updated successfully';
                this.storeService.setENToStore(enResponse);
                sessionStorage.setItem('movementGuid', enResponse.movementGuid.toString());
                this.closeModal();
              }
              else {
                this.isLoader = false;
                this.msg = 'Failed to save Trip Details.';
                this.closeModal();
              }
            }, (err: any) => {
              this.isLoader = false;
              this.msg = 'There is an issue with backend response. Please retry again after some time';
              this.closeModal();
            });
          }
        }
      }
    }
  }

  addMonthsToDate(numberofMonths : number, date : any){
    let newDate = moment(date,'DD/MM/YYYY').add(numberofMonths, 'months');
    return newDate;
  }
}
