import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  AfterViewInit,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import {
  NgbModal,
  NgbModalRef,
  NgbDateStruct,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { PolicyDashboardGridService } from './policy-dashboard-grid.service';
import { DataTableDirective } from 'angular-datatables';
import { DatePipe, CurrencyPipe } from '@angular/common';
type ColumnField = Array<{ title: string; data?: string; render?: any }>;
// import { forkJoin } from 'rxjs/observable/forkJoin';
import { SearchRecentQuotesAndPoliciesGTA } from './app.model';
import { PriceService } from '../../../../../src/app/core/services/price.service';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { Store } from '@ngrx/store';
import { SetQueryParamsService } from '../../../../../src/app/core/role/setqueryparams';
import { PortalCodeService } from '../../../../../src/app/core/portalcode/portalcode';
import { Utility } from '../../../../../src/app/core/services/utility';
import { NgbDateFRParserFormatter } from './ngb-date-fr-parser-formatter';
import { UtcToShortDatePipe } from '../../../../../projects/policyDetails/src/app/pipes/moment.pipe';
import { FormatNumberPipe } from '../../../../../src/app/core/pipes/format-number.pipe';
import { SessionStorageService } from '../../../../../src/app/core/services/remove-session-storage';
import { LogService } from '../../../../../projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from '../../../../../projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum } from '../../../../../projects/policyDetails/src/app/enum/page-name-enum';
import { HttpCoreService } from '../../../../../src/app/core/services/http-core.service';
import { agentSearchRequest, searchRequest } from './search-req.model';
import { AutoClubService } from 'src/app/shared/auto-club.service';
import { BranchesService } from 'src/app/shared/branches.service';
import { GridService } from 'src/app/shared/grid.service'
import { error } from 'console';
import { SearchBranch } from 'src/app/core/model/app.model';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';
import { tap } from 'rxjs';
import { DataSetterService } from 'src/app/core/services/data-setter.service';
import { DateService } from '../../../../../src/app/core/services/date.service';
import * as dateUpdatedService from 'src/app/core/services/date-updated.service';
export class Constants {
  static readonly DATE_FMT = 'dd/MM/yyyy';
}

@Component({
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    UtcToShortDatePipe,
  ],
  selector: 'dashboard-policy-dashboard-grid',
  templateUrl: './policy-dashboard-grid.component.html',
  styleUrls: ['./policy-dashboard-grid.component.css'],
})
export class PolicyDashboardGridComponent
  implements AfterViewInit, OnDestroy, OnInit {
  @ViewChildren('content')
  content!: ElementRef;

  @ViewChildren('contentsubjectivity')
  contentsubjectivity!: ElementRef;

  @ViewChildren('contentdataTableFilter')
  contentdataTableFilter!: ElementRef;

  @ViewChildren('alert')
  alert!: ElementRef;

  @ViewChildren(DataTableDirective)
  datatableElement!: QueryList<DataTableDirective>;

  ngForm!: FormGroup;

  _dataTableDataList: any = [];
  _dataSearchTableDataList: any = [];
  data: any = [];
  searchData: any = [];
  dataArray: any = [];
  text: any = [];
  filterArray: any = [];
  columnArray: ColumnField = [];
  searchRecentQuotesAndPoliciesGTA = new SearchRecentQuotesAndPoliciesGTA();
  dtOptions: any = [];
  dtSearchOptions: any = [];
  department = [];
  country = [];
  classification = [];
  agentlist: any = [];
  fnlagentlist: any = [];
  listClubs: any = [];
  listBranches: any = [];
  datemodelfrom: any;
  datemodelto: any;
  isSearchClick = false;
  maxDate: { year: number, month: number, day: number };
  userRoleDetails?: UserRoleDetails;

  dataTableProperties: any = {};
  obj: any = {
    title: '',
    data: '',
  };

  _objDataTabledata: any = {
    title: '',
    apiaction: '',
    data: [],
  };

  columnDefValue: any = [
    {
      targets: [],
      orderable: false,
    },
  ];

  modalData: any = {};
  modelRadio = { options: 'Policy' };
  searchVal: any = '';
  response: any;
  isLoaderPolicyGrid: boolean = false;
  advanceSearchPanel: boolean = false;
  isLoaderSearchPolicyGrid: boolean = false;
  isAdvanceSearchGridResult: boolean = false;
  isGridResult: boolean = false;
  isReadOnly: any = false;
  isSearch: boolean = false;
  gridNameField: any = 'Policy';
  isInputInvalid: boolean = false;
  nameFieldLength: number = 50;
  noSearchValueSelectedError: boolean = false;

  constructor(
    private policyDashboardGridService: PolicyDashboardGridService,
    private renderer: Renderer2,
    private priceService: PriceService,
    private setQueryParamsService: SetQueryParamsService,
    private modalService: NgbModal,
    private pipe: DatePipe,
    private utcToShortDate: UtcToShortDatePipe,
    private currencyPipe: CurrencyPipe,
    private dateService: DateService,
    private dateUpdatedService: dateUpdatedService.DateService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private portalCodeService: PortalCodeService,
    private formatNumberPipe: FormatNumberPipe,
    private sessionStorageService: SessionStorageService,
    private logService: LogService,
    private httpService: HttpCoreService,
    private autoClubService: AutoClubService,
    private branchesService: BranchesService,
    private storeService: StoreGenericService,
    private gridService: GridService,
    private dataSetterService: DataSetterService,
    private store: Store
  ) {
    const today = new Date();
    this.maxDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1, // Months are 0-based
      day: today.getDate()
    };
  }

  ngOnInit(): void {
    this.isLoaderPolicyGrid = true;
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.loadAutoClub();
        this.isReadOnly = this.userRoleDetails.IsReadOnly;
        this.getSitecoreDataTableDataList();
        this.sessionStorageService.removeSessionStorage();
      }
    });
  }

  loadAutoClub(): void { 
    let autoClubs = this.dataSetterService.setAutoClubs(this.userRoleDetails?.AutoClubURLList, true);
    this.listClubs = autoClubs;
  }

  loadBranches(autoClubGuid: any): void {
    this.branchesService.getBranchDetail(this.userRoleDetails?.AgentContactId, autoClubGuid).subscribe((data) => {
      this.listBranches = JSON.parse(JSON.stringify(data.responseMessage));
    });
  }

  //Get Agent list
  getAgentList(autoClubGuid?: any, branchGuid?: any) {
    this.fnlagentlist = [];
    this.policyDashboardGridService.getAgentDetail(autoClubGuid, branchGuid).subscribe(
      {
        next:
          (data) => {
            this.fnlagentlist = data.responseMessage;
          }, error:
          (err) => {
            this.logService.createErrorLog(
              {},
              err,
              'GetAgentDetailService',
              PageNameEnum.Dashboard,
              LogLevel.Error,
            );
          },
      }
    );
  }

  onSearch(item: {
    DataGridRenderingParameters: {
      IsWebDisplay: boolean;
      GridNameField: any;
      ApiAction: string;
      GridName: any;
    }
  }, req?: any): void {
    this.isSearchClick = false;
    if (this.datatableElement.first && this.datatableElement.first.dtInstance) {
      this.datatableElement.first.dtInstance.then(dtInstance => {
        var obsPolicies = this.getSearchPoliciesNQuotes(item, req);
        dtInstance.ajax.url(this.gridService.getAjaxMethod(obsPolicies, req, PageNameEnum.Dashboard)).load();
      });
    } else {
      this.dtOptions = [];
      this.dtOptions.push(this.buildDtOptions(item, req));
    }
  }

  //Api Method
  getSitecoreDataTableDataList(): void {
    this.policyDashboardGridService
      .getSiteCoreAgencyTotalList(this.gridNameField)
      .subscribe(
        (resp) => {

          this.text = JSON.parse(JSON.stringify(resp));
          this.text.DataGridAllList.forEach(
            (item: {
              DataGridRenderingParameters: {
                IsWebDisplay: boolean;
                GridNameField: any;
                ApiAction: string;
                GridName: any;
              };
            }) => {
              if (
                item.DataGridRenderingParameters.IsWebDisplay == true
                &&
                item.DataGridRenderingParameters.GridNameField == this.gridNameField
              ) {
                this.httpService.validateJwtToken().subscribe((data) => {
                  this.searchRecentQuotesAndPoliciesGTA.SearchFor =
                    item.DataGridRenderingParameters.ApiAction;

                  let reqObj: searchRequest = {
                    isPaginationEnabled: false
                  };
                  if (this.isSearchClick) {
                    this.onSearch(item, reqObj)
                  }
                  else {
                    this.dtOptions = [];
                    this.dtOptions.push(
                      this.buildDtOptions(item, {}),
                    );
                    this.isAdvanceSearchGridResult = false;
                    this.isGridResult = this.isGridResult;
                    this.isLoaderPolicyGrid = false;
                    this.isLoaderSearchPolicyGrid = false;
                  }
                });
              }
            },
          );
        },
        (err) => {
          this.logService.createErrorLog(
            {},
            err,
            'GetSiteCoreAgencyTotalList',
            PageNameEnum.Dashboard,
            LogLevel.Error,
          );
        },
      );
  }

  onClubSelect(event: any, item: any, keyText: any) {
    let itemValue = event.target.value;
    this.loadBranches(itemValue);
    this.fnlagentlist = [];
    for (let itemfilter of item.searchFilter) {
      if (itemValue == '' && keyText == itemfilter.KeyText.toLowerCase()) {
        itemfilter.Value = '';
      } else if (itemfilter.KeyText.toLowerCase() == 'branchname') {
        itemfilter.Value = '';
      } else if (itemfilter.KeyText.toLowerCase() == 'agentname') {
        itemfilter.Value = '';
      }
    }
  }

  onBranchSelect(event: any, item: any, keyText: any) {
    let itemValue = event.target.value;
    let clubCode = '';
    for (let itemfilter of item.searchFilter) {
      if (itemValue == '' && keyText == itemfilter.KeyText.toLowerCase()) {
        itemfilter.Value = '';
      } else if (itemfilter.KeyText.toLowerCase() == 'agentname') {
        itemfilter.Value = '';
      } else
        if (itemfilter.KeyText.toLowerCase() == 'clubname') {
          clubCode = itemfilter.Value;
        }
    }
    this.getAgentList(clubCode, itemValue);
    this.fnlagentlist = this.agentlist;
    this.fnlagentlist.sort(this.portalCodeService.sortByAlphabeticalOrder);
  }

  buildDtOptions(item: any, req: any): any {
    let qyeryPolicyId = '&q=';
    let clouser = this.setQueryParamsService.setGetaQuoteGridQueryParams('');
    let portalService = this.portalCodeService;
    this.columnArray = [];
    let targetArray: any = [];
    let orderable = false;
    this.columnDefValue = [];
    let columnindex = -1;
    let className = 'text-center';
    for (let col of item.Columns) {
      columnindex++;
      if (col.IsWebDisplay == true && col.IsPopup == true) {
        let rowindex = 0;
        this.columnArray.push({
          title: col.DisplayName,
          data: col.FieldName,
          render: function (data: any, type: any, full: any) {
            let columnvalue = '';
            let policyId = '';
            for (let item in full) {
              if (col.FieldName == item) {
                columnvalue = full[item];
              } else if ('PolicyId' == item) {
                policyId = full[item];
              }
            }

            return (
              '<a href="javascript:void(0);"  ispopup="' +
              col.IsPopup +
              '" apiaction="' +
              item.DataGridRenderingParameters.ApiAction +
              '" columnvalue="' +
              columnvalue +
              '" column="' +
              col.FieldName +
              '" policyId="' +
              policyId.toString() +
              '" >' +
              columnvalue +
              '</a>'
            );
          },
        });

        if (col.Sortable == true) {
          targetArray.push(columnindex);
        }

        className = col.Class;
      } else if (
        col.IsWebDisplay == true &&
        col.Linked1Url != null &&
        col.Linked1Url.Url != ''
      ) {
        let rowindex = 0;
        let autoClubURLList = this.userRoleDetails?.AutoClubURLList;
        this.columnArray.push({
          title: col.DisplayName,
          data: col.FieldName,
          render: function (data: any, type: any, full: any) {
            let columnvalue = '';
            let quoteNo = '';
            let movementGuid = '';
            let policyGuid = '';
            let ClubName = '';
            let quoteGuid = '';

            for (let item in full) {
              if (col.FieldName == item) {
                columnvalue = full[item];
              } else if (item == 'movementGuid') {
                movementGuid = full[item];
              } else if (item == 'policyGuid') {
                policyGuid = full[item];
              } else if (item == 'ClubName' || item == 'clubCode') {
                ClubName = full[item];
              } else if (item == 'quoteGuid') {
                quoteGuid = full[item];
              }
            }
            sessionStorage.setItem(
              'quotestatus',
              item.DataGridRenderingParameters.GridNameField,
            );
            sessionStorage.setItem('redirectFrom', 'pendingQuoteList');

            if (
              item.DataGridRenderingParameters.GridNameField ==
              'SearchQuotes' ||
              item.DataGridRenderingParameters.GridNameField == 'Quotes'
            ) {
              clouser = clouser.replace(
                qyeryPolicyId,
                '&q=' + btoa(quoteGuid.toString()),
              );
              qyeryPolicyId = '&q=' + btoa(quoteGuid.toString());
              return (
                '<a href="' +
                portalService.getPortalCodeBaseUrl(ClubName, autoClubURLList) +
                'quotes-steps-one/' +
                clouser +
                '"  target=_blank>' +
                columnvalue +
                '</a>'
              );
            } else {
              return (
                '<a href="javascript:void(0);"  linkedurl1="' +
                col.Linked1Url.Url +
                '"  policyGuid="' +
                policyGuid +
                '"  columnvalue="' +
                columnvalue +
                '" column="' +
                col.FieldName +
                '" quoteNo="' +
                quoteNo +
                '" >' +
                columnvalue +
                '</a>'
              );
            }
          },
        });

        if (col.Sortable == true) {
          targetArray.push(columnindex);
        }

        className = col.Class;
      } else if (col.IsWebDisplay == true) {
        this.columnArray.push({
          title: col.DisplayName,
          data: col.FieldName,
        });
        if (col.Sortable == true) {
          targetArray.push(columnindex);
          orderable = true;
        }
        className = col.Class;
      }
    }

    if (this.filterArray.length <= 0 &&
      item.DataGridRenderingParameters.SearchFilter !== undefined &&
      item.DataGridRenderingParameters.SearchFilter.length > 0
    ) {
      for (let filter of item.DataGridRenderingParameters.SearchFilter) {
        var tempItem = {
          KeyText: filter.KeyText,
          Value: filter.Value || '',
          Description: filter.Description,
          FieldType: filter.FieldType,
          ColumnType: filter.ColumnType,
          Type: this.gridNameField
        };
        this.filterArray.push(tempItem);
      }
    }

    if (this.filterArray.length != 0 &&
      this.filterArray.find((x: any) => x.Type == this.gridNameField) == undefined
    ) {
      let arr = this.filterArray;
      let idx = 0;
      this.filterArray = [];
      for (let filter of item.DataGridRenderingParameters.SearchFilter) {
        var tempItem = {
          KeyText: filter.KeyText,
          Value: arr[idx].Value,
          Description: filter.Description,
          FieldType: filter.FieldType,
          ColumnType: filter.ColumnType,
          Type: this.gridNameField
        };
        this.filterArray.push(tempItem);
        idx++;
      }
    }

    if (targetArray.length > 0) {
      this.columnDefValue.push({
        targets: targetArray,
        orderable: orderable,
        className: className,
      });
    }

    var dtOptions = {
      columns: this.columnArray,
      responsive: item.DataGridRenderingParameters.Responsive
        ? item.DataGridRenderingParameters.Responsive
        : false,
      autoWidth: item.DataGridRenderingParameters.AutoWidth
        ? item.DataGridRenderingParameters.AutoWidth
        : false,
      searchingOption:
        item.DataGridRenderingParameters.Searching != ''
          ? item.DataGridRenderingParameters.Searching
          : true,
      searching: true,
      bLengthChange: false,
      pagingType: 'full_numbers',
      iDisplayLength: 10,
      columnDefs: this.columnDefValue,
      gridDisplayName: item.DataGridRenderingParameters.GridName,
      gridName: item.DataGridRenderingParameters.Name,
      name: item.DataGridRenderingParameters.Name,
      singleSearchFilter: this.searchVal,
      searchFilter: this.filterArray,
      order: [],
      dom: 'Bfrtip',
      buttons: [
        //'pdf',
        //'excel'
      ],
      language: {
        paginate: {
          previous: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
          next: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
          first: '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
          last: '<i class="fa fa-angle-double-right" aria-hidden="true"></i>',
        },
      },
    };
    var obsPolicies = this.getSearchPoliciesNQuotes(item, req);
    return this.gridService.buildDtOptionsWithPaging(dtOptions, obsPolicies, req, PageNameEnum.Dashboard);
  }

  getSearchPoliciesNQuotes(item: any, req: any) {
    var that = this;
    this._dataTableDataList = [];
    if (req.fromDate != null && req.fromDate != undefined) {
      req.column = item.Columns[0].FieldName;
      req.dir = 'asc'
    }
    else if (req.fromDate == null && req.toDate != null && req.toDate != undefined) {
      req.column = item.Columns[0].FieldName;
      req.dir = 'desc'
    }
    return that.policyDashboardGridService.getSearchPoliciesNQuotes(that.modelRadio.options, req).pipe(tap((res) => {
      res.response = this.formatTableData(JSON.parse(JSON.stringify(res.response)));
      that._objDataTabledata = {
        title: item.DataGridRenderingParameters.GridName,
        apiaction: item.DataGridRenderingParameters.ApiAction,
        data: res.response,
      };
      that._dataTableDataList.push(that._objDataTabledata);
      that.isAdvanceSearchGridResult = false;
      that.isLoaderPolicyGrid = false;
      that.isLoaderSearchPolicyGrid = false;
      if (this.isSearch) {
        this.isSearch = false;
      }
    })
    );
  }

  //DataTableFilter Method
  filterById(tableindex: any, item: any): void {
    let itemindex = 0;
    let length = 0;
    let itemtableindex = tableindex;
    this.datatableElement.forEach(
      (dtElement: DataTableDirective, index: number) => {
        dtElement.dtInstance.then((dtInstance: any) => {
          if (tableindex == index) {
            dtInstance.columns().every(function (columnIndex: number) {
              if (dtInstance.search() !== dtInstance[item.singleSearchFilter]) {
                dtInstance.search(item.singleSearchFilter.toLowerCase());
              }
            });

            dtInstance.draw();
          }
        });
      },
    );
  }

  open(content: any, rowdata: any) {
    const modal: NgbModalRef = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    this.modalData = rowdata;
    modal.result.then(
      (result) => {
        //console.log(result);
      },
      (reason) => {
        //console.log(reason);
      },
    );
  }

  setDate(data: any) {
    if (data != undefined && data != '') {
      let date = data.split('T')[0].split('-');
      let day = date[1];
      let month = date[0];
      let year = date[2];
      let ngbDateStruct = {
        month: Number(month),
        day: Number(day),
        year: Number(year),
      };

      return ngbDateStruct;
    }

    return '';
  }

  //Common Method
  formatTableData(tabledata: any): void {
    for (let item of tabledata) {
      if (item.MobileNumber == undefined) {
        item.MobileNumber = '';
      }

      if (item.AgentName == undefined) {
        item.AgentName = '';
      }

      if (item.Agent == undefined) {
        item.Agent = '';
      }

      if (item.AgencyName == undefined) {
        item.AgencyName = '';
      }

      if (item.BranchName == undefined) {
        item.BranchName = '';
      }

      if (item.ProcessStatus == undefined) {
        item.ProcessStatus = '';
      }

      for (let key in item) {
        if (key == 'EffectiveDate') {
          item[key] = this.utcToShortDate.transform(item[key]);
        } else if (key == 'ExpiringPolicyExpDate') {
          item[key] = this.utcToShortDate.transform(item[key]);
        } else if (key == 'CreationDate') {
          item[key] = this.utcToShortDate.transform(item[key]);
        } else if (key == 'PurchasedDate' || key == 'purchasedDate') {
          item[key] = this.utcToShortDate.transform(item[key]);
        } else if (key == 'QuoteDate') {
          item[key] = this.utcToShortDate.transform(item[key]);
        } else if (key == 'PolicyExp') {
          item[key] = this.utcToShortDate.transform(item[key]);
        } else if (key == 'Amount') {
          item[key] = this.priceService.getPriceFixed(item[key], 2);
        } else if (key == 'WrittenPremium') {
          item[key] = this.priceService.getPriceFixed(item[key], 2);
        } else if (key == 'totalPremium') {
          item[key] = this.priceService.getPriceFixed(item[key], 2);
        } else if (key == 'MobileNumber') {
          item[key] = this.formatNumberPipe.transform(item[key]);
        }
      }
    }

    return tabledata;
  }

  formatTableRowData(tabledata: any): void {
    for (let key in tabledata) {
      if (key == 'EffectiveDate') {
        tabledata[key] = this.utcToShortDate.transform(tabledata[key]);
      } else if (key == 'ExpiringPolicyExpDate') {
        tabledata[key] = this.utcToShortDate.transform(tabledata[key]);
      } else if (key == 'CreationDate') {
        tabledata[key] = this.utcToShortDate.transform(tabledata[key]);
      } else if (key == 'PolicyEff') {
        tabledata[key] = this.utcToShortDate.transform(tabledata[key]);
      } else if (key == 'PolicyExp') {
        tabledata[key] = this.utcToShortDate.transform(tabledata[key]);
      } else if (key == 'QuoteDate') {
        tabledata[key] = this.utcToShortDate.transform(tabledata[key]);
      } else if (key == 'PurchasedDate') {
        tabledata[key] = this.utcToShortDate.transform(tabledata[key]);
      } else if (key == 'Amount') {
        tabledata[key] = this.priceService.getFormattedPrice(tabledata[key]);
      } else if (key == 'WrittenPremium') {
        tabledata[key] = this.priceService.getFormattedPrice(tabledata[key]);
      } else if (key == 'totalPremium') {
        tabledata[key] = this.priceService.getFormattedPrice(tabledata[key]);
      } else if (key == 'MobileNumber') {
        tabledata[key] = this.formatNumberPipe.transform(tabledata[key]);
      }
    }

    return tabledata;
  }

  getColumnTableData(columnfield: any, tabledata: any): any {
    let columnvalue: any = '';
    for (let item in tabledata) {
      if (columnfield == item) {
        columnvalue = tabledata[item];
      }
    }

    return columnvalue;
  }

  getPopUpData(policyId: any, apiaction: any): any {
    this.modalData = {};
    let i = 0;
    for (let itemDataTable of this._dataTableDataList) {
      if (apiaction.toLowerCase() == itemDataTable.apiaction.toLowerCase()) {
        for (let itemRowData of itemDataTable.data) {
          if (policyId == itemRowData.PolicyId.toString()) {
            this.modalData = itemRowData;
          }
        }
      }
    }

    return this.modalData;
  }

  getPopUpDatabyColumn(
    columnValue: any,
    columnkeyText: any,
    apiaction: any,
  ): any {
    this.modalData = {};
    let i = 0;
    for (let itemDataTable of this._dataTableDataList) {
      if (apiaction.toLowerCase() == itemDataTable.apiaction.toLowerCase()) {
        for (let itemRowData of itemDataTable.data) {
          if (columnValue == itemRowData[columnkeyText]) {
            this.modalData = itemRowData;
          }
        }
      }
    }

    return this.modalData;
  }

  //ng Method
  ngAfterViewInit(): void {
    this.renderer.listen('document', 'click', (event) => {
      if (
        event.target.attributes['linkedurl1'] != undefined &&
        event.target.attributes['linkedurl1'].value != ''
      ) {
        let policyGuid = event.target.attributes['policyGuid'].value;
        let linkedurl1 = event.target.attributes['linkedurl1'].value;
        sessionStorage.setItem('policyGuid', policyGuid);
        let current = new Date();
        current = new Date(
          this.policyDashboardGridService.utcToAestDate(current.toUTCString()),
        );
        location.href =
          location.protocol + '//' + location.host + linkedurl1;
      }
    });
  }

  ngOnDestroy(): void { }

  radioButtonClick(gridName: any) {
    this.modelRadio.options = gridName;
    this.gridNameField = gridName;
    this.isLoaderPolicyGrid = true;
    this.isAdvanceSearchGridResult = false;
    this.isGridResult = false;
    this.getSitecoreDataTableDataList();
  }

  getCalendarText(item: any) {
    return item.toggle();
  }

  toggle() {
    if (this.advanceSearchPanel) {
      this.advanceSearchPanel = false;
    } else {
      this.advanceSearchPanel = true;
    }
  }

  displayCustomSearchToConsole(
    event: Event,
    item: any,
    tableindex: any,
    filterType: any,
    searchform?: NgForm,
  ): void {
    this.isSearch = true;
    if (filterType != 'allClear') {
      this.isSearchClick = false;
      this.noSearchValueSelectedError = false;
      let noOffieldshasValue: Array<any> = item.searchFilter.filter(
        (row: { Value: any }) => row.Value,
      );
      if (noOffieldshasValue.length == 0) {
        this.noSearchValueSelectedError = true;
        setTimeout(() => {
          this.noSearchValueSelectedError = false;
        }, 3000);
        return;
      }
      let calendarFields: Array<any> = item.searchFilter.filter(
        (row: { KeyText: string; error: any }) =>
          (row.KeyText == 'EffectiveDate' || row.KeyText == 'ExpirationDate') &&
          row.error,
      );
      if (calendarFields.length) {
        this.isInputInvalid = true;
        return;
      }
    }
    this.isInputInvalid = false;
    this.isLoaderSearchPolicyGrid = false;
    this.isGridResult = false;
    this.dtSearchOptions = [];
    let gridname = this.modelRadio.options;
    let length = 0;

    for (let itemfilter of item.searchFilter) {
      if (filterType == 'allClear') {
        itemfilter.Value = '';
        itemfilter.error = false;
      }

      this.setFilter(itemfilter.KeyText, itemfilter.Value);
      length++;
    }

    if (filterType == 'allClear') {
      this.listBranches = [];
      this.fnlagentlist = [];
      this.isSearchClick = false;
      this.isGridResult = false;
      this.isAdvanceSearchGridResult = false;
      this.isLoaderSearchPolicyGrid = false;
    }

    if (filterType != 'allClear') {

      //-- New implementation START
      let request = this.createSearchReq(searchform?.form.value);

      for (let itemfilter of item.searchFilter) {
        this.setFilter(itemfilter.KeyText, itemfilter.Value);
      }

      this.policyDashboardGridService
        .getSiteCoreAgencyTotalList(gridname)
        .subscribe(
          (resp) => {
            let text: any;
            this.dtSearchOptions = [];
            text = resp;
            text.DataGridAllList.forEach((item: any) => {

              if (
                item.DataGridRenderingParameters.IsWebDisplay == true
              ) {
                this.onSearch(item, request);
                this.isLoaderSearchPolicyGrid = true;
                this.isAdvanceSearchGridResult = true;
                this.isGridResult = true;
              }
            });
          },
          (err) => {
            this.logService.createErrorLog(
              {},
              err,
              'GetSiteCoreAgencyTotalList',
              PageNameEnum.Dashboard,
              LogLevel.Error,
            );
          },
        );
      //-- New implementation END

    }
  }

  createSearchReq(values?: any) {
    let formValues = JSON.parse(JSON.stringify(values));
    let reqObj: searchRequest = {
      isPaginationEnabled: false,
      pageNo: 0,
      pageSize: 0,
      firstName: formValues.name1.trim(),
      lastName: formValues.name2.trim(),
      // mobileNo: formValues.name5,
      // email: formValues.name6,
      // clubCode: formValues.name7,
      // agentName: formValues.name8
    };

    if (this.modelRadio.options === 'Policy') {
      reqObj.policyNumber = formValues.name0.trim();
    }
    if (this.modelRadio.options === 'Quotes') {
      reqObj.quoteNumber = formValues.name0.trim();
    }

    const fdate = `${formValues.name3?.year}-${String(formValues.name3?.month).padStart(2, '0')}-${String(formValues.name3?.day).padStart(2, '0')}`;
    const ldate = `${formValues.name4?.year}-${String(formValues.name4?.month).padStart(2, '0')}-${String(formValues.name4?.day).padStart(2, '0')}`;

    if (formValues.name3) {
      reqObj.fromDate = fdate;
    }

    if (formValues.name4) {
      reqObj.toDate = ldate;
    }

    if (formValues.name5) {
      reqObj.mobileNo = formValues.name5.trim();
    }

    if (!!(formValues.name5) && formValues.name5.length !== 0) {
      reqObj.mobileNo = formValues.name5.trim();
    } else {
      reqObj.mobileNo = "";
    }

    if (!!(formValues.name6) && formValues.name6.length !== 0) {
      reqObj.email = formValues.name6.trim();
    } else {
      reqObj.email = "";
    }

    if (!!(formValues.name7) && formValues.name7.length !== 0) {
      reqObj.autoClubGuid = formValues.name7;
    }
    if (!!(formValues.name8) && formValues.name8.length !== 0) {
      reqObj.branchGuid = formValues.name8;
    }
    if (!!(formValues.name9) && formValues.name8.length !== 0) {
      reqObj.agentGuid = formValues.name9;
    }

    return reqObj;
  }

  setFilter(keytext: any, itemvalue: any) {
    if (keytext == 'AgencyId') {
      this.searchRecentQuotesAndPoliciesGTA.AgencyId =
        itemvalue != '' ? itemvalue : null;
    } else if (keytext == 'AgentContactId') {
      this.searchRecentQuotesAndPoliciesGTA.AgentName =
        itemvalue != '' ? itemvalue : null;
    } else if (keytext == 'ClubName') {
      this.searchRecentQuotesAndPoliciesGTA.AgencyId =
        itemvalue != '' ? itemvalue : null;
    }
    else if (keytext == 'AgentName') {
      let agentName = this.fnlagentlist.filter(
        (item: { AgentGuid: any }) => item.AgentGuid == itemvalue,
      );
      this.searchRecentQuotesAndPoliciesGTA.AgentName =
        agentName != '' && agentName.length > 0 ? agentName[0].FullName : null;
    }
    else if (keytext == 'BranchName') {
      let branhName = this.listBranches.filter(
        (item: { ContactId: any }) => item.ContactId == itemvalue,
      );
      this.searchRecentQuotesAndPoliciesGTA.BranchName =
        branhName != '' && branhName.length > 0 ? branhName[0].FullName : null;
    }
    else if (keytext == 'EffectiveDate') {
      this.searchRecentQuotesAndPoliciesGTA.EffectiveDate =
        itemvalue != '' ? this.dateService.setPostDateFormat(itemvalue) : '';
    } else if (keytext == 'ExpirationDate') {
      this.searchRecentQuotesAndPoliciesGTA.ExpirationDate =
        itemvalue != '' ? this.dateService.setPostDateFormat(itemvalue) : '';
    } else if (keytext == 'InsuredEmail') {
      this.searchRecentQuotesAndPoliciesGTA.InsuredEmail =
        itemvalue != '' ? itemvalue : null;
    } else if (keytext == 'InsuredFirstName') {
      this.searchRecentQuotesAndPoliciesGTA.InsuredFirstName =
        itemvalue != '' ? itemvalue : null;
    } else if (keytext == 'InsuredLastName') {
      this.searchRecentQuotesAndPoliciesGTA.InsuredLastName =
        itemvalue != '' ? itemvalue : null;
    } else if (keytext == 'InsuredMobile') {
      this.searchRecentQuotesAndPoliciesGTA.InsuredMobile =
        itemvalue != '' ? itemvalue : null;
    } else if (keytext == 'PolicyNum') {
      this.searchRecentQuotesAndPoliciesGTA.PolicyNum =
        itemvalue != '' ? itemvalue : null;
    }
  }

  checkvalidation(data: any, event: any) {
    if (!event.data) return true;
    if (
      data.KeyText == 'InsuredFirstName' ||
      data.KeyText == 'InsuredLastName'
    ) {
      if (data.Value.length + event.data.length > this.nameFieldLength) {
        if (data.Value.length + event.data.length - this.nameFieldLength > 1) {
          let pattern = /^[a-zA-Z]+$/i;
          let tempData = event.data;
          let dataToInsert = tempData.slice(
            0,
            this.nameFieldLength - data.Value.length,
          );
          if (pattern.test(dataToInsert)) data.Value += dataToInsert;
          return false;
        } else return false;
      } else return this.allowAlphabet(event);
    } else if (data.KeyText == 'InsuredMobile') {
      return this.validateNumberInput(event);
    } else return true;
  }

  checkvalidationwithspacesorspecial(data: any, event: any) {
    if (!event.data) return true;
    if (
      data.KeyText == 'InsuredFirstName' ||
      data.KeyText == 'InsuredLastName'
    ) {
      if (data.Value.length + event.data.length > this.nameFieldLength) {
        if (data.Value.length + event.data.length - this.nameFieldLength > 1) {
          let pattern = /^[a-zA-Z\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?><]+$/i;
          let tempData = event.data;
          let dataToInsert = tempData.slice(
            0,
            this.nameFieldLength - data.Value.length,
          );
          if (pattern.test(dataToInsert)) data.Value += dataToInsert;
          return false;
        } else return false;
      } else return this.allowAlphabetwithspacespecialchar(event);
    } else if (data.KeyText == 'InsuredMobile') {
      return this.validateNumberInput(event);
    } else return true;
  }

  checkcalendarInput(data: any, event: any) {
    if (!event.target) {
      data.error = false;
      return;
    }
    if (!event.target.value) {
      data.error = false;
    }
    else data.error = !Utility.isValidDate(event.target.value) || this.compareDates(event.target.value);
  }
  compareDates(a: any) {
    let today = new Date();
    let todayDateOnly = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );
    let dDateOnly = this.dateUpdatedService.parseDateString(a) ?? '';
    if (todayDateOnly <= dDateOnly) {
      return true;
    } else {
      return false;
    }
  }
  validateNumberInput(event: any) {
    let pattern = /^[0-9]+$/i;
    if (pattern.test(event.data)) return true;
    else return false;
  }

  allowAlphabet(event: any) {
    let keyCode = event.data;
    let pattern = /^[a-zA-Z]+$/i;
    if (pattern.test(keyCode)) return true;
    else return false;
  }

  allowAlphabetwithspacespecialchar(event: any) {
    let keyCode = event.data;
    let pattern = /^[a-zA-Z\s!@#$%^&*()_+=-`~\\\]\[{}|';:/.,?><]+$/i;
    if (pattern.test(keyCode)) return true;
    else return false;
  }
}
