import { Component, EventEmitter, OnInit } from '@angular/core';
import { SharedService } from './shared.service';
import { ManageBranchesService } from './ManageBranchs.service';
import { LoaderService } from '../../../../src/app/core/services/loader.service';
import { ConfigColumns } from 'datatables.net';
import { HeaderNavigationService } from 'projects/nav/src/app/app.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserProfile } from 'src/app/core/store/loginUser/loginUser.model';
@Component({
  selector: 'manage-branches-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class ManageBranchesComponent implements OnInit {
  openBranchModalEvent: EventEmitter<any> = new EventEmitter<any>();
  confirmModalEvent: EventEmitter<string> = new EventEmitter<string>();
  updateStatusModalEvent: EventEmitter<string> = new EventEmitter<string>();
  tableColumns: ConfigColumns[] = [
    { title: '#', data: 'id', orderable: false, className: 'text-center' },
    { title: 'Branch Name', data: 'name' },
    { title: 'Street Address', data: 'address' },
    { title: 'Phone', data: 'phoneNumber' },
    { title: 'Email', data: 'emailAddress', defaultContent: '' },
    { title: 'Fax', data: 'faxNumber', defaultContent: '' },
    { title: 'ABN', data: 'abn', defaultContent: '' },
    {
      title: 'Edit',
      data: null,
      defaultContent: '',
      orderable: false,
      className: 'text-center',
      render: (data: any, type: any, row: any) => {
        return `<button class="btn btn-link edit-btn" style="padding: 0 !important;" data-guid="${row.branchGuid}">Edit</button>`;
      },
    },
    {
      title: 'Deactivate',
      data: null,
      defaultContent: '',
      orderable: false,
      className: 'text-center',
      render: (data: any, type: any, row: any) => {
        return `<button class="btn btn-link activate-btn" style="padding: 0 !important;" data-guid="${row.branchGuid
          }" data-action="${row.isActive ? 'deactivate' : 'reactivate'}">${row.isActive ? 'Deactivate' : 'Reactivate'
          }</button>`;
      },
    },
  ];
  userProfile?: UserProfile;

  constructor(
    private sharedService: SharedService,
    private manageBranchService: ManageBranchesService,
    private loader: LoaderService,
    private navService: HeaderNavigationService,
    private store: Store
  ) {

  }

  ngOnInit() {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userProfile = loginUser.userProfile;
        this.navService.handelUnauthorizedUrl("/ManageBranches", loginUser.userRoleDetails.NavigationMenuItems)
      }
    });
  }

  openAddModal() {
    this.openBranchModalEvent.emit({ action: 'add' });
  }

  openEditModal(data: any) {
    this.openBranchModalEvent.emit({ action: 'edit', data });
  }

  onClubChanged(selected: any) {
    this.sharedService.emitClubDropdownChange(selected);
  }

  onBranchSuccess(message: any) {
    this.confirmModalEvent.emit(message);
  }

  openUpdateStatusModal(data: any) {
    this.updateStatusModalEvent.emit(data);
  }

  onUpdateConfirm(data: any) {
    let activate: boolean = true;
    let message: string = 'Branch has been reactivated successfully...';

    if (data.action === 'deactivate') {
      activate = false;
      message = 'Branch has been deactivated successfully...';
    }

    this.loader.show();
    this.manageBranchService.updateStatus(data.id, activate, this.userProfile?.LoggedUser).subscribe(() => {
      this.loader.hide();
      this.sharedService.emitRefreshTable();
      this.confirmModalEvent.emit(message);
    });
  }
}
