import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class RoleTextService {
  constructor(private http: HttpClient) {}

  // setRoleText() {
  //   if (UserRoleDetails.setRole) {
  //     if (UserRoleDetails.IsUW) {
  //       return 'Super Admin';
  //     } else if (UserRoleDetails.IsSuperUser) {
  //       return 'Super User';
  //     } else if (UserRoleDetails.IsReadOnly && ClubAndBranchInfo.Clubs) {
  //       if (ClubAndBranchInfo.Clubs.length > 1) {
  //         return 'Super Read Only';
  //       }
  //       return 'Club Read Only';
  //     } else if (UserRoleDetails.IsClubAdmin) {
  //       return 'Club Admin';
  //     } else if (UserRoleDetails.IsAgent) {
  //       return 'Club Agent';
  //     } else if (UserRoleDetails.IsTMMReadOnly) {
  //       return 'W2c ReadOnly';
  //     }
  //   }
  // }

  // setRoleType() {
  //   if (UserRoleDetails.setRole) {
  //     if (UserRoleDetails.IsUW) {
  //       return 'SuperAdmin';
  //     } else if (UserRoleDetails.IsSuperUser) {
  //       return 'SuperUser';
  //     } else if (UserRoleDetails.IsReadOnly) {
  //       return 'ClubReadOnly';
  //     } else if (UserRoleDetails.IsClubAdmin) {
  //       return 'ClubAdmin';
  //     } else if (UserRoleDetails.IsAgent) {
  //       return 'ClubAgent';
  //     } else if (UserRoleDetails.IsTMMReadOnly) {
  //       return 'TMMAReadOny';
  //     }
  //   }
  // }

  // setRoleTypeText() {
  //   if (UserRoleDetails.setRole) {
  //     if (UserRoleDetails.IsUW) {
  //       return 'TMMASuperAdmin';
  //     } else if (UserRoleDetails.IsSuperUser) {
  //       return 'TMMAAgent';
  //     } else if (UserRoleDetails.IsClubAdmin) {
  //       return 'ClubSuperUser';
  //     } else if (UserRoleDetails.IsAgent) {
  //       return 'ClubAgent';
  //     }
  //   }
  // }

  // setRoleTypeId() {
  //   if (UserRoleDetails.setRole) {
  //     if (UserRoleDetails.IsUW) {
  //       return UserRoleDetails.UWUserId;
  //     } else if (UserRoleDetails.IsSuperUser) {
  //       return UserRoleDetails.SuperUserContactId;
  //     } else if (UserRoleDetails.IsReadOnly) {
  //       return UserRoleDetails.ReadOnlyContactId;
  //     } else if (UserRoleDetails.IsClubAdmin) {
  //       return UserRoleDetails.ClubAdminContactId;
  //     } else if (UserRoleDetails.IsAgent) {
  //       return UserRoleDetails.AgentContactId;
  //     } else if (UserRoleDetails.IsTMMReadOnly) {
  //       return UserRoleDetails.TMMReadOnlyUserId;
  //     }
  //   }
  // }
}
