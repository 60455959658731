import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
  EventEmitter,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ManageUsersService } from './manageusers.service';
type ColumnField = Array<{ title: string; data?: string; render?: any }>;
// import { forkJoin } from 'rxjs/observable/forkJoin';
import { LoaderService } from '../../../../src/app/core/services/loader.service';
import { SharedService } from 'projects/manage-branches/src/app/shared.service';
import { ConfigColumns } from 'datatables.net';
import { AgentSearchParams, ClubAgent } from './app.model';
import { HeaderNavigationService } from 'projects/nav/src/app/app.service';
import { DataSetterService } from 'src/app/core/services/data-setter.service';
import { selectAgents, selectBranches } from 'src/app/core/store/user/user.selectors';
import { Store } from '@ngrx/store';
import { setAgents, setBranches } from 'src/app/core/store/user/user.actions';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails, UserProfile } from 'src/app/core/store/loginUser/loginUser.model';
import { BranchesService } from 'src/app/shared/branches.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export class Constants {
  static readonly DATE_FMT = 'dd/MM/yyyy';
}

@Component({
  selector: 'manage-users-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class ManageUsersComponent implements OnDestroy, OnInit {
  title = 'manageusers';
  @ViewChild('warningBox', { static: true })
  warningBox!: ElementRef;

  advanceSearchPanel: boolean = false;
  listClubs: any = [];
  listBranches: any = [];
  listAgentDetail: any = [];
  listAgent: ClubAgent[] = [];
  selectedClub?: any;
  selectedBranch?: string;
  selectedAgent?: string;
  noSearchValueSelectedError: boolean = false;
  agent: string = "";
  branch: string = "";
  club: string = "";
  public modalData: any;
  tableColumns: ConfigColumns[] = [
    { title: 'Email', data: 'email', defaultContent: '' },
    { title: 'First Name', data: 'firstName' },
    { title: 'Last Name', data: 'lastName' },
    { title: 'Role', data: 'roleDescription' },
    { title: 'Branch', data: 'branch' },
    {
      title: 'Edit',
      data: null,
      defaultContent: '',
      orderable: false,
      className: 'text-center',
      render: (data: any, type: any, row: any) => {
        return `<button class="btn btn-link edit-btn" style="padding: 0 !important;" data-guid="${row.agentGuid}">Edit</button>`;
      },
    },
    {
      title: 'Deactivate',
      data: null,
      defaultContent: '',
      orderable: false,
      className: 'text-center',
      render: (data: any, type: any, row: any) => {
        return `<button class="btn btn-link activate-btn" style="padding: 0 !important;" data-guid="${row.agentGuid
          }" data-action="${row.isActive ? 'deactivate' : 'reactivate'}">${row.isActive ? 'Deactivate' : 'Reactivate'
          }</button>`;
      },
    },
  ];
  userRoleDetails?: UserRoleDetails;
  userProfile?: UserProfile;
  searchForm: FormGroup;
  updateStatusModalEvent: EventEmitter<string> = new EventEmitter<string>();
  confirmModalEvent: EventEmitter<string> = new EventEmitter<string>();
  agentModalEvent: EventEmitter<ClubAgent> = new EventEmitter<ClubAgent>();

  constructor(
    private sharedService: SharedService,
    private manageUsersService: ManageUsersService,
    private loaderService: LoaderService,
    private navService: HeaderNavigationService,
    private dataSetterService: DataSetterService,
    private store: Store,
    private branchDetailService: BranchesService,
    private modalService: NgbModal,
  ) {
    this.searchForm = new FormGroup({
      firstName: new FormControl(null, null),
      lastName: new FormControl(null, null),
      email: new FormControl(null, null),
    });
  }

  ngOnInit() {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.userProfile = loginUser.userProfile;
        this.navService.handelUnauthorizedUrl("/ManageUsers", this.userRoleDetails?.NavigationMenuItems);
        this.getAutoClubs();
      }
    });

  }

  async getAutoClubs() {
    let autoClubs = this.dataSetterService.setAutoClubs(this.userRoleDetails?.AutoClubURLList, true);
    this.listClubs = autoClubs;
    if (this.listClubs.length == 1) {
      this.selectedClub = this.listClubs[0];
      this.club = this.selectedClub.autoClubGuid;
      await this.getBranches();
      this.listAgentDetail = [];
    }
    this.refreshDataTable();
  }

  async refreshDataTable() {

    let searchParams: AgentSearchParams = {
      firstName: this.searchForm.value.firstName?.trim() || '',
      lastName: this.searchForm.value.lastName?.trim() || '',
      email: this.searchForm.value.email?.trim() || '',
      autoClubGuid: this.selectedClub?.autoClubGuid ?? null,
      branchGuid: this.selectedBranch,
      agentGuid: this.selectedAgent,
    };
    this.sharedService.emitAgentSearchChange(searchParams);
  }

  async getBranches() {
    await this.branchDetailService.getBranchDetail(this.userRoleDetails?.AgentContactId, this.selectedClub.autoClubGuid).toPromise()
      .then((branches) => {
        if (branches && branches?.status == true) {
          this.listBranches = branches.responseMessage;
        }
      }, (err) => {
        this.listBranches = [];
      })
      .catch((err) => {
        this.listBranches = [];
      });
  }
  async getAgentDetail() {
    await this.manageUsersService.getAgentDetail(this.userRoleDetails?.AgentContactId, this.selectedClub?.autoClubGuid, this.selectedBranch).toPromise().then((resp) => {
      if (resp && resp?.status == true)
        this.listAgentDetail = resp.responseMessage == null ? [] : resp.responseMessage;
    }, (err) => {
      this.listAgentDetail = [];
      this.loaderService.hide();
    })
      .catch((err) => {
        this.listAgentDetail = [];
        this.loaderService.hide();
      });
  }
  async getAgents() {
    let agentsResponse: any = [];
    let searchParams: AgentSearchParams = {
      firstName: this.searchForm.value.firstName,
      lastName: this.searchForm.value.lastName,
      email: this.searchForm.value.email,
      autoClubGuid: this.selectedClub?.autoClubGuid ?? null,
      branchGuid: this.selectedBranch,
      agentGuid: this.selectedAgent,
      recordType: "I",
      loginAgentGuid: this.userRoleDetails?.AgentContactId
    };
    await this.manageUsersService.getAgents(searchParams).toPromise().then((resp) => {
      if (resp)
        agentsResponse = resp;
      this.listAgent = resp == null ? [] : resp.response;
      this.store.dispatch(setAgents({ agents: agentsResponse }));
    }, (err) => {
      this.listAgent = [];
      this.loaderService.hide();
    })
      .catch((err) => {
        this.listAgent = [];
        this.loaderService.hide();
      });

    // this.manageUsersService.getAgents(searchParams).subscribe((resp) => {
    //   this.listAgent = resp == null ? [] : resp.response;
    //   this.store.dispatch(setAgents({ agents: resp }));
    // }, (error) => {
    //   this.listAgent = [];
    //   this.loaderService.hide();
    // });
  }

  onAdvanceSearchSubmit(isSearch?: any) {
    if (isSearch === true) {
      if (!this.hasAnyValue(this.searchForm) && this.selectedAgent == undefined && this.selectedBranch == undefined && this.selectedClub == undefined) {
        this.noSearchValueSelectedError = true;
        setTimeout(() => {
          this.noSearchValueSelectedError = false;
        }, 3000);
        return;
      }
    }
    if (!this.noSearchValueSelectedError) {
      this.refreshDataTable();
    }
  }

  onAdvanceSearchClear() {
    this.noSearchValueSelectedError = false;
    this.searchForm.reset({
      firstName: '',
      lastName: '',
      email: '',
    });
    this.agent = "";
    this.branch = "";
    this.club = "";
    this.selectedAgent = undefined;
    this.selectedBranch = undefined;
    this.selectedClub = undefined;
    this.listClubs = this.dataSetterService.setAutoClubs(this.userRoleDetails?.AutoClubURLList, true);
    if (this.listClubs.length == 1) {
      this.selectedClub = this.listClubs[0];
      this.club = this.selectedClub.autoClubGuid;
    }
  }

  openAddNewAgentModal() {
    let agent: ClubAgent = {
      agentGuid: '',
      autoClubGuid: '',
      branchGuid: '',
      branch: '',
      roleId: 0,
      roleName: '',
      firstName: '',
      lastName: '',
      email: '',
      isActive: false,
      modifiedBy: ''
    }
    this.agentModalEvent.emit(agent);
  }

  openEditModal(data: ClubAgent) {
    this.agentModalEvent.emit(data);
  }

  openUpdateStatusModal(data: any) {
    this.updateStatusModalEvent.emit(data);
  }

  onUpdateConfirm(data: any) {
    let activate: boolean = true;
    let message: string = 'User has been reactivated successfully...';

    if (data.action === 'deactivate') {
      activate = false;
      message = 'User has been deactivated successfully...';
    }

    this.loaderService.show();
    this.manageUsersService.updateStatus(data.id, activate, this.userProfile?.LoggedUser).subscribe(() => {
      this.loaderService.hide();
      this.refreshDataTable();
      this.confirmModalEvent.emit(message);
    });
  }

  onAgentSuccess(message: any) {
    this.refreshDataTable();
    this.confirmModalEvent.emit(message);
  }

  onAgentFailed(message: any) {
    this.refreshDataTable();
    this.open(this.warningBox, { Error: message });
  }

  onClubSelect(e: Event) {
    this.selectedAgent = undefined;
    this.selectedBranch = undefined;
    const selectElement = e.target as HTMLSelectElement;
    const selectedCode = selectElement.value;
    const selectedClub = this.listClubs.find((club: any) => club.autoClubGuid === selectedCode);
    this.selectedClub = selectedClub;
    this.getBranches();
    this.listAgentDetail = [];
  }

  onBranchSelect(e: Event) {
    this.selectedAgent = undefined;
    const selectElement = e.target as HTMLSelectElement;
    const selectedBranch = selectElement.value;
    this.selectedBranch = selectedBranch;
    this.getAgentDetail();
  }

  onAgentSelect(e: Event) {
    const selectElement = e.target as HTMLSelectElement;
    const agentGuid = selectElement.value;
    this.selectedAgent = agentGuid;
  }

  hasAnyValue(formGroup: FormGroup): boolean {
    return Object.keys(formGroup.controls).some(key => {
      const control = formGroup.get(key);
      return control && control.value && control.value.trim() !== '';
    });
  }
  ngOnDestroy(): void { }
  toggle() {
    this.searchForm.reset({
      firstName: '',
      lastName: '',
      email: '',
    });
    if (this.advanceSearchPanel) {
      this.advanceSearchPanel = false;
    } else {
      this.advanceSearchPanel = true;
    }
  }

  open(content: any, rowdata: any) {
    const modal: NgbModalRef = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    this.modalData = rowdata;
    modal.result.then(
      (result) => {
      },
      (reason) => {
      },
    );
  }
}
