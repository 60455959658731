import { Injectable } from '@angular/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DateService } from '../services/date-updated.service';
import { DeletedPromoHeaders, ExpPromoHeaders } from './export.configs';

@Injectable({
  providedIn: 'root',
})
export class ExportService {
  public csvOptions: any = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: false,
    showTitle: false,
    title: '',
    useBom: true,
    noDownload: false,
    headers: [],
  };

  constructor(private dateService: DateService) {}

  downloadExpiredPromoCSV(data: any) {
    this.csvOptions.headers = [
      ExpPromoHeaders.Id,
      ExpPromoHeaders.PromoCode,
      ExpPromoHeaders.Description,
      ExpPromoHeaders.EffectiveDate,
      ExpPromoHeaders.ExpirationDate,
      ExpPromoHeaders.DiscountRate,
      ExpPromoHeaders.ClubName,
      ExpPromoHeaders.Status,
    ];

    let fileName =
      'PromoCodesToExpire' +
      this.dateService.getDDMMYYYYFromDate(new Date()).replace(/[/.]/g, '');

    new AngularCsv(data, fileName, this.csvOptions);
  }

  downloadDeletedPromoCSV(data: any) {
    this.csvOptions.headers = [
      DeletedPromoHeaders.Id,
      DeletedPromoHeaders.PromoCode,
      DeletedPromoHeaders.Description,
      DeletedPromoHeaders.ActivityType,
      DeletedPromoHeaders.ActivityDate,
      DeletedPromoHeaders.ActivityBy,
    ];

    let fileName =
      'DeletedPromoCodes' +
      this.dateService.getDDMMYYYYFromDate(new Date()).replace(/[/.]/g, '');

    new AngularCsv(data, fileName, this.csvOptions);
  }
}
