<section class="box-link mt-4">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-6 col-lg-3">
        <a href="/refunds" class="box-link-item d-flex active">
          <i class="fas fa-comment-dollar font-size-36"></i>
          <span class="ml-2"> Manage Refunds </span>
        </a>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <a href="/managebranches" class="box-link-item d-flex">
          <i class="fas fa-draw-polygon font-size-36"></i>
          <span class="ml-2"> Manage Branches </span>
        </a>
      </div>
      <div class="col-sm-6 col-md-6 col-lg-3">
        <a href="/promocode" class="box-link-item d-flex">
          <i class="fas fa-gift font-size-36"></i>
          <span class="ml-2"> Manage Promo Codes </span>
        </a>
      </div>

      <div class="col-sm-6 col-md-6 col-lg-3">
        <a href="/manageusers" class="box-link-item d-flex">
          <i class="fas fa-users font-size-36"></i>
          <span class="ml-2"> Manage Users </span>
        </a>
      </div>
    </div>
  </div>
</section>
