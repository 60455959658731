<app-loader>Please wait while we process your payment. This may take up to 30 second.
  Please do note press the back arrow to avoid risk of duplicate
  charges.</app-loader>

<section class="mb-5">
  <div class="container text-center mt-5">
    <div class="col-md-6 mx-auto">
      <p class="font-size-20">{{ heading }}</p>
    </div>
    <div class="col-md-8 col-lg-6 mx-auto">
      <h1 class="font-lg">{{ additionalAmount | currency }}</h1>
      <p>{{ subHeading }}</p>
      <form [formGroup]="policyPaymentsForm">
        <ng-container formArrayName="Payments">
          <ng-container *ngFor=" let item of payments.controls; let i = index ">
            <ng-container [formGroupName]="i">
              <select class="form-control" formControlName="PaymentType"
                [ngClass]="{'is-invalid': submitted && item.get('PaymentType')?.errors}"
                [attr.disabled]="isCashDisabled ? '' : null">
                <option value="">{{ dropDownText }}</option>
                <option value="PC">Cash</option>
                <option value="CC">Online</option>
              </select>
              <div *ngIf="submitted && item.get('PaymentType')?.errors" class="invalid-feedback">
                <div *ngIf="item.get('PaymentType')?.errors?.['required']">
                  {{ dropDownTextValidationMsg }}
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </form>
      <div class="row">
        <div class="col-md-12 mt-4 text-center btn-footer">
          <button class="btn btn-secondary btn-full-sm" (click)="backPolicySummary()">
            Cancel
          </button>
          <button class="btn btn-primary btn-full-sm" (click)="cashReceiptClick()">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- CashReceipt  Box POP UP --->
<ng-template #cashReceipt let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">CASH</h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <section class="mb-5">
          <div class="container text-center mt-5">
            <div class="col-md-2 mx-auto"></div>
            <div class="col-md-8 mx-auto">
              <p>
                <strong>{{ modalData.Heading }}</strong>
              </p>
              <form [formGroup]="paymentRecieptForm">
                <ng-container formArrayName="items" *ngFor=" let item of items.controls ; let i = index ">
                  <ng-container [formGroupName]="i">
                    <div class="form-group col-md-12">
                      <input type="text" class="form-control" id="" placeholder="Payment Transaction Number" value=""
                        formControlName="CashReceiptNumber"
                        [ngClass]="{ 'is-invalid': recieptSubmitted && item.get('CashReceiptNumber')?.errors }" />
                      <div *ngIf="recieptSubmitted && item.get('CashReceiptNumber')?.errors" class="invalid-feedback">
                        <div *ngIf="item.get('CashReceiptNumber')?.errors?.['required']">Please enter the Receipt
                          Number.</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 mt-2 text-center">
                        <button class="btn btn-primary btn-full-sm" (click)="popupCashReceiptClick('update')">
                          Submit
                        </button>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </form>
            </div>
            <div class="col-md-2 mx-auto"></div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModal()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>

<!-- Warning Box POP UP --->
<ng-template #warningBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <ul *ngIf="modalData.Error.length > 0">
            <li *ngFor="let error of modalData.Error">
              {{error}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModal()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>

<!-- Confirmation Box POP UP --->
<ng-template #confirmationBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-thumbs-up" aria-hidden="true"></i> Confirmation
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModalConfirmation()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Msg }} ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModalConfirmation()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>

<ng-template #paycashconfirmationBox let-modal let-c="close" let-d="dismiss" data-backdrop="static"
  data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-thumbs-up" aria-hidden="true"></i> Confirmation
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="clspayincashcofrmBox()">
      &times;
    </button>
  </div>
  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Msg }}</div>
      </div>
    </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="proceedtocashpayment()" data-dismiss="modal">
      Ok
    </button>
  </div>
</ng-template>

<ng-template #alertBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Alert
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Error }}</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModal()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>