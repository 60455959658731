import { Component, ViewChild, ElementRef, Renderer2, } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbDateParserFormatter, } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { PolicyPaymentsService } from './policy-payments.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { CommonParams } from 'src/app/core/commonparams';
import { PortalCodeEnum } from 'src/app/core/enum/sitename-enum';
import { EndorsementGenericService } from 'projects/policyDetails/src/app/shared/services/endorsement-generic.service';
import { PolicyDetailService } from 'projects/policyDetails/src/app/app.services';
import { EventEmitterService } from 'projects/policyDetails/src/app/shared/services/event-emitter.service';
import { MapperService } from 'src/app/core/services/mapper.services';
import { NgxBraintreeService } from 'projects/paymentGateWay/src/app/ngx-braintree.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';

@Component({
  selector: 'policy-payments-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class PolicyPaymentsComponent {
  title = 'policy-payments';
  modalData: any;
  policyStatus: boolean = false;
  submitted = false;
  policyPaymentsForm!: FormGroup;
  paymentRecieptForm!: FormGroup;
  isPaymentsFormLoader = false;
  isPolicyReceiptLoader = false;
  isManageRefundLoader = false;
  movementGuid!: string;
  policyGuid!: string;
  paymentType: any;
  additionalAmount: any;
  recieptSubmitted = true;
  heading: any;
  subHeading: any;
  dropDownText: any;
  dropDownTextValidationMsg: any;
  reinstatementPaymentType!: string;
  public policyId!: string;
  public currentPolicyTxnNum!: Number;
  public currentEndorsementAmount!: number;
  public currentEndoProcessStatus: string = '';
  public currentEndoProcessStatusCode: string = '';
  public currentEndorsementObject: any;
  public siteCoreSettings: any;
  public siteName: string = CommonParams.SiteName;
  public currentEndoTxnStatusCode: string = '';
  public currentEndoTxnTypeCode: string = '';
  public previousEndorsementAmount!: number;
  public currentChargeAmount: any;
  public portalCodeEnum: any;
  public policyPortalCode: string = '';
  public isCashDisabled: boolean = false;
  public currentENData: any = null;
  public sendCOI: any;

  @ViewChild('cashReceipt', { static: true })
  cashReceipt!: ElementRef;

  @ViewChild('warningBox', { static: true })
  warningBox!: ElementRef;

  @ViewChild('alertBox', { static: true })
  alertBox!: ElementRef;

  @ViewChild('confirmationBox', { static: true })
  confirmationBox!: ElementRef;

  @ViewChild('paycashconfirmationBox', { static: true })
  paycashconfirmationBox!: ElementRef;

  constructor(
    private policyPaymentsService: PolicyPaymentsService,
    private renderer: Renderer2,
    private loaderService: LoaderService,
    private modalService: NgbModal,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private pipe: DatePipe,
    private currencyPipe: CurrencyPipe,
    private fb: FormBuilder,
    public endorsementGenericService: EndorsementGenericService,
    public dtaService: PolicyDetailService,
    private eventEmitter: EventEmitterService,
    private mapperService: MapperService,
    private service: NgxBraintreeService,
    private store: Store,
  ) {
    this.portalCodeEnum = PortalCodeEnum;
  }

  async ngOnInit(): Promise<void> {
    this.store.select(loginUser).subscribe(async (loginUser) => {
      if (loginUser) {
        this.submitted = false;
        this.recieptSubmitted = false;
        this.policyGuid = sessionStorage.getItem('policyGuid') || '';
        this.movementGuid = sessionStorage.getItem('movementGuid') || '';
        this.sendCOI = sessionStorage.getItem('sendCoiCheckBoxValue') || false;

        if (sessionStorage.getItem('additionalAmount') != null && sessionStorage.getItem('additionalAmount') != undefined) {
          this.heading = 'The additional amount to be paid by the customer is';
          this.subHeading = 'Select the mode of payment from below';
          this.dropDownText = 'Please select payment type';
          this.dropDownTextValidationMsg = 'Payment type is required';
          this.additionalAmount = sessionStorage.getItem('additionalAmount');
        }

        if (this.additionalAmount == null || this.additionalAmount == undefined) {
          location.href = '/policy-detail';
        }


        if (this.movementGuid != undefined) {
          this.currentENData = await this.dtaService.getData(this.policyGuid, this.movementGuid).toPromise();
          this.populatePolicyData();
        }
      }
    });
    this.policyPaymentsForm = this.policyPaymentsService.policyPaymentsReactiveForm();
  }

  get payments(): FormArray {
    return this.policyPaymentsForm.get('Payments') as FormArray;
  }

  get items(): FormArray {
    return this.paymentRecieptForm.get('items') as FormArray;
  }

  populatePolicyData() {
    if (this.currentENData) {
      this.currentEndorsementObject = JSON.parse(JSON.stringify(this.currentENData));
      this.policyPortalCode = (this.currentENData) ? this.currentENData.autoClub : sessionStorage.getItem('portalcode') || '';
      this.isCashDisabled = this.policyPortalCode == this.portalCodeEnum.RACQ ? true : false;
      this.currentEndorsementAmount = this.additionalAmount;
    }
  }

  cashReceiptClick() {
    this.submitted = false;
    this.recieptSubmitted = false;
    this.isPaymentsFormLoader = true;
    if (this.policyPaymentsForm.invalid) {
      this.isPolicyReceiptLoader = false;
      this.submitted = true;
      this.isPaymentsFormLoader = false;
      return false;
    }

    this.paymentType = this.policyPaymentsForm.get('Payments')?.value.length > 0 ? this.policyPaymentsForm.get('Payments')?.value[0].PaymentType : "";
    if (this.paymentType == "PC") {
      this.paymentRecieptForm = this.policyPaymentsService.createPaymentRecieptForm();
      this.isPaymentsFormLoader = true;
      this.open(this.paycashconfirmationBox, { Msg: "Are you sure you want to Proceed with the changes as Paid in Cash?" });
    }
    else {
      location.href = '/paymentsummary';
    }
  }

  backPolicySummary() {
    location.href = '/policysummary';
  }

  async popupCashReceiptClick(eventType: any) {
    this.isPolicyReceiptLoader = true;
    this.submitted = false;
    this.recieptSubmitted = true;
    if (this.paymentRecieptForm.invalid) {
      return;
    }

    this.loaderService.show();
    if (eventType == 'cancel') {
      //location.href = "";
    }
    else if (eventType == 'continue' || eventType == 'update') {
      if (this.currentEndorsementObject?.premiums?.additionalPremium) {
        let paymentsFormGroup = this.paymentRecieptForm.get('items') as FormGroup;
        let cashReceiptNumber = paymentsFormGroup.controls[0].get('CashReceiptNumber');

        let request = {
          "agentInfo": this.currentENData.agentInfo,
          "policyGuid": this.policyGuid,
          "movementGuid": this.movementGuid,
          "methodType": "CASH",
          "cashReceiptNumber": (cashReceiptNumber != null) ? cashReceiptNumber.value : "",
          "amount": this.additionalAmount,
          "isSendCOI": sessionStorage.getItem("sendCoiCheckBoxValue") || false
        }
        this.modalService.dismissAll();
        let bindRequest = await this.mapperService.mapBindModel(request, "revision");
        await this.policyPaymentsService.bindEndorsement(bindRequest).toPromise()
          .then((response) => {
            if (response.status != "Failed") {
              this.loaderService.hide();
              this.onSuccessIssue(response);
              // if(sessionStorage.getItem('sendCoiCheckBoxValue') == 'true'){
              //   var sendPolicyObj: any = {
              //     movementGuid: this.movementGuid,
              //     movementType: 'EN',
              //   };
              //   this.service.sendPolicy(sendPolicyObj).subscribe((response) => {              
              //   });
              // }
              // setTimeout(() => {
              //   this.loaderService.hide();
              //   this.onSuccessIssue(response);
              //   this.open(this.warningBox, { Error: response.status });
              // }, 2000);
            }
            else if (response.status == "Failed") {
              this.recieptSubmitted = false;
              this.isPolicyReceiptLoader = false;
              this.loaderService.hide();
              this.open(this.warningBox, { Error: response.errors });
            }
          }, (err) => {
            this.recieptSubmitted = false;
            this.isPolicyReceiptLoader = false;
            this.loaderService.hide();
            this.open(this.warningBox, { Error: "Something went wrong with issue" });
          })
          .catch((err) => {
            this.recieptSubmitted = false;
            this.isPolicyReceiptLoader = false;
            this.loaderService.hide();
            this.open(this.warningBox, { Error: "Something went wrong with issue" });
          });
      }
      else {
        console.log(this.currentEndorsementObject?.premiums?.additionalPremium + " " + this.currentEndorsementAmount)
        this.recieptSubmitted = false;
        this.isPolicyReceiptLoader = false;
        this.loaderService.hide();
        this.open(this.alertBox, { Error: "There is mismatch in additional amount. Please contact Tokio Marine support." });
      }
    }
  }

  open(content: any, rowdata: any) {
    const modal: NgbModalRef = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    this.modalData = rowdata;
    modal.result.then(
      (result) => {
      },
      (reason) => {
      },
    );
  }

  closeModal() {
    this.modalService.dismissAll();
    // location.href = '/policy-detail';
  }
  closeModalConfirmation() {
    location.href = '/policy-detail';
  }

  getPriceFixed(num: any, fixed: any) {
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  clspayincashcofrmBox() {
    this.modalService.dismissAll();
  }

  proceedtocashpayment() {
    this.modalService.dismissAll();
    this.open(this.cashReceipt, {
      Heading: 'Enter the receipt number generated here',
    });
  }

  onSuccessIssue(resp: any) {
    if (resp != undefined && resp.status) {
      this.modalService.dismissAll();
      this.recieptSubmitted = false;
      sessionStorage.setItem('originalMovementGuid', this.movementGuid);
      this.open(this.confirmationBox, { Msg: resp.status });
    }
    else {
      this.open(this.cashReceipt, { Error: resp });
    }
  }
}
