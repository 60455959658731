<div class="card-gray" *ngIf="policyTripDetail != undefined">
  <h1 class="card-gray-header">TRIP DETAILS</h1>
  <div class="card-edit" *ngIf="isUpdateENCalled && isEditBtnRequired">
    <a href="javascript:void(0);">
      <i class="far fa-edit" href="javascript:void(0);" data-toggle="modal" (click)="openModal()"></i>
    </a>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">Destination</div>

        <div class="col-6 text-break text-right cursor-pointer" *ngIf="countrycod.split(',').length - 1 > 0"
          (click)="toggleMultipleCountry()">
          <strong>Multiple {{ toggleMultiple ? '-' : '+' }}</strong>
          <div [ngStyle]="{ display: toggleMultiple == false ? 'none' : 'block' }"
            *ngFor="let destination of countrycod.split(',')">
            <div class="left"></div>
            <div class="right">
              <strong>{{ destination }}</strong>
            </div>
          </div>
        </div>

        <div class="col-6 text-break text-right">
          <div *ngIf="countrycod.split(',').length - 1 == 0">
            <strong>{{ countrycod }}</strong>
          </div>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">Leaving On</div>
        <div class="col-6 text-right">
          {{
          policyTripDetail.fromDate
          }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">Returning On</div>
        <div class="col-6 text-right">
          {{
          policyTripDetail.toDate
          }}
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">Duration</div>
        <div class="col-6 text-right">{{ Duration }} days</div>
        <div class="col-12" [hidden]="!isMessageVisible">
          <div class="alert alert-success mt-3" role="alert">
            {{ msg }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</div>

<ng-template #dialogContentTemplate>
  <div *ngIf="isLoader">
    <div class="clearfix"></div>
    <div class="overlay-container position-absolute"></div>
    <div class="loader"></div>
  </div>
  <!-- <div class="modal fade"  data-backdrop="static" data-keyboard="false"> -->
  <form [formGroup]="travelerForm">
    <div class="modal-body">
      <div class="col-md-6 mx-auto text-center">
        <div class="text-center mb-4 mt-4">
          <h5 class="mb-4">Trip Details</h5>
        </div>
        <div class="form-row">
          <div class="form-group mx-auto col-12">
            <angular2-multiselect [settings]="settings" [data]="countryList" [(ngModel)]="selectedItems"
              (onSelect)="onItemSelect($event)" (onDeSelect)="OnItemDeSelect($event)"
              (onGroupSelect)="onGroupSelect($event)" (onSelectAll)="onSelectAll($event)"
              (onDeSelectAll)="onDeSelectAll($event)" (onGroupDeSelect)="onGroupDeSelect($event)"
              formControlName="CountryName"
              [ngClass]="{ 'is-invalid': submitted && f['CountryName'].errors }"></angular2-multiselect>
            <div class="errorPolicy" *ngIf="isMatchedDisabled">
              {{ errorMsg }}
            </div>
            <div class="errorPolicy" *ngIf="hasNotAllowedTravelDestination()">
              <div class="dntcountries" [innerHTML]="formatNotAllowedCountriesMessage()"></div>
            </div>
          </div>

          <div class="form-group col-6">
            <div class="input-group date-field nopadding required" id="start-date">
              <input type="text" class="form-control input-sm col-12" formControlName="TripFromDate" ngbDatepicker
                #d1="ngbDatepicker" [maxDate]="maxFromDate" (keyup)="!isTravelstrartdtElapsed ? onInputFromDate() : ''"
                [minDate]="isTravelstrartdtElapsed ? null : minvalidDate"
                (dateSelect)="dateValidate(); validateTravelToDate()"
                (click)="isTravelstrartdtElapsed ? null : d1.toggle()" [readonly]="isTravelstrartdtElapsed" />
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="far fa-calendar-alt" (click)="isTravelstrartdtElapsed ? null : d1.toggle()"
                    type="button"></i>
                </span>
              </div>
              <div class="invalid-feedback" style="display: block"
                *ngIf=" f['TripFromDate'].touched && f['TripFromDate'].errors?.['required'] ">
                Please select the Leaving On date.
              </div>

              <div class="errorPolicy" *ngIf="isDateValidatedErr">
                {{ errorMsgDate }}
              </div>
            </div>
          </div>
          <div class="form-group col-6">
            <div class="input-group date-field nopadding required" id="start-date">
              <input type="text" class="form-control input-sm col-12" formControlName="TripToDate" ngbDatepicker
                #d2="ngbDatepicker" [maxDate]="isAnnualMultiTrip ? null : maxValidDate" (keyup)="onInputToDate()"
                (dateSelect)="validateTravelToDate()" [minDate]="isAnnualMultiTrip ? null : minvalidDate"
                (click)="isAnnualMultiTrip ? null : d2.toggle()" [readonly]="isAnnualMultiTrip" />
              <!-- [markDisabled]="isAnnualMultiTrip"> -->
              <div class="input-group-append">
                <span class="input-group-text">
                  <i class="far fa-calendar-alt" (click)="isAnnualMultiTrip ? null : d2.toggle()" type="button"></i>
                </span>
              </div>
              <!-- <div *ngIf="submitted && f.TripToDate.errors" class="invalid-feedback" style="display: block;">
                  <div *ngIf="f.TripToDate.errors.required">Please select the Returning On date.</div>
              </div> -->
              <div class="invalid-feedback" style="display: block" *ngIf="
                  f['TripToDate'].touched &&
                  f['TripToDate'].errors?.['required']
                ">
                Please select the Returning On date.
              </div>
              <div class="errorPolicy" *ngIf="
                  isDateValidatedErr_return &&
                  !(
                    f['TripToDate'].touched &&
                    f['TripToDate'].errors?.['required']
                  )
                ">
                {{ errorMsgDate_return }}
              </div>
            </div>
          </div>

          <div class="form-group mx-auto col-12" *ngIf="covidpopshow"> <input type="checkbox" checked disabled /> <span
              class="glyphicon glyphicon-ok"></span> {{ PandemicAcknowledgementLabel }} </div>
        </div>
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" data-dismiss="modal" (click)="closeModal1()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary btn-full-sm" [disabled]="isMatchedDisabled"
          (click)="updateTripDetails()"> Save Changes </button>
      </div>
    </div>
  </form>
</ng-template>