import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CountriesState } from "./country.reducer";

export const selectCountriesState = createFeatureSelector<CountriesState>('countries');

export const selectCountries = createSelector(
  selectCountriesState,
  (state: CountriesState) => state.countries 
);

export const isCountriesPresent = createSelector(
    selectCountries,
  (countries) => !!countries
);