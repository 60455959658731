import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { FormBuilder, } from '@angular/forms';
import { EndPoint } from '../../../../src/app/core/services/endpoint';
 import { AgentResponse, AgentRoleResponse, AgentSearchParams, ClubAgentRequest, ClubAgentRequestResponse, } from './app.model';
import { RoleTextService } from '../../../../src/app/core/role/setroletext';
import { PortalCodeService } from '../../../../src/app/core/portalcode/portalcode';
import { HttpCoreService } from '../../../../src/app/core/services/http-core.service';
import { CacheService } from 'projects/manage-branches/src/app/caching.service';
import { LocalCacheService } from 'projects/policyDetails/src/app/policy-details-pre-existing/verisk/localCache.service';
import { Branches } from 'src/app/core/store/user/user.model';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';

@Injectable({
  providedIn: 'root',
})
export class ManageUsersService {

  httpHeaders = {};
  userRoleDetails?: UserRoleDetails;

  constructor(
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    private roleTextService: RoleTextService,
    private portalCodeService: PortalCodeService,
    private httpService: HttpCoreService,
    private cacheService: CacheService,
    private localCacheService: LocalCacheService,
    private store: Store,
  )
  { 

    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.httpHeaders = {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json; charset=utf-8'
            },
          ),
        };
      }
    })
  }

  getSiteCoreDataTableList(gridName: any) {
    return this.http.get(
      EndPoint.API_ENDPOINT + '/PASDataService/DataTable/?gridname=' + gridName,
    );
  }

  getAutoClub(): Observable<any> {
    let cacheKey = 'autoClubList';
    let cacheTTL = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    const cachedData = this.cacheService.getCache(cacheKey);
    if (cachedData) {
      return of(cachedData);
    } else {
      return new Observable((observer) => {
        this.http
          .get<any>(EndPoint.LTX_ENDPOINT + 'api/pas/configuration/autoclubs', this.httpHeaders)
          .subscribe(
            (data) => {
              this.cacheService.setCache(cacheKey, data, cacheTTL);
              observer.next(data);
              observer.complete();
            },
            (error) => {
              observer.error(error);
            },
          );
      });
    }
  }

  getBranches(autoClubGuid: string, agentContactId: any): Observable<Branches> {
    let paginationParams: any = {
      isPaginationEnabled: true,
      pageNo: 0,
      pageSize: 1000,
      autoClubGuid: autoClubGuid,
      loginAgentGuid: agentContactId
    };

    return this.http.post<Branches>(
      EndPoint.LTX_ENDPOINT + 'api/pas/branch/search',
      paginationParams,
      this.httpHeaders,
    );
  }
  getAgentDetail(agentContactId: any, autoClubGuid?: any, branchGuid?: any) {
    return this.http.get<any>(
      `${EndPoint.LTX_ENDPOINT + EndPoint.getAgentDetail}${agentContactId}&autoClubGuid=${autoClubGuid}&branchGuid=${branchGuid}`, this.httpHeaders,
    );
  }
  getAgents(agentParams: AgentSearchParams): Observable<AgentResponse> {
    return this.http.post<AgentResponse>(
      EndPoint.LTX_ENDPOINT + 'api/pas/agent/search',
      agentParams,
      this.httpHeaders,
    );
  }

  createAgent(data: ClubAgentRequest, token: any): Observable<ClubAgentRequestResponse> {
    return this.http.post<ClubAgentRequestResponse>(
      EndPoint.LTX_ENDPOINT + 'api/pas/agent/update',
      data,
      {
        headers: new HttpHeaders({
          accept: '*/*',
          'Content-Type': 'application/json'
        }),
      },
    );
  }

  updateStatus(guid: string, active: boolean, loggedUser: any): Observable<any> {
    loggedUser = loggedUser.toUpperCase();
    return this.http.post<any>(
      EndPoint.LTX_ENDPOINT + 'api/pas/agent/update/status',
      {
        agentGUID: guid,
        isActive: active,
        modifiedBy: loggedUser,
      },
      {
        headers: new HttpHeaders({
          accept: '*/*',
          'Content-Type': 'application/json'
        }),
      },
    );
  }

  getAgentRoles(clubCode: string): Observable<AgentRoleResponse> {
    return this.http.get<AgentRoleResponse>(
      EndPoint.LTX_ENDPOINT + 'api/pas/agent/role?clubCode=' + clubCode,
      this.httpHeaders,
    );
  }
}
