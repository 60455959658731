import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  setCache(key: string, data: any, ttl: number): void {
    const now = new Date().getTime();
    const item = {
      data,
      expiry: now + ttl,
    };
    sessionStorage.setItem(key, JSON.stringify(item));
  }

  getCache(key: string): any | null {
    const itemStr = sessionStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date().getTime();
    if (now > item.expiry) {
      sessionStorage.removeItem(key);
      return null;
    }
    return item.data;
  }

  clearCache(key: string): void {
    sessionStorage.removeItem(key);
  }
}