import { Injectable, OnInit } from "@angular/core";
import { Store } from '@ngrx/store';
import { DataBusService } from "projects/policyDetails/src/app/services/databus.service";
import { PolicyDetailService } from "projects/policyDetails/src/app/app.services";
import { isPolicyDataPresent, selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { Policy } from "../store/policy/policy.model";
import { setPolicy } from 'src/app/core/store/policy/policy.actions';
import { isEndorsementDataPresent, selectCurrentEndorsement } from 'src/app/core/store/endorsement/endorsement.selectors';
import { setEndorsement, updateEndorsement, discardEndorsement } from 'src/app/core/store/endorsement/endorsement.actions';
import { Countries } from "../store/country/country.model";
import { setCountries } from "../store/country/country.actions";
import { isCountriesPresent, selectCountries } from "../store/country/country.selectors";
import { Endorsement } from "../store/endorsement/endorsement.model";
import { EndPoint } from "./endpoint";

@Injectable({
  providedIn: 'root',
})
export class StoreGenericService implements OnInit {
  public emptyPolicy: string = "Default Data";
  public movementGuid: string = "";
  public currentPolicyInStore: any;
  public IsPolicyInStore: boolean = false;
  public currentENInStore: any;
  public IsENInStore: boolean = false;
  public IsCountriesInStore: boolean = false;
  public currentCountriesInStore: any;
  public stateList: any;


  constructor(
    private dataBusService: DataBusService,
    public policyDataService: PolicyDetailService,
    private store: Store
  ) {
    this.policyDataService.fetchStaticList(EndPoint.PAS_STATIC_STATES).subscribe((resp) => {
      if (resp.length > 0) {
        this.stateList = resp;
      }
    });
  }

  ngOnInit(): void {
    this.movementGuid = sessionStorage.getItem('movementGuid') || "";

    //Policy
    this.store.select(isPolicyDataPresent).subscribe(present => {
      this.IsPolicyInStore = present;
    });

    this.store.select(selectCurrentPolicy).subscribe(pol => {
      this.currentPolicyInStore = pol;
    });

    //Endorsement
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.IsENInStore = present;
    });

    this.store.select(selectCurrentEndorsement).subscribe(en => {
      this.currentENInStore = en;
    });

    //Countries
    this.store.select(isCountriesPresent).subscribe(present => {
      this.IsCountriesInStore = present;
    });

    this.store.select(selectCountries).subscribe(countries => {
      this.currentCountriesInStore = countries;
    });
  }

  async setPolicyToStore(policy: Policy) {
    var updatedPolicy = await this.updateBeforeSave(policy);
    this.store.dispatch(setPolicy({ policy: updatedPolicy }));
  }

  async setENToStore(endorsement: Endorsement) {
    var updatedEN = await this.updateBeforeSave(endorsement);
    this.store.dispatch(setEndorsement({ endorsement: updatedEN }));
  }

  setCountriesToStore(countries: Countries) {
    this.store.dispatch(setCountries({ countries: countries }));
  }


  async fetchPolicyDataFromStore() {
    if (this.IsPolicyInStore) {
      return this.currentPolicyInStore;
    }
    else {
      if (!this.IsENInStore) {
        let response = await this.dataBusService.policyData.toPromise();
        if (response != null) {
          this.setPolicyToStore(response);
        }
      }
    }
  }

  async fetchENDataFromStore() {
    if (this.IsENInStore) {
      return this.currentENInStore;
    }
    else {
      if (!this.IsENInStore) {
        let response = await this.policyDataService.getData(this.movementGuid).toPromise();
        if (response != null) {
          this.setENToStore(response);
        }
      }
    }
  }

  async fetchCountriesDataFromStore() {
    let countries: any[] = [];
    if (sessionStorage.getItem('mCountry')) {
      return JSON.parse(sessionStorage.getItem('mCountry') || '');
    }
    else {
      if (this.IsCountriesInStore) {
        countries = this.currentCountriesInStore;
      }
      else if (!this.IsCountriesInStore) {
        let response = await this.policyDataService.getCountryLTX().toPromise();
        if (response != null) {
          countries = response;
          sessionStorage.setItem('mCountry', JSON.stringify(countries));
          this.setCountriesToStore(countries);
        }
      }
    }
    return countries;
  }

  async updateBeforeSave(obj: any) {
    let selectedState: any = [];
    if (this.stateList.length == 0) {
      this.stateList = await this.policyDataService.fetchStaticList(EndPoint.PAS_STATIC_STATES).toPromise();
    }
    selectedState = (this.stateList != null) ? this.stateList.filter((x: { code: string; }) => x.code == obj.state.trim()) : null;

    //1. Destination
    if (obj.destinations && obj.destinations.length > 0) {
      let destinationList: any[] = [];
      let countries: any[] = [];
      if (this.IsCountriesInStore) {
        countries = this.currentCountriesInStore;
      }
      else {
        countries = await this.fetchCountriesDataFromStore();
      }

      obj.destinations.forEach((dest: any) => {
        let destObj = { countryCode: "", countryName: "", ratingRegionName: "" };
        var country = null;
        if(dest.countryName != null){
          country = countries.find((country: any) => country.countryCode == dest.countryCode && country.countryName == dest.countryName);
        }
        else{
          country = countries.find((country: any) => country.countryCode == dest.countryCode);
        }
        destObj.countryCode = country.countryCode;
        destObj.countryName = country.countryName;
        destObj.ratingRegionName = country.ratingRegionName;
        destinationList.push(destObj);
      });
      obj.destinations = destinationList;
    }

    //Traveler 
    if (obj.travelers && obj.travelers.length > 0) {
      let primaryTraveler = obj.travelers.find((trv: any) => trv.role.toLowerCase() == "primary");
      if (primaryTraveler != null && primaryTraveler.address != null) {
        primaryTraveler.address.countryCode = "AUS";
        primaryTraveler.address.state = (selectedState.length > 0) ? selectedState[0].code : '';
      }
    }

    //State
    if (selectedState.length > 0) {
      obj.stateId = selectedState[0].id ?? 0;
    }

    return obj;
  }

}