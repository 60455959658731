import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, } from '@angular/common/http';
import { Observable, catchError, of, tap, switchMap, throwError } from 'rxjs';
import { GlobalService } from './global.service';
import { LocalCacheService } from './localCache.service';
import moment from 'moment';
import { environment } from 'src/environments/environment';
import { MedicalAccept, MedicalDeclaration, MedicalPremium, VeriskResponse } from './verisk.model';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { EndPoint } from 'src/app/core/services/endpoint';

@Injectable({ providedIn: 'root' })
export class MedicalService {
  private siteName = sessionStorage.getItem('portalcode') || '';
  private medicalUrl = EndPoint.medicalUrl; // URL to web api
  private decryptUrl = EndPoint.decryptUrl;
  private veriskReCalculateUrl = EndPoint.veriskReCalculateUrl;
  private refreshTokenUrl = EndPoint.refreshTokenUrl;
  isRefreshingToken = false;
  userRoleDetails?: UserRoleDetails;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };
  private veriskInternalObj = {
    username : '',
    vrrInternal : ''
  };
  
  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private localCacheService: LocalCacheService,
    private store: Store
  ) {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
      }
    })
    this.siteName = (this.siteName != '') ? this.siteName : sessionStorage.getItem('portalcode') || '';
    this.fetchVeriskObject();
  }

  medicalDeclaration(parameters: MedicalDeclaration): Observable<any> {
    return this.http.post(
      `${this.medicalUrl}/declaration`,
      parameters,
      {
        headers: new HttpHeaders({
          'accept': '*/*',
          'Content-Type': 'application/json'
        }),
      },
    );
  }

  acceptMedical(parameters: MedicalAccept): Observable<any> {
    return this.http.post(
      `${this.medicalUrl}/accept`,
      parameters,
      {
        headers: new HttpHeaders({
          'accept': '*/*',
          'Content-Type': 'application/json'
        }),
      },
    );
  }

  addMedicalPremium(parameters: MedicalPremium): Observable<VeriskResponse> {
      return this.http.post<VeriskResponse>(
        `${this.medicalUrl}/premium`,
        parameters,
        {
          headers: new HttpHeaders({
            'accept': '*/*',
            'Content-Type': 'application/json'
          }),
        },
      );
  }

  decryptXMLData(): Observable<any> {
    this.fetchVeriskObject();
    const details = this.globalService.getCurrentAssessmentDetails();
    const xmlResult = details?.xmlResult;
    if (!xmlResult) return of('');
    const decrypthttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization:
          'Bearer ' + this.localCacheService.getLocalStorage('gatewayToken'),
          'X-Vrr-Auth': this.veriskInternalObj.vrrInternal,
      }),
    };
    const parameter = {
      EncryptedXml: xmlResult,
    };
    return this.http
      .post<any>(this.decryptUrl, parameter, decrypthttpOptions)
      .pipe(
        tap((_) => {
          console.log('decrypt data: ', _);
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return this.handle401Error(parameter);
          } else {
            return throwError(error);
          }
        }),
      );
  }

  buidMedicalVeriskUserSettings(result: any = ''): any {
    let age = 0;
    const quoteDetail = this.localCacheService.getSessionStorage('quoteDetail');
    const currentAssessment = this.localCacheService.getSessionStorage('currentAssessment');
    let countryList : any = [];
    countryList = sessionStorage.getItem('mCountry') ? JSON.parse(sessionStorage.getItem('mCountry') || '{}') : "";
    this.fetchVeriskObject();

    let helixScore = this.findMaxRegionId(quoteDetail.destinations,countryList);
    if (currentAssessment === 'primary' || currentAssessment === 'secondary') {
      age =
        currentAssessment === 'primary'
          ? quoteDetail.ages[0]
          : quoteDetail.ages[1];
    } else {
      const travelerGuid = this.localCacheService.getSessionStorage('veriskTravelerGuid')
      const traveler = quoteDetail.travelers.find(((traveler: any) => traveler.travelerId === travelerGuid));
      const dob = traveler.dateOfBirth;
      age = this.calculateDepAge(dob);
    }
    return {
      $G_ShowClasses: '3,4',
      $G_IsAnnual: quoteDetail.isSingleTrip ? 0 : 1, //dynamic 0 for single trip
      $G_DirectlyLinked: true,
      $G_Regions: helixScore.toString(), //TODO dynamic??
      $G_AllowWidgetBMIMetricsControl: true,
      $G_Username: this.veriskInternalObj.username, // dynamic as of now use this for testing BB3TokioMarineRACVAuTravelUAT / BB3TokioMarineW2CAusTravelUAT
      $G_AllowSavePartialDeclaration: 'false',
      $G_IndirectlyLinked: false,
      $G_LeadTime: 0,
      $G_CancellationCostId: 1,
      $G_IsWinterSport: age > 85 ? 1 : (quoteDetail.isSking || quoteDetail.isSking == "") ? 1 : 2, //dynamic
      $G_IsMetric: true,
      $G_TripDuration: this.calculateDaysBetweenDates(quoteDetail), //dynamic diff from start date and to date
      $G_Age: age, //dynamic traveler age
      $G_Locale: 'Aus',
      // "$G_ResultFormat":"xml",
      $G_ScreeningData: result ?? '', //Dynamic initially it will be empty else supply xml
    };
  }

  veriskReCalculate(result: any): Observable<any> {
    const userSettings = this.buidMedicalVeriskUserSettings(result);
    let options = this.httpOptions;
    options.headers = options.headers.set(
      'Authorization',
      'Bearer ' + this.localCacheService.getLocalStorage('gatewayToken'),
    );
    options.headers = options.headers.set(
      'X-Vrr-Auth',
      this.localCacheService.getLocalStorage('bbJWT'),
    );
    return this.http
      .post<any>(this.veriskReCalculateUrl, userSettings, options)
      .pipe(
        tap((_) => {
          console.log('recalculate data');
        }),
        catchError(
          this.globalService.handleError<any>('failed to recalculate data', []),
        ),
      );
  }

  private calculateDaysBetweenDates(quoteDetail: any) {
    const depDate = moment(quoteDetail.fromDate, 'DD/MM/YYYY');
    const rtnDate = moment(quoteDetail.toDate, 'DD/MM/YYYY');
    return rtnDate.diff(depDate, 'days');
  }

  private calculateDepAge(dateOfBirth: string) {
    const birthDate = moment(dateOfBirth, 'DD/MM/YYYY').toDate();
    console.log(
      'birthDate',
      birthDate,
      birthDate.getFullYear(),
      birthDate.getMonth(),
    );
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  private handle401Error(payload: any): Observable<any> {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true;
      return this.refreshToken().pipe(
        switchMap(() => {
          return this.decryptXMLData();
        }),
        catchError((err) => {
          this.isRefreshingToken = false;
          return throwError(err);
        }),
      );
    }
    return of();
  }

  private refreshToken(): Observable<any> {
    this.siteName = (this.siteName != '') ? this.siteName : sessionStorage.getItem('portalcode') || '';

    return this.http
      .post<any>( this.refreshTokenUrl + this.siteName, { grant_type: 'password' }, this.httpOptions, )
      .pipe(
        switchMap((token: any) => {
          this.isRefreshingToken = false;
          localStorage.setItem('gatewayToken', token.access_token);
          localStorage.setItem('bbJWT', token.client_jwt);
          const currentTimestamp = Date.now();
          localStorage.setItem(
            'lastTokenRetry',
            (currentTimestamp + token.expires_in * 1000).toString(),
          );
          return of(token);
        }),
        catchError((err) => {
          this.isRefreshingToken = false;
          return throwError(err);
        }),
      );
  }

  findMaxRegionId(selectedCountries: any[] , countries : any[]) {
    let regionId = '0';
    let selectedCountryList: any[] = [];
    selectedCountries.forEach((code: any) => {
      if (countries) {
        let selectedCountry: any = countries.find((c: { countryCode: any; }) => c.countryCode == code.countryCode);
        if (selectedCountry) {
          selectedCountryList.push(
            {
              "countryCode": selectedCountry.countryCode,
              "countryName": selectedCountry.countryName,
              "ratingRegionName": selectedCountry.ratingRegionName,
              "helixScore": selectedCountry.helixScore,
              "regionId": selectedCountry.regionId
            });
        }
      }

    });

    regionId = this.getMaxHelixScore(selectedCountryList);
    return regionId;
  }

  getMaxHelixScore(selectedCountryList : any []) {
    let helixScore = '0';
    let helixScoreArr = [];
    if (selectedCountryList.length > 0) {
      for (let val of selectedCountryList) {
        helixScoreArr.push(val.helixScore);
      }
    }
    helixScoreArr.sort(function (a, b) { return a - b; });
    helixScore = helixScoreArr[helixScoreArr.length - 1];
    return helixScore;
  }

  fetchVeriskObject() {
    this.siteName = (this.siteName != '') ? this.siteName : sessionStorage.getItem('portalcode') || '';
    if(this.veriskInternalObj.username == '' && this.veriskInternalObj.vrrInternal == ''){
      switch (this.siteName.toUpperCase()) {
        case "RACV":
          this.veriskInternalObj = {
            username: environment.veriskCredentails.RACV.username,
            vrrInternal: environment.veriskCredentails.RACV.vrrInternal
          }
          break;
        case "W2C":
          this.veriskInternalObj = {
            username: environment.veriskCredentails.W2C.username,
            vrrInternal: environment.veriskCredentails.W2C.vrrInternal
          }
          break;
        case "RACQ":
          this.veriskInternalObj = {
            username: environment.veriskCredentails.RACQ.username,
            vrrInternal: environment.veriskCredentails.RACQ.vrrInternal
          }
          break;
        case "RAA":
          this.veriskInternalObj = {
            username: environment.veriskCredentails.RAA.username,
            vrrInternal: environment.veriskCredentails.RAA.vrrInternal
          }
          break;
        case "RACT":
          this.veriskInternalObj = {
            username: environment.veriskCredentails.RACT.username,
            vrrInternal: environment.veriskCredentails.RACT.vrrInternal
          }
          break;
        case "AANT":
          this.veriskInternalObj = {
            username: environment.veriskCredentails.AANT.username,
            vrrInternal: environment.veriskCredentails.AANT.vrrInternal
          }
          break;
        case "RAC":
          this.veriskInternalObj = {
            username: environment.veriskCredentails.RAC.username,
            vrrInternal: environment.veriskCredentails.RAC.vrrInternal
          }
          break;
      }
    }
  }
}