<div class="combobox">
  <!-- <input type="text" [(ngModel)]="inputItem" (ngModelChange)="getFilteredList()" class="combobox-input" (keyup)="onKeyPress($event)" (blur)="toggleListDisplay(0)" (focus)="toggleListDisplay(1)" [ngClass]="{'error': showError}"> -->
  <span *ngIf="showError" class="error-text"><i>Invalid Selection.</i></span>
  <div class="combobox-options" *ngIf="listHidden">
    <list-item
      *ngFor="let item of list; let i = index"
      (click)="selectItem(i)"
      [ngClass]="{ selected: i === selectedIndex }"
      >{{ item }}</list-item
    >
  </div>
</div>
