/// <reference path="policy-details-pre-existing/verisk/verisk.component.ts" />
import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
// import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { PolicyDetailComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { VeriskComponent } from './policy-details-pre-existing/verisk/verisk.component';
import { PolicyDetailsPolicyComponent } from './policy-details-policy/policy-details-policy.component';
import { PolicyDetailsTripComponent } from './policy-details-trip/policy-details-trip.component';
import { PolicyDetailsTravelCareComponent } from './policy-details-travel-care/policy-details-travel-care.component';
import { PolicyDetailsTravellersComponent } from './policy-details-travellers/policy-details-travellers.component';
import { PolicyDetailsDependentsComponent } from './policy-details-dependents/policy-details-dependents.component';
import { PolicyDetailsPreExistingComponent } from './policy-details-pre-existing/policy-details-pre-existing.component';
import { PolicyDetailsLeftMenuComponent } from './policy-details-left-menu/policy-details-left-menu.component';
import { PolicyDetailsNotesComponent } from './policy-details-notes/policy-details-notes.component';
import { PolicyDetailsPolicyMovementComponent } from './policy-details-policy-movement/policy-details-policy-movement.component';
import { PolicyDetailsSummaryComponent } from './policy-details-summary/policy-details-summary.component';
import { PolicyDetailsPaymentsComponent } from './policy-details-payments/policy-details-payments.component';
import { PolicyDetailsRefundComponent } from './policy-details-refund/policy-details-refund.component';
import { PolicyDetailsAgentsComponent } from './policy-details-agents/policy-details-agents.component';
import { PolicyDetailsQuoteComponent } from './policy-details-quote/policy-details-quote.component';
import { IntegerPartPipe, DecimalPartPipe } from './pipes/split.pipe';
//import { HttpCoreService } from '../../../../src/app/core/services/http-core.service';
import { GlobalService } from './services/global-service';
import { SitecoreItemService } from './services/sitecore-item.service';
import { AppConfig } from './shared/config/app.config';
import { DialogModule } from './dialog/dialog.module';
import { DialogConfig } from './dialog/dialog-config';
import { DataBusService } from './services/databus.service';
import { HttpCoreService } from 'src/app/core/services/http-core.service';
import { PolicyDetailsCancellationComponent } from './policy-details-cancellation/policy-details-cancellation';
import { PolicyDetailsVersionComponent } from './policy-details-versions/policy-details-versions.component';
import { PolicyDiscardComponent } from './policyDiscard/policyDiscard';
import { ComboBoxComponent } from './comboBox/combo-box.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CustomNgbDateParserFormatter } from 'src/app/core/services/customNgbDateParserFormatter';
import { CurrencyPipe } from '@angular/common';

import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { DatePipe } from '@angular/common';

import { ToastrModule } from 'ngx-toastr';
import { InterceptorsService } from 'src/app/core/services/interceptors.service';
import { PolicyVersionModal } from './policy-details-versions/version-modal/version-modal.component';
import { InputValidationDirective } from './shared/directives/input-validation.directive';
import { UtcToShortDatePipe, UtcToLongDatePipe } from './pipes/moment.pipe';

import { LogPublishersService } from 'projects/policyDetails/src/app/shared/logging/log-publisher.service';
import { CommonPipeModule } from 'src/app/core/pipes/app.module';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { FormatNumberPipe } from 'src/app/core/pipes/format-number.pipe';
export { PolicyDetailComponent } from './app.component';
export function initConfig(config: AppConfig) {
  return () => config.load();
}

@NgModule({
  declarations: [
    PolicyDetailComponent,
    PolicyDetailsPolicyComponent,
    PolicyDetailsTripComponent,
    PolicyDetailsCancellationComponent,
    PolicyDiscardComponent,
    PolicyDetailsTravelCareComponent,
    PolicyDetailsTravellersComponent,
    PolicyDetailsDependentsComponent,
    PolicyDetailsPreExistingComponent,
    PolicyDetailsLeftMenuComponent,
    PolicyDetailsNotesComponent,
    PolicyDetailsPolicyMovementComponent,
    PolicyDetailsSummaryComponent,
    PolicyDetailsPaymentsComponent,
    PolicyDetailsRefundComponent,
    PolicyDetailsAgentsComponent,
    PolicyDetailsQuoteComponent,
    PolicyDetailsVersionComponent,
    PolicyVersionModal,
    IntegerPartPipe,
    DecimalPartPipe,
    VeriskComponent,
    ComboBoxComponent,
    InputValidationDirective,
    UtcToShortDatePipe,
    UtcToLongDatePipe,
  ],
  imports: [
    BrowserModule,
    NgbModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    LoaderModule,
    CommonPipeModule,
    AngularMultiSelectModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
  ],
  providers: [
    HttpCoreService,
    FormatNumberPipe,
    GlobalService,
    SitecoreItemService,
    CurrencyPipe,
    DatePipe,
    PortalCodeService,
    LogPublishersService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    DialogConfig,
    DataBusService,
    // OktaCustomAuthService,
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new CustomNgbDateParserFormatter('dd/MM/yyyy'),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true,
    },
  ],
  exports: [PolicyDetailsQuoteComponent, VeriskComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PolicyModule {}
