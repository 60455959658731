import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PolicyDetailService } from '../app.services';
import { from } from 'rxjs';
import { PriceService } from '../../../../../src/app/core/services/price.service';
import { CurrencyPipe } from '@angular/common';
import { CancelEventEmitterService } from '../shared/services/cancel-event-emitter.service';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import {
  PageNameEnum,
  SubComponentNameEnum,
} from 'projects/policyDetails/src/app/enum/page-name-enum';

@Component({
  selector: 'policyDetails-policy-details-payments',
  templateUrl: './policy-details-payments.component.html',
  styleUrls: ['./policy-details-payments.component.css'],
})
export class PolicyDetailsPaymentsComponent implements OnInit {
  paymentList: any;
  policytxnid: string;
  paymentType = 'purchase';
  isPaymentCollapsed = false;
  @Output() onPaymentUpdate: EventEmitter<any> = new EventEmitter();
  isPaymentError = false;
  paymentErrorMessage: any;

  constructor(
    public paymentData: PolicyDetailService,
    private priceService: PriceService,
    private eventCancelEmitService: CancelEventEmitterService,
    private logService: LogService,
  ) {
    this.policytxnid = sessionStorage.getItem('policyGuid') || '';
  }

  ngOnInit() {
    
    this.policytxnid = (this.policytxnid == "") ? sessionStorage.getItem('policyGuid') || '' : this.policytxnid;
    this.eventCancelEmitService.paymentEvent$.subscribe((data) => {
      this.paymentData.payment(this.policytxnid.toString()
      , this.paymentType).subscribe(
        (data) => {
          this.paymentList = data;
          this.emitRefundData(
            this.paymentList && this.paymentList.length > 0 ? this.calculateRefundAmt() : null,
          );
        },
        (err) => {
          this.logService.createErrorLog(
            {},
            err,
            'GetPaymentSummaryDetails',
            PageNameEnum.PolicyDetails + ' ' + SubComponentNameEnum.Payment,
            LogLevel.Error,
          );
        },
      );
    });
  }

  calculateRefundAmt() {
    let payments: Array<any> = this.paymentList;
    if (payments.length) {
      let ccTypePaymentArray: Array<any> = payments.filter(
        (v) => v.methodType.toLowerCase() == 'cc',
      );
      let pcTypePaymentArray: Array<any> = payments.filter(
        (v) => v.methodType.toLowerCase() == 'pc',
      );

      let ccRefundAmt = ccTypePaymentArray
        .map((ccPaymentObj) => ccPaymentObj.amount)
        .reduce((a, b) => a + b, 0);
      let pcRefundAmt = pcTypePaymentArray
        .map((pcPaymentObj) => pcPaymentObj.amount)
        .reduce((a, b) => a + b, 0);
      return { ccRefundAmt: ccRefundAmt, pcRefundAmt: pcRefundAmt };
    } else return { ccRefundAmt: 0, pcRefundAmt: 0 };
  }

  emitRefundData(data: any) {
    this.onPaymentUpdate.emit(data);
  }

  formatprice(value: any) {
    let formattedvalue = this.priceService.getPriceFixed(value, 2);
    return formattedvalue;
  }

  openPolicyPayment() {
    this.policytxnid = sessionStorage.getItem('policyGuid') || '';
    this.paymentData.payment(this.policytxnid, this.paymentType).subscribe(
      (data) => {
        this.paymentList = data;
        this.emitRefundData(
          this.paymentList && this.paymentList.length > 0 ? this.calculateRefundAmt() : null,
        );
      },
      (err) => {
        this.logService.createErrorLog(
          {},
          err,
          'GetPaymentSummaryDetails',
          PageNameEnum.PolicyDetails + ' ' + SubComponentNameEnum.Payment,
          LogLevel.Error,
        );

        this.isPaymentError = true;
        this.paymentErrorMessage = err.error.Message;
      },
    );
    this.isPaymentCollapsed = !this.isPaymentCollapsed;
  }
}
