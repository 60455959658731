import { Injectable } from "@angular/core";
import { BindModel, Endorsement, EndorsementModel } from "../store/endorsement/endorsement.model";
import { AgentInfo } from "../store/policy/policy.model";
import { PolicyDetailService } from "projects/policyDetails/src/app/app.services";
import { EndPoint } from "./endpoint";
import { } from "../userdetails/userprofile";
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails, UserProfile } from 'src/app/core/store/loginUser/loginUser.model';

@Injectable({
    providedIn: 'root',
})
export class MapperService {
    public stateList: any = [];
    userRoleDetails?: UserRoleDetails;
    userProfile?: UserProfile;
    constructor(
        private dataService: PolicyDetailService,
        private store: Store
    ) {
        this.store.select(loginUser).subscribe((loginUser) => {
            if (loginUser) {
                this.userRoleDetails = loginUser.userRoleDetails;
                this.userProfile = loginUser.userProfile;
                this.dataService.fetchStaticList(EndPoint.PAS_STATIC_STATES).subscribe((resp) => {
                    if (resp.length > 0) {
                        this.stateList = resp;
                    }
                });
            }
        });
    }

    async mapEndorsementModel(endorsement: Endorsement, agentType: string, activityType? : string): Promise<EndorsementModel> {
        let obj = {
            policyGuid: endorsement.policyGuid,
            movementGuid: endorsement.movementGuid,
            clubProductGuid: endorsement.clubProductGuid,
            fromDate: endorsement.fromDate,
            toDate: endorsement.toDate,
            ages: endorsement.ages,
            updateComponent: "",
            destinations: endorsement.destinations,
            dependentsCount: endorsement.dependentsCount,
            isSingleTrip: endorsement.isSingleTrip,
            promoCode: endorsement.promoCode,
            autoClub: endorsement.autoClub,
            isClubMember: !endorsement?.isClubMember && endorsement?.membershipNumber?.length > 0 ? true : endorsement?.isClubMember ,
            channel: "Online",
            stateId: await this.getStateFromCode(endorsement.state),
            membershipNumber: endorsement.membershipNumber,
            emergencyContact: endorsement.emergencyContact,
            activityType : (activityType != null && activityType > '') ? activityType : "",
            travelers: await this.updateTravelerDetails(endorsement.state, endorsement.travelers.map(({ medicalInfo, medicalDeclarations, ...keepAttrs }) => keepAttrs)),
            agentInfo: this.mapAgentByType(endorsement.agentInfo, agentType)
        };

        return obj;
    }

    async mapBindModel(bindRequest: any, agentType: string): Promise<BindModel> {
        let obj = {
            agentBranchInfo: this.mapAgentByType(bindRequest.agentInfo, agentType),
            policyGuid: bindRequest.policyGuid ?? '',
            movementGuid: bindRequest.movementGuid ?? '',
            methodType: bindRequest.methodType ?? '',
            nonce: bindRequest.nonce ?? '',
            amount: bindRequest.amount ?? 0,
            cashReceiptNumber: bindRequest.cashReceiptNumber ?? '',
            isSendCOI: bindRequest.isSendCOI.length > 0 && bindRequest.isSendCOI.toString().toLowerCase() == 'true' ? true : false,
        }
        return obj;
    }

    mapAgentByType(agentInfo: AgentInfo[], type: string) {
        var agent: any = {}
        let agentGuid = this.userRoleDetails?.AgentContactId;
        let branchGuid = this.userRoleDetails?.BranchContactId;
        let roleId = 0;
        if (agentInfo && agentInfo.length > 0) {
            if (agent != undefined) {
                agent.agentGuid = agentGuid;
                agent.agentName = "";
                agent.branchGuid = branchGuid;
                agent.branchName = "";
                agent.roleId = roleId;
            }
        }
        else {
            let obj = {
                agentGuid: agentGuid > '' ? agentGuid : "",
                branchGuid: branchGuid > '' ? branchGuid : "",
                roleId: roleId
            }
            agent = obj;
        }
        return agent;
    }

    async getStateFromCode(stateCode: string) {
        let selectedState: any = [];
        if (this.stateList.length == 0) {
            this.stateList = await this.dataService.fetchStaticList(EndPoint.PAS_STATIC_STATES).toPromise();
        }
        if (stateCode != null) {
            selectedState = (this.stateList != null) ? this.stateList.filter((x: { code: string; }) => x.code == stateCode.trim()) : null;
        }
        return (selectedState != null) ? selectedState[0].id : '';
    }

    async updateTravelerDetails(stateCode: string, travelers: any) {
        let primaryTraveler = travelers.filter((x: { role: string; }) => x.role == "primary")[0];
        let address = (primaryTraveler) ? primaryTraveler.address : null;
        if (address != null) {
            address.stateId = await this.getStateFromCode(stateCode);
            delete address.state;
        }
        return travelers;
    }

}