import { Component, OnInit } from '@angular/core';
import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { Store } from '@ngrx/store';
import { LoaderService } from 'src/app/core/services/loader.service';
@Component({
  selector: 'dashboard-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  title = 'dashboard';
  isAuthenticated: boolean = true;
  headerNavigation: any = [];
  listClubs: any = [];

  constructor(public oktaCustomAuth: OktaCustomAuthService,
    private store: Store,
    private loader: LoaderService
  ) { }

  ngOnInit() {
    this.loader.show();
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        let userRoleDetails = loginUser.userRoleDetails;
        if (userRoleDetails.AgentContactId && userRoleDetails.AgentContactId != undefined) {
          this.isAuthenticated = true;
        }
        else {
          this.isAuthenticated = false;
        }
        this.loader.hide();
      }
    });

  }
}
