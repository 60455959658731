<div class="mt-4">
  <div id="accordion">
    <div class="card">
      <div class="card-header" id="h-agent">
        <h5 class="mb-0">
          <button
            class="btn btn-link accordion-note"
            (click)="openPolicyDetailsVersion('Agent')"
            [attr.aria-expanded]="!isAgentCollapsed"
            data-toggle="collapse"
            [attr.data-target]="'#' + agentId"
            aria-expanded="false"
            aria-controls="c-agent"
          >
            <i
              [ngClass]="{
                rotate0: !isAgentCollapsed,
                rotate90: isAgentCollapsed
              }"
              class="fas fa-chevron-circle-right mr-2 rotate90"
            ></i>
            <span>Agents Commission</span>
          </button>
        </h5>
      </div>

      <div
        [id]="agentId"
        class="collapse"
        aria-labelledby="h-agent"
        data-parent="#accordion"
      >
        <div class="card-body" *ngIf="commissionDetails !== undefined">
          <table
            id="datatable1"
            class="table table-striped table-bordered table-dashboard"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th></th>
                <th>Without Tax</th>
                <th>GST</th>
                <th>Stamp Duty</th>
                <th>Total (With Tax)</th>
              </tr>
            </thead>
            <tbody>
              <!--<tr>
                <td><strong>Wholesale</strong></td>
                <td class="text-right">
                  {{
                    getPriceFixed(agentDetails.WholesalePremium, 2) | currency
                  }}
                </td>
                <td class="text-right">
                  {{ getPriceFixed(agentDetails.WholesaleGST, 2) | currency }}
                </td>
                <td class="text-right">
                  {{
                    getPriceFixed(agentDetails.WholesaleStampDuty, 2) | currency
                  }}
                </td>
                <td class="text-right">
                  {{ getPriceFixed(agentDetails.WholesaleTotal, 2) | currency }}
                </td>
              </tr> -->
              <tr>
                <td><strong>Commissions</strong></td>
                <td class="text-right">
                  {{ getPriceFixed(commissionDetails.withoutTax, 2) | currency }}
                </td>
                <td class="text-right">
                  {{ getPriceFixed(commissionDetails.gst, 2) | currency }}
                </td>
                <td class="text-right">
                  {{
                    getPriceFixed(commissionDetails.stampDuty, 2)
                      | currency
                  }}
                </td>
                <td class="text-right">
                  {{
                    getPriceFixed(commissionDetails.totalWithTax, 2) | currency
                  }}
                </td>
              </tr>
              <!-- <tr>
                <td><strong>Retail</strong></td>
                <td class="text-right">
                  {{ getPriceFixed(agentDetails.RetailPremium, 2) | currency }}
                </td>
                <td class="text-right">
                  {{ getPriceFixed(agentDetails.RetailGST, 2) | currency }}
                </td>
                <td class="text-right">
                  {{
                    getPriceFixed(agentDetails.RetailStampDuty, 2) | currency
                  }}
                </td>
                <td class="text-right">
                  {{ getPriceFixed(agentDetails.RetailTotal, 2) | currency }}
                </td>
              </tr> -->
            </tbody>
          </table>
        </div>
        <div class="text-center card-body" *ngIf="commissionLoader">
          <span class="spinner-border text-primary"></span>
        </div>
        <div class="col-12" [hidden]="!isCommissionError">
          <div class="alert alert-success mt-3" role="alert">
            {{ commissionErrorMessage }}
          </div>
        </div>
        <div class="card-body" *ngIf="!isCommissionError && commissionDetails == undefined && !commissionLoader">
          No data is available
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mt-4" *ngIf="isEmail">
  <div id="accordion">
    <div class="card">
      <div class="card-header" id="h-6">
        <h5 class="mb-0">
          <a
            href=""
            class="btn btn-link accordion-note"
            (click)="openPolicyDetailsVersion('Email')"
            [attr.aria-expanded]="!isEmailCollapsed"
            data-toggle="collapse"
            data-target="#c-6"
            aria-expanded="false"
            aria-controls="c-6"
          >
            <i
              [ngClass]="{
                rotate0: !isEmailCollapsed,
                rotate90: isEmailCollapsed
              }"
              class="fas fa-chevron-circle-right mr-2 rotate90"
            ></i>
            <span>Emails</span>
          </a>
        </h5>
      </div>

      <div
        id="c-6"
        class="collapse"
        aria-labelledby="h-6"
        data-parent="#accordion"
      >
        <div *ngIf="Emaildata?.length > 0" class="card-body">
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of Emaildata">
                <td>
                  {{ item.createdDate | utcToShortDatePipe }}
                </td>
                <td> {{ item.description }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" *ngIf="Emaildata?.length == 0">
          No records found
        </div>
        <div class="card-body text-center" *ngIf="Emaildata == null">
          <span class="spinner-border text-primary"></span>
        </div>
      </div>
    </div>
  </div>
</div>
