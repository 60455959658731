/// <reference path="../../../environments/environment.ts" />
/// <reference path="../../../environments/environment.ts" />
import { environment } from '../../../environments/environment';
import { CryptoService } from '../cryptoservice/cryptoservice';

export class EndPoint {
  public static envVersionNumber = "gl7l78b/gkK/peCafX7W3g==";
  public static API_ENDPOINT = 'https://pasqa.tmmatravel.com/api/datagrid';
  public static LTX_ENDPOINT = environment.LTX_END_POINT;
  public static ADDRESIFYAPIKEY = environment.ADDRESIFYAPIKEY;
  public static XAPIKEY = environment.XAPIKEY;


  public static PolicySearch = 'api/pas/policy/search';
  public static getBranchDetail = 'api/pas/branch?loginAgentGuid=';
  public static getAgentDetail = 'api/pas/agent?loginAgentGuid=';
  public static viewPDS = 'api/pas/document/pds/view/';
  public static QuoteSearch = 'api/pas/quotes/search';
  public static PromoCodeSearch = 'api/pas/promocode/search';
  public static RegenerateCOI = '/api/pas/document/coi/regenerate';
  public static ExportHistory = '/api/pas/history/activity/';
  public static ViewCOI = '/api/pas/document/coi/view/';
  public static ResendCOI = '/api/pas/document/coi/resend/';
  public static EmailHistory = '/api/pas/history/email/';
  public static AddNotes = '/api/pas/add';
  public static GetNotes = '/api/pas/allnote?movementId=';
  public static DeleteNotes = '/api/pas/delete';
  public static PaymentSummary = '/api/pas/payment/summary/';
  public static PolicyDetail = '/api/pas/policy/';
  public static CompareEndorsment = '/api/pas/endorsement/compare/';
  public static BindEndorsment = '/api/pas/endorsement/bind';
  public static paymentSummeryRefund = '/api/pas/payment/summary/refunds';
  public static policyCancel = '/api/pas/policy/cancel';
  public static paymentIssueRefund = '/api/pas/payment/issue/refund';
  public static validatePromoCode = '/api/pas/promocode/validate/';
  public static paymentCommission = '/api/pas/payment/commission/';
  public static policyReActivate = 'api/pas/policy/reactivate';
  public static paymentUpdate = '/api/pas/payment/update';
  public static paymentAdd = '/api/pas/payment/add';
  public static paymentDelete = '/api/pas/payment/delete';
  public static securityRole = '/api/pas/agent/securityrole?email=';
  public static decryptUrl = 'https://gatewayuat.verisk.com/rating/au/travel/calculation/decrypt/v2';
  public static veriskReCalculateUrl = 'https://gatewayuat.verisk.com/rating/au/travel/calculation/rescore/v2';

  //EN
  public static PAS_CANCELREASON_LIST = EndPoint.LTX_ENDPOINT +'PASDataService/CancelReasonList/CancelReasons';
  public static API_ADDRESSIFY_AUTOCOMPLETE = environment.ADDRESIFY_AUTOCOMPLETE_URL + EndPoint.ADDRESIFYAPIKEY+'&term=';
  public static API_ADDRESSIFY_ADDRESSPRO = environment.ADDRESSIFY_ADDRESSPRO_URL + EndPoint.ADDRESIFYAPIKEY+'&term=';
  public static PAS_STATIC_LIST = EndPoint.LTX_ENDPOINT +'/PASDataService/StaticList/';
  public static PAS_VALIDATION = EndPoint.LTX_ENDPOINT + '/PASDataService/ValidationMessages/';
  public static PAS_CONFIGURATIONS = EndPoint.LTX_ENDPOINT + '/PASDataService/Configurations/';
  public static PAS_COLORPICKER = EndPoint.LTX_ENDPOINT + '/PASDataService/ColorPicker/';

  public static PAS_STATIC_STATES = EndPoint.PAS_STATIC_LIST + 'States';
  public static PAS_STATIC_MEDICALLABELING = EndPoint.PAS_STATIC_LIST + 'MedicalLabeling';
  public static PAS_STATIC_PRODUCTLABELING = EndPoint.PAS_STATIC_LIST + 'ProductLabeling';
  public static PAS_VALIDATION_MESSAGES = EndPoint.PAS_VALIDATION + 'Validation';
  public static PAS_CONFIGURATIONS_PRODUCTS = EndPoint.PAS_CONFIGURATIONS + 'Products';
  public static PAS_COLORPICKER_THEME = EndPoint.PAS_COLORPICKER + 'Theme';

  public static PAS_COUNTRIES = EndPoint.LTX_ENDPOINT + '/api/pas/configuration/countries';
  public static FETCH_POLICY = EndPoint.LTX_ENDPOINT + '/api/pas/policy';
  public static PAS_ADDPROMO = EndPoint.LTX_ENDPOINT + '/api/pas/promocode/save';
  public static EN_CREATE = EndPoint.LTX_ENDPOINT + '/api/pas/endorsement/create';
  public static EN_DISCARD = EndPoint.LTX_ENDPOINT + '/api/pas/endorsement/discard';
  public static EN_UPDATE = EndPoint.LTX_ENDPOINT + '/api/pas/endorsement/update';
  public static PAS_AGENTS = EndPoint.LTX_ENDPOINT + '/api/pas/agent/search';
  public static PAS_PRODUCTS_PRICING = EndPoint.LTX_ENDPOINT + '/api/pas/product/pricing';
  public static PAS_POLICY_MOVEMENT = EndPoint.LTX_ENDPOINT + '/api/pas/policy';
  public static PAS_BIND_EN = EndPoint.LTX_ENDPOINT + '/api/pas/endorsement/bind';
  public static PAS_BRAINTREE_TOKEN = EndPoint.LTX_ENDPOINT + '/api/pas/token/braintree/';  
  public static PAS_COI_REGENERATE = EndPoint.LTX_ENDPOINT + '/api/pas/document/coi/regenerate'; 
  public static PAS_BRANCH_SEARCH = EndPoint.LTX_ENDPOINT + '/api/pas/branch/search'; 
  public static PAS_CXONE_MASK_URL = EndPoint.LTX_ENDPOINT + '/api/pas/cxone/mask'; 
  public static PAS_CXONE_UNMASK_URL = EndPoint.LTX_ENDPOINT + '/api/pas/cxone/unmask'; 
  public static PAS_SECURITYUSER_ROLE = EndPoint.LTX_ENDPOINT +  EndPoint.securityRole 
  public static refreshTokenUrl =  EndPoint.LTX_ENDPOINT + `/api/pas/token/verisk/`;
  public static medicalUrl =  EndPoint.LTX_ENDPOINT + `/api/pas/medical`;
}
