import { FormGroup, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Utility } from '../shared/utility';
import { DateService } from 'src/app/core/services/date-updated.service';

export class customValidator {
  static MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors?.['mustMatch']) {
        return;
      }
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    };
  }

  public static ageRangeValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      if (typeof control.value !== 'object') {
        return { invalidNumber: true };
      }

      let dobMoment;
      const dateService = new DateService();
      if (control.value.day) {
        dobMoment = dateService.getMomentFromDDMMYYYY(
          `${control.value.day}/${control.value.month}/${control.value.year}`,
        );
      } else {
        dobMoment = dateService.getMoment(control.value);
      }
      const pricingDateString = sessionStorage.getItem('PricingDate');
      let ageInYears = dateService.ageInYears(
        dateService.toDDMMYYYY(dobMoment),
        pricingDateString,
      );

      if (
        control.value !== undefined &&
        (ageInYears <= 17 || ageInYears > 75)
      ) {
        return { ageRange: true };
      }
      return null;
    }
  }

  public static ageW2CRangeValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      let dobMoment;
      const dateService = new DateService();
      if (control.value.day) {
        dobMoment = dateService.getMomentFromDDMMYYYY(
          `${control.value.day}/${control.value.month}/${control.value.year}`,
        );
      } else {
        dobMoment = dateService.getMoment(control.value);
      }
      const pricingDateString = sessionStorage.getItem('PricingDate');
      let ageInYears = dateService.ageInYears(
        dateService.toDDMMYYYY(dobMoment),
        pricingDateString,
      );

      if (
        control.value !== undefined &&
        (ageInYears <= 17 || ageInYears > 110)
      ) {
        return { ageRange: true };
      }
      return null;
    }
  }

  public static ageRangeValidatorForDependent(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      if (typeof control.value !== 'object') {
        return { invalidNumber: true };
      }

      let dobMoment;
      const dateService = new DateService();
      if (control.value.day) {
        dobMoment = dateService.getMomentFromDDMMYYYY(
          `${control.value.day}/${control.value.month}/${control.value.year}`,
        );
      } else {
        dobMoment = dateService.getMoment(control.value);
      }

      const dobMMDDYYYY = dateService.toDDMMYYYY(dobMoment);
      const pricingDateString = sessionStorage.getItem('PricingDate');
      let ageInYears = dateService.ageInYears(dobMMDDYYYY, pricingDateString);

      const currentMonth = dateService.now().month();

      if (
        control.value !== undefined &&
        ((ageInYears < 1 && ageInYears != 0) || ageInYears > 25)
      ) {
        return { ageRange: true };
      } else if (control.value !== undefined && ageInYears == 0) {
        if (!dateService.isValidDateOfBirth(dobMMDDYYYY)) {
          return { ageRange: true };
        }
      }
      return null;
    }
  }

  // Validates phone number
  static numberValidator(number: any): any {
    if (number.pristine) {
      return null;
    }

    //  const NUMBER_REGEXP = /^\d{9}$/;
    //  const NUMBER_REGEXP = /^([0-9]{9})$/;
    const NUMBER_REGEXP = /^([1,2,3,4,5,7,8,9])([0-9]{8})$/;
    const NUMBER_REGEXP_1 = /^([0-9]{10})$/;
    const NUMBER_REGEXP_2 = /^(61)([0-9]{9})$/;

    const NUMBER_REGEXP_NEW = /^[0-9]\d{9}$/;

    // us format phonenumber validator
    const NUMBER_REGEXP_3 = /^\(?([0-9]{3})\)?[- ]?[0-9]{3}?[- ]?([0-9]{4})$/;

    number.markAsTouched();

    let val = number.value.replace(/[^a-zA-Z0-9]/g, '');
    if (NUMBER_REGEXP_NEW.test(val)) {
      return null;
    }
    // else if (NUMBER_REGEXP_2.test(val)) {
    //   return null;
    // } else if ( NUMBER_REGEXP_3.test(val)) {
    //     return null;
    // }
    return {
      invalidNumber: true,
    };
  }

  static numbersOnlyValidator(
    control: AbstractControl,
  ): { [key: string]: boolean } | null {
    if (control.value) {
      const value: string = control.value;
      const valid = value.match(/^[0-9]*$/);
      return valid ? null : { invalidNumber: true };
    }
    return null;
  }

  static noSpecialCharactersValidator(control: AbstractControl) : { [key: string]: boolean } | null {
    if (control.value) {
      const value: string = control.value;
      const valid = value.match(/^[A-Za-z\s',-]+$/);
      return valid ? null : { invalidCharacters: true };
    }
    return null;
  }

  static DateValidator(dateInput: any): any {
    const Date_Regex = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;
    if (dateInput.pristine) {
      return null;
    }
    if (
      dateInput.value != null &&
      dateInput.value !== undefined &&
      dateInput.value !== ''
    ) {
      dateInput.markAsTouched();
      if (dateInput.value != null && typeof dateInput.value === 'object') {
        return null;
      }

      if (Date_Regex.test(dateInput.value)) {
        return null;
      }
      return {
        invalidDate: true,
      };
    }
    return null;
  }

  static conditionalValidation(
    firstControlName: string,
    secondControlName: string,
    thirdControlName: string,
  ) {
    return (formGroup: FormGroup) => {
      const first = formGroup.controls[firstControlName];
      const second = formGroup.controls[secondControlName];
      const third = formGroup.controls[thirdControlName];
      // if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      //     // return if another validator has already found an error on the matchingControl
      //     return;
      // }
      // set error on matchingControl if validation fails
      if (first.value || second.value || third.value) {
        if (!first.value) {
          first.setErrors({ required: true });
        }
        if (!second.value) {
          second.setErrors({ required: true });
        }
        if (!third.value) {
          third.setErrors({ required: true });
        }
      } else {
        first.setErrors(null);
        second.setErrors(null);
        third.setErrors(null);
      }
    };
  }
  // changes by PRS [for blank function]
  static requiredWithoutBlank(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      if (control.value.trim().length > 0) {
        return null;
      } else {
        return { required: true };
      }
    }
  }
  static memberShip(string: any) {
    if (string.pristine) {
      return null;
    }

    const NUMBER_REGEXP = /^([a-zA-Z0-9_-]){0,25}$/;
    const NUMBER_REGEXP_1 = /^[d\-_\s]+$/;
    string.markAsTouched();

    if (NUMBER_REGEXP.test(string.value)) {
      return null;
    } else if (NUMBER_REGEXP_1.test(string.value)) {
      return null;
    }
    return {
      invalidNumber: true,
    };
  }

  omitSpecialChar(event: any) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (
      (k > 64 && k < 91) ||
      (k > 96 && k < 123) ||
      k == 8 ||
      k == 32 ||
      (k >= 48 && k <= 57)
    );
  }
}
// ageRangeValidator(control: AbstractControl): { [key: string]: boolean } | null {
//   let yearDiff = new Date().getFullYear() - control.value.year;
//   if (control.value !== undefined && yearDiff >= 18 && yearDiff < 45) {
//     return { 'ageRange': true };
//   }
//   return { 'ageRange': false };
// }
