/// <reference path="validator.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule, HttpHandler, } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl, } from '@angular/forms';
import { EndPoint } from '../../../../src/app/core/services/endpoint';
import { HttpCoreService } from '../../../../src/app/core/services/http-core.service';
import { customValidator } from './validator';
import { BranchDetails, BranchResponse, DataGridHeaders, DeleteBranch } from './app.model';
import { CacheService } from './caching.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';

@Injectable({
  providedIn: 'root',
})
export class ManageBranchesService {

  httpHeaders = {};
  userRoleDetails?: UserRoleDetails;

  constructor(
    private http: HttpClient,
    private _formBuilder: FormBuilder,
    private httpService: HttpCoreService,
    private cacheService: CacheService,
    private store: Store,
  ) {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.httpHeaders = {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json; charset=utf-8'
            },
          ),
        };
      }
    })
  }

  addBranch() {
    // added
    return this._formBuilder.group({
      GTAContact: new FormGroup({
        selectAutoClub: new FormControl('', Validators.required),
        ContactId: new FormControl(0, Validators.required),
        EntityTypeId: new FormControl(1),
        OrgFullName: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z\\s-_]+$')]),
        ParentContactNum: new FormControl('', Validators.required),
        IsDelete: new FormControl(false),
        PhoneNumbers: this._formBuilder.array([this.phoneData()]),
        Fax: this._formBuilder.array([this.faxData()]),
        EmailAccts: this._formBuilder.array([this.emailData()]),
        NameTypeIds: new FormControl([1]),
        Addresses: this._formBuilder.array([this.addressData()]),
        Producers: this._formBuilder.array([this.producerData()]),
        ABN: new FormControl('', customValidator.onlyElevenNumbers),
      }),
    });
  }

  phoneData() {
    return new FormGroup({
      IsDefault: new FormControl(true),
      PhoneTypeId: new FormControl(1),
      PhoneNum: new FormControl('', customValidator.onlyTenNumbers),
    });
  }

  faxData() {
    return new FormGroup({
      IsDefault: new FormControl(true),
      PhoneTypeId: new FormControl(3),
      PhoneNum: new FormControl('', [customValidator.onlyTenNumbers]),
    });
  }

  emailData() {
    return new FormGroup({
      IsDefault: new FormControl(true),
      EmailTypeId: new FormControl(1),
      EmailAddress: new FormControl('', Validators.email),
    });
  }

  addressData() {
    return new FormGroup({
      AddressId: new FormControl(1454148),
      CountryCode: new FormControl('AUS'),
      ZipCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(4),
      ]),
      StreetAddress: new FormControl('', Validators.required),
      StateCode: new FormControl('', Validators.required),
      City: new FormControl('', Validators.required),
      AddressTypes: new FormControl([3, 5]),
    });
  }

  producerData() {
    return new FormGroup({
      ProducerTypesId: new FormControl(2),
      SubCompanyCode: new FormControl('GTA'),
      PayComms: new FormControl('R'),
    });
  }

  getAddressifyAutoCompleteData(searchedData: string) {
    let url = `${EndPoint.API_ADDRESSIFY_AUTOCOMPLETE}`+`${searchedData}`;
    return this.httpService.getRequest(url);
  }

  getAddressifyInfoData(searchedData: string) {
    let url = `${EndPoint.API_ADDRESSIFY_ADDRESSPRO}` + `${searchedData}`;
    return this.httpService.getRequest(url);
  }

  getSiteCoreAgencyTotalList() {
    return this.http.get<DataGridHeaders>(
      EndPoint.API_ENDPOINT +
      '/PASDataService/DataTable/?gridname=ManageBranches',
    );
  }

  getSiteCoreLabeling() {
    return this.http.get(
      EndPoint.API_ENDPOINT + '/PASDataService/GetDataDashboardPolicy',
    );
  }

  getAutoClub(): Observable<any> {
    let cacheKey = 'autoClubList';
    let cacheTTL = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    const cachedData = this.cacheService.getCache(cacheKey);
    if (cachedData) {
      console.log('getting cache');
      return of(cachedData);
    } else {
      console.log('getting new list');
      return new Observable((observer) => {
        this.http.get<any>(
          EndPoint.LTX_ENDPOINT + 'api/pas/configuration/autoclubs',
          this.httpHeaders,
        ).subscribe(
          (data) => {
            this.cacheService.setCache(cacheKey, data, cacheTTL);
            observer.next(data);
            observer.complete();
          },
          (error) => {
            observer.error(error);
          },
        );
      });
    }
  }

  getBranches(params: any): Observable<BranchResponse> {
    return this.http.post<BranchResponse>(
      EndPoint.LTX_ENDPOINT + 'api/pas/branch/search',
      params,
      this.httpHeaders,
    );
  }

  saveBranch(data: BranchDetails): Observable<BranchResponse> {
    return this.http.post<BranchResponse>(
      EndPoint.LTX_ENDPOINT + 'api/pas/branch/update',
      data,
      this.httpHeaders,
    );
  }

  updateStatus(guid: string, active: boolean, loggedUser: any): Observable<any> {
    loggedUser = loggedUser.toUpperCase();
    return this.http.post<any>(
      EndPoint.LTX_ENDPOINT + 'api/pas/branch/update/status',
      {
        branchGuid: guid,
        isActive: active,
        modifiedBy: loggedUser,
      },
      {
        headers: new HttpHeaders({
          'accept': '*/*',
          'Content-Type': 'application/json'
        }),
      },
    );
  }
}