import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CommonParams, JwtToken } from '../commonparams';
import { Utility } from './utility';
import { EndPoint } from './endpoint';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';

@Injectable()
export class HttpCoreService {
  public baseUrl: string | undefined;
  public rootUrl: string | undefined;
  public httpHeaders: HttpHeaders | undefined;
  userRoleDetails?: UserRoleDetails;
  constructor(
    private http: HttpClient, // private globalError: GlobalErrorHandler
    private store: Store
  ) {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
      }
    });
  }

  readXMLFileData(xmlFilePath: any) {
    return this.http.get(xmlFilePath, {
      headers: new HttpHeaders()
        .set('Content-Type', 'text/xml')
        .append('Access-Control-Allow-Methods', 'GET')
        .append('Access-Control-Allow-Origin', '*')
        .append(
          'Access-Control-Allow-Headers',
          'Access-Control-Allow-Headers, Access-Control-Allow-Origin, Access-Control-Request-Method',
        ),
      responseType: 'text',
    });
  }

  readMockupData(filePath: string) {
    return this.http.get<any>(filePath);
  }

  postRequest(url: string, body: any): Observable<any> {

    let httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
    };

    const HttpUploadOptions = {
      headers: httpHeaders.headers,
    };
    return this.http.post<any>(url, body, HttpUploadOptions);
  }

  postRequestVersik(
    url: string,
    body: any,
    access_token: any,
  ): Observable<any> {
    let httpHeaders = {
      headers: new HttpHeaders({
        'x-api-key': 'access_token',
      }),
    };

    const HttpUploadOptions = {
      headers: httpHeaders.headers,
    };
    return this.http.post<any>(url, body, HttpUploadOptions);
  }

  postRequestXapi(url: string, body: any): Observable<any> {
    let httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
      }),
    };

    const HttpUploadOptions = {
      headers: httpHeaders.headers,
    };
    return this.http.post<any>(url, body, HttpUploadOptions);
  }

  putRequest(url: string, body: any): Observable<any> {
    let httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8'
      }),
    };

    const HttpUploadOptions = {
      headers: httpHeaders.headers,
    };
    return this.http.put<any>(url, body, HttpUploadOptions);
    // .pipe(catchError(this.handleError));
  }

  postRequestJson(url: string, body: any): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Content-Type', 'application/json');

    const HttpUploadOptions = {
      headers: httpHeaders,
    };
    return this.http.post<any>(url, body, HttpUploadOptions);
    //.pipe(catchError(this.handleError));
  }

  postRequestWithHeaders(
    url: string,
    body: any,
    httpHeaders: HttpHeaders,
  ): Observable<any> {
    const HttpUploadOptions = {
      headers: httpHeaders,
    };
    return this.http.post<any>(url, body, HttpUploadOptions);
  }

  getRequest(
    url: string,
    queryString: any = null,
    token: string = '',
  ): Observable<any> {
    let httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };

    let httpParams = new HttpParams();
    if (queryString) {
      queryString.forEach((element: any) => {
        httpParams = httpParams.set(element, queryString[element]);
      });
    }

    return this.http.get<any>(url, {
      headers: httpHeaders.headers,
    }); //.pipe(catchError(this.handleError));
  }

  getQueryString(object: any) {
    var parameters = [];
    for (var property in object) {
      if (object.hasOwnProperty(property)) {
        parameters.push(encodeURI(property + '=' + object[property]));
      }
    }
    return parameters.join('&');
  }

  getRaw(
    url: string,
    queryString: any = null,
    token: string = '',
  ): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Content-Type', 'application/json');
    let httpParams = new HttpParams();
    if (queryString) {
      queryString.forEach((element: any) => {
        httpParams = httpParams.set(element, queryString[element]);
      });
    }
    return this.http.get<any>(url, {
      headers: httpHeaders,
    });
  }

  validateJwtToken(): Observable<any> {
    console.log('quote.service.validateJwtToken from storage');
    return of({});
    var token: JwtToken | any = JSON.parse(
      sessionStorage.getItem('jwtToken') || '',
    );
    //if (
    //  Utility.isNullOrEmpty(token) ||
    //  Utility.tokenExpired(token.expires_at)
    //) {
    //  return this.getAccessToken(`${EndPoint.DataServiceBaseUrl}/token`).pipe(
    //    map((_jwtToken) => {
    //      const date = Utility.toDate(new Date());
    //      date.setUTCSeconds(_jwtToken.expires_in);
    //      _jwtToken.expires_at = date.toString();
    //      sessionStorage.setItem('jwtToken', JSON.stringify(_jwtToken));
    //      console.log('quote.service.validateJwtToken http');
    //      return _jwtToken;
    //    }),
    //  );
    //} else {
    //  console.log('quote.service.validateJwtToken from storage');
    //  return of(token);
    //}
  }

  getAccessToken(tokenUrl: string): Observable<any> {
    let httpHeaders = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const HttpUploadOptions = {
      headers: httpHeaders,
    };
    return this.http.get(tokenUrl, HttpUploadOptions.headers);
  }

  // public proxyUrl(url: string) {
  //   var infinityKey = ["/quote/"];
  //   var isInfinity = false;
  //   isInfinity = infinityKey.some(v => url.includes(v));
  //   if (isInfinity) {
  //     this.validateJwtToken().toPromise();
  //   }
  // }
}
