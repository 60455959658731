/// <reference path="../../../environments/environment.ts" />
/// <reference path="endpoint.ts" />
/// <reference path="endpoint.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndPoint } from './endpoint';
// import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in-no-jquery';
// import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min';
import { environment } from '../../../environments/environment';
import { Okta } from '../../../../src/app/shared/okta/okta.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  user: any;
  oktaSignIn;
  idToken: any;
  accessToken: any;
  document: any;
  constructor(
    private http: HttpClient,
    private okta: Okta,
  ) {
    this.oktaSignIn = okta.getWidget();
  }

  errorTracker() {
    // this.oktaSignIn.authClient.signOut(() => {
    //   this.user = undefined;
    //   localStorage.clear();
    //   location.href =
    //     location.protocol + '//' + location.host + '/en-us/professional-lines';
    // });
  }
}
