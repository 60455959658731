import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'integerPart' })
export class IntegerPartPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    let parts = value.toString().split('.');
    return parts[0];
  }
}
@Pipe({ name: 'decimalPart' })
export class DecimalPartPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    let parts = value.toString().split('.');
    return parts[1];
  }
}
