<div class="card-gray">
  <h1 class="card-gray-header">{{ policyTravelCares?.CoverageType }}</h1>
  <div class="card-edit" *ngIf="isUpdateENCalled && isEditBtnRequired">
    <a href="javascript:void(0);">
      <i *ngIf="isLoadeing != 'Yes'" class="far fa-edit" data-toggle="modal" (click)="openQuoteModal()"></i>
      <i *ngIf="isLoadeing === 'Yes'" class="loader-sm mt-2"></i>
    </a>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">{{ excessLebel }}</span>
        </div>
        <div class="col-6 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">
            {{policyTravelCares?.excess}}
          </span>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'"> Skiing</span>
        </div>
        <div class="col-6 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">
            {{ policyTravelCares?.Sking }}</span>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'"> Cruising</span>
        </div>
        <div class="col-6 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">{{
            policyTravelCares?.Cruise
            }}</span>
        </div>
      </div>
    </li>
    <li class="list-group-item" *ngIf="policyTravelCares?.productAlias == 'Annual Multi Trip' || policyTravelCares?.productAlias == 'Annual Multi-Trip'">
      <div class="row">
        <div class="col-6">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">Max Trip Length</span>
        </div>
        <div class="col-6 text-right">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'">{{
            policyTravelCares.multiTripLength
            }}</span>
        </div>
      </div>
    </li>
  </ul>
</div>