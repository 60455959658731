import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CommonUtility {
  constructor() {}

  static shallowCpyJSONTrimObj(value: any) {
    return JSON.parse(JSON.stringify(value).replace(/"\s+|\s+"/g, '"'));
  }

  static isObject = (object: any) => {
    return object !== null && typeof object === 'object';
  };

  static compareSimpleJSONObject(object1: Object, object2: Object) {
    let isEqual =
      JSON.stringify(object1) === JSON.stringify(object2) ? true : false;
    return isEqual;
  }
}
