import { Component, OnInit, Input } from '@angular/core';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css'],
})
export class LoaderComponent implements OnInit {
  @Input() beforPayment: boolean = false;
  @Input() errorMessage: any;
  isLoading: boolean = false;
  constructor(private loaderService: LoaderService) {}

  ngOnInit() {
    this.loaderService.isLoading.subscribe((loadState) => {
      this.isLoading = loadState;
    });
  }
}
