import { BrowserModule } from '@angular/platform-browser';
import {
  NgModule,
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe, PercentPipe, DatePipe } from '@angular/common';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { ManageBranchesComponent } from './app.component';
import { HttpCoreService } from '../../../../src/app/core/services/http-core.service';
// import { ComboBoxComponent } from './comboBox/combo-box.component';
import { PortalCodeService } from '../../../../src/app/core/portalcode/portalcode';
import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
import { FormatNumberPipe } from '../../../../src/app/core/pipes/format-number.pipe';
import { InterceptorsService } from '../../../../src/app/core/services/interceptors.service';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { ToastrModule } from 'ngx-toastr';
import { BranchesTableComponent } from './branches-table/branches-table.component';
import { ClubsDropdownComponent } from './clubs-dropdown/clubs-dropdown.component';
import { BranchModalComponent } from './branch-modal/branch-modal.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { DeactivateConfirmModalComponent } from './deactivate-confirm-modal/deactivate-confirm-modal.component';
export { ManageBranchesComponent } from './app.component';

@NgModule({
  declarations: [ManageBranchesComponent],
  imports: [
    BranchesTableComponent,
    BrowserModule,
    FormsModule,
    LoaderModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    DataTablesModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
    ClubsDropdownComponent,
    BranchModalComponent,
    ConfirmModalComponent,
    DeactivateConfirmModalComponent,
  ],
  providers: [
    CurrencyPipe,
    FormatNumberPipe,
    PercentPipe,
    DatePipe,
    OktaCustomAuthService,
    HttpCoreService,
    PortalCodeService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ManageBranchesModule {
}
