import { Directive, OnInit, OnDestroy, Renderer2, Inject } from '@angular/core';
//import { DOCUMENT } from '@angular/platform-browser';

@Directive({
  selector: '[ngxBraintreeDirective]',
})
export class NgxBraintreeDirective implements OnInit, OnDestroy {
  script: any;

  constructor(private renderer: Renderer2) {}

  ngOnInit() {
    this.script = this.renderer.createElement('script');
    this.script.type = 'text/javascript';
    this.script.src =
      'https://js.braintreegateway.com/web/dropin/1.13.0/js/dropin.min.js';
    this.renderer.appendChild(window.document.body, this.script);
  }

  ngOnDestroy() {
    this.renderer.removeChild(window.document.body, this.script);
  }
}
