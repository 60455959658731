<ng-template #contentmodalAddUser let-modal let-c="close" let-d="dismiss">
  <section class="mb-5">
    <div class="container">
      <h2 class="mt-4 mb-3 box-label">{{ isNewAgent ? "Add User" : "Edit User" }}</h2>
      <form [formGroup]="addUserForm" (ngSubmit)="onAgentSubmit()">
        <ng-container>
          <div class="form-row mt-3">
            <ng-container>
              <div class="form-group col-sm-6 col-md-4">
                <select id="inputState" class="form-control" formControlName="selectedClubId"
                  (change)="onAutoClubFormChange($event)" [class.is-invalid]="isInvalid('selectedClubId')">
                  <option value="">Select Autoclub</option>
                  <option *ngFor="let club of listClubs" [value]="club.autoClubGuid">
                    {{ club.link_Url?.title }}
                  </option>
                </select>
                <app-form-error [submitted]="submitted" [control]="addUserForm.get('selectedClubId')!" label=""
                  rawMessage="Select auto club is required"></app-form-error>
              </div>

              <div class="form-group col-sm-6 col-md-4">
                <select id="inputState" class="form-control" formControlName="selectedBranchId"
                  [class.is-invalid]="isInvalid('selectedBranchId')">
                  <option value="">Select Branch Name</option>
                  <option *ngFor="let branch of listBranches" [value]="branch.branchGuid">
                    {{ branch.name }}
                  </option>
                </select>
                <app-form-error [submitted]="submitted" [control]="addUserForm.get('selectedBranchId')!" label=""
                  rawMessage="Select branch is required"></app-form-error>
              </div>
            </ng-container>
            <div class="form-group col-sm-6 col-md-4">
              <select id="inputState" class="form-control" formControlName="selectedRoleId"
                [class.is-invalid]="isInvalid('selectedRoleId')">
                <option value="">Select Role</option>
                <option *ngFor="let role of listRoles" [value]="role.roleId">
                  {{ role.roleName }}
                </option>
              </select>
              <app-form-error [submitted]="submitted" [control]="addUserForm.get('selectedRoleId')!" label=""
                rawMessage="Select role is required"></app-form-error>
            </div>
          </div>
          <div class="form-row mt-3">
            <div class="form-group col-sm-6 col-md-4">
              <input type="email" class="form-control" id="email" formControlName="email" placeholder="Email"
                maxlength="100" [class.is-invalid]="isInvalid('email')" [readOnly]="!isNewAgent" />
              <app-form-error [submitted]="submitted" [control]="addUserForm.get('email')!" label="email address"
                rawMessage="Email address is required"></app-form-error>
            </div>
            <div class="form-group col-sm-6 col-md-4">
              <input type="text" class="form-control" id="firstName" formControlName="firstName"
                placeholder="First Name" maxlength="100" [class.is-invalid]="isInvalid('firstName')" />
              <app-form-error [submitted]="submitted" [control]="addUserForm.get('firstName')!" label=""
                rawMessage="First name is required"></app-form-error>
            </div>
            <div class="form-group col-sm-6 col-md-4">
              <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Last Name"
                maxlength="100" [class.is-invalid]="isInvalid('firstName')" />
              <app-form-error [submitted]="submitted" [control]="addUserForm.get('lastName')!" label=""
                rawMessage="Last name is required"></app-form-error>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 mt-4 text-center btn-footer">
              <a class="btn btn-secondary btn-full-sm" (click)="modal.close()">Cancel</a>
              <button class="btn btn-primary btn-full-sm {{isSaveUser ? 'disabled' : ''}}" type="submit">
                <span *ngIf="isSaveUser" class="spinner-border text-primary" style="width: 1rem; height: 1rem;"
                  role="status" aria-hidden="false"></span>
                <span *ngIf="!isSaveUser">Save Changes</span>
              </button>
            </div>
          </div>
        </ng-container>
      </form>
    </div>
  </section>
</ng-template>