/// <reference path="../../../environments/environment.ts" />
/// <reference path="endpoint.ts" />
/// <reference path="endpoint.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndPoint } from './endpoint';
// import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in-no-jquery';
// import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min';
import { environment } from '../../../environments/environment';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  constructor(private http: HttpClient) {}

  removeSessionStorage() {
    sessionStorage.removeItem('policyGuid');
    sessionStorage.removeItem('movementGuid');
    sessionStorage.removeItem('originalMovementGuid');
    sessionStorage.removeItem('PricingDate');
    sessionStorage.removeItem('PurchaseDate');
    sessionStorage.removeItem('policyUpdateClick');
    sessionStorage.removeItem('quotestatus');
    sessionStorage.removeItem('selectedClubKey');
    sessionStorage.removeItem('cancelStatus');
    sessionStorage.removeItem('portalcode');
    sessionStorage.removeItem('assessmentDetails');
    sessionStorage.removeItem('assesmentsDetails');
    sessionStorage.removeItem('quoteDetail');
    sessionStorage.removeItem('medicalPremium');
    sessionStorage.removeItem('redirectFrom');
    sessionStorage.removeItem('medicalPremium');
    sessionStorage.removeItem('veriskTravelerGuid');
    sessionStorage.removeItem('additionalAmount');
    sessionStorage.removeItem('sendCoiCheckBoxValue');
    sessionStorage.removeItem('adultTravelerMedCond1');
    sessionStorage.removeItem('adultTravelerMedCond2');
    sessionStorage.removeItem('travelerMedCondDetails');
    sessionStorage.removeItem('currentAssessment');
    sessionStorage.removeItem('fromVerisk');

    sessionStorage.removeItem('reinstatementPaymentType');
    sessionStorage.removeItem('HelixScore');
    localStorage.removeItem('bbJWT');    
    //  sessionStorage.removeItem('mCountry');
  }
}
