<!-- <div class="overlay-custom-container"></div> -->
<div class="modal-backdrop fade show"></div>
<div class="modal-open">
  <div class="modal fade show" style="display: block">
    <!-- (click)="onOverlayClicked($event)" -->
    <div class="modal-dialog modal-xl" (click)="onDialogClicked($event)">
      <div class="modal-content">
        <ng-template appInsertion> </ng-template>
      </div>
    </div>
  </div>
</div>
