<div class="text-right mt-4 abc" [style.display]="display">
  <div class="alert alert-success alert-dismissible fade show text-left" role="alert" *ngIf="message">
    {{ message }}
    <!--<button type="button" class="close" (click)="toggleState()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
    </button>-->
  </div>
</div>
<div class="text-right mt-4 abc" [style.display]="displayError">
  <div class="alert alert-danger text-left" role="alert" *ngIf="messageWrong">
    {{ messageWrong }}
    <button type="button" class="close" (click)="closeAlert()" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
</div>
<div class="row top-view-link">
  <ng-container *ngIf="!isReadOnly">
    <button [disabled]="isDisabledResend" class="col-3 link-button" *ngIf="isResendCoi" (click)="resendCoi()">
      <span>{{ ResentCoiOrMailText }}</span>
      <div *ngIf="isResendCoiClicked" class="transparent-button-loader">
        <span class="spinner-border text-primary" style="width:.8rem;height: .8rem;opacity: 0.7;"></span>
      </div>
    </button>
    <button [disabled]="isDisabledRegenerate" class="col-3 link-button" *ngIf="isResendCoi" (click)="regenerateCoi()">
      <span>Re-generate COI</span>
      <div *ngIf="isRegenerateCoiClicked" class="transparent-button-loader">
        <span class="spinner-border text-primary" style="width:.8rem;height: .8rem;opacity: 0.7;"></span>
      </div>
    </button>
  </ng-container>
  <button [disabled]="isDisabledViewCoi" class="col-4 link-button" (click)="viewCoi()">
    <span>View/Download COI</span>
    <div *ngIf="isViewCoiClicked" class="transparent-button-loader">
      <span class="spinner-border text-primary" style="width:.8rem;height: .8rem;opacity: 0.7;"></span>
    </div>
  </button>
  <button [disabled]="isDisabledViewPds" class="col-2 link-button" (click)="viewPDS(viewPdsId)">
    View PDS
  </button>
</div>