import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxBraintreeService } from './ngx-braintree.service';
import { ConfigureDropinService } from './configure-dropin.service';
import { NgxBraintreeDirective } from './ngx-braintree.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
import { PaymentComponent } from './app.component';
import { CustomNgbDateParserFormatter } from 'src/app/core/services/customNgbDateParserFormatter';
import { CurrencyPipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { InterceptorsService } from 'src/app/core/services/interceptors.service';
import { PortalCodeService } from '../../../../src/app/core/portalcode/portalcode';
import { LogPublishersService } from 'projects/policyDetails/src/app/shared/logging/log-publisher.service';
import { HttpCoreService } from 'src/app/core/services/http-core.service';
import { AppConfig } from 'projects/policyDetails/src/app/shared/config/app.config';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { NgxBraintreePayDirective } from './ngx-braintree-pay.directive';
export { PaymentComponent } from './app.component';

export function initConfig(config: AppConfig) {
  return () => config.load();
}

@NgModule({
  declarations: [PaymentComponent, NgxBraintreeDirective,NgxBraintreePayDirective],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    LoaderModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
    ReactiveFormsModule,
    NgbModule,
  ],
  providers: [
    OktaCustomAuthService,
    CurrencyPipe,
    PortalCodeService,
    HttpCoreService,
    LogPublishersService,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      deps: [AppConfig],
      multi: true,
    },
    { provide: NgxBraintreeService, useClass: NgxBraintreeService },
    { provide: ConfigureDropinService, useClass: ConfigureDropinService },
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new CustomNgbDateParserFormatter('dd/MM/yyyy'),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true,
    },
  ],
})
export class PaymentGatewayModule {}
