import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpClientModule,
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EndPoint } from '../../../../../src/app/core/services/endpoint';

@Injectable({
  providedIn: 'root',
})
export class PolicyStatusService {
  constructor(private http: HttpClient) { }

  httpHeaders = {
    headers: new HttpHeaders({
    }),
  };

  getSiteCoreMetricList() {
    return this.http.get(
      EndPoint.API_ENDPOINT + '/PASDataService/GetDataDashboardPolicy',
    );
  }
}
