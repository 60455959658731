import { Injectable } from '@angular/core';
import { LogService } from '../logging/log-service';
import { EndorsementGenericService } from './endorsement-generic.service';
import { DataBusService } from '../../services/databus.service';
import { MessageBusService } from '../../services/messagebus.service';
import { ToastrService } from 'ngx-toastr';
import { PolicyDetailService } from '../../app.services';
import { PageNameEnum, SubComponentNameEnum } from '../../enum/page-name-enum';
import { LogLevel } from '../enum/log-level-enum';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Utility } from '../utility';
import { EventEmitterService } from './event-emitter.service';

@Injectable({
  providedIn: 'root',
})
export class TravelerUpdateService {
  msg: string = 'Traveller detail updated successfully';

  constructor(
    private loaderService: LoaderService,
    private logService: LogService,
    public endorsementGenericService: EndorsementGenericService,
    private dataBusService: DataBusService,
    private messageService: MessageBusService,
    public dtaService: PolicyDetailService,
    private toastr: ToastrService,
    private eventEmitter: EventEmitterService,
  ) {}

  // updateEndorsementService(callComingFrom: string) {
  //   this.endorsementGenericService
  //     .prepareRequestForEndorsement(sessionStorage.getItem('movementGuid'))
  //     .then((data) => {
  //       let createReqObject = {
  //         PolicyId: data.PolicyId.toString(),
  //         PolicyTxnId: data.PolicyTxnId.toString(),
  //         VersionNum: '1',
  //         PolicyNum: data.PolicyNum,
  //         SubCompanyCode: 'GTA',
  //       };
  //       this.endorsementGenericService
  //         .createEndorsement(createReqObject)
  //         .subscribe(
  //           (dataRes) => {
  //             data.PolicyTxnId = dataRes.PolicyTxnId.toString();
  //             //update the traveller with new details
  //             this.updateTravellersDetails(data);
  //             this.endorsementGenericService.updateEndorsement(data).subscribe(
  //               (resdata) => {
  //                 if (resdata.Status == 'SUCCESS') {
  //                   sessionStorage.setItem(
  //                     'policytxnid',
  //                     resdata.PolicyTxnId.toString(),
  //                   );
  //                   this.dataBusService.publishPolicyDetails();
  //                   this.messageService.publishMessage(true);
  //                   this.toastr.success('Traveller detail updated !!');
  //                   sessionStorage.removeItem('updatedContact');
  //                   sessionStorage.removeItem('isContactModified');

  //                   //Emit IssueEndorsement Calls
  //                   switch (callComingFrom) {
  //                     case 'PolicyPayments':
  //                       this.eventEmitter.onPolicyPaymentsIssue(true);
  //                       break;
  //                     case 'ManageRefund':
  //                       this.eventEmitter.onManageRefundIssue(true);
  //                       break;
  //                     case 'PaymentSummary':
  //                       this.eventEmitter.onPaymentSummaryIssue(true);
  //                       break;
  //                     case 'PaymentGateway':
  //                       this.eventEmitter.onPaymentGatewayIssue(true);
  //                       break;
  //                     default:
  //                       break;
  //                   }
  //                 } else {
  //                   this.msg = 'Traveller detail not updated successfully.';
  //                 }
  //                 this.createActivityLogItem('Traveller detail updated');
  //               },
  //               (err) => {
  //                 this.logService.createErrorLog(
  //                   data,
  //                   err,
  //                   'UpdateEndorsement',
  //                   PageNameEnum.PolicySummary +
  //                     ' ' +
  //                     SubComponentNameEnum.Traveller,
  //                   LogLevel.Error,
  //                 );
  //                 this.msg =
  //                   'There is an issue with backend response. Please retry after some time';
  //                 this.createActivityLogItem(this.msg);
  //               },
  //             );
  //           },
  //           (err) => {
  //             this.loaderService.hide();
  //             this.logService.createErrorLog(
  //               createReqObject,
  //               err,
  //               'CreateEndorsement',
  //               PageNameEnum.PolicySummary +
  //                 ' ' +
  //                 SubComponentNameEnum.Traveller,
  //               LogLevel.Error,
  //             );
  //           },
  //         );
  //     });
  // }

  // createActivityLogItem(msg: any) {
  //   this.dtaService
  //     .createActivityLogItem('Travelerdtlupd', msg)
  //     .subscribe((response) => {});
  // }

  updateTravellersDetails(data: any) {
    var updatedTraveller = JSON.parse(
      sessionStorage.getItem('updatedContact') || '',
    );
    if (updatedTraveller != null) {
      if (data.Traveler1 != null) {
        data.Traveler1.FirstName = updatedTraveller.firstName;
        data.Traveler1.LastName = updatedTraveller.lastName;
        data.Traveler1.Addresses[0].StreetAddress = updatedTraveller.address;
        data.Traveler1.Addresses[0].StateCode = updatedTraveller.state;
        data.Traveler1.Addresses[0].ZipCode = updatedTraveller.zipCode;
        data.Traveler1.Addresses[0].City = updatedTraveller.city;
        data.Traveler1.EmailAccts[0].EmailAddress = updatedTraveller.email;
        data.Traveler1.BirthDate = this.setPostDateFormat(updatedTraveller.dob);
        data.Traveler1.Age = Utility.ageFromDateOfBirth(
          new Date(data.Traveler1.BirthDate),
        );
        data.Traveler1.PhoneNumbers[0].PhoneNum =
          updatedTraveller.phoneNumber != null
            ? updatedTraveller.phoneNumber.replace(/[^a-zA-Z0-9]/g, '')
            : '';

        data.RequestDetails.Ages[0] = data.Traveler1.Age;
        if (updatedTraveller.memberShip == '') {
          delete data.QuoteDetails.MembershipNumber;
        } else {
          data.QuoteDetails.MembershipNumber = updatedTraveller.memberShip;
        }
      }
      if (
        updatedTraveller.secondaryTraveller.length > 0 &&
        updatedTraveller.secondaryTraveller[0].SecondaryfirstName != '' &&
        updatedTraveller.secondaryTraveller[0].SecondaryfirstName != null &&
        updatedTraveller.secondaryTraveller[0].SecondaryLastName != '' &&
        updatedTraveller.secondaryTraveller[0].SecondaryLastName != null
      ) {
        let traveler2: any;
        if (data.Traveler2 && data.Traveler2.FirstName) {
          traveler2 = Object.assign({}, data.Traveler2);
        } else {
          traveler2 = Object.assign({}, data.Traveler1);
          traveler2.ContactId = '';
        }

        traveler2.FirstName =
          updatedTraveller.secondaryTraveller[0].SecondaryfirstName;
        traveler2.LastName =
          updatedTraveller.secondaryTraveller[0].SecondaryLastName;
        traveler2.BirthDate = this.setPostDateFormat(
          updatedTraveller.secondaryTraveller[0].dob1,
        );
        traveler2.Age = Utility.ageFromDateOfBirth(
          new Date(traveler2.BirthDate),
        );
        data.RequestDetails.Ages[1] = traveler2.Age;
        data.Traveler2 = traveler2;
      } else {
        if (data.Traveler2 != undefined) {
          data.Traveler2 = [];
          data.RequestDetails.Ages.splice(1, 1);
        }
      }
    }
  }

  setPostDateFormat(date: any) {
    if (
      date == '' ||
      date == null ||
      date === undefined ||
      typeof date != 'object'
    ) {
      return;
    }
    let ngbDate = date;
    let datevalue =
      ngbDate['year'].toString() +
      '-' +
      (ngbDate['month'].toString().length == 1
        ? '0' + ngbDate['month'].toString()
        : ngbDate['month'].toString()) +
      '-' +
      (ngbDate['day'].toString().length == 1
        ? '0' + ngbDate['day'].toString()
        : ngbDate['day'].toString());
    return datevalue;
  }

  // updateBasicEndorsementService() {
  //   this.loaderService.show();
  //   this.endorsementGenericService
  //     .prepareRequestForEndorsement(sessionStorage.getItem('movementGuid'))
  //     .then((data) => {
  //       let createReqObject = {
  //         PolicyId: data.PolicyId.toString(),
  //         PolicyTxnId: data.PolicyTxnId.toString(),
  //         VersionNum: '1',
  //         PolicyNum: data.PolicyNum,
  //         SubCompanyCode: 'GTA',
  //       };
  //       this.endorsementGenericService
  //         .createEndorsement(createReqObject)
  //         .subscribe(
  //           (dataRes) => {
  //             data.PolicyTxnId = dataRes.PolicyTxnId.toString();
  //             this.updateBaseTravellersDetails(data);
  //             data.IsTravelerToUpdate = true;
  //             this.endorsementGenericService.updateEndorsement(data).subscribe(
  //               (resdata) => {
  //                 if (resdata.Status == 'SUCCESS') {
  //                   // sessionStorage.setItem('movementGuid', resdata.PolicyTxnId);
  //                   // this.dataBusService.publishPolicyDetails();
  //                   // this.messageBusService.publishMessage(true);
  //                   this.clearSessionOnUpd();
  //                   this.eventEmitter.onDiscard(true);
  //                 } else {
  //                   this.msg = 'Traveller detail not updated successfully.';
  //                 }
  //                 this.loaderService.hide();
  //                 this.createActivityLogItem('Traveller detail updated');
  //               },
  //               (err) => {
  //                 this.loaderService.hide();
  //                 this.logService.createErrorLog(
  //                   data,
  //                   err,
  //                   'UpdateEndorsement',
  //                   PageNameEnum.PolicySummary +
  //                     ' ' +
  //                     SubComponentNameEnum.Traveller,
  //                   LogLevel.Error,
  //                 );
  //                 this.msg =
  //                   'There is an issue with backend response. Please retry after some time';
  //                 this.createActivityLogItem(this.msg);
  //               },
  //             );
  //           },
  //           (err) => {
  //             this.loaderService.hide();
  //             this.logService.createErrorLog(
  //               createReqObject,
  //               err,
  //               'CreateEndorsement',
  //               PageNameEnum.PolicySummary +
  //                 ' ' +
  //                 SubComponentNameEnum.Traveller,
  //               LogLevel.Error,
  //             );
  //           },
  //         );
  //     });
  // }

  updateBaseTravellersDetails(data: any) {
    if (sessionStorage.getItem('baseTravelers')) {
      var baseTvl = JSON.parse(sessionStorage.getItem('baseTravelers') || '');
      if (baseTvl != null && baseTvl.Traveler1 != null) {
        data.Traveler1 = baseTvl.Traveler1;
      }
      if (baseTvl != null && baseTvl.Traveler2 != null) {
        data.Traveler2 = baseTvl.Traveler2;
      }
    }
  }

  clearSessionOnUpd() {
    sessionStorage.removeItem('baseTravelers');
  }
}
