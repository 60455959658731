import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PolicyState } from "./policy.reducer";

export const selectPolicyState = createFeatureSelector<PolicyState>('policy');

export const selectCurrentPolicy = createSelector(
  selectPolicyState,
  (state: PolicyState) => state.policy 
);

export const isPolicyDataPresent = createSelector(
  selectCurrentPolicy,
  (policy) => !!policy
);