import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ManageBranchesService } from '../ManageBranchs.service';
import { NgForOf } from '@angular/common';
import { DataSetterService } from 'src/app/core/services/data-setter.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
@Component({
  selector: 'app-clubs-dropdown',
  standalone: true,
  imports: [
    NgForOf,
  ],
  templateUrl: './clubs-dropdown.component.html',
  styleUrl: './clubs-dropdown.component.css',
})
export class ClubsDropdownComponent {
  clubs: any[] = [];
  @Input() selectedClubCode?: string;
  @Input() isParentComponent: boolean = false;
  @Input() actionType: string = 'edit';
  @Output() clubChanged: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private dataSetterService: DataSetterService,
    private store: Store) {
  }

  ngOnInit() {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        let autoClubs = this.dataSetterService.setAutoClubs(loginUser.userRoleDetails.AutoClubURLList, false);
        this.clubs = autoClubs;
        this.clubs = this.clubs.filter(({ key }) => key !== "W2C");
        if(this.isParentComponent == true || this.clubs.length == 2){
          this.clubs = this.clubs.filter(({ autoClubGuid }) => autoClubGuid !== null);
        }
        if (this.clubs.length > 0 && this.actionType == 'edit') {
          const selectedClub = this.clubs.find(club => club.autoClubGuid === this.selectedClubCode) || this.clubs[0];
          this.clubChanged.emit(selectedClub);
        }
        else{
          this.selectedClubCode = this.clubs[0];
          this.clubChanged.emit(this.selectedClubCode);
        }
      }
    });
  }

  onClubChange(e: Event) {
    const selectElement = e.target as HTMLSelectElement;
    const selectedCode = selectElement.value;
    const selectedClub = this.clubs.find(club => club.autoClubGuid === selectedCode);
    this.clubChanged.emit(selectedClub);
  }
}