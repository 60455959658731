<button
  [disabled]="isDisabledDiscard"
  class="btn btn-primary ml-3 button-disable"
  (click)="discardChanges()"
>
  Discard Changes
</button>

<ng-template #discardAlertTemplate>
  <div *ngIf="isLoader">
    <div class="clearfix"></div>
    <div class="overlay-custom-container"></div>
    <div class="loader"></div>
  </div>
  <div class="" id="myModal-11">
    <div class="modal-body">
      <div class="col-md-5 mx-auto text-center">
        <div *ngIf="discardMessageSuccess" class="alert alert-success my-4">
          {{discardMessageSuccess}}
        </div>
        <div
          *ngIf="discardMessageFail"
          class="alert alert-warning my-4 text-break"
        >
          {{discardMessageFail}}
        </div>
      </div>
    </div>
    <div class="text-center mb-4 mt-2 btn-footer">
      <button
        type="button"
        class="btn btn-primary btn-full-sm"
        (click)="closeAlertChildModal()"
      >
        OK
      </button>
    </div>
  </div>
</ng-template>
