import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, TemplateRef, ElementRef, } from '@angular/core';
import { PolicyDetailService } from '../app.services';
import { NgbModal, NgbModalRef, NgbDateParserFormatter, } from '@ng-bootstrap/ng-bootstrap';
import { filter, first } from 'rxjs/operators';
import { EndorsementGenericService } from '../shared/services/endorsement-generic.service';
import { DataBusService } from '../services/databus.service';
import { MessageBusService } from '../services/messagebus.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbDateFRParserFormatter } from './ngb-date-fr-parser-formatter';
import { customValidator } from '../policy-details-travellers/validator';
import { DateService } from 'src/app/core/services/date-updated.service';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum, SubComponentNameEnum, } from 'projects/policyDetails/src/app/enum/page-name-enum';
import { PortalCodeEnum } from 'src/app/core/enum/sitename-enum';
import { SitecoreItemService } from '../services/sitecore-item.service';
import { Utility } from '../shared/utility';
import { Store } from '@ngrx/store';
import { selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { isEndorsementDataPresent, selectCurrentEndorsement } from 'src/app/core/store/endorsement/endorsement.selectors';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { PromoCodeService } from 'projects/promo-code/src/app/promo-code.service';
import { MapperService } from 'src/app/core/services/mapper.services';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';

export class Constants {
  static readonly DATE_FMT = 'dd-MM-yyyy';
}
@Component({
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
  ],
  selector: 'policyDetails-policy-details-summary',
  templateUrl: './policy-details-summary.component.html',
  styleUrls: ['./policy-details-summary.component.css'],
})
export class PolicyDetailsSummaryComponent implements OnInit, OnChanges {
  public policySummary: any;
  public policyContactId: any;
  public policyAllData: any;
  listCopne: any[] = [];
  promoCodeValue: any;
  message: any;
  errorMessage: any;
  promocodeDiscountValue: any = 0;
  grandTotalValue: number = 0;
  promoCodeInput: any;
  selectDisabled: boolean = false;
  modalRef!: NgbModalRef;
  policyTxIdValue: any;
  isLoaderTrue: boolean = true;
  storageObjData: any;
  dataFind: any;
  dataFind2: any;
  dataFind3: any;
  cruiseValue: number = 0;
  excessValue: number = 0;
  isToaster: boolean = true;
  promoCode: any = '';
  skingValue: any = 0;
  riskDataElements: any;
  updateObject: any;
  afterUpdatePromo: any;
  updatePromo: any;
  promoDiscount: any;
  dataMappingOne: any;
  dataMappingTwo: any;
  dataMappingThree: any;
  dataMappingFour: any;
  dataMappingCoverage: any;
  messageRemovePromo: any;
  promoDisResult: any;
  memberShipDisResult: any;
  memberShipDiscountValue: any;
  promocodeDiscountValueAfterAlert: any;
  isLoadeingQuoteDetails: boolean = false;
  displayPromoAlertAfterPromo: any = 'none';
  displayPromoAlertUpdatePromo: any = 'none';
  displayPromoAlertRemovePromo: any = 'none';
  displayPromoAlert: any = 'none';
  displayPromoAlertRemove: any = 'none';
  isLoadeing: string = 'Yes';
  maxTripLengthAmountValue: any;
  maxTripLengthAmountDiscrip: any;
  selectedExcessName: any = '';
  isLoader: boolean = false;
  searchedPromoCodeList: any = [];
  noDataFound = false;
  promoCodeSearchForm!: FormGroup;
  submitted = false;
  additionalAmountData: any;
  showAdditionalAmount = false;
  policyVersionTxnId: any;
  siteName: any;
  public excessLebel = 'Excess';
  public currentPolicyInStore: any;
  public isPolicyInStore: boolean = false;
  public isUpdateENCalled: boolean = false;
  public currentENInStore: any;
  public IsENInStore: boolean = false;


  @Input() isPromocode = true;
  @ViewChild('dialogContentTemplate', { static: true })
  dialogContent: TemplateRef<ElementRef>;
  userRoleDetails?: UserRoleDetails;

  constructor(
    private policyService: PolicyDetailService,
    private promoCodeService: PromoCodeService,
    private modalService: NgbModal,
    private el: ElementRef,
    private messageService: MessageBusService,
    private endorsementGenericService: EndorsementGenericService,
    private dataBusService: DataBusService,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private logService: LogService,
    private itemService: SitecoreItemService,
    private store: Store,
    private mapperService: MapperService,
    private storeService: StoreGenericService,
  ) {
    this.dialogContent = this.el.nativeElement;
  }

  ngOnInit() {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
      }
    })
    this.errorMessage = '';
    this.promoCodeSearchForm = this.formBuilder.group(
      {
        promoCode: [''],
        discountRate: [
          '',
          Validators.compose([
            Validators.max(99),
            customValidator.numbersOnlyValidator,
          ]),
        ],
        effectiveDate: [
          null,
          Validators.compose([customValidator.DateValidator]),
        ],
      },
      {
        validator: this.atLeastOneValidator(),
      },
    );
    // this.getPolicyData();

    // this.storageObjData = JSON.parse(
    //   sessionStorage.getItem('policyDetailsStorageData') || '{}',
    // );
    // this.messageService.messageData.subscribe((data) => {
    //   this.isEditable = data;
    // });

    this.policyService.policyTransactionId.subscribe((policyTxnid) => {
      this.policyVersionTxnId = policyTxnid;
    });

    //Policy Store
    this.store.select(selectCurrentPolicy).subscribe(pol => {
      this.currentPolicyInStore = pol;
      if (pol != null) {
        this.populatePolicyData(this.currentPolicyInStore);
      }
    });

    //Endorsement Store
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.isUpdateENCalled = present;
    });

    this.store.select(selectCurrentEndorsement).subscribe(en => {
      this.currentENInStore = en;
      if (en != null) {
        this.populatePolicyData(this.currentENInStore);
      }
    });
  }

  // getPolicyData() {
  //   this.dataBusService.policyData
  //     .pipe(filter((data) => data !== undefined))
  //     .subscribe((dataBehv) => {
  //       this.policyAllData = dataBehv;
  //       if (dataBehv && dataBehv.premiums !== undefined) {
  // this.siteName = dataBehv.autoClub;
  // this.isLoaderTrue = false;
  // this.isLoadeing = 'No';
  // this.displayPromoAlertRemove = 'none';
  // this.policySummary = dataBehv.premiums;
  // var dateObject = this.policyService.utcToAestDate('17/11/2021');
  // const PurchaseDate = this.policyService.utcToAestDate(
  //   sessionStorage.getItem('PurchaseDate') || '',
  // );
  // const date2 = new Date(PurchaseDate);
  // let differnceDate = Utility.differenceInDays(date2, dateObject);
  // if (
  //   this.siteName?.toUpperCase() == PortalCodeEnum.RACV.toUpperCase() &&
  //   differnceDate <= 0
  // ) {
  //   if (
  //     this.policySummary.CoverageType.toLocaleLowerCase() ==
  //     'total travel care'
  //   ) {
  //     this.policySummary.CoverageType = 'Comprehensive';
  //   }
  //   if (
  //     this.policySummary.CoverageType.toLocaleLowerCase() ==
  //     'extra travel care'
  //   ) {
  //     this.policySummary.CoverageType = 'Essentials';
  //   }
  //   if (
  //     this.policySummary.CoverageType.toLocaleLowerCase() ==
  //     'travel care'
  //   ) {
  //     this.policySummary.CoverageType = 'Basics';
  //   }
  // }
  // if (
  //   dataBehv.promoCode &&
  //   dataBehv.promoCode !== '' &&
  //   dataBehv.promoCode != undefined
  // ) {
  //   this.displayPromoAlert = 'block';
  //   this.displayPromoAlertAfterPromo = 'none';
  //   this.displayPromoAlertUpdatePromo = 'inline-block';
  //   this.displayPromoAlertRemovePromo = 'inline-block';
  //   this.message =
  //     dataBehv.promoCode +
  //     ' Coupon Applied!';
  // } else {
  //   this.displayPromoAlertAfterPromo = 'inline-block';
  //   this.displayPromoAlertUpdatePromo = 'none';
  //   this.displayPromoAlertRemovePromo = 'none';
  // }

  // this.messageService.messageData.subscribe((data) => {
  //   this.isEditable = data;
  //   if (this.isEditable) {
  //     if (
  //       dataBehv.movements &&
  //       dataBehv.movements.length > 0
  //     ) {
  //       let policyMovementData =
  //         dataBehv.movements[dataBehv.movements.length - 1];
  //       if (
  //         sessionStorage.getItem('movementGuid') ==
  //         policyMovementData.movementId &&
  //         (policyMovementData.TxnTypeCode == 'EN' ||
  //           policyMovementData.TxnTypeCode == 'RW') &&
  //         policyMovementData.Status == 'In Process'
  //       ) {
  //         if (
  //           sessionStorage.getItem('hideAdditional') &&
  //           sessionStorage.getItem('hideAdditional') == 'true'
  //         ) {
  //           this.showAdditionalAmount = false;
  //           this.additionalAmountData = null;
  //         } else {
  //           this.showAdditionalAmount = true;
  //           this.additionalAmountData = policyMovementData.WrittenPrem;
  //         }
  //       } else {
  //         this.showAdditionalAmount = false;
  //         this.additionalAmountData = null;
  //       }
  //     } else {
  //       this.showAdditionalAmount = false;
  //       this.additionalAmountData = null;
  //     }
  //   }
  // });
  // this.getCoverageData();
  //       }
  //     });
  // }

  // getGTAContactId() {
  //   this.policyService.agencyAgentURL().subscribe(
  //     (gtaId) => {
  //       let agenctAgentId = gtaId.find(
  //         (dataId: any) => dataId.ShortName === this.siteName,
  //       );
  //       if (
  //         agenctAgentId &&
  //         agenctAgentId.ContactId &&
  //         agenctAgentId.ContactId !== undefined
  //       ) {
  //         //console.log('===', this.policyContactId);
  //         this.policyContactId = agenctAgentId.ContactId;
  //         this.getCoupneList(this.policyContactId);
  //       }
  //     },
  //     (err) => {
  //       this.logService.createErrorLog(
  //         {},
  //         err,
  //         'AgencyAgentURL',
  //         PageNameEnum.PolicyDetails + ' ' + SubComponentNameEnum.Summary,
  //         LogLevel.Error,
  //       );
  //     },
  //   );
  // }

  // getCoupneList(id: any) {
  //   this.policyService.getCoupan(id).subscribe(
  //     (response) => {
  //       this.listCopne = response.ContactProducerPromoCodes;
  //     },
  //     (err) => {
  //       this.logService.createErrorLog(
  //         {},
  //         err,
  //         'GetCoupon',
  //         PageNameEnum.PolicyDetails + ' ' + SubComponentNameEnum.Summary,
  //         LogLevel.Error,
  //       );
  //     },
  //   );
  // }

  selectionChange(event: any) {
    let value = event.target.value;
    this.clearPromocodeSearchValues();
    //  this.promoCode = value.trim();
    if (this.listCopne) {
      this.listCopne.forEach((element) => {
        if (element['promoCode'] === value) {
          this.searchedPromoCodeList.push(element);
        }
      });
    }
  }


  // getCoverageData() {
  //   this.clearPolicySummary();
  //   if (
  //     this.policyAllData &&
  //     this.policyAllData.UWQuotes &&
  //     this.policyAllData.UWQuotes.length > 0
  //   ) {
  //     this.dataMappingOne = this.policyAllData.UWQuotes.find(
  //       (data: any) => data.LOBs && data.LOBs.length > 0,
  //     );
  //     this.dataMappingTwo = this.dataMappingOne.LOBs.find(
  //       (data: any) => data.Risks && data.Risks.length > 0,
  //     );

  //     this.dataMappingCoverage = this.dataMappingTwo.Risks.find(
  //       (data: any) => data.Coverages && data.Coverages.length > 0,
  //     );
  //     this.dataFind2 = this.dataMappingCoverage.Coverages.find(
  //       (data: any) =>
  //         data.CovTypeCode == 'CRU' && data.DeleteDate == undefined,
  //     );
  //     if (
  //       this.dataFind2 &&
  //       this.dataFind2.CovTypeCode &&
  //       this.dataFind2.CovTypeCode !== undefined
  //     ) {
  //       this.cruiseValue = this.dataFind2.PremiumAmount;
  //     }
  //     this.dataFind3 = this.dataMappingCoverage.Coverages.find(
  //       (data: any) =>
  //         data.CovTypeCode == 'WIN' && data.DeleteDate == undefined,
  //     );
  //     if (
  //       this.dataFind3 &&
  //       this.dataFind3.CovTypeCode &&
  //       this.dataFind3.CovTypeCode !== undefined
  //     ) {
  //       this.skingValue = this.dataFind3.PremiumAmount;
  //     }
  //     this.promoDisResult = this.dataMappingCoverage.Coverages.find(
  //       (data: any) =>
  //         data.CovDescription == 'Promo Code Discount' &&
  //         data.DeleteDate == undefined,
  //     );
  //     if (
  //       this.promoDisResult &&
  //       this.promoDisResult.CovDescription &&
  //       this.promoDisResult.CovDescription !== undefined
  //     ) {
  //       this.promocodeDiscountValue = this.replaceMinus(
  //         this.promoDisResult.PremiumAmount.toString(),
  //       );
  //     }

  //     this.memberShipDisResult = this.dataMappingCoverage.Coverages.find(
  //       (data: any) =>
  //         data.CovDescription == 'Membership Discount' &&
  //         data.DeleteDate == undefined,
  //     );
  //     if (
  //       this.memberShipDisResult &&
  //       this.memberShipDisResult.CovDescription &&
  //       this.memberShipDisResult.CovDescription !== undefined
  //     ) {
  //       this.memberShipDiscountValue = this.replaceMinus(
  //         this.memberShipDisResult.PremiumAmount.toString(),
  //       );
  //     }

  //     let maxTripLengthAmount = this.dataMappingCoverage.Coverages.find(
  //       (data: any) =>
  //         data.CovTypeCode == 'MTL' && data.DeleteDate == undefined,
  //     );
  //     if (
  //       maxTripLengthAmount &&
  //       maxTripLengthAmount.CovDescription &&
  //       maxTripLengthAmount.CovDescription !== undefined
  //     ) {
  //       this.maxTripLengthAmountValue = maxTripLengthAmount.PremiumAmount;
  //       this.maxTripLengthAmountDiscrip = maxTripLengthAmount.CovDescription;
  //     }

  //     let lobDataElement = this.policyAllData.UWQuotes.find(
  //       (data: any) => data.LOBs && data.LOBs.length > 0,
  //     );
  //     let riskDataElement = lobDataElement.LOBs.find(
  //       (data: any) => data.Risks && data.Risks.length > 0,
  //     );
  //     let ElementUpdateRequests =
  //       riskDataElement.Risks[0].RiskDataElements.ElementUpdateRequests;
  //     let excessData = ElementUpdateRequests.find(
  //       (data: any) => data.ElementName == 'Excess',
  //     );
  //     if (
  //       excessData &&
  //       excessData.ElementName &&
  //       excessData.ElementName !== undefined
  //     ) {
  //       this.selectedExcessName = excessData.FreeTextValue;
  //     }
  //     let region = Utility.typeSafeHandle(
  //       this.policyAllData,
  //       'AdditionalPolicyDetailsGTA.QuoteDetails.Region',
  //     );
  //     let quoteName = Utility.typeSafeHandle(
  //       this.policyAllData,
  //       'AdditionalPolicyDetailsGTA.QuoteDetails.QuoteName',
  //     );
  //     let IsDomestic = region == 'Domestic' ? true : false;
  //     this.excessLebel = 'Excess';
  //     if (IsDomestic && quoteName === 'Tier1') {
  //       this.selectedExcessName = Utility.typeSafeHandle(
  //         this.policyAllData,
  //         'AdditionalPolicyDetailsGTA.QuoteDetails.RentalBaseExcess',
  //       );
  //       this.excessLebel = 'Rental Car excess';
  //     }
  //   }
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (this.policySummary && this.policySummary === undefined) {
      this.isLoaderTrue = true;
    }
    if (
      changes['policySummary'] &&
      changes['policySummary'].currentValue !== undefined
    ) {
      this.policySummary = changes['policySummary'].currentValue;
      this.isLoaderTrue = false;
    }
  }

  checkPromocodeAuthenticity(processType: string, couponValue?: string) {
    this.promoCodeInput = couponValue;
    //console.log('===', this.promoCode.trim().length);
    if (processType === 'add') {
      if (
        this.promoCode.trim().length === 0 &&
        this.promoCodeInput &&
        this.promoCodeInput.trim().length > 0
      ) {
        this.promoCode = this.promoCodeInput;
        this.couponValidate(processType);
        // } else if (
        //   this.promoCode.trim().length > 0 &&
        //   this.promoCodeInput &&
        //   this.promoCodeInput.trim().length > 0
        // ) {
        //   this.errorMessage = 'Please choose only one option';
        //   return;
      } else if (
        this.promoCode.trim().length === 0 &&
        (!this.promoCodeInput || this.promoCodeInput.trim().length === 0)
      ) {
        this.errorMessage = 'Please enter promocode';
        return;
      } else {
        this.prepareRequestForEndorsement(processType);
      }
    } else {
      this.promoCode = '';
      this.prepareRequestForEndorsement(processType);
    }
  }

  couponValidate(processType: string) {
    this.isLoader = true;
    this.displayPromoAlert = 'none';
    let promoRequestDate;
    // if (this.policyContactId) 
    //   {
    //convert date from any local to aest
    // const pricingDate = this.dateService.getDDMMYYYYFromDate(
    //   this.policyService.utcToAestDate(
    //     sessionStorage.getItem('PricingDate') || '',
    //   ),
    // );
    // if (pricingDate != '') {
    //   promoRequestDate = pricingDate.replace(/[^0-9]/g, '');
    // } else {
    //   promoRequestDate = this.dateService
    //     .getDDMMYYYYFromDate(
    //       this.policyService.utcToAestDate(new Date().toString()),
    //     )
    //     .replace(/[^0-9]/g, '');
    // }
    this.policyService
      .validateCoupon(
        this.currentPolicyInStore.autoClub,
        this.promoCode,
      )
      .pipe(
        filter((res) => res !== undefined),
        first(),
      )
      .subscribe(
        (data) => {
          if (data) {
            this.displayPromoAlert = 'block';
            this.displayPromoAlertRemove = 'none';

            if (
              data.isValid === true) {

              this.prepareRequestForEndorsement(processType);
            }
            else if (data.isValid === false) {
              this.promoCode = '';
              this.message = '';
              this.isLoader = false;
              this.errorMessage =
                data.message || 'Invalid Promo Code.';
              this.isLoader = false;
            }
            else {
              this.promoCode = '';
              this.message = '';
              this.errorMessage = data.message || 'Invalid Promo Code.';
              this.isLoader = false;
            }
          }
        },
        (err) => {
          this.isLoader = false;
        },
      );
    // } else {
    //   this.prepareRequestForEndorsement(processType);
    // }
  }

  prepareRequestForEndorsement(operationType: string) {
    this.errorMessage = '';
    this.isLoadeingQuoteDetails = true;
    this.displayPromoAlert = 'none';
    this.isLoadeing = 'Yes';
    this.isLoader = true;

    if (
      sessionStorage.getItem('movementGuid') &&
      sessionStorage.getItem('originalMovementGuid') &&
      sessionStorage.getItem('movementGuid') !==
      sessionStorage.getItem('originalMovementGuid')
    ) {
      this.applyPromocode(operationType);
      return;
    }
    // this.endorsementGenericService
    //   .prepareRequestForEndorsement(sessionStorage.getItem('movementGuid'))
    //   .then((data) => {
    //     this.updateObject = data;
    //     if (this.updateObject !== undefined) {
    //       this.updateObject.RequestDetails.PromoCode = this.promoCode;
    //       this.updatePromo = this.updateObject;
    //       let objEndReq = {};
    //       if (
    //         localStorage.getItem('versionNum') &&
    //         localStorage.getItem('versionNum') !== undefined
    //       ) {
    //         objEndReq = {
    //           PolicyId: this.policyAllData.PolicyId.toString(),
    //           PolicyTxnId: this.policyAllData.PolicyTxnId.toString(),
    //           VersionNum: localStorage.getItem('versionNum'),
    //           PolicyNum: this.policyAllData.PolicyNum,
    //           SubCompanyCode: 'GTA',
    //         };
    //       } else {
    //         objEndReq = {
    //           PolicyId: this.policyAllData.PolicyId.toString(),
    //           PolicyTxnId: this.policyAllData.PolicyTxnId.toString(),
    //           VersionNum: '1',
    //           PolicyNum: this.policyAllData.PolicyNum,
    //           SubCompanyCode: 'GTA',
    //         };
    //       }
    //       this.endorsementGenericService.createEndorsement(objEndReq).subscribe(
    //         (createEndData) => {
    //           this.updatePromo.VersionNum = createEndData.VersionNum;
    //           this.endorsementGenericService
    //             .updateEndorsement(this.updatePromo)
    //             .pipe(filter((data) => data !== undefined))
    //             .subscribe(
    //               (updateEndData) => {
    //                 this.afterUpdatePromo = updateEndData;
    //                 if (
    //                   updateEndData &&
    //                   updateEndData.ResponseStatus &&
    //                   updateEndData.ResponseStatus.ErrorCode !== undefined
    //                 ) {
    //                   this.isLoadeing = 'No';
    //                   this.isLoader = false;
    //                   this.displayPromoAlert = 'none';
    //                   this.displayPromoAlertRemove = 'block';
    //                   this.messageRemovePromo =
    //                     'Something went wrong please try again!!';
    //                 } else {
    //                   if (
    //                     updateEndData &&
    //                     updateEndData.PolicyTxnId !== undefined
    //                   ) {
    //                     sessionStorage.setItem(
    //                       'policytxnid',
    //                       this.afterUpdatePromo.PolicyTxnId,
    //                     );
    //                   }
    //                   this.applyPromocode(operationType);
    //                 }
    //               },
    //               (err) => {
    //                 this.isLoader = false;
    //                 this.logService.createErrorLog(
    //                   objEndReq,
    //                   err,
    //                   'UpdateEndorsement',
    //                   PageNameEnum.PolicyDetails +
    //                   ' ' +
    //                   SubComponentNameEnum.Summary,
    //                   LogLevel.Error,
    //                 );
    //               },
    //             );
    //         },
    //         (err) => {
    //           this.isLoader = false;
    //           this.logService.createErrorLog(
    //             objEndReq,
    //             err,
    //             'CreateEndorsement',
    //             PageNameEnum.PolicyDetails + ' ' + SubComponentNameEnum.Summary,
    //             LogLevel.Error,
    //           );
    //         },
    //       );
    //     }
    //   });
  }

  async applyPromocode(type: string) {
    this.isLoadeing = 'Yes';
    this.isLoader = true;

    //Check for RSXLogging
    // reqData.IsRSXLogActive = this.checkforRSXLogging();
    let updateRequest: any = {}
    if (this.currentENInStore) {
      Object.assign(updateRequest, this.currentENInStore);
    }
    updateRequest.promoCode = this.promoCode;
    updateRequest = await this.mapperService.mapEndorsementModel(updateRequest, "revision");
    this.policyService.updateEndorsement(updateRequest).subscribe((resdata) => {
      if (resdata) {
        this.storeService.setENToStore(resdata);
        sessionStorage.setItem('movementGuid', resdata.movementGuid);
        this.isLoader = false;
        this.isLoadeing = 'No';
        this.displayPromoAlert = 'none';
        this.displayPromoAlertRemove = 'block';
        this.displayPromoAlertUpdatePromo = 'block';
        this.message =
          'The promo code ' + this.promoCode + ' has been added successfully';
        this.errorMessage = '';
        this.isToaster = true;
        if (type == 'add') {
          this.updateNewDataWithPromocodeAddition();
          this.closeModal();
        } else {
          this.updateDataWithRemovePromocode();
        }

      } else {
        this.isLoadeing = 'No';
        this.isLoader = false;
        this.message = '';
        this.errorMessage = resdata.ErrorMessage || 'Invalid Promo Code.';
        this.isToaster = false;
      }
    },
      (err) => {
        this.isLoader = false;
      },
    );
  }

  updateNewDataWithPromocodeAddition() {
    // this.dataBusService.publishPolicyDetails(false, sessionStorage.getItem('movementGuid') || '');
    // this.messageService.publishMessage(true);
    this.dataBusService.policyData.subscribe((dataBehv) => {
      this.isLoadeingQuoteDetails = false;
      this.policyAllData = dataBehv;
      this.policySummary = dataBehv.premiums;
      var dateObject = this.policyService.utcToAestDate('17/11/2021');
      const PurchaseDate = this.policyService.utcToAestDate(
        sessionStorage.getItem('PurchaseDate') || '',
      );
      const date2 = new Date(PurchaseDate);
      // let differnceDate= (Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate())) / (1000 * 60 * 60 * 24)
      // ));
      let differnceDate = Utility.differenceInDays(date2, dateObject);
      if (
        this.siteName.toUpperCase() == PortalCodeEnum.RACV.toUpperCase() &&
        differnceDate <= 0
      ) {
        if (
          this.policySummary.CoverageType.toLocaleLowerCase() ==
          'total travel care'
        ) {
          this.policySummary.CoverageType = 'Comprehensive';
        }
        if (
          this.policySummary.CoverageType.toLocaleLowerCase() ==
          'extra travel care'
        ) {
          this.policySummary.CoverageType = 'Essentials';
        }
        if (
          this.policySummary.CoverageType.toLocaleLowerCase() == 'travel care'
        ) {
          this.policySummary.CoverageType = 'Basics';
        }
      }
      this.displayPromoAlertRemove = 'none';
      if (
        dataBehv &&
        dataBehv.promoCode &&
        dataBehv.promoCode !== ''
      ) {
        this.displayPromoAlert = 'block';
        this.displayPromoAlertAfterPromo = 'none';
        this.displayPromoAlertUpdatePromo = 'inline-block';
        this.displayPromoAlertRemovePromo = 'inline-block';
        this.message =
          dataBehv.promoCode + ' Coupon Applied!';
      } else {
        this.displayPromoAlertAfterPromo = 'inline-block';
        this.displayPromoAlertUpdatePromo = 'none';
        this.displayPromoAlertRemovePromo = 'none';
      }
    });
  }

  updateDataWithRemovePromocode() {
    // this.dataBusService.publishPolicyDetails();
    // this.messageService.publishMessage(true);
    this.dataBusService.policyData.subscribe((dataBehv) => {
      // new addition
      this.isLoadeingQuoteDetails = false;
      this.isLoadeing = 'No';
      this.policyAllData = dataBehv;
      this.policySummary = dataBehv.premiums;
      var dateObject = this.policyService.utcToAestDate('17/11/2021');
      const PurchaseDate = this.policyService.utcToAestDate(
        sessionStorage.getItem('PurchaseDate') || '',
      );
      const date2 = new Date(PurchaseDate);
      // let differnceDate= (Math.floor((Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate()) - Date.UTC(dateObject.getFullYear(), dateObject.getMonth(), dateObject.getDate())) / (1000 * 60 * 60 * 24)
      // ));
      let differnceDate = Utility.differenceInDays(date2, dateObject);
      if (
        this.siteName.toUpperCase() == PortalCodeEnum.RACV.toUpperCase() &&
        differnceDate <= 0
      ) {
        if (
          this.policySummary.CoverageType.toLocaleLowerCase() ==
          'total travel care'
        ) {
          this.policySummary.CoverageType = 'Comprehensive';
        }
        if (
          this.policySummary.CoverageType.toLocaleLowerCase() ==
          'extra travel care'
        ) {
          this.policySummary.CoverageType = 'Essentials';
        }
        if (
          this.policySummary.CoverageType.toLocaleLowerCase() == 'travel care'
        ) {
          this.policySummary.CoverageType = 'Basics';
        }
      }
      if (
        dataBehv &&
        dataBehv.promoCode &&
        dataBehv.promoCode !== ''
      ) {
        this.displayPromoAlert = 'none';
        this.displayPromoAlertRemove = 'block';
        this.messageRemovePromo = 'PromoCode Successfully Removed!!';
        this.displayPromoAlertAfterPromo = 'none';
        this.displayPromoAlertUpdatePromo = 'inline-block';
        this.displayPromoAlertRemovePromo = 'inline-block';
      } else {
        this.displayPromoAlertRemove = 'block';
        this.displayPromoAlertAfterPromo = 'inline-block';
        this.displayPromoAlertUpdatePromo = 'none';
        this.displayPromoAlertRemovePromo = 'none';
      }
    });
  }

  openModal() {
    if (this.siteName) {
      let clubGuid = this.userRoleDetails?.AutoClubURLList.find((club: any) =>
        club.key === this.currentPolicyInStore.autoClub)?.autoClubGuid;
      let reqObj =
      {
        isPaginationEnabled: false,
        pageNo: 0,
        pageSize: 0,
        promoCode: "",
        autoClubGuid: clubGuid,
        effectiveDate: "",
        expirationDate: "",
        loginAgentGuid: this.userRoleDetails?.AgentContactId
      }
      this.promoCodeService.getOctaAgencyPortalApiServiceList(reqObj).subscribe(
        (data) => {
          if (data != undefined) {
            this.listCopne = data.response;
          }
        });
      //this.getGTAContactId();
    }
    this.displayPromoAlertRemove = 'none';
    this.promoCode = '';
    this.clearPromocodeSearchValues();
    this.modalRef = this.modalService.open(this.dialogContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeModal() {
    this.clearPromocodeSearchValues();
    this.modalRef.close();
    this.clearSearch();
  }

  clearSearch() {
    this.promoCodeInput = '';
  }

  closeAlert() {
    this.displayPromoAlert = 'none';
    this.displayPromoAlertRemove = 'none';
  }

  toFixed(num: any, fixed: any) {
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  replaceMinus(data: string) {
    if (data && data.includes('-')) {
      return data.replace('-', '');
    } else {
      return data;
    }
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.promoCodeSearchForm.controls;
  }

  atLeastOneValidator = () => {
    return (controlGroup: any) => {
      let controls = controlGroup.controls;
      if (controls) {
        let theOne = Object.keys(controls).find(
          (key) =>
            controls[key].value !== null &&
            controls[key].value !== '' &&
            controls[key].valid,
        );
        //console.log(theOne);
        if (!theOne) {
          return {
            atLeastOneRequired: {
              text: 'At least one should be selected',
            },
          };
        }
      }
      //console.log('called');
      return null;
    };
  };

  setDateFormat(date: any) {
    let ngbDate = date;
    if (date != null && typeof date === 'object') {
      let datevalue =
        ngbDate['month'].toString() +
        '/' +
        ngbDate['day'].toString() +
        '/' +
        ngbDate['year'].toString();
      return datevalue;
    } else {
      return ngbDate;
    }
  }

  checkDateCompare(dateinput1: any, dateinput2: any) {
    let date1 = new Date(dateinput1);
    let date2 = new Date(dateinput2);
    if (
      date1.getFullYear() == date2.getFullYear() &&
      date1.getMonth() == date2.getMonth() &&
      date1.getDate() == date2.getDate()
    ) {
      return true;
    } else {
      return false;
    }
  }

  clearPromocodeSearchValues() {
    this.submitted = false;
    this.searchedPromoCodeList = [];
    this.promoCodeInput = '';
    this.errorMessage = '';
    this.noDataFound = false;
  }

  onSubmit() {
    this.clearPromocodeSearchValues();
    this.submitted = true;
    // stop here if form is invalid
    if (this.promoCodeSearchForm.invalid) {
      return;
    }
    this.searchedPromoCodeList = [];
    const values = this.promoCodeSearchForm.value;
    const keys = Object.keys(values);
    if (this.listCopne) {
      keys.forEach((element) => {
        this.listCopne.forEach((ele) => {
          if (ele[element]) {
            if (element === 'effectiveDate') {
              if (values[element] && values[element] != null) {
                let checkResult = this.checkDateCompare(
                  ele[element],
                  this.setDateFormat(values[element]),
                );
                if (checkResult) {
                  this.searchedPromoCodeList.push(ele);
                }
              }
            } else if (element === 'promoCode') {
              if (values[element] && values[element] != null) {
                if (
                  ele[element]
                    .toString()
                    .trim()
                    .toLowerCase()
                    .includes(values[element].toString().trim().toLowerCase())
                ) {
                  this.searchedPromoCodeList.push(ele);
                }
              }
            } else {
              if (
                ele[element].toString().trim().toLowerCase() ===
                values[element].toString().trim().toLowerCase()
              ) {
                this.searchedPromoCodeList.push(ele);
              }
            }
          }
        });
      });
      if (this.searchedPromoCodeList.length === 0) {
        this.noDataFound = true;
      }
    }
  }

  // checkforRSXLogging() {
  //   let value = false;
  //   let itemData = this.checkForItemServiceData();
  //   if (itemData != undefined) {
  //     //Check for RSXLogging
  //     const genericSettingsItemId =
  //       this.itemService.getItemServiceID('GenericSettings');
  //     const genericSettingsData = itemData[genericSettingsItemId];
  //     value =
  //       genericSettingsData != null &&
  //         genericSettingsData.IsRSXLogActive != null &&
  //         genericSettingsData.IsRSXLogActive.toLowerCase() == 'true'
  //         ? true
  //         : false;
  //   }
  //   return value;
  // }

  // checkForItemServiceData() {
  //   let itemData: any;
  //   if (
  //     sessionStorage.getItem('itemServiceResponseData') &&
  //     sessionStorage.getItem('itemServiceResponseData') != '{}'
  //   ) {
  //     itemData = JSON.parse(
  //       sessionStorage.getItem('itemServiceResponseData') || '',
  //     );
  //   } else {
  //     // this.callItemData();
  //     itemData = JSON.parse(
  //       sessionStorage.getItem('itemServiceResponseData') || '',
  //     );
  //   }
  //   return itemData;
  // }

  // async callItemData() {
  //   const initialLoad = [
  //     'StateList',
  //     'PASURL',
  //     'StepThreeAPICall',
  //     'GenericSettings',
  //     'CoverageDefaultSettings',
  //     'ThemeColor',
  //     'TripTypeLebeling',
  //   ];

  //   let itemIdsData = initialLoad.map((currentItemName) => {
  //     if (
  //       currentItemName == 'StateList' ||
  //       currentItemName == 'StepThreeAPICall'
  //     ) {
  //       return {
  //         Name: currentItemName,
  //         ItemId: this.itemService.getItemServiceID(currentItemName),
  //         IsChildren: true,
  //       };
  //     } else {
  //       return {
  //         Name: currentItemName,
  //         ItemId: this.itemService.getItemServiceID(currentItemName),
  //       };
  //     }
  //   });
  //   const payload: any = {
  //     step: 'policyDataPasId' + sessionStorage.getItem('movementGuid'),
  //     data: itemIdsData,
  //   };
  //   const res: any = await this.itemService
  //     .getDataByScItemService(payload)
  //     .toPromise();
  //   if (res) {
  //     sessionStorage.setItem(
  //       'itemServiceResponseData',
  //       JSON.stringify(Utility.lowerCaseObjectKeys(res)),
  //     );
  //   }
  // }

  //LTX 
  populatePolicyData(currentPolicyInStore: any) {
    this.policyAllData = currentPolicyInStore;
    this.selectedExcessName = currentPolicyInStore.excess;
    if (currentPolicyInStore && currentPolicyInStore.premiums !== undefined) {
      this.siteName = currentPolicyInStore.autoClub;
      this.isLoaderTrue = false;
      this.isLoadeing = 'No';
      // this.displayPromoAlertRemove = 'none';
      this.policySummary = currentPolicyInStore.premiums;

      if(this.policySummary.multiTripLength > 0 && currentPolicyInStore.multiTripLength.length > 0){
        this.maxTripLengthAmountDiscrip = currentPolicyInStore.multiTripLength + ' Max Trip Length';
        this.maxTripLengthAmountValue = this.policySummary.multiTripLength;
      }

      if (currentPolicyInStore.promoCode && currentPolicyInStore.promoCode !== '' && currentPolicyInStore.promoCode != undefined) {
        this.displayPromoAlert = 'block';
        this.displayPromoAlertAfterPromo = 'none';
        this.displayPromoAlertUpdatePromo = 'inline-block';
        this.displayPromoAlertRemovePromo = 'inline-block';
        this.message = currentPolicyInStore.promoCode + ' Coupon Applied!';
      }
      else {
        this.displayPromoAlertAfterPromo = 'inline-block';
        this.displayPromoAlertUpdatePromo = 'none';
        this.displayPromoAlertRemovePromo = 'none';
      }

      if (this.isUpdateENCalled) {
        if (currentPolicyInStore.movements && currentPolicyInStore.movements.length > 0) {
          let sortedMovements = currentPolicyInStore.movements.sort(function (a: { version: number }, b: { version: number }) { return a.version - b.version });
          let policyMovementData = sortedMovements[sortedMovements.length - 1];
          if (sessionStorage.getItem('movementGuid') == policyMovementData.movementId && policyMovementData.type == 'EN' && policyMovementData.status == 'Pending') {
            if (sessionStorage.getItem('hideAdditional') && sessionStorage.getItem('hideAdditional') == 'true') {
              this.showAdditionalAmount = false;
              this.additionalAmountData = null;
            }
            else if (currentPolicyInStore.premiums && currentPolicyInStore.premiums.additionalPremium != 0) {
              this.showAdditionalAmount = true;
              this.additionalAmountData = currentPolicyInStore.premiums.additionalPremium;
            }
            else {
              this.showAdditionalAmount = false;
              this.additionalAmountData = null;
            }
          }
          else {
            this.showAdditionalAmount = false;
            this.additionalAmountData = null;
          }
        }
        else {
          this.showAdditionalAmount = false;
          this.additionalAmountData = null;
        }
      }
      // this.setCoverageData();
    }
  }
}
