import { Injectable } from '@angular/core';
import { WebService } from '../services/web.service';
import { EndPoint } from '../services/endpoint';
import { Store } from '@ngrx/store';
import { loginUser } from '../store/loginUser/loginUser.selectors';
import { UserProfile } from '../store/loginUser/loginUser.model';
import { PaymentGatewayService } from 'projects/paymentGateWay/src/app/payment-gateway.service';

@Injectable({
  providedIn: 'root',
})
export class CXOneService {
  public siteName: string;
  public loggedInUserName: string = "";
  public loggedInUserEmail: string = "";
  public maskCallUrl: string;
  public unMaskCallUrl: string;
  public maskingSession: any;
  public enableMasking: boolean = false;
  public maskCallTriggered ?: boolean ;
  userProfile?: UserProfile;

  constructor(private webService: WebService, private store: Store, private paymentGatewayService: PaymentGatewayService) {
    this.siteName = sessionStorage.getItem('portalcode') || '';
    this.maskCallUrl = EndPoint.PAS_CXONE_MASK_URL;
    this.unMaskCallUrl = EndPoint.PAS_CXONE_UNMASK_URL;
    if (localStorage.getItem('mask')) {
      this.maskingSession = JSON.parse( atob(localStorage.getItem('mask') || ''), );
    }
    // if ( this.siteName === 'RAC' && this.loggedInUserEmail !== undefined && this.loggedInUserEmail !== null ) {
    //   console.log('CXOne Service agentEmail', this.loggedInUserEmail);
    //   setTimeout(() => {
    //     this.checkWindowUrl();
    //   }, 500);
    // }

    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userProfile = loginUser.userProfile;
        this.loggedInUserName = this.userProfile?.LoggedUser;
        this.loggedInUserEmail = this.userProfile?.ContactEmail;
        console.log('CXOne Service agentEmail', this.loggedInUserEmail);
      }
    });

    this.paymentGatewayService.cxOneCallStatus$.subscribe((cxOneModel: CxOneModel) => {
      this.enableMasking = cxOneModel.isCallEnabled;
      this.maskCallTriggered = cxOneModel.maskCallTriggered;
      if ( this.siteName === 'RAC' && this.loggedInUserEmail !== undefined && this.loggedInUserEmail !== null && this.enableMasking) {
        this.checkWindowUrl();
      }
    });
  }

  checkWindowUrl() {
    let url = window.location.href;
    let step4 = url.includes('paymentsummary');
    if (step4 && this.enableMasking) {
      if (!this.maskingSession && this.maskCallTriggered) {
        this.maskCall();
      }
      else if (this.maskingSession && !this.maskCallTriggered) {
        this.unMaskCall();
      }
    }
  }

  maskCall() {
    const requestObj = {
      user: this.loggedInUserEmail,
      sitename: this.siteName,
    };
    this.webService.cxOnePostRequest(this.maskCallUrl, requestObj).subscribe((res) => {
        if (res.status == 202) {
          this.maskingSession = res.maskingSession;
          localStorage.setItem( 'mask', btoa(JSON.stringify(this.maskingSession)), );
        }
        else if (res.status != 424) {
          this.checkWindowUrl();
        }
      });
  }

  unMaskCall() {
    const requestobj = {
      sessionid: this.maskingSession.sessionid,
      contactid: this.maskingSession.contactid,
      sitename: this.siteName,
    };
    this.maskingSession = null;
    localStorage.removeItem('mask');
    this.webService.cxOnePostRequest(this.unMaskCallUrl, requestobj).subscribe((res) => { 
      if (res.meta.statusCode == 202) { 

      } 
      this.checkWindowUrl(); 
    });
  }
}

export interface CxOneModel {
  isCallEnabled: boolean,
  maskCallTriggered? : boolean
}
