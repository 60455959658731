import { createAction, props } from '@ngrx/store';
import { Endorsement } from './endorsement.model';

export const fetchEndorsement = createAction(
    'Fetch Endorsement',
    props<{ policyGuid : string , movementGuid : string}>()
)

export const setEndorsement = createAction(
    'Set Endorsement',
    props<{ endorsement : Endorsement}>()
)

export const updateEndorsement = createAction(
    'Update Endorsement',
    props<{ endorsement : Partial<Endorsement>}>()
)

export const discardEndorsement = createAction(
    'Discard Endorsement',
    props<{ endorsement : Endorsement}>()
)