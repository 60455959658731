/// <reference path="../../../../src/app/core/role/setqueryparams.ts" />
/// <reference path="../../../../src/app/core/role/checkrolestatus.ts" />
/// <reference path="app.service.ts" />
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { HeaderComponent } from './app.component';
export { HeaderComponent } from './app.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
  ],
})
export class HeaderModule {}
