import { environment } from "src/environments/environment";
import { EndPoint } from "../services/endpoint";
var CryptoJS = require("crypto-js");

export class CryptoService {
  static b64EncodeUnicode(str: any) {
    return btoa(
      encodeURIComponent(str).replace(
        /%([0-9A-F]{2})/g,
        (match, p1) => {
          return String.fromCharCode(('0x' + p1) as any);
        },
      ),
    );
  }

  static b64DecodeUnicode(str: any) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );
  }

  static encryptData(requestBody : string) {  
    let envVersionNumber = EndPoint.envVersionNumber;                                          // Base64 encoded ciphertext, 32 bytes string as key
    var key = CryptoJS.enc.Utf8.parse(envVersionNumber);                                          // Convert into WordArray (using Utf8)
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);                             // Use zero vector as IV
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(requestBody), key, { iv: iv });  // By default: CBC, PKCS7
    return encrypted.toString();                                                                  // Convert into string (using Utf8)
  }

  static decryptData(requestBody : string) {  
    let envVersionNumber = EndPoint.envVersionNumber;                    // Base64 encoded ciphertext, 32 bytes string as key
    var key = CryptoJS.enc.Utf8.parse(envVersionNumber);                    // Convert into WordArray (using Utf8)
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);       // Use zero vector as IV
    var decrypted = CryptoJS.AES.decrypt(requestBody, key, { iv: iv });     // By default: CBC, PKCS7
    return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));               // Convert into string (using Utf8)                    // Convert into string (using Utf8)
  }
}
