import { createReducer, on, Action  } from "@ngrx/store";
import { fetchEndorsement, setEndorsement, updateEndorsement, discardEndorsement } from "./endorsement.actions";
import { Endorsement } from "./endorsement.model";

export interface EndorsementState {
    endorsement  : Endorsement | null;
}

export const initialState : EndorsementState  = {
    endorsement : null
};


const _endorsementReducer = createReducer(
    initialState,
    on(setEndorsement, (state, {endorsement}) =>({
        ...state,
        endorsement
    })),
    on(updateEndorsement, (state, { endorsement }) => ({
        ...state,
        endorsement : { ...state.endorsement, ...endorsement } as Endorsement
      })),
      on(discardEndorsement, state => ({
        ...state,
        book: null
      }))
);

export function endorsementReducer(state: EndorsementState | undefined, action: Action) {
    return _endorsementReducer(state, action);
}