import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LocalCacheService {
  constructor() { }

  public saveLocalStorage(keyName: string, jsonObject: any) {
    localStorage.setItem(keyName, JSON.stringify(jsonObject));
  }

  public getLocalStorage(keyName: string) {
    return localStorage.getItem(keyName) || '';
  }

  public saveSessionStorage(keyName: string, jsonObject: any) {
    sessionStorage.setItem(keyName, JSON.stringify(jsonObject));
  }

  public getSessionStorage(keyName: string) {
    const jsonObject = sessionStorage.getItem(keyName);
    try {
      return jsonObject ? JSON.parse(jsonObject) : null;
    }
    catch {
      return null;
    }
  }

  public removeSessionStorage(keyName: string) {
    sessionStorage.removeItem(keyName);
  }

  public getSessionStorageObservable(keyName: string): Observable<any> {
    return new Observable((observer) => {
      const jsonObject = sessionStorage.getItem(keyName);
      observer.next(jsonObject ? JSON.parse(jsonObject) : null);
    });
  }

  public clearAllSession() {
    sessionStorage.clear();
  }

}
