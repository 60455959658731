import { CryptoService } from './cryptoservice/cryptoservice';
import { DateService } from './services/date.service';
import { environment } from 'src/environments/environment';



 export class ClubAndBranchInfo {
  public static roleInfo = sessionStorage.getItem('navSecurityRole') != null ? JSON.parse(sessionStorage.getItem('navSecurityRole') || '') : null ;
  public static decryptedObj = (ClubAndBranchInfo.roleInfo != null) ? CryptoService.decryptData(ClubAndBranchInfo.roleInfo.data) : null;
  public static finalClubBranchInfo = (ClubAndBranchInfo.roleInfo != null ) ? ClubAndBranchInfo.roleInfo.data = (ClubAndBranchInfo.decryptedObj != null) ? ClubAndBranchInfo.decryptedObj : null : null;

  public static SetClubandbranchInfoData: any = ClubAndBranchInfo.roleInfo ;
  public static SetClubandbranchInfo = ClubAndBranchInfo.SetClubandbranchInfoData;


  public static Clubs = ClubAndBranchInfo.SetClubandbranchInfo != null && ClubAndBranchInfo.SetClubandbranchInfo != undefined ? ClubAndBranchInfo.SetClubandbranchInfo.Clubs : '';

  public static BranchName =''; 
}

export class CommonParams {
  public static allauthresponse = JSON.parse(
    localStorage.getItem('allauthresponse') || '{}',
  );
  public static SubCompanyCode = 'GTA';
  // public static PortalCode = "RACV";
  public static SiteName = 'RACV';
  public static FormSetId = '10709';
  public static Duration = 'Last3Month';
  public static ActivityDate = DateService.setPostActiveDateFormat();

  // public static Xapikey = environment.xapikey;
  public static $G_Username = environment.$G_Username;
  // public static veriskUrl = environment.veriskUrl;
  // public static OktaAuthorizationKey = environment.OktaAuthorizationKey;
  public static BraintreeClientToken = environment.BraintreeClientToken;
  // public static RSXApiKey = environment.RSXAPI_KEY;
}

export interface JwtToken {
  token_type: string;
  expires_in: number;
  access_token: string;
  expires_at?: string;
}
