import { Injectable } from '@angular/core';
import { AgentSearchParams } from 'projects/manageusers/src/app/app.model';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private clubDropdownChangedSource = new Subject<any>();
  clubDropdownChanged$ = this.clubDropdownChangedSource.asObservable();

  private addBranchSubmitSource = new Subject<any>();
  addBranchSubmit$ = this.addBranchSubmitSource.asObservable();

  private agentSearchParamsChangedSource = new BehaviorSubject<any>({});
  agentSearchParamsChanged$ = this.agentSearchParamsChangedSource.asObservable();

  emitClubDropdownChange(data: any) {
    this.clubDropdownChangedSource.next(data);
  }

  emitRefreshTable() {
    this.addBranchSubmitSource.next(null);
  }

  emitAgentSearchChange(params: AgentSearchParams) {
    this.agentSearchParamsChangedSource.next(params);
  }
}
