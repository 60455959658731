<div id="accordion">
  <div class="card">
    <div class="card-header" id="h1">
      <h5 class="mb-0">
        <button class="btn btn-link accordion-note" data-toggle="collapse" (click)="openPolicyNotes()"
          [attr.aria-expanded]="!isNoteCollapsed" [attr.data-target]="'#' + accid" aria-expanded="false"
          aria-controls="accid">
          <i [ngClass]="{ rotate0: !isNoteCollapsed, rotate90: isNoteCollapsed }"
            class="fas fa-chevron-circle-right mr-2 rotate90"></i>
          <span>Notes</span>
        </button>
      </h5>
    </div>

    <div [id]="accid" class="collapse" aria-labelledby="h1" data-parent="#accordion">
      <!-- *ngIf = "generalNote" -->
      <div class="card-body">
        <table id="datatable1" class="table table-striped table-bordered table-dashboard" style="width: 100%"
          *ngIf="generalNote && noteDetail.length > 0">
          <thead>
            <tr>
              <th>ActivityDateTime</th>
              <th>Description</th>
              <th>LoggedInUser</th>
              <th *ngIf="isDeleteNodeVisible">Delete</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of noteDetail">
              <td>{{ item.createdTime }}</td>
              <td class="word-wrap">{{ item.description }}</td>
              <td class="word-wrap">{{ item.loggedInUser }}</td>
              <td *ngIf="isDeleteNodeVisible">
                <a><i class="far fa-trash-alt" (click)="
                      openDeleteNoteModal(item.id, item.description)
                    "></i></a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="text-center card-body" *ngIf="!generalNote && noteDetail?.length == 0 && versionMovementGuid!=undefined">
          No notes found.
        </div>
      </div>
      <div class="text-center card-body" *ngIf="noteDetail == null && !generalNote">
        <span class="spinner-border text-primary"></span>
      </div>

      <!--<ng-container *ngIf="!isReadOnly">-->
      <ng-container>
        <div class="card-body" *ngIf="isNotesRequired">
          <form [formGroup]="noteForm">
            <h2 class="mb-2 box-label-sm">Add a Notes</h2>
            <div class="form-group">
              <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
                placeholder="Add notes here" [ngClass]="{ 'is-invalid': showError }"></textarea>
              <!-- <div *ngIf="showError" class="invalid-feedback">
              <div *ngIf="showError">Please enter Notes</div>
          </div>    -->
              <!-- SJ Changes notes ticket -->
              <div class="invalid-feedback">
                <div *ngIf="
                    showError ||
                    noteForm.controls['Description'].errors?.['isBlank']
                  ">
                  Please enter Notes
                </div>
              </div>
            </div>

            <div class="text-left mt-2 btn-footer">
              <button class="btn btn-primary btn-full-sm" (click)="noteSubmitted()">
                <i class="fas fa-plus-circle mr-1"></i>
                <span [ngClass]="{ 'spinner-border spinner-border-sm': isLoader }" role="status"
                  aria-hidden="false"></span>
                Add Notes
              </button>
            </div>
            <div *ngIf="showMsg">
              <p class="alert alert-success mt-3">
                <strong>Notes Added Successfully</strong>
              </p>
            </div>
            <div *ngIf="showDeleteMsg">
              <p class="alert alert-success mt-3">
                <strong>Note Deleted Successfully</strong>
              </p>
            </div>
            <div *ngIf="errorMsg.length > 0">
              <p class="alert alert-danger mt-3">
                <strong>{{errorMsg}}</strong>
              </p>
            </div>
          </form>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #deleteNoteConformation>
  <div>
    <div class="modal-body">
      <div class="col-md-10 mx-auto text-center">
        <div class="alert my-2">Are you sure you want to delete this item?</div>
      </div>
    </div>
    <div class="text-center mb-4 btn-footer">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="deleteNote()">
        Yes
      </button>
      <button type="button" class="btn btn-primary btn-full-sm" (click)="modalRef.close()">
        No
      </button>
    </div>
  </div>
</ng-template>