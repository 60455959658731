import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WebService {
  constructor(private http: HttpClient) {}

  cxOnePostRequest(url: string, data: any): Observable<any> {
    return this.http.post(url, data).pipe(
      timeout(30000),
      catchError((err: HttpErrorResponse) => {
        return this.handleError(err);
      }),
    );
  }

  /**
   * @method handleError
   * @param error
   * This method is called to handle Error
   */
  private handleError(error: HttpErrorResponse): Observable<any> {
    if (error) {
      if (error.status === 404) {
        return throwError(error.error);
      } else if (error.status === 400 || error.status === 500) {
        if (error.error) {
          if (error.error.apierror) {
            return throwError(error.error.apierror.message);
          } else {
            return throwError(error.statusText);
          }
        } else {
          return throwError(error.statusText);
        }
      } else if (error.status === 409) {
        return throwError(error.error.apierror.message);
      }
      // return throwError(error.statusText);
      return throwError(false);
    }
    return throwError(false);
  }
}
