export class SearchRecentQuotesAndPoliciesGTA {
  SearchFor?: string;
  AgencyId?: string;
  PolicyNum?: string;
  InsuredLastName?: string;
  EffectiveDate?: string;
  ExpirationDate?: string;
  InsuredMobile?: string;
  InsuredEmail?: string;
  AgentName?: string;
  BranchName?: string;
  InsuredFirstName?: string;
  SubCompanyCode?: string;
  IsUnderwriter?: string;
}
