<app-loader></app-loader>
<div class="mt-4">
  <div id="accordion">
    <div class="card">
      <div class="card-header" id="h-refund">
        <h5 class="mb-0">
          <button class="btn btn-link accordion-note" data-toggle="collapse" data-target="#c-refund"
            (click)="openPolicyRefund()" [attr.aria-expanded]="!isRefundCollapsed" aria-expanded="false"
            aria-controls="c-refund">
            <i [ngClass]="{
                rotate0: !isRefundCollapsed,
                rotate90: isRefundCollapsed
              }" class="fas fa-chevron-circle-right mr-2 rotate90"></i>
            <span>Refunds</span>
          </button>
        </h5>
      </div>
      <div id="c-refund" class="collapse" aria-labelledby="h-refund" data-parent="#accordion">
        <div class="card-body">
          <table *ngIf="list && list.length > 0" id="datatable1"
            class="table table-striped table-bordered table-dashboard" style="width: 100%">
            <thead>
              <tr>
                <th>Refd Id</th>
                <th>Pol Txn Id</th>
                <th>Date</th>
                <th>Type</th>
                <th>Amount</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of list">
                <ng-container>
                  <td>{{ item.displayPaymentRefNumber }}</td>
                  <td class="text-center">
                    <span><i class="fas fa-info-circle" title="{{ item.movementId }}"></i></span>
                  </td>
                  <td>{{ item.date | utcToShortDatePipe }}</td>
                  <td>{{ item.methodType }}</td>
                  <td class="text-right">
                    {{ formatprice(item.amount) }}
                  </td>
                  <td>
                    <a class="status-link d-inline-flex align-items-center text-decoration-underline text-primary"
                      *ngIf="item.status === 'Pending'; else notPending" (click)="getPaymentSummary()" role="button"
                      data-toggle="tooltip" title="Initialize the Refund">
                      <i class="fas fa-info-circle mr-2"></i>{{ item.status }}
                    </a>

                    <ng-template #notPending>
                      {{ item.status }}
                    </ng-template>
                  </td>
                </ng-container>
              </tr>
            </tbody>
          </table>
          <div class="text-center" *ngIf="list == null && !isRefundError">
            <span class="spinner-border text-primary"></span>
          </div>
          <div class="card-body" *ngIf="list && list.length == 0 && !isRefundError">
            <!-- {{ list.Validation }} -->
            No data is available
          </div>
          <div class="col-12" [hidden]="!isRefundError">
            <div class="alert alert-success mt-3" role="alert">
              {{ refundErrorMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #manageRefundsTemplate>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">Manage Refunds</h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModalConfirmation()">&times;</button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <table *ngIf="paymentRefundsData && paymentRefundsData.length > 0" id="datatableRefunds"
      class="table table-striped table-bordered table-dashboard" style="width: 100%">
      <thead>
        <tr>
          <th>Transactions</th>
          <th>Transactions Type</th>
          <th>Refund Amount</th>
          <!-- <th class="text-center"></th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paymentRefundsData; let i = index;">
          <td>{{item.paymentRefNumber}}</td>
          <td>
            {{item.method=="CC"?'Credit
            Card':item.method=="CP"?'Credit Card':'Club Payment'}}
          </td>
          <td>{{formatprice(item.amount)}}</td>
          <!-- <td>
            <button
              *ngIf="item.method=='CC' ||  item.method=='PC';"
              type="button"
              class="btn btn-primary btn-sm"
              (click)="confirmRefundPay(item, i)"
              [disabled]="getDisabledStatus(i)"
            >
              {{setRefundText(item,i)}}
            </button>
            <ng-template #abc>
              <span class="text-success">{{item.PaymentStatus}}</span>
            </ng-template>
          </td> -->
        </tr>
      </tbody>
    </table>
    <div class="card-body"
      *ngIf="paymentRefundsData && paymentRefundsData.PaymentData && paymentRefundsData.PaymentData.length==0">
      No data avilable
    </div>
    <div class="text-center mb-4 mt-2 btn-footer">
      <div *ngIf="cancelCashRefundMessageEror && !CancelAllPolicyMessage">
        <h6 class="alert-danger alert">{{cancelCashRefundMessageEror}}</h6>
      </div>
      <div *ngIf="CancelAllPolicyMessage">
        <h6 class="alert-success alert">{{CancelAllPolicyMessage}}</h6>
      </div>
      <div *ngIf="cashReciptError">
        <h6 class="alert-danger alert">{{cashReciptError}}</h6>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary btn-full-sm" (click)="refundAllConfirmation()" data-toggle="modal"
      [disabled]="isRefundClicked" data-target="#myModal-15">
      <span *ngIf="isRefundAllClicked" class="spinner-border text-primary" style="width: 1rem; height: 1rem;"
        role="status" aria-hidden="false"></span>
      <span *ngIf="!isRefundAllClicked">{{refundAllText}}</span>
    </button>
  </div>
</ng-template>
<!--Cash Payement Refund poup-->
<ng-template #cancelCashPaymentRefundcontentTemplate>
  <div class="" id="myModal-13">
    <form [formGroup]="cancelCashPaymentForm" (ngSubmit)="cashItemRefund()">
      <div class="modal-body">
        <div class="col-md-8 mx-auto text-center">
          <div class="text-center mb-4 mt-4">
            <h5 class="mb-4">Cash</h5>
          </div>
          <div class="form-group mx-auto col-8">
            <div class="input-group date-field nopadding required">
              <input type="text" class="form-control input-sm col-12" placeholder="Receipt Number"
                formControlName="ReceiptNumber" />
            </div>
          </div>
          <div *ngIf="cancelCashRefundMessageEror">
            {{cancelCashRefundMessageEror}}
          </div>
        </div>
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeCashRefundModel()">
          Close
        </button>
        <button type="submit" class="btn btn-primary btn-full-sm" [disabled]="!cancelCashPaymentForm.valid"
          data-toggle="modal" data-target="#myModal-13">
          Continue
        </button>
      </div>
    </form>
  </div>
</ng-template>