import { createReducer, on, Action  } from "@ngrx/store";
import { setCountries } from "./country.actions";
import { Countries } from "./country.model";

export interface CountriesState {
    countries  : Countries | null;
}

export const initialState : CountriesState  = {
    countries : null
};


const _countriesReducer = createReducer(
    initialState,
    on(setCountries, (state, {countries}) =>({
        ...state,
        countries
    }))
);

export function countriesReducer(state: CountriesState | undefined, action: Action) {
    return _countriesReducer(state, action);
}