/// <reference path="../../../environments/environment.ts" />
/// <reference path="endpoint.ts" />
/// <reference path="endpoint.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndPoint } from './endpoint';
// import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in-no-jquery';
// import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
// import { isNumber } from 'util';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
  ) {}

  public static setPostActiveDateFormat() {
    let date = Date.now();
    let datevalue = date.toString();
    return datevalue;
  }

  setPostDateFormat(date: any) {
    if (
      date == '' ||
      date == null ||
      date === undefined ||
      typeof date != 'object'
    ) {
      return;
    }
    let ngbDate = date;
    let datevalue =
      ngbDate['year'].toString() +
      '-' +
      this.padNumber(ngbDate['month']).toString() +
      '-' +
      this.padNumber(ngbDate['day']).toString();
    return datevalue;
  }

  padNumber(value: number) {
    if (typeof value === 'number') {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

  setSearchDateFormat(date: any) {
    let ngbDate = date;
    let datevalue =
      ngbDate['month'].toString() +
      '-' +
      ngbDate['day'].toString() +
      '-' +
      ngbDate['year'].toString();

    return datevalue;
  }

  setFormDateFormat(dateValue: any) {
    if (dateValue != undefined && dateValue != '') {
      let date = dateValue.split('T')[0].split('-');
      let day = date[0];
      let month = date[1];
      let year = date[2];
      let ngbDateStruct = {
        month: Number(month),
        day: Number(day),
        year: Number(year),
      };

      return ngbDateStruct;
    }

    return dateValue;
  }

  setFormDateFormFormat(dateValue: any) {
    if (dateValue != undefined && dateValue != '') {
      let date = dateValue.split('T')[0];

      return date;
    }

    return dateValue;
  }

  setDefaultDateFormat(date: any) {
    let ngbDate = date;
    let datevalue =
      ngbDate['day'].toString() +
      '-' +
      ngbDate['month'].toString() +
      '-' +
      ngbDate['year'].toString();

    return datevalue;
  }

  setDateFormatByDelimiter(dateValue: any, delimiter : string) {
    if (dateValue != undefined && dateValue != '') {
      let date = dateValue.split('T')[0].split(delimiter);
      let day = date[0];
      let month = date[1];
      let year = date[2];
      let ngbDateStruct = {
        month: Number(month),
        day: Number(day),
        year: Number(year),
      };

      return ngbDateStruct;
    }

    return dateValue;
  }
}
