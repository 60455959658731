<div class="mt-4">
  <div id="accordion">
    <div class="card">
      <div class="card-header" id="h-payment">
        <h5 class="mb-0">
          <button
            class="btn btn-link accordion-note"
            data-toggle="collapse"
            (click)="openPolicyPayment()"
            [attr.aria-expanded]="!isPaymentCollapsed"
            data-target="#c-payment"
            aria-expanded="false"
            aria-controls="c-payment"
          >
            <i
              [ngClass]="{
                rotate0: !isPaymentCollapsed,
                rotate90: isPaymentCollapsed
              }"
              class="fas fa-chevron-circle-right mr-2 rotate90"
            ></i>
            <span>Payments</span>
          </button>
        </h5>
      </div>
      <div
        id="c-payment"
        class="collapse payment-table"
        aria-labelledby="h-payment"
        data-parent="#accordion"
      >
        <div class="card-body">
          <table
            id="datatable1"
            *ngIf="
              paymentList &&
              paymentList.length > 0
            "
            class="table table-striped table-bordered table-dashboard"
            style="width: 100%"
          >
            <thead>
              <tr>
                <th>When</th>
                <th>Pymt Id</th>
                <th>Pol Txn Id</th>
                <th>Successful</th>
                <th>Type</th>
                <th>Amt</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of paymentList">
                <td>{{ item.date | utcToShortDatePipe }}</td>
                <td>{{ item.displayPaymentRefNumber }}</td>
                <td class="text-center">
                  <span
                    ><i
                      class="fas fa-info-circle"
                      title="{{ item.movementId }}"
                    ></i
                  ></span>
                </td>
                <td>{{ item.status }}</td>
                <td>{{ item.methodType }}</td>
                <td class="text-right">
                  {{
                    item.amount
                      ? (item.amount | currency: 'USD')
                      : ''
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <div class="text-center" *ngIf="paymentList == null && !isPaymentError">
            <span class="spinner-border text-primary"></span>
          </div>
          <div
            class="card-body"
            *ngIf="paymentList && paymentList.length == 0 && !isPaymentError"
          >
            <!-- {{ paymentList.Validation }} -->
            No data is available
          </div>
          <div class="col-12" [hidden]="!isPaymentError">
            <div class="alert alert-success mt-3" role="alert">
              {{ paymentErrorMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
