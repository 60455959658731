/// <reference path="../services/messagebus.service.ts" />
import { Component, OnInit, Input } from '@angular/core';
import { PolicyDetailService } from '../app.services';
import { DataBusService } from '../services/databus.service';
import { MessageBusService } from '../services/messagebus.service';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { isGeneratorFunction } from 'util/types';

@Component({
  selector: 'policyDetails-policy-details-agents',
  templateUrl: './policy-details-agents.component.html',
  styleUrls: ['./policy-details-agents.component.css'],
})
export class PolicyDetailsAgentsComponent implements OnInit {
  Emaildata: any;
  policySummaryEmailsList: any = [];
  @Input() isEmail = true;
  @Input() versionMovementGuid: any;
  agentId!: string;
  agentDetails: any;
  isEditable = false;
  isAgentCollapsed = false;
  isEmailCollapsed = false;
  commissionDetails: any;
  commissionLoader: boolean = true;
  isCommissionError = false;
  commissionErrorMessage: any;
  constructor(
    public policySummaryEmailsData: PolicyDetailService,
    private dataBusService: DataBusService,
    private messageService: MessageBusService,
    private logService: LogService,
  ) { }

  ngOnInit() {
    this.agentId = this.isEmail ? 'c-agent' : 'v-agent';
    this.messageService.messageData.subscribe((data) => {
      this.isEditable = data;
    });

    // this.dataBusService.policyData.subscribe(data => {
    //   if (data.PolicyId != undefined) {
    //     let data1 = {
    //       PolicyId: data.PolicyId,
    //       ActivityType: "NewGenEmail",
    //     }
    //     this.policySummaryEmailsData.emailHistory(data1).subscribe(res => {
    //       if (res.ActivityLogsDetails != undefined) {
    //         this.Emaildata = res.ActivityLogsDetails.filter(x => x.ActivityType == "NewGenEmail");
    //       }
    //     })

    //     this.policySummaryEmailsData.agentCommissiom(data.PolicyId, '1', data.PolicyTxnId).subscribe(data => {
    //       this.agentDetails = data;
    //     })
    //   }
    // });
  }
  getPriceFixed(num: any, fixed: any) {
    if (num != undefined) {
      let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
      return num.toString().match(re)[0];
    }
  }

  openPolicyDetailsVersion(Flag: string) {

    if (Flag == 'Agent') {
      if (!this.commissionDetails && !this.isCommissionError) {
        var movementGuid = this.versionMovementGuid || sessionStorage.getItem('movementGuid') || '';
        this.policySummaryEmailsData.getPaymentCommission(
          movementGuid
        ).subscribe(
          (res) => {
            this.commissionDetails = res;
            this.commissionLoader = false;
          },
          (err) => {
            this.isCommissionError = true;
            this.commissionLoader = false;
            this.commissionErrorMessage = err.error.Message;
          }
        )
      }

      this.isAgentCollapsed = !this.isAgentCollapsed;
    }
    else {
      this.dataBusService.policyData.subscribe(
        (data) => {
          if (data.policyGuid != undefined) {

            this.policySummaryEmailsData.emailHistory(data.policyGuid).subscribe(
              (res) => {
                if (res != undefined && res.length > 0) {
                  this.Emaildata = res
                }
                else{
                  this.Emaildata = res;
                }
              }
            );

            // this.policySummaryEmailsData
            //   .agentCommissiom(
            //     data.PolicyId.toString(),
            //     '1',
            //     data.PolicyTxnId.toString(),
            //   )
            //   .subscribe(
            //     (data) => {
            //       this.agentDetails = data;
            //     }
            //   );
          }
        }
      );
      this.isEmailCollapsed = !this.isEmailCollapsed;
    }
  }
}
