/// <reference path="../../../../../src/app/core/services/price.service.ts" />
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { PolicyDetailService } from '../app.services';
import { DataBusService } from '../services/databus.service';
import { PriceService } from '../../../../../src/app/core/services/price.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { CancelEventEmitterService } from '../shared/services/cancel-event-emitter.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PolicyService } from 'projects/policySummary/src/app/policy.service';
import { Store } from '@ngrx/store';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserProfile } from 'src/app/core/store/loginUser/loginUser.model';

@Component({
  selector: 'policyDetails-policy-details-refund',
  templateUrl: './policy-details-refund.component.html',
  styleUrls: ['./policy-details-refund.component.css'],
})
export class PolicyDetailsRefundComponent implements OnInit {
  list: any;
  @Input() policyData: any;
  @Output() onRefundedAmountUpdate: EventEmitter<any> = new EventEmitter();
  isRefundCollapsed = false;
  paymentType = 'refund';
  isRefundError = false;
  refundErrorMessage = '';
  isRefundClicked = false;
  isRefundAllClicked = false;
  cashReciptError: string | undefined;
  CancelAllPolicyMessage: string | undefined;
  cancelMessageFail: any;
  cancelForm!: FormGroup;
  cancelCashPaymentForm!: FormGroup;
  refundAllText = 'Issue Refund';
  isReloadPage = false;
  isRefundAllDone = false;
  modelManangeRefundsRef!: NgbModalRef;
  modelcancelCashPaymentRefundRef!: NgbModalRef;
  cancelCashRefundMessageEror: any;
  intialRefundAmount: any;
  paymentRefundsData: any;
  finalCanecellation: any = [];
  cancelButton: boolean = true;
  selectedTransaction: any;
  @ViewChild('manageRefundsTemplate', { static: true })
  dialogManageRefundsBox: TemplateRef<ElementRef>;
  @ViewChild('cancelCashPaymentRefundcontentTemplate', { static: true })
  dialogManageCashRefundsBox: TemplateRef<ElementRef>;
  userProfile?: UserProfile;


  constructor(
    public refundServ: PolicyDetailService,
    private dataBusService: DataBusService,
    private eventCancelEmitService: CancelEventEmitterService,
    private loaderService: LoaderService,
    private priceService: PriceService,
    public formBuilder: FormBuilder,
    private el: ElementRef,
    private modalService: NgbModal,
    private policyService: PolicyService,
    public dataService: PolicyDetailService,
    private store: Store,
    private storeService: StoreGenericService
  ) {
    this.dialogManageRefundsBox = this.el.nativeElement;
    this.dialogManageCashRefundsBox = this.el.nativeElement;
  }

  ngOnInit() {
    this.eventCancelEmitService.refundEvent$.subscribe((data) => {
      this.loaderefund();
    });
    this.cancelCashPaymentForm = this.formBuilder.group({
      ReceiptNumber: [''],
    });
    
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userProfile = loginUser.userProfile;
      }
    });
  }

  loaderefund() {
    // let policyTxnId = sessionStorage.getItem('originalMovementGuid');
    let policyTxnId = sessionStorage.getItem("policyGuid");
    this.refundServ.payment(policyTxnId, this.paymentType).subscribe(
      (response) => {
        this.list = response;
        //console.log('refundList', response);
        this.emitRefundData(this.list && this.list.length > 0 ? this.calculateRefundAmt() : null);
      },
      (err) => {
        this.isRefundError = true;
        this.refundErrorMessage = err.error.Message;
      }
    );
  }

  openManageRefundsModel() {
    this.modelManangeRefundsRef = this.modalService.open(
      this.dialogManageRefundsBox,
      {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      },
    )
  }

  calculateRefundAmt() {
    let refunds: Array<any> = this.list;
    if (refunds.length) {
      let ccTypeRefundArray: Array<any> = refunds.filter(
        (v) => v.methodType.toLowerCase() == 'cc',
      );
      let pcTypeRefundArray: Array<any> = refunds.filter(
        (v) => v.methodType.toLowerCase() == 'pc',
      );

      let ccRefundedAmt = ccTypeRefundArray
        .map((ccPaymentObj) => ccPaymentObj.amount)
        .reduce((a, b) => a + b, 0);
      let pcRefundedAmt = pcTypeRefundArray
        .map((pcPaymentObj) => pcPaymentObj.amount)
        .reduce((a, b) => a + b, 0);
      return { ccRefundedAmt: ccRefundedAmt, pcRefundedAmt: pcRefundedAmt };
      // return null;
    } else return { ccRefundedAmt: 0, pcRefundedAmt: 0 };
    // return {}
  }

  emitRefundData(data: any) {
    this.onRefundedAmountUpdate.emit(data);
  }

  formatprice(value: any) {
    let formattedvalue = this.priceService.getPriceFixed(value, 2);
    return formattedvalue;
  }

  closeRefundConfirmModel() {
    // this.RefundConfirmRef.close();
  }
  openCashRefundModel() {
    this.modelcancelCashPaymentRefundRef = this.modalService.open(
      this.dialogManageCashRefundsBox,
      {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      },
    );
  }
  refundAllConfirmation() {
    if (this.isRefundAllDone) {
      this.closeModalConfirmation();
    }
    else {
      this.confirmFullRefund();
    }
  }
  closeModalConfirmation() {
    this.modelManangeRefundsRef.close();
    if (this.isReloadPage === true) {
      this.isReloadPage = false;
      location.href = location.protocol + '//' + location.host + '/policy-detail';
    }
  }
  getPaymentSummary() {
    let postData: any = {
      policyGuid: sessionStorage.getItem("policyGuid"),
      movementGuid: sessionStorage.getItem("movementGuid")
    };
    this.dataService.paymentSummaryRefunds(postData).subscribe(
      (response) => {
        if (response != undefined && response.length > 0) {
          this.paymentRefundsData = response;
          this.openManageRefundsModel();

          this.loaderService.hide();
        } else {
          this.loaderService.hide();
          this.cancelMessageFail = JSON.stringify(
            response.ResponseStatus.Message.split('Stack Trace:')[0].replace(
              'Exception',
            ),
          );
        }
      },
      (error) => {
        this.loaderService.hide();
        if (
          error &&
          error.error &&
          error.error.ResponseStatus &&
          error.error.ResponseStatus.Message
        ) {
          this.cancelMessageFail = JSON.stringify(
            error.error.ResponseStatus.Message.split('Stack Trace:')[0],
          );
        }
      },
    );
  }
  confirmFullRefund() {
    // this.closeRefundConfirmModel();
    this.isRefundAllClicked = true;
    this.isRefundClicked = true;
    this.CancelAllPolicyMessage = '';
    this.cashReciptError = '';
    this.cancelCashRefundMessageEror = '';
    if (this.paymentRefundsData.filter(
      (f: any) =>
        f.method === 'Cash',
    ).length > 0) {
      this.openCashRefundModel();
    }
    else {
      let reqObj: any = {
        policyGuid: sessionStorage.getItem('policyGuid'),
        transactionRefNo: "",
        refundEntireAmount: true,
        reasonCode: "FRC",
        cashRefundRefNumber: ""
      }
      this.savePaymentRecipt(reqObj);
    }
  }
  cashItemRefund() {
    this.modelcancelCashPaymentRefundRef.close();
    if (this.isRefundAllClicked == true) {
      let reqObj: any = {
        policyGuid: sessionStorage.getItem('policyGuid'),
        transactionRefNo: "",
        refundEntireAmount: true,
        reasonCode: "FRC  ",
        cashRefundRefNumber: this.cancelCashPaymentForm.value.ReceiptNumber
      }
      this.savePaymentRecipt(reqObj, this.cancelCashPaymentForm.value.ReceiptNumber);
    }
  }
  closeCashRefundModel() {
    this.modelcancelCashPaymentRefundRef.close();
    this.loaderService.hide();
    this.isRefundAllClicked = false;
    this.isRefundClicked = false;
  }

  savePaymentRecipt(req: any, caseRefNo?: any) {
    this.dataService.paymentIssueRefund(req)
      .subscribe(
        (response) => {
          if (response != undefined && response.isSuccessful) {
            this.refundAllText = 'Close';
            this.isRefundAllDone = true;
            this.cancelButton = false;
            this.finalCanecellation = this.paymentRefundsData;
            this.CancelAllPolicyMessage = "Policy has been refunded successfully, Please close this window to proceed.";
            this.finalCanecellation.forEach((item: any) => {
              item.brainTreeRefundStatus = true;
              if (item.method === 'Cash') {
                item.cashRefundRefNumber = caseRefNo;
              }
            });

          } else if (response != undefined && !response.isSuccessful
            && response.errors.length > 0) {
            this.cashReciptError = "This cash refund reference number is already in use for the same policy. Please enter a different reference number.";
          }
          else {
            this.cancelCashRefundMessageEror =
              'There was an error processing your refund. Please contact Tokio Marine support';
            this.refundAllText = 'Refund Fail';
            this.callPaymentFailed();
          }
          this.isReloadPage = true;
          this.loaderService.hide();
          this.isRefundAllClicked = false;
          this.isRefundClicked = false;
        },
        (err) => {
          this.loaderService.hide();
          this.isRefundAllClicked = false;
          this.isRefundClicked = false;
          this.cancelCashRefundMessageEror =
            'There was an error processing your refund. Please contact Tokio Marine support';
          this.refundAllText = 'Refund Fail'
          this.callPaymentFailed();
        },
      );
  }
  callPaymentFailed(item?: any) {
    let filteredData = item != undefined
      ? this.paymentRefundsData.filter((obj: any) => obj.paymentRefNumber == item.paymentRefNumber)
      : this.paymentRefundsData
    for (let itemPayment of filteredData) {
      if (
        this.finalCanecellation != undefined &&
        this.finalCanecellation.length > 0
      ) {
        if (
          this.finalCanecellation.filter(
            (f: any) =>
              f.paymentRefNumber ===
              itemPayment.paymentRefNumber,
          ).length == 0
        ) {
          let failedItem = itemPayment;
          failedItem.brainTreeRefundStatus = false;
          this.finalCanecellation.push(failedItem);
        }
      } else {
        let failedItem = itemPayment;
        failedItem.brainTreeRefundStatus = false;
        this.finalCanecellation.push(failedItem);
      }
    }
  }
  openPolicyRefund() {
    if (!this.list) {
      this.loaderService.show();
      this.dataBusService.policyData.subscribe((data) => {
        this.loaderService.hide();
        if (data.policyGuid != undefined) {
          this.loaderefund();
        }
      });
    }
    this.isRefundCollapsed = !this.isRefundCollapsed;
  }
}
