/// <reference path="../../../../src/app/core/portalcode/portalcode.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { EndPoint } from '../../../../src/app/core/services/endpoint';
import { CommonParams } from '../../../../src/app/core/commonparams';
import { PortalCodeService } from '../../../../src/app/core/portalcode/portalcode';
import { RoleTextService } from '../../../../src/app/core/role/setroletext';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(
    private http: HttpClient,
    private handler: HttpHandler,
    private portalCodeService: PortalCodeService,
    private roleTextService: RoleTextService,
  ) { }

  getAutoclubList() {
    return this.http.get(
      EndPoint.API_ENDPOINT + '/PASDataService/GetAutoclubList',
    );
  }
}
