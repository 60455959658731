import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PolicyDetailService } from '../app.services';
import { CommonParams } from 'src/app/core/commonparams';
import { catchError } from 'rxjs/operators';
import { throwError, Subscription } from 'rxjs';
import { DataBusService } from '../services/databus.service';
import { UtcToShortDatePipe, UtcToLongDatePipe } from '../pipes/moment.pipe';
import { DateService } from 'src/app/core/services/date-updated.service';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from 'projects/dashboard/src/app/policy-dashboard-grid/ngb-date-fr-parser-formatter';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import {
  PageNameEnum,
  SubComponentNameEnum,
} from 'projects/policyDetails/src/app/enum/page-name-enum';
import { isPolicyDataPresent, selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';

declare var require: any;
const FileSaver = require('file-saver');
@Component({
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
    UtcToShortDatePipe,
  ],
  selector: 'policyDetails-policy-details-left-menu',
  templateUrl: './policy-details-left-menu.component.html',
  styleUrls: ['./policy-details-left-menu.component.css'],
})
export class PolicyDetailsLeftMenuComponent implements OnInit, OnDestroy {
  storageObjData: any;
  viewCoiId: any;
  resendViewCoiId: any;
  viewPdsId: any;
  saveAsPdfId: any;
  isDisabledResend: boolean = false;
  isDisabledRegenerate: boolean = false;
  isDisabledViewCoi: boolean = false;
  isDisabledViewPds: boolean = false;
  isDisabledSavePdf: boolean = false;
  linkData: any;
  message: any;
  messageWrong: any;
  ResentCoiOrMailText: string = 'Resend COI';
  display: any;
  displayError: any;
  subscription: Subscription[] = [];
  policyVersionTxnId!: string;
  isReadOnly: any = false;
  viewCoiObj: any = {};
  policyGuid: any;
  movement: any;
  policyData: any;
  isResendCoiClicked: boolean = false;
  isRegenerateCoiClicked: boolean = false;
  isViewCoiClicked: boolean = false;
  @Input() isResendCoi = true;
  @Input() movementGuid: any;

  constructor(
    private policyService: PolicyDetailService,
    private dataBusService: DataBusService,
    private utcToShortDate: UtcToShortDatePipe,
    private newDateService: DateService,
    private logService: LogService,
    private store: Store
  ) { }

  ngOnInit() {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.isReadOnly = loginUser.userRoleDetails.IsReadOnly;
      }
    });
    this.resendViewCoiId = this.policyService.RESEND_COI;
    this.viewPdsId = this.policyService.VIEW_PDS;
    this.saveAsPdfId = this.policyService.SAVE_AS_PDF;
    //this.storageObjData = JSON.parse(localStorage.getItem('policyDetailsStorageData'));
    this.storageObjData = JSON.parse(
      sessionStorage.getItem('policyDetailsStorageData') || '{}',
    );
    this.policyGuid = sessionStorage.getItem('policyGuid');
    this.setCoiFormSetId();
    this.policyService.policyTransactionId.subscribe((policyTxnid) => {
      if (policyTxnid === sessionStorage.getItem('movementGuid')) {
        this.policyVersionTxnId =
          sessionStorage.getItem('originalMovementGuid') || '';
      } else {
        this.policyVersionTxnId = policyTxnid;
      }
    });

    this.policyService.coiObj.subscribe((data) => {
      this.viewCoiObj = data;
    });

    this.store.select(selectCurrentPolicy).subscribe(async pol =>{
      if (pol && pol.movements.length > 0) {
        var sortedMovements = pol.movements.sort(function (a: { version: number }, b: { version: number }) { return a.version - b.version });
        var lastMovement = sortedMovements[pol.movements.length -1];
        let cancelMovement = (lastMovement.transactionStatus == "CN") ? lastMovement.movementId : undefined;
        if (cancelMovement != undefined) {
          this.movementGuid = cancelMovement;
        }
      }
    });
  }

  viewCoi() {
    this.isDisabledViewCoi = true;
    this.display = 'none';
    this.displayError = 'none';
    this.isViewCoiClicked = true;
    let reqMovementGuid = this.movementGuid != undefined ? this.movementGuid : this.policyVersionTxnId ? this.policyVersionTxnId
      : sessionStorage.getItem('originalMovementGuid')
    this.policyService
      .viewCoi(this.policyGuid, reqMovementGuid)
      .subscribe(
        (res) => {
          let data = res;
          if (res !== undefined) {
            this.isDisabledViewCoi = false;
            if (res && !res.successful) {
              this.showError('The COI is currently being generated. Please try again in a 60 secs.');
            }
            else if (res && Object.keys(res).length > 0) {
              this.linkData = data.coiUrl;
              this.display = 'none';
              this.displayError = 'none';
              // this.message = 'View COI has been sent successfully!!';
              window.open(data.coiUrl, '_blank');
            } else if (res && Object.keys(res).length === 0) {
              this.displayError = 'block';
              this.display = 'none';
              this.messageWrong =
                'Something went wrong please try again!!';
            }
            setTimeout(() => {
              this.display = 'none';
              this.displayError = 'none';
            }, 5000);
            // this.linkData = data.coiUrl;
          }
          this.isViewCoiClicked = false;
        }, () => {
          this.isViewCoiClicked = false;
        }
      );
  }


  resendCoi() {
    this.display = 'none';
    this.displayError = 'none';
    this.isDisabledResend = true;
    this.isResendCoiClicked = true;
    let reqMovementGuid = this.movementGuid != undefined ? this.movementGuid : this.policyVersionTxnId ? this.policyVersionTxnId
      : sessionStorage.getItem('originalMovementGuid')
    this.policyService.resendCoi(reqMovementGuid).subscribe
      ((res) => {
        if (res && res.coiResponse && !res.successful) {
          this.showError('The COI is currently being generated. Please try again in a 60 secs.');
        } 
        else if (res != undefined && res.coiResponse != undefined
          && res.successful != undefined && res.coiResponse.emailSent) {
          this.display = 'block';
          this.displayError = 'none';
          this.message = (this.ResentCoiOrMailText === 'Resend E-Mail' ? 'Email' : 'Resend COI') + ' has been sent successfully!!';
        }
        else if (res && Object.keys(res).length === 0) {
          this.displayError = 'block';
          this.display = 'none';
          this.messageWrong =
            'Something went wrong please try again!!';
        }
        else if (!res.successful){
          this.showError('The COI is currently being generated. Please try again in a 60 secs.');
        }
        setTimeout(() => {
          this.display = 'none';
          this.displayError = 'none';
        }, 5000);
        this.isDisabledResend = false;
        this.isResendCoiClicked = false;
      }, () => {
        this.isDisabledResend = false;
        this.isResendCoiClicked = false;
      }
      );
  }

  regenerateCoi() {
    this.display = 'none';
    this.displayError = 'none';
    this.isDisabledRegenerate = true;

    if (this.policyData != undefined && this.policyData.movements != undefined)
      this.movement = this.policyData.movements.find((d: any) => d.movementId === this.policyVersionTxnId);
    let reqObj: any =
    {
      movementGuid: this.movementGuid != undefined ? this.movementGuid : this.policyVersionTxnId ? this.policyVersionTxnId
        : sessionStorage.getItem('originalMovementGuid'),
      movementType: this.movement ? this.movement.type : ''
    }
    this.isRegenerateCoiClicked = true;
    this.policyService.regenerateCOI(reqObj).subscribe((res) => {
      if (res && res.coiResponse && !res.successful) {
        this.showError('The COI is currently being generated. Please try again in a 60 secs.');
      }
      else if (res != undefined && res.coiResponse != undefined
        && res.successful != undefined && res.coiResponse.emailSent) {
        this.display = 'block';
        this.displayError = 'none';
        this.message = 'COI Re-generated successfully!!';
      }
      else if (res && Object.keys(res).length === 0) {
        this.displayError = 'block';
        this.display = 'none';
        this.messageWrong =
          'Something went wrong please try again!!';
      }
      else if (!res.successful){
        this.showError('The COI is currently being generated. Please try again in a 60 secs.');
      }
      setTimeout(() => {
        this.display = 'none';
        this.displayError = 'none';
      }, 5000);
      this.isDisabledRegenerate = false;
      this.isRegenerateCoiClicked = false;
    }, () => {
      this.isDisabledRegenerate = false;
      this.isRegenerateCoiClicked = false;
    }
    );
  }

  viewPDS(id: any) {
    this.policyService.viewPDS(sessionStorage.getItem('policyGuid')).subscribe(
      (data) => {
        this.isDisabledViewPds = true;
        this.display = 'none';
        this.displayError = 'none';
        //let res = 'https://assets.ctfassets.net/424qhvkqksfz/2XhzxIo64CZpM0avSnqElz/546eb6612a292077c90bc2602b045208/RACV_PDS_Brochure_2019_v6_WEB.pdf';
        let res = data.pdsUrl;
        if (res !== undefined) {
          this.isDisabledViewPds = false;
          window.open(res, '_blank');
        }
      }
    );
  }

  saveAsPDF(id: any) {
    this.isDisabledSavePdf = true;
    this.display = 'none';
    this.displayError = 'none';
  }

  showError(message: string) {
    this.displayError = 'block';
    this.display = 'none';
    this.messageWrong = message;
  }

  downloadPdf(pdfUrl: string, pdfName: string) {
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  closeAlert() {
    this.display = 'none';
    this.displayError = 'none';
  }

  ngOnDestroy() {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }

  setCoiFormSetId() {
    if (localStorage.getItem('coiFormSetId') === null) {
      if (sessionStorage.getItem('policyMasterGtaData')) {
        let response = JSON.parse(
          sessionStorage.getItem('policyMasterGtaData') || '',
        );
        let formTypes = response.FormsetTypes.filter(
          (x: any) =>
            (x.FormSetTypeCode == 'EN' && !x.IsDefault) ||
            (x.FormSetTypeCode == 'NB' && x.IsDefault),
        );
        if (formTypes) {
          sessionStorage.setItem('coiFormSetId', formTypes[0].FormSetId);
          let coiFormSetId = sessionStorage.getItem('coiFormSetId');
          if (coiFormSetId) {
            this.resendViewCoiId = coiFormSetId;
            this.saveAsPdfId = coiFormSetId;
          }
        }
      } 
      else {

      }
    } else {
      let coiFormSetId = sessionStorage.getItem('coiFormSetId');
      if (coiFormSetId) {
        this.resendViewCoiId = coiFormSetId;
        this.saveAsPdfId = coiFormSetId;
      }
    }
    this.dataBusService.policyData.subscribe((data) => {

      this.policyData = data;
      if (data.movements != undefined && data.movements.length > 0 && this.movementGuid != undefined) {
        let movement = (data.movements.length > 0) ? data.movements.find((data: any) => data.movementId === this.movementGuid) : {};
        data.transactionStatus = movement.transactionStatus
      }
      if (data && data.transactionStatus === 'CN') {

        this.isDisabledViewCoi = true;
        this.isDisabledResend = false;
        this.isDisabledRegenerate = true;
        this.ResentCoiOrMailText = 'Resend E-Mail';
      } else {
        this.ResentCoiOrMailText = 'Resend COI';
        this.isDisabledViewCoi = false;
        this.isDisabledResend = false;
        this.isDisabledRegenerate = false;
      }
    });
  }

  utcToAestDate(utcDate: string) {
    let aestTime = this.newDateService.getDateAsOfAESTFromDate(
      this.newDateService.getMomentFromDate(utcDate),
    );
    return new Date(aestTime);
  }
}
