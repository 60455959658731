import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { HttpInterceptor } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { EndPoint } from './endpoint';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { JwtToken } from '../commonparams';
import { urls } from './grid-json-response';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { SecurityRoleResponse } from 'projects/nav/src/app/app.model';
import { CryptoService } from '../cryptoservice/cryptoservice';
import { CacheService } from 'projects/manage-branches/src/app/caching.service';

var whiteListUrlKeyWord = ['QuotePayment'];
var quoteUrlParam = ['/quote/'];
var token = ['token'];
@Injectable({
  providedIn: 'root',
})
export class InterceptorsService implements HttpInterceptor {
  xLoginId : string = ''; 
  userRoleDetails?: UserRoleDetails;
  exemptedUrls? : string[] = ['https://gatewayuat.verisk.com/']
  navSecurity : any = this.fetchCachedNavSettings('navSecurityRole');
  
  constructor(
    private toastr: ToastrService,
    private http: HttpClient,
    private store: Store,
    private cacheService : CacheService
  ) 
  {
    if(this.navSecurity != null){
      this.xLoginId = this.navSecurity.agentGuid;
    }
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.xLoginId = this.userRoleDetails?.AgentContactId
      }
    })
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let start = performance.now();
    let ok: string;
    let apirequest: any;
    let blacklist = false;

    const requestClone = JSON.parse(JSON.stringify(request));
    const { url, method, params } = requestClone;

    if ((method == 'GET') && url.includes('/DataTable')) {
      for (const element of urls) {
        if (url.includes(element.lastParams)) {
          return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
        }
      }
    }
    if ((method == 'GET') && url.includes('/StaticList')) {
      for (const element of urls) {
        if (url.includes(element.lastParams)) {
          switch (element.lastParams) {
            case "States":
              return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
            case "MedicalLabeling":
              return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
            case "ProductLabeling":
              return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
            default:
              break;
          }
        }
      }
    }
    if ((method == 'GET') && url.includes('/ValidationMessages')) {
      for (const element of urls) {
        if (url.includes(element.lastParams)) {
          switch (element.lastParams) {
            case "Validation":
              return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
            default:
              break;
          }
        }
      }
    }
    if ((method == 'GET') && url.includes('/CancelReasonList')) {
      for (const element of urls) {
        if (url.includes(element.lastParams)) {
          switch (element.lastParams) {
            case "CancelReasons":
              return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
            default:
              break;
          }
        }
      }
    }
    if ((method == 'GET') && url.includes('/Configurations')) {
      for (const element of urls) {
        if(url.includes(element.lastParams)){
          switch (element.lastParams) {
            case "Products":
              return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
            default:
              break;
          }
        }
      }
    }
    if ((method == 'GET') && url.includes('/ColorPicker')) {
      for (const element of urls) {
        if(url.includes(element.lastParams)){
          switch (element.lastParams) {
            case "Theme":
              return of(new HttpResponse({ status: 200, body: ((element.json) as any).default }));
            default:
              break;
          }
        }
      }
    }
    // mock JSON response -- END     

    if(!this.exemptedUrls?.some(substring=>request.url.includes(substring))){
      //Add custom Headers
      request = request.clone({ setHeaders: { 
        'x-api-key': EndPoint.XAPIKEY
      }});

      if(this.xLoginId > ''){
        request = request.clone({ setHeaders: { 
          'X-LoginId' : this.xLoginId
        }});
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }

        return event;
      }),
      catchError((error: HttpErrorResponse): any => {
        if(error.status == undefined){
          return;
        }
        if (error.url?.includes('CXOneMaskCall') && error.status == 424) {
          return;
        }
        switch (error.status) {
          case 400:
            if (
              error.error?.errors[0]?.errorSummary !=
              'login: An object with this field already exists in the current organization'
            ) {
              this.toastr.error('Something went wrong please try again!!');
            }
            break;
          case 401:
            // Don't show the error message in 401 as Verisk will try to refresh the token.
            break;
          case 403:
            this.toastr.error('Something went wrong please try again!!');
            break;
          case 404:
            this.toastr.error('Something went wrong please try again!!');
            break;
          case 410:
            this.toastr.error('Something went wrong please try again!!');
            break;
          case 500:
            this.toastr.error('Something went wrong please try again!!');
            break;
          case 502:
            this.toastr.error('Something went wrong please try again!!');
            break;
          case 504:
            this.toastr.error('Something went wrong please try again!!');
            break;
          default:
            this.toastr.error('Something went wrong please try again!!');
        }
        return throwError(error);
      }),
    );
  }

  errorService(error: any, payload: any) {
    return this.http
      .get(
        EndPoint.API_ENDPOINT +
        '/GtpPasLogService/GtpPasLog?error=' +
        error +
        '&payload=' +
        payload +
        '&sitename=' +
        'PAS',
      )
      .toPromise();
  }

  errorLogService(error: any, payload: any) {
    let params = { error: error, payload: payload, sitename: 'PAS' };
    return this.http
      .post<any>(EndPoint.API_ENDPOINT + '/GTPAPI/SitecoreLog', params)
      .toPromise();
  }
 
  getUrlPathName(url: any) {
    var newUrl = '';
    var spliturl = url.split('/');
    spliturl.forEach((item: any, index: any) => { if (index > 2) { newUrl += `${'/'}${item}`; } });
    newUrl = newUrl.slice(1);
    return newUrl;
  }

 fetchCachedNavSettings(cacheKey : string){
  const cachedData = this.cacheService.getCache(cacheKey);
    if (cachedData) {
      let encryptedData = JSON.parse(JSON.stringify(cachedData));
      let decryptedData : SecurityRoleResponse = CryptoService.decryptData(encryptedData);
      let agentSettings = decryptedData.responseMessage;
      return agentSettings;
    } 
 }
}
