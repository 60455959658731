import { Component, OnInit, ViewChild, TemplateRef, ElementRef, } from '@angular/core';
import { Utility } from '../shared/utility';
import { PolicyDetailService } from '../app.services';
import { IPremiumRate } from '../shared/models/premium';
import { SitecoreItemService } from '../services/sitecore-item.service';
import { DialogConfig } from '../dialog/dialog-config';
import { DialogRef } from '../dialog/dialog-ref';
import { SetQueryParamsService } from 'src/app/core/role/setqueryparams';
import { SiteNameEnum } from '../shared/enum/sitename-enum';
import { EndorsementGenericService } from '../shared/services/endorsement-generic.service';
import { DataBusService } from '../services/databus.service';
import { filter } from 'rxjs/operators';
import { MessageBusService } from '../services/messagebus.service';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { NgbModal, NgbModalRef, } from '@ng-bootstrap/ng-bootstrap';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum, SubComponentNameEnum, } from 'projects/policyDetails/src/app/enum/page-name-enum';
import { DateService } from 'src/app/core/services/date-updated.service';
import moment from 'moment';
import { EndPoint } from 'src/app/core/services/endpoint';
import { MapperService } from 'src/app/core/services/mapper.services';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';

@Component({
  selector: 'policyDetails-policy-details-quote',
  templateUrl: './policy-details-quote.component.html',
  styleUrls: ['./policy-details-quote.component.css'],
})
export class PolicyDetailsQuoteComponent implements OnInit {
  modalRef!: NgbModalRef;
  modelAlertRef!: NgbModalRef;
  switchtrip: any;
  private themeWrapper = document.querySelector('body');
  public isLoadingQuoteDetails = false;
  public inProgress = false;
  public _premiumRate!: IPremiumRate;
  public userSelectionIndex: number = 0;
  public isShowBenefits: boolean = false;
  public isShowFeatureTitle: boolean = false;
  public showHideFeatureTitle: number = -1;
  public isShowFeatureTitleList: any = [];
  isEditable = false;
  isLoader = false;
  public siteName: string = '';
  public isW2CDisabled = false;
  public siteNameEnum: any;
  public currentReturnDate!: Date;
  public queryParam: any;
  public isCardLoaded = false;
  public isShowHideButtonDisable = false;
  public defaultDataSettingsFromSitecore: any;
  public setFactorDate: any;
  public stepsValidate = {
    stepOne: 'N',
    stepTwo: 'N',
    stepThree: 'N',
    stepFour: 'N',
  };
  public requestResult: any;
  public updateMessageSuccess = '';
  public openModal = false;
  isAMTTripWithMultiCountry = false;
  isAMTTripWithMultiCountryText = '';
  showHideDiv = false;
  public setExcess200Date: any;
  public modifyExcess200: boolean = false;
  public isNoCruiseDisabled: boolean = false;
  public selectedCountries: any;
  public productConfigurations: any;
  public colorThemes: any;
  public productLabeling: any;
  public productConfigurationsLTX: any;
  public validationMessages: any;
  public messages : any = {
    singleTripToAMTSwitchMsg : "",
    amTripToSingleSwitchMsg : ""
  };
  public isProductUpdateDisabled : boolean = true;
  public ageLength : string = '';
  public coverageError : boolean = false;
  public originalTripTypeIsSingle : boolean = false;

  @ViewChild('confirmMessageTemplate', { static: true })
  dialogalertBox!: TemplateRef<ElementRef>;
  @ViewChild('switchTripTemplate', { static: true })
  tripalertBox!: TemplateRef<ElementRef>;

  constructor(
    private quoteService: PolicyDetailService,
    private itemService: SitecoreItemService,
    private dataBusService: DataBusService,
    private portalCodeService: PortalCodeService,
    public config: DialogConfig,
    private dialog: DialogRef,
    private setQueryParamsService: SetQueryParamsService,
    private modalService: NgbModal,
    private el: ElementRef,
    private endorsementGenericService: EndorsementGenericService,
    private messageService: MessageBusService,
    private logService: LogService,
    private dateService : DateService,
    private mapperService : MapperService,
    private storeService : StoreGenericService
  ) {
    this.siteNameEnum = SiteNameEnum;
  }

  ngOnInit() {
    this.isLoadingQuoteDetails = true;
    this.populatePolicyData(this.config.data?.policy,this.config.data?.endorsement);
  }

  onClose() {
    // this.switchtrip = '';
    let singleTrip = this.originalTripTypeIsSingle;
    this.state.IsSingleTrip = singleTrip;
    this.state.tripType = this.state.IsSingleTrip ? 'single' : 'multi-trip';
    this.dialog.close();
  }

  openAlertModel() {
    this.updateMessageSuccess = 'Coverage options updated successfully.';
    this.modelAlertRef = this.modalService.open(this.dialogalertBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeAlertChildModal() {
    this.modelAlertRef.close();
  }

  showHideLabelForTripType() {
    this.showHideDiv = true;
    setTimeout(() => {
      this.showHideDiv = false;
    }, 3000);
  }

  setPostDateFormat(date: any) {
    if (
      date == '' ||
      date == null ||
      date === undefined ||
      typeof date != 'object'
    ) {
      return;
    }
    let ngbDate = date;
    if (ngbDate) {
      let datevalue =
        ngbDate['year'].toString() +
        '-' +
        (ngbDate['month'].toString().length == 1
          ? '0' + ngbDate['month'].toString()
          : ngbDate['month'].toString()) +
        '-' +
        (ngbDate['day'].toString().length == 1
          ? '0' + ngbDate['day'].toString()
          : ngbDate['day'].toString());
      return datevalue;
    }
  }

  parsePersistedData(key: string) {
    //return this.persistService.getPersistedDetails(key);
  }

  getSelectedExcess(index: any, selectedExcessName: any) {
    if (!this.state.isDomestic) {
      let findIndex = this.findCoverageExcessIndex(
        index,
        this.state.selectedExcessName,
      );
      if (findIndex < 0) {
        let findNAIndex = this.findCoverageExcessIndex(index, 'NA');
        if (findNAIndex < 0) {
          this.state.coveragesItems[index].excessList.push({
            excessName: 'NA',
          });
        }
        return 'NA';
      }
      return this.state.selectedExcessName;
    } else {
      return selectedExcessName;
    }
  }

  setNotApplicable(excessName: any, exceptIndex: any) {
    for (let idx in this.state.coveragesItems) {
      let findNAIndex = this.findCoverageExcessIndex(idx, 'NA');
      if (findNAIndex < 0 && exceptIndex != idx) {
        let findIndex = this.findCoverageExcessIndex(idx, excessName);
        if (findIndex < 0) {
          this.state.coveragesItems[idx].excessList.push({ excessName: 'NA' });
          this.state.coveragesItems[idx].selectedExcessName = 'NA';
        }
      } else if (findNAIndex > -1 && exceptIndex == idx) {
        this.state.coveragesItems[idx].excessList.splice(findNAIndex, 1);
      } else if (findNAIndex > -1 && exceptIndex != idx) {
        let findIndex = this.findCoverageExcessIndex(idx, excessName);
        if (findIndex > -1) {
          this.state.coveragesItems[idx].selectedExcessName = excessName;
        } else {
          this.state.coveragesItems[idx].selectedExcessName = 'NA';
        }
      }
    }
  }

  findCoverageExcessIndex(coverageIndex: any, excessName: any) {
    return this.state.coveragesItems[coverageIndex].excessList.findIndex(
      (c: any) => c.excessName.toUpperCase() == excessName.toUpperCase(),
    );
  }

  isTrue(value: any) {
    let result = Utility.isTrue(value);
    return result;
  }

  getFeatureList(featureList: any) {
    let filter: any = [];

    [...this.state.featureList].map((item, index) => {
      if (item.showInFeature.toLowerCase() === 'true') {
        filter.push(item);
      }
    });
    return filter;
  }

  findIndexOfFeature(feature: any, planType: any) {
    return feature
      ? feature.coverageIn.findIndex(
          (k: any) => k.toLowerCase() === planType.toLowerCase(),
        )
      : -1;
  }

  changeDate(tripType: any) {
    try {
      if (tripType === 'multi-trip') {
        let calculatedDate = this.dateService.setDateFormatByDelimiter(this.state.tripInfo.departDate,'/');
        let departDate = calculatedDate;
        // let calculatedDate = new Date(this.state.tripInfo.departDate);
        let isLeapYear = moment( departDate.getFullYear().toString()).isLeapYear();
        if (isLeapYear) {
          let currentMonth = departDate.getMonth() + 1;
          if (currentMonth > 2) {
            calculatedDate = new Date(
              calculatedDate.setDate(calculatedDate.getDate() + 364),
            );
          } else {
            calculatedDate = new Date(
              calculatedDate.setDate(calculatedDate.getDate() + 365),
            );
          }
        } 
        else {
          calculatedDate = new Date( calculatedDate.setDate( departDate.getDate() + 364, ),
          );
        }
        this.state.tripInfo.returnDate = calculatedDate;
      } 
      else {
        let calculatedDate = this.dateService.setDateFormatByDelimiter(this.state.tripInfo.departDate,'/');
        let departDate = calculatedDate;
        calculatedDate = new Date(
          calculatedDate.setDate(departDate.getDate() + 7),
        );
        this.state.tripInfo.returnDate = calculatedDate;
      }
    } catch (error) {
      //this.logError("In changeDate: " + error);
    }
  }

  findIndexOfCoverage(coverage: string): number {
    let index = 0;
    let selectedIndex = index;
    this.state.coveragesItems.forEach((_coverage: any) => {
      if (_coverage.productName.toLowerCase() == coverage.toLowerCase()) {
        return (selectedIndex = index);
      }
      index++;
    });
    return selectedIndex;
  }

  getRentalBaseExcess(selectedExcessName: string) {
    if (this.state.isDomestic && this.state.planType.toUpperCase() == 'TIER1') {
      return selectedExcessName;
    }
    return '';
  }

  setCardIndex(index: any, coverageItem: any): any {
    if (this.state.planType == coverageItem.productName) {
      this.state.selectedQuoteIndex = index + 1;
    }
  }

  toggleBenefits(isShowBenefits: any) {
    this.isShowBenefits = !isShowBenefits;
  }

  toggleisShowFeatureTitle(index: any) {
    for (let idx in this.state.featureList) {
      if (index != idx) {
        this.isShowFeatureTitleList[idx] = false;
      }
    }
    this.isShowFeatureTitleList[index] = !this.isShowFeatureTitleList[index];
  }

  excessList: any = [{ excessName: '' }];

  domesticList: any = [
    {
      productName: '',
      productText: '',
      hideExcess: false,
      hideSkiing: false,
      hideCruise: false,
      selectedExcessName: '',
      selectedSkiing: false,
      selectedCruise: false,
      isMostPopular: false,
      excessList: [
        {
          excessName: '$0',
        },
      ],
      mostPopularTxt: 'Most Popular',
      memberPriceTxt: 'Member Price',
      cruiseOptionTxt: 'Going on a cruise?',
      chooseYourExcessTxt: 'Choose your excess',
      skiingTxt: 'Skiing/Winter?',
      selectedBtnTxt: 'SELECTED',
      regularPriceTxt: 'Regular Price',
      chooseBtnTxt: 'CHOOSE',
      'selectedMaxLenName ': '30 days',
      showMultipleDays: true,
      chooseYourMaxTripLenTxt: 'Choose your max trip length',
      maxTripLenList: [
        {
          maxTripLenName: '30 days',
        },
      ],
    },
  ];
  
  internationalList: any = [
    {
      productName: '',
      productText: '',
      hideExcess: false,
      hideSkiing: false,
      hideCruise: false,
      selectedExcessName: '',
      selectedSkiing: false,
      selectedCruise: false,
      isMostPopular: false,
      excessList: [
        {
          excessName: '$0',
        },
      ],
      mostPopularTxt: 'Most Popular',
      memberPriceTxt: 'Member Price',
      cruiseOptionTxt: 'Going on a cruise?',
      chooseYourExcessTxt: 'Choose your excess',
      skiingTxt: 'Skiing/Winter?',
      selectedBtnTxt: 'SELECTED',
      regularPriceTxt: 'Regular Price',
      chooseBtnTxt: 'CHOOSE',
      'selectedMaxLenName ': '30 days',
      showMultipleDays: true,
      chooseYourMaxTripLenTxt: 'Choose your excess',
      maxTripLenList: [
        {
          maxTripLenName: '30 days',
        },
      ],
    },
  ];

  multiTripList: any = [
    {
      productName: '',
      productText: '',
      hideExcess: false,
      hideSkiing: false,
      hideCruise: false,
      selectedExcessName: '',
      selectedSkiing: false,
      selectedCruise: false,
      isMostPopular: false,
      excessList: [
        {
          excessName: '$0',
        },
      ],
      mostPopularTxt: 'Most Popular',
      memberPriceTxt: 'Member Price',
      cruiseOptionTxt: 'Going on a cruise?',
      chooseYourExcessTxt: 'Choose your excess',
      skiingTxt: 'Skiing/Winter?',
      selectedBtnTxt: 'SELECTED',
      regularPriceTxt: 'Regular Price',
      chooseBtnTxt: 'CHOOSE',
      'selectedMaxLenName ': '30 days',
      showMultipleDays: true,
      chooseYourMaxTripLenTxt: 'Choose your excess',
      maxTripLenList: [
        {
          maxTripLenName: '30 days',
        },
      ],
    },
  ];

  featureList: any = [
    {
      title: 'Cancellation Fees',
      cover: '$unlimited',
      desc: 'Coverage for Additional Accommodation.',
      showInFeature: true,
      awesomeIcon: 'cancellation-fees-icon',
      showText: false,
      coverageIn: ['Tier3', 'Tier1', 'Tier2', 'Annual Multi Trip'],
    },
  ];

  whiteLabeling: any = {
    SingleTripLabel: 'Single Trip',
    SingleTriptooltip: 'Single',
    MultiTripLabel: 'Annual Multi-Trip',
    MultiTripLabeltooltip: 'Annual Multi-Trip',
  };

  userDetails = {
    PolicyHolderFName: '',
    PolicyHolderLName: '',
    AddTravelerFName: '',
    AddTravelerLName: '',
    Dependance: [{ FName: '', LName: '', Age: 0 }],
    PolicyHolderEmail: '',
    PolicyHolderPhNo: '',
    PolicyHolderAddress: '',
    IsAgreed: false,
    IsPreExistingMedical: false,
  };

  paymentDetails = {
    CardHolderName: '',
    CardNo: '',
    CardExpiry: '',
    CardCVV: '',
  };

  traveler = {
    Age: 0,
    ContactId: 0,
    EntityTypeId: 2,
    LastName: '',
    FirstName: '',
    MiddleName: '',
    BirthDate: '',
    Addresses: [],
    NameTypeIds: [4],
  };

  state: any = {
    mostPopular: '',
    IsSingleTrip: true,
    tripType: 'single',
    planType: '',
    currentPlanType: '',
    planName: '',
    tripInfo: {
      locations: [
        {
          name: '',
        },
      ],
      codes: [
        {
          CountryCode: '',
          CountryName: '',
          RatingRegionName: '',
        },
      ],
      departDate: new Date(),
      returnDate: new Date(),
      active: false,
    },
    isDomestic: false,
    numOfDays: '',
    selectedExcessName: '',
    selectedMaxLenName: '',
    selectedSkiing: true,
    selectedCruise: true,
    coveragesItems: [],
    featureList: [],
    ageSecond: 0,
    ageFirst: 0,
    dependantCount: 0,
    postCode: '',
    stateName: '',
    stateCode: '',
    PolicyHolderFName: '',
    PolicyHolderLName: '',
    AddTravelerFName: '',
    AddTravelerLName: '',
    Dependance: [{ FName: '', LName: '', Age: 0 }],
    PolicyHolderEmail: '',
    PolicyHolderPhNo: '',
    PolicyHolderAddress: '',
    IsAgreed: false,
    IsPreExistingMedical: false,
    tripTotalCost: 0.0,
    rate: 0,
    discountAmount: 0,
    GSTTaxForPremium: 0,
    RegularFinalPremium: 0,
    taxAmount: 0,
    netPremium: 0,
    racvMember: 'No',
    QuoteNo: '',
    StampDutyTaxForPremium: 0,
    CruisePremium: 0,
    ExcessPremium: 0,
    TotalPremium: 0,
    PrimaryCoveragePremium: 0,
    SkiingPremium: 0,
    MultiTripLengthPremium: 0,
    TripDurationPremium: 0,
    MemberDiscountPremium: 0,
    PromoCodeDiscountPremium: 0,
    travelerFirstName: '',
    travelerLastName: '',
    addTravelerFirstName: '',
    addTravelerLastName: '',
    travellerAge: 0,
    addTravellerAge: 0,
    dependantsList: [],
    travellerEmailId: '',
    travelerContactNo: 0,
    showUserDetails: false,
    showSummaryMedicalCondition: false,
    selectedQuoteIndex: 0,
    promoCode: '',
    havePromocodeText: '',
    promoCodeApplied: 'No',
    discountRate: 0,
    agencyContactId: 0,
    requetsParam: {},
    Traveler1: this.traveler,
    Traveler2: this.traveler,
    PolicyDetails: {
      PolicyTxnId: 0,
      QuoteNum: '---',
    },
    Region: '',
    premiumOnToggle: 0,
    skiingNotApplicable: false,
    ProductType: '',
    updateQuoteURL: '',
  };

  QuoteDetails = [];

  //LTX
  async populatePolicyData(policy: any, endorsement: any) {
    this.requestResult = JSON.parse(JSON.stringify(endorsement));
    //Policy
    let courtyOrRegionNames = policy?.destinations;
    if (courtyOrRegionNames != null) {
      this.checkAMTWithMultiCountry(courtyOrRegionNames);
    }
    //Endorsements
    this.siteName = this.requestResult?.autoClub;
    this.selectedCountries = this.requestResult?.destinations;

    let isDomestic = endorsement.destinations.find((dest : any) =>  dest.ratingRegionName.toLowerCase() == 'domestic'.toLowerCase()) != null  ? true : false;
    this.state.isDomestic = isDomestic;

    this.state.IsSingleTrip = this.requestResult?.isSingleTrip;
    this.state.tripType = this.state.IsSingleTrip ? 'single' : 'multi-trip';
    this.originalTripTypeIsSingle = endorsement?.isSingleTrip;

    this.state.promoCode = this.requestResult?.promoCode;
    this.state.racvMember = this.requestResult?.isClubMember ? 'Yes' : 'No';
    this.state.ageFirst = this.requestResult?.ages[0];
    this.state.ageSecond = this.requestResult?.ages[1];
    this.state.dependantCount = this.requestResult?.dependentsCount;
    this.state.selectedSkiing = this.requestResult?.isSking;
    this.state.selectedCruise = this.requestResult?.isCruise;
    this.state.planType = this.requestResult?.quoteName;
    this.state.currentPlanType = this.requestResult?.quoteName;
    this.state.selectedMaxLenName = this.requestResult?.multiTripLength;
    this.state.selectedExcessName = this.requestResult?.excess;
    this.state.numOfDays = this.dateService.daysDifference(this.requestResult?.fromDate, this.requestResult?.toDate);
    this.state.skiingNotApplicable = this.state.ageFirst > 85 || this.state.ageSecond > 85 ? true : false;
    await this.fetchValidationMessages();
    this.loadDefaultConfigurationData(false)
  }

  checkAMTWithMultiCountry(countryOrRegionNamesData: any) {
    if (countryOrRegionNamesData && countryOrRegionNamesData.length > 0) {
      let hasDomesticCountry = countryOrRegionNamesData.find(
        (o: any) =>
          o.ratingRegionName.toUpperCase() === 'Domestic'.toUpperCase(),
      );
      let hasInternationalCountry = countryOrRegionNamesData.find(
        (o: any) =>
          o.ratingRegionName.toUpperCase() !== 'Domestic'.toUpperCase(),
      );
      if (hasDomesticCountry && hasInternationalCountry) {
        this.isAMTTripWithMultiCountry = true;
      } else {
        this.isAMTTripWithMultiCountry = false;
      }
    }
  }

  setAustralianCuiseAsTrue() {
    this.state.selectedCruise = true;
    this.isNoCruiseDisabled = true;
    this.state.coveragesItems.forEach((item: any) => {
      item.selectedCruise = `${this.state.selectedCruise}`;
    });
  }

  setDefaultSettingList(isSingleTrip: boolean, defaultSelectionData: any) {
    if (isSingleTrip == true) {
      if (this.state.isDomestic) {
        this.domesticList = defaultSelectionData;
      } else {
        this.internationalList = defaultSelectionData;
      }
    } else {
      this.multiTripList = defaultSelectionData;
    }
  }

  setPricingByEndorsement(applyDefault: boolean = false,coverageType : any = null){
    let clouser = this;
    let listOfTrip = applyDefault ? (this.state.tripType === 'multi-trip' ? [...this.multiTripList] : (this.state.isDomestic ? [...this.domesticList] : [...this.internationalList])) : this.state.coveragesItems;
    this.state.coveragesItems = [...listOfTrip].map((item: any, index) => {

      if (this.state.planType === item.productName) {
        this.state.planName = item.productText;
        this.state.selectedExcessName = item.selectedExcessName;
        this.state.selectedMaxLenName = item.selectedMaxLenName;
        this.state.selectedCruise = item.selectedCruise;
        this.state.selectedSkiing = item.selectedSkiing;
      }
      let excessName = item.selectedExcessName;
      let skiing = item.selectedSkiing;
      let cruise = item.selectedCruise;
      let maxLen = item.selectedMaxLenName;
      let isMultiTrip = this.state.tripType === 'multi-trip' ? true : false;
      clouser._premiumRate = clouser.getSelectedRateSummary(this.QuoteDetails, isMultiTrip, item, excessName, skiing, cruise, maxLen, coverageType);

      if (item.productName.toLowerCase() == clouser.state.planType.toLowerCase()) {
        clouser.setSummaryRateDetails(clouser, clouser._premiumRate);
      }

      return {
        ...item, netPremium: clouser._premiumRate.netPremium,
        PrimaryCoveragePremium: clouser._premiumRate.PrimaryCoveragePremium,
        ExcessPremium: clouser._premiumRate.ExcessPremium,
        CruisePremium: clouser._premiumRate.CruisePremium,
        SkiingPremium: clouser._premiumRate.SkiingPremium,
        RegularFinalPremium: clouser._premiumRate.RegularFinalPremium,
        MultiTripLengthPremium: clouser._premiumRate.MultiTripLengthPremium,
        //TripDurationPremium: clouser._premiumRate.TripDurationPremium,
        StampDutyTaxForPremium: clouser._premiumRate.StampDutyTaxForPremium,
        MemberDiscountPremium: Utility.convertCurrencyToNumber(clouser._premiumRate.MemberDiscountPremium),
        PromoCodeDiscountPremium: Utility.convertCurrencyToNumber(clouser._premiumRate.PromoCodeDiscountPremium),
        //Region: clouser._premiumRate.Region,
        ProductType: clouser._premiumRate.ProductType
      };

    });
  }

  getSelectedRateSummary( _quoteDetails: any, IsMultiTrip: boolean, _item: any, _excessName: string, _isSkiingCovered: boolean, _isCruise: boolean = false, _maxLen: string, coverageType: string = '', ) {
    let _clouser = this;
    _clouser._premiumRate = this.refreshPremium();
    if (_excessName == 'NA') {
      return _clouser._premiumRate;
    }

    _quoteDetails.forEach((product: any) => {
      if (product.productName.toLowerCase() === _item.productName.toLowerCase()) {
        if ( this.IsMached( product, _item, _excessName, _isCruise, _isSkiingCovered, ) ) {
          if (IsMultiTrip) {
            if (
              product.multiTripLength.toLowerCase() === _maxLen.toLowerCase()
            ) {
              return this.filteredData(_clouser, product);
            } else {
              return _clouser._premiumRate;
            }
          }
          return this.filteredData(_clouser, product);
        } else {
          return _clouser._premiumRate;
        }
      }
    });
    return _clouser._premiumRate;
  }

  setSummaryRateDetails(clouser: any, premiumRate: any) {
    clouser._premiumRate = premiumRate;
    clouser.state.rate = clouser._premiumRate.rate;
    clouser.state.discountAmount = clouser._premiumRate.discountAmount;
    clouser.state.GSTTaxForPremium = clouser._premiumRate.GSTTaxForPremium;
    clouser.state.taxAmount = clouser._premiumRate.taxAmount;
    clouser.state.netPremium = clouser._premiumRate.netPremium;

    clouser.state.StampDutyTaxForPremium = clouser._premiumRate.StampDutyTaxForPremium;
    clouser.state.CruisePremium = clouser._premiumRate.CruisePremium;
    clouser.state.ExcessPremium = clouser._premiumRate.ExcessPremium;
    clouser.state.PrimaryCoveragePremium = clouser._premiumRate.PrimaryCoveragePremium;
    clouser.state.SkiingPremium = clouser._premiumRate.SkiingPremium;
    clouser.state.MultiTripLengthPremium = clouser._premiumRate.MultiTripLengthPremium;
    // clouser.state.TripDurationPremium = clouser._premiumRate.TripDurationPremium;
    clouser.state.MemberDiscountPremium = Utility.convertCurrencyToNumber( clouser._premiumRate.MemberDiscountPremium, );
    clouser.state.PromoCodeDiscountPremium = Utility.convertCurrencyToNumber( clouser._premiumRate.PromoCodeDiscountPremium, );

    // clouser.state.Region = clouser._premiumRate.Region;
    clouser.state.ProductType = clouser._premiumRate.ProductType;
    clouser.state.ClubProductGuid = clouser._premiumRate.ClubProductGuid;
  }

  refreshPremium() {
    return {
      rate: 0,
      discountAmount: 0,
      GSTTaxForPremium: 0,
      RegularFinalPremium: 0,
      taxAmount: 0,
      netPremium: 0,
      PrimaryCoveragePremium: 0,
      StampDutyTaxForPremium: 0,
      CruisePremium: 0,
      ExcessPremium: 0,
      TotalPremium: 0,
      SkiingPremium: 0,
      MultiTripLengthPremium: 0,
      // TripDurationPremium: 0,
      MemberDiscountPremium: 0,
      PromoCodeDiscountPremium: 0,
      // Region: '',
      ProductType: '',
    };
  }

  IsMached( product: any, item: any, excessName: string, isCruise: boolean, isSkiing: boolean, ) {
    let isMatched = false;
    if ( !Utility.isTrue(item.hideExcess) && !Utility.isTrue(item.hideSkiing) && !Utility.isTrue(item.hideCruise) ) {
      isMatched = Utility.convertCurrencyToNumber(product.excess) === Utility.convertCurrencyToNumber(excessName) && Utility.isTrue(product.isSki) === Utility.isTrue(isSkiing) && Utility.isTrue(product.isCruise) === Utility.isTrue(isCruise);
    } 
    else if ( !Utility.isTrue(item.hideExcess) && Utility.isTrue(item.hideSkiing) && !Utility.isTrue(item.hideCruise) ) {
      isMatched = Utility.convertCurrencyToNumber(product.excess) === Utility.convertCurrencyToNumber(excessName) && Utility.isTrue(product.isCruise) === Utility.isTrue(isCruise);
    } 
    else if ( !Utility.isTrue(item.hideExcess) && !Utility.isTrue(item.hideSkiing) && Utility.isTrue(item.hideCruise) ) {
      isMatched = Utility.convertCurrencyToNumber(product.excess) === Utility.convertCurrencyToNumber(excessName) && Utility.isTrue(product.isSki) === Utility.isTrue(isSkiing);
    } 
    else if ( Utility.isTrue(item.hideExcess) && !Utility.isTrue(item.hideSkiing) && !Utility.isTrue(item.hideCruise) ) {
      isMatched = Utility.isTrue(product.isSki) === Utility.isTrue(isSkiing) && Utility.isTrue(product.isCruise) === Utility.isTrue(isCruise);
    } 
    else if ( Utility.isTrue(item.hideExcess) && Utility.isTrue(item.hideSkiing) && !Utility.isTrue(item.hideCruise) ) {
      isMatched = Utility.isTrue(product.isCruise) === Utility.isTrue(isCruise);
    } 
    else if ( !Utility.isTrue(item.hideExcess) && Utility.isTrue(item.hideSkiing) && Utility.isTrue(item.hideCruise) ) {
      isMatched = Utility.convertCurrencyToNumber(product.excess) === Utility.convertCurrencyToNumber(excessName);
    } 
    else if ( Utility.isTrue(item.hideExcess) && !Utility.isTrue(item.hideSkiing) && Utility.isTrue(item.hideCruise) ) {
      isMatched = Utility.isTrue(product.isSki) === Utility.isTrue(isSkiing);
    } 
    else if ( Utility.isTrue(item.hideExcess) && Utility.isTrue(item.hideSkiing) && Utility.isTrue(item.hideCruise) ) {
      isMatched = true;
    }
    return isMatched;
  }

  filteredData(_clouser: any, product: any) {
    _clouser._premiumRate.ClubProductGuid = product.clubProductGuid;
    _clouser._premiumRate.ProductType = product.productType;
    _clouser._premiumRate.ExcessPremium = product.excessPremium;
    _clouser._premiumRate.GSTTaxForPremium = product.gst;
    _clouser._premiumRate.MultiTripLengthPremium = product.multiTripLengthPremium;
    _clouser._premiumRate.SkiingPremium = product.skiingPremium;
    _clouser._premiumRate.CruisePremium = product.cruisePremium;
    _clouser._premiumRate.rate = product.basePremium;
    _clouser._premiumRate.PrimaryCoveragePremium = product.basePremium;
    _clouser._premiumRate.netPremium = product.totalPremium;
    _clouser._premiumRate.RegularFinalPremium = product.regularPremium;
    _clouser._premiumRate.taxAmount = product.stampDuty;
    _clouser._premiumRate.StampDutyTaxForPremium = product.stampDuty;
    _clouser._premiumRate.MemberDiscountPremium = Utility.convertCurrencyToNumber(product.membershipDisc);
    _clouser._premiumRate.PromoCodeDiscountPremium = Utility.convertCurrencyToNumber(product.promoCodeDisc);
    
    // _clouser._premiumRate.TripDurationPremium = product.TripDurationPremium;
    // _clouser._premiumRate.Region = product.Region;

    return _clouser._premiumRate;
  }

  onChangeSkiing(skiing: any, index: number) {
    this.isProductUpdateDisabled = false;
    this.setSelectedValues(index, skiing, 'skiing');
    let _planType =
      this.state.tripType === 'multi-trip'
        ? [...this.multiTripList][index].productName
        : (this.state.isDomestic
            ? [...this.domesticList]
            : [...this.internationalList])[index].productName;
    this.setPricingByEndorsement(false, _planType);
    return true;
  }

  onChangeCruise(cruise: any, index: number) {
    this.isProductUpdateDisabled = false;
    this.setSelectedValues(index, cruise, 'cruise');
    let _planType =
      this.state.tripType === 'multi-trip'
        ? [...this.multiTripList][index].productName
        : (this.state.isDomestic
            ? [...this.domesticList]
            : [...this.internationalList])[index].productName;
    this.setPricingByEndorsement(false, _planType);
    return true;
  }

  selectQuote(coverageItem: any, coverageIndex: any) {
    this.isProductUpdateDisabled = false;
    this.state.selectedQuoteIndex = coverageIndex + 1;
    this.state.planType = coverageItem.productName;
    this.state.planName = coverageItem.productText;
    this.setPricingByEndorsement(false, coverageItem.productName);
  }

  selectExcess(event: any, i: any) {
    this.isProductUpdateDisabled = false;
    let _excessName = event.target.value;
    this.setSelectedValues(i, _excessName, 'excess');
    let _planType =
      this.state.tripType === 'multi-trip'
        ? [...this.multiTripList][i].productName
        : (this.state.isDomestic
            ? [...this.domesticList]
            : [...this.internationalList])[i].productName;
    if (!this.state.isDomestic) {
      //  this.setNotApplicable(_excessName, i);
    }
    this.setPricingByEndorsement(false, _planType);
  }

  selectMaxLen(event: any, i: any) {
    this.isProductUpdateDisabled = false;
    let _maxLenName = event.target.value;
    this.setSelectedValues(i, _maxLenName, 'maxLen');
    let _planType =
      this.state.tripType === 'multi-trip'
        ? [...this.multiTripList][i].productName
        : (this.state.isDomestic
            ? [...this.domesticList]
            : [...this.internationalList])[i].productName;
    this.setPricingByEndorsement(false, _planType);
  }

  setSelectedValues(index: any, value: any, flag: any) {
    switch (flag) {
      case 'excess': {
        if (this.state.isDomestic) {
          this.state.coveragesItems[index].selectedExcessName = `${value}`;
        } else {
          for (let idx in this.state.coveragesItems) {
            if (idx === '2' && value === '$0') {
              this.state.coveragesItems[idx].selectedExcessName = `$100`;
            } else {
              this.state.coveragesItems[idx].selectedExcessName = `${value}`;
            }
          }
        }
        break;
      }
      case 'skiing': {
        if (this.state.isDomestic) {
          this.state.coveragesItems[index].selectedSkiing = `${value}`;
        } else {
          for (let idx in this.state.coveragesItems) {
            this.state.coveragesItems[idx].selectedSkiing = `${value}`;
          }
        }
        break;
      }
      case 'cruise': {
        if (this.state.isDomestic) {
          this.state.coveragesItems[index].selectedCruise = `${value}`;
        } else {
          for (let idx in this.state.coveragesItems) {
            this.state.coveragesItems[idx].selectedCruise = `${value}`;
          }
        }
        break;
      }
      case 'maxLen': {
        if (this.state.isDomestic) {
          this.state.coveragesItems[index].selectedMaxLenName = `${value}`;
        } else {
          for (let idx in this.state.coveragesItems) {
            this.state.coveragesItems[idx].selectedMaxLenName = `${value}`;
          }
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  getExcess(selectedExcessName: string) {
    if (
      this.state.isDomestic &&
      (this.state.planType.toUpperCase() == 'TIER2' ||
        this.state.planType.toUpperCase() == 'TIER1')
    ) {
      return '';
    }
    return selectedExcessName;
  }

  openTripAlertModel(val: any, click?: any) {
    if (val == 'single') {
      if (this.isAMTTripWithMultiCountry) {
        this.showHideLabelForTripType();
        return;
      }
    }
    if (this.requestResult.isSingleTrip && val == 'single') {
      return;
    }
    if ( !this.requestResult.isSingleTrip && val == 'multi-trip' ) {
      return;
    }
    this.switchtrip = val;
    let policyDetails = this.config.data?.policy;
    if(policyDetails){
      let isDomesticArray = policyDetails.productType;

      if ( this.switchtrip !== '' && this.openModal === false && click !== '' ) { //
        this.openModal = false;
        let errorMessage = '';
        
        if(!this.ageValidation()){
          if(this.switchtrip == 'multi-trip'){
            errorMessage = `Please enter the traveler's age between 18 to ${this.ageLength} years for Annual Multi Trip plan`;
          }
          else{
            errorMessage = `Please enter the traveler's age between 18 to ${this.ageLength} years.`;
          }
          this.coverageError = true;
        }
        else if(this.siteName === 'W2C' && val == 'multi-trip' && isDomesticArray == 'Domestic'){
          this.isW2CDisabled = true;
          errorMessage = `Our Annual Multi-trip plan is an international cover that also includes trips within Australia. When traveling in Australia you are not covered for medical, hospital or dental expenses.`;
          this.coverageError = true;
        }
        else{
          this.coverageError = false;
        }

        this.updateMessageSuccess = errorMessage;
        this.state.tripInfo.departDate = this.requestResult.fromDate;

        if(errorMessage.length > 0 && errorMessage > ''){
          this.isProductUpdateDisabled = true;
          this.openModal = true;
          this.modelAlertRef = this.modalService.open(this.tripalertBox, {
            size: 'lg',
            backdrop: 'static',
            keyboard: false,
          });
        }
        else{
          this.openModal = false;
          this.changetrip('clicked');
        }
      } 
      else if ( this.switchtrip !== '' && click !== '') {
        this.state.tripInfo.departDate = this.requestResult.fromDate;
        let value = policyDetails.destinations;
        let ratingregion: any = [];
        let uniqueregion: any = [];
        for (let res of value) {
          ratingregion.push(res.ratingRegionName);
        }
        ratingregion.forEach((ele: any) => { if(!uniqueregion.includes(ele)) { uniqueregion.push(ele); } });
        if (uniqueregion.includes('Domestic')) {
          this.state.isDomestic = true;
        } else {
          this.state.isDomestic = false;
        }
        this.openModal = false;
      }
    }
  }

  changetrip(val: any) {
    // this.closeTripAlertChildModal();
    this.changeTripType(this.switchtrip);
  }

  changeTripType = (val: any) => {
    this.isLoadingQuoteDetails = true;
    this.state.coveragesItems = [];
    this.state.netPremium = 0;
    this.state.premiumOnToggle = 0;
    this.state.tripType = val;
    this.state.IsSingleTrip = val === 'single' ? true : false;
    this.requestResult.isSingleTrip = this.state.IsSingleTrip;
    this.changeDate(val);
    this.state.coveragesItems =
      val === 'multi-trip'
        ? [...this.multiTripList]
        : this.state.isDomestic
          ? [...this.domesticList]
          : [...this.internationalList];
    this.state.featureList = [...this.state.featureList];
    this.loadDefaultConfigurationData();
  }

  closeTripAlertChildModal() {
    this.openModal = false;
    //this.switchtrip = '';
    // let singleTrip = this.requestResult?.isSingleTrip;
    // this.state.IsSingleTrip = singleTrip;
    // this.state.tripType = this.state.IsSingleTrip ? 'single' : 'multi-trip';
    this.modelAlertRef.close();
  }

  onSave(val: any) {
    this.updateQuoteRequest(val);
  }

  async updateQuoteRequest(val: any, obj?: any) {
    let selectedClubProductGuid = this.state.ClubProductGuid;
    if (val === false) {
      this.isLoadingQuoteDetails = true;
      var updateRequest: any = {};
      if (this.requestResult) {
        Object.assign(updateRequest, this.requestResult);
        updateRequest.clubProductGuid = selectedClubProductGuid;
      }
      if(this.switchtrip == 'multi-trip' && this.coverageError == false){
        updateRequest.toDate = this.dateService.getDDMMYYYYFromDate(this.state.tripInfo.returnDate);
      }
      updateRequest = await this.mapperService.mapEndorsementModel(updateRequest, "revision", "CoverageUpdated");
      this.quoteService.updateEndorsement(updateRequest).subscribe((enResponse) => {
        if (enResponse) {
          this.isLoadingQuoteDetails = false;
          this.storeService.setENToStore(enResponse);
          sessionStorage.setItem('movementGuid', enResponse.movementGuid.toString());
          this.dialog.close(enResponse);
          this.openAlertModel();
        }
        else {
          this.isLoadingQuoteDetails = false;
          this.dialog.close();
        }
      }, (err: any) => {
        this.isLoadingQuoteDetails = false;
        this.dialog.close();
      });
    } 
  }

  async setDefaultValues(isSingleTrip: boolean, localCall: any) {

    let combinedListOfData: any = await this.fetchConfigurations( isSingleTrip, this.state.isDomestic);

    if (combinedListOfData) {
      let defaultSelectionData = combinedListOfData.map((item: any) => {
        let ExcessList = [];
        let MaxTripLenList = [];
        if (this.state.planType === item.InsuranceValue) {
          this.isAMTTripWithMultiCountryText = Utility.typeSafeHandle( item,'ValidateDesitinationMessageForAMT', 'string', ).toString();

          this.state.planName = item.InsuranceText;
          
          item.SelectedSkiing = Utility.typeSafeHandle( this.state, 'selectedSkiing', 'string', ).toString();
          
          item.SelectedCruise = Utility.typeSafeHandle( this.state, 'selectedCruise', 'string', ).toString();
          
          item.SelectedMaxLenName = Utility.typeSafeHandle( this.state, 'selectedMaxLenName', 'string', ).toString();

          item.SelectedExcessName = Utility.typeSafeHandle( this.state, 'selectedExcessName', 'string', ).toString();
        }
        
        if (item.ExcessList) {
          ExcessList = item.ExcessList.split('|').map((item: any) => { return { excessName: item }; });
        }

        if (item.MaxTripLenList) {
          MaxTripLenList = item.MaxTripLenList.split(',').map((item: any) => { return { maxTripLenName: item }; });
        }

        return {
          productName: item.InsuranceValue,
          productText: item.InsuranceText,
          showMultipleDays: item.ShowMultipleDays.toLowerCase(),
          selectedExcessName: item.HideExcess.toLowerCase() === 'true' ? '' : Utility.isDefined(item.SelectedExcessName) ? item.SelectedExcessName : '',
          selectedMaxLenName: Utility.isDefined(item.SelectedMaxLenName) ? item.SelectedMaxLenName : '',
          selectedSkiing: item.HideSkiing.toLowerCase() === 'true' ? '' : Utility.isDefined(item.SelectedSkiing) ? item.SelectedSkiing.toLowerCase() : '',
          selectedCruise: item.HideCruise.toLowerCase() === 'true' ? '' : Utility.isDefined(item.SelectedCruise) ? item.SelectedCruise.toLowerCase() : '',
          hideExcess: Utility.isDefined(item.HideExcess) ? item.HideExcess.toLowerCase() : 'false',
          hideSkiing: item.HideSkiing.toLowerCase(),
          hideCruise: item.HideCruise.toLowerCase(),
          isMostPopular: item.IsMostPopular.toLowerCase(),
          excessList: ExcessList,
          mostPopularTxt: item.MostPopularTxt,
          memberPriceTxt: item.MemberPriceTxt,
          cruiseOptionTxt: item.CruiseOptionTxt,
          chooseYourExcessTxt: item.ChooseYourExcessTxt,
          chooseYourMaxTripLenTxt: item.ChooseYourMaxTripLenTxt,
          maxTripLenList: MaxTripLenList,
          skiingTxt: item.SkiingTxt,
          selectedBtnTxt: item.SelectedBtnTxt,
          regularPriceTxt: item.RegularPriceTxt,
          chooseBtnTxt: item.ChooseBtnTxt,
        };
      });

      this.setDefaultSettingList(isSingleTrip, defaultSelectionData);
     
      this.state.coveragesItems = defaultSelectionData;
      
      //if Australian Cruise set Cruise value as 'True'
      if ( this.selectedCountries != null && this.selectedCountries.length > 0 && this.selectedCountries.find((x: any) => x.countryCode == 'AUC')) {
        this.setAustralianCuiseAsTrue();
      } 
      else {
        this.isNoCruiseDisabled = false;
      }
    }
  }

  async loadDefaultConfigurationData(localCall: boolean = false){
    let isSingleTrip = this.state.tripType === 'single' ? true : false;
    await this.setDefaultValues(isSingleTrip, localCall);
    this.getContentJSON();
  }

  async getContentJSON() {
    try {
      await this.loadThemeColorSettings();
      await this.loadProductLabelingSetting();
      await this.fetchProductConfigurationsLTX();
    } 
    catch (error) {
    }
  }
  
  async loadThemeColorSettings() {
    let themeColor : any;
    themeColor = await this.fetchColorThemes();
    if (themeColor) {
        this.themeWrapper?.style.setProperty( '--GroupOneBG', themeColor?.GroupOneBG, );
        this.themeWrapper?.style.setProperty( '--GroupTwoBG', themeColor?.GroupTwoBG, );
        this.themeWrapper?.style.setProperty( '--GroupThreeBG', themeColor?.GroupThreeBG, );
        this.themeWrapper?.style.setProperty( '--GroupFourBG', themeColor?.GroupFourBG, );
        this.themeWrapper?.style.setProperty( '--GroupFiveBG', themeColor?.GroupFiveBG, );
        this.themeWrapper?.style.setProperty( '--GroupSixBG', themeColor?.GroupSixBG, );
        this.themeWrapper?.style.setProperty( '--GroupSevenBG', themeColor?.GroupSevenBG, );
        this.themeWrapper?.style.setProperty( '--GroupEightBG', themeColor?.GroupEightBG, );

        this.themeWrapper?.style.setProperty( '--GroupOneFG', themeColor?.GroupOneFG, );
        this.themeWrapper?.style.setProperty( '--GroupTwoFG', themeColor?.GroupTwoFG, );
        this.themeWrapper?.style.setProperty( '--GroupThreeFG', themeColor?.GroupThreeFG, );
        this.themeWrapper?.style.setProperty( '--GroupFourFG', themeColor?.GroupFourFG, );
        this.themeWrapper?.style.setProperty( '--GroupFiveFG', themeColor?.GroupFiveFG, );
        this.themeWrapper?.style.setProperty( '--GroupSixFG', themeColor?.GroupSixFG, );
        this.themeWrapper?.style.setProperty( '--GroupSevenFG', themeColor?.GroupSevenFG, );
        this.themeWrapper?.style.setProperty( '--GroupEightFG', themeColor?.GroupEightFG, );
      }
  }

  async loadProductLabelingSetting() {
    let productLabeling: any;
    productLabeling = await this.fetchProductLabeling();
    if (productLabeling) {
      this.whiteLabeling.SingleTripLabel = productLabeling.SingleTripLabel;
      this.whiteLabeling.SingleTriptooltip = productLabeling.SingleTriptooltip;
      this.whiteLabeling.MultiTripLabeltooltip = productLabeling.MultiTripLabeltooltip;
      this.whiteLabeling.MultiTripLabel = productLabeling.MultiTripLabel;
    }
  }

  async fetchProductConfigurationsLTX() {
    let primaryTraveler = this.requestResult.travelers.filter((x: { role: string; }) => x.role == "primary")[0];
    let secondaryTraveler = this.requestResult.travelers.filter((x: { role: string; }) => x.role == "secondary");
    let productConfigRequest = {
      "destinations": this.requestResult?.destinations,
      "state": this.requestResult?.state,
      "fromDate": this.requestResult?.fromDate,
      "toDate": this.requestResult?.toDate,
      "rateDate": this.requestResult?.rateDate,
      "clubCode": this.requestResult?.autoClub,
      "firstPersonAge": Utility.ageFromDateOfBirth( new Date(primaryTraveler.dateOfBirth)),
      "secondPersonAge": (secondaryTraveler.length > 0) ? Utility.ageFromDateOfBirth(new Date(secondaryTraveler[0].dateOfBirth)) : 0,
      "channel": this.requestResult?.channel ?? "Online",
      "promoCode": this.requestResult?.promoCode ?? "",
      "isClubMember": this.requestResult?.isClubMember ?? false,
      "isSingleTrip" : this.requestResult?.isSingleTrip ?? false
    }

    let productConfigLTX = await this.quoteService.fetchProductConfigurationsLTX(productConfigRequest).toPromise();
    if(productConfigLTX){
      let configurationList : any = [];
      if(productConfigLTX.length > 0){
        productConfigLTX.forEach((x: { products: any; }) => {
          if(x.products.length > 0){
            configurationList.push(...x.products);
          }
        })
      }
      this.QuoteDetails = configurationList;
      this.setPricingByEndorsement(true);

      //Select Default Tier, Excess, Cruise, Skii
      if(this.state != null && this.state.coveragesItems != null && this.state.coveragesItems.length > 0 ){
        let length = null;
        let coverageItem = null;
        if(this.switchtrip > ''){
          length = this.state.coveragesItems.length > 0 ? 0 : this.state.coveragesItems.length;
        }
        else{
          let coverages = this.state.coveragesItems;
          let value = this.requestResult?.quoteName.toLowerCase();
          length = coverages.findIndex(function(cov : any) { return cov.productName.toLowerCase() == value; });
        }
        
        
        if(this.state.tripType == 'multi-trip'){
          coverageItem = this.state.coveragesItems[length];
        }
        else{
          coverageItem = this.state.coveragesItems[length];
        }
        
        if(coverageItem != null){
          let excess = Utility.typeSafeHandle( this.state, 'selectedExcessName', 'string', ).toString();
          let excessObj = null
          
          //Select Tier
          this.selectQuote(coverageItem,0);
          
          if(coverageItem?.excessList.find((item : any) => item.excessName == excess)){
            excessObj={ target : { value : excess }};
            //Select Excess
            this.selectExcess(excessObj,length);
          }
          
          //Select Cruise
          this.state.selectedCruise = this.requestResult?.isCruise;
          this.onChangeCruise(Utility.typeSafeHandle( this.state, 'selectedCruise', 'string', ).toString(),0);

          //Select Skii
          this.state.selectedSkiing = this.requestResult?.isSking;
          this.onChangeSkiing(Utility.typeSafeHandle( this.state, 'selectedSkiing', 'string', ).toString(),0);
        }
      }

      this.isLoadingQuoteDetails = false;
    }
    throw new Error('Method not implemented.');
  }
  
  async fetchConfigurations(isSingleTrip : boolean, isDomestic : boolean) {
    this.productConfigurations = [];
    let productByCoverage = [];
    let configType : string = "";

    if(isSingleTrip){
      configType = (isDomestic) ? "SINGLE_DOMESTIC" : "SINGLE_INTERNATIONAL";
    }
    else{
      configType = (isDomestic) ? "AMT_DOMESTIC" : "AMT_INTERNATIONAL";
    }

    let configurationResp = await this.quoteService.fetchConfigurations(EndPoint.PAS_CONFIGURATIONS_PRODUCTS).toPromise();
    if (configurationResp) {

      if (Object.hasOwn(configurationResp, this.siteName)) {
        let clubWiseConfig = configurationResp[this.siteName];
        productByCoverage = clubWiseConfig[configType];
      }
    }
    this.productConfigurations = (productByCoverage) ? productByCoverage : [];
    return productByCoverage;
  }

  async fetchColorThemes() {
    this.colorThemes = {};
    let clubWiseColor = {};
    let colorTheme = await this.quoteService.fetchColorThemes(EndPoint.PAS_COLORPICKER_THEME).toPromise();
    if (colorTheme) {

      if (Object.hasOwn(colorTheme, this.siteName)) {
        clubWiseColor = colorTheme[this.siteName];
      }
    }
    this.colorThemes = (clubWiseColor) ? clubWiseColor : {};
    return clubWiseColor;
  }

  async fetchProductLabeling(){
    this.productLabeling = {};
    let clubWiseLabeling = {};
    let labelings = await this.quoteService.fetchStaticList(EndPoint.PAS_STATIC_PRODUCTLABELING).toPromise();
    if (labelings) {

      if (Object.hasOwn(labelings, this.siteName)) {
        clubWiseLabeling = labelings[this.siteName];
      }
    }
    this.productLabeling = (clubWiseLabeling) ? clubWiseLabeling : {};
    return clubWiseLabeling;
  }

  async fetchValidationMessages() {
    let validationResp = await this.quoteService.fetchValidationMessages(EndPoint.PAS_VALIDATION_MESSAGES).toPromise();
    if (validationResp) {

      if (Object.hasOwn(validationResp, this.siteName)) {
        this.validationMessages = validationResp[this.siteName];
        this.messages.singleTripToAMTSwitchMsg = this.validationMessages != null && this.validationMessages.hasOwnProperty('singleTripToAMTSwitchMsg') ? this.validationMessages.singleTripToAMTSwitchMsg : '';
        this.messages.amTripToSingleSwitchMsg = this.validationMessages != null && this.validationMessages.hasOwnProperty('amTripToSingleSwitchMsg') ? this.validationMessages.amTripToSingleSwitchMsg : '';
      }
    }
  }

  ageValidation(){
    let value = true;
    this.ageLength = this.siteName.toUpperCase() == 'W2C' ? '75' : ( this.switchtrip == 'multi-trip') ? '75' : '110';
    const maxlength = this.siteName.toUpperCase() == 'W2C' ? 75 : 110;

    if(this.requestResult.ages.length > 0){
      this.requestResult.ages.forEach((age: any) => {
        if(age > 0){
          if((this.switchtrip == 'multi-trip') && (age < 18 || age > 75) ) {
            value = false;
          } 
          else if ((age < 18 || age > maxlength) ) {
            value = false;
          } 
        }        
      });
    }
    return value;
  }
}
