import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  TemplateRef,
  ElementRef,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormBuilder,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { DataBusService } from '../services/databus.service';
import { PolicyDetailService } from '../app.services';
import { CommonParams } from 'src/app/core/commonparams';
import { from, Subscription } from 'rxjs';
import { DateService } from 'src/app/core/services/date-updated.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserProfile, UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import {
  PageNameEnum,
  SubComponentNameEnum,
} from 'projects/policyDetails/src/app/enum/page-name-enum';

@Component({
  selector: 'policyDetails-policy-details-notes',
  templateUrl: './policy-details-notes.component.html',
  styleUrls: ['./policy-details-notes.component.css'],
})
export class PolicyDetailsNotesComponent implements OnInit, OnDestroy {
  isNoteCollapsed = false;
  @Input() versionMovementGuid: any = null;
  @Input() isNotesRequired: boolean = true;
  accid!: string;
  noteForm!: FormGroup;
  public history: any;
  submitted = false;
  showMsg: boolean = false;
  policytxnid = '';
  public noteDetail: any;
  public generalList: any;
  errorMsg = '';
  generalNote: boolean = false;
  isLoader: boolean = false;
  subscription: Subscription[] = [];
  isReadOnly: any = false;
  showDeleteMsg: any = false;
  modalRef!: NgbModalRef;
  isDeleteNodeVisible: any = true;
  selectedActityToDeleteNote: any = null;
  movementGuid: any = "";
  userProfile?: UserProfile;
  userRoleDetails?: UserRoleDetails;
  @ViewChild('deleteNoteConformation', { static: true })
  deleteNoteConformationContent: TemplateRef<ElementRef>;
  constructor(
    public formBuilder: FormBuilder,
    private date: DateService,
    public noteService: PolicyDetailService,
    private dataBusService: DataBusService,
    private el: ElementRef,
    private modalService: NgbModal,
    private logService: LogService,
    private store: Store
  ) {
    this.deleteNoteConformationContent = this.el.nativeElement;
    this.policytxnid = sessionStorage.getItem('policyGuid') || '';
    this.movementGuid = sessionStorage.getItem('movementGuid') || '';

  }

  ngOnInit() {

    this.policytxnid = (this.policytxnid == "") ? sessionStorage.getItem('policyGuid') || "" : this.policytxnid;
    this.movementGuid = (this.movementGuid == "") ? sessionStorage.getItem('movementGuid') || "" : this.movementGuid;

    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userProfile = loginUser.userProfile;
        this.isReadOnly = loginUser.userRoleDetails.IsReadOnly;
        this.userRoleDetails = loginUser.userRoleDetails;
        this.isDeleteNodeVisible = !this.userRoleDetails.IsAgent;

      }
    })
    // this.isDeleteNodeVisible = this.userRoleDetails?.IsUW;
    this.accid = this.isNotesRequired ? 'c1' : 'c2';
    // SJ changes notes
    this.noteForm = this.formBuilder.group({
      Description: [
        '',
        Validators.compose([Validators.required, this.requiredWithoutBlank]),
      ],
    });

  }

  getNotes() {
    this.movementGuid = (this.versionMovementGuid != null) ? this.versionMovementGuid : sessionStorage.getItem('movementGuid') || '';
    this.noteService.getNotes(this.movementGuid).subscribe(NotesData => {
      if (NotesData != undefined) {
        this.noteDetail = NotesData;
        if (this.noteDetail.length > 0) {
          this.generalNote = true;
        } else {
          this.generalNote = false;
        }
        this.noteDetail.forEach((p: any) => {
          if (
            p.createdTime &&
            p.createdTime.split('T')[1] != undefined
          ) {
            p.createdTime = this.date.toLongDate(
              this.date.getMoment(p.createdTime),
            );
          }
        });
      }
    });
  }

  openDeleteNoteModal(id: string, description: string) {
    this.modalRef = this.modalService.open(this.deleteNoteConformationContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    this.selectedActityToDeleteNote = {
      noteId: id,
      description: description
    };
  }
  closeDeleteNote() {
    this.selectedActityToDeleteNote = null;
  }

  deleteNote() {
    if (this.isDeleteNodeVisible && this.selectedActityToDeleteNote) {
      let data = {
        noteId: this.selectedActityToDeleteNote.noteId,
        policyGuid: this.policytxnid,
        movementGuid: sessionStorage.getItem('movementGuid') || '',
        // loggedInUser: this.userProfile?.LoggedUser,
      };
      this.subscription.push(
        this.noteService.deleteNote(data).subscribe(
          (response) => {
            if (response != undefined && response.isSuccessful) {
              this.showError = false;
              //console.log(response);
              this.showDeleteMsg = true;
              setTimeout(() => {
                this.showDeleteMsg = false;
              }, 5000);

              this.getlatestNotes();
            } else {
              this.showErrorMessage('There is a problem in deleting Note');
            }
            this.isLoader = false;
          },
          (err) => {
            this.isLoader = false;
          },
          () => {
            this.modalRef.close();
            this.selectedActityToDeleteNote = null;
          },
        ),
      );
    }
  }

  setNoteData(NotesData: any) {
    if (NotesData != undefined) {
      this.noteDetail = NotesData;
      if (this.noteDetail.length > 0) {
        this.generalNote = true;
        this.noteDetail.forEach((p: any) => {
          if (
            p.createdTime &&
            p.createdTime.split('T')[1] != undefined
          ) {
            p.createdTime = this.date.toLongDate(
              this.date.getMoment(p.createdTime),
            );
          }
        });
      } else {
        this.generalNote = false;
      }
    }
  }

  get f() {
    return this.noteForm.controls;
  }
  
  showError = false;

  noteSubmitted() {
    this.submitted = true;
    this.isLoader = true;
    this.policytxnid =  (this.policytxnid == "") ? sessionStorage.getItem('policyGuid') || "" : this.policytxnid;

    if (this.noteForm.invalid) {
      this.isLoader = false;
      this.showError = true;
      return;
    }
    let todayISOString = new Date().toISOString();
    let data = {
      policyGuid: this.policytxnid, //parseInt(this.policytxnid),
      movementGuid: sessionStorage.getItem('movementGuid') || '',
      description: this.noteForm.controls['Description'].value,
      // loggedInUser: this.userProfile?.LoggedUser,
    };
    // this.noteForm.setValidators(Validators.required);
    this.subscription.push(
      this.noteService.notes(data).subscribe((response) => {
        if (response.isSuccessful) {
          this.showError = false;
          //console.log(response);
          this.generalNote = true;
          this.showMsg = true;
          setTimeout(() => {
            this.showMsg = false;
          }, 5000);

          this.getlatestNotes();
          this.noteForm.controls['Description'].setValue(null);
        } else {
          this.showErrorMessage('There is a problem in Notes adding');
        }
        this.isLoader = false;
      },
        (err: any) => {
          this.isLoader = false;
        }
      ),
    );

  }

  showErrorMessage(message: string) {
    this.errorMsg = message;
    setTimeout(() => {
      this.errorMsg = '';
    }, 5000);
  }

  getError(v: any) {
    return JSON.stringify(v);
  }

  getlatestNotes() {
    this.subscription.push(
      this.noteService.getNotes(this.movementGuid.toString()).subscribe(
        (NotesData) => {
          if (NotesData != undefined) {
            this.noteDetail = NotesData;

            if (this.noteDetail.length > 0) {
              this.generalNote = true;
              this.noteDetail.forEach(
                (p: any) =>
                (p.createdTime = this.date.toLongDate(
                  this.date.getMoment(p.createdTime),
                )),
              );
            } else {
              this.generalNote = false;
            }
          }
        }
      ),
    );
  }
  // SJ changes notes
  requiredWithoutBlank(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      if (control.value.trim().length > 0) {
        return null;
      } else {
        return { required: true };
      }
    }
  }

  ngOnDestroy() {
    this.subscription.forEach((sub) => sub.unsubscribe());
  }
  openPolicyNotes() {
    this.getNotes();
    this.isNoteCollapsed = !this.isNoteCollapsed;
  }
}
