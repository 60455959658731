import { NgIf } from '@angular/common';
import {
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-deactivate-confirm-modal',
  standalone: true,
  imports: [NgIf],
  templateUrl: './deactivate-confirm-modal.component.html',
  styleUrl: './deactivate-confirm-modal.component.css',
})
export class DeactivateConfirmModalComponent {
  @Output() updateConfirmEvent: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('deactivateConfirmationBox') deactivateModal!: TemplateRef<any>;
  @Input() updateStatusModalEvent!: EventEmitter<any>;
  private modalService = inject(NgbModal);
  private data?: any;
  isDeactivate: boolean = false;

  ngOnInit(): void {
    if (this.updateStatusModalEvent) {
      this.updateStatusModalEvent.subscribe((data: any) => {
        this.isDeactivate = data.action === 'deactivate';
        this.data = data;
        this.open(this.deactivateModal);
      });
    }
  }

  open(content: TemplateRef<any>) {
    this.modalService
      .open(content, {
        size: 'lg',
        centered: true,
        backdrop: 'static',
      })
      .result.then(
        (result) => {},
        (reason) => {},
      );
  }

  onConfirm() {
    this.modalService.dismissAll();
    this.updateConfirmEvent.emit(this.data);
    this.data = undefined;
  }
}
