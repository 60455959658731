import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { EndPoint } from 'src/app/core/services/endpoint';
import { catchError } from 'rxjs/operators';
import { HttpCoreService } from '../../../../src/app/core/services/http-core.service';
import { CommonParams } from 'src/app/core/commonparams';
import { DateService } from 'src/app/core/services/date-updated.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PolicyDetailService {
  policyTransactionId = new BehaviorSubject<string>(
    sessionStorage.getItem('movementGuid') || '',
  );
  coiObj = new BehaviorSubject<{ typeId: string; business: string }>({
    typeId: '',
    business: '',
  });
  VIEW_COI: any = '10709';
  RESEND_COI: any = '10719';
  VIEW_PDS: any = '10720';
  SAVE_AS_PDF: any = '10709';
  usNotTravelCountryList = ['CUBA', 'IRAN', 'NORTH KOREA'];
  policyMovement = new BehaviorSubject<any[]>([]);
  dNTCountryListByError: any;
  dNTCountryList: any;
  dNTTravelMessage: any;

  constructor(
    private http: HttpClient,
    private httpService: HttpCoreService,
    private newDateService: DateService,
  ) { }

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET',
    'Access-Control-Allow-Origin': '*',
  });

  httpOptions = {
    headers: this.headers,
    // observe: 'response'
  };

  DecryptResult(params: any) {
    var result = this.httpService.postRequest(
      location.protocol + '//' + location.host + '/GTPAPI/DecryptResult',
      params,
    );
    return result;
  }

  emailHistory(policyGuid: any) {
    return this.httpService.getRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.EmailHistory}${policyGuid}`,
    );
  }

  regenerateCOI(reqObj: any) {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT}${EndPoint.RegenerateCOI}`, reqObj,
    );
  }
  exportHistory(policyGuid: any) {
    return this.httpService.getRequest(
      `${EndPoint.LTX_ENDPOINT}${EndPoint.ExportHistory}${policyGuid}`,
    );
  }

  getPolicyTxnIdForVersion(policyTxnId: any) {
    this.policyTransactionId.next(policyTxnId.toString());
  }

  async refreshMedicalToken(siteName: string){
   await this.http.post<any>(EndPoint.refreshTokenUrl + siteName, { grant_type: 'password' }, this.httpOptions).toPromise()
   .then((token) => {
    localStorage.setItem('gatewayToken', token.access_token);
    localStorage.setItem('bbJWT', token.client_jwt);
    const currentTimestamp = Date.now();
    localStorage.setItem( 'lastTokenRetry', (currentTimestamp + token.expires_in * 1000).toString(), );
   });
 }

  validateCoupon(
    autoClub: any,
    promocode: any,
  ): Observable<any> {
    return this.httpService.getRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.validatePromoCode}${autoClub}/${promocode}`,
    );
  }

  payment(policytxnid: any, paymentType: any, movementId?: any): Observable<any> {
    let movementQuery = "";
    if (movementId != undefined && movementId != null) {
      movementQuery = "?movementGuid=" + movementId.replace(/^"(.+)"$/, '$1');
    }
    return this.httpService.getRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.PaymentSummary}${policytxnid.toString()}/${paymentType}${movementQuery}`,
    );
  }

  compareEndorsment(policytxnid: any): Observable<any> {
    return this.httpService.getRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.CompareEndorsment}${policytxnid.toString()}`,
    );
  }
  viewCoi(txnId: any, id: any): Observable<any> {
    return this.httpService.getRequest(
      `${EndPoint.LTX_ENDPOINT}${EndPoint.ViewCOI}${txnId}/${id}`,
    );
  }
  policySummaryEmails(obj: object): Observable<any> {
    return this.http.post(
      'https://6lbilgditb.execute-api.us-east-1.amazonaws.com/QA/GetNewGenEmailActivityDetails',
      obj,
    );
  }
  resendCoi(movementGuid: any): Observable<any> {
    return this.httpService
      .getRequest(`${EndPoint.LTX_ENDPOINT}${EndPoint.ResendCOI}${movementGuid}`)
      .pipe(catchError((e) => throwError(e)));
  }

  viewPDS(id: any): Observable<any> {
    return this.httpService.getRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.viewPDS}${id}`,
    );
  }
  // saveAsPDF(txnId: any, id: any): Observable<any> {
  //   return this.httpService.getRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}AccessGeneratedPDFLink/${txnId}/${id}`,
  //   );
  // }
  // updateDetailsGTA(obj: object): Observable<any> {
  //   return this.httpService.putRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}UpdatePolicyDetailsGTA`,
  //     obj,
  //   );
  // }

  // updateQuoteGTA(obj: object): Observable<any> {
  //   return this.httpService.putRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}UpdateQuoteGTA`,
  //     obj,
  //   );
  // }

  paymentIssueRefund(postData: any): Observable<any> {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.paymentIssueRefund}`,
      postData,
    );
  }

  paymentUpdate(paymentType: any, postData: any): Observable<any> {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.paymentUpdate}/${paymentType}`,
      postData,
    );
  }
  paymentAdd(paymentType: any, postData: any): Observable<any> {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.paymentAdd}/${paymentType}`,
      postData,
    );
  }
  paymentDelete(paymentType: any, postData: any): Observable<any> {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.paymentDelete}/${paymentType}`,
      postData,
    );
  }
  // GetGTARefundDetails(postData: any) {
  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}GetGTARefundDetails`,
  //     postData,
  //   );
  // }
  // issueCancellationGTA(postData: any) {
  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}IssueCancellationGTA`,
  //     postData,
  //   );
  // }
  // closePaybleGTA(postData: any) {
  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}ClosePaybleGTA`,
  //     postData,
  //   );
  // }
  // cancelReasonList(): Observable<any> {
  //   return this.httpService.getRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}PolicyMasterData/gta`,
  //   );
  //   //   return this.http.get('https://6lbilgditb.execute-api.us-east-1.amazonaws.com/QA/GetPolicy/{policyTxnId}');
  // }

  paymentSummaryRefunds(reqObj: any): Observable<any> {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.paymentSummeryRefund}`,
      reqObj
    );
    //   return this.http.get('https://6lbilgditb.execute-api.us-east-1.amazonaws.com/QA/GetPolicy/{policyTxnId}');
  }
  policyCancel(reqObj: any): Observable<any> {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.policyCancel}`,
      reqObj
    );
  }

  policyReActivate(params: any): Observable<any> {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.policyReActivate}`,
      params,
    );
  }
  // issueRewrite(params: any): Observable<any> {
  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}IssueRewriteGTA`,
  //     params,
  //   );
  // }

  // updatePolicy(params: any) {
  //   return this.http.post(
  //     `${EndPoint.OCTAAPI_ENDPOINT}CreateNewEndorsementGTA`,
  //     params,
  //   );
  // }

  notes(params: any): Observable<any> {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.AddNotes}`,
      params,
    );
  }
  getNotes(id: any): Observable<any> {
    return this.httpService.getRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.GetNotes}${id}`,
    );
  }
  deleteNote(params: any): Observable<any> {
    return this.httpService.postRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.DeleteNotes}`,
      params,
    );
  }

  // refund(id: any): Observable<any> {
  //   return this.httpService.getRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}GetRefundSummaryDetails/${id}`,
  //   );
  // }

  // SaveMiscellaneousChanges(params: any) {
  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}SaveMiscellaneousChanges`,
  //     params,
  //   );
  // }

  // agentList(agencyId: any): Observable<any> {
  //   return this.httpService.getRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}GetAgencyAgentForSubcompany/GTA/${agencyId}`,
  //   );
  // }
  // agentName(param: any): Observable<any> {
  //   return this.httpService.getRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}GetContact/${param}`,
  //   );
  // }

  // getQuoteDetails(params: any) {
  //   return this.httpService.postRequestXapi(EndPoint.RSX_ENDPOINT, params);
  // }

  updateQuoteDetails(url: any, quoteParam: any) {
    return this.httpService.putRequest(url, quoteParam);
  }

  // agencyAgentURL(): Observable<any> {
  //   return this.httpService.getRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}GetAgencyAgentForSubcompany/GTA`,
  //   );
  // }
  // acceptDecliendPremiumForContact(obj: object): Observable<any> {
  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}AcceptDeclineAdditionalPremiumForContact`,
  //     obj,
  //   );
  // }
  // agentCommissiom(id: any, num: any, txnId: any) {
  //   return this.httpService.getRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}GetAgentCommissionInfoGTA/${id}/${num}/${txnId}`,
  //   );
  // }

  getPaymentCommission(movementGuid: any) {
    return this.httpService.getRequest(
      `${EndPoint.LTX_ENDPOINT + EndPoint.paymentCommission}${movementGuid}`
    );
  }

  fullRefund(postFullRefundData: any) {
    return this.http.post(
      location.protocol + '//' + location.host + '/GTPAPI/FullRefund',
      postFullRefundData,
    );
  }

  fullRefundOld(postFullRefundData: any) {
    return this.httpService.postRequest(
      location.protocol + '//' + location.host + '/GTPAPI/FullRefund',
      postFullRefundData,
    );
  }

  // addAdditionalPremiumGTA(obj: object) {
  //   //console.log(obj)
  //   let httpHeaders = {
  //     headers: new HttpHeaders({
  //     }),
  //   };

  //   return this.http.post<any>(
  //     EndPoint.OCTAAPI_ENDPOINT + 'AddAdditionalPremiumGTA',
  //     obj,
  //     httpHeaders,
  //   );
  // }


  getRequest(
    obj: object,
    access_token: any,
    client_jwt: any,
    VeriskRescoreURL: any,
  ): Observable<any> {
    let httpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Content-Type', 'application/json');
    httpHeaders = httpHeaders.append('Accept', 'application/json');
    httpHeaders = httpHeaders.append('Authorization', 'Bearer ' + access_token);
    httpHeaders = httpHeaders.append('x-vrr-auth', client_jwt);
    //console.log("access_token" + access_token);

    return this.http.post<any>(VeriskRescoreURL, obj, {
      headers: httpHeaders,
    }); //.pipe(catchError(this.handleError));
  }

  // getCountry() {
  //   return this.httpService.getRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}GetCountryList`,
  //   );
  // }

  getApiToken(siteName: string) {
    return this.http.post(
      location.protocol +
      '//' +
      location.host +
      '/Verisk/Authtoken?sitename=' +
      siteName,
      '',
    );
  }

  readMockupData(filePath: string) {
    return this.httpService.readMockupData(filePath);
  }

  setDestinationCountry(countryList: any) {
    sessionStorage.setItem('selectedCountry', JSON.stringify(countryList));
  }

  getDestinationCountry() {
    let country: any = sessionStorage.getItem('selectedCountry');
    return JSON.parse(country);
  }

  getValidationMessage(siteName: string, param: any) {
    let contactNumber: string;
    let selectedCountryList = this.getDestinationCountry();
    this.dNTCountryListByError = param != undefined ? param.DNTCountryListByError : null;
    this.dNTCountryList = param != undefined ? param.DNTCountryList : null;
    this.dNTTravelMessage = param != undefined ? param.DNTCountryErrorMessage : null;
    switch (siteName) {
      case 'RACV':
        contactNumber = '13 13 29';
        break;
      case 'RACQ':
        contactNumber = '1300 338 821';
        break;
      case 'RAA':
        contactNumber = '61 8 8202 4346';
        break;
      case 'AANT':
        contactNumber = '08 8925 5901';
        break;
      case 'RAC':
        contactNumber = '1 300 655 179';
        break;
      case 'World2Cover':
        contactNumber = '02 92257599';
        break;
      case 'W2C':
        contactNumber = '61 2 9225 7599';
        break;
      default:
        contactNumber = '';
    }
    let countryNames = selectedCountryList != null && this.dNTCountryListByError != null ? selectedCountryList.filter((value: any) => this.dNTCountryListByError.includes(value),) : '';

    if (selectedCountryList != null && this.dNTCountryListByError != null && selectedCountryList.some((item: any) => this.dNTCountryListByError.includes(item))) {
      return { tripDetailValidationMessages: { doNotTravelDestinationMsg: this.dNTTravelMessage.replace('{CountryName}', countryNames,), }, };
    }
    else {
      return {
        tripDetailValidationMessages: {
          doNotTravelDestinationMsg:
            selectedCountryList != null &&
              this.dNTCountryList != null &&
              selectedCountryList.filter((e: any) => this.dNTCountryList.find((obj: any) => obj.toUpperCase() === e.toUpperCase(),) !== undefined,) &&
              selectedCountryList.some((r: any) => this.dNTCountryList.indexOf(r) >= 0,)
              ? `You are unable to complete your quote for this destination.` // New msg added for cuba `Please contact ${siteName} service on ${contactNumber} for assistance with this change, you are unable to complete this change`
              : `Our policy excludes cover to any country with the advice of Do not travel and we cannot offer a policy to {destinations}.`,
        },
      };
    }
  }

  // applyCoupon(data: any) {
  //   let url = `${EndPoint.OCTAAPI_ENDPOINT}ApplyPromoCodeDiscountGTA`;
  //   return this.httpService.postRequest(url, data);
  // }

  // savepreExistingQuestions(
  //   policyTxnId: any,
  //   contactId: any,
  //   preExistingQuestionValues: any,
  // ): Observable<any> {
  //   let param: any = {
  //     PolicyTxnId: '',
  //     ContactId: '',
  //     Questionaire: {},
  //   };

  //   const url = `${EndPoint.OCTAAPI_ENDPOINT}SaveMedicalQuestionnaire`;

  //   param.PolicyTxnId = policyTxnId.toString();
  //   param.ContactId = contactId;
  //   param.Questionaire = preExistingQuestionValues;

  //   return this.httpService.putRequest(url, param);
  // }

  utcToAestDate(utcDate: string) {
    let aestTime = this.newDateService.getDateAsOfAESTFromDate(
      this.newDateService.getMomentFromDate(utcDate),
    );
    return new Date(aestTime);
  }

  // createActivityLogItem(activityType: any, activityDescription: any) {
  //   let current = new Date();
  //   current = new Date(this.utcToAestDate(current.toUTCString()));

  //   let reqObj = {
  //     PolicyTxnId: sessionStorage.getItem('movementGuid'),
  //     ActivityType: activityType,
  //     ActivityDate: current,
  //     Description: activityDescription,
  //     LoggedInUser: UserProfile.LoggedUser,
  //   };

  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}CreateActivityLogItem`,
  //     reqObj,
  //   );
  // }

  // getAddress(url: string, ifInfoOnly: boolean, searchedData: string) {
  //   url = `${environment.sitecoreBaseAddress}/${url}/${searchedData}/${ifInfoOnly}`;
  //   return this.httpService.getRaw(url);
  // }

  //LTX 
  getData(id: any, movement?: string): Observable<any> {
    id = id.replace(/^"(.+)"$/, '$1');
    let movementQuery = "";
    if (movement) {
      movementQuery = "?movementGuid=" + movement.replace(/^"(.+)"$/, '$1');
    }
    return this.httpService.getRequest(`${EndPoint.FETCH_POLICY}/${id}${movementQuery}`);
  }

  getVersionData(policyGuid: any, movementGuid: any): Observable<any> {
    policyGuid = policyGuid.replace(/^"(.+)"$/, '$1');
    movementGuid = movementGuid.replace(/^"(.+)"$/, '$1');
    return this.httpService.getRequest(`${EndPoint.FETCH_POLICY}/${policyGuid}?movementGuid=${movementGuid}`);
  }

  fetchStaticList(endPoint: string) {
    return this.httpService.getRequest(`${endPoint}`);
  }
  fetchCancelReasonList() {
    return this.httpService.getRequest(`${EndPoint.PAS_CANCELREASON_LIST}`);
  }
  fetchValidationMessages(endPoint: string) {
    return this.httpService.getRequest(`${endPoint}`);
  }

  fetchConfigurations(endPoint: string) {
    return this.httpService.getRequest(`${endPoint}`);
  }

  fetchColorThemes(endPoint: string) {
    return this.httpService.getRequest(`${endPoint}`);
  }

  getCountryLTX(): Observable<any> {
    return this.httpService.getRequest(`${EndPoint.PAS_COUNTRIES}`);
  }

  fetchAgents(autoClubGuid: any, agentContactId: any): Observable<any> {
    return this.httpService.getRequest(`${EndPoint.LTX_ENDPOINT + EndPoint.getAgentDetail}${agentContactId}&autoClubGuid=${autoClubGuid}`);
  }

  discardEN(params: any): Observable<any> {
    return this.httpService.postRequest(`${EndPoint.EN_DISCARD}`, params);
  }

  updateEndorsement(request: any): Observable<any> {
    return this.httpService.putRequest(`${EndPoint.EN_UPDATE}`, request);
  }

  fetchFromAddressify(ifInfoOnly: boolean, searchedData: string, state: string) {
    let url = "";
    if (ifInfoOnly) {
      url = `${EndPoint.API_ADDRESSIFY_ADDRESSPRO}` + searchedData + "&state=" + state;
    }
    else {
      url = `${EndPoint.API_ADDRESSIFY_AUTOCOMPLETE}` + searchedData + "&state=" + state;
    }
    return this.httpService.getRaw(url);
  }

  fetchProductConfigurationsLTX(request: any): Observable<any> {
    return this.httpService.postRequest(`${EndPoint.PAS_PRODUCTS_PRICING}`, request);
  }

}
