export enum PageNameEnum {
  Dashboard = 'Dashboard',
  Header = 'Header',
  ManageBranches = 'ManageBranches',
  ManageUsers = 'ManageUsers',
  PaymentGateway = 'PaymentGateway',
  PolicyPayment = 'PolicyPayment',
  PolicySummary = 'PolicySummary',
  PromoCode = 'PromoCode',
  Refunds = 'Refunds',
  PolicyDetails = 'PolicyDetails',
}

export enum SubComponentNameEnum {
  Agents = 'Agents',
  Cancellation = 'Cancellation',
  Dependents = 'Dependents',
  Discard = 'Discard',
  LeftMenu = 'LeftMenu',
  Notes = 'Notes',
  Payment = 'Payment',
  Policy = 'Policy',
  Quote = 'Quote',
  Refund = 'Refund',
  Summary = 'Summary',
  Traveller = 'Traveller',
  Trip = 'Trip',
  Versions = 'Versions',
  PreExisting = 'PreExisting',
  Details = 'Details',
}
