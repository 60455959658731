<ng-container class="text-center" style="min-height: 100%">
  <section class="mb-5">
    <div class="container">
      <h2 class="mt-4 box-label">Manage Users</h2>
      <div class="row">
        <div class="col-md-3 text-left">
          <h2 class="mt-4 box-label">Search</h2>
        </div>
        <div class="col-md-9 text-right">
          <a href="javascript:void(0);" class="btn btn-primary" (click)="openAddNewAgentModal()">Add a User</a>
        </div>
      </div>

      <div class="row">
        <div class="col-md-4">
          <div class="form-group">
            <select id="inputState" class="form-control" [value]="club" (change)="onClubSelect($event)"
              [(ngModel)]="club">
              <option *ngIf="this.listClubs.length > 1" value="" disabled="true" [selected]="true">Select Autoclub
              </option>
              <option selected="" *ngFor="let item of listClubs" [value]="item.autoClubGuid">
                {{ item.link_Url?.title }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <select id="inputState" class="form-control" (change)="onBranchSelect($event)" [(ngModel)]="branch">
              <option value="">Select Branch</option>
              <option *ngFor="let item of listBranches" [value]="item.branchGuid">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <select id="inputState" class="form-control" (change)="onAgentSelect($event)" [(ngModel)]="agent">
              <option value="">Select Agent</option>
              <option *ngFor="let item of listAgentDetail" [value]="item.agentGuid">
                {{ item.agentName }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <form [formGroup]="searchForm" (ngSubmit)="onAdvanceSearchSubmit()">
        <div class="row">
          <div class="col-md-12">
            <h4 class="box-label">
              <a href="javascript:void(0);" id="advance-search-btn" (click)="toggle()">Search by user details<i
                  class="fa fa-chevron-down custom-icon" aria-hidden="true"></i></a>
            </h4>
          </div>
        </div>

        <div class="row mt-3" id="advance-search" style="margin-top: 1rem !important" *ngIf="advanceSearchPanel">
          <div class="form-group col-sm-6 col-md-4">
            <input class="form-control" type="text" id="firstName" formControlName="firstName"
              placeholder="First Name" />
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Last Name" />
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <input type="text" class="form-control" id="email" formControlName="email" placeholder="Email" />
          </div>
        </div>

        <div class="row">
          <div class="col-md-12 mt-1 text-center btn-footer">
            <div *ngIf="noSearchValueSelectedError">
              <p class="alert alert-danger mt-3">
                <strong>At least one field in the Search section must be selected
                  in order for the search to return the results.</strong>
              </p>
            </div>
            <button class="btn btn-secondary btn-full-sm" id="clear" (click)="onAdvanceSearchClear()">
              Clear
            </button>
            <button class="btn btn-primary btn-full-sm" id="apply" type="button" (click)="onAdvanceSearchSubmit(true)">
              Search
            </button>
          </div>
        </div>
      </form>

      <div class="col-md-3 text-left">
        <h2 class="mt-4 box-label">Users</h2>
      </div>
      <app-agents-table [tableColumns]="tableColumns" (openEditModal)="openEditModal($event)"
        (openDeactivateModal)="openUpdateStatusModal($event)" />
    </div>
  </section>
</ng-container>

<ng-template #warningBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="modal.close()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Error }}</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="modal.close()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>

<app-deactivate-confirm-modal [updateStatusModalEvent]="updateStatusModalEvent"
  (updateConfirmEvent)="onUpdateConfirm($event)" />
<app-add-agent-modal [listClubs]="listClubs" [addAgentModalEvent]="agentModalEvent"
  (onAgentSuccess)="onAgentSuccess($event)" (onAgentFailed)="onAgentFailed($event)" />
<app-confirm-modal [confirmModalEvent]="confirmModalEvent" />
<app-loader></app-loader>