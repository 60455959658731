<ng-template
  #deactivateConfirmationBox
  let-modal
  let-c="close"
  let-d="dismiss"
  data-backdrop="static"
  data-keyboard="false"
>
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
    </h4>
    <button
      type="button"
      class="close"
      data-dismiss="modal"
      (click)="modal.close()"
    >
      &times;
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="isDeactivate; else elseBlock">
          <p>Are you sure you want to deactivate this user?</p>
        </div>
        <ng-template #elseBlock>
          <p>Are you sure you want to reactivate this user?</p>
        </ng-template>
      </div>
    </div>
  </div>

  <div class="modal-footer" style="justify-content: flex-end">
    <button
      type="button"
      class="btn btn-primary pull-right"
      (click)="modal.close()"
    >
      No
    </button>
    <button
      type="button"
      (click)="onConfirm()"
      class="btn btn-primary pull-right mr-2"
    >
      <span role="status" aria-hidden="false"></span>
      Yes
    </button>
  </div>
</ng-template>
