import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, } from '@angular/core';
import { PolicyDetailService } from '../app.services';
import { filter } from 'rxjs/operators';
import { FormGroup, FormBuilder, } from '@angular/forms';
import { NgbModal, NgbModalRef, } from '@ng-bootstrap/ng-bootstrap';
import { Utility } from 'src/app/core/services/utility';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { DateService } from 'src/app/core/services/date-updated.service';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum, SubComponentNameEnum, } from 'projects/policyDetails/src/app/enum/page-name-enum';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { isPolicyDataPresent, selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { isEndorsementDataPresent, selectCurrentEndorsement } from 'src/app/core/store/endorsement/endorsement.selectors';
import { LoaderService } from 'src/app/core/services/loader.service';
import { setEndorsement } from 'src/app/core/store/endorsement/endorsement.actions';
import { MapperService } from 'src/app/core/services/mapper.services';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';
import { SiteNameEnum } from '../enum/sitename-enum';

@Component({
  selector: 'policyDetails-policy-details-policy',
  templateUrl: './policy-details-policy.component.html',
  styleUrls: ['./policy-details-policy.component.css'],
})
export class PolicyDetailsPolicyComponent implements OnInit {
  agentList: any = [];
  disableButtonMode!: boolean;
  list: any = [];
  @Input() premiumChanged: any;
  @Input() isPremium: boolean = false;
  showHide!: boolean;
  agentForm!: FormGroup;
  public policyDetail: any;
  agentName: any;
  agentName1: any;
  submitted = false;
  policytxnid = '';
  modalRef!: NgbModalRef;
  isEditable = false;
  isLoader: boolean = false;
  msg: string = 'Agent updated successfully';
  siteName: any;
  clubGuid: any;
  agencyContactId: any;
  isUserUN: boolean = false;
  selectedAgent: any = [];
  agentGuid: any;
  coverageType: string = '';
  public isUserAuthorisedToEdit: boolean = false;
  @Input() isEditBtnRequired: boolean = true;
  @Input() versionMovementGuid: string | undefined;
  @Input() model: any;
  @ViewChild('dialogTmmaTemplate', { static: true })
  dialogTmmaTemplate!: TemplateRef<ElementRef>;
  @ViewChild('dialogContentTemplate', { static: true })
  dialogContent!: TemplateRef<ElementRef>;
  isAgentLoader: boolean = false;
  userRoleDetails?: UserRoleDetails;
  public agents: any = [];
  public currentPolicyInStore: any;
  public IsPolicyInStore: boolean = false;
  public currentENInStore: any;
  public IsENInStore: boolean = false;

  constructor(
    private loaderService: LoaderService,
    public formBuilder: FormBuilder,
    public policyDetailService: PolicyDetailService,
    private modalService: NgbModal,
    private portalCodeService: PortalCodeService,
    private newDateService: DateService,
    private logService: LogService,
    private mapperService: MapperService,
    private store: Store,
    private storeService: StoreGenericService
  ) {
    this.policytxnid = sessionStorage.getItem('movementGuid') || '';
  }

  ngOnInit() {
    this.agentForm = this.formBuilder.group({
      Agent: [" "],
    });
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        if (this.userRoleDetails?.IsUW) {
          this.isUserUN = true;
        }
      }
    });

    this.setAgentData(false);
    this.validateUserToAgentEdit();

    //Policy Store
    this.store.select(isPolicyDataPresent).subscribe(present => {
      this.IsPolicyInStore = present;
    });

    this.store.select(selectCurrentPolicy).subscribe(pol => {
      this.currentPolicyInStore = pol;
      if (pol != null) {
        this.policyDetail = pol;
        this.populatePolicyData(this.policyDetail);
      }
    });

    //Endorsement Store
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.IsENInStore = present;
      if (this.IsENInStore && !this.userRoleDetails?.IsAgent) {
        this.isEditable = true;
        //ToDo remove with Agent Impl
        this.isUserAuthorisedToEdit = true;
        if (this.userRoleDetails?.IsUW) {
          this.isUserUN = true;
        }
      }
      else {
        this.isEditable = false;
        //ToDo remove with Agent Impl
        this.isUserAuthorisedToEdit = false;
        this.isUserUN = false;
      }
    });

    this.store.select(selectCurrentEndorsement).subscribe(en => {
      this.currentENInStore = en;
      if (en != null) {
        // this.policyDetail = en;
        this.populatePolicyData(this.currentENInStore);
      }
    });
  }

  async updateAgent(data: any) {
    this.loaderService.show();
    this.submitted = true;
    this.isAgentLoader = true;
    let agentToUpdate: any;
    if (this.agentForm.invalid) {
      this.loaderService.hide();
      return;
    }

    var updateRequest: any = {};
    if (this.currentENInStore) {
      Object.assign(updateRequest, this.currentENInStore);
    }
    else {
      this.store.select(selectCurrentEndorsement).subscribe(en => {
        this.currentENInStore = en;
      });
    }

    //Update Starts here 
    updateRequest = await this.mapperService.mapEndorsementModel(updateRequest, "sales");
    var salesAgent = updateRequest.agentInfo;
    if (salesAgent) {
      salesAgent.isSalesToUpdate = true;
      salesAgent.agentGuid = (this.selectedAgent != null) ? this.selectedAgent.agentGuid : "";
      salesAgent.agentName = (this.selectedAgent != null) ? this.selectedAgent.agentName : "";
      salesAgent.branchGuid = (this.selectedAgent != null) ? this.selectedAgent.branchGuid : "";
      salesAgent.branchName = (this.selectedAgent != null) ? this.selectedAgent.branchName : "";
      salesAgent.roleId = (this.selectedAgent != null) ? this.selectedAgent.roleId : "";
    }
    updateRequest.agentInfo = salesAgent;

    this.policyDetailService.updateEndorsement(updateRequest).subscribe((enResponse) => {
      if (enResponse) {
        this.loaderService.hide();
        this.storeService.setENToStore(enResponse);
        sessionStorage.setItem('movementGuid', enResponse.movementGuid.toString());
      }
    }, (err: any) => {
      this.loaderService.hide();
    });
    this.closeModal();
  }

  populatePolicyData(data: any) {
    this.siteName = data.autoClub
    this.clubGuid = this.userRoleDetails?.AutoClubURLList.find((club: any) =>
      club.key === this.currentPolicyInStore.autoClub)?.autoClubGuid;
    sessionStorage.setItem('portalcode', this.siteName);
    if (data.movements != undefined && data.movements.length > 0 && this.versionMovementGuid != undefined) {
      let movement = (data.movements.length > 0) ? data.movements.find((data: any) => data.movementId === this.versionMovementGuid) : {};
      data.policyStatus = movement.transactionStatus === 'CN' ? 'Cancelled' : 'Active';
    }

    this.coverageType = data?.productAlias

    if (data && data.rateDate) {
      // data.rateDate = this.policyDetailService.utcToAestDate(data.rateDate);
      sessionStorage.setItem('PricingDate', data.rateDate);
    } else {
      sessionStorage.setItem('PricingDate', '');
    }

    if (data && data.purchaseDate) {
      sessionStorage.setItem('PurchaseDate', data.purchaseDate);
    }

    if (data && data.agentInfo && data.agentInfo.length > 0) {
      var agent = data.agentInfo.find((x: any) => x.agentType.toLowerCase() == "sales");
      if (agent != null) {
        this.agentGuid = agent.agentGuid;
        this.agentName = agent.agentName;
      }
    }
  }

  sortByFullName(a: any, b: any) {
    if (a.fullName.toLowerCase() > b.fullName.toLowerCase()) return 1;
    if (b.fullName.toLowerCase() > a.fullName.toLowerCase()) return -1;
    return 0;
  }

  setAgentData(agentPresent: boolean) {
    if (!agentPresent) {
      this.agentForm.setValue({ Agent: " " });
    }
    else {
      this.agentForm.setValue({ Agent: this.agentGuid != undefined ? this.agentGuid : " " });
    }
  }

  validateUserToAgentEdit() {
    if (Utility.isTrue(this.userRoleDetails?.IsUW) || Utility.isTrue(this.userRoleDetails?.IsSuperUser) || Utility.isTrue(this.userRoleDetails?.IsClubAdmin)) {
      this.isUserAuthorisedToEdit = true;
    }
  }

  agentSelectionChange(event: any) {
    let value = event.target.value;
    this.selectedAgent = this.list.find((x: any) => x.agentGuid == value);
  }

  utcToAestDate(utcDate: string) {
    let aestTime = this.newDateService.getDateAsOfAESTFromDate(
      this.newDateService.getMomentFromDate(utcDate),
    );
    return new Date(aestTime);
  }

  getPriceFixed(num: any, fixed: any) {
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  disableButton(val: any) {
    if (val.target.value) {
      this.disableButtonMode = false;
    } else {
      this.disableButtonMode = true;
    }
  }

  async openAgentModal() {
    this.agentList = [];
    this.setAgentData(false);
    //Agent Call by Club 

    this.policyDetailService.fetchAgents(this.clubGuid, this.userRoleDetails?.AgentContactId)
      .pipe(filter((agentResponse) => agentResponse !== undefined)).subscribe((agentResponse) => {
        var agentListResponse = agentResponse.responseMessage;
        if (agentListResponse) {
          agentListResponse.forEach((element: any) => {
            if (![1].includes(element.roleId)) {
              let obj: any = {};
              obj = Object.assign(element);
              obj.fullName = element.agentName;
              this.agentList.push(obj);
            }
          });
        }
        this.agentList.sort(this.sortByFullName);
        this.list = this.agentList;

        let agent = this.agentList.filter((x: { agentGuid: any; }) => x.agentGuid == this.agentGuid);
        let agentPresent = (agent.length > 0) ? true : false;
        this.setAgentData(agentPresent);

      }, (err) => { });

    this.disableButtonMode = true;
    this.modalRef = this.modalService.open(this.dialogContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  async openTmmaModal() {
    this.setAgentData(false);
    this.agentList = [];
    let adminAutoClubGuid: string;

    adminAutoClubGuid = this.userRoleDetails?.AutoClubURLList.find((club: any) =>
      club.key === SiteNameEnum.W2C)?.autoClubGuid;
    //Agent Call by Club 
    this.policyDetailService.fetchAgents(adminAutoClubGuid, this.userRoleDetails?.AgentContactId)
      .pipe(filter((agentResponse) => agentResponse !== undefined)).subscribe((agentResponse) => {
        var agentListResponse = agentResponse.responseMessage;
        if (agentListResponse) {
          agentListResponse.forEach((element: any) => {
            let obj: any = {};
            obj = Object.assign(element);
            obj.fullName = element.agentName;
            this.agentList.push(obj);
          });
        }
        this.agentList.sort(this.sortByFullName);
        this.list = this.agentList;

        let agent = this.agentList.filter((x: { agentGuid: any; }) => x.agentGuid == this.agentGuid);
        let agentPresent = (agent.length > 0) ? true : false;
        this.setAgentData(agentPresent);

      }, (err) => { });

    // this.policyDetailService
    //   .agentList('')
    //   .pipe(filter((data) => data !== undefined))
    //   .subscribe((data) => {
    //     data = data.filter((f: any) => f.ShortName == 'W2C');
    //     data.forEach((element: any) => {
    //       if (element.Agents) {
    //         element.Agents.forEach((ele: any) => {
    //           if (ele.NameTypes[0].NameTypeCode == 'PA1') {
    //             let obj: any = {};
    //             obj = Object.assign(ele);
    //             obj.ParentContactId = element.ParentContactId;
    //             this.agentList.push(obj);
    //           }
    //         });
    //       }
    //     });
    //     this.agentList.sort(this.sortByFullName);
    //     this.list = this.agentList;
    //   });

    this.modalRef = this.modalService.open(this.dialogTmmaTemplate, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeModal() {
    this.modalRef.close();
  }

  closeModal1() {
    this.modalRef.close();
  }
}


