import { Injectable } from '@angular/core';
import { Okta } from '../../../../src/app/shared/okta/okta.service';
import { Constantsurls } from '../../core/constantsurls';

@Injectable({
  providedIn: 'root',
})
export class OktaCustomAuthService {
  user: any;
  oktaSignIn;

  constructor(private okta: Okta) {
    this.oktaSignIn = this.okta.getWidget();
    //this.oktaSignIn.authClient.session.get((response: any) => {
    //  let loggedUser = JSON.parse(sessionStorage.getItem('auth') || '');
    //  if (loggedUser == null || response.status !== 'ACTIVE') {
    //    this.oktaSignIn.authClient.signOut(() => {
    //      this.user = undefined;
    //      sessionStorage.clear();
    //      location.href =
    //        location.protocol + '//' + location.host + Constantsurls.login;
    //    });
    //  }
    //});
  }
}
