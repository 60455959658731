import { Component, OnInit, Input } from '@angular/core';
import { PolicyDetailService } from '../app.services';
import { Store } from '@ngrx/store';
import { isPolicyDataPresent, selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { isEndorsementDataPresent, selectCurrentEndorsement } from 'src/app/core/store/endorsement/endorsement.selectors';

@Component({
  selector: 'policyDetails-policy-details-policy-movement',
  templateUrl: './policy-details-policy-movement.component.html',
  styleUrls: ['./policy-details-policy-movement.component.css'],
})
export class PolicyDetailsPolicyMovementComponent implements OnInit {
  policymovement: any = [];
  policyTxnid = '';
  isPolicyMovementCollapsed = false;
  public currentPolicyInStore: any;
  public IsPolicyInStore: boolean = false;
  public currentENInStore: any;
  public IsENInStore: boolean = false;
  public isUpdateENCalled: boolean = false;

  constructor(
    public dataService: PolicyDetailService,
    private store: Store
  ) { }

  ngOnInit() {
    //Policy Store
    this.store.select(isPolicyDataPresent).subscribe(present => {
      this.IsPolicyInStore = present;
    });

    this.store.select(selectCurrentPolicy).subscribe(pol => {
      this.isPolicyMovementCollapsed = false;
      this.currentPolicyInStore = pol;
      if (pol != null) {
        this.populatePolicyData(this.currentPolicyInStore);
      }
    });

    //Endorsement Store
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.isUpdateENCalled = present;
    });

    this.store.select(selectCurrentEndorsement).subscribe(en => {
      this.isPolicyMovementCollapsed = false;
      this.currentENInStore = en;
      if (en != null) {
        this.populatePolicyData(this.currentENInStore);
      }
    });

  }

  getPriceFixed(num: any, fixed: any) {
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  populatePolicyData(currentPolicyInStore: any) {
    if (currentPolicyInStore.movements && currentPolicyInStore.movements.length > 0) {
      let sortedMovements = currentPolicyInStore.movements.sort(function (a: { version: number }, b: { version: number }) { return a.version - b.version });
      this.policymovement = sortedMovements;
    }
    console.log("isPolicyMovementCollapsed : ", this.isPolicyMovementCollapsed);
  }
}
