import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class NavigationPreventionService implements OnDestroy {

  constructor(private location: Location) {
    window.addEventListener('popstate', this.preventBackNavigation);
  }

  ngOnDestroy() {
    window.removeEventListener('popstate', this.preventBackNavigation);
  }

  preventBackNavigation = (event: PopStateEvent) => {
    // Prevent the default action of navigating back
    event.preventDefault();
    // Use Angular's Location service to stay on the current path
    this.location.go(this.location.path());
  };
}
