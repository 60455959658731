import { Component, Inject, OnInit } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { HeaderNavigationService } from 'projects/nav/src/app/app.service';
import { Subject, filter, takeUntil } from 'rxjs';
import { UserProfile } from 'src/app/core/userdetails/userprofile';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.actions';

@Component({
  selector: 'entra-id-login',
  template: '',
})
export class LoginComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private headerNavigationService: HeaderNavigationService,
    private store: Store,
  ) {}

  // On initialization of the page, display the page elements based on the user state
  async ngOnInit() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None,
        ),
        takeUntil(this._destroying$),
      )
      .subscribe(async () => {
        if (!this.authenticated) {
          this.login();
        } else {
          this.getSecurityRole();
        }
      });

    // Used for storing and displaying token expiration
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.LOGIN_SUCCESS,
        ),
      )
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        if (localStorage.getItem('accessToken') == null) {
          localStorage.setItem('accessToken', payload.accessToken);
          localStorage.setItem('entraIdAccount', JSON.stringify(payload.account));
          this.msalService.instance.setActiveAccount(payload.account);
        }
      });
  }

  getUserRoleDetails(responseMessage: any) {
    return {
      ContactEmail: responseMessage.email,
      AgentContactId: responseMessage.agentGuid,
      BranchContactId: responseMessage.branchGuid,
      AgencyContactId: responseMessage.isAgency ? responseMessage.agentGuid : '',
      ClubAdminContactId: responseMessage.isClubAdmin == true ? responseMessage.agentGuid : '',
      SuperUserContactId: responseMessage.isSuperUser == true ? responseMessage.agentGuid : '',
      InsuredContactId: responseMessage.isInsured == true ? responseMessage.agentGuid : '',
      ReadOnlyContactId: responseMessage.isReadOnly == true ? responseMessage.agentGuid : '',
      UWUserId: responseMessage.isUW == true ? responseMessage.agentGuid : '',
      IsUW: responseMessage.isUW,
      IsAgency: responseMessage.isAgency,
      IsClubAdmin: responseMessage.isClubAdmin,
      IsSuperUser: responseMessage.isSuperUser,
      IsAgent: responseMessage.isAgent,
      IsInsured: responseMessage.isInsured,
      IsReadOnly: responseMessage.isReadOnly,
      IsTMMReadOnly: responseMessage.isTMMReadOnly,
      TMMReadOnlyUserId: responseMessage.isTMMReadOnly == true ? responseMessage.agentGuid : '',
      AutoClubURLList: responseMessage.autoClubURLList,
      NavigationMenuItems: responseMessage.navigationMenuItems
    };
  }

  getSecurityRole() {
    const email = UserProfile.getAuthData().username;
    const logedUser = UserProfile.getAuthData().name;
    const token = 'Bearer ' + localStorage.getItem('accessToken');
    this.headerNavigationService.getSecurityRole(email).subscribe((resp) => {

      if (resp) {
        let userProfile = { LoggedUser: logedUser, ContactEmail: email, Token: token }; 
        let userRoleDetails = this.getUserRoleDetails(resp);
        this.store.dispatch(loginUser({
          loginUser: {
            userProfile: userProfile,
            userRoleDetails: userRoleDetails
          }
        }));
      } else {
        this.logout();
      }
    });
  }
  // Log the user in and redirect them if MSAL provides a redirect URI otherwise go to the default URI
  login() {
    this.msalService.loginRedirect({
      ...this.msalGuardConfig.authRequest,
    } as RedirectRequest);
  }

  logout() {
    sessionStorage.clear();
    localStorage.clear();
    this.msalService.logoutRedirect({
      account: this.msalService.instance.getActiveAccount(),
      postLogoutRedirectUri: '/',
    });
  }

  get authenticated(): boolean {
    return this.msalService.instance.getActiveAccount() ? true : false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
