import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormatNumberPipe } from './format-number.pipe';
import { NameFormatPipe } from './nameFormate.pipe';
import { TruncateNumberPipe } from './truncate.pipe';

@NgModule({
  declarations: [FormatNumberPipe, NameFormatPipe, TruncateNumberPipe],
  imports: [BrowserModule],
  exports: [FormatNumberPipe, NameFormatPipe, TruncateNumberPipe],
})
export class CommonPipeModule {}
