import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LogService } from '../../../projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from '../../../projects/policyDetails/src/app/shared/enum/log-level-enum';
@Injectable({
  providedIn: 'root'
})
export class GridService {

  constructor(private logService:LogService){

  }
  buildDtOptionsWithPaging(dtOptions:any, obs: Observable<any>, req: any, errorPage: any):any{
    dtOptions.serverSide = true;
    dtOptions.pageLength = 10;
    dtOptions.orderMulti = false;
    dtOptions.order = [];
    dtOptions.ajax = this.getAjaxMethod(obs,req,errorPage);
    return dtOptions;
  }

  getAjaxMethod( obs: Observable<any>, req: any, errorPage: any):any{
    return (dataTablesParameters: any, callback: any) => {
      req.pageNo = dataTablesParameters.start / dataTablesParameters.length;
      req.pageSize = dataTablesParameters.length;
      req.isPaginationEnabled = true;
      if(dataTablesParameters.order.length > 0){
        let order = dataTablesParameters.order[0];
        let colName = dataTablesParameters.columns[order.column].data;
        req.column = colName;
        req.dir = order.dir;
      }
      obs.subscribe(
        {
          next:
            (res) => {
              // -- error handling - start 
              if (res?.Success == 'false') {
                console.error("Error ", res.Message);
                return;
              }
              // // -- error handling - end 
  
              callback({
                recordsTotal: res.itemsCount,
                recordsFiltered: res.itemsCount,
                data: res.response
              });
            },
          error:
            (err) => {
              this.logService.createErrorLog(
                {},
                err,
                'GetOctaAgencyPortalApiServiceList',
                errorPage,
                LogLevel.Error,
              );
            },
        }
      )
    }  
 
  }

}
