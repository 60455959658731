/// <reference path="../../../environments/environment.ts" />
/// <reference path="endpoint.ts" />
/// <reference path="endpoint.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EndPoint } from './endpoint';
// import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in-no-jquery';
// import * as OktaSignIn from '@okta/okta-signin-widget/dist/js/okta-sign-in.min';
import { environment } from '../../../environments/environment';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  constructor(
    private http: HttpClient,
    private currencyPipe: CurrencyPipe,
  ) {}

  getFormattedPrice(price: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencyDisplay: 'code',
      minimumFractionDigits: 0,
    }).format(price);
  }

  getCultureFormattedPrice(price: number) {
    return new Intl.NumberFormat('en-AU', {
      style: 'currency',
      currencyDisplay: 'symbol',
      currency: 'AUD',
      minimumFractionDigits: 2,
    }).format(price);
  }

  getPriceFixed(num: any, fixed: any) {
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    let fixedPrice = num.toString().match(re)[0];
    fixedPrice = this.currencyPipe.transform(
      fixedPrice,
      'USD',
      'symbol',
      '1.2-2',
    );
    return fixedPrice;
  }
}
