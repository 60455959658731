import {
  Directive,
  HostListener,
  ElementRef,
  Input,
  OnInit,
  OnChanges,
} from '@angular/core';
import { PatternValidator } from '@angular/forms';

@Directive({
  selector: '[InputValidation]',
})
export class InputValidationDirective implements OnChanges {
  @Input() length!: number;
  @Input() inputType: string = 'alpha';
  inputTypeOptions: any = { alpha: /^[a-zA-Z]+$/i, num: /^[0-9]+$/i };

  constructor(public el: ElementRef) {}

  ngOnChanges(change: any) {
    if (!this.inputTypeOptions[this.inputType]) this.inputType = 'alpha';
  }

  @HostListener('beforeinput', ['$event']) onKeyDown(event: any) {
    if (!event.data) return true;

    if (this.inputTypeOptions[this.inputType].test(event.data))
      return this.validateLength(this.el.nativeElement.value + event.data);
    else return false;
  }

  validateLength(value: any) {
    if (!this.length && this.length != 0) return true;
    else {
      if (value.toString().length > this.length) return false;
      else return true;
    }
  }

  // @HostListener('paste', ['$event']) onPaste(event) {
  //   let value = this.el.nativeElement.value;
  //   let clipboardData = event.clipboardData;
  //   let pastedData = clipboardData.getData('Text');
  //   if (new RegExp(this.inputTypeOptions[this.inputType]).test(pastedData))
  //     return this.validateLength(value + pastedData);
  //   else
  //     return false;
  // }
}
