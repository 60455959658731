import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  transform(value: any, locale: string = 'aus'): any {
    if (value) {
      let num = value.replace(/[^a-zA-Z0-9]/g, '');
      // 		if (locale === 'aus') {
      //   // 96 0344 2345
      // 		  return num.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2 $3');
      // 		} else {
      // 			// (960) 344-2345
      // 			return '(' + num.substring(0, 3) + ') ' + num.substring(3, 6) + '-' + num.substring(6, num.length);
      // 		}
      return num;
    }
    return null;
  }
}
