import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'truncateNumber',
})
export class TruncateNumberPipe implements PipeTransform {
  transform(value: number | any) {
    const fixedUpto = 2;
    const re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixedUpto || -1) + '})?');
    if (
      value.toString().match(re) != undefined &&
      value.toString().match(re).length > 0
    ) {
      return value.toString().match(re)[0];
    } else {
      return value;
    }
  }
}
