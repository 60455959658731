/// <reference path="../../../../../src/app/core/services/price.service.ts" />
/// <reference path="../../../../../src/app/core/services/date.service.ts" />
import { Component, ViewChild, TemplateRef, ElementRef, Input, OnInit, AfterViewInit, } from '@angular/core';
import { PolicyDetailService } from '../../app/app.services';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, } from '@angular/forms';
import { OktaCustomAuthService } from '../../../../../src/app/shared/okta/oktaauth.service';
import { DataBusService } from '../services/databus.service';
import { CommonParams } from 'src/app/core/commonparams';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Utility } from '../shared/utility';
import { window } from 'rxjs/operators';
import { PriceService } from '../../../../../src/app/core/services/price.service';
import { CurrencyPipe } from '@angular/common';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { CancelEventEmitterService } from '../shared/services/cancel-event-emitter.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { SetQueryParamsService } from 'src/app/core/role/setqueryparams';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum, SubComponentNameEnum, } from 'projects/policyDetails/src/app/enum/page-name-enum';
import { ReturnStatement } from '@angular/compiler';
import { EndPoint } from 'src/app/core/services/endpoint';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails, UserProfile } from 'src/app/core/store/loginUser/loginUser.model';
import { isEndorsementDataPresent, selectCurrentEndorsement } from 'src/app/core/store/endorsement/endorsement.selectors';
import { selectCurrentPolicy, isPolicyDataPresent } from 'src/app/core/store/policy/policy.selectors';
import { DateService } from 'src/app/core/services/date-updated.service';

@Component({
  selector: 'policyDetails-policy-details-cancellation',
  templateUrl: './policy-details-cancellation.html',
  styleUrls: ['./policy-details-cancellation.css'],
})
export class PolicyDetailsCancellationComponent
  implements OnInit, AfterViewInit {
  @Input('refundData') refundData: any;
  cancelButton!: boolean;
  cancelCashRefundMessageEror: any;
  cancelMessageEror: any;
  cancelMessageSuccess: any;
  cancelMessageAlertBtn: any;
  cancelMessageFail: any;
  cancelForm!: FormGroup;
  cancelCashPaymentForm!: FormGroup;
  movementGuid!: string;
  cancellist: any[] = [];
  isBrainTreeRefundFailedList: any = [];
  policyDetails: any = {};
  modelRef!: NgbModalRef;
  modelChildRef!: NgbModalRef;
  modelAlertRef!: NgbModalRef;
  PercentageBoxRef!: NgbModalRef;
  RefundConfirmRef!: NgbModalRef;
  OriginalrefundData: any;
  ShowPercentageDiv: boolean = false;
  PartialRefundPercentage: any;
  PartialRefundOnlyToCC: boolean = false;
  PercentageError: boolean = false;
  PercentageEntered: boolean = false;
  selectedInputState: string = '';
  modelManangeRefundsRef!: NgbModalRef;
  modelcancelCashPaymentRefundRef!: NgbModalRef;
  paymentRefundsData: any;
  CancellationTxnId: any;
  CancelAllPolicyMessage: string | undefined;
  TxnStatusCode!: string;
  ProcessStatusCode!: string;
  isLoader: boolean = false;
  isConfirmLoader: boolean = false;
  isHideCancel: boolean = true;
  isCancelButtonEnabled: boolean = false;
  isBrainTreeRefundFailed: boolean = false;
  isIssuedButtonDisabled: boolean = false;
  isDisplayPolicyCancelMessage: boolean = true;
  today!: Date;
  setApiTxnEffDate: any;
  cancelFormError: boolean = false;
  finalCanecellation: any = [];
  selectedTransaction: any;
  purchaseDate: any;
  expiryDate: any;
  departureDate: any;
  NotesData: any;
  Movement: any;
  Activity: any;
  noteForm!: FormGroup;
  submitted = false;
  refundAllText = 'Refund all Transactions';
  isRefundProcessInitated = false;
  isRefundClicked = false;
  isRefundAllClicked = false;
  isRefundAllDone = false;
  refundIndex = -1;
  IsRefundValueShow: boolean = false;
  cashReciptError: string | undefined;
  userRoleDetails?: UserRoleDetails;
  userProfile?: UserProfile;
  @ViewChild('cancelcontentTemplate', { static: true })
  dialogBox: TemplateRef<ElementRef>;
  @ViewChild('cancelconfirmTemplate', { static: true })
  dialogconfirmBox: TemplateRef<ElementRef>;
  @ViewChild('cancelAlertTemplate', { static: true })
  dialogalertBox: TemplateRef<ElementRef>;
  @ViewChild('manageRefundsTemplate', { static: true })
  dialogManageRefundsBox: TemplateRef<ElementRef>;
  @ViewChild('cancelCashPaymentRefundcontentTemplate', { static: true })
  dialogManageCashRefundsBox: TemplateRef<ElementRef>;
  siteName: any;
  @ViewChild('noteBox', { static: true })
  noteBox!: ElementRef;
  @ViewChild('PercentageBox', { static: true })
  PercentageBox!: ElementRef;
  @ViewChild('manageRefundConfirmAllTemplate', { static: true })
  dialogRefundConfirmBox: TemplateRef<ElementRef>;
  postdatarequest: any = {};
  public currentPolicyInStore: any;
  public IsPolicyInStore: boolean = false;
  public currentENInStore: any;
  public IsENInStore: boolean = false;

  constructor(
    public dataService: PolicyDetailService,
    public formBuilder: FormBuilder,
    private portalCodeService: PortalCodeService,
    //public oktaCustomAuthService: OktaCustomAuthService,
    private priceService: PriceService,
    private currencyPipe: CurrencyPipe,
    private dataBusService: DataBusService,
    private el: ElementRef,
    private modelService: NgbModal,
    private eventEmitterService: CancelEventEmitterService,
    private loaderService: LoaderService,
    private setQueryParamsService: SetQueryParamsService,
    private logService: LogService,
    private store: Store,
    private dateService: DateService
  ) {
    this.dialogBox = this.el.nativeElement;
    this.dialogconfirmBox = this.el.nativeElement;
    this.dialogalertBox = this.el.nativeElement;
    this.dialogManageRefundsBox = this.el.nativeElement;
    this.dialogManageCashRefundsBox = this.el.nativeElement;
    this.dialogRefundConfirmBox = this.el.nativeElement;

    //Policy
    this.store.select(selectCurrentPolicy).subscribe(pol => {
      this.currentPolicyInStore = pol;
    });

    this.store.select(isPolicyDataPresent).subscribe(present => {
      this.IsPolicyInStore = present;
      this.bindPolicyData();
    });

    //Endorsement Store
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.IsENInStore = present;
    });

    this.store.select(selectCurrentEndorsement).subscribe(en => {
      this.currentENInStore = en;
    });
  }

  ngOnInit() {
    this.noteForm = this.formBuilder.group({
      Description: [
        '',
        Validators.compose([Validators.required, this.requiredWithoutBlank]),
      ],
    });

    // this.dataBusService.policyData.subscribe(
    //   (data) => {

    //   },
    //   (err) => {
    //     this.logService.createErrorLog(
    //       {},
    //       err,
    //       'GetPolicyData',
    //       PageNameEnum.PolicyDetails + ' ' + SubComponentNameEnum.Cancellation,
    //       LogLevel.Error,
    //     );
    //   },
    // );
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;

        this.bindPolicyData();
      }
    });

    this.cancelForm = this.formBuilder.group({
      CancelReason: ['', Validators.required],
    });

    this.cancelCashPaymentForm = this.formBuilder.group({
      ReceiptNumber: [''],
    });
  }

  bindPolicyData() {
    if (this.IsPolicyInStore && this.currentPolicyInStore && this.currentPolicyInStore.movements.length > 0) {
      var sortedMovements = this.currentPolicyInStore.movements.sort(function (a: { version: number }, b: { version: number }) { return a.version - b.version });
      this.siteName = this.currentPolicyInStore.autoClub;

      let result = sortedMovements[sortedMovements.length - 1];

      //let result = data.PolicyMovement.find(
      // (data) => data.TxnStatusCode === 'CN' && data.ProcessStatusCode === 'C'
      //);
      //if (result == undefined) {
      //  result = data.PolicyMovement.find((data) => data.TxnStatusCode === 'EX');
      //}

      //if (result == undefined) {
      //  result = data.PolicyMovement.find((data) => data.TxnStatusCode === 'CN');
      //}

      //if (result == undefined) {
      //  result = data.PolicyMovement.find((data) => data.TxnStatusCode === 'PE' && data.TxnTypeCode === 'EN');
      //  if (result && result.TxnStatusCode === 'PE' && result.TxnTypeCode === 'EN') {
      //    this.isHideCancel = true;
      //  }
      //}
      this.isHideCancel = false;
      if (result && result.transactionStatus === 'CN') {
        this.isHideCancel = true;
      }

      if (result && result.transactionStatus === 'EX' && !this.userRoleDetails?.IsUW) {
        this.isHideCancel = true;
      }

      if (result && result.status === 'Pending') {
        this.isHideCancel = true;
      }

      this.today = new Date();
      this.setApiTxnEffDate = this.currentPolicyInStore.fromDate;

      this.movementGuid = this.currentPolicyInStore.movementGuid.toString();

      // this.NotesData = this.currentPolicyInStore;
      if (this.currentPolicyInStore && this.currentPolicyInStore.movements) {
        this.Movement = this.currentPolicyInStore.movements;
      }

      // if ( this.NotesData && this.NotesData.ActivityHistory && this.NotesData.ActivityHistory != undefined ) {
      //   this.Activity = this.NotesData.ActivityHistory;
      // }
    }

    if (this.currentPolicyInStore) {
      let purchaseDate = new Date(
        this.dataService.utcToAestDate(
          (this.dateService.parseDateString(this.currentPolicyInStore.purchaseDate) || new Date()).toUTCString(),
        ),
      );
      let expiryDate = new Date(
        this.dataService.utcToAestDate(
          (this.dateService.parseDateString(this.currentPolicyInStore.toDate) || new Date()).toUTCString(),
        ),
      );
      let departureDate = new Date(
        this.dataService.utcToAestDate(
          (this.dateService.parseDateString(this.currentPolicyInStore.fromDate) || new Date()).toUTCString(),
        ),
      );
      this.enableCancelButton(purchaseDate, expiryDate, departureDate);

      this.purchaseDate = purchaseDate;
      this.expiryDate = expiryDate;
      this.departureDate = departureDate;
    }
    this.enableCancelButton(this.purchaseDate, this.expiryDate, this.departureDate,);
  }

  ngAfterViewInit() { }

  enableCancelButton(purchaseDate: any, expiryDate: any, departureDate: any) {
    let currentDate = new Date();
    if (Utility.isTrue(this.userRoleDetails?.IsReadOnly)) {
      this.isCancelButtonEnabled = false;
    }
    else if(Utility.isTrue(this.userRoleDetails?.IsSuperUser)){
      this.isCancelButtonEnabled = true;
      //If trip already started
      if (Utility.isTrue(this.userRoleDetails?.IsSuperUser)) {
        let diffInDays = Utility.differenceInDaysDate(currentDate, departureDate);
        if (diffInDays < 0) {
          this.isCancelButtonEnabled = false;
        }
      }
    }
    else if (Utility.isTrue(this.userRoleDetails?.IsAgent)) {
      let dateDiffInDays = Utility.differenceInDaysDate(purchaseDate, this.dataService.utcToAestDate(currentDate.toISOString()));
      if (dateDiffInDays < 21 && dateDiffInDays >= 0) {
        this.isCancelButtonEnabled = true;
      }
      //If trip already started
      if (Utility.isTrue(this.userRoleDetails?.IsAgent)) {
        let diffInDays = Utility.differenceInDaysDate(currentDate, departureDate);
        if (diffInDays < 0) {
          this.isCancelButtonEnabled = false;
        }
      }
    }
    else if (Utility.isTrue(this.userRoleDetails?.IsClubAdmin)) {
      let daysRemainsToExpire = Utility.differenceInDaysDate(currentDate, expiryDate);
      if (daysRemainsToExpire >= 0) {
        this.isCancelButtonEnabled = true;
      }
    }
    else if (Utility.isTrue(this.userRoleDetails?.IsUW)) {
      this.isCancelButtonEnabled = true;
    }
  }

  setYearFormDateFormat(dateValue: any) {
    if (dateValue != undefined && dateValue != '') {
      let date = dateValue.split('T')[0].split('-');
      let day = date[2];
      let month = date[1];
      let year = date[0];
      let ngbDateStruct = day + '/' + month + '/' + year;

      return ngbDateStruct;
    }
    return dateValue;
  }

  setPostDateFormat(date: any) {
    if (date == '' || date == null || date === undefined || typeof date != 'object') {
      return;
    }
    let ngbDate = date;
    let datevalue =
      ngbDate['year'].toString() +
      '-' +
      ngbDate['month'].toString() +
      '-' +
      ngbDate['day'].toString();
    return datevalue;
  }

  openCashRefundModel() {
    this.modelcancelCashPaymentRefundRef = this.modelService.open(
      this.dialogManageCashRefundsBox,
      {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      },
    );
  }

  openAlertModel() {
    this.modelAlertRef = this.modelService.open(this.dialogalertBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  checkForCancellation() {
    // if (this.isNoteAdded()) {
    // 	this.openModel();
    // } else {
    // 	this.openNotes();
    // }
    this.openNotes();
  }

  // openModel() {
  //   this.dataService.cancelReasonList().subscribe((response) => {
  //     let formTypes = response.FormsetTypes.filter((x) => x.FormSetTypeCode == 'EN' && !x.IsDefault);
  //     if (formTypes) {
  //       sessionStorage.setItem('coiFormSetId', formTypes[0].FormSetId);
  //     }
  //     this.cancellist = response.CancelReasonCodes;
  //     this.cancellist = this.cancellist.filter((data) => data.IsInactive == 0 && data.CancelReasonCode !== 'CR');
  //   });
  //   this.eventEmitterService.onClick();
  //   this.modelRef = this.modelService.open(this.dialogBox, {
  //     size: 'lg',
  //     backdrop: 'static',
  //     keyboard: false
  //   });
  // }

  openModel() {
    //   let response: any
    //   this.openCancelModal(response);
    if (sessionStorage.getItem('policyMasterGtaData')) {
      this.openCancelModal(
        JSON.parse(sessionStorage.getItem('policyMasterGtaData') || ''),
      );
    }
    else {
      // let reqObj = {
      //   policyGuid: this.currentPolicyInStore.policyGuid,
      //   movementGuid: this.currentPolicyInStore.movementGuid,
      //   loggedInUser: UserProfile.LoggedUser
      // }
      // this.dataService.paymentSummaryRefunds(reqObj).subscribe(
      //   (response) => {
      let response: any;
      this.openCancelModal(response);
      // }
      // );
    }
  }

  openCancelModal(response: any) {
    // let formTypes = response.FormsetTypes.filter((x: any) => x.FormSetTypeCode == 'EN' && !x.IsDefault,);
    // if (formTypes) {
    //   sessionStorage.setItem('coiFormSetId', formTypes[0].FormSetId);
    // }
    this.dataService.fetchCancelReasonList().subscribe((resp) => {
      if (resp != undefined && resp.length > 0) {
        this.cancellist = resp;
      }
    });
    if (this.userRoleDetails?.IsUW !== true) {
      this.cancellist = this.cancellist.filter((data) => data.resonCode !== 'PRC');
    }

    // //Check for Pro Rata Active or Deactive
    // let IsProRataActive = EndPoint.IsProRataEnabled;
    // if (!IsProRataActive) {
    //   this.cancellist = this.cancellist.filter((data) => data.IsInactive == 0 && data.resonCode !== 'PRC',);
    //   this.cancellist = this.cancellist.filter((data) => data.IsInactive == 0 && data.resonCode !== 'FRC',);
    // }
    // if (!Utility.isTrue(UserRoleDetails.IsUW)) {
    //   this.cancellist = this.cancellist.filter((data) => data.IsInactive == 0 && data.resonCode !== 'PRC',);
    //   this.cancellist = this.cancellist.filter((data) => data.IsInactive == 0 && data.resonCode !== 'FRC',);
    // }
    this.eventEmitterService.onClick();
    this.modelRef = this.modelService.open(this.dialogBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  openManageRefundsModel() {
    this.modelManangeRefundsRef = this.modelService.open(
      this.dialogManageRefundsBox,
      {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      },
    );
    setTimeout(() => {
      this.isDisplayPolicyCancelMessage = false;
    }, 5000);
  }

  openChildModel() {
    this.modelChildRef = this.modelService.open(this.dialogconfirmBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      //,windowClass:"style='margin-left: 241px !important;'"
    });
  }

  closeCashRefundModel() {
    this.modelcancelCashPaymentRefundRef.close();
    this.loaderService.hide();
    this.isRefundAllClicked = false;
    this.isRefundClicked = false;
    this.refundIndex = -1;
    this.isRefundProcessInitated = false;
  }

  closeManageRefundModel() {
    this.modelManangeRefundsRef.close();
    location.href =
      location.protocol + '//' + location.host + '/policy-detail';
  }

  closeModal() {
    this.IsRefundValueShow = false;
    this.cancelFormError = false;
    this.modelRef.close();
  }

  closeChildModal(isNoSelected = false) {
    this.modelChildRef.close();
    // open submodel when close childmodel
    if (isNoSelected) {
      this.openModel();
    }
  }

  closeAlertChildModal() {
    if (this.cancelMessageSuccess) {
      this.modelAlertRef.close();
      this.openManageRefundsModel();
    } else this.modelAlertRef.close();
  }

  cancelconfirmation() {
    if (this.cancelForm.valid) {
      this.cancelFormError = false;
      // this.closeModal();
      // this.openNotes();
      this.openChildModel();
      this.closeModal();
      return;
    } else {
      this.cancelFormError = true;
    }
  }

  cancelPolicySave() {
    this.loaderService.show();
    this.cancelMessageSuccess = '';
    this.cancelMessageFail = '';
    //  this.isConfirmLoader = true;
    let reqObj: any = {
      policyGuid: this.currentPolicyInStore.policyGuid,
      reasonCode: this.cancelForm.value.CancelReason,
      // loggedInUser: this.userProfile?.LoggedUser,
    }
    if (this.cancelForm.value.CancelReason === 'PRC') {
      reqObj.percentage = this.PartialRefundPercentage;
    }
    let agentDetailsObj =
      this.setQueryParamsService.getAgentDetailsForAPIRequest();
    this.dataService.policyCancel(reqObj).subscribe((resp) => {
      if (resp != undefined && resp.isSuccessful) {
        let postData: any = {
          policyGuid: this.currentPolicyInStore.policyGuid,
          movementGuid: this.movementGuid,
          // loggedInUser: this.userProfile?.LoggedUser,
        };
        this.postdatarequest.postData = postData;
        this.dataService.paymentSummaryRefunds(postData).subscribe(
          (response) => {
            if (response != undefined && response.length > 0) {
              this.paymentRefundsData = response;
              this.openManageRefundsModel();
              this.closeChildModal();
              this.getDisabledStatusForSubmit();

              this.loaderService.hide();
            } else {
              this.loaderService.hide();
              this.cancelMessageFail = JSON.stringify(
                response.ResponseStatus.Message.split('Stack Trace:')[0].replace(
                  'Exception',
                ),
              );
              this.openModel();
            }
          },
          (error) => {
            this.loaderService.hide();
            if (
              error &&
              error.error &&
              error.error.ResponseStatus &&
              error.error.ResponseStatus.Message
            ) {
              this.cancelMessageFail = JSON.stringify(
                error.error.ResponseStatus.Message.split('Stack Trace:')[0],
              );
            }
          },
        );
      }
      else {
        this.loaderService.hide();
        this.cancelMessageFail = "Something went wrong please try again.";
      }
    },
      (error) => {
        this.loaderService.hide();
        if (
          error &&
          error.error &&
          error.error.ResponseStatus &&
          error.error.ResponseStatus.Message
        ) {
          this.cancelMessageFail = JSON.stringify(
            error.error.ResponseStatus.Message.split('Stack Trace:')[0],
          );
        }
      });


    this.isConfirmLoader = false;
  }
  OpenCashRefund() {
    this.openCashRefundModel();
  }

  cashItemRefund() {
    this.paymentRefundsData.for
    if (this.isRefundAllClicked == true) {
      let reqObj: any = {
        policyGuid: this.currentPolicyInStore.policyGuid,
        transactionRefNo: "",
        refundEntireAmount: true,
        reasonCode: this.cancelForm.value.CancelReason,
        cashRefundRefNumber: this.cancelCashPaymentForm.value.ReceiptNumber
      }
      this.issueFullRefund(reqObj, this.cancelCashPaymentForm.value.ReceiptNumber);
    }
    else {
      this.selectedTransaction.cashRefundRefNumber =
        this.cancelCashPaymentForm.value.ReceiptNumber; //this.cancelCashPaymentForm.controls["ReceiptNumber"].value();
      let reqObj: any = {
        policyGuid: this.currentPolicyInStore.policyGuid,
        transactionRefNo: this.selectedTransaction.paymentRefNumber,
        amount: this.selectedTransaction.amount,
        refundEntireAmount: false,
        reasonCode: this.cancelForm.value.CancelReason,
        cashRefundRefNumber: this.selectedTransaction.cashRefundRefNumber
      }
      this.issuePaymentRefund(this.selectedTransaction, reqObj);
    }// this.selectedTransaction.brainTreeRefundStatus = true;
    // if (
    //   this.finalCanecellation.filter(
    //     (f: any) =>
    //       f.paymentRefNumber ===
    //       this.selectedTransaction.paymentRefNumber,
    //   ).length == 0
    // ) {
    //   this.finalCanecellation.push(this.selectedTransaction);
    // }
    this.getDisabledStatusForSubmit();
    this.modelcancelCashPaymentRefundRef.close();
  }

  getDisabledStatusOld(index: any) {
    if (!this.finalCanecellation) {
      return false;
    }

    return !this.finalCanecellation[index] ? false : true;
  }

  getDisabledStatus(item: any, index: any) {
    if (
      this.finalCanecellation != undefined &&
      this.finalCanecellation.length > 0
    ) {
      if (
        this.finalCanecellation.filter(
          (f: any) =>
            f.paymentRefNumber === item.paymentRefNumber &&
            f.ItemIndex === index,
        ).length != 0
      ) {
        return true;
      }
    }

    return false;
  }

  getDisabledStatusForSubmit() {
    if (
      this.finalCanecellation &&
      this.finalCanecellation.length ==
      this.paymentRefundsData.length
    ) {
      this.cancelButton = false;
    } else {
      this.cancelButton = true;
      return true;
    }
  }

  setRefundText(item: any, index: any) {
    if (
      this.finalCanecellation != undefined &&
      this.finalCanecellation.length > 0
    ) {
      if (this.finalCanecellation.filter((f: { brainTreeRefundStatus: boolean; }) => f.brainTreeRefundStatus === true).length == this.paymentRefundsData.length) {
        this.refundAllText = 'Close';
        this.isRefundAllDone = true;
        this.isIssuedButtonDisabled = true;
        this.CancelAllPolicyMessage = "Policy has been refunded successfully, Please close this window to proceed.";
        return 'Issued';
      }
      if (
        this.finalCanecellation.filter(
          (f: any) =>
            f.paymentRefNumber === item.paymentRefNumber,
        ).length != 0
      ) {
        let filterItem = this.finalCanecellation.filter(
          (f: any) =>
            f.paymentRefNumber === item.paymentRefNumber,
        );
        if (filterItem != undefined) {
          if (
            filterItem[0].brainTreeRefundStatus &&
            filterItem[0].ItemIndex == index
          ) {
            return 'Issued';
          } else if (!filterItem[0].brainTreeRefundStatus) {
            return 'Refund Failed';
          }
        } else {
          return 'Initiate Refund';
        }
      } else {
        return 'Initiate Refund';
      }
    } else {
      return 'Initiate Refund';
    }
  }

  callPaymentFailed(item?: any) {
    let filteredData = item != undefined
      ? this.paymentRefundsData.filter((obj: any) => obj.paymentRefNumber == item.paymentRefNumber)
      : this.paymentRefundsData
    for (let itemPayment of filteredData) {
      if (
        this.finalCanecellation != undefined &&
        this.finalCanecellation.length > 0
      ) {
        if (
          this.finalCanecellation.filter(
            (f: any) =>
              f.paymentRefNumber ===
              itemPayment.paymentRefNumber,
          ).length == 0
        ) {
          let failedItem = itemPayment;
          failedItem.brainTreeRefundStatus = false;
          this.finalCanecellation.push(failedItem);
        }
      } else {
        let failedItem = itemPayment;
        failedItem.brainTreeRefundStatus = false;
        this.finalCanecellation.push(failedItem);
      }
    }
  }

  confirmRefundPay(item: any, index: any): void {
    item.ItemIndex = index;
    this.selectedTransaction = item;
    this.cancelCashRefundMessageEror = '';
    this.cashReciptError = '';
    this.CancelAllPolicyMessage = '';
    if (!this.isRefundProcessInitated) {
      this.isRefundClicked = true;
      this.refundIndex = index;
      this.isRefundProcessInitated = true;
      if (item.method == 'Cash') {
        this.openCashRefundModel();
      }
      else {
        let reqObj: any = {
          policyGuid: this.currentPolicyInStore.policyGuid,
          transactionRefNo: item.paymentRefNumber,
          amount: item.amount,
          refundEntireAmount: false,
          reasonCode: this.cancelForm.value.CancelReason,
          cashRefundRefNumber: ""
        }
        this.issuePaymentRefund(item, reqObj);
      }
    }
    // if (item.methodType == 'CC') {
    //   let postFullRefundData = {};
    //   if (this.PartialRefundPercentage != undefined) {
    //     let currRefundAmount = 0;
    //     let refundData = this.paymentRefundsData;
    //     let refundObj =
    //       refundData != null && refundData.length > 0
    //         ? refundData.filter(
    //           (x: any) =>
    //             x.PaymentConfirmationNumber == item.PaymentConfirmationNumber,
    //         )[0]
    //         : null;

    //     postFullRefundData = {
    //       transaction_id: item.PaymentConfirmationNumber,
    //       chargeAmount: refundObj != null ? refundObj.PaymentAmount : 0,
    //     };
    //   } else {
    //     postFullRefundData = {
    //       transaction_id: item.PaymentConfirmationNumber,
    //     };
    //   }
    // this.dataService.fullRefund(postFullRefundData).subscribe(
    //   (result: any) => {
    //     if (result != 'Refunded') {
    //       this.cancelCashRefundMessageEror =
    //         'There was an error processing your refund. Please contact Tokio Marine support';
    //       this.callPaymentFailed();
    //       //this.getDisabledStatusForSubmit();
    //     } else {
    //       item.brainTreeRefundStatus = true;
    //       item.ItemIndex = index;
    //       this.finalCanecellation.push(item);
    //       this.getDisabledStatusForSubmit();
    //     }

    //     this.loaderService.hide();
    //     this.dataService
    //       .createActivityLogItem('BraintreeAPILOG', result)
    //       .subscribe((response) => { });
    //   },
    //   (err) => {
    //     this.loaderService.hide();
    //     this.logService.createErrorLog(
    //       postFullRefundData,
    //       err,
    //       'FullRefund',
    //       PageNameEnum.PolicyDetails +
    //       ' ' +
    //       SubComponentNameEnum.Cancellation,
    //       LogLevel.Error,
    //     );
    //     this.cancelCashRefundMessageEror =
    //       'There was an error processing your refund. Please contact Tokio Marine support';
    //     this.callPaymentFailed();
    //     //this.getDisabledStatusForSubmit();
    //     this.dataService
    //       .createActivityLogItem('BraintreeAPILOG', err)
    //       .subscribe((response) => { });
    //   },
    // );
    // } else if (item.methodType === 'PC') {
    //   this.selectedTransaction.ItemIndex = index;
    //   this.cancelCashRefundMessageEror = '';
    //   this.openCashRefundModel();
    //   this.loaderService.hide();
    //   return;
    // }
  }
  issuePaymentRefund(item: any, req: any) {
    this.dataService.paymentIssueRefund(req).subscribe(
      (response) => {
        if (response != undefined && response.isSuccessful) {
          item.brainTreeRefundStatus = true;
          this.finalCanecellation.push(item);
          if (this.finalCanecellation.length != this.paymentRefundsData.length) {
            this.isRefundProcessInitated = false;
          }
        }
        else if (response != undefined && !response.isSuccessful
          && response.errors.length > 0) {
          this.cashReciptError = response.errors[0];
          this.isRefundProcessInitated = false;
          this.isRefundAllClicked = false;
        }
        this.isRefundClicked = false;
        this.refundIndex = -1;
      },
      (err) => {
        this.isRefundClicked = false;
        this.refundIndex = -1;
        if (this.finalCanecellation.length != this.paymentRefundsData.length) {
          this.isRefundProcessInitated = false;
        }
        this.cancelCashRefundMessageEror =
          'There was an error processing your refund. Please contact Tokio Marine support';
        this.callPaymentFailed(item);
      },
    );
  }
  refundAllConfirmation() {
    if (this.isRefundAllDone) {
      this.closeManageRefundModel();
    } else {
      this.openRefundConfirmModel();
    }
  }

  openRefundConfirmModel() {
    this.RefundConfirmRef = this.modelService.open(this.dialogRefundConfirmBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
      //,windowClass:"style='margin-left: 241px !important;'"
    });
  }
  closeRefundConfirmModel() {
    this.RefundConfirmRef.close();
  }
  confirmFullRefund() {
    this.closeRefundConfirmModel();
    this.isRefundAllClicked = true;
    this.isRefundClicked = true;
    this.CancelAllPolicyMessage = '';
    this.cashReciptError = '';
    this.cancelCashRefundMessageEror = '';
    if (this.paymentRefundsData.filter(
      (f: any) =>
        f.method === 'Cash',
    ).length > 0) {
      this.openCashRefundModel();
    }
    else {
      let reqObj: any = {
        policyGuid: this.currentPolicyInStore.policyGuid,
        transactionRefNo: "",
        refundEntireAmount: true,
        reasonCode: this.cancelForm.value.CancelReason,
        cashRefundRefNumber: ""
      }
      this.issueFullRefund(reqObj, '');
    }
  }
  issueFullRefund(req: any, caseRefNo?: any) {
    this.dataService.paymentIssueRefund(req).subscribe(
      (response) => {
        if (response != undefined && response.isSuccessful) {
          this.refundAllText = 'Close';
          this.isRefundAllDone = true;
          this.cancelButton = false;
          this.finalCanecellation = this.paymentRefundsData;
          this.CancelAllPolicyMessage = "Policy has been refunded successfully, Please close this window to proceed.";
          this.finalCanecellation.forEach((item: any) => {
            item.brainTreeRefundStatus = true;
            if (item.paymentType === 'Cash') {
              item.cashRefundRefNumber = caseRefNo;
            }
          });

        } else if (response != undefined && !response.isSuccessful
          && response.errors.length > 0) {
          this.cashReciptError = response.errors[0];
        }
        else {
          this.cancelCashRefundMessageEror =
            'There was an error processing your refund. Please contact Tokio Marine support';
          this.refundAllText = 'Refund Fail'
          this.callPaymentFailed();
        }
        this.loaderService.hide();
        this.isRefundAllClicked = false;
        this.isRefundClicked = false;
      },
      (err) => {
        this.loaderService.hide();
        this.isRefundAllClicked = false;
        this.isRefundClicked = false;
        this.cancelCashRefundMessageEror =
          'There was an error processing your refund. Please contact Tokio Marine support';
        this.refundAllText = 'Refund Fail'
        this.callPaymentFailed();
      },
    );
  }
  changeCancelReason(value: any) {
    if (this.cancelForm.controls['CancelReason'].valid) {
      this.cancelFormError = false;
    }
    this.selectedInputState = this.cancelForm.controls['CancelReason'].value;
    if (
      value.target.options[
        value.target.options.selectedIndex
      ].innerText.toUpperCase().trim() == 'PARTIAL CANCELLATION'
    ) {
      this.OriginalrefundData = this.refundData;
      this.IsRefundValueShow = false;
      this.ShowPercentageDiv = true;
      if (this.refundData != undefined) {
        sessionStorage.setItem('pcRefundAmt', this.refundData.pcRefundAmt);
        sessionStorage.setItem('ccRefundAmt', this.refundData.ccRefundAmt);
      }
      this.openPercentageBox();
    } else if (
      value.target.options[
        value.target.options.selectedIndex
      ].innerText.toUpperCase()?.trim() == 'FULL CANCELLATION'
    ) {
      this.IsRefundValueShow = true;
      this.ShowPercentageDiv = false;
      this.PartialRefundPercentage = undefined;
      this.PartialRefundOnlyToCC = false;
      this.PercentageEntered = true;
      if (this.OriginalrefundData) {
        this.refundData = {
          ccRefundAmt: parseFloat(sessionStorage.getItem('ccRefundAmt') || ''),
          pcRefundAmt: parseFloat(sessionStorage.getItem('pcRefundAmt') || ''),
        };
      }
    } else {
      this.IsRefundValueShow = false;
      this.ShowPercentageDiv = false;
      this.PartialRefundPercentage = undefined;
      this.PartialRefundOnlyToCC = false;
      if (this.OriginalrefundData) {
        this.refundData = {
          ccRefundAmt: parseFloat(sessionStorage.getItem('ccRefundAmt') || ''),
          pcRefundAmt: parseFloat(sessionStorage.getItem('pcRefundAmt') || ''),
        };
      }
    }
  }

  formatprice(value: any) {
    let formattedvalue = this.priceService.getPriceFixed(value, 2);
    return formattedvalue;
  }

  openNotes() {
    this.submitted = false;
    this.modelAlertRef = this.modelService.open(this.noteBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }
  openPercentageBox() {
    this.submitted = false;
    this.PartialRefundPercentage;
    this.PercentageBoxRef = this.modelService.open(this.PercentageBox, {
      size: 'sm',
      backdrop: 'static',
      keyboard: false,
    });
  }
  // onlyNumberWithDecimal(el, evnt) {
  //   var charC = (evnt.which) ? evnt.which : evnt.keyCode;
  //   if (charC == 46) {
  //     if (el.value.indexOf('.') === -1) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     if (charC > 31 && (charC < 48 || charC > 57))
  //       return false;
  //   }
  //   return true;

  // }
  // limitTo5(e) {
  //   const limit = 5;
  //   if (parseFloat(e.target.value) <= parseFloat("99.99")) {
  //     if (e.target.value.length > limit) {
  //       let fieldId = e.target.id;
  //       let fieldValue = e.target.value;
  //       let inputChar = String.fromCharCode(e.keyCode);
  //       if (fieldValue.includes(inputChar)) {
  //         e.target.value = fieldValue.substring(0, fieldValue.length - 1);
  //       }
  //       return false;
  //     };
  //   }
  //   else {
  //     let fieldValue = e.target.value;
  //     let inputChar = String.fromCharCode(e.keyCode);
  //     if (fieldValue.includes(inputChar)) {
  //       e.target.value = fieldValue.substring(0, fieldValue.length - 1);
  //     }
  //     return false;
  //   }
  // }
  onFocusOutEvent(e: any) {
    if(e.target.value != ""){
      var result = this.regexforPercentCheck(e.target.value);
      if (!result) this.PercentageError = true;
    }
    else{
      this.PercentageError = false;
    }
  }
  regexforPercentCheck(value: any) {
    if (value <= 0) return false;
    let allow2no = new RegExp('^[0-9]*(\\.[0-9]{0,2})?$');
    if (allow2no.test(value.toString())) {
      let re = new RegExp('^(?=.*\\d)\\d{0,2}(?:\\.\\d{0,2})?$');
      return re.test(value.toString());
    } else {
      return false;
    }
  }
  ClearError() {
    this.PercentageError = false;
  }

  CheckPercentageError() {
    if (this.PercentageError){
      return false;
    }
    else{
      this.PercentageEntered = false;
      this.ShowPercentageDiv = false;
    }
  }
  PercentageSubmitted() {
    if (this.OriginalrefundData) {
      //let Percentage: any = parseFloat(document.getElementById('Percentage'));
      var Percentage = parseFloat(
        (<HTMLInputElement>document.getElementById('Percentage')).value,
      );
      this.PartialRefundPercentage = Percentage;
      var total =
        this.OriginalrefundData.ccRefundAmt +
        this.OriginalrefundData.pcRefundAmt;
      var refundFromTotal = (total * Percentage) / 100;
      if (this.OriginalrefundData.ccRefundAmt >= refundFromTotal) {
        this.OriginalrefundData.ccRefundAmt = refundFromTotal;
        if (this.OriginalrefundData.pcRefundAmt > 0) {
          this.PartialRefundOnlyToCC = true;
          this.OriginalrefundData.pcRefundAmt = 0;
        } else {
          this.PartialRefundOnlyToCC = false;
        }
      } else {
        refundFromTotal = refundFromTotal - this.OriginalrefundData.ccRefundAmt;
        this.OriginalrefundData.pcRefundAmt = refundFromTotal;
        this.PartialRefundOnlyToCC = false;
      }
    }
    this.IsRefundValueShow = true;
    this.PercentageEntered = true;
    this.PercentageBoxRef.close();
  }
  ClosePercentageForm() {
    this.PercentageError = false;
    this.selectedInputState = '';
    this.PercentageBoxRef.close();
    this.PartialRefundPercentage = undefined;
    return false;
  }
  closeNotes() {
    this.modelService.dismissAll();
  }

  DateConvertIntoMs(dateTime: any) {
    if (dateTime) {
      let formateDate =
        dateTime.split('T')[0] + ' ' + dateTime.split('T')[1].split('.')[0];
      return new Date(formateDate).getTime();
    }
  }

  isNoteAdded() {
    let notes = [];
    let activity = JSON.parse(JSON.stringify(this.Activity));
    let policyMovement = JSON.parse(JSON.stringify(this.Movement));

    // check for all notes
    for (let val of activity) {
      if (val.ActivityType === 'GeneralNotes') {
        notes.push(val);
      }
    }
    // check for in process policy
    let movement = policyMovement.filter((inpro: any) => {
      return inpro.Status === 'Completed' || inpro.Status === 'In Process';
    });

    // compare time of in process policy and notes
    let lastCompletedEndrsmt;
    let lastAddedNode;
    if (movement.length > 0) {
      lastCompletedEndrsmt = movement[movement.length - 1];
    }
    if (notes.length > 0) {
      lastAddedNode = notes[notes.length - 1];
    }
    if (lastCompletedEndrsmt && lastAddedNode) {
      let dateForComparison = new Date(lastAddedNode.ActivityDateTime);
      lastCompletedEndrsmt.TxnIssued = this.DateConvertIntoMs(
        lastCompletedEndrsmt.TxnIssued,
      );
      lastAddedNode.ActivityDateTime = this.DateConvertIntoMs(
        lastAddedNode.ActivityDateTime,
      );
      if (lastCompletedEndrsmt.TxnIssued > lastAddedNode.ActivityDateTime) {
        return false;
      } else {
        return this.compareDates(dateForComparison);
      }
    }
    return false;
  }

  compareDates(a: any) {
    let today = new Date();
    let inputDate = new Date(a);
    let todayDateOnly = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
    );
    let dDateOnly = new Date(
      inputDate.getFullYear(),
      inputDate.getMonth(),
      inputDate.getDate(),
    );
    if (todayDateOnly <= dDateOnly) {
      return true;
    } else {
      return false;
    }
  }

  resetReasons() {
    let ddlReason: any = document.getElementById('inputState');
    ddlReason.value = 0;
  }
  get f() {
    return this.noteForm.controls;
  }

  resetForm() {
    this.closeNotes();
    this.submitted = false;
    this.noteForm.reset();
  }
  noteSubmitted() {
    this.submitted = true;
    if (this.noteForm.invalid) {
      return;
    }
    this.loaderService.show();
    let todayISOString = new Date().toISOString();
    let data = {
      policyGuid: this.currentPolicyInStore.policyGuid.toString(), //parseInt(this.policytxnid),
      movementGuid: this.currentPolicyInStore.movementGuid.toString(),
      description: this.noteForm.controls['Description'].value,
      loggedInUser: this.userProfile?.LoggedUser,
    };
    this.dataService.notes(data).subscribe(
      (response) => {
        if (response.isSuccessful) {
          this.loaderService.hide();
          this.resetForm();
          // this.cancelPolicySave();
          // this.closeAlertChildModal();
          this.openModel();
        } else {
          this.loaderService.hide();
        }
      },
      (err) => {
        this.loaderService.hide();
      },
    );
  }

  requiredWithoutBlank(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      if (control.value.trim().length > 0) {
        return null;
      } else {
        return { required: true };
      }
    }
  }

  resendCoiCustEmail() {
    let coiFormSetId = '';
    let retrievedData = JSON.parse(sessionStorage.getItem('formsetids') || '');
    let formsetid = retrievedData.find(
      (data: any) => data.FormSetTypeCode == 'CN',
    );
    coiFormSetId = formsetid.FormSetId;
    // let reqObj = {
    //   PolicyTxnId: this.CancellationTxnId,
    //   FormsetId: coiFormSetId,
    //   SubCompanyCode: 'GTA',
    //   NoticeTextCollection: [],
    //   CreateActivityLogItem: {
    //     PolicyTxnId: this.CancellationTxnId,
    //     ActivityType: 'NewGenEmail',
    //     ActivityDate: new Date(),
    //     Description: 'COI Sent',
    //     // LoggedInUser: this.userProfile?.LoggedUser,
    //   },
    // };
    /*    this.dataService.resendCoi(reqObj).subscribe(
          (data) => {
            //console.log('success');
          },
          (err) => {
            //console.log('hide');
          }
        );*/
  }
}
