import { createReducer, on, Action  } from "@ngrx/store";
import { Agents, Branches, Roles } from "./user.model";
import { setAgents, setBranches, setRoles, updateAgents, updateBranches, updateRoles } from "./user.actions";

export interface BranchesState {
    branches  : Branches | null;
}

export interface AgentsState {
    agents  : Agents | null ;
}

export interface RolesState {
    roles  : Roles | null;
}

export const initialBranchState : BranchesState  = {
    branches : null
};

export const initialAgentState : AgentsState  = {
    agents : null
};

export const initialRolesState : RolesState  = {
    roles : null
};

const _branchesReducer = createReducer(
    initialBranchState,
    on(setBranches, (state, {branches}) =>({
        ...state,
        branches
    })),
    on(updateBranches, (state, { updateBranches }) => ({
        ...state,
        branches : { ...state.branches, ...updateBranches } as Branches
    }))
);

const _agentsReducer = createReducer(
    initialAgentState,
    on(setAgents, (state, {agents}) =>({
        ...state,
        agents
    })),
    on(updateAgents, (state, { updateAgents }) => ({
        ...state,
        agents : { ...state.agents, ...updateAgents } as Agents
    }))
);

const _rolesReducer = createReducer(
    initialRolesState,
    on(setRoles, (state, {roles}) =>({
        ...state,
        roles
    })),
    on(updateRoles, (state, { updateRoles }) => ({
        ...state,
        roles : { ...state.roles, ...updateRoles } as Roles
    }))
);

export function branchesReducer(state: BranchesState | undefined, action: Action) {
    return _branchesReducer(state, action);
}

export function agentsReducer(state: AgentsState | undefined, action: Action) {
    return _agentsReducer(state, action);
}

export function rolesReducer(state: RolesState | undefined, action: Action) {
    return _rolesReducer(state, action);
}