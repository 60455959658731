/// <reference path="manage.service.ts" />
import { Component, OnInit } from '@angular/core';
import { ManageService } from './manage.service';
import { LogService } from '../../../../../projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from '../../../../../projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum } from '../../../../../projects/policyDetails/src/app/enum/page-name-enum';

@Component({
  selector: 'dashboard-manage',
  templateUrl: './manage.component.html',
  styleUrls: ['./manage.component.css'],
})
export class ManageComponent implements OnInit {
  sitecoreData: any = [];
  manage: any = [];
  manageloading: boolean = false;

  constructor(
    private manageService: ManageService,
    private logService: LogService,
  ) {}

  ngOnInit() {
    this.getSitecoreManageData();
  }

  getSitecoreManageData(): void {
    this.manageService.getSitecoreManageList().subscribe(
      (resp) => {
        this.manage = resp;
      },
      (err) => {
        this.logService.createErrorLog(
          {},
          err,
          'GetDataDashboardSubMenu',
          PageNameEnum.Dashboard,
          LogLevel.Error,
        );
      },
    );
  }
}
