import { NgFor, NgIf } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageUsersService } from '../manageusers.service';
import { LoaderModule } from 'src/app/core/loader/loader.module';
import { FormErrorComponent } from 'projects/manage-branches/src/app/form-error/form-error.component';
import { DataSetterService } from 'src/app/core/services/data-setter.service';
import { AgentRole, ClubAgent, ClubAgentRequest } from '../app.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators, } from '@angular/forms';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails, UserProfile } from 'src/app/core/store/loginUser/loginUser.model';
import { selectBranches, selectRoles } from 'src/app/core/store/user/user.selectors';
import { setBranches, setRoles } from 'src/app/core/store/user/user.actions';
import { BranchesService } from 'src/app/shared/branches.service';

@Component({
  selector: 'app-add-agent-modal',
  standalone: true,
  imports: [NgIf, NgFor, ReactiveFormsModule, LoaderModule, FormErrorComponent],
  templateUrl: './add-agent-modal.component.html',
  styleUrl: './add-agent-modal.component.css',
})
export class AddAgentModalComponent implements OnInit, OnDestroy {
  @ViewChild('contentmodalAddUser') contentmodalAddUser!: TemplateRef<any>;
  @Input() addAgentModalEvent!: EventEmitter<ClubAgent>;
  @Input() listClubs: any = [];
  @Output() onAgentSuccess: EventEmitter<string> = new EventEmitter<string>();
  @Output() onAgentFailed: EventEmitter<string> = new EventEmitter<string>();

  addUserForm: FormGroup;
  mainArrayFrom: any = [];
  listBranches: any = [];
  submitted = false;
  isNewAgent: boolean = false;
  data?: ClubAgent;
  listRoles: AgentRole[] = [];
  isSaveUser: boolean = false;
  selectedClub?: string;
  userRoleDetails?:UserRoleDetails;
  userProfile?: UserProfile;

  constructor(
    private modalService: NgbModal,
    private manageUsersService: ManageUsersService,
    private dataSetterService: DataSetterService,
    private store: Store,
    private branchDetailService: BranchesService
  ) {
    this.addUserForm = new FormGroup({
      selectedClubId: new FormControl(null, Validators.required),
      selectedBranchId: new FormControl(null, Validators.required),
      selectedRoleId: new FormControl(null, Validators.required),
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
    });
    this.isNewAgent = true;
  }

  ngOnInit(): void {
    this.store.select(selectBranches).subscribe(branches => {
      this.listBranches = branches?.response;
    });

    this.store.select(selectRoles).subscribe(roles => {
      this.listRoles = roles == null ? [] : roles.responseMessage;
    });

    this.store.select(loginUser).subscribe(loginUser => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.userProfile = loginUser.userProfile;
      }
    });

    if (this.addAgentModalEvent) {
      this.addAgentModalEvent.subscribe(async (data: ClubAgent) => {
        this.data = data;
        this.addUserForm.reset();
        this.isNewAgent = this.data.agentGuid == null || this.data.agentGuid.length == 0;
        if (!this.isNewAgent) {
          this.addUserForm.get('email')?.setValue(data.email);
          this.addUserForm.get('firstName')?.setValue(data.firstName);
          this.addUserForm.get('lastName')?.setValue(data.lastName);

          this.addUserForm.get('selectedClubId')?.setValue(data.autoClubGuid);
          this.selectedClub = data.autoClubGuid;
          this.addUserForm.get('selectedClubId')?.disable();
          await this.getBranches(data.autoClubGuid, data.branchGuid);
          await this.getAgentRoles(data.autoClubGuid, data.roleId);
        }
        else {
          this.addUserForm.get('selectedClubId')?.setValue('');
          this.addUserForm.get('selectedBranchId')?.setValue('');
          this.addUserForm.get('selectedRoleId')?.setValue('');
          this.addUserForm.get('selectedClubId')?.enable();
          
        }
        
        let autoClubs = this.dataSetterService.setAutoClubs(this.userRoleDetails?.AutoClubURLList, true);
        this.listClubs = autoClubs;
        if (this.listClubs.length == 1) {
          this.addUserForm.get('selectedClubId')?.setValue(this.listClubs[0].autoClubGuid);
          if(this.isNewAgent){
            await this.getBranches(this.listClubs[0].autoClubGuid);
            await this.getAgentRoles(this.listClubs[0].autoClubGuid);
          }
        }
        
        this.open(this.contentmodalAddUser);
      });
    }
  }

  ngOnDestroy(): void {
    this.addAgentModalEvent.unsubscribe();
    this.listBranches = [];
    this.selectedClub = '';
  }

  async getBranches(autoClubGuid?: string, selectedBranch?: string) {
    let a = this.listClubs;
    const club = this.listClubs.find((club: any) => club.autoClubGuid === autoClubGuid);
    await this.manageUsersService.getBranches(club.autoClubGuid, this.userRoleDetails?.AgentContactId).toPromise()
      .then(async (resp) => {
        if (resp) {
          this.listBranches = resp.response;
          if (selectedBranch) {
            this.addUserForm.get('selectedBranchId')?.setValue(selectedBranch);
          }
          else {
            this.addUserForm.get('selectedBranchId')?.setValue("");
          }
        }
      }, (err) => {
        this.listBranches = [];
      })
      .catch((err) => {
        this.listBranches = [];
      });
  }

  async getAgentRoles(autoClubGuid: string, selectedRoleId?: number) {
    const club = this.listClubs.find((club: any) => club.autoClubGuid === autoClubGuid);
    await this.manageUsersService.getAgentRoles(club.key).toPromise()
      .then(async (resp) => {
        if (resp) {
          this.listRoles = (resp != null) ? resp.responseMessage : [];;
          if (selectedRoleId) {
            this.addUserForm.get('selectedRoleId')?.setValue(selectedRoleId.toString());
          }
          else {
            this.addUserForm.get('selectedRoleId')?.setValue("");
          }
          this.store.dispatch(setRoles({ roles: resp }));
        }
      }, (err) => {
        this.listRoles = [];
      })
      .catch((err) => {
        this.listRoles = [];
      });
  }

  open(content: TemplateRef<any>) {
    // this.listRoles = [];
    this.submitted = false;
    this.modalService.open(content, { size: 'lg', centered: true, backdrop: 'static', }).result.then( (result) => { }, (reason) => { }, );
  }

  onAgentSubmit() {
    this.isSaveUser = true;
    this.submitted = true;
    const validateFields = [
      'selectedClubId',
      'selectedBranchId',
      'selectedRoleId',
      'email',
      'firstName',
      'lastName',
    ];

    this.markRequiredControlsAsTouched(this.addUserForm, validateFields);
    if (!this.validateSpecificFields(this.addUserForm, validateFields)) {
      this.isSaveUser = false;
      return;
    }
    const selectedClubIdControl = this.addUserForm.get('selectedClubId');
    if (selectedClubIdControl?.disabled) {
      selectedClubIdControl.enable();
    }
    const data: ClubAgentRequest = {
      autoClubGuid: this.addUserForm.value.selectedClubId,
      branchGuid: this.addUserForm.value.selectedBranchId,
      roleId: Number(this.addUserForm.value.selectedRoleId),
      firstName: this.addUserForm.value.firstName,
      lastName: this.addUserForm.value.lastName,
      email: this.addUserForm.value.email,
      modifiedBy: this.userProfile ? this.userProfile.LoggedUser.toUpperCase() : '',
    };
    if (selectedClubIdControl?.disabled) {
      selectedClubIdControl.disable();
    }
    if (!this.isNewAgent) {
      data.agentGuid = this.data?.agentGuid;
    }
    if (this.listClubs.length == 1) {
      data.autoClubGuid = this.listClubs[0].autoClubGuid;
    }

    this.manageUsersService.createAgent(data, this.userProfile?.Token).subscribe(
      (res) => {
        this.modalService.dismissAll();
        this.addUserForm.reset();
        if (res.status) {
          this.onAgentSuccess.emit(res.message);
        } else {
          this.onAgentFailed.emit(res.message);
        }
        this.submitted = false;
        this.isSaveUser = false;
      },
      (error) => {
        this.modalService.dismissAll();
        this.addUserForm.reset();
        this.onAgentFailed.emit(error.error.Message);
        this.isSaveUser = false;
      },
    );
  }

  markRequiredControlsAsTouched(formGroup: FormGroup, fields: string[]): void {
    fields.forEach((field) => {
      const control = this.getNestedControl(formGroup, field);
      if (control) {
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
      } else {
        // console.error(`Could not find control for field: ${field}`, control);
      }
    });
  }

  getNestedControl(formGroup: FormGroup, path: string): AbstractControl | null {
    let control: AbstractControl | null = formGroup;
    return control?.get(path);
  }

  validateSpecificFields(formGroup: FormGroup, fields: string[]): boolean {
    let isValid = true;
    fields.forEach((field) => {
      const control = this.getNestedControl(formGroup, field);
      if (control && control.invalid) {
        control.markAsTouched({ onlySelf: true });
        control.updateValueAndValidity();
        isValid = false;
      }
    });
    return isValid;
  }

  isInvalid(controlName: string): boolean {
    const control = this.getNestedControl(this.addUserForm, controlName);
    return control
      ? this.submitted && (control.invalid || control.value == 0) &&
      (control.dirty || control.touched)
      : false;
  }

  onAutoClubFormChange(e: Event) {
    const selectElement = e.target as HTMLSelectElement;
    const selectedId = selectElement.value;
    const club = this.listClubs.find((club: any) => club.autoClubGuid === selectedId);
    this.selectedClub = (club != null) ? club.autoClubGuid : "";
    this.addUserForm.get('selectedBranchId')?.setValue('');
    this.addUserForm.get('selectedRoleId')?.setValue('');
    if(selectedId > ""){
      this.getBranches(selectedId);
      setTimeout(() => {
        this.getAgentRoles(selectedId);  
      }, 500);
      
      
    }
    else{
      this.listBranches = [];
      this.listRoles = [];
    }
  }
}
