import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Config, ConfigColumns } from 'datatables.net';
import { DataTableDirective, DataTablesModule } from 'angular-datatables';
import { Subscription, of, tap, catchError } from 'rxjs';
import { LoaderService } from '../../../../../src/app/core/services/loader.service';
import { SharedService } from 'projects/manage-branches/src/app/shared.service';
import { ManageBranchesService } from 'projects/manage-branches/src/app/ManageBranchs.service';
import { ManageUsersService } from '../manageusers.service';
import { AgentSearchParams, ClubAgent } from '../app.model';
import { error } from 'console';
import { GridService } from 'src/app/shared/grid.service'
import { PageNameEnum } from '../../../../../projects/policyDetails/src/app/enum/page-name-enum';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { selectBranches } from 'src/app/core/store/user/user.selectors';

@Component({
  selector: 'app-agents-table',
  standalone: true,
  imports: [DataTablesModule],
  templateUrl: './agents-table.component.html',
  styleUrl: './agents-table.component.css',
})
export class AgentsTableComponent implements OnInit, OnDestroy {
  @ViewChild(DataTableDirective, { static: false })
  dtElement!: DataTableDirective;
  @Input() tableColumns: ConfigColumns[] = [];
  @Output() openEditModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() openDeactivateModal: EventEmitter<any> = new EventEmitter<any>();
  @Output() openReactivateModal: EventEmitter<any> = new EventEmitter<any>();

  selectedClub?: any;
  userRoleDetails?: UserRoleDetails
  dtOptions: Config = {};
  agentSearchChangedSubscription: Subscription;
  private searchParams?: AgentSearchParams;
  private listAgents: ClubAgent[] = [];

  constructor(
    private sharedService: SharedService,
    private manageUsersService: ManageUsersService,
    private loader: LoaderService,
    private gridService: GridService,
    private store: Store
  ) {
    this.agentSearchChangedSubscription = new Subscription();
  }

  ngOnInit(): void {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
      }
    });
    this.agentSearchChangedSubscription = this.sharedService.agentSearchParamsChanged$.subscribe((data) => {
      if(data != null && (Object.keys(data).length !== 0)){
        this.searchParams = data;
        setTimeout(() => {
          this.refreshTable(data);
        }, 50);
      }
    });

    var dtOptions = {
      lengthChange: false,
      pagingType: 'full_numbers',
      language: {
        paginate: {
          next: `<i class="fas fa-angle-right"></i>`,
          previous: '<i class="fas fa-angle-left"></i>',
          last: '<i class="fas fa-angle-double-right"></i>',
          first: '<i class="fas fa-angle-double-left"></i>',
        },
      },
      columns: this.tableColumns,
      initComplete: () => {
        this.bindClickEvents();
      },
    };

    var obsAgents = of({ itemsCount: 0, response: [] });
    this.dtOptions = this.gridService.buildDtOptionsWithPaging(dtOptions, obsAgents, {}, PageNameEnum.Dashboard);
  }

  getAgents(req: any) {
    return this.manageUsersService.getAgents(req).pipe(tap((res) => {
      this.listAgents = res.response;
      this.loader.hide();
    }),
      catchError(err => {
        this.loader.hide();
        return err;
      })
    );
  }

  bindClickEvents() {
    const $tableBody = $('#branch-table tbody');

    $tableBody.off('click', '.edit-btn');
    $tableBody.off('click', '.activate-btn');

    $tableBody.on('click', '.edit-btn', (event) => {
      const id = $(event.currentTarget).data('guid');
      this.onEditClick(id);
    });

    $tableBody.on('click', '.activate-btn', (event) => {
      const id = $(event.currentTarget).data('guid');
      const action = $(event.currentTarget).data('action');
      this.onDeactivateClick({ id, action });
    });
  }

  onEditClick(guid: string) {
    let data = this.listAgents.find(
      (agent: ClubAgent) => agent.agentGuid === guid,
    );
    this.openEditModal.emit(data);
  }

  onDeactivateClick(data: any) {
    this.openDeactivateModal.emit(data);
  }

  refreshTable(selectedValue?: any): void {
    this.searchParams = selectedValue;

    if (this.dtElement) {
      this.dtElement.dtInstance.then((dtInstance) => {
        this.loader.show();
        var req = this.searchParams;
        if (req) {
          req.recordType = "I";
          req.loginAgentGuid = this.userRoleDetails?.AgentContactId;
        }
        var obsAgents = this.getAgents(req);
        dtInstance.ajax.url(this.gridService.getAjaxMethod(obsAgents, req, PageNameEnum.ManageUsers)).load();
      });
    }
  }

  ngOnDestroy(): void {
    this.agentSearchChangedSubscription.unsubscribe();
  }
}
