<app-loader></app-loader>
<div class="clearfix"></div>
<section class="mb-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-6 mt-4">
        <div class="row">
          <div class="col-12 text-left mb-3">
            <div *ngIf="!isReadOnly && !IsTMMReadOnly">
              <ng-container *ngIf="!isHideCancel">
                <button (click)="updateOrRevisionPolicy()" class="btn btn-primary my-0 ml-0 mr-1"
                  [disabled]="isUpdateRevisionDisabled"> {{ buttonText }} </button>
              </ng-container>
              <ng-container *ngIf="isDisabled">
                <policyDetails-policy-details-cancellation
                  [refundData]="refundData"></policyDetails-policy-details-cancellation>
              </ng-container>
              <ng-container *ngIf="isReactivateCancelPolicy">
                <button (click)="openreactivatePolicyPopup()" class="btn btn-secondary float-sm-right"> Reactivate
                  Policy </button>
              </ng-container>
            </div>
          </div>
        </div>
        <policyDetails-policy-details-policy></policyDetails-policy-details-policy>
        <policyDetails-policy-details-trip></policyDetails-policy-details-trip>
        <policyDetails-policy-details-travel-care></policyDetails-policy-details-travel-care>
        <policyDetails-policy-details-travellers></policyDetails-policy-details-travellers>
        <policyDetails-policy-details-dependents></policyDetails-policy-details-dependents>
        <policyDetails-policy-details-pre-existing
          (onPrexExistingUpdate)="reAssessmentNeed = $event"></policyDetails-policy-details-pre-existing>
      </div>

      <div class="col-md-12 col-lg-6 mt-2">
        <policyDetails-policy-details-left-menu></policyDetails-policy-details-left-menu>
        <div class="mt-4 custom-accordion">
          <policyDetails-policy-details-notes [policyData]="policyData"> </policyDetails-policy-details-notes>
          <policyDetails-policy-details-policy-movement></policyDetails-policy-details-policy-movement>
          <policyDetails-policy-details-summary></policyDetails-policy-details-summary>
          <policyDetails-policy-details-payments
            (onPaymentUpdate)="paymentData = $event; updateRefundData()"></policyDetails-policy-details-payments>
          <policyDetails-policy-details-refund (onRefundedAmountUpdate)="refundedData = $event; updateRefundData()"
            [policyData]="policyData"></policyDetails-policy-details-refund>
          <div class="mt-4">
            <policyDetails-policy-details-agents></policyDetails-policy-details-agents>
          </div>
          <policyDetails-policy-details-version></policyDetails-policy-details-version>
          <div class="row">
            <div class="col-md-12 mt-4 text-center">
              <ng-container *ngIf="!isReadOnly && !IsTMMReadOnly">
                <a href="javascript:void(0);" class="btn-links" (click)="exportAuditHistory()">Export Audit History
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isReadOnly && !IsTMMReadOnly">
      <div class="col-md-12 mt-4 text-center">
        <div class="row">
          <div class="col-md-12 mt-4 text-center">
            <ng-container *ngIf="!isDisabled">
              <ng-container *ngIf="isRewrite">
                <button class="btn btn-secondary ml-3 button-disable" (click)="checkAndDiscardChanges()"> Discard
                  Changes </button>
              </ng-container>
              <button class="btn btn-primary ml-3 button-disable"
                (click)="compareAndContinue()"> Compare and Continue </button>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #discardAlertTemplate>
  <div *ngIf="isLoaderDiscard">
    <div class="clearfix"></div>
    <div class="overlay-custom-container"></div>
    <div class="loader"></div>
  </div>
  <div class="" id="myModal-11">
    <div class="modal-body">
      <div class="col-md-5 mx-auto text-center">
        <div *ngIf="discardMessageSuccess" class="alert alert-success my-4">
          {{ discardMessageSuccess }}
        </div>
        <div *ngIf="discardMessageFail" class="alert alert-warning my-4 text-break">
          {{ discardMessageFail }}
        </div>
      </div>
    </div>
    <div class="text-center mb-4 mt-2 btn-footer">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeAlertChildModal()">
        OK
      </button>
    </div>
  </div>
</ng-template>

<ng-template #noteBox>
  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <form [formGroup]="noteForm" (ngSubmit)="noteSubmitted()">
            <h2 class="mb-2 box-label-sm">Add a Notes</h2>
            <div class="form-group">
              <textarea class="form-control" rows="3" maxlength="6000" formControlName="Description"
                placeholder="Add notes here" [ngClass]="{
                  'is-invalid': submitted && f['Description'].errors
                }"></textarea>
              <div *ngIf="submitted && f['Description'].errors" class="invalid-feedback">
                <div *ngIf="f['Description'].errors['required']">
                  Please enter a note
                </div>
              </div>
            </div>
            <div class="text-right mt-2 btn-footer">
              <button class="btn btn-secondary btn-sm" (click)="resetForm()">
                Cancel
              </button>
              <button [disabled]="isAddNoteClicked" class="btn btn-primary btn-sm" type="submit">
                <span *ngIf="isAddNoteClicked" class="spinner-border text-primary"
                  style="width: 1rem; height: 1rem;"></span>
                <span>Save</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #assessmentBox>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle"> </i>Alert
    </h4>
    <button type="button" class="close" (click)="closeReassessmentError()">
      &times;
    </button>
  </div>
  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-warning">
          <p class="p-2">
            Please recalculate medical premium by clicking Recalculate Medical
            Premium in the Update Medical Cover section.
          </p>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeReassessmentError()">
      OK
    </button>
  </div>
</ng-template>

<ng-template #reactivatePolicyBox>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle"> </i>Reactivate Policy
    </h4>
    <button type="button" class="close" (click)="closeReassessmentError()">
      &times;
    </button>
  </div>
  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="alert alert-warning">
          <p class="p-2">Are you sure you want to reactivate this policy?</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="reactivatePolicyOpenNote()">
      Yes
    </button>

    <button type="submit" class="btn btn-primary pull-right" (click)="closeReassessmentError()">
      No
    </button>
  </div>
</ng-template>

<ng-template #alertBox>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle"> </i>Alert
    </h4>
    <button type="button" class="close" (click)="closeReactivateAlertModel()">
      &times;
    </button>
  </div>
  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>
          <ul *ngIf="modalData.Error.length > 0">
            <li class="alert alert-warning"  *ngFor="let error of modalData.Error">
              {{error}}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeReactivateAlertModel()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>