<ng-template #confirmationBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-thumbs-up" aria-hidden="true"></i> Confirmation
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="modal.close()">
      &times;
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ message }}</div>
      </div>
    </div>
  </div>

  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="modal.close()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>