export class PaymentData {
  PaymentConfirmationNumber!: string;
  PaymentType!: string;
  PaymentAmount!: number;
  VerificationToken!: string;
  PaymentStatus!: string;
}

export class CreateActivityLogItem {
  PolicyTxnId!: string;
  ActivityType!: string;
  ActivityDate!: string;
  Description!: string;
  LoggedInUser!: string;
}

export class IssueEndorsementGTA {
  PolicyTxnId!: string;
  FormSetId!: string;
  BranchName!: string;
  PaymentData!: PaymentData[];
  CreateActivityLogItem!: CreateActivityLogItem;
  AgentDetails: any;
}

export class ContactProducerPromoCode {
  PromoCode!: string;
}

export class AddPromoCode {
  ContactProducerPromoCodes!: ContactProducerPromoCode[];
}
