import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  ViewChild,
  TemplateRef,
  ElementRef,
  HostListener,
} from '@angular/core';
import { PolicyService } from './policy.service';
import { take, filter, first, takeUntil } from 'rxjs/operators';
import {
  NgbModal,
  NgbModalRef,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ClubAndBranchInfo, CommonParams } from 'src/app/core/commonparams';
import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
import { CurrencyPipe } from '@angular/common';
import { PriceService } from '../../../../src/app/core/services/price.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { PolicyDetailService } from '../../../policyDetails/src/app/app.services';
import { Session } from 'protractor';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import {
  PageNameEnum,
  SubComponentNameEnum,
} from 'projects/policyDetails/src/app/enum/page-name-enum';
import { PortalCodeEnum } from 'src/app/core/enum/sitename-enum';
import { Utility } from 'src/app/core/services/utility';
import { EndorsementGenericService } from 'projects/policyDetails/src/app/shared/services/endorsement-generic.service';
import { DataBusService } from 'projects/policyDetails/src/app/services/databus.service';
import { MessageBusService } from 'projects/policyDetails/src/app/services/messagebus.service';
import { ToastrService } from 'ngx-toastr';
import { TravelerUpdateService } from 'projects/policyDetails/src/app/shared/services/traveller-update.service';
import { EventEmitterService } from 'projects/policyDetails/src/app/shared/services/event-emitter.service';
import moment from 'moment';
import { DateService } from 'src/app/core/services/date-updated.service';
import { NavigationPreventionService } from 'src/app/core/services/navigation-prevention.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails, UserProfile } from 'src/app/core/store/loginUser/loginUser.model';

@Component({
  selector: 'policySummary-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class PolicySummaryComponent implements OnInit, OnChanges {
  title = 'policySummary';
  policySummaryData: any;
  summaryChangeCurrentData: any;
  risksArrayDataCurrent: any;
  getNoOfDependentCount: any;
  uWQuotesArrayDataCurrent: any;
  lobSArrayDataCurrent: any;
  cityZipCodeArrayCurrent: any;
  cityZipCodeDataCurrent: any;
  coverageArrayDataCurrent: any;
  dataFindCurrent: any;
  dataFind2Current: any;
  dataFind3Current: any;
  cruiseCurrentValue: any;
  excessCurrentValue: any;
  skingCurrentValue: any;
  additionalAmount: any;
  summaryChangePreviousData: any;
  risksArrayDataPrevious: any;
  uWQuotesArrayDataPrevious: any;
  lobSArrayDataPrevious: any;
  cityZipCodeArrayPrevious: any;
  cityZipCodeDataPrevious: any;
  coverageArrayDataPrevious: any;
  dataFindPrevious: any;
  dataFind2Previous: any;
  dataFind3Previous: any;
  cruisePreviousValue: any;
  excessPreviousValue: any;
  skingPreviousValue: any;
  countryResult: any;
  private destroy$: Subject<boolean>;
  isIssueEndorsementLoader = false;
  currentCountryName: any = '';
  previousCountryName: any = '';
  getExcess: any;
  previousDateFrom: any;
  summaryForm: FormGroup;
  checkCoiValue: boolean = false;
  countryDataCurrent: any;
  countryDataPrevious: any;
  list: any;
  countryCodeNamePrevious: any;
  countryCodeNameCurrent: any;
  additionalAmountData: any = 0;
  isCheckAdditional: boolean = false;
  isCheckRefundAdditional: boolean = false;
  discardMessageFail!: string;
  discardMessageSuccess!: string;
  modelAlertRef: NgbModalRef | any;
  isLoader: boolean = false;
  additionalWrittenPrem: any;
  isCheckIssueEndorsement: any;
  isPolicyReceiptLoader = false;
  isLoadeing: string = 'Yes';
  memberShipDiscountCurrentValue: any;
  memberShipDiscountPreviousValue: any;
  promoCodeDiscountCurrentValue: any;
  promoCodeDiscountPreviousValue: any;
  isPendingRewrite: boolean = true;
  maxTripLengthCurrentValue: any;
  maxTripLengthPreviousValue: any;
  maxTripLengthPreviousDiscrip: any;
  maxTripLengthCurrentDiscrip: any;
  refundData = [];
  public toggleMultiple: boolean = false;
  public toggleMultiple2: boolean = false;
  PolicyNum: any;
  msg: string = '';
  userRoleDetails?: UserRoleDetails;
  userProfile?: UserProfile;
  @ViewChild('discardAlertTemplate', { static: true })
  dialogalertBox!: TemplateRef<ElementRef>;
  isDisabledDiscard: boolean = true;

  @ViewChild('warningBox', { static: true })
  warningBox!: ElementRef;

  @ViewChild('confirmationBox', { static: true })
  confirmationBox!: ElementRef;
  modalData: any;

  constructor(
    private policyService: PolicyService,
    private loaderService: LoaderService,
    private priceService: PriceService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    public oktaCustomAuth: OktaCustomAuthService,
    private modelService: NgbModal,
    public dataService: PolicyDetailService,
    public endorsementGenericService: EndorsementGenericService,
    private logService: LogService,
    private eventEmitter: EventEmitterService,
    private travelerUpdateService: TravelerUpdateService,
    private dateService: DateService,
    private navigationPreventionService: NavigationPreventionService,
    private store: Store
  ) {
    this.destroy$ = new Subject();
    this.summaryForm = this.fb.group({
      checkCoi: ['true'],
    });
  }
  ngOnInit() {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.userProfile = loginUser.userProfile;
        this.additionalAmount = 0;

        if (sessionStorage.getItem('pendingrequest')) {
          this.isPendingRewrite = false;
        }

        this.compairSummaryData();

        this.checkCoiValue = true;
        sessionStorage.setItem('sendCoiCheckBoxValue', JSON.stringify(this.checkCoiValue),);

        this.summaryForm.controls['checkCoi'].valueChanges.subscribe((data) => {
          this.checkCoiValue = data;
          sessionStorage.setItem('sendCoiCheckBoxValue', JSON.stringify(this.checkCoiValue),);
        });

        this.checkPolicyTxnIdWithEndorseWithDiscard();

        this.eventEmitter.onPaymentSummaryIssueCall.subscribe(
          (isUpdated: string) => {
            if (isUpdated) {
              this.issueEndorsementCall();
            }
          },
        );

        this.eventEmitter.onDiscardCall.subscribe((isUpdated: string) => {
          if (isUpdated) {
            this.discardChanges();
          }
        });
      }
    });
  }

  compairSummaryData() {
    // if (sessionStorage.getItem('movementGuid') !== sessionStorage.getItem('originalMovementGuid')) {
    this.dataService
      .compareEndorsment(sessionStorage.getItem('policyGuid'))
      .pipe(filter((dataRes) => dataRes !== undefined))
      .subscribe(
        (res) => {
          this.isLoadeing = 'No';
          this.policySummaryData = res;
          this.refundData = res.refunds;
          //==========Current Data ======================//
          if (res.currentEndorsement != undefined) {
            this.PolicyNum = res.currentEndorsement.policyNumber;
            this.policySummaryData.currentEndorsement = res.currentEndorsement;
            this.summaryChangeCurrentData = this.policySummaryData.currentEndorsement;
            let siteName = sessionStorage.getItem('portalcode') || '';
            var dateObject = this.dataService.utcToAestDate('17/11/2021');
            let PurchaseDate = this.dataService.utcToAestDate(
              sessionStorage.getItem('PurchaseDate') || '',
            );
            const date2 = new Date(PurchaseDate);
            this.summaryChangeCurrentData.fromDate = new Date(this.dataService.utcToAestDate((this.dateService.parseDateString(this.summaryChangeCurrentData.fromDate) || new Date()).toUTCString(),),);
            this.summaryChangeCurrentData.toDate = new Date(this.dataService.utcToAestDate((this.dateService.parseDateString(this.summaryChangeCurrentData.toDate) || new Date()).toUTCString(),),);
            if (this.summaryChangeCurrentData.travelers.length > 0) {
              let primaryTraveler = this.summaryChangeCurrentData.travelers.find((x: any) => x.role === "primary");
              let secondaryTraveler = this.summaryChangeCurrentData.travelers.find((x: any) => x.role === "secondary");
              let dependentTraveler = this.summaryChangeCurrentData.travelers.filter((x: any) => x.role === "dependent");
              this.summaryChangeCurrentData.PrimaryTravellerName = primaryTraveler && primaryTraveler != undefined ? primaryTraveler.firstName + ' ' + primaryTraveler.lastName : '';
              this.summaryChangeCurrentData.SecondaryTravellerName = secondaryTraveler && secondaryTraveler != undefined ? secondaryTraveler.firstName + ' ' + secondaryTraveler.lastName : '';
              this.summaryChangeCurrentData.DependentTravellers = dependentTraveler;
            }
            if (localStorage.getItem('countryResult') && localStorage.getItem('countryResult') !== undefined) {
              this.countryResult = localStorage.getItem('countryResult');
            }
          }
          //==========Previous Data ======================//

          this.policySummaryData.previousEndorsement = res.previousEndorsement;
          this.summaryChangePreviousData = this.policySummaryData.previousEndorsement;
          this.summaryChangePreviousData.fromDate = new Date(this.dataService.utcToAestDate((this.dateService.parseDateString(this.summaryChangePreviousData.fromDate) || new Date()).toUTCString(),),);
          this.summaryChangePreviousData.toDate = new Date(this.dataService.utcToAestDate((this.dateService.parseDateString(this.summaryChangePreviousData.toDate) || new Date()).toUTCString(),),);
          // this.summaryChangePreviousData.TripToDate = this.policySummaryData.PreviousTransaction.AdditionalPolicyDetailsGTA.TripToDate;
          if (this.summaryChangePreviousData.travelers.length > 0) {
            let primaryTraveler = this.summaryChangePreviousData.travelers.find((x: any) => x.role === "primary");
            let secondaryTraveler = this.summaryChangePreviousData.travelers.find((x: any) => x.role === "secondary");
            let dependentTraveler = this.summaryChangePreviousData.travelers.filter((x: any) => x.role === "dependent");

            this.summaryChangePreviousData.PrimaryTravellerName = primaryTraveler && primaryTraveler != undefined ? primaryTraveler.firstName + ' ' + primaryTraveler.lastName : '';
            this.summaryChangePreviousData.SecondaryTravellerName = secondaryTraveler && secondaryTraveler != undefined ? secondaryTraveler.firstName + ' ' + secondaryTraveler.lastName : '';
            this.summaryChangePreviousData.DependentTravellers = dependentTraveler;
          }
          // this.additionalAmount = this.summaryChangePreviousData.premiums.totalCost - this.summaryChangeCurrentData.premiums.totalCost;
          this.additionalAmount = this.summaryChangeCurrentData.premiums.additionalPremium

          // this.additionalAmount =
          //   this.replaceMinus( (this.summaryChangeCurrentData && this.summaryChangeCurrentData.premiums && this.summaryChangeCurrentData.premiums !== undefined && this.summaryChangeCurrentData.premiums.totalCost && this.summaryChangeCurrentData.premiums.totalCost != undefined ? this.summaryChangeCurrentData.premiums.totalCost : '0' ).toString(), ) -
          //   this.replaceMinus( (this.summaryChangePreviousData && this.summaryChangePreviousData.premiums && this.summaryChangePreviousData.premiums !== undefined && this.summaryChangePreviousData.premiums.totalCost && this.summaryChangePreviousData.premiums.totalCost !== undefined ? this.summaryChangePreviousData.premiums.totalCost : '0' ).toString(), );

          if (this.additionalAmount != 0) {
            sessionStorage.setItem('additionalAmount', this.additionalAmount)
          }
          this.getNoOfDependentCount = this.summaryChangePreviousData.dependentsCount;
          this.getNoOfDaysPrevious();
          this.getNoOfDaysCurrent();
          this.getDataUpQuotesCurrent();
          this.getDataUpQuotesPrevious();
          // this.getNoOfDependent();
          this.getCoverageCurrentData();
          this.getCoveragePreviousData();
          // this.getCountryCurrent();
          // this.getCountryPrevious();
        },
        (err) => {
          this.logService.createErrorLog(
            {},
            err,
            'GetPolicyCompareData',
            PageNameEnum.PolicySummary,
            LogLevel.Error,
          );
        },
      );
  }

  getDataUpQuotesCurrent() {
    this.checkAdditionalAmount(this.additionalAmount);

    let destinationList: any[] = [];
    var countries = sessionStorage.getItem('mCountry') ? JSON.parse(sessionStorage.getItem('mCountry') || '{}') : "";
    if (countries && countries.length > 0) {
      var destinations = this.policySummaryData.currentEndorsement?.destinations ?? [];
      if (destinations.length > 0) {
        destinations.forEach((dest: any) => {
          var country = countries.find((country: any) => country.countryCode == dest.countryCode);
          destinationList.push(country.countryName);
        });
        if (destinationList.length <= 1) {
          this.currentCountryName = destinationList.join('');
        } else {
          this.currentCountryName = destinationList.join(' , ');
        }
      }
      // }
    }
  }

  getDataUpQuotesPrevious() {
    let destinationList: any[] = [];
    var countries = sessionStorage.getItem('mCountry') ? JSON.parse(sessionStorage.getItem('mCountry') || '{}') : "";
    if (countries && countries.length > 0) {
      var destinations = this.policySummaryData.previousEndorsement.destinations;
      if (destinations.length > 0) {
        destinations.forEach((dest: any) => {
          var country = countries.find((country: any) => country.countryCode == dest.countryCode);
          destinationList.push(country.countryName);
        });
        if (destinationList.length <= 1) {
          this.previousCountryName = destinationList.join('');
        } else {
          this.previousCountryName = destinationList.join(' , ');
        }
      }
      // }
    }
  }

  getNoOfDaysCurrent() {
    if (
      this.summaryChangeCurrentData &&
      this.summaryChangeCurrentData.fromDate !== undefined
    ) {
      return this.policyService.differenceInDays(
        this.summaryChangeCurrentData.fromDate,
        this.summaryChangeCurrentData.toDate,
      );
    }
  }

  getNoOfDaysPrevious() {
    if (
      this.summaryChangePreviousData &&
      this.summaryChangePreviousData.fromDate !== undefined
    ) {
      return this.policyService.differenceInDays(
        this.summaryChangePreviousData.fromDate,
        this.summaryChangePreviousData.toDate,
      );
    }
  }

  getCoverageCurrentData() {
    if (
      this.summaryChangeCurrentData != null &&
      this.summaryChangeCurrentData != undefined
    ) {
      this.cruiseCurrentValue =
        this.summaryChangeCurrentData.premiums.cruise;
      this.skingCurrentValue =
        this.summaryChangeCurrentData.premiums.skiing;
      this.memberShipDiscountCurrentValue =
        this.summaryChangeCurrentData.premiums.membershipDiscount;
      this.promoCodeDiscountCurrentValue =
        this.summaryChangeCurrentData.premiums.promoCodeDiscount;
      this.maxTripLengthCurrentValue =
        this.summaryChangeCurrentData.premiums.multiTripLength;
    }
  }

  getCoveragePreviousData() {
    if (
      this.summaryChangePreviousData != null &&
      this.summaryChangePreviousData != undefined
    ) {
      this.cruisePreviousValue =
        this.summaryChangePreviousData.premiums.cruise;
      this.skingPreviousValue =
        this.summaryChangePreviousData.premiums.skiing;
      this.memberShipDiscountPreviousValue =
        this.summaryChangePreviousData.premiums.membershipDiscount;
      this.promoCodeDiscountPreviousValue =
        this.summaryChangePreviousData.premiums.promoCodeDiscount;
      this.maxTripLengthPreviousValue =
        this.summaryChangePreviousData.premiums.multiTripLength;
    }
  }

  getNoOfDependent() {
    if (
      this.risksArrayDataCurrent &&
      this.risksArrayDataCurrent.RiskDataElements &&
      this.risksArrayDataCurrent.RiskDataElements.ElementUpdateRequests !==
      undefined
    ) {
      this.getNoOfDependentCount =
        this.risksArrayDataCurrent.RiskDataElements.ElementUpdateRequests.find(
          (data: any) => data.ElementName == 'No of Dependents',
        );
      if (
        this.getNoOfDependentCount &&
        this.getNoOfDependentCount.IntegerValue !== undefined
      ) {
        return this.getNoOfDependentCount.IntegerValue;
      } else {
        return (this.getNoOfDependentCount.IntegerValue = '');
      }
    }
  }

  resendCoiCustEmail() {
    let coiFormSetId = sessionStorage.getItem('coiFormSetId');
    if (!coiFormSetId) {
      coiFormSetId = this.policyService.RESEND_COI;
    }
    let PolicyTxnId = sessionStorage.getItem('originalMovementGuid');

    if (this.checkCoiValue == true) {
      this.policyService.resendCoi(PolicyTxnId).subscribe(
        (data) => {
          this.loaderService.hide();
        },
      );
    }
  }

  checkCoi() {
    this.loaderService.show();
    setTimeout(() => {
      this.loaderService.hide(); 
      location.href = '/policypayments';
    }, 3000);
  }

  replaceMinus(data: any) {
    if (data && data.includes('-')) {
      return data.replace('-', '');
    } else {
      return data;
    }
  }

  checkAdditionalAmount(currAmount: any) {
    sessionStorage.removeItem('additionalAmount');
    sessionStorage.removeItem('initiateRefundAmount');
    this.additionalAmountData = 0;
    this.isCheckIssueEndorsement = false;
    this.isCheckAdditional = false;
    this.isCheckRefundAdditional = false;

    if (currAmount == 0) {
      this.isCheckIssueEndorsement = true;
    } else if (currAmount > 0) {
      sessionStorage.setItem('additionalAmount', this.toSessionFixed(currAmount, 2),);
      //console.log('additionalAmount', currAmount);
      this.isCheckAdditional = true;
    } else {
      currAmount = Math.abs(currAmount);
      sessionStorage.setItem('initiateRefundAmount', this.toSessionFixed(currAmount, 2),);
      //console.log('initiateRefundAmount', currAmount);
      this.isCheckRefundAdditional = true;
    }
  }

  toSessionFixed(num: any, fixed: any) {
    let re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
    return num.toString().match(re)[0];
  }

  toFixed(value: any, num: any) {
    let formattedvalue = this.priceService.getPriceFixed(value, 2);
    return formattedvalue;
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.compairSummaryData();
  }

  checkAndDiscardChanges() {
    if (
      sessionStorage.getItem('updatedContact') &&
      sessionStorage.getItem('isContactModified') &&
      sessionStorage.getItem('baseTravelers')
    ) {
      var updatedContactTxnId = JSON.parse(
        sessionStorage.getItem('isContactModified') || '',
      ).PolicyTxnId.toString();
      if (updatedContactTxnId == sessionStorage.getItem('movementGuid')) {
      }
    } else {
      this.discardChanges();
    }
  }

  discardChanges() {
    this.isLoader = true;
    this.discardMessageSuccess = '';
    this.discardMessageFail = '';
    let data = {
      movementGuid: sessionStorage.getItem('movementGuid'),
      // loggedInUser: 'SYSTEM'
    };
    this.dataService
      .discardEN(data)
      .pipe(filter((data) => data !== undefined))
      .subscribe(
        (response) => {
          if (response && response.isSuccessful) {
            sessionStorage.setItem('movementGuid', sessionStorage.getItem('originalMovementGuid') || '',);
            this.discardMessageSuccess = 'Changes has been discarded.';
            this.openAlertModel();
          } else {
            this.discardMessageFail = 'Something went wrong please try again.';
            this.openAlertModel();
          }
        },
        (err) => {
          this.logService.createErrorLog(
            data,
            err,
            'DiscardChange',
            PageNameEnum.PolicySummary,
            LogLevel.Error,
          );
        },
      );

    this.isLoader = false;
  }

  openAlertModel() {
    this.modelAlertRef = this.modelService.open(this.dialogalertBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeAlertChildModal() {
    if (this.discardMessageSuccess) {
      this.modelAlertRef.close();
      window.location.href = '/policy-detail';
    } else this.modelAlertRef.close();
  }

  checkPolicyTxnIdWithEndorseWithDiscard() {
    if (
      sessionStorage.getItem('originalMovementGuid') ===
      sessionStorage.getItem('movementGuid')
    ) {
      this.isDisabledDiscard = true;
    } else {
      this.isDisabledDiscard = false;
    }
  }

  endorsementClose() {
    if (
      sessionStorage.getItem('updatedContact') &&
      sessionStorage.getItem('isContactModified')
    ) {
      var updatedContactTxnId = JSON.parse(
        sessionStorage.getItem('isContactModified') || '',
      ).PolicyTxnId;
      if (updatedContactTxnId == sessionStorage.getItem('movementGuid')) {
        this.loaderService.show();
        // this.travelerUpdateService.updateEndorsementService('PaymentSummary');
      } else {
        sessionStorage.removeItem('updatedContact');
        sessionStorage.removeItem('isContactModified');
        this.issueEndorsementCall();
      }
    } else {
      this.issueEndorsementCall();
    }
  }

  open(content: any, rowdata: any) {
    const modal: NgbModalRef = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    this.modalData = rowdata;
    modal.result.then(
      (result) => {
        //console.log(result);
      },
      (reason) => {
        //console.log(reason);
      },
    );
  }

  closeModalConfirmation() {
    location.href = '/policy-detail';
  }

  toggleMultipleCountry() {
    this.toggleMultiple = !this.toggleMultiple;
  }
  toggleMultipleCountry2() {
    this.toggleMultiple2 = !this.toggleMultiple2;
  }

  issueEndorsementCall() {
    this.isIssueEndorsementLoader = true;
    this.loaderService.show();
    let policyGuid = sessionStorage.getItem('policyGuid') || '';
    let movementGuid = sessionStorage.getItem('movementGuid') || '';
    let coiSend = sessionStorage.getItem("sendCoiCheckBoxValue") || '';
    let sendCoiCheckBoxValue = coiSend.length > 0 && coiSend.toString().toLowerCase() == 'true' ? true : false ;

    let policyEndorsement =
    {
      policyGuid: policyGuid.toString(),
      movementGuid: movementGuid.toString(),
      nonce: '',
      methodType: 'NONFIN',
      cashReceiptNumber: '',
      amount: 0,
      isSendCOI: sendCoiCheckBoxValue,
      agentBranchInfo:
      {
        agentGuid: this.userRoleDetails?.AgentContactId,
        agentName: this.userProfile?.LoggedUser,
        branchGuid: this.userRoleDetails?.BranchContactId,
        branchName: ClubAndBranchInfo.BranchName,
        roleId: 0
      }
    };

    this.policyService.savePaymentRecieptRequest(policyEndorsement)
      .subscribe(
        (resp) => {
          this.loaderService.hide();
          if (resp != undefined && resp.status != undefined) {
            sessionStorage.setItem('originalMovementGuid', movementGuid);
            this.open(this.confirmationBox, { Msg: 'SUCCESS' });
          } else if (
            resp.Success != undefined &&
            resp.StackTrace != null
          ) {
            this.open(this.warningBox, { Error: resp.Message });
          } else {
            this.open(this.warningBox, { Error: resp.Errors[0] });
          }
          // this.resendCoiCustEmail();
        },
        (err) => {
          this.loaderService.hide();
        },
      );

    this.isIssueEndorsementLoader = false;
  }
}
