<ng-template #addBranchModal let-modal let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ this.action === 'add' ? 'Add a Branch' : 'Edit Branch' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">&times;</button>
  </div>
  <div class="modal-body p-0 m-0">
    <form [formGroup]="addBranchForm" (ngSubmit)="onSubmit()" #addBranchFormRef="ngForm">
      <ng-container formGroupName="GTAContact">
        <div class="card bg-light" style="min-height: 200px">
          <div class="card-body">
            <div class="form-row mt-3">
              <div class="form-group col-md-12 col-md-4">
                <app-clubs-dropdown [selectedClubCode]="selectedClubCode" [actionType]="action" (clubChanged)="onClubChanged($event)" />
              </div>
              <div class="form-group col-md-12 col-lg-4">
                <input type="text" maxlength="50" class="form-control"
                  [class.is-invalid]="isInvalid('GTAContact.OrgFullName')" placeholder="Branch Name"
                  formControlName="OrgFullName" autocomplete="off" />

                <app-form-error [submitted]="submitted" [control]="addBranchForm.get('GTAContact.OrgFullName')!"
                  label="Branch Name"></app-form-error>
              </div>

              <div class="form-group col-md-12 col-lg-8">
                <app-select-address-dropdown [addressGroup]="getAddressGroup()"
                  [isInvalid]="isInvalid('GTAContact.Addresses.0.StreetAddress')"
                  (addressSelected)="onAddressSelected($event)" />

                <app-form-error [submitted]="submitted" [control]="getAddressGroup().get('StreetAddress')!"
                  label="Street Address"></app-form-error>
              </div>

              <div class="form-group col-md-12 col-lg-4" [formGroup]="getAddressGroup()">
                <input type="text" class="form-control" id="inputEmail4" maxlength="50"
                  [class.is-invalid]="isInvalid('GTAContact.Addresses.0.City')" placeholder="Suburb"
                  formControlName="City" />

                <app-form-error [submitted]="submitted" [control]="getAddressGroup().get('City')!"
                  label="Suburb"></app-form-error>
              </div>

              <div class="form-group col-md-12 col-lg-4" [formGroup]="getAddressGroup()">
                <select class="form-control" formControlName="StateCode"
                  [class.is-invalid]="isInvalid('GTAContact.Addresses.0.StateCode')">
                  <option *ngFor="let state of states" [ngValue]="state.code">
                    {{ state.code }}
                  </option>
                </select>

                <app-form-error [submitted]="submitted" [control]="getAddressGroup().get('StateCode')!"
                  label="State"></app-form-error>
              </div>

              <div class="form-group col-md-12 col-lg-4" [formGroup]="getAddressGroup()">
                <input type="text" maxlength="4" class="form-control"
                  [class.is-invalid]="isInvalid('GTAContact.Addresses.0.ZipCode')" placeholder="Postcode"
                  formControlName="ZipCode" />

                <app-form-error [submitted]="submitted" [control]="getAddressGroup().get('ZipCode')!"
                  label="Postcode"></app-form-error>
              </div>

              <div class="form-group col-md-12 col-lg-4" [formGroup]="getPhoneGroup()">
                <input type="text" class="form-control" maxlength="14" min="10"
                  [class.is-invalid]="isInvalid('GTAContact.PhoneNumbers.0.PhoneNum')" placeholder="Phone Number"
                  formControlName="PhoneNum" />

                <app-form-error [submitted]="submitted" [control]="getPhoneGroup().get('PhoneNum')!" [totalDigits]="10"
                  label="Phone Number"></app-form-error>
              </div>

              <div class="form-group col-md-12 col-lg-4" [formGroup]="getFaxGroup()">
                <input type="text" maxlength="14" min="10" class="form-control"
                  [class.is-invalid]="isInvalid('GTAContact.Fax.0.PhoneNum')" placeholder="Fax"
                  formControlName="PhoneNum" />
                <app-form-error [submitted]="submitted" [control]="getFaxGroup().get('PhoneNum')!" [totalDigits]="10"
                  label="Fax" rawMessage="Please enter a valid Fax Number."></app-form-error>
              </div>

              <div class="form-group col-md-12 col-lg-4" [formGroup]="getEmailAccounts()">
                <input type="email" class="form-control" placeholder="Email"
                  [class.is-invalid]="isInvalid('GTAContact.EmailAccts.0.EmailAddress')"
                  formControlName="EmailAddress" />
                <app-form-error [submitted]="submitted" [control]="getEmailAccounts().get('EmailAddress')!"
                  label="Email Address"></app-form-error>
              </div>

              <div class="form-group col-md-12 col-lg-4">
                <input type="text" maxlength="11" class="form-control" placeholder="ABN"
                  [class.is-invalid]="isInvalid('GTAContact.ABN')" formControlName="ABN" />
                <app-form-error [submitted]="submitted" [control]="addBranchForm.get('GTAContact.ABN')!"
                  [totalDigits]="11" label="ABN"></app-form-error>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
  <div class="modal-footer">
    <div class="row w-100">
      <div class="col-md-12 mt-4 text-center btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" (click)="modal.close()">Cancel
        </button>
        <button type="button" (click)="addBranchFormRef.ngSubmit.emit()" class="btn btn-primary btn-full-sm">Save
        </button>
      </div>
    </div>
  </div>
</ng-template>