import { LogLevel } from '../enum/log-level-enum';

export class LogEntry {
  entryDate: Date = new Date();
  error: string = '';
  siteName: string = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate: boolean = true;
  payLoad: string = '';

  buildLogString(): string {
    let ret: string = '';

    if (this.logWithDate) {
      ret = new Date() + ' - ';
    }
    ret += 'Type: ' + LogLevel[this.level];
    ret += ' - Error: ' + this.error;
    if (this.extraInfo.length) {
      ret += ' - ExtraInfo: ' + this.formatParams(this.extraInfo);
    }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    if (params.some((p) => typeof p == 'object')) {
      ret = '';
      for (let item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }
}
