import { Inject, Injectable } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import { AuthenticationResult, RedirectRequest } from '@azure/msal-browser';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TokenRefreshService {
  private readonly _tokenRefreshInterval = 30 * 60 * 1000; // Refresh token every second
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
  ) {}

  startTokenRefresh(): void {
    timer(this._tokenRefreshInterval, this._tokenRefreshInterval)
      .pipe(
        takeUntil(this._destroying$),
        switchMap(() => this.checkAndRenewToken()),
      )
      .subscribe(
        () => console.log('Token refreshed successfully'),
        (error) => console.error('Error refreshing token:', error),
      );
  }

  stopTokenRefresh(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  private checkAndRenewToken(): Observable<any> {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (activeAccount) {
      const request = {
        scopes: [
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.ReadWrite.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Group.ReadWrite.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.Invite.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Application.ReadWrite.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/AppRoleAssignment.ReadWrite.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/GroupMember.ReadWrite.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/PendingExternalUserProfile.ReadWrite.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/ExternalUserProfile.ReadWrite.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Directory.AccessAsUser.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.ManageIdentities.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.EnableDisableAccount.All',
          'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Directory.ReadWrite.All',
        ],
        account: activeAccount,
        forceRefresh: true,
      };

      return this.msalService.acquireTokenSilent(request).pipe(
        tap((response: AuthenticationResult) => {
          localStorage.setItem('accessToken', response.accessToken);
        }),
        catchError((error) => {
          return throwError(error); // Optional: Rethrow the error
        }),
      );
    } else {
      this.msalService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
      return throwError('No active account found'); // Example of error handling
    }
  }
}
