import { createFeatureSelector, createSelector } from "@ngrx/store";
import { LoginUserState, } from "./loginUser.reducer";

export const selectLoginState = createFeatureSelector<LoginUserState>('loginUser');

export const loginUser = createSelector(
  selectLoginState,
  (state: LoginUserState) => state?.loginUser
);

