<!--<div class="loader" *ngIf="isShowLoader"></div>-->
<app-loader></app-loader>
<div class="card-gray">
  <h1 class="card-gray-header">Update Medical Cover</h1>

  <div class="card-edit" *ngIf="isUpdateENCalled && isEditBtnRequired">
    <a href="javascript:void(0);">
      <i *ngIf="isLoadeing != 'Yes'" class="far fa-edit" data-toggle="modal" (click)="openModal()"></i>
      <i *ngIf="isLoadeing === 'Yes'" class="loader-sm mt-2"></i>
    </a>
  </div>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row">
        <div class="col-7">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'" style="word-break: break-word">
            {{
            getFullName(
            state.Traveler1.FirstName,
            '',
            state.Traveler1.LastName
            ) | nameFormat: 3
            }}, {{ state.Traveler1.Age }}</span>
        </div>
        <ng-container>
          <div class="col-3 text-right">
            <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
            <span *ngIf="isLoadeing === 'No'">{{
              state.Traveler1.MedicalResult ? state.Traveler1.MedicalResult : 'None'
              }}</span>
          </div>
          <div
            *ngIf=" isLoadeing === 'No' &&  state.Traveler1.IsQuestionAnswer && (state.Traveler1.MedicalResult === 'Accepted' || state.Traveler1.MedicalResult === 'Declined') "
            class="col-2 text-left">
            <button type="button" class="btn btn-sm btn-primary px-2"
              (click)=" checkCommonMedicalDetails( state.Traveler1 )"> view </button>
          </div>
        </ng-container>
      </div>
    </li>
    <li class="list-group-item" *ngIf="state.Traveler2.FirstName">
      <div class="row">
        <div class="col-7">
          <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
          <span *ngIf="isLoadeing === 'No'" style="word-break: break-word">
            {{
            getFullName(
            state.Traveler2.FirstName,
            '',
            state.Traveler2.LastName
            ) | nameFormat: 3
            }}, {{ state.Traveler2.Age }}</span>
        </div>
        <ng-container>
          <div class="col-3 text-right">
            <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
            <span *ngIf="isLoadeing === 'No'">{{
              state.Traveler2.MedicalResult ? state.Traveler2.MedicalResult : 'None'
              }}</span>
          </div>
          <div
            *ngIf=" isLoadeing === 'No' && state.Traveler2.IsQuestionAnswer && (state.Traveler2.MedicalResult === 'Accepted' || state.Traveler2.MedicalResult === 'Declined') "
            class="col-2 text-left">
            <button type="button" class="btn btn-sm btn-primary px-2"
              (click)="checkCommonMedicalDetails( state.Traveler2 ) "> view </button>
          </div>
        </ng-container>
      </div>
    </li>
    <div *ngIf="state.Dependents && state.Dependents.length > 0">
      <li class="list-group-item" *ngFor="let dependent of state.Dependents; let idx = index">
        <div class="row">
          <div class="col-7">
            <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
            <span *ngIf="isLoadeing === 'No'" style="word-break: break-word">
              {{
              getFullName(dependent.FirstName, '', dependent.LastName)
              | nameFormat: 3
              }}, {{ dependent.Age }}</span>
          </div>
          <ng-container>
            <div class="col-3 text-right">
              <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
              <span *ngIf="isLoadeing === 'No'">{{
                dependent.MedicalResult ? dependent.MedicalResult : 'None'
                }}</span>
            </div>
            <div *ngIf="
                isLoadeing === 'No' &&
                dependent.IsQuestionAnswer &&
                (dependent.MedicalResult === 'Accepted' ||
                  dependent.MedicalResult === 'Declined')
              " class="col-2 text-left">
              <button type="button" class="btn btn-sm btn-primary px-2"
                (click)=" checkCommonMedicalDetails( dependent ) "> view </button>
            </div>
          </ng-container>
        </div>
      </li>
    </div>
  </ul>
</div>
<ng-template #dialogContentTemplate>
  <div class="modal-header">
    <h5 class="modal-title">Update Medical Cover</h5>
    <button type="button" *ngIf="userSelectionIndex !== 5" class="close" (click)="closePreExistingModal()"
      aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12 mx-auto">
      <div *ngIf="userSelectionIndex == 1" class="row">
        <div class="col-md-12">
          <h2>{{ whiteLebeling.PreExistingHeading }}</h2>
          <h6 class="pt-2">{{ whiteLebeling.QuestionsText }}</h6>

          <!-- adult travellers section-->
          <div class="box-panel mt-4">
            <h6>
              <b>{{ whiteLebeling.TravellersText }}</b>
            </h6>
            <div class="row pt-2" *ngIf="state.Traveler1.FirstName">
              <div class="col-lg-5 col-xl-5">
                <div class="para-left">
                  {{ state.Traveler1.FirstName }} {{ state.Traveler1.LastName }}
                </div>
              </div>
              <div class="col-lg-7 col-xl-7">
                <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-light" [ngClass]="{ active: adultTravelerStatusChecked }">
                    <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                      [(ngModel)]="adultTravelerStatusChecked" (click)="getAssessBtn1('Yes')" />Yes
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                  <label class="btn btn-light" *ngIf="getAssesseeStatus(0) == 'Pending'"
                    [ngClass]="{ active: !adultTravelerStatusChecked }">
                    <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                      [(ngModel)]="adultTravelerStatusChecked" (click)="getAssessBtn1('No')" />No
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                </div>
                <span
                  *ngIf=" !state.assessmantStatus && !state.showAssessBtn1 && !(getAssesseeStatus(0) && getAssesseeStatus(0) != 'Pending') ">
                  {{ whiteLebeling.PreexistingNo }}
                  {{ whiteLebeling.PreexistingText }}
                </span>

                <button *ngIf=" state.showAssessBtn1 && getAssesseeStatus(0) === 'Pending' "
                  class="btn btn-secondary assess-btn custom-theme-group-two ml-2"
                  (click)="checkAssessOfTralver1('AdultTraveler')">
                  {{ whiteLebeling.AdultTravellersAssess }}
                </button>
                <button
                  *ngIf=" adultTravelerStatusChecked && !( state.showAssessBtn1 && getAssesseeStatus(0) === 'Pending' ) "
                  [disabled]="!isReassesButtonEnabled(0)"
                  class="btn btn-secondary assess-btn custom-theme-group-two ml-2"
                  (click)="checkAssessOfTralver1('AdultTraveler', 'Reasses')">
                  {{ whiteLebeling.ReassesButtonText }}
                </button>
                <span>
                  <button
                    *ngIf=" (getAssesseeStatus(0) === 'Accepted' || getAssesseeStatus(0) === 'Declined') && needToUpdateMedicalAssessment(0) "
                    (click)="editAdditionalPremium(0)" class="btn btn-secondary assess-btn custom-theme-group-two ml-2">
                    <span *ngIf="isSavingRecord && buttonClicked == '0' && !isSavingForDependent"
                      class="spinner-border text-primary" style="width: 1rem; height: 1rem;" role="status"
                      aria-hidden="false"></span>
                    <span *ngIf="!isSavingRecord || buttonClicked != '0' || isSavingForDependent">Recalculate Medical
                      Premium</span>
                  </button>
                </span>
                <!--after assement is completed expression should be shown  not covered-->
                <span
                  *ngIf=" getAssesseeStatus(0) == 'Not Covered' && (state.Traveler1.FirstName || veriskLoaded == 'Yes')">
                  {{ getAssesseeStatus(0) }} </span>

                <!-- below status will be shown in W2C for question 5 to No -->
                <span
                  *ngIf=" siteName === siteNameEnum.W2C && getAssesseeStatus(0) == 'Condition Covered' && (state.Traveler1.FirstName || veriskLoaded == 'Yes') ">
                  {{ getAssesseeStatus(0) }} </span>
              </div>
            </div>
            <!--additional traveler-->
            <div class="row pt-2" *ngIf="state.Traveler2.FirstName">
              <div class="col-lg-5">
                <div class="para-left">
                  {{ state.Traveler2.FirstName }} {{ state.Traveler2.LastName }}
                </div>
              </div>
              <div class="col-lg-7">
                <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-light" [ngClass]="{ active: additionalTravelerStatusChecked }">
                    <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                      [(ngModel)]="additionalTravelerStatusChecked" (click)="getAssessBtn2('Yes')" />Yes
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                  <label class="btn btn-light" *ngIf="getAssesseeStatus(1) === 'Pending'"
                    [ngClass]="{ active: !additionalTravelerStatusChecked }">
                    <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                      [(ngModel)]="additionalTravelerStatusChecked" (click)="getAssessBtn2('No')" />No
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                </div>
                <span
                  *ngIf=" !state.assessmantStatus1 && !state.showAssessBtn2 && !(getAssesseeStatus(1) && getAssesseeStatus(1) != 'Pending') ">
                  {{ whiteLebeling.PreexistingNo }}
                  {{ whiteLebeling.PreexistingText }}
                </span>

                <button class="ml-2" *ngIf=" state.showAssessBtn2 && getAssesseeStatus(1) === 'Pending' "
                  class="btn btn-secondary assess-btn custom-theme-group-two ml-2"
                  (click)="checkAssessAddTravlr2('AdditionalTraveler')">
                  {{ whiteLebeling.AdultTravellersAssess }}
                </button>
                <button
                  *ngIf=" additionalTravelerStatusChecked && !( state.showAssessBtn2 && getAssesseeStatus(1) === 'Pending' ) "
                  [disabled]="!isReassesButtonEnabled(1)"
                  class="btn btn-secondary assess-btn custom-theme-group-two ml-2"
                  (click)=" checkAssessAddTravlr2('AdditionalTraveler', 'Reasses') ">
                  {{ whiteLebeling.ReassesButtonText }}
                </button>
                <!--after assement is completed expression should be shown-->
                <span>
                  <button
                    *ngIf=" (getAssesseeStatus(1) === 'Accepted' || getAssesseeStatus(1) === 'Declined') && needToUpdateMedicalAssessment(1)"
                    (click)="editAdditionalPremium(1)" class="btn btn-secondary assess-btn custom-theme-group-two ml-2">
                    <span *ngIf="isSavingRecord && buttonClicked == '1' && !isSavingForDependent"
                      class="spinner-border text-primary" style="width: 1rem; height: 1rem;" role="status"
                      aria-hidden="false"></span>
                    <span *ngIf="!isSavingRecord || buttonClicked != '1' || isSavingForDependent">Recalculate Medical
                      Premium</span>
                  </button>
                </span>
                <span *ngIf="getAssesseeStatus(1) == 'Not Covered'">{{ getAssesseeStatus(1) }}</span>

                <!-- below status will be shown in W2C for question 5 to No -->
                <span *ngIf=" siteName === siteNameEnum.W2C && getAssesseeStatus(0) == 'Condition Covered' ">
                  {{ getAssesseeStatus(1) }}
                </span>
              </div>
            </div>
            <hr />

            <!--dependent traveler-->
            <div>
              <h6>
                <b *ngIf="state.Dependents.length != 0">{{
                  whiteLebeling.DependentsHeading
                  }}</b>
              </h6>
              <div class="row pt-2" *ngFor="let dependent of users; let idx = index">
                <div class="col-lg-5 col-xl-5" *ngIf="dependent.FName">
                  <div class="para-left">
                    {{ dependent.FName }} {{ dependent.LName }}
                  </div>
                </div>
                <div class="col-lg-7 col-xl-7" *ngIf="dependent.FName">
                  <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
                    <label class="btn btn-light" [ngClass]="{ active: users[idx].dependentStatusChecked }">
                      <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                        [(ngModel)]="users[idx].dependentStatusChecked" (click)="getAssessBtn3('Yes', idx)" />Yes
                      <span class="d-inline-block cursor" tabindex="0">
                        <i class="fa fa-info info-icon pl-2"></i>
                      </span>
                    </label>

                    <label class="btn btn-light" *ngIf="getAssesseeStatus(idx, true) === 'Pending'"
                      [ngClass]="{ active: !users[idx].dependentStatusChecked }">
                      <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                        [(ngModel)]="users[idx].dependentStatusChecked" (click)="getAssessBtn3('No', idx)" />No
                      <span class="d-inline-block cursor" tabindex="0">
                        <i class="fa fa-info info-icon pl-2"></i>
                      </span>
                    </label>
                  </div>

                  <span *ngIf="
                      !depedentAssessmantStatus[idx] &&
                      !state.showAssessBtn3[idx] &&
                      !(
                        getAssesseeStatus(idx, true) &&
                        getAssesseeStatus(idx, true) != 'Pending'
                      )
                    ">
                    {{ whiteLebeling.PreexistingNo }}
                    {{ whiteLebeling.PreexistingText }}
                  </span>
                  <!-- state.showAssessBtn3 -->

                  <button *ngIf="
                      state.showAssessBtn3[idx] &&
                      !depedentAssessmantStatus[idx] &&
                      getAssesseeStatus(idx, true) === 'Pending'
                    " class="btn btn-secondary assess-btn custom-theme-group-two ml-2"
                    (click)="checkAssessDepTravlr3('DependentTraveler', idx)">
                    {{ whiteLebeling.AdultTravellersAssess }}
                  </button>
                  <button *ngIf="
                      users[idx].dependentStatusChecked &&
                      !(
                        state.showAssessBtn3[idx] &&
                        !depedentAssessmantStatus[idx] &&
                        getAssesseeStatus(idx, true) === 'Pending'
                      )
                    " [disabled]="!isReassesButtonEnabled(idx, true)"
                    class="btn btn-secondary assess-btn custom-theme-group-two ml-2" (click)="
                      checkAssessDepTravlr3('DependentTraveler', idx, 'Reasses')
                    ">
                    {{ whiteLebeling.ReassesButtonText }}
                  </button>
                  <span>
                    <!--<span
                      *ngIf="getAssesseeStatus(idx,true)==='Accepted'">{{whiteLebeling.OfferAccepted}}</span>
                  <span
                      *ngIf="getAssesseeStatus(idx,true)==='Declined'">{{whiteLebeling.OfferDeclined}}</span>-->
                    <button *ngIf="
                        (getAssesseeStatus(idx, true) === 'Accepted' ||
                          getAssesseeStatus(idx, true) === 'Declined') &&
                        needToUpdateMedicalAssessment(idx, true)
                      " (click)="editAdditionalPremium(idx, true)"
                      class="btn btn-secondary assess-btn custom-theme-group-two ml-2">
                      <span *ngIf="isSavingRecord && buttonClicked == idx && isSavingForDependent"
                        class="spinner-border text-primary" style="width: 1rem; height: 1rem;" role="status"
                        aria-hidden="false"></span>
                      <span *ngIf="!isSavingRecord || buttonClicked != idx || !isSavingForDependent">Recalculate Medical
                        Premium</span>
                    </button>
                  </span>

                  <!--after assement is completed expression should be shown-->
                  <span *ngIf="getAssesseeStatus(idx, true) == 'Not Covered'">
                    {{ getAssesseeStatus(idx, true) }}
                  </span>

                  <!-- below status will be shown in W2C for question 5 to No -->
                  <span *ngIf="
                      siteName === siteNameEnum.W2C &&
                      getAssesseeStatus(idx, true) == 'Condition Covered'
                    ">
                    {{ getAssesseeStatus(idx, true) }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!--continue button-->
          <div class="row mt-4">
            <div class="col-md-12" *ngIf="!noEligibleTraveller">
              <button (click)="continueToAssess()" class="btn btn-primary custom-theme-group-two">
                {{ whiteLebeling.ContinuebtnText }}
              </button>
            </div>

            <br />
            <br />
            <div class="col-md-12" *ngIf="state.assesseeValidation">
              {{ whiteLebeling.ContinueAssessmentCompleteTxt }}
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="userSelectionIndex == 2" class="row">
        <div class="col-md-12">
          <h2>{{ whiteLebeling.AssessmentText }} {{ travelerName }}</h2>
          <h6 class="pt-2">{{ whiteLebeling.AssessmentQuestionsText }}</h6>
          <!-- adult travellers section-->
          <div class="box-panel">
            <div class="row pt-2">
              <div class="col-md-9">
                <div class="para-left">{{ whiteLebeling.Question1Text }}</div>
              </div>
              <div class="col-md-3">
                <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-light" [ngClass]="{ active: state.question1Selected }">
                    <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                      [(ngModel)]="state.question1Selected" data-toggle="modal" data-target="#alert-confrim-q1"
                      (click)="changeSelection(1, 'Yes')" />Yes
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                  <label class="btn btn-light" [ngClass]="{ active: !state.question1Selected }">
                    <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                      [(ngModel)]="state.question1Selected" (click)="changeSelection(1, 'No')" />No
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <div class="row pt-2">
              <div class="col-md-9">
                <div class="para-left">
                  {{ whiteLebeling.Question2Text }}
                </div>
              </div>
              <div class="col-md-3">
                <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-light" [ngClass]="{ active: state.question2Selected }">
                    <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                      [(ngModel)]="state.question2Selected" data-toggle="modal" data-target="#alert-confrim-q2"
                      (click)="changeSelection(2, 'Yes')" />Yes
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                  <label class="btn btn-light" [ngClass]="{ active: !state.question2Selected }">
                    <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                      [(ngModel)]="state.question2Selected" (click)="changeSelection(2, 'No')" />No
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <hr />

            <div class="row pt-2">
              <div class="col-md-9">
                <div class="para-left" [innerHTML]="sameAsHtml(whiteLebeling.Question3Text)"></div>
              </div>
              <div class="col-md-3">
                <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-light" [ngClass]="{ active: state.question3Selected }">
                    <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                      [(ngModel)]="state.question3Selected" (click)="changeSelection(3, 'Yes')" data-trigger="hover"
                      data-toggle="modal" data-target="#alert-confrim" />Yes
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                  <label class="btn btn-light" [ngClass]="{ active: !state.question3Selected }">
                    <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                      [(ngModel)]="state.question3Selected" (click)="changeSelection(3, 'No')" />No
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>
            <hr />
            <div class="row pt-2">
              <div class="col-md-9">
                <div class="para-left" [innerHTML]="sameAsHtml(whiteLebeling.Question4Text)"></div>
              </div>
              <div class="col-md-3">
                <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-light" [ngClass]="{ active: state.question4Selected }">
                    <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                      [(ngModel)]="state.question4Selected" (click)="changeSelection(4, 'Yes')" />Yes
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                  <label class="btn btn-light" [ngClass]="{ active: !state.question4Selected }">
                    <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                      [(ngModel)]="state.question4Selected" (click)="changeSelection(4, 'No')" />No
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div>

            <!-- Question no. 5 will be only shown in case of W2C -->
            <!-- <div class="row pt-2" *ngIf="siteName === siteNameEnum.W2C">
              <div class="col-md-9">
                <div class="para-left" [innerHTML]="sameAsHtml(whiteLebeling.Question5Text)"></div>
              </div>
              <div class="col-md-3">
                <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
                  <label class="btn btn-light" [ngClass]="{'active':state.question5Selected}">
                    <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                      [(ngModel)]="state.question5Selected" (click)="changeSelection(5,'Yes')">Yes
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                  <label class="btn btn-light" [ngClass]="{'active':!state.question5Selected}">
                    <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                      [(ngModel)]="state.question5Selected" (click)="changeSelection(5,'No')">No
                    <span class="d-inline-block cursor" tabindex="0">
                      <i class="fa fa-info info-icon pl-2"></i>
                    </span>
                  </label>
                </div>
              </div>
            </div> -->
          </div>

          <p *ngIf="showResult.showAssessmantresult3" style="color: red">
            {{ whiteLebeling.ReviewValidationText }}
          </p>

          <p *ngIf="showResult.showAssessmantresult4" style="color: red">
            {{ whiteLebeling.ReviewTextW2C }}
          </p>
          <p *ngIf="showResult.showAssessmantresult5" style="color: red">
            {{ whiteLebeling.ConfirmDeclarationTextW2C }}
          </p>

          <!--continue button-->
          <div class="row mt-4">
            <div class="col-4">
              <button [disabled]="isDeclarationContinueDisabled() && !isSavingRecord" (click)="continueToNextTraveler()"
                class="btn btn-primary custom-theme-group-two">
                  <span *ngIf="isSavingRecord" class="spinner-border text-primary"
                  style="width: 1rem; height: 1rem;" role="status" aria-hidden="false"></span>
                  <span *ngIf="!isSavingRecord">{{ whiteLebeling.NextTravellerBtnText }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="userSelectionIndex == 4">
        <div class="privacy box-panel policyMessges">
          <h1>
            <!-- {{whiteLebeling.ResultFor ? whiteLebeling.ResultFor:'Result for '}} -->
            Assement result for {{ travelerName }}
          </h1>
          <p *ngIf="showResult.showAssessmantresult1">
            {{ whiteLebeling.ShowAssessmantResult1Text }}
          </p>
          <p *ngIf="showResult.showAssessmantresult2">
            {{ whiteLebeling.ShowAssessmantresult2Text }}
          </p>
        </div>
        <button class="btn btn-primary custom-theme-group-two" (click)="goToMedicalCondition()">
          {{ whiteLebeling.ReturnToMedicalScreeningText }}
        </button>
      </div>

      <div *ngIf="userSelectionIndex == 5 && !state.isVeriskError">
        <div class="privacy box-panel policyMessges">
          <p *ngIf="medicalAssessmentStatus !== 'NOTCOVERED'">
            {{ whiteLebeling.DeclaredMedicalCondition }}
            {{ state.selectedAssesee }}:
          </p>
          <!-- <p *ngIf="medicalAssessmentStatus==='NOTCOVERED'">
          {{whiteLebeling.DefaultResultTxt}}
        </p> -->
          <ul *ngIf="
              medicalAssessmentStatus === 'ACCEPTDECLINED' &&
              state.additionalPremium > 0
            ">
            <li *ngFor="let condition of state.medicalConditions">
              {{ condition }}
            </li>
          </ul>
          <div *ngIf="medicalAssessmentStatus === 'NOTCOVERED'">
            <!-- {{ whiteLebeling.NotCoveredResultTxt1 }} -->
            <ul>
              <li *ngFor="let condition of state.medicalConditions">
                {{ condition }}
              </li>
            </ul>
            Unfortunately the Medical Condition(s) you have declared cannot be
            covered. If you choose to purchase this policy, you will not be covered
            for any event arising from or related to this Medical Condition(s).
          </div>

          <div *ngIf="
              medicalAssessmentStatus === 'ACCEPTDECLINED' &&
              state.additionalPremium == 0
            ">
            <ul>
              <li *ngFor="let condition of state.medicalConditions">
                {{ condition }}
              </li>
            </ul>
            <p>{{ whiteLebeling.ZeroPremiumAutoAccept }}</p>
          </div>
          <div *ngIf="
              medicalAssessmentStatus === 'ACCEPTDECLINED' &&
              state.additionalPremium > 0
            ">
            <p>
              {{ whiteLebeling.IndicateAdditionalPremium }}
            </p>
            <b>
              {{ whiteLebeling.AdditionalPremium }}: ${{
              state.additionalPremium | number: '2.2-2'
              }}
            </b>
            <br />
            <sub *ngIf="medicalAssessmentStatus === 'ACCEPTDECLINED'">{{
              whiteLebeling.AfterTaxAndDiscount
              }}</sub>
          </div>
        </div>
        <div *ngIf="
            medicalAssessmentStatus === 'ACCEPTDECLINED' &&
            isMedicalScoreVisible === 'true'
          " style="color: red">
          <b>
            Medical Score :
            {{ state.medicalScore | number: '2.2-2' }}
          </b>
          <br />
        </div>
        <div class="row mt-4" *ngIf="medicalAssessmentStatus === 'ACCEPTDECLINED'">
          <div class="col-md-12">

            <button type="button" [disabled]="isSavingRecord" (click)="acceptMedicalAssesment('Accepted')"
              class="btn btn-secondary custom-theme-group-two">
              <span *ngIf="isSavingRecord && buttonClicked == 'Accepted'" class="spinner-border text-primary"
                style="width: 1rem; height: 1rem;" role="status" aria-hidden="false"></span>
              <span *ngIf="!isSavingRecord || buttonClicked != 'Accepted'">{{ whiteLebeling.Accept }}</span>
            </button>&nbsp;&nbsp;

            <button type="button" [disabled]="isSavingRecord" (click)="acceptMedicalAssesment('Declined')"
                    *ngIf="state.additionalPremium >= 0" class="btn btn-secondary custom-theme-group-two decline-button">
                <span *ngIf="isSavingRecord && buttonClicked == 'Declined'" class="spinner-border text-primary"
                      style="width: 1rem; height: 1rem;" role="status" aria-hidden="false"></span>
                <span *ngIf="!isSavingRecord || buttonClicked != 'Declined'">{{ whiteLebeling.Decline }}</span>
                <span class="tooltip-text" id="right">{{declineToolTipText}}</span>
            </button>
            

            <!-- <button type="button" [disabled]="isSavingRecord" (click)="acceptMedicalAssesment('Declined')"
              *ngIf="state.additionalPremium >= 0" class="btn btn-secondary custom-theme-group-two" data-toggle="tooltip" data-placement="right" title="Tooltip on right">
              <span *ngIf="isSavingRecord && buttonClicked == 'Declined'" class="spinner-border text-primary"
                style="width: 1rem; height: 1rem;" role="status" aria-hidden="false"></span>
              <span *ngIf="!isSavingRecord || buttonClicked != 'Declined'">{{ whiteLebeling.Decline }}</span>
            </button> -->
          </div>
        </div>

        <!-- below div needs to be shown when status is equal to Not Covered -->
        <div class="row mt-4" *ngIf="medicalAssessmentStatus === 'NOTCOVERED'">
          <div class="col-md-12">
            <button class="btn btn-primary custom-theme-group-two" (click)="updateMedicalConditionWhenNotCovered()">
              {{ whiteLebeling.updateMedicalAssessment }}
            </button>
            &nbsp;&nbsp;
            <button type="button" (click)="goToMedicalConditionAfterVerisk()" *ngIf="state.additionalPremium >= 0"
              class="btn btn-secondary custom-theme-group-two">
              OK
            </button>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="userSelectionIndex==5 && state.isVeriskError">
        <div class="alert font-12" class="alert-danger" style="max-height: 100px;overflow: auto;">
            {{state.veriskError}}
        </div>

        <button (click)="returnToHome()" class="btn btn-secondary custom-theme-group-two">
            {{whiteLebeling.ReturnToHome}}
        </button>
    </div> -->
      <!-- result of assessment questions for traveller -->

      <div *ngIf="userSelectionIndex == 6">
        <div class="privacy box-panel policyMessges">
          <h1>
            {{
            whiteLebeling.ResultFor ? whiteLebeling.ResultFor : 'Result for '
            }}
            {{ travelerName }}
          </h1>
          <p>
            {{ whiteLebeling.DefaultResultTxt }}
          </p>
          <p [innerHTML]="whiteLebeling.ShowAssessmantresult2Text"></p>
        </div>
        <button class="btn btn-primary custom-theme-group-two" (click)="goToMedicalCondition()">
          {{ whiteLebeling.ReturnToMedicalScreeningText }}
        </button>
      </div>

      <!-- below div will be shown for W2C condition covered -->
      <div *ngIf="userSelectionIndex == 8">
        <div class="privacy box-panel policyMessges">
          <h1>
            {{
            whiteLebeling.ResultFor ? whiteLebeling.ResultFor : 'Result for '
            }}
            {{ travelerName }}
          </h1>

          <p [innerHTML]="whiteLebeling.ShowAssessmantresult3Text"></p>
        </div>
        <button class="btn btn-primary custom-theme-group-two" (click)="goToMedicalCondition()">
          {{ whiteLebeling.ReturnToMedicalScreeningText }}
        </button>
      </div>
    </div>
    <div class="row">
      <div *ngIf="userSelectionIndex == 7" class="col-md-12">
        <div class="privacy box-panel policyMessges">
          <p>{{ whiteLebeling.NotEligibleTxt }}</p>

          <div>
            <p>{{ whiteLebeling.TravellerExcludedTxt }}</p>
            <p>
              {{ state.Traveler1.FirstName }} {{ state.Traveler1.LastName }}
              {{ whiteLebeling.TravellerAnswerYesTo }}
            </p>
            <ul>
              <li *ngIf="adultTravelerQuestionStatus.question1 === 'Yes'">
                {{ whiteLebeling.TravelerQuestion1Txt }}
              </li>
              <li *ngIf="adultTravelerQuestionStatus.question2 === 'Yes'">
                {{ whiteLebeling.TravelerQuestion2Txt }}
              </li>
              <li *ngIf="adultTravelerQuestionStatus.question3 == 'Yes'">
                {{ whiteLebeling.TravelerQuestion3Txt }}
              </li>
            </ul>
          </div>

          <div *ngIf="noEligibleTraveller && state.Traveler2.FirstName">
            <p>{{ whiteLebeling.TravellerExcludedTxt }}</p>
            <p>
              {{ state.Traveler2.FirstName }} {{ state.Traveler2.LastName }}
              {{ whiteLebeling.TravellerAnswerYesTo }}
            </p>
            <ul>
              <li *ngIf="AdditionalTravelerQuestionStatus.question1 === 'Yes'">
                {{ whiteLebeling.TravelerQuestion1Txt }}
              </li>
              <li *ngIf="AdditionalTravelerQuestionStatus.question2 === 'Yes'">
                {{ whiteLebeling.TravelerQuestion2Txt }}
              </li>
              <li *ngIf="AdditionalTravelerQuestionStatus.question3 == 'Yes'">
                {{ whiteLebeling.TravelerQuestion3Txt }}
              </li>
            </ul>
          </div>
        </div>
        <div *ngIf="noEligibleTraveller">
          <button (click)="returnToHome()" class="btn btn-primary custom-theme-group-two">
            {{ whiteLebeling.ReturnToHome }}
          </button>
        </div>
      </div>
    </div>

    <div class="modal fade alert-confrim" id="alert-confrim" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-gray">
          <!-- <div class="modal-header py-1">
            <h5 class="modal-title"></h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div class="close-btn-wrap py-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <!-- <div class="close-btn-wrap">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                      </button>
                  </div> -->
          <div class="modal-body">
            {{ whiteLebeling.ConfirmAlertText }}
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade alert-confrim" id="alert-confrim-q2" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-gray">
          <!-- <div class="modal-header py-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div class="close-btn-wrap">
            <button type="button" class="close py-1" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" *ngIf="siteName !== siteNameEnum.W2C">
            {{ whiteLebeling.confirmAlertQ2 }}
          </div>
          <div class="modal-body" *ngIf="siteName === siteNameEnum.W2C">
            {{ whiteLebeling.Question2ToolTipText }}
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade alert-confrim" id="alert-confrim-q1" tabindex="0" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content bg-gray">
          <!-- <div class="modal-header py-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div> -->
          <div class="close-btn-wrap py-1">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" *ngIf="siteName !== siteNameEnum.W2C">
            {{ whiteLebeling.confirmAlertQ1Part1 }} <br />
            {{ whiteLebeling.confirmAlertQ1Part2 }} <br />
            {{ whiteLebeling.confirmAlertQ1Part3 }}
          </div>
          <div class="modal-body" *ngIf="siteName === siteNameEnum.W2C">
            {{ whiteLebeling.Question1ToolTipText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancelAlertTemplate>
  <div *ngIf="isLoader">
    <div class="clearfix"></div>
    <div class="overlay-custom-container"></div>
    <div class="loader"></div>
  </div>
  <div class="" id="myModal-11">
    <div class="modal-body">
      <div class="col-md-10 mx-auto text-center">
        <!-- <div *ngIf="cancelMessageSuccess" class="alert alert-success my-2">{{cancelMessageSuccess}}</div> -->
        <div *ngIf="state.isVeriskError" class="alert alert-warning my-4 text-break">
          {{ state.veriskError }}
        </div>
      </div>
    </div>
    <div class="text-center mb-4 btn-footer">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeAlertChildModal()">
        Close
      </button>
    </div>
  </div>
</ng-template>

<ng-template #warningAlertTemplate>
  <div class="" id="myModal-11">
    <div class="modal-body">
      <div class="col-md-10 mx-auto text-center">
        <div class="alert alert-warning my-4 text-break">
          {{ whiteLebeling.CarRentalNotificationText }}
        </div>
      </div>
    </div>
    <div class="text-center mb-4 btn-footer">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeWarningChildModal()">
        Ok
      </button>
    </div>
  </div>
</ng-template>

<ng-template #viewContentTemplate>
  <div class="modal-header">
    <a href="javascript:;" aria-label="Close" (click)="closeViewModal()">
      <i class="fas fa-arrow-alt-circle-left ml-3 mr-2 font-size-20"></i>
      <span class="font-size-20">Return to Policy Summary</span>
    </a>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <h2>{{ whiteLebeling.AssessmentText }} {{ viewtravelerName }}</h2>
      <h6 class="pt-2">{{ whiteLebeling.AssessmentQuestionsText }}</h6>
      <!-- adult travellers section-->
      <div class="box-panel">
        <div class="row pt-2">
          <div class="col-md-9">
            <div class="para-left">{{ whiteLebeling.Question1Text }}</div>
          </div>
          <div class="col-md-3">
            <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-light" [ngClass]="{ active: state.question1Selected }">
                <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                  [(ngModel)]="state.question1Selected" data-toggle="modal" data-target="#alert-confrim-q1"
                  (click)="changeSelection(1, 'Yes')" />Yes
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
              <label class="btn btn-light" [ngClass]="{ active: !state.question1Selected }">
                <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                  [(ngModel)]="state.question1Selected" (click)="changeSelection(1, 'No')" />No
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
            </div>
          </div>
        </div>
        <hr />
        <div class="row pt-2">
          <div class="col-md-9">
            <div class="para-left">
              {{ whiteLebeling.Question2Text }}
            </div>
          </div>
          <div class="col-md-3">
            <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-light" [ngClass]="{ active: state.question2Selected }">
                <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                  [(ngModel)]="state.question2Selected" data-toggle="modal" data-target="#alert-confrim-q2"
                  (click)="changeSelection(2, 'Yes')" />Yes
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
              <label class="btn btn-light" [ngClass]="{ active: !state.question2Selected }">
                <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                  [(ngModel)]="state.question2Selected" (click)="changeSelection(2, 'No')" />No
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
            </div>
          </div>
        </div>
        <hr />

        <div class="row pt-2">
          <div class="col-md-9">
            <div class="para-left" [innerHTML]="sameAsHtml(whiteLebeling.Question3Text)"></div>
          </div>
          <div class="col-md-3">
            <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-light" [ngClass]="{ active: state.question3Selected }">
                <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                  [(ngModel)]="state.question3Selected" (click)="changeSelection(3, 'Yes')" data-trigger="hover"
                  data-toggle="modal" data-target="#alert-confrim" />Yes
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
              <label class="btn btn-light" [ngClass]="{ active: !state.question3Selected }">
                <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                  [(ngModel)]="state.question3Selected" (click)="changeSelection(3, 'No')" />No
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
            </div>
          </div>
        </div>
        <hr />
        <div class="row pt-2">
          <div class="col-md-9">
            <div class="para-left" [innerHTML]="sameAsHtml(whiteLebeling.Question4Text)"></div>
          </div>
          <div class="col-md-3">
            <div class="blue-toggle btn-group btn-group-toggle" data-toggle="buttons">
              <label class="btn btn-light" [ngClass]="{ active: state.question4Selected }">
                <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
                  [(ngModel)]="state.question4Selected" (click)="changeSelection(4, 'Yes')" />Yes
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
              <label class="btn btn-light" [ngClass]="{ active: !state.question4Selected }">
                <input type="radio" name="options" id="option2" autocomplete="off" [value]="false"
                  [(ngModel)]="state.question4Selected" (click)="changeSelection(4, 'No')" />No
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
            </div>
          </div>
        </div>

        <!-- Question no. 5 will be only shown in case of W2C -->
        <!-- <div class="row pt-2" *ngIf="siteName === siteNameEnum.W2C">
          <div class="col-md-9">
            <div
              class="para-left"
              [innerHTML]="sameAsHtml(whiteLebeling.Question5Text)"
            ></div>
          </div>
          <div class="col-md-3">
            <div
              class="blue-toggle btn-group btn-group-toggle"
              data-toggle="buttons"
            >
              <label
                class="btn btn-light"
                [ngClass]="{ active: state.question5Selected }"
              >
                <input
                  type="radio"
                  name="options"
                  id="option1"
                  autocomplete="off"
                  [value]="true"
                  [(ngModel)]="state.question5Selected"
                  (click)="changeSelection(5, 'Yes')"
                />Yes
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
              <label
                class="btn btn-light"
                [ngClass]="{ active: !state.question5Selected }"
              >
                <input
                  type="radio"
                  name="options"
                  id="option2"
                  autocomplete="off"
                  [value]="false"
                  [(ngModel)]="state.question5Selected"
                  (click)="changeSelection(5, 'No')"
                />No
                <span class="d-inline-block cursor" tabindex="0">
                  <i class="fa fa-info info-icon pl-2"></i>
                </span>
              </label>
            </div>
          </div>
        </div> -->
      </div>

      <p *ngIf="showResult.showAssessmantresult3" style="color: red">
        {{ whiteLebeling.ReviewValidationText }}
      </p>

      <p *ngIf="showResult.showAssessmantresult4" style="color: red">
        {{ whiteLebeling.ReviewTextW2C }}
      </p>
      <p *ngIf="showResult.showAssessmantresult5" style="color: red">
        {{ whiteLebeling.ConfirmDeclarationTextW2C }}
      </p>

      <!--continue button-->
      <!-- <div class="row mt-4">
        <div class="col-4">
          <button (click)="continueToNextTraveler()" class="btn btn-primary custom-theme-group-two">
            {{whiteLebeling.NextTravellerBtnText}}
          </button>
        </div>

      </div> -->
    </div>
  </div>
  <div class="modal-footer align-items-center justify-content-center">
    <div class="row">
      <div class="col-sm-12">
        <button type="button" class="btn btn-secondary btn-full-sm mx-auto" (click)="closeViewModal()">
          Close
        </button>
      </div>
    </div>
  </div>
</ng-template>