import { Injectable } from '@angular/core';
import { isMoment, Moment, isDate } from 'moment';
// import * as moment from 'moment-timezone';
//import moment from 'moment';
import moment from 'moment-timezone';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  readonly defaultTimezone = 'Australia/Sydney';

  public constructor() {
    //setting up the default timezone
    moment().tz(this.defaultTimezone);
  }

  public now(): Moment {
    return moment();
  }

  public isMoment(_moment: any): boolean {
    return isMoment(_moment) ? _moment.isValid() : false;
  }

  public isValidDate(date: string | Date | Moment): boolean {
    try {
      return this.getMoment(date).isValid();
    } catch (error) {
      return false;
    }
  }

  public getMomentFromDDMMYYYY(dateDDMMYYYY: string): Moment {
    if (
      !dateDDMMYYYY ||
      typeof dateDDMMYYYY !== 'string' ||
      dateDDMMYYYY.length > 10
    )
      this.throwErrorInvalidFormat(dateDDMMYYYY);

    const _moment = moment(dateDDMMYYYY, 'DD/MM/YYYY');

    this.throwErrorIfNotAValidMoment(_moment);

    return _moment;
  }

  public getMomentFromDate(date: Date | string): Moment {
    return this.getMoment(date);
  }

  public getMoment(date: string | Date | Moment | any): Moment {
    this.throwErrorIfNull(date);

    let _moment;

    if (typeof date === 'string') {
      if (date.length <= 10) _moment = this.getMomentFromDDMMYYYY(date);
      else _moment = moment(date);
    } else if (date instanceof Date) {
      if (isNaN(date.getDate())) this.throwErrorInvalidDate(date);

      _moment = moment(date);
    } else if (this.isMoment(date)) {
      _moment = date;
    } else {
      this.throwErrorInvalidFormat(date);
    }

    this.throwErrorIfNotAValidMoment(_moment);

    return _moment;
  }

  public getDDMMYYYYFromDate(date: Date): string {
    return this.toDDMMYYYY(this.getMomentFromDate(date));
  }

  public getDateAsOfAESTFromDate(date: string | Date | Moment): Date {
    this.throwErrorIfNull(date);

    const _moment = this.getMoment(date);
    const _aestMoment = _moment.startOf('days');
    const _aestDate = new Date(
      _aestMoment.year(),
      _aestMoment.month(),
      _aestMoment.date(),
    );
    return _aestDate;
  }

  public formatDefault(_moment: Moment) {
    this.throwErrorIfNotAValidMoment(_moment);

    return this.toISO(_moment);
  }

  public toISO(_moment: Moment): string {
    this.throwErrorIfNotAValidMoment(_moment);

    return _moment.format();
  }

  public toDDMMYYYY(_moment: Moment): string {
    this.throwErrorIfNotAValidMoment(_moment);

    return _moment.format('DD/MM/YYYY');
  }

  public toDate(_moment: Moment): Date {
    this.throwErrorIfNotAValidMoment(_moment);

    return _moment.toDate();
  }

  public toLocalISO(_moment: Moment) {
    this.throwErrorIfNotAValidMoment(_moment);

    return this.toISO(_moment.local());
  }

  public toLocalDDMMYYYY(_moment: Moment) {
    this.throwErrorIfNotAValidMoment(_moment);

    return this.toDDMMYYYY(_moment.local());
  }

  public toLocalDate(_moment: Moment) {
    this.throwErrorIfNotAValidMoment(_moment);

    return this.toDate(_moment.local());
  }

  public ageInYears(
    dob: string | Date | Moment,
    pricingDate: string | Date | Moment,
  ) {
    if (!dob || !pricingDate) this.throwErrorInvalidFormat(dob);

    const dobMoment = this.getMoment(this.toDDMMYYYY(this.getMoment(dob)));
    const pricingDateMoment = this.getMoment(
      this.toDDMMYYYY(this.getMoment(pricingDate)),
    );

    const age = pricingDateMoment.diff(dobMoment, 'years');

    if (age < 0) return 0;

    return age;
  }

  public ageInMonths(dobDDMMYYYY: string) {
    if (!dobDDMMYYYY) this.throwErrorInvalidFormat(dobDDMMYYYY);

    const age = this.now().diff(
      this.getMomentFromDDMMYYYY(dobDDMMYYYY),
      'months',
    );

    if (age < 0) return 0;

    return age;
  }

  public getDateByFormat(dob: string | Date | Moment){
    const dobMoment = this.getMoment(this.toDDMMYYYY(this.getMoment(dob)));
    return dobMoment;
  }

  // public diffInYears(input: string | Date | Moment) {
  //     if (!input)
  //         this.throwErrorInvalidFormat(input);

  //     // const _moment = this.getMomentFromDDMMYYYY(dobDDMMYYYY);
  //     //const now = this.now();
  //     //const age = now.diff(_moment, 'years');

  //     const age = this.now().diff(this.getMomentFromDDMMYYYY(input), 'years');

  //     if (age < 0)
  //         return 0;

  //     return age;
  // }

  // public ageInMonths(input: string | Date | Moment) {
  //     if (!input)
  //         this.throwErrorInvalidFormat(dobDDMMYYYY);

  //     const age = this.now().diff(this.getMomentFromDDMMYYYY(dobDDMMYYYY), 'months');

  //     if (age < 0)
  //         return 0;

  //     return age;
  // }

  //#region Error Handling
  private throwErrorIfNull(value: any): void {
    if (!value) {
      this.throwErrorNullArgument();
    }
  }

  private throwErrorIfNotAValidMoment(_moment: Moment): void {
    if (!this.isMoment(_moment)) {
      this.throwErrorInvalidDate(_moment);
    }
  }

  private throwErrorInvalidDate(obj: any): void {
    this.throwError(this.Errors.InvalidDate, obj);
  }

  private throwErrorInvalidFormat(obj: any): void {
    this.throwError(this.Errors.InvalidFormat, obj);
  }

  private throwErrorNullArgument(): void {
    this.throwError(this.Errors.NullArgument, '');
  }

  private throwError(errorCode: string, obj: string | object) {
    if (!errorCode) errorCode = this.Errors.Unknown;

    if (!obj) obj = 'EMPTY MESSAGE.';

    let message;
    let dataType;

    if (typeof obj === 'string') {
      dataType = 'string';
      message = obj;
    } else if (obj instanceof Date) {
      dataType = 'Date';
      message = obj.toString();
    } else if (isMoment(obj)) {
      dataType = 'Moment';
      message = obj.toString();
    } else {
      dataType = 'unknown';
      this.throwErrorInvalidFormat(JSON.stringify(obj));
    }

    throw new Error(`${obj} ERROR MESSAGE: [${dataType}]: ${message}`);
  }

  private readonly Errors = {
    InvalidDate: 'Not a valid date.',
    InvalidFormat: 'Not a valid date format.',
    NullArgument: 'Null argument.',
    Unknown: 'Unknown error.',
  };
  //#endregion
}
