<div class="container">
  <div class="row">
    <div class="col-md-12 col-lg-6 mt-4">
      <policyDetails-policy-details-policy [isPremium]="true" [isEditBtnRequired]="false"
        [premiumChanged]="premiumChanged" [versionMovementGuid]="movementGuid"></policyDetails-policy-details-policy>
      <policyDetails-policy-details-trip [isEditBtnRequired]="false"></policyDetails-policy-details-trip>
      <policyDetails-policy-details-travel-care [isEditBtnRequired]="false"> </policyDetails-policy-details-travel-care>
      <policyDetails-policy-details-travellers [isEditBtnRequired]="false"> </policyDetails-policy-details-travellers>
      <policyDetails-policy-details-dependents [isEditBtnRequired]="false"> </policyDetails-policy-details-dependents>
      <policyDetails-policy-details-pre-existing [isEditBtnRequired]="false">
      </policyDetails-policy-details-pre-existing>
    </div>
    <div class="col-md-12 col-lg-6 mt-2">
      <policyDetails-policy-details-left-menu [isResendCoi]="true" [movementGuid]="movementGuid">
      </policyDetails-policy-details-left-menu>
      <div class="mt-4 custom-accordion">
        <policyDetails-policy-details-notes [isNotesRequired]="false"
          [versionMovementGuid]="movementGuid"></policyDetails-policy-details-notes>
        <policyDetails-policy-details-summary [isPromocode]="false"></policyDetails-policy-details-summary>
        <div class="mt-4">
          <policyDetails-policy-details-agents [isEmail]="false" [versionMovementGuid]="movementGuid"></policyDetails-policy-details-agents>
        </div>
        <!-- Payment -->
        <div class="mt-4">
          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button class="btn btn-link accordion-note w-100 text-left" style="pointer-events: none;">
                    <span data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" style="pointer-events: auto;"
                      aria-controls="collapseOne" (click)="openPolicyPayment('Purchase')">
                      <i [ngClass]="{ rotate0: !isPolicyPaymentCollapsed, rotate90: isPolicyPaymentCollapsed }"
                        class="fas fa-chevron-circle-right mr-2 rotate90"></i>Payments
                    </span>
                    <span class="text-right" data-toggle="tooltip" data-placement="top" title="Add Payment">
                      <i class="fa fa-plus-circle float-right mt-1" aria-hidden="true"  style="pointer-events: auto;"
                        (click)=" addPayment( paymentdatalist ? paymentdatalist.length : 0 ) "></i>
                    </span>
                  </button>
                </h5>
              </div>
              <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                  <table id="datatable1" class="table table-striped table-bordered table-dashboard" style="width: 100%"
                    *ngIf="paymentdatalist!=undefined && paymentdatalist.length>0">
                    <thead>
                      <tr>
                        <th>Trans Id</th>
                        <th>PolicyTxnId</th>
                        <th>Successful</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of paymentdatalist">
                        <td class="text-right">{{ item.displayPaymentRefNumber }}</td>
                        <!-- <td>{{ item.movementId }}</td> -->
                        <td class="text-center">
                          <span><i class="fas fa-info-circle" title="{{ item.movementId }}"></i></span>
                        </td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.methodType }}</td>
                        <td> {{ item.amount ? (item.amount | currency: 'USD') : '' }} </td>
                        <td>
                          <a href="javascript:void(0);">
                            <i class="fas fa-edit" aria-hidden="true" data-toggle="tooltip" data-placement="top"
                              title="Edit Payment" (click)="editPayment(item)"></i>
                          </a>
                          <a href="javascript:void(0);">
                            <i *ngIf="paymentdatalist.length > 1" class="fa fa-trash" aria-hidden="true"
                              style="margin: 6px" data-toggle="tooltip" data-placement="top" title="Delete Payment"
                              (click)="deletePayment(item)"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-center"
                    *ngIf='paymentdatalist==undefined || paymentdatalist.length==0'>
                    <span [ngClass]="{
                      'spinner-border text-primary': isPaymentLoader
                    }" role="status" aria-hidden="false"></span>
                  </div>
                  <div class="col-12" [hidden]="!isMessageVisible">
                    <div class="alert alert-success mt-3" role="alert">
                      {{ msg }}
                    </div>
                  </div>
                  <div class="col-12" [hidden]="!isMessageVisiblePayment">
                    <div class="alert alert-success mt-3" role="alert">
                      {{ paymentMsg }}
                    </div>
                  </div>
                  <div class="col-12" [hidden]="!isMessageVisibleDeletePayment">
                    <div class="alert alert-success mt-3" role="alert">
                      {{ paymentDeleteMsg }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Refund -->
        <div class="mt-4">
          <div id="accordion1">
            <div class="card">
              <div class="card-header" id="headingOne1">
                <h5 class="mb-0">
                  <button class="btn btn-link accordion-note w-100 text-left" style="pointer-events: none;">
                    <span data-toggle="collapse" data-target="#collapseOne1" aria-expanded="false"
                      aria-controls="collapseOne1" style="pointer-events: auto;"  (click)="openPolicyPayment('Refund')">
                      <i [ngClass]="{ rotate0: !isPolicyRefundCollapsed, rotate90: isPolicyRefundCollapsed }"
                        class="fas fa-chevron-circle-right mr-2"></i>Refunds
                    </span>
                    <span class="text-right" data-toggle="tooltip" data-placement="top" title="Add Refund">
                      <i class="fa fa-plus-circle float-right mt-1" aria-hidden="true"  style="pointer-events: auto;"
                        (click)=" createRefund( refunddatalist ? refunddatalist.length : 0 ) "></i>
                    </span>
                  </button>
                </h5>
              </div>
              <div id="collapseOne1" class="collapse" aria-labelledby="headingOne1" data-parent="#accordion1">
                <div class="card-body">
                  <table *ngIf="refunddatalist.length > 0" id="datatable1"
                    class="table table-striped table-bordered table-dashboard" style="width: 100%">
                    <thead>
                      <tr>
                        <th>Trans id</th>
                        <th>PolicyTxnId</th>
                        <th>Successful</th>
                        <th>Type</th>
                        <th>Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of refunddatalist">
                        <td class="text-right">
                          {{ item.displayPaymentRefNumber }}
                        </td>
                        <!-- <td>{{ item.movementId }}</td> -->
                        <td class="text-center">
                          <span><i class="fas fa-info-circle" title="{{ item.movementId }}"></i></span>
                        </td>
                        <td>{{ item.status }}</td>
                        <td>{{ item.methodType }}</td>
                        <td>
                          {{
                          item.amount
                          ? (item.amount | currency: 'USD')
                          : ''
                          }}
                        </td>

                        <td>
                          <a href="javascript:void(0);">
                            <i class="fas fa-edit" aria-hidden="true" data-toggle="tooltip" data-placement="top"
                              title="Edit Refund" (click)="editRefund(item)"></i>
                          </a>
                          <a href="javascript:void(0);">
                            <i class="fa fa-trash" aria-hidden="true" style="margin: 6px" data-toggle="tooltip"
                              data-placement="top" title="Delete Payment" (click)="deleteRefundOpen(item)"></i>
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="text-center"
                    *ngIf='refunddatalist==undefined || refunddatalist.length==0'>
                    <span [ngClass]="{
                      'spinner-border text-primary': isRefundLoader
                    }" role="status" aria-hidden="false"></span>
                  </div>
                  <tr *ngIf="refunddatalist.length == 0">
                    No data found
                  </tr>

                  <div class="col-12" [hidden]="!isMessageVisibleRefund">
                    <div class="alert alert-success mt-3" role="alert">
                      {{ refundMsg }}
                    </div>
                  </div>
                  <div class="col-12" [hidden]="!isMessageVisibleRefundCreate">
                    <div class="alert alert-success mt-3" role="alert">
                      {{ refundcreateMsg }}
                    </div>
                  </div>
                  <div class="col-12" [hidden]="!isMessageVisibleDeleteRefund">
                    <div class="alert alert-success mt-3" role="alert">
                      {{ refundDeleteMsg }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #dialogContentTemplate>
    <h2 *ngIf="!showCnfrmation" class="mt-4 mb-3 box-label" style="margin: 300px">
      Edit Payment
    </h2>
    <form *ngIf="!showCnfrmation" [formGroup]="paymentForm"
      (ngSubmit)="openNotes(rowHiddenField.value, 'PaymentType', 'Update')">
      <div class="modal-body">
        <div class="form-row mt-3">
          <div class="form-group col-sm-6 col-md-4">
            <span>TransactionId</span>
            <input type="text" class="form-control" id="Email" formControlName="TransactionId"
              placeholder="Payment TransactionId " [ngClass]="{
                'is-invalid': editPaymentSubmitted && f['TransactionId'].errors
              }" />
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <span>Type</span>
            <input type="text" class="form-control" id="FirstName" formControlName="Type"
              placeholder="Payment Successful" [ngClass]="{ 'is-invalid': f['Type'].errors }" />
            <div *ngIf="editPaymentSubmitted && f['Type'].errors" class="invalid-feedback">
              <div *ngIf="f['Type'].errors?.['required']">
                Please enter the Payment Type.
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <span>Successful Status</span>
            <input type="text" class="form-control" id="LastName" formControlName="Successful"
              placeholder="Payment Type" [ngClass]="{
                'is-invalid': editPaymentSubmitted && f['Successful'].errors
              }" />
            <div *ngIf="editPaymentSubmitted && f['Successful'].errors" class="invalid-feedback">
              <div *ngIf="f['Successful'].errors?.['required']">
                Please enter the Payment Type.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="form-group col-sm-6 col-md-4">
            <span>Amount</span>
            <input type="text" class="form-control" id="FirstName" formControlName="Amount" placeholder="Payment Amount"
              [ngClass]="{
                'is-invalid': editPaymentSubmitted && f['Amount'].errors
              }" />
            <div *ngIf="editPaymentSubmitted && f['Amount'].errors" class="invalid-feedback">
              <div *ngIf="f['Amount'].errors?.['required']">
                Please enter the Payment Amount.
              </div>
              <div *ngIf="f['Amount'].errors?.['pattern']">
                Payment Amount must have numbers.
              </div>
            </div>
          </div>

          <div class="form-group col-sm-6 col-md-4">
            <span>Policy Txn Id</span>

            <input type="text" class="form-control" id="FirstName" formControlName="policyTxnId"
              placeholder="Policy Txn Id" (blur)="onKeydown($event)" [ngClass]="{
                'is-invalid': editPaymentSubmitted && f['policyTxnId'].errors
              }" />
            <div *ngIf="editPaymentSubmitted && f['policyTxnId'].errors" class="invalid-feedback">
              <div *ngIf="f['policyTxnId'].errors?.['required']">
                Please enter the PolicyTxnId.
              </div>
              <div *ngIf="f['policyTxnId'].errors?.['pattern']">
                Please enter the PolicyTxnId.
              </div>
            </div>
            <div *ngIf="warning == true">
              <i class="fas fa-exclamation-triangle">
                {{ policyMsg }}
              </i>
            </div>
          </div>
        </div>
        <input type="hidden" formControlName="Row" #rowHiddenField />
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeModal()">
          Cancel
        </button>
        <button class="btn btn-primary btn-full-sm">Save Changes</button>
      </div>
    </form>
    <div class="card-body" *ngIf="showCnfrmation">
      <form [formGroup]="noteForm" (ngSubmit)="curdOperationConfirmation('Update')">
        <h2 class="mb-2 box-label-sm">Add a Notes</h2>
        <div class="form-group">
          <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
            placeholder="Add notes here" [ngClass]="{
              'is-invalid': submitted && notes['Description'].errors
            }"></textarea>
          <div *ngIf="submitted && notes['Description'].errors" class="invalid-feedback">
            <div *ngIf="notes['Description'].errors?.['required']">
              Please enter a note
            </div>
          </div>
        </div>
        <div *ngIf="failedMessage.length > 0" class="invalid-feedback" style="display: block !important">
          {{ failedMessage }}
        </div>
        <div class="text-right mt-2 btn-footer">
          <button class="btn btn-secondary btn-sm" (click)="resetForm()">
            Cancel
          </button>
          <button class="btn btn-primary btn-sm" type="submit">Save</button>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #dialogContentTemplate1>
    <h2 *ngIf="!showCnfrmation" class="mt-4 mb-3 box-label" style="margin: 300px">
      Edit Refund
    </h2>
    <form [formGroup]="refundForm" *ngIf="!showCnfrmation"
      (ngSubmit)="openNotes(rowHiddenFields.value, 'RefundType', 'Update')">
      <div class="modal-body">
        <div class="form-row mt-3">
          <div class="form-group col-sm-6 col-md-4">
            <span>TransactionId</span>
            <input type="text" class="form-control" id="email" formControlName="RTransactionId"
              placeholder="Refund TransactionId " [ngClass]="{
                'is-invalid': editRefundSubmitted && r['RTransactionId'].errors
              }" />
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <span>Type</span>
            <input type="text" class="form-control" id="FirstName" formControlName="RType"
              placeholder="Refund Successful" [ngClass]="{
                'is-invalid': editRefundSubmitted && r['RType'].errors
              }" />
            <div *ngIf="editRefundSubmitted && r['RType'].errors" class="invalid-feedback">
              <div *ngIf="r['RType'].errors?.['required']">
                Please enter the Refund Type.
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <span>Successful Status</span>
            <input type="text" class="form-control" id="LastName" formControlName="RSuccessful"
              placeholder="Refund Type" [ngClass]="{
                'is-invalid': editRefundSubmitted && r['RSuccessful'].errors
              }" />
            <div *ngIf="editRefundSubmitted && r['RSuccessful'].errors" class="invalid-feedback">
              <div *ngIf="r['RSuccessful'].errors?.['required']">
                Please enter the Refund Type.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="form-group col-sm-6 col-md-4">
            <span>Amount</span>
            <input type="text" class="form-control" id="FirstName" formControlName="RAmount"
              placeholder="Payment Amount" [ngClass]="{
                'is-invalid': editRefundSubmitted && r['RAmount'].errors
              }" />
            <div *ngIf="editRefundSubmitted && r['RAmount'].errors" class="invalid-feedback">
              <div *ngIf="r['RAmount'].errors?.['required']">
                Please enter the Refund Amount.
              </div>
              <div *ngIf="r['RAmount'].errors?.['pattern']">
                Please enter the Refund Amount.
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <span>PolicyTxnId</span>
            <input type="text" class="form-control" id="LastName" formControlName="RPolicyTxnId"
              (blur)="onKeydown($event)" placeholder="PolicyTxnId" [ngClass]="{
                'is-invalid': editRefundSubmitted && r['RPolicyTxnId'].errors
              }" />
            <div *ngIf="editRefundSubmitted && r['RPolicyTxnId'].errors" class="invalid-feedback">
              <div *ngIf="r['RPolicyTxnId'].errors?.['required']">
                Please enter the PolicyTxnId.
              </div>
            </div>
            <div *ngIf="warning == true">
              <i class="fas fa-exclamation-triangle">
                {{ policyMsg }}
              </i>
            </div>
          </div>
        </div>
        <input type="hidden" formControlName="Row" #rowHiddenFields />
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeModal()">
          Cancel
        </button>
        <button class="btn btn-primary btn-full-sm">Save Changes</button>
      </div>
    </form>

    <div class="card-body" *ngIf="showCnfrmation">
      <form [formGroup]="noteForm" (ngSubmit)="curdOperationConfirmation('Update')">
        <h2 class="mb-2 box-label-sm">Add a Notes</h2>
        <div class="form-group">
          <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
            placeholder="Add notes here" [ngClass]="{
              'is-invalid': submitted && notes['Description'].errors
            }"></textarea>
          <div *ngIf="submitted && notes['Description'].errors" class="invalid-feedback">
            <div *ngIf="notes['Description'].errors?.['required']">
              Please enter a note
            </div>
          </div>
        </div>
        <div *ngIf="failedMessage.length > 0" class="invalid-feedback" style="display: block !important">
          {{ failedMessage }}
        </div>
        <div class="text-right mt-2 btn-footer">
          <button class="btn btn-secondary btn-sm" (click)="resetForm()">
            Cancel
          </button>
          <button class="btn btn-primary btn-sm" type="submit">Save</button>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #dialogContentTemplatePayment>
    <h2 *ngIf="!showCnfrmation" class="mt-4 mb-3 box-label" style="margin: 300px">
      Add Payment
    </h2>

    <form *ngIf="!showCnfrmation" [formGroup]="CreatePayment" (ngSubmit)="
        openNotes(rowHiddenFieldcreate.value, 'PaymentType', 'Addition')
      ">
      <div class="modal-body">
        <div class="form-row mt-3">
          <div class="form-group col-sm-6 col-md-4">
            <span>TransactionId</span>
            <input type="text" class="form-control" id="" formControlName="paymentTransactionId"
              placeholder="Payment TransactionId " [ngClass]="{
                'is-invalid':
                  createPaymentSubmitted &&
                  createf['paymentTransactionId'].errors
              }" />
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <span>Type</span>
            <select type="text" class="form-control" id="FirstName" formControlName="paymentType"
              placeholder="Payment Type" [ngClass]="{
              'is-invalid':
                createPaymentSubmitted && createf['paymentType'].errors
            }">
              <option value="" disabled="true" [selected]="true">Select Payment Type</option>
              <option value='CC'>CC</option>
              <option value='PC'>PC</option>
            </select>
            <div *ngIf="createPaymentSubmitted && createf['paymentType'].errors" class="invalid-feedback">
              <div *ngIf="createf['paymentType'].errors?.['required']">
                Please enter the Payment Type.
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <span>Successful Status</span>
            <select type="text" class="form-control" id="LastName" formControlName="paymentSuccessful"
              placeholder="Payment success" [ngClass]="{
                'is-invalid':
                  createPaymentSubmitted && createf['paymentSuccessful'].errors
              }">
              <option value="" disabled="true" [selected]="true">Select Status</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
            </select>
            <div *ngIf="
                createPaymentSubmitted && createf['paymentSuccessful'].errors
              " class="invalid-feedback">
              <div *ngIf="createf['paymentSuccessful'].errors?.['required']">
                Please enter the Successful.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="form-group col-sm-6 col-md-4">
            <span>Amount</span>
            <input type="text" class="form-control" id="FirstName" formControlName="paymentAmount"
              placeholder="Payment Amount" [ngClass]="{
                'is-invalid':
                  createPaymentSubmitted && createf['paymentAmount'].errors
              }" />
            <div *ngIf="createPaymentSubmitted && createf['paymentAmount'].errors" class="invalid-feedback">
              <div *ngIf="createf['paymentAmount'].errors?.['required']">
                Please enter the Payment Amount.
              </div>
              <div *ngIf="createf['paymentAmount'].errors?.['pattern']">
                Please enter the Payment Amount.
              </div>
            </div>
          </div>

          <!-- <div class="form-group col-sm-6 col-md-4">
            <span>Policy Txn Id</span>
            <input type="text" class="form-control" id="FirstName" formControlName="paymentPolicyTxnId"
              placeholder="PolicyTxnId" (blur)="onKeydown($event)" [ngClass]="{
                'is-invalid':
                  createPaymentSubmitted && createf['paymentPolicyTxnId'].errors
              }" />
            <div *ngIf="
                createPaymentSubmitted && createf['paymentPolicyTxnId'].errors
              " class="invalid-feedback">
              <div *ngIf="createf['paymentPolicyTxnId'].errors?.['required']">
                Please enter the PolicyTxnId.
              </div>
              <div *ngIf="createf['paymentPolicyTxnId'].errors?.['pattern']">
                Please enter the PolicyTxnId.
              </div>
            </div>
            <div *ngIf="warning == true">
              <i class="form-fas fa-exclamation-triangle text-warning">
                {{ policyMsg }}
              </i>
            </div>
          </div> -->

          <!-- <div class="form-group col-sm-6 col-md-4">
              <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
                  placeholder="Add notes here"
                  [ngClass]="{ 'is-invalid': createPaymentSubmitted && createf.Description.errors }"></textarea>

              <div *ngIf="createPaymentSubmitted && createf.Description.errors" class="invalid-feedback">
                  <div *ngIf="createf.Description.errors.required">Please enter the Notes.</div>
              </div>
          </div> -->
        </div>
        <input type="hidden" formControlName="Row" #rowHiddenFieldcreate />
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeModal()">
          Cancel
        </button>
        <button class="btn btn-primary btn-full-sm">Save Changes</button>
      </div>
    </form>

    <div class="card-body" *ngIf="showCnfrmation">
      <form [formGroup]="noteForm" (ngSubmit)="curdOperationConfirmation('Addition')">
        <h2 class="mb-2 box-label-sm">Add a Notes</h2>
        <div class="form-group">
          <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
            placeholder="Add notes here" [ngClass]="{
              'is-invalid': submitted && notes['Description'].errors
            }"></textarea>
          <div *ngIf="submitted && notes['Description'].errors" class="invalid-feedback">
            <div *ngIf="notes['Description'].errors?.['required']">
              Please enter a note
            </div>
          </div>
        </div>
        <div class="text-right mt-2 btn-footer">
          <button class="btn btn-secondary btn-sm" (click)="resetForm()">
            Cancel
          </button>
          <button class="btn btn-primary btn-sm" type="submit">Save</button>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #dialogContentTemplateRefund>
    <h2 *ngIf="!showCnfrmation" class="mt-4 mb-3 box-label" style="margin: 300px">
      Add Refund
    </h2>
    <form *ngIf="!showCnfrmation" [formGroup]="createRefundPayment" (ngSubmit)="
        openNotes(rowHiddenFieldcreateR.value, 'RefundType', 'Addition')
      ">
      <div class="modal-body">
        <div class="form-row mt-3">
          <div class="form-group col-sm-6 col-md-4">
            <span>TransactionId</span>
            <input type="text" class="form-control" id="" formControlName="refundTransactionId"
              placeholder="Refund TransactionId " [ngClass]="{
                'is-invalid':
                  createRefundSubmitted && createR['refundTransactionId'].errors
              }" />
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <span>Type</span>
            <select type="text" class="form-control" id="FirstName" formControlName="refundType"
              placeholder="Refund Type" [ngClass]="{
            'is-invalid':
            createRefundSubmitted && createR['refundType'].errors
          }">
              <option value="" disabled="true" [selected]="true">Select Refund Type</option>
              <option value='CC'>CC</option>
              <option value='PC'>PCC</option>
            </select>
            <div *ngIf="createRefundSubmitted && createR['refundType'].errors" class="invalid-feedback">
              <div *ngIf="createR['refundType'].errors?.['required']">
                Please enter the Refund Type.
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4">
            <span>Successful Status</span>
            <select type="text" class="form-control" id="LastName" formControlName="refundSuccessful"
              placeholder="Refund Status" [ngClass]="{
                'is-invalid':
                createRefundSubmitted && createR['refundSuccessful'].errors
              }">
              <option value="" disabled="true" [selected]="true">Select Status</option>
              <option value="Paid">Paid</option>
              <option value="Pending">Pending</option>
            </select>
            <div *ngIf="
                createRefundSubmitted && createR['refundSuccessful'].errors
              " class="invalid-feedback">
              <div *ngIf="createR['refundSuccessful'].errors?.['required']">
                Please enter the Refund Status.
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mt-3">
          <div class="form-group col-sm-6 col-md-4">
            <span>Amount</span>
            <input type="text" class="form-control" id="FirstName" formControlName="refundAmount"
              placeholder="Refund Amount" [ngClass]="{
                'is-invalid':
                  createRefundSubmitted && createR['refundAmount'].errors
              }" />
            <div *ngIf="
                createRefundSubmitted && createR['refundAmount'].errors
              " class="invalid-feedback">
              <div *ngIf="createR['refundAmount'].errors?.['required']">
                Please enter the Refund Amount.
              </div>
              <div *ngIf="createR['refundAmount'].errors?.['pattern']">
                Refund Amount must have numbers.
              </div>
            </div>
          </div>
          <!-- <div class="form-group col-sm-6 col-md-4">
            <span>PolicyTxnId</span>
            <input type="text" class="form-control" id="LastName" formControlName="refundPolicyTxnId"
              (blur)="onKeydown($event)" placeholder="PolicyTxnId" [ngClass]="{
                'is-invalid':
                  createRefundSubmitted && createR['refundPolicyTxnId'].errors
              }" />
            <div *ngIf="
                createRefundSubmitted && createR['refundPolicyTxnId'].errors
              " class="invalid-feedback">
              <div *ngIf="createR['refundPolicyTxnId'].errors?.['required']">
                Please enter the PolicyTxnId.
              </div>
            </div>
            <div *ngIf="warning == true">
              <i class="fas fa-exclamation-triangle">
                {{ policyMsg }}
              </i>
            </div>
          </div> -->
        </div>
        <input type="hidden" formControlName="Row" #rowHiddenFieldcreateR />
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeModal()">
          Cancel
        </button>
        <button class="btn btn-primary btn-full-sm">Save Changes</button>
      </div>
    </form>

    <div class="card-body" *ngIf="showCnfrmation">
      <form [formGroup]="noteForm" (ngSubmit)="curdOperationConfirmation('Addition')">
        <h2 class="mb-2 box-label-sm">Add a Notes</h2>
        <div class="form-group">
          <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
            placeholder="Add notes here" [ngClass]="{
              'is-invalid': submitted && notes['Description'].errors
            }"></textarea>
          <div *ngIf="submitted && notes['Description'].errors" class="invalid-feedback">
            <div *ngIf="notes['Description'].errors?.['required']">
              Please enter a note
            </div>
          </div>
        </div>
        <div class="text-right mt-2 btn-footer">
          <button class="btn btn-secondary btn-sm" (click)="resetForm()">
            Cancel
          </button>
          <button class="btn btn-primary btn-sm" type="submit">Save</button>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #dialogContentTemplateDeletePayment>
    <!-- Modal Header -->

    <div *ngIf="!showCnfrmation" class="modal-header">
      <h4 class="modal-title">
        <i class="fa fa-thumbs-up" aria-hidden="true"></i> Confirmation
      </h4>
    </div>

    <!-- Modal body -->
    <div *ngIf="!showCnfrmation" class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div>Are you sure want to delete payment</div>
        </div>
      </div>
    </div>

    <!-- Modal footer -->
    <div *ngIf="!showCnfrmation" class="modal-footer" style="justify-content: flex-end">
      <button type="submit" class="btn btn-primary pull-right" (click)="closeModal()" data-dismiss="modal">
        No
      </button>
      <button type="submit" class="btn btn-primary pull-right" (click)="openNotes(0, 'PaymentType', 'Delete')"
        data-dismiss="modal">
        Yes
      </button>
    </div>

    <div class="card-body" *ngIf="showCnfrmation">
      <form [formGroup]="noteForm" (ngSubmit)="curdOperationConfirmation('Delete')">
        <h2 class="mb-2 box-label-sm">Add a Notes</h2>
        <div class="form-group">
          <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
            placeholder="Add notes here"
            [ngClass]="{ 'is-invalid': submitted && notes['Description'].errors }"></textarea>
          <div *ngIf="submitted && notes['Description'].errors" class="invalid-feedback">
            <div *ngIf="notes['Description'].errors?.['required']">
              Please enter a note
            </div>
          </div>
        </div>
        <div class="text-right mt-2 btn-footer">
          <button class="btn btn-secondary btn-sm" (click)="resetForm()">
            Cancel
          </button>
          <button class="btn btn-primary btn-sm" type="submit">Save</button>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #dialogContentTemplateDeleteRefund>
    <!-- Modal Header -->

    <div *ngIf="!showCnfrmation" class="modal-header">
      <h4 class="modal-title">
        <i class="fa fa-thumbs-up" aria-hidden="true"></i> Confirmation
      </h4>
    </div>

    <!-- Modal body -->
    <div *ngIf="!showCnfrmation" class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div>Are you sure want to delete Refund</div>
        </div>
      </div>
    </div>

    <!-- Modal footer -->
    <div *ngIf="!showCnfrmation" class="modal-footer" style="justify-content: flex-end">
      <button type="submit" class="btn btn-primary pull-right" (click)="closeModal()" data-dismiss="modal">
        No
      </button>
      <button type="submit" class="btn btn-primary pull-right" (click)="openNotes(0, 'RefundType', 'Delete')"
        data-dismiss="modal">
        Yes
      </button>
    </div>

    <div class="card-body" *ngIf="showCnfrmation">
      <form [formGroup]="noteForm" (ngSubmit)="curdOperationConfirmation('Delete')">
        <h2 class="mb-2 box-label-sm">Add a Notes</h2>
        <div class="form-group">
          <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
            placeholder="Add notes here" [ngClass]="{
              'is-invalid': submitted && notes['Description'].errors
            }"></textarea>
          <div *ngIf="submitted && notes['Description'].errors" class="invalid-feedback">
            <div *ngIf="notes['Description'].errors?.['required']">
              Please enter a note
            </div>
          </div>
        </div>
        <div class="text-right mt-2 btn-footer">
          <button class="btn btn-secondary btn-sm" (click)="resetForm()">
            Cancel
          </button>
          <button class="btn btn-primary btn-sm" type="submit">Save</button>
        </div>
      </form>
    </div>
  </ng-template>

  <ng-template #noteBox>
    <!-- Modal body -->
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div class="card-body">
            <form [formGroup]="noteForm" (ngSubmit)="noteSubmitted()">
              <h2 class="mb-2 box-label-sm">Add a Notes</h2>
              <div class="form-group">
                <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
                  placeholder="Add notes here" [ngClass]="{
                    'is-invalid': submitted && notes['Description'].errors
                  }"></textarea>
                <div *ngIf="submitted && f['Description'].errors" class="invalid-feedback">
                  <div *ngIf="notes['Description'].errors?.['required']">
                    Please enter a note
                  </div>
                </div>
              </div>
              <div class="text-right mt-2 btn-footer">
                <button class="btn btn-secondary btn-sm" (click)="resetForm()">
                  Cancel
                </button>
                <button class="btn btn-primary btn-sm" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>