import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-form-error',
  standalone: true,
  imports: [
    NgIf,
  ],
  templateUrl: './form-error.component.html',
  styleUrl: './form-error.component.css'
})
export class FormErrorComponent {
  @Input() control!: AbstractControl;
  @Input() label!: string;
  @Input() totalDigits?: number = 0;
  @Input() rawMessage?: string;
  @Input() submitted?: boolean;
}
