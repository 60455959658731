import { Pipe, PipeTransform } from '@angular/core';
import { Moment } from 'moment';
import { DateService } from '../services/date-updated.service';

@Pipe({ name: 'utcToShortDatePipe' })
export class UtcToShortDatePipe implements PipeTransform {
  constructor(private dateService: DateService) {}

  transform(value: Moment | Date | string): any {
    if (!value) {
      return '';
    }
    return this.dateService.toDDMMYYYY(this.dateService.getMoment(value));
  }
}

@Pipe({ name: 'utcToLongDatePipe' })
export class UtcToLongDatePipe implements PipeTransform {
  constructor(private dateService: DateService) {}

  transform(value: Moment | Date | string): any {
    if (!value) {
      return '';
    }
    return this.dateService.toLongDate(this.dateService.getMoment(value));
  }
}
