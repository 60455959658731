import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CurrencyPipe, PercentPipe, DatePipe } from '@angular/common';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { PromoCodeComponent } from './app.component';
import { OktaCustomAuthService } from '../../../../src/app/shared/okta/oktaauth.service';
import { CustomNgbDateParserFormatter } from 'src/app/core/services/customNgbDateParserFormatter';
import { ToastrModule } from 'ngx-toastr';
import { InterceptorsService } from 'src/app/core/services/interceptors.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RoleTextService } from '../../../../src/app/core/role/setroletext';
import { LogPublishersService } from 'projects/policyDetails/src/app/shared/logging/log-publisher.service';
import { HttpCoreService } from 'src/app/core/services/http-core.service';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppMaterialModule } from 'src/app-material/app-material.module';
import { LoaderService } from 'src/app/core/services/loader.service';
import { LoaderModule } from 'src/app/core/loader/loader.module';
export { PromoCodeComponent } from './app.component';

@NgModule({
  declarations: [PromoCodeComponent],
  imports: [
    BrowserModule,
    FormsModule,
    LoaderModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      preventDuplicates: true,
    }),
    HttpClientModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    AppMaterialModule,
    MatRadioModule,
    MatTooltipModule,
    DataTablesModule,
  ],
  providers: [
    CurrencyPipe,
    PercentPipe,
    DatePipe,
    OktaCustomAuthService,
    RoleTextService,
    HttpCoreService,
    LogPublishersService,
    LoaderService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorsService,
      multi: true,
    },
    {
      provide: NgbDateParserFormatter,
      useFactory: () => new CustomNgbDateParserFormatter('dd/MM/yyyy'),
    },
  ],
})
export class PromoCodeModule {}
