/// <reference path="../../../../src/app/core/role/setroletext.ts" />
import { Component, ChangeDetectorRef, OnInit, Input, Inject, } from '@angular/core';
import { HeaderService } from './app.service';
import { RoleTextService } from '../../../../src/app/core/role/setroletext';
import { LogService } from '../../../../projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from '../../../../projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum } from '../../../../projects/policyDetails/src/app/enum/page-name-enum';
import { HeaderNavigationService } from 'projects/nav/src/app/app.service';
import { MsalService } from '@azure/msal-angular';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { Store } from '@ngrx/store';
@Component({
  selector: 'header-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class HeaderComponent implements OnInit {
  title = 'header';
  user: any;
  loggedUser: any = {};
  response: any = [];
  isShow: boolean = false;
  roleText: any;
  heroImageUrl: string = "";
  heroImageAlt: string = "";
  isRACV: boolean = false;

  constructor(
    private headerService: HeaderService,
    private navService: HeaderNavigationService,
    private roleTextService: RoleTextService,
    private logService: LogService,
    private msalService: MsalService,
    private store: Store
  ) { }

  ngOnInit() {
    this.getNavigationDetails();
  }

  getNavigationDetails() {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.isShow = true;
        this.loggedUser = loginUser.userProfile.LoggedUser.toUpperCase();
        this.navService
          .getSecurityRole(loginUser.userProfile.ContactEmail)
          .subscribe((resp) => {
            if (resp) {
              this.roleText = this.getRoleText(resp.agentType);
              this.heroImageUrl = resp.heroImageUrl;
              this.heroImageAlt = resp.alt;
              if (resp.autoClubURLList.length == 1) {
                if (resp.autoClubURLList[0].key == "RACV") {
                  this.isRACV = true;
                }
              }
            }
          });
      }
    });
  }

  getRoleText(agentType: string) {
    switch (agentType) {
      case "TMMASuperAdmin":
        return "Super Admin";
      case "ClubSuperUser":
        return "Club Admin";
      case "ClubAgent":
        return "Club Agent";
      case "ReadOnly":
        return "Read Only";
      case "TMMAAgent":
        return "Super User";
      case "W2CReadOnly":
        return "W2C Read Only";
    }
  }
  
  async logout() {
    sessionStorage.clear();
    localStorage.clear();

    this.msalService.logoutRedirect({
      account: this.msalService.instance.getActiveAccount(),
      postLogoutRedirectUri: '/',
    });
  }
}
