import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';
import { SharedModule } from '../shared.module';
import { ManageBranchesService } from '../ManageBranchs.service';

@Component({
  selector: 'app-select-address-dropdown',
  standalone: true,
  imports: [
    FormsModule,
    NgIf,
    ReactiveFormsModule,
    SharedModule,
  ],
  templateUrl: './select-address-dropdown.component.html',
  styleUrl: './select-address-dropdown.component.css'
})
export class SelectAddressDropdownComponent {
  @Input() addressGroup!: FormGroup;
  @Input() isInvalid!: boolean;
  @Output() addressSelected = new EventEmitter<any>();

  filteredAddress: any = [];

  constructor(private manageBranchService: ManageBranchesService) {
  }

  filterAddress() {
    const address = this.addressGroup.get('StreetAddress')?.value;
    if (address?.length > 1) {
      this.manageBranchService.getAddressifyAutoCompleteData(address).subscribe(data => {
        // this.errorMsg = '';
        this.filteredAddress = data || [];
        // if (!data?.length) {
        //   this.errorMsg = 'No record found';
        // }
      }, () => {
        // this.errorMsg = 'Error fetching data';
      });
    } else {
      this.filteredAddress = [];
    }
  }

  onSelectAddress(address: string) {
    this.manageBranchService
      .getAddressifyInfoData(address)
      .toPromise()
      .then((infodata) => {
        if (infodata.Suburb && infodata.State) {
          this.addressSelected.emit(infodata);
        }
      });

    this.filteredAddress.length = 0;
  }

}