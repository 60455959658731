<div class="loader" *ngIf="isLoadingQuoteDetails"></div>
<div class="quote-box" *ngIf="!isLoadingQuoteDetails">
  <div class="modal-body quote-box">
    <div class="text-center mb-4 mt-4">
      <h5>Update Coverage Options</h5>
      <div class="blue-toggle btn-group btn-group-toggle pt-3" data-toggle="buttons">
        <label class="btn btn-light" [ngClass]="{ active: state.tripType === 'single', disabled: isAMTTripWithMultiCountry }" (click)="openTripAlertModel('single', 'clicked')">
          <input type="radio" name="options" id="option1" autocomplete="off" [value]="true"
            [(ngModel)]="state.IsSingTrip" />
            {{ whiteLabeling.SingleTripLabel }}
          <span class="d-inline-block cursor" tabindex="0">
            <i class="fa fa-info info-icon pl-2"></i>
          </span>
        </label>
        <label class="btn btn-light" [ngClass]="{ active: state.tripType === 'multi-trip', disabled: isW2CDisabled }" (click)="openTripAlertModel('multi-trip', 'clicked')">
          <input type="radio" disabled name="options" id="option2" autocomplete="off" [value]="false"
            [(ngModel)]="!state.IsSingTrip" />
            {{ whiteLabeling.MultiTripLabel }}
          <span class="d-inline-block cursor" tabindex="0">
            <i class="fa fa-info info-icon pl-2"></i>
          </span>
        </label>
      </div>
      <h6 *ngIf="showHideDiv" style="color: #ff5722; margin-top: 2px">
        {{ isAMTTripWithMultiCountryText }}
      </h6>
    </div>

    <div class="card-group">
      <div class="card" [ngClass]="{
          'card-hover': state.planType == coverageItem.productName,
          'mt-50': state.currentPlanType != coverageItem.productName
        }" *ngFor="let coverageItem of state.coveragesItems; let i = index">
        <div class="t{{ i + 1 }}" [ngClass]="{
            HideControl: state.currentPlanType != coverageItem.productName
          }">
          <div class="title">
            Current Plan
            <ng-container *ngIf="setCardIndex(i, coverageItem)"> </ng-container>
          </div>
        </div>

        <div class="card-header q{{ i + 1 }}">
          <div class="row">
            <div class="col-md-12 text-left">
              <h5>{{ coverageItem.productText }}</h5>
            </div>
            <div class="col-md-12" *ngIf="siteName === siteNameEnum.W2C || state.racvMember == 'No'">
              <div class="right">
                <div>${{ coverageItem.regularPriceTxt }}</div>
                <span class="regular-price">
                  ${{
                  (coverageItem.netPremium | number: '1.2')! | integerPart
                  }}
                  <span>.{{
                    (coverageItem.netPremium | number: '1.2')! | decimalPart
                    }}</span>
                </span>
              </div>
            </div>
            <div class="col-md-12" *ngIf="siteName != siteNameEnum.W2C && state.racvMember == 'Yes'">
              <div class="left pl-0 pl-xl-3">
                <div>${{ coverageItem.regularPriceTxt }}</div>
                <h6 class="strike">${{ coverageItem.RegularFinalPremium }}</h6>
              </div>
              <div class="right">
                <div>${{ coverageItem.memberPriceTxt }}</div>
                <span class="regular-price">
                  ${{ (coverageItem.netPremium | number: '.2-2')! | integerPart
                  }}<span>
                    .{{
                    (coverageItem.netPremium | number: '.2-2')! | decimalPart
                    }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <table class="table-borderless">
            <tbody>
              <tr [ngClass]="{
                  HideControl: !isTrue(coverageItem.showMultipleDays)
                }">
                <td>{{ coverageItem.chooseYourMaxTripLenTxt }}</td>
                <td class="text-right">
                  <select class="form-control dropdown-custom ml-auto" id="maxlen{{ i }}"
                    [(ngModel)]="state.coveragesItems[i].selectedMaxLenName" (change)="selectMaxLen($event, i)">
                    <option *ngFor="let maxTripLenName of coverageItem.maxTripLenList"
                      [value]="maxTripLenName.maxTripLenName">
                      {{ maxTripLenName.maxTripLenName }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr [ngClass]="{ HideControl: isTrue(coverageItem.hideExcess) }">
                <td>{{ coverageItem.chooseYourExcessTxt }}</td>
                <td class="text-right">
                  <select class="form-control dropdown-custom ml-auto" id="excess{{ i }}"
                    [(ngModel)]="state.coveragesItems[i].selectedExcessName" (change)="selectExcess($event, i)">
                    <option *ngFor="let excess of coverageItem.excessList" [value]="excess.excessName">
                      {{ excess.excessName }}
                    </option>
                  </select>
                </td>
              </tr>
              <tr [ngClass]="{ HideControl: isTrue(coverageItem.hideSkiing) || state.skiingNotApplicable }">
                <td>{{ coverageItem.skiingTxt }}</td>
                <td class="text-right">
                  <div class="blue-toggle btn-group btn-group-toggle pt-3">
                    <label class="btn btn-sm btn-light" [ngClass]="{
                      active: isTrue(state.coveragesItems[i].selectedSkiing)
                    }">
                      <input type="radio" (change)="onChangeSkiing(true, i)" name="skiing{{ i }}" id="skiingY{{ i }}"
                        [value]="true" [(ngModel)]="state.coveragesItems[i].selectedSkiing" autocomplete="off" />
                      Yes
                    </label>
                    <label class="btn btn-sm btn-light" [ngClass]="{
                      active: !isTrue(state.coveragesItems[i].selectedSkiing)
                    }">
                      <input type="radio" (change)="onChangeSkiing(false, i)" name="skiing{{ i }}" id="skiingN{{ i }}"
                        [value]="false" [(ngModel)]="state.coveragesItems[i].selectedSkiing" autocomplete="off" />No
                    </label>
                  </div>
                </td>
              </tr>
              <tr [ngClass]="{ HideControl: isTrue(coverageItem.hideCruise) }">
                <td>{{ coverageItem.cruiseOptionTxt }}</td>
                <td class="text-right">
                  <div class="blue-toggle btn-group btn-group-toggle pt-3">
                    <label class="btn btn-sm btn-light" [ngClass]="{
                        active: isTrue(state.coveragesItems[i].selectedCruise)
                      }">
                      <input type="radio" (change)="onChangeCruise(true, i)" name="cruise{{ i }}" id="cruiseY{{ i }}"
                        [value]="true" [(ngModel)]="state.coveragesItems[i].selectedCruise" autocomplete="off" />
                      Yes
                    </label>
                    <label class="btn btn-sm btn-light" [ngClass]="{
                        active: !isTrue(state.coveragesItems[i].selectedCruise),
                        disabled: isNoCruiseDisabled
                      }">
                      <input type="radio" (change)="onChangeCruise(false, i)" name="cruise{{ i }}" id="cruiseN{{ i }}"
                        [value]="false" [(ngModel)]="state.coveragesItems[i].selectedCruise" autocomplete="off"
                        [disabled]="isNoCruiseDisabled" />No
                    </label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body">
          <div class="text-center">
            <button class="btn btn-outline-secondary custom-theme-group-eight w-100" (click)="selectQuote(coverageItem, i)" [ngClass]="{ 'border0 btn-selected-plan': coverageItem.productName == state.planType }">
              <i [ngClass]="{ 'fa fa-check-circle yellow pr-2': coverageItem.productName == state.planType }"></i>
              {{
              coverageItem.productName === state.planType
              ? coverageItem.selectedBtnTxt
              : coverageItem.chooseBtnTxt
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center mb-4 mt-4">
    <button type="button" class="btn btn-secondary btn-full-sm" (click)="onClose()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary btn-full-sm" (click)="onSave(false)" [disabled]="isProductUpdateDisabled">
      Save Changes
    </button>
  </div>
</div>
<ng-template #confirmMessageTemplate>
  <div class="" id="myModal-confirm">
    <div class="modal-body">
      <div class="col-md-10 mx-auto text-center">
        <div class="alert my-2">{{ updateMessageSuccess }}</div>
      </div>
    </div>
    <div class="text-center mb-4 btn-footer">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeAlertChildModal()">
        Ok
      </button>
    </div>
  </div>
</ng-template>

<ng-template #switchTripTemplate>
  <div *ngIf="isLoader">
    <div class="clearfix"></div>
    <div class="overlay-custom-container position-absolute"></div>
    <div class="loader"></div>
  </div>
  <div class="" id="myModal-confirm">
    <div class="modal-body">
      <div class="col-md-10 mx-auto text-center">
        <div class="alert my-2">{{ updateMessageSuccess }}</div>
      </div>
    </div>
    <div class="text-center mb-4 btn-footer" *ngIf="!coverageError">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeTripAlertChildModal()">
        NO
      </button>
      <button type="button" class="btn btn-primary btn-full-sm" (click)="changetrip('clicked')">
        YES
      </button>
    </div>
    <div class="text-center mb-4 btn-footer" *ngIf="coverageError">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeTripAlertChildModal()">
        Close
      </button>
      <!-- <button type="button" class="btn btn-primary btn-full-sm" (click)="changetrip('clicked')">
        YES
      </button> -->
    </div>
  </div>
</ng-template>