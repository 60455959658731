import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
var OktaSignIn = require('@okta/okta-signin-widget');

@Injectable({
  providedIn: 'root',
})
export class Okta {
  // widget;
  sitecoreData: any;

  constructor(private http: HttpClient) {
    // this.widget = new OktaSignIn({
    //   baseUrl: environment.baseUrl,
    //   logo: "/assets/images/logo-tmhcc.svg",
    //   clientId: environment.clientId,
    //   redirectUri: environment.redirectUri,
    //   authParams: {
    //     issuer: environment.issuer,
    //     responseType: ['id_token', 'token'],
    //     responseMode: "okta_post_message",
    //     scope: [
    //       "openid",
    //       "email",
    //       "profile",
    //       "address",
    //       "phone",
    //       "offline_access"
    //     ]
    //   },
    //   apiUrl: environment.apiUrl
    // });
  }

  getWidget() {
    // return this.widget;
  }
}
