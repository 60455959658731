/// <reference path="../../../../../src/app/core/services/price.service.ts" />
/// <reference path="../policy.service.ts" />
import {
  Component,
  ViewChild,
  TemplateRef,
  ElementRef,
  Input,
} from '@angular/core';
import { PolicyService } from '../policy.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { OktaCustomAuthService } from '../../../../../src/app/shared/okta/oktaauth.service';
import { ClubAndBranchInfo, CommonParams } from 'src/app/core/commonparams';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IssueEndorsementGTA } from '../app.model';
import { NgxBraintreeService } from '../ngx-braintree.service';
import { PriceService } from '../../../../../src/app/core/services/price.service';
import { CurrencyPipe } from '@angular/common';
import { LoaderService } from 'src/app/core/services/loader.service';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { SitecoreItemService } from 'projects/policyDetails/src/app/services/sitecore-item.service';
import { Utility } from 'projects/policyDetails/src/app/shared/utility';
import { isClose, IsCloseScalers } from 'is-close';
import { EndorsementGenericService } from 'projects/policyDetails/src/app/shared/services/endorsement-generic.service';
import { DataBusService } from 'projects/policyDetails/src/app/services/databus.service';
import { MessageBusService } from 'projects/policyDetails/src/app/services/messagebus.service';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { PolicyDetailService } from 'projects/policyDetails/src/app/app.services';
import { ToastrService } from 'ngx-toastr';
import { TravelerUpdateService } from 'projects/policyDetails/src/app/shared/services/traveller-update.service';
import { EventEmitterService } from 'projects/policyDetails/src/app/shared/services/event-emitter.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails, UserProfile } from 'src/app/core/store/loginUser/loginUser.model';
@Component({
  selector: 'manage-refunds',
  templateUrl: './manage-refunds.html',
  styleUrls: ['./manage-refunds.css'],
})
export class ManageRefundsComponent {
  cancelCashRefundMessageEror: any;
  cancelMessageEror: any;
  cancelMessageSuccess: any;
  cancelMessageAlertBtn: any;
  cancelMessageFail: any;
  cancelForm!: FormGroup;
  cancelCashPaymentForm!: FormGroup;
  policyTxnId!: string;
  cancellist: any[] = [];
  policyDetails: any = {};
  policyTravelCare: any = {};
  modelRef!: NgbModalRef;
  modelChildRef!: NgbModalRef;
  modelAlertRef!: NgbModalRef;
  modelManangeRefundsRef!: NgbModalRef;
  modelcancelCashPaymentRefundRef!: NgbModalRef;
  CancellationTxnId: any;
  paymentTransactionID: any;
  TxnStatusCode!: string;
  ProcessStatusCode!: string;
  isinitiateRefundtLoader: boolean = false;
  isinitiateRefundButtonVisible: boolean = false;
  issueEndorsementGTA = new IssueEndorsementGTA();
  modalData: any;
  intialRefundAmount: any;
  nonce!: string;
  siteName: string = CommonParams.SiteName;
  isManageRefundLoader = false;
  initiateRefundButtonText = 'Initiate Refund';
  isBrainTreeRefundFailed: boolean = false;
  manageRefundItem: any;
  finalCanecellation: any = [];
  cancelButton: boolean = true;
  selectedTransaction: any;
  isRefundClicked = false;
  isRefundAllClicked = false;
  cashReciptError: string | undefined;
  CancelAllPolicyMessage: string | undefined;
  refundAllText = 'Initiate Refund';
  isErrorOccured = false;
  isRefundAllDone = false;
  msg: string = 'Traveller detail updated successfully';
  userRoleDetails?: UserRoleDetails;
  userProfile?: UserProfile;
  public currentPolicyTxnNum!: Number;
  public currentEndorsementAmount!: number;
  public currentEndoProcessStatus: string = '';
  public currentEndoProcessStatusCode: string = '';
  public currentEndorsementObject: any;
  public siteCoreSettings: any;
  public policyId!: string;
  public currentEndoTxnStatusCode: string = '';
  public currentEndoTxnTypeCode: string = '';
  public previousEndorsementAmount!: number;
  public initiateCurrentRefund: any;

  // @Input() createPurchase: Function = (
  //   createPurchaseURL: any,
  //   nonce: any,
  //   chargeAmount: any,
  //   siteName: any,
  //   transactionId: any,
  // ) =>
  //   this.service.createPurchase(
  //     this.createPurchaseURL,
  //     nonce,
  //     chargeAmount,
  //     this.siteName,
  //     this.paymentTransactionID,
  //   );
  @Input() paymentRefundsData: any = [];
  @Input()
  createPurchaseURL!: string;
  @Input()
  clientTokenURL!: string;
  @Input()
  chargeAmount!: number;

  policyStatus: boolean = false;
  @ViewChild('warningBox', { static: true })
  warningBox!: ElementRef;
  @ViewChild('confirmationBox', { static: true })
  confirmationBox!: ElementRef;
  @ViewChild('manageRefundsTemplate', { static: true })
  dialogManageRefundsBox: TemplateRef<ElementRef>;
  @ViewChild('cancelCashPaymentRefundcontentTemplate', { static: true })
  dialogManageCashRefundsBox: TemplateRef<ElementRef>;

  constructor(
    public policyService: PolicyService,
    public formBuilder: FormBuilder,
    private service: NgxBraintreeService,
    private loaderService: LoaderService,
    private portalCodeService: PortalCodeService,
    public oktaCustomAuthService: OktaCustomAuthService,
    private el: ElementRef,
    private modalService: NgbModal,
    private priceService: PriceService,
    private sitecoreItemService: SitecoreItemService,
    public endorsementGenericService: EndorsementGenericService,
    public dtaService: PolicyDetailService,
    private eventEmitter: EventEmitterService,
    private travelerUpdateService: TravelerUpdateService,
    private store: Store
  ) {
    this.dialogManageRefundsBox = this.el.nativeElement;
    this.dialogManageCashRefundsBox = this.el.nativeElement;
  }

  ngOnInit() {
    this.cancelCashPaymentForm = this.formBuilder.group({
      ReceiptNumber: [''],
    });
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.userProfile = loginUser.userProfile;
      }
    })
    // this.policyTxnId = sessionStorage.getItem('movementGuid') || '';

    // if (this.policyTxnId != undefined) {
    //   // this.createdata();
    // }

    // this.eventEmitter.onManageRefundIssueCall.subscribe((isUpdated: string) => {
    //   if (isUpdated) {
    //     this.issueEndorsementCall();
    //   }
    // });
  }

  // async createdata() {
  //   let response = await this.policyService
  //     .getPolicyDetailbyId(this.policyTxnId.toString())
  //     .toPromise();
  //   if (response) {
  //     let policyMovement = response['PolicyMovement'];
  //     let prPolicy = policyMovement.find(
  //       (ele: any) => ele.TxnStatusCode === 'PR',
  //     );
  //     this.policyStatus = prPolicy ? true : false;
  //     this.policyId = response.PolicyId.toString();
  //     this.siteName = Utility.typeSafeHandle(
  //       response,
  //       'AdditionalPolicyDetailsGTA.RequestDetails.PortalCode',
  //     );
  //     this.currentPolicyTxnNum = response.PolicyTxnNum;
  //     this.currentEndorsementObject =
  //       response.PolicyMovement.length > 0
  //         ? response.PolicyMovement.filter(
  //             (x: any) =>
  //               x.PolicyTxnNum == this.currentPolicyTxnNum &&
  //               x.PolicyTxnId == this.policyTxnId.toString(),
  //           )[0]
  //         : undefined;
  //     this.currentEndorsementAmount =
  //       this.currentEndorsementObject != undefined
  //         ? Math.abs(this.currentEndorsementObject.WrittenPrem)
  //         : 0;
  //     this.currentEndoProcessStatus =
  //       this.currentEndorsementObject != undefined
  //         ? this.currentEndorsementObject.Status
  //         : '';
  //     this.currentEndoProcessStatusCode =
  //       this.currentEndorsementObject != undefined
  //         ? this.currentEndorsementObject.ProcessStatusCode
  //         : '';
  //     this.currentEndoTxnTypeCode =
  //       this.currentEndorsementObject != undefined
  //         ? this.currentEndorsementObject.TxnTypeCode
  //         : '';
  //     this.currentEndoTxnStatusCode =
  //       this.currentEndorsementObject != undefined
  //         ? this.currentEndorsementObject.TxnStatusCode
  //         : '';
  //     //Cancel - Rewrite
  //     if (
  //       this.currentEndoTxnTypeCode.toLowerCase() == 'rw' &&
  //       this.currentEndoTxnStatusCode.toLowerCase() == 'pr'
  //     ) {
  //       let previousPolicyData = await this.policyService
  //         .getPolicyCompairData(this.policyTxnId.toString())
  //         .toPromise();
  //       if (
  //         previousPolicyData &&
  //         previousPolicyData.PreviousTransaction != undefined &&
  //         previousPolicyData.PreviousTransaction.AdditionalPolicyDetailsGTA !=
  //           undefined
  //       ) {
  //         this.previousEndorsementAmount =
  //           previousPolicyData.PreviousTransaction.AdditionalPolicyDetailsGTA.TotalPremium;
  //       }
  //     }
  //     if (sessionStorage.getItem('itemServiceResponseData')) {
  //       this.sitecoreItemService.siteName = this.siteName;
  //       const itemData: any = JSON.parse(
  //         sessionStorage.getItem('itemServiceResponseData') || '',
  //       );
  //       const itemId =
  //         this.sitecoreItemService.getItemServiceID('GenericSettings');
  //       if (Utility.checkKeyInObject(itemId, itemData)) {
  //         this.siteCoreSettings = itemData[itemId];
  //       }
  //     }
  //   }
  // }

  initiateRefund() {
    // this.loaderService.show();
    //   if (this.policyStatus === true) {
    //     this.intialRefundAmount = sessionStorage.getItem('initiateRefundAmount');
    //     let dateparameter = JSON.parse(
    //       sessionStorage.getItem('originalPolicyTxnData') || '',
    //     );
    //     let postData = {
    //       PolicyTxnId: this.policyTxnId.toString(),
    //       CancelReasonId: 0, //1203,
    //       CancelMethodCode: 'F',
    //       SubCompanyCode: 'GTA',
    //       GetPaymentConfirmationNums: true,
    //       ReturnPremiumContactId:
    //         this.portalCodeService.getAgencyIDPortalSiteName(this.siteName),
    //       CancelRequestedBy: 'I',
    //       TxnEff: dateparameter.PolicyEff,
    //       AdjustedPrem: null,
    //       AdjustedFees: null,
    //       AdjustedComm: null,
    //       AdjustedTaxes: null,
    //       AdjustedAccretion: null,
    //       RefundAmount: 0 - this.intialRefundAmount,
    //     };
    //     this.policyService.GetGTARefundDetails(postData).subscribe(
    //       (resp) => {
    //         this.callMsgrewriteGTA(resp);
    //         this.loaderService.hide();
    //       },
    //       (err) => {
    //         this.loaderService.hide();
    //       },
    //     );
    //   } else {
    //     //this.issueEndorsementGTA.PolicyTxnId = this.policyTxnId;
    //     //this.issueEndorsementGTA.PaymentData = [
    //     //]
    if (this.paymentRefundsData != undefined) {
      this.openManageRefundsModel();
    }
  }
  openManageRefundsModel() {
    this.modelManangeRefundsRef = this.modalService.open(
      this.dialogManageRefundsBox,
      {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      },
    )
  }
  closeRefundConfirmModel() {
    // this.RefundConfirmRef.close();
  }
  refundAllConfirmation() {
    if (this.isRefundAllDone) {
      this.closeModalConfirmation('close');
    }
    else {
      this.confirmFullRefund();
    }
  }
  confirmFullRefund() {
    // this.closeRefundConfirmModel();
    this.isRefundAllClicked = true;
    this.isRefundClicked = true;
    this.CancelAllPolicyMessage = '';
    this.cashReciptError = '';
    this.cancelCashRefundMessageEror = '';
    if (this.paymentRefundsData.filter(
      (f: any) =>
        f.method === 'PC',
    ).length > 0) {
      this.openCashRefundModel();
    }
    else {
      this.savePaymentRecipt();
    }
  }
  savePaymentRecipt() {
    this.intialRefundAmount = sessionStorage.getItem('initiateRefundAmount');
    let coiSend = sessionStorage.getItem("sendCoiCheckBoxValue") || '';
    let sendCoiCheckBoxValue = coiSend.length > 0 && coiSend.toString().toLowerCase() == 'true' ? true : false;

    let postData = {
      policyGuid: sessionStorage.getItem('policyGuid'),
      cashReceiptNumber: this.cancelCashPaymentForm.value.ReceiptNumber ?? '',
      nonce: '',
      methodType: 'Refund',
      isSendCOI: sendCoiCheckBoxValue,
      movementGuid: sessionStorage.getItem('movementGuid'),
      amount: 0 - this.intialRefundAmount,
      agentBranchInfo:
      {
        agentGuid: this.userRoleDetails?.AgentContactId,
        agentName: this.userProfile?.LoggedUser,
        branchGuid: this.userRoleDetails?.BranchContactId,
        branchName: ClubAndBranchInfo.BranchName,
        roleId: 0,
      }
    };
    this.policyService
      .savePaymentRecieptRequest(postData)
      .subscribe(
        (resp) => {
          if (resp != undefined && resp.status?.toLowerCase() != 'failed') {
            this.refundAllText = 'Close';
            this.isRefundAllDone = true;
            this.cancelButton = false;
            this.finalCanecellation = this.paymentRefundsData;
            this.CancelAllPolicyMessage = "Policy has been refunded successfully, Please close this window to proceed.";
            this.finalCanecellation.forEach((item: any) => {
              item.brainTreeRefundStatus = true;
              if (item.method === 'PC') {
                item.cashReceiptNumber = this.cancelCashPaymentForm.value.ReceiptNumber ?? '';
              }
            });

          } else if (resp != undefined && resp.status?.toLowerCase() == 'failed' && resp.errors.length > 0) {
            this.cashReciptError = "This cash refund reference number is already in use for the same policy. Please enter a different reference number.";
            this.isErrorOccured = true;
          }
          else {
            this.cancelCashRefundMessageEror =
              'There was an error processing your refund. Please contact Tokio Marine support';
            this.refundAllText = 'Refund Fail'
            this.isErrorOccured = true;
            this.callPaymentFailed();
          }
          this.loaderService.hide();
          this.isRefundAllClicked = false;
          this.isRefundClicked = false;
        },
        (err) => {
          this.loaderService.hide();
          this.isRefundAllClicked = false;
          this.isRefundClicked = false;
          this.isErrorOccured = true;
          this.cancelCashRefundMessageEror =
            'There was an error processing your refund. Please contact Tokio Marine support';
          this.refundAllText = 'Refund Fail'
          this.callPaymentFailed();
        },
      );
  }
  //     //this.policyService.saveRefundPaymentRecieptRequest(this.issueEndorsementGTA)
  //     //  .subscribe(resp => {
  //     //    if (resp.Status != undefined && resp.Status == "SUCCESS") {
  //     //      this.modalService.dismissAll();
  //     //      if (resp.PaymentData != undefined) {
  //     //        let filterdata = resp.PaymentData.filter(f => f.PaymentAmount !== "0");
  //     //        this.paymentRefundsData = filterdata;
  //     //      }
  //     //      this.resendCoiCustEmail();
  //     //      this.open(this.dialogManageRefundsBox, { Msg: "SUCCESS" });
  //     //    } else if (resp.ResponseStatus != undefined && resp.ResponseStatus.ErrorCode != null) {
  //     //      this.open(this.warningBox, { Error: resp.ResponseStatus.Message });
  //     //    } else {
  //     //      this.open(this.warningBox, { Error: resp.Errors[0] });
  //     //    }
  //     //  });
  //   }
  // }

  // callMsgrewriteGTA(resp: any) {
  //   let policyTxnId = sessionStorage.getItem('movementGuid');
  //   if (resp.PaymentData != undefined) {
  //     this.modalService.dismissAll();
  //     if (resp.PaymentData != undefined) {
  //       let filterdata = resp.PaymentData.filter(
  //         (f: any) => f.PaymentAmount !== '0',
  //       );
  //       this.paymentRefundsData = filterdata;
  //     }
  //     this.resendCoiCustEmail();
  //     this.open(this.dialogManageRefundsBox, { Msg: 'SUCCESS' });
  //   } else if (
  //     resp.ResponseStatus != undefined &&
  //     resp.ResponseStatus.ErrorCode != null
  //   ) {
  //     this.open(this.warningBox, { Error: resp.ResponseStatus.Message });
  //   } else {
  //     this.open(this.warningBox, {
  //       Error: resp.IssueRewriteResponse.Errors[0],
  //     });
  //   }
  // }

  cashItemRefund() {
    // this.selectedTransaction.paymentRefNumber =
    //   this.cancelCashPaymentForm.value.ReceiptNumber; //this.cancelCashPaymentForm.controls["ReceiptNumber"].value();
    // this.finalCanecellation.push(this.selectedTransaction);
    this.getDisabledStatusForSubmit();
    this.modelcancelCashPaymentRefundRef.close();
    this.savePaymentRecipt();
  }

  openCashRefundModel() {
    this.modelcancelCashPaymentRefundRef = this.modalService.open(
      this.dialogManageCashRefundsBox,
      {
        size: 'lg',
        backdrop: 'static',
        keyboard: false,
      },
    );
  }

  getDisabledStatus(index: any) {
    //this.loaderService.show()
    if (!this.finalCanecellation) {
      return false;
    }

    return !this.finalCanecellation[index] ? false : true;
  }

  getDisabledStatusForSubmit() {
    if (
      this.finalCanecellation &&
      this.finalCanecellation.length == this.paymentRefundsData.length
    ) {
      this.cancelButton = false;
    } else {
      this.cancelButton = true;
    }
  }

  setRefundText(item: any, index: any) {
    if (
      this.finalCanecellation != undefined &&
      this.finalCanecellation.length > 0
    ) {
      if (this.finalCanecellation.filter((f: { brainTreeRefundStatus: boolean; }) => f.brainTreeRefundStatus === true).length == this.paymentRefundsData.length) {
        this.refundAllText = 'Close';
        this.isRefundAllDone = true;
        this.CancelAllPolicyMessage = "Policy has been refunded successfully, Please close this window to proceed.";
        return 'Issued';
      }
      if (
        this.finalCanecellation.filter(
          (f: any) =>
            f.paymentRefNumber === item.paymentRefNumber,
        ).length != 0
      ) {
        let filterItem = this.finalCanecellation.filter(
          (f: any) =>
            f.paymentRefNumber === item.paymentRefNumber,
        );
        if (filterItem != undefined) {
          if (
            filterItem[0].brainTreeRefundStatus &&
            filterItem[0].ItemIndex == index
          ) {
            return 'Issued';
          } else if (!filterItem[0].brainTreeRefundStatus) {
            return 'Refund Failed';
          }
        } else {
          return 'Initiate Refund';
        }
      } else {
        return 'Initiate Refund';
      }
    } else {
      return 'Initiate Refund';
    }
  }
  callPaymentFailed(item?: any) {
    let filteredData = item != undefined
      ? this.paymentRefundsData.filter((obj: any) => obj.paymentRefNumber == item.paymentRefNumber)
      : this.paymentRefundsData
    for (let itemPayment of filteredData) {
      if (
        this.finalCanecellation != undefined &&
        this.finalCanecellation.length > 0
      ) {
        if (
          this.finalCanecellation.filter(
            (f: any) =>
              f.paymentRefNumber ===
              itemPayment.paymentRefNumber,
          ).length == 0
        ) {
          let failedItem = itemPayment;
          failedItem.brainTreeRefundStatus = false;
          this.finalCanecellation.push(failedItem);
        }
      } else {
        let failedItem = itemPayment;
        failedItem.brainTreeRefundStatus = false;
        this.finalCanecellation.push(failedItem);
      }
    }
  }
  //callPaymentFailed() {
  //  for (let itemPayment of this.paymentRefundsData) {
  //    if (this.finalCanecellation != undefined && this.finalCanecellation.length > 0) {
  //      if (this.finalCanecellation.filter(f => f.paymentRefNumber === itemPayment.paymentRefNumber).length == 0) {
  //        let failedItem = itemPayment;
  //        failedItem.brainTreeRefundStatus = false;
  //        this.finalCanecellation.push(failedItem);
  //      }
  //    }
  //    else {
  //      let failedItem = itemPayment;
  //      failedItem.brainTreeRefundStatus = false;
  //      this.finalCanecellation.push(failedItem);
  //    }
  //  }
  //}
  confirmRefundPay(item: any, index: any): void {
    // this.loaderService.show();

    // this.policyService
    //   .getPolicyStatus(this.policyId, this.currentPolicyTxnNum)
    //   .subscribe((response) => {
    //     if (response && response !== null && response !== undefined) {
    //       let processStatCode = 'p';
    //       if (
    //         response.CreateActivityLogItem &&
    //         response.CreateActivityLogItem.ActivityType.toLowerCase() !==
    //           'paymentdone' &&
    //         response.CreateActivityLogItem.ActivityType.toLowerCase() !==
    //           'complete'
    //       ) {
    //         this.intialRefundAmount = sessionStorage.getItem(
    //           'initiateRefundAmount',
    //         );

    //         this.initiateCurrentRefund =
    //           this.intialRefundAmount != null
    //             ? JSON.parse(JSON.stringify(this.intialRefundAmount))
    //             : null;
    //         //Cancel - Rewrite
    //         if (
    //           response.TxnTypeCode.toLowerCase() == 'rw' &&
    //           response.TxnStatusCode.toLowerCase() == 'pr'
    //         ) {
    //           this.initiateCurrentRefund = (
    //             this.previousEndorsementAmount - Number(this.intialRefundAmount)
    //           ).toFixed(2);
    //         }
    //         //ReActivate
    //         if (
    //           response.TxnTypeCode.toLowerCase() == 're' &&
    //           response.TxnStatusCode.toLowerCase() == 're'
    //         ) {
    //           processStatCode = 'a';
    //         }
    //         //New Promocode added/removed
    //         if (
    //           response.TxnTypeCode.toLowerCase() == 'en' &&
    //           response.TxnStatusCode.toLowerCase() == 'pe' &&
    //           response.ProcessStatusCode.toLowerCase() == 'a'
    //         ) {
    //           processStatCode = 'a';
    //         }

    //         //Check tolerance while comparing amounts
    //         let isEqualAmountWithTolerance = isClose(
    //           this.currentEndorsementAmount,
    //           this.initiateCurrentRefund,
    //           this.siteCoreSettings.PaymentAmountBufferSize,
    //           undefined,
    //           undefined,
    //           IsCloseScalers.absA,
    //         );

    //         if (
    //           isEqualAmountWithTolerance &&
    //           this.currentEndoProcessStatusCode.toLowerCase() ==
    //             processStatCode &&
    //           this.currentEndoProcessStatus.toLowerCase() == 'in process'
    //         ) {
    //           this.selectedTransaction = item;
    //           if (item.PaymentType == 'CC') {
    //             this.createPurchaseURL =
    //               location.protocol +
    //               '//' +
    //               location.host +
    //               '/GTP_PASPartialSettlement/QuotePayment';
    //             this.paymentTransactionID = item.paymentRefNumber;
    //             this.createPurchase(
    //               this.createPurchaseURL,
    //               'CC',
    //               item.PaymentAmount,
    //               this.siteName,
    //               this.paymentTransactionID,
    //             ).subscribe(
    //               (result: any) => {
    //                 this.cancelButton = false;
    //                 if (result.Target == undefined) {
    //                   this.cancelCashRefundMessageEror =
    //                     'There was an error processing your refund. Please contact Tokio Marine support';
    //                   this.callPaymentFailed();
    //                   //this.getDisabledStatusForSubmit();
    //                 } else if (result.Target != null) {
    //                   item.brainTreeRefundStatus = true;
    //                   item.ItemIndex = index;
    //                   sessionStorage.setItem(
    //                     'originalPolicyTxnId',
    //                     this.policyTxnId.toString(),
    //                   );
    //                   this.finalCanecellation.push(item);
    //                   //this.getDisabledStatusForSubmit();
    //                 }
    //                 this.loaderService.hide();
    //               },
    //               (err: any) => {
    //                 this.cancelButton = false;
    //                 this.loaderService.hide();
    //                 this.cancelCashRefundMessageEror =
    //                   'There was an error processing your refund. Please contact Tokio Marine support';
    //                 this.callPaymentFailed();
    //                 //this.getDisabledStatusForSubmit();
    //               },
    //             );
    //           } else if (item.PaymentType === 'PC') {
    //             this.cancelButton = false;
    //             item.brainTreeRefundStatus = true;
    //             item.ItemIndex = index;
    //             this.cancelCashRefundMessageEror = '';
    //             this.openCashRefundModel();
    //             this.loaderService.hide();
    //             return;
    //           }
    //         } else {
    //           this.loaderService.hide();
    //           let errorMsg =
    //             this.siteCoreSettings != null &&
    //             this.siteCoreSettings.Payment_Already_Done.length > 0
    //               ? this.siteCoreSettings.Payment_Already_Done
    //               : 'Something went wrong please try again.';
    //           this.open(this.warningBox, { Error: errorMsg });
    //         }
    //       } else {
    //         this.loaderService.hide();
    //         let errorMsg =
    //           this.siteCoreSettings != null &&
    //           this.siteCoreSettings.Payment_Already_Done.length > 0
    //             ? this.siteCoreSettings.Payment_Already_Done
    //             : 'Something went wrong please try again.';
    //         this.open(this.warningBox, { Error: errorMsg });
    //       }
    //     }
    //   });
  }

  // callClosePaybleGTA() {
  //   this.loaderService.show();
  //   this.issueEndorsementGTA.PolicyTxnId = this.policyTxnId.toString();
  //   this.issueEndorsementGTA.PaymentData = [];
  //   //update Travellers details if updated or else call issue Endorsement
  //   if (
  //     sessionStorage.getItem('updatedContact') &&
  //     sessionStorage.getItem('isContactModified')
  //   ) {
  //     var updatedContactTxnId = JSON.parse(
  //       sessionStorage.getItem('isContactModified') || '',
  //     ).PolicyTxnId.toString();
  //     if (updatedContactTxnId == this.policyTxnId) {
  //       this.travelerUpdateService.updateEndorsementService('ManageRefund');
  //     } else {
  //       sessionStorage.removeItem('updatedContact');
  //       sessionStorage.removeItem('isContactModified');
  //       this.issueEndorsementCall();
  //     }
  //   } else {
  //     this.issueEndorsementCall();
  //   }
  // }

  open(content: any, rowdata: any) {
    const modal: NgbModalRef = this.modalService.open(content, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    this.modalData = rowdata;
    modal.result.then(
      (result) => {
        //console.log(result);
      },
      (reason) => {
        //console.log(reason);
      },
    );
  }

  closeModal() {
    this.modalService.dismissAll();
    location.href = '/policysummary';
  }

  closeModalConfirmation(status?: any) {
    this.modelManangeRefundsRef.close();
    if (this.isErrorOccured == true) {
      location.href = '/policysummary';
    }
    else if (status == null) {
    }
    else {
      location.href = location.protocol + '//' + location.host + '/policy-detail';
    }
  }
  closeCashRefundModel() {
    this.modelcancelCashPaymentRefundRef.close();
    this.loaderService.hide();
    this.isRefundAllClicked = false;
    this.isRefundClicked = false;
  }
  formatprice(value: any) {
    let formattedvalue = this.priceService.getPriceFixed(value, 2);
    return formattedvalue;
  }

  resendCoiCustEmail() {
    let coiFormSetId = sessionStorage.getItem('coiFormSetId');
    if (!coiFormSetId) {
      coiFormSetId = this.policyService.RESEND_COI;
    }
    let reqObj = {
      PolicyTxnId: sessionStorage.getItem('movementGuid'),
      FormsetId: coiFormSetId,
      SubCompanyCode: 'GTA',
      NoticeTextCollection: [],
      CreateActivityLogItem: {
        PolicyTxnId: sessionStorage.getItem('movementGuid'),
        ActivityType: 'NewGenEmail',
        ActivityDate: new Date(),
        Description: 'COI Sent',
        LoggedInUser: this.userProfile?.LoggedUser,
      },
    };
    let sendMail = sessionStorage.getItem('sendCoiCheckBoxValue');
    if (sendMail && sendMail == 'true') {
      this.policyService.resendCoi(reqObj).subscribe(
        (data) => {
          //console.log('success');
        },
        (err) => {
          //console.log('fail');
        },
      );
    }
  }

  // issueEndorsementCall() {
  //   this.policyService
  //     .callEndorsementOrRewrite(this.issueEndorsementGTA, this.policyStatus)
  //     .subscribe((resp) => {
  //       this.loaderService.hide();
  //       this.resendCoiCustEmail();

  //       this.cancelCashRefundMessageEror = '';
  //       let policyRefundDetails = [];

  //       let postData: any = {
  //         PolicyTxnId: this.policyTxnId.toString(),
  //         policyRefundDetails: [],
  //       };

  //       for (let index in this.finalCanecellation) {
  //         let item = this.finalCanecellation[index];

  //         if (item.brainTreeRefundStatus) {
  //           let itempostData = {
  //             TransactionId: item.paymentRefNumber,
  //             RefundType: item.PaymentType,
  //             RefundAmount: item.PaymentAmount,
  //             RefundStatus: 'Y',
  //             UWDataElementId: item.UWDataElementId,
  //             RowNumber: item.RowNum,
  //           };

  //           policyRefundDetails.push(itempostData);
  //         }
  //       }

  //       postData.policyRefundDetails = policyRefundDetails;
  //       this.policyService.closePaybleGTA(postData).subscribe((resp) => {
  //         if (resp.Status != undefined) {
  //           sessionStorage.setItem(
  //             'originalPolicyTxnId',
  //             this.policyTxnId.toString(),
  //           );
  //           this.modalService.dismissAll();
  //           this.open(this.confirmationBox, {
  //             Msg: 'Refund has been successfully issued.',
  //           });
  //         } else if (
  //           resp.ResponseStatus != undefined &&
  //           resp.ResponseStatus.ErrorCode != null
  //         ) {
  //           this.open(this.warningBox, { Error: resp.ResponseStatus.Message });
  //         } else {
  //           this.open(this.warningBox, { Error: resp.Errors[0] });
  //         }
  //       });
  //     });
  // }
}
