<ng-container>
  <div
    *ngIf="policyStatus.length == 0 || isLoaderPolicyStatus == true"
    class="text-center"
    style="min-height: 30%"
  >
    <br />
    <br />
    <br />
    <br />
    <span
      [ngClass]="{ 'spinner-border text-primary': isLoaderPolicyStatus }"
      role="status"
      aria-hidden="false"
    ></span>
  </div>
</ng-container>
<section *ngIf="isLoaderPolicyStatus == false">
  <div class="container">
    <div class="row d-flex mt-lg-5">
      <div class="col-12 col-md-28 circle-tabs pt-10">
        <h2 class="mb-3 box-label">{{ policyStatus.Heading }}</h2>
        <ul class="nav nav-tabs row">
          <li
            class="nav-item col-6 text-center nopadding"
            *ngFor="
              let item of policyStatus.LeftDashboardPolicyData;
              let i = index
            "
          >
            <div class="nav-link active" data-toggle="tab">
              <div class="number-circle">{{ item.Count }}</div>
              <h4 class="circle-label">{{ item.Description }}</h4>
            </div>
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-72">
        <div class="active-bg">
          <div class="py-2 px-2 col-6 col-lg-3">
            <select
              class="form-control"
              style="height: 32px"
            >
              <option
                *ngFor="let x of policyStatus.Duration"
                [value]="x.Month"
                [selected]="x.Month == x.SelectedValue"
              >
                {{ x.Title }}
              </option>
            </select>
          </div>
          <section class="circle-tabs">
            <ul class="nav nav-tabs">
              <li
                class="nav-item col-sm-50 col-md-20 text-center nopadding"
                *ngFor="
                  let item of policyStatus.RightDashboardPolicyData;
                  let i = index
                "
              >
                <div
                  class="nav-link"
                  data-toggle="tab"
                  *ngIf="
                    item.KeyText != 'CommissionsPaid' &&
                    item.KeyText != 'TotalGrossWrittenPremium'
                      ? true
                      : false
                  "
                >
                  <div class="number-circle number-circle-ex">
                    {{ item.Count }}
                  </div>
                  <h4 class="circle-label circle-label1">
                    {{ item.Description }}
                  </h4>
                </div>
                <div
                  class="nav-link"
                  data-toggle="tab"
                  *ngIf="
                    item.KeyText == 'CommissionsPaid' ||
                    item.KeyText == 'TotalGrossWrittenPremium'
                      ? true
                      : false
                  "
                >
                  <div class="number-circle number-circle-ex">
                    {{ item.Count | currency: 'USD' : 'symbol' }}
                  </div>
                  <h4 class="circle-label circle-label1">
                    {{ item.Description }}
                  </h4>
                </div>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>
