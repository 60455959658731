<ng-container *ngIf="filterArray.length > 0">
<div class="container">
  <h2 class="mt-5 mb-3 box-label">Search</h2>
  <div class="custom-control custom-radio custom-control-inline">
    <input type="radio" class="custom-control-input" [(ngModel)]="modelRadio.options" id="Policies" value="Policy"
      (click)="radioButtonClick('Policy')" />
    <label class="custom-control-label" for="Policies">Policies</label>
  </div>

  <div class="custom-control custom-radio custom-control-inline">
    <input type="radio" class="custom-control-input" [(ngModel)]="modelRadio.options" id="Quotes" value="Quotes"
      (click)="radioButtonClick('Quotes')" />
    <label class="custom-control-label" for="Quotes">Quotes</label>
  </div>
  <!--<ng-container *ngIf="!isReadOnly">
    <div class="custom-control custom-radio custom-control-inline">
      <input type="radio" class="custom-control-input" [(ngModel)]="modelRadio.options" id="Quotes" value="Quotes" (click)="radioButtonClick('Quotes')">
      <label class="custom-control-label" for="Quotes">Quotes</label>
    </div>
  </ng-container>-->
</div>
<!-- <ng-container>
  <div *ngIf="dtOptions.length == 0 || isLoaderPolicyGrid == true" class="text-center" style="min-height: 100%">
    <br />
    <br />
    <span [ngClass]="{ 'spinner-border text-primary': isLoaderPolicyGrid }" role="status" aria-hidden="false"></span>
  </div>
</ng-container> -->
<ng-container class="text-center" style="min-height: 100%">
  <!-- <div *ngFor="let gridItem of dtOptions; let i = index"> -->
  <section>
    <div class="container">
      <form #formRef="ngForm">
        <div class="form-row mt-3" style="margin-bottom: -2rem !important">
          <div class="form-group {{ filterArray[in].ColumnType }}" *ngFor="let filter of filterArray; let in = index">
            <ng-container *ngIf="in <= 4">
              <input [(ngModel)]="filterArray[in].Value" (beforeinput)="
                    checkvalidationwithspacesorspecial(filter, $event)
                  " name="name{{ in }}" class="form-control" #name="ngModel" required *ngIf="
                  filterArray[in].FieldType.toLowerCase() ==
                    'textbox'
                      ? true
                      : false
                  " placeholder="{{ filterArray[in].Description }}" />

              <div class="input-group" *ngIf="
                filterArray[in].FieldType.toLowerCase() ==
                      'calendar' &&
                      filterArray[in].KeyText.toLowerCase() ==
                      'purchaseddate'
                      ? true
                      : false
                  ">
                <input type="text" [(ngModel)]="filterArray[in].Value" name="name{{ in }}" class="form-control"
                  #name="ngModel" required placeholder="{{ filterArray[in].Description }}" ngbDatepicker
                  #PurchasedDate="ngbDatepicker" autocomplete="off" />

                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" type="button"
                    (click)="PurchasedDate.toggle()"></button>
                </div>
              </div>

              <div class="input-group" *ngIf="
                filterArray[in].FieldType.toLowerCase() ==
                      'calendar' &&
                      filterArray[in].KeyText.toLowerCase() ==
                      'effectivedate'
                      ? true
                      : false
                  ">
                <input type="text" [(ngModel)]="filterArray[in].Value" (dateSelect)="checkcalendarInput(filter, $event)"
                  (keyup)="checkcalendarInput(filter, $event)" name="name{{ in }}" class="form-control" #name="ngModel"
                  required placeholder="{{ filterArray[in].Description }}"
                  [ngClass]="{ 'is-invalid': isInputInvalid && filter.error }" ngbDatepicker
                  #EffectiveDate="ngbDatepicker" autocomplete="off" [maxDate]="maxDate"/>

                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" type="button"
                    (click)="EffectiveDate.toggle()"></button>
                </div>
                <div *ngIf="isInputInvalid && filter.error" class="invalid-feedback">
                  <div>Invalid Effective Date</div>
                </div>
              </div>

              <div class="input-group" *ngIf="
                filterArray[in].FieldType.toLowerCase() ==
                      'calendar' &&
                      filterArray[in].KeyText.toLowerCase() ==
                      'expirationdate'
                      ? true
                      : false
                  ">
                <input [(ngModel)]="filterArray[in].Value" (dateSelect)="checkcalendarInput(filter, $event)"
                  (keyup)="checkcalendarInput(filter, $event)" name="name{{ in }}" class="form-control" #name="ngModel"
                  required placeholder="{{ filterArray[in].Description }}"
                  [ngClass]="{ 'is-invalid': isInputInvalid && filter.error }" ngbDatepicker
                  #ExpirationDate="ngbDatepicker" autocomplete="off"  [maxDate]="maxDate"/>

                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" type="button"
                    (click)="ExpirationDate.toggle()"></button>
                </div>
                <div *ngIf="isInputInvalid && filter.error" class="invalid-feedback">
                  <div>Invalid Expiration Date</div>
                </div>
              </div>

              <div class="input-group" *ngIf="
                filterArray[in].FieldType.toLowerCase() ==
                      'calendar' &&
                      filterArray[in].KeyText.toLowerCase() ==
                      'quotedate'
                      ? true
                      : false
                  ">
                <input [(ngModel)]="filterArray[in].Value" name="name{{ in }}" class="form-control" #name="ngModel"
                  required placeholder="{{ filterArray[in].Description }}" ngbDatepicker #QuoteDate="ngbDatepicker"
                  autocomplete="off" />

                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" type="button"
                    (click)="QuoteDate.toggle()"></button>
                </div>
              </div>

              <select class="form-control" [(ngModel)]="filterArray[in].Value" name="name{{ in }}" #name="ngModel"
                *ngIf="
                  filterArray[in].FieldType.toLowerCase() ==
                    'select'
                      ? true
                      : false
                  ">
                <option value="" selected="selected">
                  -- {{ filterArray[in].Description }} --
                </option>
                <option *ngFor="let x of filterArray[in].Value" [value]="x[in].Description"
                  [selected]="x.Value == filterArray[in].Value">
                  {{ x[in].Description }}
                </option>
              </select>
            </ng-container>
          </div>
        </div>
        <a href="javascript:void(0);" id="advance-search-btn" (click)="toggle()">Advanced Search
          <i class="fa fa-chevron-down custom-icon" aria-hidden="true"></i></a>
        <div class="form-row mt-3" id="advance-search" style="margin-top: 1rem !important" *ngIf="advanceSearchPanel">
          <ng-container *ngFor="let filter of filterArray; let in = index">
            <ng-container *ngIf="in >= 5">
              <div class="form-group {{ filterArray[in].ColumnType }}">
                <input type="text" (beforeinput)="
                      checkvalidationwithspacesorspecial(filter, $event)
                    " [(ngModel)]="filterArray[in].Value" name="name{{ in }}" class="form-control" #name="ngModel"
                  required *ngIf="
                    filterArray[in].FieldType.toLowerCase() ==
                      'textbox'
                        ? true
                        : false
                    " placeholder="{{ filterArray[in].Description }}" />

                <select class="form-control" [(ngModel)]="filterArray[in].Value" name="name{{ in }}" #name="ngModel"
                  (change)="onClubSelect($event, dtOptions[0], 'clubname')" *ngIf="
                    filterArray[in].FieldType.toLowerCase() ==
                        'select' &&
                        filterArray[in].KeyText.toLowerCase() ==
                        'clubname'
                        ? true
                        : false
                    ">
                  <option value="" selected="selected">
                    -- {{ filterArray[in].Description }} --
                  </option>
                  <option *ngFor="let x of listClubs" [value]="x.autoClubGuid">
                    {{ x.link_Url?.title }}
                  </option>
                </select>

                <select class="form-control" [(ngModel)]="filterArray[in].Value" name="name{{ in }}" #name="ngModel"
                  (change)="onBranchSelect($event, dtOptions[0], 'branchname')" *ngIf="
                    filterArray[in].FieldType.toLowerCase() ==
                        'select' &&
                        filterArray[in].KeyText.toLowerCase() ==
                        'branchname'
                        ? true
                        : false
                    ">
                  <option value="" selected="selected">
                    -- {{ filterArray[in].Description }} --
                  </option>
                  <option *ngFor="let x of listBranches" [value]="x.branchGuid">
                    {{ x.name }}
                  </option>
                </select>

                <select class="form-control" [(ngModel)]="filterArray[in].Value" name="name{{ in }}" #name="ngModel"
                  *ngIf="
                    filterArray[in].FieldType.toLowerCase() ==
                        'select' &&
                        filterArray[in].KeyText.toLowerCase() ==
                        'agentname'
                        ? true
                        : false
                    ">
                  <option value="" selected="selected">
                    -- {{ filterArray[in].Description }} --
                  </option>
                  <option *ngFor="let x of fnlagentlist" [value]="x.agentGuid">
                    {{ x.agentName}}
                  </option>
                </select>
              </div>
            </ng-container>
          </ng-container>
        </div>
        <div class="row">
          <div class="col-md-12 mt-1 text-center btn-footer">
            <div *ngIf="noSearchValueSelectedError">
              <p class="alert alert-danger mt-3">
                <strong>At least one field in the Search section must be selected
                  in order for the search to return the results.</strong>
              </p>
            </div>
            <button class="btn btn-primary btn-full-sm {{isLoaderSearchPolicyGrid ? 'disabled' : ''}}" id="apply"
              type="submit" (click)="
                  displayCustomSearchToConsole($event, dtOptions[0], 0, 'search', formRef)
                ">
              Search
            </button>
            <button class="btn btn-secondary btn-full-sm" id="clear" type="submit" (click)="
                  displayCustomSearchToConsole($event, dtOptions[0], 0, 'allClear')
                ">
              Clear
            </button>
          </div>
        </div>
      </form>
    </div>
  </section>
  <section *ngIf="isGridResult == true && dtOptions[0] != null" [style]="isSearch ? 'display:none':''">
    <div class="container">
      <div class="policygrid">
        <h2 class="mt-4 box-label">{{ dtOptions[0].gridDisplayName }}</h2>
        <table datatable [dtOptions]="dtOptions[0]" attr.name="{{ dtOptions[0].gridName }} "
          class="table table-striped table-bordered dataTable no-footer dtr-inline w-100" style="width: 100%">
          <thead>
            <tr>
              <th *ngFor="let column of columnArray">
                {{ column.title }}
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </section>

  <ng-container>
    <div *ngIf="dtSearchOptions.length == 0 || isLoaderSearchPolicyGrid == true" class="text-center"
      style="min-height: 100px">
      <br />
      <br />
      <span [ngClass]="{
            'spinner-border text-primary': isLoaderSearchPolicyGrid
          }" role="status" aria-hidden="false"></span>
    </div>
  </ng-container>

  <ng-container *ngIf="dtSearchOptions.length > 0 && isAdvanceSearchGridResult == true" class="text-center">
    <div *ngFor="let gridSearchItem of dtSearchOptions; let i = index" style="min-height: 100%">
      <section>
        <div class="container">
          <h2 class="mt-4 box-label">{{ gridSearchItem.gridDisplayName }}</h2>
          <div class="policygrid">
            <table datatable [dtOptions]="gridSearchItem" attr.name="{{ gridSearchItem.gridName }} "
              class="table table-striped table-bordered dataTable no-footer dtr-inline w-100" style="width: 100%">
              <thead>
                <tr>
                  <th *ngFor="let column of gridSearchItem.columns">
                    {{ column.title }}
                  </th>
                </tr>
              </thead>
            </table>
          </div>
        </div>
      </section>
    </div>
  </ng-container>
  <!-- </div> -->
</ng-container>
</ng-container>