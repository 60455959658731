import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PortalCodeService } from '../../../../src/app/core/portalcode/portalcode';

@Injectable()
export class NgxBraintreeService {
  constructor(
    private http: HttpClient,
    private portalCodeService: PortalCodeService,
  ) {}

  getClientToken(clientTokenURL: string): Observable<any> {
    return this.http.get(clientTokenURL, { responseType: 'json' }).pipe(
      map((response: any) => {
        return response.token;
      }),
      catchError((error) => {
        return error;
      }),
    );
  }

//   createPurchase(
//     createPurchaseURL: string,
//     nonce: string,
//     chargeAmount: number,
//     siteName: string,
//     paymentTransactionID: any,
//   ): Observable<any> {
//     if (sessionStorage.getItem('portalcode')) {
//       siteName = sessionStorage.getItem('portalcode') || '';
//     }
//     siteName =
//       siteName != '' && siteName != null
//         ? siteName
//         : this.portalCodeService.getPortalSiteName();
//     const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
//     return this.http
//       .post(
//         createPurchaseURL,
//         {
//           nonce: nonce,
//           chargeAmount: chargeAmount,
//           siteName: siteName,
//           transectionid: paymentTransactionID,
//         },
//         { headers: headers },
//       )
//       .pipe(
//         map((response: any) => {
//           return response;
//         }),
//       );
//   }
}
