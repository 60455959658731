<app-loader></app-loader>
<div class="card-active">
  <h3 class="card-active-header text-center"> Policy# {{ policyDetail?.policyNumber }} </h3>
  <ul class="list-group list-group-flush">
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <strong>Plan</strong>
        </div>
        <div class="col-6 text-right"> {{ coverageType }} </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <strong> Purchased Date</strong>
        </div>
        <div class="col-6 text-right"> {{ policyDetail?.purchaseDate | utcToShortDatePipe }} </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <strong>Pricing Date</strong>
        </div>
        <div class="col-6 text-right"> {{ policyDetail?.rateDate | utcToShortDatePipe }} </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <strong>Payment</strong>
        </div>
        <div class="col-6 text-right"> {{ (policyDetail!==undefined && policyDetail.premiums!=undefined && policyDetail.premiums.totalCost != undefined ? getPriceFixed(policyDetail.premiums.totalCost, 2) : '0' ) | currency }} </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <strong>Autoclub</strong>
        </div>
        <div class="col-6 text-right">
          <span *ngIf="policyDetail"> {{ policyDetail?.autoClub }} </span>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <strong>Policy Status</strong>
        </div>
        <div class="col-6 text-right">
          <span *ngIf="policyDetail"> {{ policyDetail?.policyStatus }} </span>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div class="col-6">
          <strong>Agent Name</strong>
        </div>
        <div class="col-6 text-right"> {{ agentName }} </div>
      </div>
    </li>
    <li class="list-group-item" *ngIf="isPremium && premiumChanged != 'undefined' && premiumChanged != ''">
      <div class="row">
        <div class="col-6">
          <strong>Premium Change</strong>
        </div>
        <div class="col-6 text-right">
          <span *ngIf="premiumChanged"> {{ getPriceFixed(premiumChanged, 2) | currency }} </span>
        </div>
      </div>
    </li>
    <li class="list-group-item">
      <div class="row">
        <div [ngClass]="{ 'col-3': isUserUN, 'col-8': !isUserUN }"></div>
        <div class="col-4 text-right" *ngIf="isEditable && isEditBtnRequired && isUserAuthorisedToEdit">
          <a href="javascript:void(0);" data-toggle="modal" data-toggle="modal" class="card-active-link" (click)="openAgentModal()">Update Agent</a>
        </div>
        <div class="text-right" [ngClass]="{ 'col-5': isEditable, 'col-8': !isEditable }" *ngIf=" isUserUN && isEditable && isEditBtnRequired && isUserAuthorisedToEdit ">
          <a href="javascript:void(0);" data-toggle="modal" data-toggle="modal" class="card-active-link" (click)="openTmmaModal()">Update TMMA User</a>
        </div>
      </div>
    </li>
  </ul>
</div>

<ng-template #dialogContentTemplate>
  <app-loader></app-loader>
  <!-- Modal body -->
  <div class="modal-body">
    <form [formGroup]="agentForm">
      <div class="col-md-5 mx-auto">
        <div class="text-center mb-4 mt-4">
          <h5 class="mb-4">Update Agent</h5>
          <strong>Current Agent:</strong> {{ agentName }}
        </div>
        <div class="form-group col-md-12">
          <select id="inputState" class="form-control" formControlName="Agent" (change)="agentSelectionChange($event)">
            <option value=" ">Please Select Agent</option>
            <option *ngFor="let item of list" [value]="item.agentGuid">{{ item.fullName }}</option>
          </select>
        </div>
      </div>
      <div class="text-center mb-4 mt-4">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal" (click)="closeModal()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="updateAgent('agent')">
          Save Changes
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #dialogTmmaTemplate>
  <app-loader></app-loader>
  <!-- Modal body -->
  <div class="modal-body">
    <form [formGroup]="agentForm">
      <div class="col-md-5 mx-auto">
        <div class="text-center mb-4 mt-4">
          <h5 class="mb-4">Update TMMA User</h5>
          <strong>Current TMMA User:</strong> {{ agentName }}
        </div>
        <div class="form-group col-md-12">
          <select id="inputState" class="form-control" formControlName="Agent"
            (change)="agentSelectionChange($event)">
            <option value=" ">Please Select TMMA User</option>
            <option *ngFor="let item of list" [value]="item.agentGuid"> {{ item.fullName }}
            </option>
          </select>
        </div>
      </div>
      <div class="text-center mb-4 mt-4">
        <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal" (click)="closeModal()">
          Cancel
        </button>
        <button type="button" class="btn btn-primary" (click)="updateAgent('tmmaUser')">
          Save Changes
        </button>
      </div>
    </form>
  </div>
</ng-template>