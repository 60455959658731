import { createReducer, on, Action  } from "@ngrx/store";
import { setPolicy } from "./policy.actions";
import { Policy } from "./policy.model";

export interface PolicyState {
    policy  : Policy | null;
}

export const initialState : PolicyState  = {
    policy : null
};


const _policyReducer = createReducer(
    initialState,
    on(setPolicy, (state, {policy}) =>({
        ...state,
        policy
    }))
);

export function policyReducer(state: PolicyState | undefined, action: Action) {
    return _policyReducer(state, action);
}