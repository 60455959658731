/// <reference path="../../../../../src/app/core/role/setqueryparams.ts" />
/// <reference path="../../../../../src/app/core/role/checkrolestatus.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpClientModule, HttpHandler, } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EndPoint } from '../../../../../src/app/core/services/endpoint';
import { CommonParams, } from '../../../../../src/app/core/commonparams';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails } from 'src/app/core/store/loginUser/loginUser.model';
import { Store } from '@ngrx/store';
import { SearchRecentQuotesAndPoliciesGTA } from './app.model';
import { SetQueryParamsService } from '../../../../../src/app/core/role/setqueryparams';
import { RoleStatus } from '../../../../../src/app/core/role/checkrolestatus';
import { DateService } from '../../../../../src/app/core/services/date-updated.service';
import { HttpCoreService } from '../../../../../src/app/core/services/http-core.service';
import { agentSearchRequest, searchRequest } from './search-req.model';

@Injectable({
  providedIn: 'root',
})
export class PolicyDashboardGridService {
  userRoleDetails?: UserRoleDetails;
  constructor(
    private http: HttpClient,
    private handler: HttpHandler,
    private setQueryParamsService: SetQueryParamsService,
    private roleStatus: RoleStatus,
    private newDateService: DateService,
    private httpService: HttpCoreService,
    private store: Store
  ) {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
      }
    });
  }

  httpHeaders = {
    headers: new HttpHeaders({
    }),
  };

  getOctaSearchAgencyPortalApiServiceList(
    searchRecentQuotesAndPoliciesGTA: SearchRecentQuotesAndPoliciesGTA,
  ) {
    if (this.userRoleDetails &&
      this.roleStatus.checkUWStatusQueryParams(this.userRoleDetails) == 'true' &&
      searchRecentQuotesAndPoliciesGTA.AgencyId == null
    ) {
      searchRecentQuotesAndPoliciesGTA.AgencyId = '';
    } else {
      searchRecentQuotesAndPoliciesGTA.AgencyId =
        searchRecentQuotesAndPoliciesGTA.AgencyId != '' &&
          searchRecentQuotesAndPoliciesGTA.AgencyId != null
          ? searchRecentQuotesAndPoliciesGTA.AgencyId
          : this.userRoleDetails?.AgencyContactId;
    }

    searchRecentQuotesAndPoliciesGTA.SubCompanyCode =
      CommonParams.SubCompanyCode;
    if (this.userRoleDetails) {
      searchRecentQuotesAndPoliciesGTA.IsUnderwriter =
        this.roleStatus.checkUWStatusQueryParams(this.userRoleDetails);
    }
  }

  getSearchPoliciesNQuotes(searchFor: string, req?: searchRequest) {
    if (req != undefined) {
      req.loginAgentGuid = this.userRoleDetails?.AgentContactId
    }
    // Policy Quotes
    if (searchFor === 'Policy') {
      return this.http.post<any>(
        `${EndPoint.LTX_ENDPOINT + EndPoint.PolicySearch}`, req, this.httpHeaders,
      );
    }
    if (searchFor === 'Quotes') {
      return this.http.post<any>(
        `${EndPoint.LTX_ENDPOINT + EndPoint.QuoteSearch}`, req, this.httpHeaders,
      );
    }
    return throwError(() => 'Wrong Server Request');
  }

  getSiteCoreAgencyTotalList(gridname: string) {
    return this.http.get(
      EndPoint.API_ENDPOINT + '/PASDataService/DataTable/?gridname=' + gridname,
    );
  }

  getAgentDetailService(req?: agentSearchRequest) {
    if (req != undefined) {
      req.loginAgentGuid = this.userRoleDetails?.AgentContactId;
    }
    return this.http.post<any>(
      `${EndPoint.LTX_ENDPOINT}api/pas/agent/search`, req, this.httpHeaders,
    );
  }
  getAgentDetail(autoClubGuid?: any, branchGuid?: any) {
    return this.http.get<any>(
      `${EndPoint.LTX_ENDPOINT + EndPoint.getAgentDetail}${this.userRoleDetails?.AgentContactId}&autoClubGuid=${autoClubGuid}&branchGuid=${branchGuid}`, this.httpHeaders,
    );
  }

  utcToAestDate(utcDate: string) {
    let aestTime = this.newDateService.getDateAsOfAESTFromDate(
      this.newDateService.getMomentFromDate(utcDate),
    );
    return new Date(aestTime);
  }

  //LTX
  getCountryList(): Observable<any> {
    return this.httpService.getRequest(`${EndPoint.PAS_COUNTRIES}`,
    );
  }
}
