import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PortalCodeService } from '../../../../src/app/core/portalcode/portalcode';
import { EndPoint } from 'src/app/core/services/endpoint';

@Injectable()
export class NgxBraintreeService {
  public createPurchaseURL : string = "";
  constructor(
    private http: HttpClient,
    private portalCodeService: PortalCodeService,
  ) { }

  getClientToken(clientTokenURL: string): Observable<any> {
    return this.http.get(clientTokenURL, { responseType: 'text' }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error) => {
        throw error;
      }),
    );
  }

  createPurchase(x_api_key: string, purchaseObject: any): Observable<any> {
    let httpHeaders = new HttpHeaders({
      'x-api-key': x_api_key,
      'Content-Type': 'application/json; charset=utf-8',
    });
    return this.http
      .post(this.createPurchaseURL, purchaseObject, { headers: httpHeaders })
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }


  sendPolicy(policyObject: any): Observable<any> {
    var sendPolicyUrl = `${EndPoint.PAS_COI_REGENERATE}`;
    return this.http
      .post(sendPolicyUrl, policyObject)
      .pipe(
        map((response: any) => {
          return response;
        }),
      );
  }
}
