import { Component, OnInit, Input, ViewChild, TemplateRef, ElementRef, } from '@angular/core';
import { NgbModalRef, NgbModal, } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, AbstractControl, Validators, } from '@angular/forms';
import { PolicyDetailService } from '../../app.services';
import { DataBusService } from '../../services/databus.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserProfile } from 'src/app/core/store/loginUser/loginUser.model';

@Component({
  selector: 'policy-version-modal',
  templateUrl: './version-modal.component.html',
  styleUrls: ['version-modal.component.css'],
})
export class PolicyVersionModal implements OnInit {
  paymentType: any;
  paymentStatus: any;
  paymentAmount: any;
  paymentConfirmation: any;
  paymentForm!: FormGroup;
  refundForm!: FormGroup;
  CreatePayment!: FormGroup;
  createRefundPayment!: FormGroup;
  paymentObj: any;
  submitted = false;
  modalRef!: NgbModalRef;
  currentRowVal!: number;
  transactionType!: string;
  showCnfrmation!: boolean;
  userProfile?: UserProfile;
  @ViewChild('dialogContentTemplate', { static: true })
  dialogContent!: TemplateRef<ElementRef>;
  @ViewChild('dialogContentTemplate1', { static: true })
  dialogContent1!: TemplateRef<ElementRef>;
  @ViewChild('dialogContentTemplatePayment', { static: true })
  dialogContentPayment!: TemplateRef<ElementRef>;
  @ViewChild('dialogContentTemplateRefund', { static: true })
  dialogContentRefund!: TemplateRef<ElementRef>;
  @ViewChild('dialogContentTemplateDeletePayment', { static: true })
  dialogContentDelete!: TemplateRef<ElementRef>;
  @ViewChild('dialogContentTemplateDeleteRefund', { static: true })
  dialogContentRefundDelete!: TemplateRef<ElementRef>;
  RefObj: any = {};
  RefundType: any;
  RefundStatus: any;
  RefundAmount: any;
  RefundConfirmationNo: any;
  paymentDate: any;
  refundDate: any;
  msg: string = 'Payment updated successfully.';
  refundMsg: string = 'Refund updated successfully.';
  paymentMsg: string = 'Payment create successfully.';
  refundcreateMsg: string = 'Refund create successfully.';
  paymentDeleteMsg: string = 'Payment Deleted successfully.';
  refundDeleteMsg: string = 'Refund Deleted successfully.';
  isMessageVisible: boolean = false;
  isMessageVisibleRefund: boolean = false;
  isMessageVisiblePayment: boolean = false;
  isMessageVisibleRefundCreate: boolean = false;
  isMessageVisibleDeletePayment: boolean = false;
  isMessageVisibleDeleteRefund: boolean = false;
  paymentdatalist: PaymentdataRow[] = [];
  refunddatalist: PaymentdataRow[] = [];
  paymentRowData: any;
  policyTxnArray: any = [];
  selectedPaymentRowIndex!: number;
  selectedRefundRowIndex!: number;
  editPaymentSubmitted: any = false;
  editRefundSubmitted: any = false;
  createPaymentSubmitted: any = false;
  createRefundSubmitted: any = false;
  addPaymentRequest: any;
  addRefundRequest: any;
  dataElementitemTobeDeleted: any;
  noteForm!: FormGroup;
  modelAlertRef!: NgbModalRef;
  policyMsg: any;
  public warning = false;
  isPolicyPaymentCollapsed: any = false;
  isPolicyRefundCollapsed: any = false;
  failedMessage: string = "";
  transactionId: any;
  policyTxnId: any;
  isPaymentLoader = false;
  isRefundLoader = false;
  constructor(
    private modalService: NgbModal,
    private loaderService: LoaderService,
    private dataBusService: DataBusService,
    public formBuilder: FormBuilder,
    private policyService: PolicyDetailService,
    private modelService: NgbModal,
    public dataService: PolicyDetailService,
    private store: Store,
  ) { }
  @Input() premiumChanged!: String;
  @Input() movementGuid: any;
  @Input() policyGuid: any;
  @Input() versionData: any;
  @Input() versionMovementGuid: any;
  @Input() versionNum: any;
  ngOnInit() {
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userProfile = loginUser.userProfile;
      }
    });
    this.noteForm = this.formBuilder.group({
      Description: [
        '',
        Validators.compose([Validators.required, this.requiredWithoutBlank]),
      ],
    });
    this.paymentForm = this.formBuilder.group({
      TransactionId: [''],
      Successful: [{ value: '', disabled: true }, Validators.required],
      Type: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^(CC|PC)$'),
      ])],
      Amount: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{1,9}([,.][0-9]{1,2})?$'),
        ]),
      ],
      policyTxnId: [{ value: '', disabled: true }, Validators.required],
      Row: [''],
    });

    this.refundForm = this.formBuilder.group({
      RTransactionId: [''],
      RSuccessful: [{ value: '', disabled: true }, Validators.required],
      RType: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^(CC|PC)$'),
      ])],
      RAmount: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{1,9}([,.][0-9]{1,2})?$'),
        ]),
      ],
      RPolicyTxnId: [{ value: '', disabled: true }, Validators.required],
      Row: [''],
    });

    this.CreatePayment = this.formBuilder.group({
      paymentTransactionId: [''],
      paymentSuccessful: ['', Validators.required],
      paymentType: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^(CC|PC)$'),
      ])],
      paymentAmount: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{1,9}([,.][0-9]{1,2})?$'),
        ]),
      ],
      // paymentPolicyTxnId: ['', Validators.required],
      // Description: ['', Validators.required],
      Row: [''],
    });


    this.createRefundPayment = this.formBuilder.group({
      refundTransactionId: [''],
      refundSuccessful: ['', Validators.required],
      refundType: ['', Validators.compose([
        Validators.required,
        Validators.pattern('^(CC|PC)$'),
      ])],
      refundAmount: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('^[0-9]{1,9}([,.][0-9]{1,2})?$'),
        ]),
      ],
      // refundPolicyTxnId: ['', Validators.required],
      // Description: ['', Validators.required],
      Row: [''],
    });

    this.versionMovementGuid = this.movementGuid;

    this.versionData.movements.filter((data: any) => {
      if (data.movementId == this.movementGuid) {
        this.paymentDate = data.date;
        this.refundDate = data.date;
      }
    });

    this.versionData.movements.forEach((data1: any) => {
      this.policyTxnArray.push(data1.movementId);
    });

    // this.addPaymentRequest =
    //   this.versionData.UpdateUserConfigurableDataElement.ElementUpdateRequests.filter(
    //     (elem: any) => elem.DataGroupId == 1135,
    //   );

    // this.addRefundRequest =
    //   this.versionData.UpdateUserConfigurableDataElement.ElementUpdateRequests.filter(
    //     (ele: any) => ele.DataGroupId == 1136,
    //   );

    // this.createArrayforPaymentorRefund(
    //   this.versionData.UpdateUserConfigurableDataElement.ElementUpdateRequests,
    //   false,
    // );
    // this.createArrayforPaymentorRefund(
    //   this.versionData.UpdateUserConfigurableDataElement.ElementUpdateRequests,
    //   true,
    // );

    this.paymentObj = {
      paymentDate: this.paymentDate,
      paymentStatus: this.paymentStatus,
      paymentType: this.paymentType,
      paymentConfirmation: this.paymentConfirmation,
      paymentAmount: this.paymentAmount,
    };

    this.RefObj = {
      RefundStatus: this.RefundStatus,
      RefundType: this.RefundType,
      RefundAmount: this.RefundAmount,
      RefundConfirmation: this.RefundConfirmationNo,
      RefundDate: this.refundDate,
    };
  }
  openPolicyPayment(paymentType: any) {
    if (paymentType == 'Purchase') {
      this.isPaymentLoader = true;
    }
    else {
      this.isRefundLoader = true;
    }
    let policytxnid = sessionStorage.getItem('policyGuid') || '';
    this.policyService.payment(policytxnid, paymentType, this.movementGuid).subscribe(
      (data) => {
        if (data != undefined) {
          if (paymentType == 'Purchase') {
            this.paymentdatalist = data;
          }
          else {
            this.refunddatalist = data;
          }
        }
        if (paymentType == 'Purchase') {
          this.isPolicyPaymentCollapsed = !this.isPolicyPaymentCollapsed;
        }
        else if (paymentType == 'Refund') {
          this.isPolicyRefundCollapsed = !this.isPolicyRefundCollapsed;
        }
        this.isPaymentLoader = false;
        this.isRefundLoader = false;
      },
      (err) => {
        this.isPaymentLoader = false;
        this.isRefundLoader = false;
      },
    );
  }

  // createArrayforPaymentorRefund(data: any, isRefund: boolean) {
  //   var result = data.reduce(function (r: any, a: any) {
  //     let key = a.RowNum || 'others';
  //     r[key] = r[key] || [];
  //     r[key].push(a);
  //     return r;
  //   }, Object.create(null));

  //   if (isRefund) {
  //     this.refunddatalist = [];
  //   } else {
  //     this.paymentdatalist = [];
  //   }

  //   for (let [key, value] of Object.entries(result)) {
  //     if (key.toString() != 'others') {
  //       const localarrylist = <any[]>value;
  //       if (isRefund) {
  //         let refunddataRw = new RefunddataRow();
  //         refunddataRw.Row = parseInt(key);
  //         let counter = 0;
  //         localarrylist.forEach((el) => {
  //           if (el.DataGroupId == 1136 && el.ElementCode == 'RefundType') {
  //             if (el.FreeTextValue != undefined && el.FreeTextValue != '') {
  //               refunddataRw.RefundType = el.FreeTextValue;
  //               counter++;
  //             }
  //           }
  //           if (el.DataGroupId == 1136 && el.ElementCode == 'RefundStatus') {
  //             if (el.FreeTextValue != undefined && el.FreeTextValue != '') {
  //               refunddataRw.RefundStatus = el.FreeTextValue;
  //               counter++;
  //             }
  //           }
  //           if (el.DataGroupId == 1136 && el.ElementCode == 'RefundAmount') {
  //             if (el.DecimalValue != undefined && el.DecimalValue != '') {
  //               refunddataRw.RefundAmount = el.DecimalValue;
  //               counter++;
  //             }
  //           }
  //           if (
  //             el.DataGroupId == 1136 &&
  //             el.ElementCode == 'RefundConfirmationNo'
  //           ) {
  //             if (el.FreeTextValue != undefined && el.FreeTextValue != '') {
  //               refunddataRw.RefundConfirmationNo = el.FreeTextValue;
  //               counter++;
  //             }
  //           }
  //           if (el.DataGroupId == 1136 && el.ElementCode == 'PolicyTxnId') {
  //             if (el.IntegerValue != undefined && el.IntegerValue != '') {
  //               refunddataRw.PolicyTxnId = el.IntegerValue;
  //               counter++;
  //             }
  //           }
  //         });
  //         if (counter > 0) {
  //           this.refunddatalist.push(refunddataRw);
  //         }
  //       } else {
  //         let paymentdataRw = new PaymentdataRow();
  //         paymentdataRw.Row = parseInt(key);
  //         let paymentrowcounter = 0;
  //         localarrylist.forEach((el) => {
  //           if (el.DataGroupId == 1135 && el.ElementCode == 'PaymentType') {
  //             if (el.FreeTextValue != undefined && el.FreeTextValue != '') {
  //               paymentdataRw.PaymentType = el.FreeTextValue;
  //               paymentrowcounter++;
  //             }
  //           }
  //           if (el.DataGroupId == 1135 && el.ElementCode == 'PaymentStatus') {
  //             if (el.FreeTextValue != undefined && el.FreeTextValue != '') {
  //               paymentdataRw.PaymentStatus = el.FreeTextValue;
  //               paymentrowcounter++;
  //             }
  //           }
  //           if (el.DataGroupId == 1135 && el.ElementCode == 'PaymentAmount') {
  //             if (el.DecimalValue != undefined && el.DecimalValue != '') {
  //               paymentdataRw.PaymentAmount = el.DecimalValue;
  //               paymentrowcounter++;
  //             }
  //           }
  //           if (
  //             el.DataGroupId == 1135 &&
  //             el.ElementCode == 'PayConfirmationNo'
  //           ) {
  //             if (el.FreeTextValue != undefined && el.FreeTextValue != '') {
  //               paymentdataRw.PayConfirmationNo = el.FreeTextValue;
  //               paymentrowcounter++;
  //             }
  //           }
  //           if (el.DataGroupId == 1135 && el.ElementCode == 'PolicyTxnId') {
  //             if (el.IntegerValue != undefined && el.IntegerValue != '') {
  //               paymentdataRw.PolicyTxnId = el.IntegerValue;
  //               paymentrowcounter++;
  //             }
  //           }
  //         });
  //         if (paymentrowcounter > 0) {
  //           this.paymentdatalist.push(paymentdataRw);
  //         }
  //       }
  //     }
  //   }
  // }

  setPaymentData(item: any) {
    this.transactionId = item.paymentId != undefined ? item.paymentId : '';
    this.policyTxnId = item.movementId != undefined ? item.movementId : '';
    this.paymentForm.patchValue({
      Amount: item.amount != undefined ? item.amount : '',
      Type: item.methodType != undefined ? item.methodType : '',
      Successful: item.status != undefined ? item.status : '',
      TransactionId: item.displayPaymentRefNumber || '',
      policyTxnId: this.policyTxnId,
      Row: item.Row != undefined ? item.Row : '',
    });
  }

  setRefundtData(item: any) {
    this.transactionId = item.paymentId != undefined ? item.paymentId : '';
    this.policyTxnId = item.movementId != undefined ? item.movementId : '';
    this.refundForm.patchValue({
      RAmount: item.amount != undefined ? item.amount : '',
      RType: item.methodType != undefined ? item.methodType : '',
      RSuccessful: item.status != undefined ? item.status : '',
      RTransactionId: item.displayPaymentRefNumber || '',
      RPolicyTxnId: this.policyTxnId,
      Row: item.Row != undefined ? item.Row : '',
    });
  }

  editPayment(item: any) {
    this.warning = false;
    this.showCnfrmation = false;
    this.submitted = false;
    this.openModalPayment(item);
  }

  editRefund(item: any) {
    this.warning = false;
    this.showCnfrmation = false;
    this.submitted = false;
    this.openModalRefund(item);
  }

  deletePayment(item: any) {
    this.warning = false;
    this.showCnfrmation = false;
    this.submitted = false;
    this.deletePaymentOpen(item);
  }

  deleteRefund(item: any, i: any) {
    this.warning = false;
    this.showCnfrmation = false;
    this.submitted = false;
    this.deleteRefundOpen(item, i);
  }


  addPayment(dataLength: any) {
    this.warning = false;
    this.showCnfrmation = false;
    this.submitted = false;
    this.openModalCreatePayment(dataLength);
  }

  createRefund(dataLength: any) {
    this.warning = false;
    this.showCnfrmation = false;
    this.submitted = false;
    this.openModalCreateRefund(dataLength);
  }

  openModalPayment(item: any) {
    this.warning = false;
    this.editPaymentSubmitted = false;
    this.setPaymentData(item);
    this.modalRef = this.modalService.open(this.dialogContent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  openModalRefund(item: any) {
    this.warning = false;
    this.editRefundSubmitted = false;
    this.setRefundtData(item);
    this.modalRef = this.modalService.open(this.dialogContent1, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  deletePaymentOpen(item: any) {
    this.dataElementitemTobeDeleted = item;
    this.modalRef = this.modalService.open(this.dialogContentDelete, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  openModalCreatePayment(dataLength: any) {
    this.warning = false;
    this.createPaymentSubmitted = false;
    // this.policyTxnId = item.movementId != undefined ? item.movementId : '';
    this.CreatePayment.reset();
    this.CreatePayment.patchValue({
      Row: dataLength + 1,
    });
    this.modalRef = this.modalService.open(this.dialogContentPayment, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  deleteRefundOpen(item: any, i?: any) {
    this.warning = false;
    this.dataElementitemTobeDeleted = item;
    this.modalRef = this.modalService.open(this.dialogContentRefundDelete, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  openModalCreateRefund(dataLength: any) {
    this.warning = false;
    this.createRefundSubmitted = false;
    this.createRefundPayment.reset();

    this.createRefundPayment.patchValue({
      Row: dataLength + 1,
    });
    this.modalRef = this.modalService.open(this.dialogContentRefund, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  get f() {
    return this.paymentForm.controls;
  }

  get notes() {
    return this.noteForm.controls;
  }

  get createf() {
    return this.CreatePayment.controls;
  }

  get r() {
    return this.refundForm.controls;
  }

  get createR() {
    return this.createRefundPayment.controls;
  }

  noteSubmitted(policyTxnId?: any) {
    this.submitted = true;
    if (this.noteForm.invalid) {
      return;
    }
    this.loaderService.show();
    let todayISOString = new Date().toISOString();
    let data = {
      // PolicyTxnId: parseInt(policyTxnId),
      ActivityType: 'GeneralNotes',
      ActivityDate: todayISOString,
      Description: this.noteForm.controls['Description'].value,
      // LoggedInUser: this.userProfile?.LoggedUser,
    };
    this.dataService.notes(data).subscribe(
      (response) => {
        if (response.Status == 'SUCCESS') {
          // this.dataBusService.publishPolicyDetails();
          this.resetForm();
          //	 this.cancelPolicySave();
          this.loaderService.hide();
        } else {
          this.loaderService.hide();
        }
      },
      (err) => {
        this.loaderService.hide();
      },
    );
  }

  curdOperationConfirmation(operation: any, value?: any) {
    this.submitted = true;
    if (this.noteForm.invalid) {
      return;
    }

    switch (operation) {
      case 'Addition':
        if (this.transactionType == 'PaymentType') {
          this.onCreatePaymentSubmit(this.currentRowVal);
        }
        if (this.transactionType == 'RefundType') {
          this.CreateRefundSubmit(this.currentRowVal);
        }
        break;
      case 'Update':
        if (this.transactionType == 'PaymentType') {
          this.onSubmit(this.currentRowVal);
        }
        if (this.transactionType == 'RefundType') {
          this.updateRefundSubmit(this.currentRowVal);
        }
        break;
      case 'Delete':
        if (this.transactionType == 'PaymentType') {
          this.deletPaymentItem();
        }
        if (this.transactionType == 'RefundType') {
          this.deletRefundItem();
        }
        break;
    }
  }

  requiredWithoutBlank(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      if (control.value.trim().length > 0) {
        return null;
      } else {
        return { required: true };
      }
    }
  }

  openNotes(currentrowval: any, transactiontype: any, operation: any) {
    this.currentRowVal = currentrowval;
    this.transactionType = transactiontype;
    this.submitted = false;
    switch (operation) {
      case 'Addition':
        this.createPaymentSubmitted = true;
        this.createRefundSubmitted = true;
        if (this.transactionType == 'PaymentType') {
          if (this.CreatePayment.invalid) {
            return;
          }
        }
        if (this.transactionType == 'RefundType') {
          if (this.createRefundPayment.invalid) {
            return;
          }
        }
        break;
      case 'Update':
        this.editPaymentSubmitted = true;
        this.editRefundSubmitted = true;
        if (this.transactionType == 'PaymentType') {
          if (this.paymentForm.invalid) {
            return;
          }
        }
        if (this.transactionType == 'RefundType') {
          if (this.refundForm.invalid) {
            return;
          }
        }
        break;
      case 'Delete':
        if (this.transactionType == 'PaymentType') {
        }
        if (this.transactionType == 'RefundType') {
        }
        break;
    }

    this.showCnfrmation = true;
  }

  closeNotes() {
    this.modelService.dismissAll();
  }

  resetForm() {
    this.noteForm.reset();
    this.showCnfrmation = false;
    this.failedMessage = "";
  }
  //payment update
  onSubmit(value: any) {
    this.editPaymentSubmitted = true;

    if (this.paymentForm.invalid) {
      return;
    }

    this.loaderService.show();

    let postData = {
      amount: this.paymentForm.value.Amount,
      newPaymentRefNumber: this.paymentForm.value.TransactionId,
      methodType: this.paymentForm.value.Type
    };
    this.paymentUpdate('Purchase', postData);
  }

  paymentUpdate(type: string, postData: any) {
    postData.movementGuid = this.policyTxnId;
    postData.paymentRefNumber = this.transactionId;
    let policyGuid = sessionStorage.getItem('policyGuid') || '';
    let data = {
      policyGuid: policyGuid,
      movementGuid: this.policyTxnId,
      Description: this.noteForm.controls['Description'].value,
      // LoggedInUser: this.userProfile?.LoggedUser,
    };
    this.dataService.notes(data).subscribe();
    this.policyService.paymentUpdate(type, postData).subscribe(async (data: any) => {
      if (data.isSuccessful) {
        if (type == 'Purchase') {
          this.closeModal1();
        } else {
          this.refundModalClose();
        }
        this.openPolicyPayment(type);
      } else {
        this.failedMessage = data.errors[0];
      }
      this.loaderService.hide();
    }, () => {
      this.failedMessage = 'There is an issue with backend response. Please retry after some time';
      this.loaderService.hide();
    });
  }
  //update refund
  updateRefundSubmit(value: any) {
    this.editRefundSubmitted = true;
    if (this.refundForm.invalid) {
      return;
    }

    this.loaderService.show();
    let postData = {
      amount: this.refundForm.value.RAmount,
      newPaymentRefNumber: this.refundForm.value.RTransactionId,
      methodType: this.refundForm.value.RType
    };
    this.paymentUpdate('Refund', postData);
  }
  //add payment 123456

  onKeydown(events: any) {
    let event = events.target.value;
    this.policyMsg = '';
    let policyValue = this.policyTxnArray.filter((x: any) => x == event);
    if (policyValue.length == 0) {
      this.warning = true;
      this.policyMsg =
        'PolicyTxnId is not valid. Please verify before processing. ';
    } else {
      this.warning = false;
    }
  }
  onCreatePaymentSubmit(val: any) {
    this.editPaymentSubmitted = true;

    if (this.CreatePayment.invalid) {
      return;
    }

    this.loaderService.show();

    let postData = {
      amount: this.CreatePayment.value.paymentAmount,
      paymentRefNumber: this.CreatePayment.value.paymentTransactionId,
      methodType: this.CreatePayment.value.paymentType,
      movementGuid: this.movementGuid
    };


    let policyGuid = sessionStorage.getItem('policyGuid') || '';
    let data = {
      policyGuid: policyGuid,
      movementGuid: this.movementGuid,
      Description: this.noteForm.controls['Description'].value,
      // LoggedInUser: this.userProfile?.LoggedUser,
    };
    this.dataService.notes(data).subscribe();
    this.paymentAdd('Purchase', postData);
  }

  // add refund
  CreateRefundSubmit(value: any) {
    this.createRefundSubmitted = true;
    if (this.createRefundPayment.invalid) {
      return;
    }

    this.loaderService.show();
    let postData = {
      amount: this.createRefundPayment.value.refundAmount,
      paymentRefNumber: this.createRefundPayment.value.refundTransactionId || '',
      methodType: this.createRefundPayment.value.refundType,
      movementGuid: this.movementGuid
    };


    let policyGuid = sessionStorage.getItem('policyGuid') || '';
    let data = {
      policyGuid: policyGuid,
      movementGuid: this.movementGuid,
      Description: this.noteForm.controls['Description'].value,
      // LoggedInUser: this.userProfile?.LoggedUser,
    };
    this.dataService.notes(data).subscribe();
    this.paymentAdd('Refund', postData);
  }

  paymentAdd(type: any, postData: any) {
    this.policyService.paymentAdd(type, postData).subscribe(async (data: any) => {
      if (data.isSuccessful) {
        if (type == 'Purchase') {
          this.createModalClose();
        } else {
          this.createRefundClose();
        }
        this.openPolicyPayment(type);
      } else {
        this.failedMessage = data.errors[0];
      }
      this.loaderService.hide();
    }, () => {
      this.failedMessage = 'There is an issue with backend response. Please retry after some time';
      this.loaderService.hide();
    });
  }
  reBuildArrayData(dataGroupId: any) {
    this.policyService.getData(this.movementGuid).subscribe((data) => {
      this.versionData = data;
      this.addRefundRequest =
        this.versionData.UpdateUserConfigurableDataElement.ElementUpdateRequests.filter(
          (ele: any) => ele.DataGroupId == dataGroupId,
        );
      // this.createArrayforPaymentorRefund(
      //   this.versionData.UpdateUserConfigurableDataElement.ElementUpdateRequests,
      //   false,
      // );
      // this.createArrayforPaymentorRefund(
      //   this.versionData.UpdateUserConfigurableDataElement.ElementUpdateRequests,
      //   true,
      // );
      this.createModalClose();
      this.createRefundClose();
      this.CreatePayment.reset();
      this.paymentForm.reset();
      this.createRefundPayment.reset();
      this.refundForm.reset();
      this.noteSubmitted(this.movementGuid);
      this.loaderService.hide();
    });
  }
  //delete
  deletPaymentItem() {
    if (this.dataElementitemTobeDeleted.invalid) {
      return;
    }

    this.loaderService.show();
    let postData = {
      successfulStatus: this.dataElementitemTobeDeleted.status,
      paymentRefNumber: this.dataElementitemTobeDeleted.paymentId,
      methodType: this.dataElementitemTobeDeleted.methodType,
      movementGuid: this.movementGuid
    };


    let policyGuid = sessionStorage.getItem('policyGuid') || '';
    let data = {
      policyGuid: policyGuid,
      movementGuid: this.movementGuid,
      Description: this.noteForm.controls['Description'].value,
      // LoggedInUser: this.userProfile?.LoggedUser,
    };
    this.dataService.notes(data).subscribe();
    this.paymentDelete('Purchase', postData);

  }
  deletRefundItem() {
    if (this.dataElementitemTobeDeleted.invalid) {
      return;
    }

    this.loaderService.show();
    let postData = {
      successfulStatus: this.dataElementitemTobeDeleted.status,
      paymentRefNumber: this.dataElementitemTobeDeleted.paymentId,
      methodType: this.dataElementitemTobeDeleted.methodType,
      movementGuid: this.movementGuid
    };


    let policyGuid = sessionStorage.getItem('policyGuid') || '';
    let data = {
      policyGuid: policyGuid,
      movementGuid: this.movementGuid,
      Description: this.noteForm.controls['Description'].value,
      // LoggedInUser: this.userProfile?.LoggedUser,
    };
    this.dataService.notes(data).subscribe();
    this.paymentDelete('Refund', postData);
  }

  paymentDelete(type: any, postData: any) {
    this.policyService.paymentDelete(type, postData).subscribe(async (data: any) => {
      if (data.isSuccessful) {
        if (type == 'Purchase') {
          this.paymentDeleteModalClose();
        } else {
          this.refundDeleteModalClose();
        }
        this.openPolicyPayment(type);
      } else {
        this.failedMessage = data.errors[0];
      }
      this.loaderService.hide();
    }, () => {
      this.failedMessage = 'There is an issue with backend response. Please retry after some time';
      this.loaderService.hide();
    });
  }

  closeModal1() {
    this.modalRef.close();
    this.resetForm();
    this.isMessageVisible = true;
    setTimeout(() => {
      this.isMessageVisible = false;
    }, 9000);
  }

  refundModalClose() {
    this.modalRef.close();
    this.resetForm();
    this.isMessageVisibleRefund = true;
    setTimeout(() => {
      this.isMessageVisibleRefund = false;
    }, 9000);
  }

  paymentDeleteModalClose() {
    this.modalRef.close();
    this.isMessageVisibleDeletePayment = true;
    setTimeout(() => {
      this.isMessageVisibleDeletePayment = false;
    }, 9000);
  }

  refundDeleteModalClose() {
    this.modalRef.close();
    this.isMessageVisibleDeleteRefund = true;
    setTimeout(() => {
      this.isMessageVisibleDeleteRefund = false;
    }, 9000);
  }

  createModalClose() {
    this.modalRef.close();
    this.isMessageVisiblePayment = true;
    setTimeout(() => {
      this.isMessageVisiblePayment = false;
    }, 9000);
  }

  createRefundClose() {
    this.modalRef.close();
    this.isMessageVisibleRefundCreate = true;
    setTimeout(() => {
      this.isMessageVisibleRefundCreate = false;
    }, 9000);
  }

  closeModal() {
    this.modalRef.close();
  }
}

export class PaymentdataRow {
  methodType!: string;
  status!: string;
  amount!: string;
  paymentId!: string;
  movementId!: string;
  Row!: Number;
  displayPaymentRefNumber!: string;
}

export class RefunddataRow {
  RefundType!: string;
  RefundStatus!: string;
  RefundAmount!: string;
  RefundConfirmationNo!: string;
  PolicyTxnId!: string;
  Row!: Number;
}
