/// <reference path="../../../../src/app/core/services/endpoint.ts" />
import { Injectable } from '@angular/core';
import { HttpClient, HttpHandler } from '@angular/common/http';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { RoleTextService } from 'src/app/core/role/setroletext';
import { SecurityRole, SecurityRoleResponse, SecurityUserResponse } from './app.model';
import { EndPoint } from 'src/app/core/services/endpoint';
import { Observable, of } from 'rxjs';
import { CacheService } from 'projects/manage-branches/src/app/caching.service';
import { BranchResponse } from 'projects/manage-branches/src/app/app.model';
import { CryptoService } from 'src/app/core/cryptoservice/cryptoservice';

@Injectable({
  providedIn: 'root',
})
export class HeaderNavigationService {

  constructor(
    private http: HttpClient,
    private handler: HttpHandler,
    private portalCodeService: PortalCodeService,
    private roleTextService: RoleTextService,
    private cacheService: CacheService,
  ) {
  }

  getSecurityRole(email: string):Observable<SecurityRole> {
    let cacheKey = 'navSecurityRole';
    let cacheTTL = 1 * 60 * 60 * 1000; // 1 hour in milliseconds

    const cachedData = this.cacheService.getCache(cacheKey);
    if (cachedData) {
      let encryptedData = JSON.parse(JSON.stringify(cachedData));
      let decryptedData : SecurityRoleResponse = CryptoService.decryptData(encryptedData);
      return of(decryptedData.responseMessage);
    } 
    else {
      return new Observable((observer) => {
        let url = EndPoint.PAS_SECURITYUSER_ROLE + encodeURIComponent(CryptoService.encryptData(email.toLowerCase()));

        this.http.get<SecurityUserResponse>(url,).subscribe(
          (data) => {
            if(data != null && data.value != null ){
              this.cacheService.setCache(cacheKey, data.value, cacheTTL);
              let response = CryptoService.decryptData(data.value);
              observer.next(response.responseMessage);
              observer.complete();
            }
          },
          (error) => {
            observer.error(error);
          },
        );
      });
    }
  }

  handelUnauthorizedUrl(path: string, navigationMenuItems: any) {
    if (navigationMenuItems != null) {
      var item = navigationMenuItems.find((x: any) => x.primaryMenuItems.navigationLink == path);
      if (!item) {
        document.location.href = location.protocol + '//' + location.host;
      }
    }
  }

  getBranches( params: any): Observable<BranchResponse> {
    return this.http.post<BranchResponse>(EndPoint.PAS_BRANCH_SEARCH,params);
  }
}