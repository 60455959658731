import { Component, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { PolicyDetailService } from '../app.services';
import { DataBusService } from '../services/databus.service';
import { OktaCustomAuthService } from 'src/app/shared/okta/oktaauth.service';
import { LogService } from 'projects/policyDetails/src/app/shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import {
  PageNameEnum,
  SubComponentNameEnum,
} from 'projects/policyDetails/src/app/enum/page-name-enum';

@Component({
  selector: 'policyDetails-policyDiscard',
  templateUrl: './policyDiscard.html',
  styleUrls: ['./policyDiscard.css'],
})
export class PolicyDiscardComponent {
  discardMessageFail!: string;
  discardMessageSuccess!: string;
  modelAlertRef!: NgbModalRef;
  isLoader: boolean = false;

  @ViewChild('discardAlertTemplate', { static: true })
  dialogalertBox: TemplateRef<ElementRef>;
  isDisabledDiscard: boolean = true;

  constructor(
    public dataService: PolicyDetailService,
    // public oktaCustomAuthService: OktaCustomAuthService,
    private dataBusService: DataBusService,
    private el: ElementRef,
    private modelService: NgbModal,
    private logService: LogService,
  ) {
    this.dialogalertBox = this.el.nativeElement;
  }

  ngOnInit() {
    this.dataBusService.policyData.subscribe((data) => {
      this.checkPolicyTxnIdWithEndorseWithDiscard();
    });
    this.checkPolicyTxnIdWithEndorseWithDiscard();
  }

  openAlertModel() {
    this.modelAlertRef = this.modelService.open(this.dialogalertBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }
  closeAlertChildModal() {
    if (this.discardMessageSuccess) {
      this.modelAlertRef.close();
      // window.location.href = "/dashboard";
      window.location.href = '/policy-detail';
    } else this.modelAlertRef.close();
  }
  checkPolicyTxnIdWithEndorseWithDiscard() {
    if (
      sessionStorage.getItem('originalMovementGuid') ===
      sessionStorage.getItem('movementGuid')
    ) {
      this.isDisabledDiscard = true;
    } else {
      this.isDisabledDiscard = false;
    }
  }
  discardChanges() {
    this.isLoader = true;
    this.discardMessageSuccess = '';
    this.discardMessageFail = '';
    let data = {
      PolicyTxnId: sessionStorage.getItem('movementGuid'),
    };
    if (
      sessionStorage.getItem('updatedContact') &&
      sessionStorage.getItem('isContactModified')
    ) {
      sessionStorage.removeItem('updatedContact');
      sessionStorage.removeItem('isContactModified');
    }
    this.dataService
      .discardEN(data)
      .pipe(filter((data) => data !== undefined))
      .subscribe(
        (response) => {
          if (response && response.isSuccessful) {
            sessionStorage.setItem( 'movementGuid', sessionStorage.getItem('originalMovementGuid') || '', );
            this.discardMessageSuccess = 'Changes has been discarded.';
            this.openAlertModel();
          } else {
            this.discardMessageFail = 'Something went wrong please try again.';
            this.openAlertModel();
          }
        },
        (err) => {
          this.isLoader = false;
          this.logService.createErrorLog(
            data,
            err,
            'DiscardChange',
            PageNameEnum.PolicyDetails + ' ' + SubComponentNameEnum.Discard,
            LogLevel.Error,
          );
        },
      );
    this.isLoader = false;
  }
}
