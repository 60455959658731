// import * as moment from 'moment-timezone';
import moment from 'moment';
import { DateService } from './date-updated.service';
import { isDate } from 'moment-timezone';
// import { isDate } from 'rxjs/internal/util/isDate';

export class Utility {
  private static dateService = new DateService();

  public static IsExistInArray(stringArray: any[], matchValue: any): boolean {
    return stringArray.find((c) => c.toUpperCase() == matchValue.toUpperCase());
  }

  public static differenceInDays(firstDate: any, secondDate: any) {
    return Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));
  }

  public static differenceInDaysDate(firstDate: any, secondDate: any) {
    firstDate = new Date(firstDate);
    secondDate = new Date(secondDate);
    return Math.round((secondDate - firstDate) / (1000 * 60 * 60 * 24));
  }

  public static getBaseUrl() {
    let baseUrl = window.location.origin
      ? window.location.origin + '/'
      : window.location.protocol + '/' + window.location.host + '/';
    return baseUrl;
  }
  public static removeExtraSlash(url: any) {
    return url.replace(/([^:]\/)\/+/g, '$1');
  }

  public static getFullUrl() {
    let baseUrl = window.location.href;
    return baseUrl;
  }

  public static SortOrder(dataArray: any, object: any) {
    return dataArray.sort(
      (a: any, b: any) => object[a.productName] - object[b.productName],
    );
  }

  public static alphaNumeric(val: any): any {
    if (val.pristine) {
      return null;
    }
    const VAL_REGEXP = /^(?=.*[a-z0-9])[a-z0-9!@#$,'"/&*.]{0,}$/i;
    val.markAsTouched();
    if (VAL_REGEXP.test(val.value)) {
      return null;
    }
    return {
      invalidVal: true,
    };
  }

  public static onlyAlphabets(event: any) {
    const pattern = /^[a-zA-Z]+$/i;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public static onlyNumberNSlash(event: any) {
    const pattern = /^[0-9-/-]+$/i;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public static _keyUp(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  public static validateDec(key: any) {
    //getting key code of pressed key
    var keycode = key.which ? key.which : key.keyCode;
    //comparing pressed keycodes
    if (!(keycode == 8 || keycode == 46) && (keycode < 48 || keycode > 57)) {
      return false;
    } else {
      // var parts = key.srcElement.value.split('.');
      // if (parts.length > 1 && keycode == 46)
      //   return false;``
      // return true;
    }
  }

  public static allowOnly4number(event: any) {
    // return event.charCode >= 48 && event.charCode <= 5;
    const pattern = /^[0-9]{4}$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8) {
      event.preventDefault();
    }
  }

  public static onlyAlphanumeric(event: any) {
    const pattern = /^[a-z0-9]+$/i;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  public static onlyAlphanumericNSpace(event: any) {
    const pattern = /^[a-z0-9- ]+$/i;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  public static leapYear(date: any) {
    if (date) {
      let year = date.getFullYear();
      return (year & 3) == 0 && (year % 25 != 0 || (year & 15) == 0);
    }
  }

  public static isDefined<T>(value: T | undefined | null): value is T {
    return <T>value !== undefined && <T>value !== null;
  }

  public static ageFromDateOfBirth(dob: Date): any {
    const pricingDateString = sessionStorage.getItem('PricingDate');
    if (this.dateService.isValidDate(dob) && pricingDateString) {
      if (dob && typeof dob === 'string' && (dob as string).length === 10) {
        //assuming we are getting DD/MM/YYYY here
        return this.dateService.ageInYears(dob, pricingDateString);
      } else {
        const birthDate = this.dateService.getDDMMYYYYFromDate(dob);
        return this.dateService.ageInYears(birthDate, pricingDateString);
      }
    }
    // let dobDate;

    // if (dob && typeof (dob) === 'string' && (dob as string).length === 10) {
    //   dobDate = moment(dob, 'DD/MM/YYYY');
    // }
    // else {
    //   dobDate = dob;
    // }

    // const today = Utility.utcToAestDate((new Date()).toLocaleString());
    // const birthDate = new Date(dobDate);
    // let age = today.getFullYear() - birthDate.getFullYear();
    // let month = today.getMonth() - birthDate.getMonth();
    // if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
    //   age--;
    // }
    // return age;
  }

  public static random10DigitNumber() {
    return Math.floor(Math.random() * 90000) + 10000;
  }

  public static combineStrings(firstName: any, middleName: any, lastName = '') {
    return `${firstName} ${middleName} ${lastName}`.trim();
  }

  public static getDOBfromAge(age: number) {
    var birthDate = new Date();
    birthDate.setDate(birthDate.getDate() - 365 * 10);
    return birthDate;
  }

  public static getFormattedDate(date: any) {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  }
  public static IsQueryStringExists() {
    let url = this.getFullUrl();
    let exists = url.split('?').length > 1;
    if (exists) return true;
    return false;
  }
  public static getQryStringKeyValue(key: string, array: any[]) {
    let result = array.find((element) => element.key == key);
    return result!.value.trim();
  }

  public static readQueryString() {
    let url = this.getFullUrl();
    let finalArray: any;
    if (url) {
      let queryStringPart = url.split('?').pop();
      if (queryStringPart) {
        let arrayQueryParam = queryStringPart.split('&');
        finalArray = arrayQueryParam.map((param) => {
          let _param = param.split('=');
          return { key: _param[0], value: _param[1] };
        });
      }
    }
    return finalArray;
  }

  public static isValidDate(dateString: any) {
    // First check for the pattern
    if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

    // Parse the date parts to integers
    var parts = dateString.split('/');
    var day = parseInt(parts[0], 10);
    var month = parseInt(parts[1], 10);
    var year = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || month < 1 || month > 12) return false;

    var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
      monthLength[1] = 29;

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  public static isTrue(value: any) {
    if (value) {
      if (value === 'true') return true;
      if (value === 'True') return true;
      if (JSON.parse(value) == true) return true;

      return false;
    }
    return false;
  }
  public static isYes(value: any) {
    if (value) {
      if (value.toUpperCase() === 'YES') return true;
    }
    return false;
  }

  public static convertNumberToCurrency(number: any) {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });

    return formatter.format(number); // "$1,000.00"
    // const formatter = new Intl.NumberFormat('it-IT', {
    //   style: 'currency',
    //   currency: 'EUR'
    // })
    //formatter.format(10) // "$10.00"
    //formatter.format(123233000) // "$123,233,000.00"
  }

  public static convertCurrencyToNumber(currency: any) {
    //var currency = "-$4,400.50";
    if (!currency) return currency;
    if (currency === 'NA') {
      return currency;
    }

    currency = `${currency}`;
    return Number(currency.replace(/[^0-9\.]+/g, ''));
  }

  //Handle Null
  public static typeSafeHandle(
    obj: any,
    props: string,
    dataType: string = '',
  ): any {
    let result: any = this.get(obj, props);
    if (result) {
      return result;
    }
    switch (dataType) {
      case 'int':
        result = 0;
        break;
      case 'boolean':
        result = false;
        break;
      case 'array':
        result = [];
        break;
      case 'split':
        result = ['', '', ''];
        break;
      default:
        result = '';
        break;
    }
    return result;
  }

  public static get<T, P1 extends keyof NonNullable<T>>(
    obj: T,
    prop1: P1,
  ): NonNullable<T>[P1] | undefined;

  public static get<
    T,
    P1 extends keyof NonNullable<T>,
    P2 extends keyof NonNullable<NonNullable<T>[P1]>,
  >(
    obj: T,
    prop1: P1,
    prop2: P2,
  ): NonNullable<NonNullable<T>[P1]>[P2] | undefined;

  public static get<
    T,
    P1 extends keyof NonNullable<T>,
    P2 extends keyof NonNullable<NonNullable<T>[P1]>,
    P3 extends keyof NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>,
  >(
    obj: T,
    prop1: P1,
    prop2: P2,
    prop3: P3,
  ): NonNullable<NonNullable<NonNullable<T>[P1]>[P2]>[P3] | undefined;

  // ...and so on...

  public static get(obj: any, props: string): any {
    if (props) {
      return (
        obj &&
        props
          .split('.')
          .reduce(
            (result, prop) => (result == null ? undefined : result[prop]),
            obj,
          )
      );
    } else {
      return '';
    }
  }

  public static getFullName(
    firstName: string,
    midName: string,
    lastName: string,
    isCamelCase: boolean = false,
  ) {
    let fullName = '';
    if (!this.isNullOrEmpty(firstName)) {
      fullName = firstName;
    }
    if (!this.isNullOrEmpty(midName)) {
      fullName = fullName != '' ? `${fullName} ${midName}` : `${midName}`;
    }
    if (!this.isNullOrEmpty(lastName)) {
      fullName = fullName != '' ? `${fullName} ${lastName}` : `${lastName}`;
    }
    //alert(this.camelize(fullName));
    return isCamelCase ? this.properCase(fullName) : fullName;
  }

  public static isNullOrEmpty(value: any) {
    if (!value || value === '' || value === undefined || value.length < 0) {
      return true;
    }
    return false;
  }

  public static properCase(str: any, isCamelCase: boolean = false) {
    return str
      .split(' ')
      .map(function (word: any, index: any) {
        // If it is the first word make sure to lowercase all the chars.
        if (isCamelCase && index == 0) {
          return word.toLowerCase();
        }
        // If it is not the first word only upper case the first char and lowercase the rest.
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(' ');
  }

  public static asyncLocalStorage = {
    setItem: function (key: any, value: any) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key: any) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    },
  };

  public static asyncSessionStorage = {
    setItem: function (key: any, value: any) {
      return Promise.resolve().then(function () {
        sessionStorage.setItem(key, value);
      });
    },
    getItem: function (key: any) {
      return Promise.resolve().then(function () {
        return sessionStorage.getItem(key);
      });
    },
  };

  public static tokenExpired(expireAt: string | Date) {
    let currentDateTime = this.toDate(new Date());
    if (!expireAt) return true;
    let expireAtDateTime = this.toDate(expireAt);
    return currentDateTime >= expireAtDateTime;
  }

  public static toDate(dateInput: string | Date): any {
    let convertedDate;

    if (!dateInput) throw 'Invalid date.';

    //when we are getting 10 digit string, it has to be "DD/MM/YYYY" format
    if (typeof dateInput === 'string') {
      if ((dateInput as string).length === 10) {
        convertedDate = moment(dateInput, 'DD/MM/YYYY');
      } else {
        convertedDate = new Date(dateInput);
      }
    } else if (isDate(dateInput)) {
      convertedDate = new Date(dateInput);
    } else {
      throw 'Invalid date.';
    }

    return convertedDate;
  }
}
