<ng-container *ngIf="!isHideCancel">
  <button class="btn btn-secondary float-sm-right" [disabled]="!isCancelButtonEnabled" (click)="checkForCancellation()">
    Cancel Policy
  </button>
</ng-container>
<app-loader></app-loader>
<ng-template #cancelcontentTemplate>
  <div class="" id="myModal-7">
    <form [formGroup]="cancelForm" (ngSubmit)="cancelconfirmation()">
      <div class="modal-body">
        <div class="col-md-8 mx-auto text-center">
          <div class="text-center mb-4 mt-4">
            <h5 class="mb-4">Cancel Policy</h5>
          </div>
          <p>I would like to cancel this policy.</p>
          <div class="form-row mx-auto col-8 mt-4 mb-3">
            <label class="col-md-6 text-left"> Cancellation Date </label>
            <span class="col-md-6 text-left"> {{today | date: 'dd/MM/yyyy'}}</span>
          </div>

          <div class="form-group mx-auto col-8">
            <select id="inputState" class="form-control" formControlName="CancelReason"
              (change)="changeCancelReason($event)" [value]="selectedInputState">
              <option value="" disabled="true" [selected]="true">
                Select Cancel Reason
              </option>
              <option *ngFor="let item of cancellist" value="{{item.resonCode}}">
                {{item.reason}}
              </option>
            </select>
            <div class="invalid-input" *ngIf="cancelFormError">
              Please select a Cancellation reason
            </div>
          </div>
          <div *ngIf="!ShowPercentageDiv">
            <div class="form-group mx-auto col-10">
              <div class="alert alert-primary" *ngIf="refundData && IsRefundValueShow">
                <p>
                  A Refund of
                  {{((refundData.ccRefundAmt?refundData.ccRefundAmt:0)+(refundData.pcRefundAmt?refundData?.pcRefundAmt:0))
                  | currency}} is required for this policy update.
                </p>
                <ng-container *ngIf="refundData.ccRefundAmt && !refundData.pcRefundAmt">
                  <p>
                    The refunds will be processed online and issued to the
                    credit card used to make the purchase.
                  </p>
                </ng-container>
                <ng-container *ngIf="!refundData.ccRefundAmt && refundData.pcRefundAmt">
                  <p>
                    The refund must be made in cash. Enter a cash refund receipt
                    number to record the cash refund.
                  </p>
                </ng-container>
                <ng-container *ngIf="refundData.ccRefundAmt && refundData.pcRefundAmt">
                  <p>
                    The refunds are a mixture of cash and credit:
                    {{refundData?.pcRefundAmt | currency}} in cash and
                    {{refundData?.ccRefundAmt | currency}} in credit.
                  </p>
                  <p>
                    The credit refunds will be issued to the card used to make
                    the purchase.
                  </p>
                  <p>
                    The cash refund receipt number provided will be used as
                    records of the cash portion of the refunds
                  </p>
                </ng-container>
              </div>
              <div class="alert alert-primary" *ngIf="!refundData && IsRefundValueShow">
                Could not calculate refund amount, some error occured.
              </div>
            </div>
          </div>
          <div *ngIf="ShowPercentageDiv">
            <div class="form-group mx-auto col-10">
              <div class="alert alert-primary" *ngIf="OriginalrefundData && IsRefundValueShow">
                <p>
                  A Refund of
                  {{((OriginalrefundData.ccRefundAmt?OriginalrefundData.ccRefundAmt:0)+(OriginalrefundData.pcRefundAmt?OriginalrefundData?.pcRefundAmt:0))
                  | currency}} is required for this policy update.
                </p>
                <ng-container
                  *ngIf="OriginalrefundData.ccRefundAmt && !OriginalrefundData.pcRefundAmt && !PartialRefundOnlyToCC">
                  <p>
                    The refunds will be processed online and issued to the
                    credit card used to make the purchase.
                  </p>
                </ng-container>
                <ng-container
                  *ngIf="!OriginalrefundData.ccRefundAmt && OriginalrefundData.pcRefundAmt && !PartialRefundOnlyToCC">
                  <p>
                    The refund must be made in cash. Enter a cash refund receipt
                    number to record the cash refund.
                  </p>
                </ng-container>
                <ng-container
                  *ngIf="OriginalrefundData.ccRefundAmt && OriginalrefundData.pcRefundAmt && !PartialRefundOnlyToCC">
                  <p>
                    The refunds are a mixture of cash and credit:
                    {{OriginalrefundData?.pcRefundAmt | currency}} in cash and
                    {{OriginalrefundData?.ccRefundAmt | currency}} in credit.
                  </p>
                  <p>
                    The credit refunds will be issued to the card used to make
                    the purchase.
                  </p>
                  <p>
                    The cash refund receipt number provided will be used as
                    records of the cash portion of the refunds
                  </p>
                </ng-container>
                <ng-container
                  *ngIf="OriginalrefundData.ccRefundAmt && !OriginalrefundData.pcRefundAmt && PartialRefundOnlyToCC">
                  <p>
                    The refunds are a mixture of cash and credit
                    {{OriginalrefundData?.ccRefundAmt | currency}} in credit.
                  </p>
                  <p>
                    The credit refunds will be issued to the card used to make
                    the purchase.
                  </p>
                </ng-container>
              </div>
              <div class="alert alert-primary" *ngIf="!OriginalrefundData && IsRefundValueShow">
                Could not calculate refund amount, some error occured.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeModal()">
          Cancel
        </button>
        <button type="submit" class="btn btn-primary btn-full-sm" data-toggle="modal" data-target="#myModal-10" [disabled]="ShowPercentageDiv || !PercentageEntered">
          Cancel Policy
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #cancelconfirmTemplate>
  <!-- <div *ngIf="isConfirmLoader">
    <div class="clearfix">
    </div>
    <div class="overlay-custom-container">
    </div>
    <div class="loader">
    </div>
  </div> -->
  <div class="" id="myModal-10">
    <div class="modal-body">
      <div class="col-md-5 mx-auto text-center">
        <div class="text-center mb-4 mt-4">
          <h4>Cancel Policy</h4>
        </div>
        <span>Are you sure you want to cancel the policy?</span>
      </div>
    </div>
    <div class="text-center mb-4 mt-2 btn-footer">
      <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeChildModal(true)">
        No
      </button>
      <button type="button" class="btn btn-primary btn-full-sm" (click)="cancelPolicySave()">
        Yes
      </button>
    </div>
    <div class="col-md-10 mx-auto text-center">
      <!-- <div *ngIf="cancelMessageSuccess" class="alert alert-success my-2">{{cancelMessageSuccess}}</div> -->
      <div *ngIf="cancelMessageFail" class="alert alert-warning my-4 text-break">
        {{cancelMessageFail}}
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancelAlertTemplate>
  <div *ngIf="isLoader">
    <div class="clearfix"></div>
    <div class="overlay-custom-container"></div>
    <div class="loader"></div>
  </div>
  <div class="" id="myModal-11">
    <div class="modal-body">
      <div class="col-md-10 mx-auto text-center">
        <div *ngIf="cancelMessageSuccess" class="alert alert-success my-2">
          {{cancelMessageSuccess}}
        </div>
        <div *ngIf="cancelMessageFail" class="alert alert-warning my-4 text-break">
          {{cancelMessageFail}}
        </div>
      </div>
    </div>
    <div class="text-center mb-4 btn-footer">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeAlertChildModal()">
        {{cancelMessageAlertBtn}}
      </button>
    </div>
  </div>
</ng-template>
<!--manageRefundsTemplate popup-->

<ng-template #manageRefundsTemplate>
  <div class="" id="myModal-12">
    <div class="modal-header">
      <h4 role="alert">
      </h4>
      <button *ngIf="isRefundAllDone" type="button" class="close" data-dismiss="modal"
        (click)="closeManageRefundModel()">&times;</button>
    </div>
    <div class="modal-body">
      <h4 class='text-center'>Manage Refund</h4>
      <table *ngIf="paymentRefundsData && paymentRefundsData.length > 0" id="datatableRefunds"
        class="table table-striped table-bordered table-dashboard" style="width: 100%">
        <thead>
          <tr>
            <th>Transactions</th>
            <th>Transactions Type</th>
            <th>Refund Amount</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of paymentRefundsData; let i = index">
            <td>{{item.displayPaymentRefNumber}}</td>
            <td>
              {{item.method}}
            </td>
            <td class="text-right">{{formatprice(item.amount)}}</td>
            <td>
              <ng-container>
                <button type="button" class="btn btn-primary btn-full-sm" (click)="confirmRefundPay(item, i)"
                  [disabled]="isRefundClicked || getDisabledStatus(item,i) || isIssuedButtonDisabled">
                  <span *ngIf="refundIndex == i" class="spinner-border text-primary" style="width: 1rem; height: 1rem;"
                    role="status" aria-hidden="false"></span>
                  <span *ngIf="refundIndex != i">{{setRefundText(item,i)}}</span>
                </button>
                <ng-template #abc>
                  <span class="text-success">{{item.PaymentStatus}}</span>
                </ng-template>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="card-body" *ngIf="paymentRefundsData && paymentRefundsData.length==0">
        No data avilable
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <div *ngIf="cancelCashRefundMessageEror && !CancelAllPolicyMessage">
          <h6 class="alert-danger alert">{{cancelCashRefundMessageEror}}</h6>
        </div>
        <div *ngIf="CancelAllPolicyMessage">
          <h6 class="alert-success alert">{{CancelAllPolicyMessage}}</h6>
        </div>
        <div *ngIf="cashReciptError">
          <h6 class="alert-danger alert">{{cashReciptError}}</h6>
        </div>
        <div *ngIf="isDisplayPolicyCancelMessage">
          <h6 class="alert-success alert">Policy has been cancelled successfully please intiate refund.</h6>
        </div>
      </div>
    </div>

    <!-- Modal footer -->
    <div class="modal-footer" style="justify-content: flex-end">
      <button type="submit" class="btn btn-primary btn-full-sm" (click)="refundAllConfirmation()" data-toggle="modal"
        [disabled]="isRefundClicked" data-target="#myModal-15">
        <span *ngIf="isRefundAllClicked" class="spinner-border text-primary" style="width: 1rem; height: 1rem;"
          role="status" aria-hidden="false"></span>
        <span *ngIf="!isRefundAllClicked">{{refundAllText}}</span>
      </button>
    </div>
  </div>
</ng-template>
<ng-template #manageRefundConfirmAllTemplate>
  <div class="" id="myModal-15">
    <div class="modal-body">
      <div class="col-md-5 mx-auto text-center">
        <div class="text-center mb-4 mt-4">
          <h4>Insured All Refund</h4>
        </div>
        <span>Are you sure you want to refund all?</span>
      </div>
    </div>
    <div class="text-center mb-4 mt-2 btn-footer">
      <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeRefundConfirmModel()">
        No
      </button>
      <button type="button" class="btn btn-primary btn-full-sm" (click)="confirmFullRefund()">
        Yes
      </button>
    </div>
  </div>
</ng-template>
<!--Cash Payement Refund poup-->
<ng-template #cancelCashPaymentRefundcontentTemplate>
  <div class="" id="myModal-13">
    <form [formGroup]="cancelCashPaymentForm" (ngSubmit)="cashItemRefund()">
      <div class="modal-body">
        <div class="col-md-8 mx-auto text-center">
          <div class="text-center mb-4 mt-4">
            <h5 class="mb-4">Cash</h5>
          </div>
          <div class="form-group mx-auto col-8">
            <div class="input-group date-field nopadding required">
              <input type="text" class="form-control input-sm col-12" placeholder="Receipt Number"
                formControlName="ReceiptNumber" />
            </div>
          </div>
          <div *ngIf="cancelCashRefundMessageEror">
            {{cancelCashRefundMessageEror}}
          </div>
        </div>
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeCashRefundModel()">
          Close
        </button>
        <button type="submit" class="btn btn-primary btn-full-sm" [disabled]="!cancelCashPaymentForm.valid"
          data-toggle="modal" data-target="#myModal-13">
          Continue
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #noteBox>
  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body">
          <form [formGroup]="noteForm" (ngSubmit)="noteSubmitted()">
            <h2 class="mb-2 box-label-sm">Add a Notes</h2>
            <div class="form-group">
              <textarea class="form-control" rows="3" formControlName="Description" maxlength="6000"
                placeholder="Add notes here"
                [ngClass]="{ 'is-invalid': submitted && f['Description'].errors }"></textarea>
              <div *ngIf="submitted && f['Description'].errors" class="invalid-feedback">
                <div *ngIf="f['Description'].errors?.['required']">
                  Please enter a note
                </div>
              </div>
            </div>
            <div class="text-right mt-2 btn-footer">
              <button class="btn btn-secondary btn-sm" (click)="resetForm()">
                Cancel
              </button>
              <button class="btn btn-primary btn-sm" type="submit">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #PercentageBox>
  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="card-body p-0">
          <form [formGroup]="noteForm" (ngSubmit)="PercentageSubmitted()">
            <h2 class="mb-2 pbox-sm">
              Enter a refund Percentage (0.01 - 99.99)
            </h2>
            <div *ngIf="PercentageError" class="alert-danger alert" style="padding: 8px;">
              <span>Entered value should be positive & between 0.01 - 99.99.</span>
            </div>
            <div class="form-group pbox-w" *ngIf="PartialRefundPercentage !== undefined">
              <div class="input-group">
                <input class="form-control" type="number" data-auto-id="Percentage" id="Percentage"
                  value="{{PartialRefundPercentage}}" (keydown)="ClearError()" (focusout)="onFocusOutEvent($event)" />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="form-group pbox-w" *ngIf="PartialRefundPercentage == undefined">
              <div class="input-group">
                <input class="form-control" type="number" data-auto-id="Percentage" id="Percentage"
                  (focusout)="onFocusOutEvent($event)" (keydown)="ClearError()" />
                <div class="input-group-append">
                  <span class="input-group-text">%</span>
                </div>
              </div>
            </div>
            <div class="text-center mt-2 btn-footer">
              <button class="btn btn-secondary btn-sm mr-1" (click)="ClosePercentageForm()">
                Cancel
              </button>
              <button class="btn btn-primary btn-sm ml-0" (click)="CheckPercentageError()" type="submit">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</ng-template>