import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Type, ApplicationRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { loginUserReducer } from 'src/app/core/store/loginUser/loginUser.reducer';
import { HeaderModule, HeaderComponent } from 'projects/header/src/app/app.module';
import { NavModule, NavComponent } from 'projects/nav/src/app/app.module';
import { DashBoardModule, AppComponent } from 'projects/dashboard/src/app/app.module';
import { ManageBranchesModule, ManageBranchesComponent } from 'projects/manage-branches/src/app/app.module';
import { ManageUsersModule, ManageUsersComponent } from 'projects/manageusers/src/app/app.module';
import { PolicyModule, PolicyDetailComponent } from 'projects/policyDetails/src/app/app.module';
import { PolicySummaryModule, PolicySummaryComponent } from 'projects/policySummary/src/app/app.module';
import { PromoCodeModule, PromoCodeComponent } from 'projects/promo-code/src/app/app.module';
import { PolicyPaymentsModule, PolicyPaymentsComponent } from 'projects/policy-payments/src/app/app.module';
import { PaymentGatewayModule, PaymentComponent } from 'projects/paymentGateWay/src/app/app.module';
import { endorsementReducer } from 'src/app/core/store/endorsement/endorsement.reducer';
import { policyReducer } from 'src/app/core/store/policy/policy.reducer';
import { countriesReducer } from 'src/app/core/store/country/country.reducer';
import { agentsReducer, branchesReducer, rolesReducer } from 'src/app/core/store/user/user.reducer';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  IPublicClientApplication,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import { LoginComponent } from './app.component';
import { TokenRefreshService } from './token-refresh.service';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // 'Application (client) ID' of app registration in the Microsoft Entra admin center - this value is a GUID
      clientId: '8769c949-09f8-4803-937d-b05af1838c67',
      // Full directory URL, in the form of https://login.microsoftonline.com/<tenant>
      authority:
        'https://login.microsoftonline.com/a39dc3b5-2fe0-47e2-9c45-884c2a6382b7',
      // Must be the same redirectUri as what was provided in your app registration.
      redirectUri: '/',
      postLogoutRedirectUri: '/',
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE,
    },
  });
}

// MSAL Interceptor is required to request access tokens in order to access the protected resource (Graph)
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('scopes', [
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.ReadWrite.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Group.ReadWrite.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.Invite.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Application.ReadWrite.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/AppRoleAssignment.ReadWrite.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/GroupMember.ReadWrite.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/PendingExternalUserProfile.ReadWrite.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/ExternalUserProfile.ReadWrite.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Directory.AccessAsUser.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.ManageIdentities.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.EnableDisableAccount.All',
    'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Directory.ReadWrite.All',
  ]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

// MSAL Guard is required to protect routes and require authentication before accessing protected routes
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.ReadWrite.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Group.ReadWrite.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.Invite.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Application.ReadWrite.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/AppRoleAssignment.ReadWrite.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/GroupMember.ReadWrite.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/PendingExternalUserProfile.ReadWrite.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/ExternalUserProfile.ReadWrite.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Directory.AccessAsUser.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.ManageIdentities.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/User.EnableDisableAccount.All',
        'api://832816ca-9b4a-41dd-b4e1-76db8495bb63/Directory.ReadWrite.All',
      ],
    },
  };
}

export function initializeApp(msalService: MsalService): () => Promise<void> {
  return () => msalService.instance.initialize();
}

var reducer = { loginUser: loginUserReducer };
var url = window.location.pathname;
var currentModule = DashBoardModule;
var currentComponent: Type<any> = AppComponent;
if (url.toLowerCase() == '/promocode') {
  currentModule = PromoCodeModule;
  currentComponent = PromoCodeComponent;
} else if (url.toLowerCase() == '/managebranches') {
  currentModule = ManageBranchesModule;
  currentComponent = ManageBranchesComponent;
} else if (url.toLowerCase() == '/policy-detail') {
  currentModule = PolicyModule;
  currentComponent = PolicyDetailComponent;
  reducer = { ...reducer, ...{ policy: policyReducer, endorsement: endorsementReducer, countries: countriesReducer } }
} else if (url.toLowerCase() == '/policysummary') {
  currentModule = PolicySummaryModule;
  currentComponent = PolicySummaryComponent;
} else if (url.toLowerCase() == '/manageusers') {
  currentModule = ManageUsersModule;
  currentComponent = ManageUsersComponent;
  reducer = { ...reducer, ...{ branches: branchesReducer, roles: rolesReducer, agents: agentsReducer } };
} else if (url.toLowerCase() == '/policypayments') {
  currentModule = PolicyPaymentsModule;
  currentComponent = PolicyPaymentsComponent;
} else if (url.toLowerCase() == '/paymentsummary') {
  currentModule = PaymentGatewayModule;
  currentComponent = PaymentComponent;
}

@NgModule({
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MsalModule,
    HeaderModule,
    NavModule,
    currentModule,
    StoreModule.forRoot(reducer),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
    }),
    EffectsModule.forRoot([]),
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent],
  providers: [
    HttpClientModule,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [MsalService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    TokenRefreshService,
  ],
})
export class LoginModule {
  constructor(private tokenRefreshService: TokenRefreshService) {
    this.tokenRefreshService.startTokenRefresh();
  }
  
  ngDoBootstrap(app: ApplicationRef) {
    app.bootstrap(LoginComponent);
    app.bootstrap(MsalRedirectComponent);
    app.bootstrap(HeaderComponent);
    app.bootstrap(NavComponent);
    app.bootstrap(currentComponent);
  }
}
