import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfig {
  private config: any = null;
  private env: any = null;

  constructor(private http: HttpClient) {}

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   * a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   * b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load() {
    return new Promise((resolve, reject) => {
      let request: any = null;

      // let baseUrl = window.location.origin
      //      ? window.location.origin + '/'
      //      : window.location.protocol + '/' + window.location.host + '/';
      // // baseUrl = `${baseUrl}${environment.assetsPath}/configSettings.json`;
      // // let baseUrl = window.location.origin;
      // baseUrl = `${baseUrl}/assets/configSettings.json`;

      // //console.log("Reading config file. with url:" + baseUrl);
      // request = this.http.get(baseUrl);

      if (request) {
        request.subscribe(
          (responseData: any) => {
            this.config = responseData;
            resolve(true);
          },
          (error: any) => {
            // console.log(
            //   `Configuration file "configSettings.json" could not be read`,
            // );

            let errorMsg = '';
            // if (error && error.json().error)
            // errorMsg = error.json().error;
            // else
            // errorMsg = error;
            // console.log(
            //   `Error message while reading appconfigJson: ${JSON.stringify(errorMsg)}`,
            // );
            resolve(true);
            return throwError(() => 'Server error');
          },
        );
      } else {
        // console.error(`Env config file "configSettings.json" is not valid`);
        resolve(true);
      }
    });
  }
}
