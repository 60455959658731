import { createAction, props } from '@ngrx/store';
import { Agents, Branches, Roles } from './user.model';

export const setBranches = createAction(
    'Set Branches',
    props<{ branches : Branches}>()
)

export const setAgents = createAction(
    'Set Agents',
    props<{ agents : Agents }>()
)

export const setRoles = createAction(
    'Set Roles',
    props<{ roles : Roles}>()
)

export const updateBranches = createAction(
    'Update Branches',
    props<{ updateBranches : Partial<Branches>}>()
)

export const updateAgents = createAction(
    'Update Agents',
    props<{ updateAgents : Partial<Agents>}>()
)

export const updateRoles = createAction(
    'Update Roles',
    props<{ updateRoles : Partial<Roles>}>()
)

