<app-loader></app-loader>
<section class="step4">
  <div class="container">
    <h2 class="mt-4 box-label">{{ paymentText }}</h2>
    <br>
    <div class="row">
      <div class="col-md-6 col-xl-6">
        <div class="box-panel">
          <div *ngIf="showLoader" style="position: relative; text-align: center">
            <div #loaderRef>
              <ng-content select=".ngxLoader" *ngIf="!hideLoader"></ng-content>
            </div>
            <img *ngIf="loaderRef.children.length === 0 && !hideLoader" src="{{ loaderImage }}" />
          </div>
          <div class="error" *ngIf="errorMessage">Error</div>
          <div *ngIf="clientToken" ngxBraintreeDirective>
            <div id="dropin-container"></div>
            <div #buttonsRef>
              <ng-content class="confirmBtn" select=".ngxButtons"></ng-content>
            </div>
            <br>
            <div class="action-button-wrapper">
              <span *ngIf="buttonsRef.children.length === 0 && !enabledStyle && !disabledStyle">
                <button data-auto-id="paymentBtn" *ngIf="showPayButton" ngxPay>
                  {{ buttonText }} <i class="fa fa-chevron-right pl-2"></i>
                </button>
              </span>
            </div>
          </div>

          <div *ngIf="showPaymentReceipt" class="alert alert-success mt-3">
            {{ paymentSuccessMsg }}
          </div>
          <div *ngIf="showPaymentReceipt">
            <a [href]="boxSharedLinkUrl">Download quote (PDF)</a>
          </div>
          <div *ngIf="errorMessage" class="alert alert-warning" style="margin-top: 10px">
            {{ errorMessage }}
          </div>
          <div *ngIf="clientTokenNotReceived">
            <div class="error">{{ ErrorClientToken }}</div>
            <div class="errorInfoDiv">
              Make sure your clientTokenURL's JSON response is as shown below:
              <pre>{{ '{' }}"token":"braintree_client_token_generated_on_your_server"{{ '}' }}</pre>
            </div>
          </div>
          <!--continue button-->
        </div>
      </div>
      <!--- summary section starts here-->
      <div class="col-md-6 col-xl-6">
        <div class="card-summary" style="margin-top: 31px">
          <h1 class="card-gray-header-summary">{{ summaryText }} :</h1>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" *ngFor="let item of policySummaryData; let i = index">
              <div class="row" *ngIf="item.KeyText !== 'TotalPremium'">
                <div class="col-md-8">
                  <span>{{ item.Description }}</span>
                </div>
                <div class="col-md-4 text-right">
                  <strong>
                    <span
                      *ngIf=" (item.Description === 'Membership Discount' || item.Description === 'Promo Code') && item.Value !== '$0.00' ">-</span>{{
                    item.Value }}
                  </strong>
                </div>
              </div>
              <div class="row" *ngIf="item.KeyText === 'TotalPremium'">
                <div class="col-md-8">
                  <span class="card-gray-header-summary p-0">{{ item.Description }}</span>
                </div>
                <div class="col-md-4 text-right">
                  <span class="card-gray-header-summary p-0">{{ item.Value }}
                  </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="card-total">
          <div class="row">
            <div class="col-6 col-md-8">
              <strong>Additional Amount</strong>
            </div>
            <div class="col-6 col-md-4 text-right">
              <strong> {{ additionalAmount }} </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Confirmation Box POP UP --->
<ng-template #warningBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModalConfirmation()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Error }} ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModalConfirmation()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>

<ng-template #confirmationBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-thumbs-up" aria-hidden="true"></i> Confirmation
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModalConfirmation()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Message }} ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModalConfirmation()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>

<ng-template #alertBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Alert
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModalConfirmation()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Error }} ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModalConfirmation()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>