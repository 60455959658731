/// <reference path="services/messagebus.service.ts" />
import { ViewChild, TemplateRef, ElementRef, Component, OnDestroy, AfterViewInit } from '@angular/core';
import { PolicyDetailService } from '../app/app.services';
import { Utility } from './shared/utility';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
import { DataBusService } from './services/databus.service';
import { MessageBusService } from './services/messagebus.service';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { PreExitingBusService } from './services/preextingbus.service';
import { DateService } from 'src/app/core/services/date-updated.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { SitecoreItemService } from './services/sitecore-item.service';
import { EndorsementGenericService } from './shared/services/endorsement-generic.service';
import { FormGroup, Validators, AbstractControl, FormBuilder, } from '@angular/forms';
import { SetQueryParamsService } from 'src/app/core/role/setqueryparams';
import { LogService } from './shared/logging/log-service';
import { LogLevel } from 'projects/policyDetails/src/app/shared/enum/log-level-enum';
import { PageNameEnum, SubComponentNameEnum, } from 'projects/policyDetails/src/app/enum/page-name-enum';
import { CXOneService } from 'src/app/core/cxone/cxone.service';
import { TravelerUpdateService } from './shared/services/traveller-update.service';
import { EventEmitterService } from './shared/services/event-emitter.service';
import { Store } from '@ngrx/store';
import { loginUser } from 'src/app/core/store/loginUser/loginUser.selectors';
import { UserRoleDetails, UserProfile } from 'src/app/core/store/loginUser/loginUser.model';
import { isPolicyDataPresent, selectCurrentPolicy } from 'src/app/core/store/policy/policy.selectors';
import { setPolicy } from 'src/app/core/store/policy/policy.actions';
import { isEndorsementDataPresent, selectCurrentEndorsement } from 'src/app/core/store/endorsement/endorsement.selectors';
import { setEndorsement, updateEndorsement, discardEndorsement } from 'src/app/core/store/endorsement/endorsement.actions';
import { StoreGenericService } from 'src/app/core/services/store-generic.service';
import { setCountries } from 'src/app/core/store/country/country.actions';
import { element } from 'protractor';

@Component({
  selector: 'policyDetails-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class PolicyDetailComponent implements OnDestroy, AfterViewInit {
  title = 'policyDetails';
  emptyPolicy: string = "Default Data";
  NotesData: any;
  policyGuid!: string;
  policyTxnId!: string;
  iscancelrewrite: boolean = false;
  tripDetailsDependentlist: any = [];
  checkedIds: any[] = [];
  list: any[] = [];
  agentList: any;
  isDisabled!: boolean;
  countryCodeName!: string;
  isRewrite: boolean = true;
  policyDetails: any = {};
  policyTripDetails: any = {};
  policyTravelCare: any = {};
  policyTravellerDetails: any = {};
  policySummarys: any = {};
  policyData: any = {};
  policyTxIdData: any;
  policyAllData: any;
  policyDetailsStorageData: any;
  additionalAmountAfterEN: any;
  getPolicyAllData: any;
  paymentStatusNB: any;
  paymentStatusEN: any;
  discardMessageFail!: string;
  discardMessageSuccess!: string;
  modelAlertRef!: NgbModalRef;
  isLoader: boolean = false;
  isLoaderDiscard: boolean = false;
  buttonPolicyVisable: boolean = false;
  reAssessmentNeed: boolean = false;
  isHideCancel: boolean = false;
  isReactivateCancelPolicy: boolean = false;
  buttonText = 'Update Policy';
  Movement: any;
  Activity: any;
  public modalData: any;
  @ViewChild('noteBox', { static: true }) noteBox!: ElementRef
  @ViewChild('reactivatePolicyBox', { static: true }) reactivatePolicyBox!: ElementRef
  @ViewChild('assessmentBox', { static: true }) assessmentBox!: ElementRef
  @ViewChild('discardAlertTemplate', { static: true }) dialogalertBox!: TemplateRef<ElementRef>
  @ViewChild('alertBox', { static: true }) alertBox!: ElementRef;
  paymentData: any;
  refundedData: any;
  refundData: any;
  isDisabledDiscard: boolean = true;
  isUpdateRevisionDisabled: boolean = false;
  isReadOnly: any = false;
  IsTMMReadOnly: any = false;
  isErrorInReactivation: any = false;
  isCancelStatus = false;
  noteForm!: FormGroup;
  submitted = false;
  isreactivatePolicyNote = false;
  loadOriginalPolicy = true;
  siteName = "";
  isAddNoteClicked: boolean = false;
  userRoleDetails?: UserRoleDetails;
  userProfile?: UserProfile;
  public siteCoreSettings: any;
  public exportAuditHistoryResponse: any;
  public currentPolicyInStore: any;
  public IsPolicyInStore: boolean = false;
  public currentENInStore: any;
  public IsENInStore: boolean = false;
  public msg: string = 'Traveller detail updated successfully';

  constructor(
    public dataService: PolicyDetailService,
    private portalCodeService: PortalCodeService,
    private loaderService: LoaderService,
    private setQueryParamsService: SetQueryParamsService,
    private dataBusService: DataBusService,
    // public oktaCustomAuthService: OktaCustomAuthService,
    private messageBusService: MessageBusService,
    private endorsementGenericService: EndorsementGenericService,
    private dateService: DateService,
    private modelService: NgbModal,
    private sitecoreItemService: SitecoreItemService,
    public formBuilder: FormBuilder,
    private logService: LogService,
    private travelerUpdateService: TravelerUpdateService,
    private eventEmitter: EventEmitterService,
    private store: Store,
    private storeService: StoreGenericService
  ) {
    //Policy
    this.store.select(selectCurrentPolicy).subscribe(pol => {
      this.currentPolicyInStore = pol;
    });
    this.store.select(isPolicyDataPresent).subscribe(present => {
      this.IsPolicyInStore = present;
    });

    //Endorsement Store
    this.store.select(isEndorsementDataPresent).subscribe(present => {
      this.IsENInStore = present;
    });

    this.store.select(selectCurrentEndorsement).subscribe(en => {
      this.currentENInStore = en;
    });
  }

  ngOnInit() {
    this.noteForm = this.formBuilder.group({
      Description: [
        '',
        Validators.compose([Validators.required, this.requiredWithoutBlank]),
      ],
    });
    this.store.select(loginUser).subscribe((loginUser) => {
      if (loginUser) {
        this.userRoleDetails = loginUser.userRoleDetails;
        this.userProfile = loginUser.userProfile;
        this.isReadOnly = this.userRoleDetails?.IsReadOnly;
        this.IsTMMReadOnly = this.userRoleDetails?.IsTMMReadOnly;
        sessionStorage.setItem('policyUpdateClick', 'false');
        let checkQueryStringPolicyTxnId = this.setQueryParamsService.getURLParameter('PolicyTxnId');

        if (checkQueryStringPolicyTxnId != '' && checkQueryStringPolicyTxnId != undefined && checkQueryStringPolicyTxnId != null) {
          sessionStorage.setItem('movementGuid', checkQueryStringPolicyTxnId);
          sessionStorage.setItem('originalPolicyTxnId', checkQueryStringPolicyTxnId);
        }
        let policyGuid = this.setQueryParamsService.getURLParameter('policyguid');
        if (policyGuid) {
          sessionStorage.setItem('policyGuid', policyGuid);
        }
        this.noteForm = this.formBuilder.group({
          Description: [
            '',
            Validators.compose([Validators.required, this.requiredWithoutBlank]),
          ],
        });

        if (checkQueryStringPolicyTxnId != '' && checkQueryStringPolicyTxnId != undefined && checkQueryStringPolicyTxnId != null) {
          sessionStorage.setItem('movementGuid', checkQueryStringPolicyTxnId);
          sessionStorage.setItem('originalPolicyTxnId', checkQueryStringPolicyTxnId);
        }

        //Get Policy Call
        this.dataBusService.policyData.subscribe((data) => {
          //Set Policy Data in Store 
          if (data.value != this.emptyPolicy) {
            var pendingEN = (data.movements.length > 0) ? data.movements.find((data: any) => data.status === 'Pending') : {};
            if (pendingEN != undefined) {
              sessionStorage.setItem('movementGuid', pendingEN.movementId);
            }
            this.storeService.setPolicyToStore(data);
          }

          if (data.value != this.emptyPolicy && data.movements) {
            if (sessionStorage.getItem('hideAdditional') && sessionStorage.getItem('hideAdditional') == 'true') {

            }
            else {
              this.setMovements(data);
              if (this.loadOriginalPolicy) {
                this.getENData(data, false);
              }
            }
            this.loaderService.hide();
          }
        });
        this.dataBusService.publishPolicyDetails();
        this.eventEmitter.onDiscardCall.subscribe((isUpdated: string) => {
          if (isUpdated) {
            this.discardChanges();
          }
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.loaderService.show();
  }

  updateRefundData() {
    if (this.paymentData || this.refundedData) {
      this.refundData = {
        ccRefundAmt:
          this.paymentData?.ccRefundAmt - (this.refundedData?.ccRefundedAmt ?? 0),
        pcRefundAmt:
          this.paymentData?.pcRefundAmt - (this.refundedData?.pcRefundedAmt ?? 0),
      };
    }
  }

  updateOrRevisionPolicy() {
    sessionStorage.setItem('policyUpdateClick', 'true');
    this.loaderService.show();

    //Fetch PolicyData from Store
    if (!this.IsPolicyInStore) {
      this.dataService.getData(this.policyGuid).subscribe(
        (data) => {
          this.storeService.setPolicyToStore(data);
          this.setMovements(data);
          this.getENData(data, true);
        },
        (err) => {
          this.isLoader = false;
        });
    }
    else {
      this.setMovements(this.currentPolicyInStore);
      this.getENData(this.currentPolicyInStore, true);
    }
  }

  async getENData(policyData: any, updateRevisionButtonClicked: boolean) {
    if (sessionStorage.getItem('originalMovementGuid') != sessionStorage.getItem('movementGuid')) {
      this.buttonText = 'Continue Revision';
      this.isUpdateRevisionDisabled = false;
    }
    var sortedMovements = policyData.movements.sort(function (a: { version: number }, b: { version: number }) { return a.version - b.version });
    if (policyData && sortedMovements.length > 0) {
      sessionStorage.setItem('cancelStatus', '');
      let result = sortedMovements[sortedMovements.length - 1];
      if (result && result.transactionStatus === 'CN') {
        this.isHideCancel = true;
        this.isCancelStatus = true;
      }
      if (((result && result.transactionStatus === 'CN' && result.status === 'Completed') || (result.status === 'Pending' && result.type == 'RE')) && this.userRoleDetails?.IsUW) {
        this.isReactivateCancelPolicy = true;
        this.isHideCancel = true;
        this.isCancelStatus = true;
        sessionStorage.setItem('cancelStatus', 'true');
      }
      if (result && result.transactionStatus === 'EX' && !this.userRoleDetails?.IsUW) {
        this.isHideCancel = true;
        this.isCancelStatus = true;
      }
      // if (result && result.transactionStatus == 'RW' && result.status !== 'Completed') {
      //   this.isRewrite = false;
      //   if (!this.isRewrite) {
      //     sessionStorage.setItem('policyAlreadySwitched', 'true');
      //   }
      //   else {
      //     sessionStorage.setItem('policyAlreadySwitched', 'false');
      //   }
      // }
    }

    await this.checkIfENToBeCalled(policyData, updateRevisionButtonClicked);

    if (this.IsENInStore) {
      policyData = this.currentENInStore;
    }

    if (policyData) {
      this.additionalAmountAfterEN = (policyData.premiums != null) ? policyData.premiums.additionalPremium : 0;
    }

    if (policyData && policyData.movements && policyData.movements.length >= 0) {
      let movementNB = policyData.movements.find((data: any) => data.type !== undefined && data.type === 'NB');

      if (movementNB && movementNB.status && movementNB.status !== undefined) {
        this.paymentStatusNB = movementNB.status;
      }

      let movementEN = policyData.movements.find((data: any) => data.type !== undefined && data.type === 'EN');
      if (movementEN && movementEN.status && movementEN.status !== undefined) {
        this.paymentStatusEN = movementEN.status;
      }
    }

    if (policyData && policyData !== undefined) {
      //Here we get the data  for Policy Detail components
      this.policyDetails.PolicyNum = policyData.policyNumber;
      this.policyDetails.PlanName = policyData.CoverageType;
      this.policyDetails.PurchaseDate = policyData.purchaseDate;
      this.policyDetails.PricingDate = policyData.rateDate;
      this.policyDetails.PaymentTotal = policyData.premiums.totalCost;
      this.policyDetails.AgentName = '';

      // From here we get the data for policyTripDetails components
      if (policyData.CountryCode) {
        // await this.getCountry(policyData.CountryCode);
      }
      this.policyTripDetails.Departure = policyData.PolicyEff;
      this.policyTripDetails.Return = policyData.PolicyExp;

      //To find the date difference between two Date picker
      this.policyTripDetails.Duration = this.dateService.daysDifference(policyData.fromDate, policyData.toDate);

      //ToDo Agent 
      this.policyDetails.PolicyProducerContactId = policyData.PolicyProducerContactId;
      let contactId = this.policyDetails.PolicyProducerContactId;
      if (contactId && policyData.AdditionalPolicyDetailsGTA.Agents.SalesAgent) {
        this.agentList = policyData.AdditionalPolicyDetailsGTA.Agents.SalesAgent.AgentName;
        this.policyDetails.agentName = this.agentList;
      }
    }
  }

  async createEndorsementCall() {
    this.loadOriginalPolicy = false;
    if (sessionStorage.getItem('veriskLoaded') != 'Yes') {
      this.loaderService.show();
      this.policyTxnId = sessionStorage.getItem('movementGuid') || '';

      let createENRequest = {
        "policyGuid": this.currentPolicyInStore.policyGuid,
        "movementGuid": this.policyTxnId,
        // "loggedInUser": "SYSTEM"
      }

      var endorsementResponse = await this.endorsementGenericService.createEndorsementCall(createENRequest).toPromise()
      if (endorsementResponse != null) {
        this.loaderService.hide();
        if (endorsementResponse != null) {
          sessionStorage.setItem('movementGuid', endorsementResponse.movementGuid.toString());

          if (endorsementResponse.value != "Default Data") {
            this.storeService.setENToStore(endorsementResponse);
          }
        }
      }
    }
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    };
    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(
      data,
      fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION,
    );
  }

  exportAuditHistory() {
    this.dataService.exportHistory(sessionStorage.getItem('policyGuid') || '').subscribe(
      (data) => {
        if (data != undefined && data.length > 0) {
          this.exportAuditHistoryResponse = data;
          this.exportAsExcelFile(
            this.exportAuditHistoryResponse,
            this.currentPolicyInStore.policyNumber + '_history_',
          );
        }
      },
      (err) => {
        this.loaderService.hide();
      },
    );
  }

  checkPolicyTxnIdWithEndorse(data: any) {
    if (
      sessionStorage.getItem('originalMovementGuid') === sessionStorage.getItem('movementGuid')
    ) {
      this.isDisabled = true;
    } else if (
      sessionStorage.getItem('originalMovementGuid') !== sessionStorage.getItem('movementGuid') && this.paymentStatusNB == 'Completed' && this.paymentStatusEN == 'Completed'
    ) {
      this.isDisabled = true;
    } else if (
      sessionStorage.getItem('originalMovementGuid') !== sessionStorage.getItem('movementGuid') && data <= 0
    ) {
      this.isDisabled = true;
    }
    else {
      this.isDisabled = false;
    }
  }

  DateConvertIntoMs(dateTime: any) {
    if (dateTime) {
      dateTime = dateTime.toString();
      if (dateTime.split('T')[1] != undefined) {
        let formateDate =
          dateTime.split('T')[0] + ' ' + dateTime.split('T')[1].split('.')[0];
        return new Date(formateDate).getTime();
      } else {
        let splitedDateTime = dateTime.split(' ');
        let spltDate = splitedDateTime[0].split('/');
        let dateConverted = new Date(
          spltDate[2] +
          '-' +
          spltDate[1] +
          '-' +
          spltDate[0] +
          ' ' +
          splitedDateTime[1],
        ).getTime();
        return dateConverted;
      }
    }
  }

  isNoteAdded() {
    this.dataService.getNotes(sessionStorage.getItem("movementGuid")).subscribe
      ((data: any) => {
        if (data.length > 0) {
          this.Activity = JSON.parse(JSON.stringify(data));
        }
      })
    let notes = this.Activity || [];
    let policyMovement =
      this.Movement.length > 0 ? JSON.parse(JSON.stringify(this.Movement)) : [];

    // check for in process policy
    let movement = policyMovement.filter((inpro: any) => {
      return inpro.status === 'Completed';
    });

    // compare time of in process policy and notes
    let lastCompletedEndrsmt;
    let lastAddedNode;
    if (movement.length > 0) {
      lastCompletedEndrsmt = movement[movement.length - 1];
    }
    if (notes.length > 0) {
      lastAddedNode = notes[notes.length - 1];
    }

    if (lastCompletedEndrsmt && lastAddedNode) {
      lastCompletedEndrsmt.TxnIssued = this.DateConvertIntoMs(
        lastCompletedEndrsmt.TxnIssued,
      );
      lastAddedNode.ActivityUTCDateTime = this.DateConvertIntoMs(
        lastAddedNode.ActivityUTCDateTime,
      );
      if (lastCompletedEndrsmt.TxnIssued > lastAddedNode.ActivityUTCDateTime) {
        return false;
      } else if (lastAddedNode.ActivityUTCDateTime) {
        let lastCompletedData = policyMovement[policyMovement.length - 1];
        lastCompletedData.DateEntered = this.DateConvertIntoMs(
          lastCompletedData.DateEntered,
        );
        if (lastCompletedData.DateEntered > lastAddedNode.ActivityUTCDateTime) {
          return false;
        } else {
          this.loaderService.show();
          return true;
        }
      } else {
        this.loaderService.show();
        return true;
      }
    }

    return false;
  }

  compareAndContinue() {

    if (this.reAssessmentNeed) {
      this.showReassessmentError();
      return;
    }
    // if (this.isNoteAdded()) {
    //   window.location.href = '/policysummary';
    // }
    // else {
      this.openNotes();
    // }
    sessionStorage.setItem('fromVerisk', 'No');
  }

  openAlertModel() {
    this.modelAlertRef = this.modelService.open(this.dialogalertBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }
  openReactivateAlertModel(content: any, rowdata: any) {
    this.modelService.dismissAll();
    this.isAddNoteClicked = false;
    this.modelService.open(content, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
    this.modalData = rowdata;
  }
  closeReactivateAlertModel() {
    this.noteForm.reset();
    this.modelService.dismissAll();
  }
  closeAlertChildModal() {
    if (this.discardMessageSuccess) {
      this.modelAlertRef.close();
      //window.location.href = "/dashboard";
      window.location.href = '/policy-detail';
    } else this.modelAlertRef.close();
  }

  discardChanges() {
    this.loaderService.show();
    this.discardMessageSuccess = '';
    this.discardMessageFail = '';
    let data = {
      movementGuid: sessionStorage.getItem('movementGuid'),
      // loggedInUser: 'SYSTEM',
    };
    this.dataService
      .discardEN(data)
      .pipe(filter((data) => data !== undefined))
      .subscribe(
        (response) => {
          if (response && response.isSuccessful) {
            sessionStorage.setItem('movementGuid', sessionStorage.getItem('originalMovementGuid') || '',);
            this.discardMessageSuccess = 'Changes has been discarded.';
            this.openAlertModel();
            this.loaderService.hide();
          } else {
            this.discardMessageFail = 'Something went wrong please try again.';
            this.openAlertModel();
            this.loaderService.hide();
          }
        },
        (err) => {
          this.loaderService.hide();
        },
      );
  }

  openNotes() {
    this.submitted = false;
    this.modelAlertRef = this.modelService.open(this.noteBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }
  closeNotes() {
    this.modelService.dismissAll();
  }

  showReassessmentError() {
    this.modelAlertRef = this.modelService.open(this.assessmentBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }
  closeReassessmentError() {
    this.modelService.dismissAll();
  }

  formatAuditHistoryDate(d: Date) {
    var time = d.toLocaleTimeString();
    return (
      [this.pad(d.getDate()), this.pad(d.getMonth() + 1), d.getFullYear()].join(
        '/',
      ) +
      ' ' +
      time
    );
  }

  pad(degit: any) {
    return degit < 10 ? '0' + degit : degit;
  }

  get f() {
    return this.noteForm.controls;
  }

  resetForm() {
    this.noteForm.reset();
    this.closeNotes();
  }

  async noteSubmitted() {
    this.submitted = true;
    if (this.noteForm.invalid) {
      return;
    }
    this.isAddNoteClicked = true;
    let todayISOString = new Date().toISOString();
    let data = {
      policyGuid: sessionStorage.getItem("policyGuid"), //parseInt(this.policytxnid),
      movementGuid: sessionStorage.getItem("movementGuid"),
      description: this.noteForm.controls['Description'].value,
      // loggedInUser: this.userProfile?.LoggedUser,
    };
    let notes = await this.dataService.notes(data).toPromise().catch((err) => {
      this.isAddNoteClicked = false;
    });;
    if (this.isreactivatePolicyNote) {
      await this.reactivatePolicy();
    } 
    else {
      // this.dataBusService.publishPolicyDetails();
      window.location.href = '/policysummary';
      // this.setCoiFormSetId();
    }
  }
  requiredWithoutBlank(
    control: AbstractControl,
  ): { [key: string]: boolean } | any {
    if (control.value) {
      if (control.value.trim().length > 0) {
        return null;
      } else {
        return { required: true };
      }
    }
  }

  ngOnDestroy() {
    this.loaderService.hide();
  }

  async reactivatePolicy() {
    this.loaderService.show();
    let createReinstatementObject = {
      policyGuid: this.currentPolicyInStore.policyGuid,
      movementGuid: this.currentPolicyInStore.movementGuid
    };
    await this.dataService.policyReActivate(createReinstatementObject).toPromise()
      .then((resp) => {
        console.log(resp);
        if (resp != undefined && resp?.errors == null) {
          this.loaderService.hide();
          sessionStorage.setItem('movementGuid', resp.movementGuid.toString());
          this.storeService.setENToStore(resp);
          setTimeout(function(){
            window.location.href = '/policysummary';
          },50);
        }
        else if (resp != undefined && resp.errors.length > 0) {
          this.isErrorInReactivation = true;
          this.loaderService.hide();
          this.openReactivateAlertModel(this.alertBox, { Error: resp.errors });
        }
      })
      .catch((err) => {
        this.loaderService.hide();
      });
  }

  reactivatePolicyOpenNote() {
    this.modelService.dismissAll();
    this.isreactivatePolicyNote = true;
    this.openNotes();
  }

  closeReactivatePolicyPopup() {
    this.modelService.dismissAll();
    window.location.href = '/policy-detail';
  }

  openreactivatePolicyPopup() {
    this.isreactivatePolicyNote = false;
    this.submitted = false;
    this.modelAlertRef = this.modelService.open(this.reactivatePolicyBox, {
      size: 'lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  checkAndDiscardChanges() {
    this.discardChanges();
  }

  setMovements(data: any) {
    if (data && data.movements && data.movements.length > 0 && data.movements != undefined) {
      this.Movement = data.movements;
    }
  }
  private capitalizeFirstLetter(str: string): string {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  async checkIfENToBeCalled(policyData: any, updateButtonClicked: any) {
    this.isDisabled = true;
    let policyMovement: any;
    var sortedMovements = policyData.movements.sort(function (a: { version: number }, b: { version: number }) { return a.version - b.version });
    if (this.isCancelStatus == false) {
      if (policyData && policyData.movements && sortedMovements.length > 0) {
        if (!updateButtonClicked) {
          policyMovement = sortedMovements.find((data: any) => data.status === 'Completed');
          if (policyMovement != undefined) {
            let resultMovement = sortedMovements.find((data: any) => data.status === 'Pending' && (data.type == 'EN' || data.type == 'RE'));
            if (resultMovement != undefined) {
              let movementId = resultMovement.movementId;
              policyMovement = sortedMovements.find((data: any) => data.movementId == movementId);
              this.isDisabled = false;
              this.buttonText = 'Continue Revision';
              this.buttonPolicyVisable = true;
            }
            else {
              let movement = sortedMovements[sortedMovements.length - 1];
              movement = sortedMovements.find((data: any) => data.movementId == movement.movementId);
              this.isDisabled = true;
              this.buttonText = 'Update Policy';
            }
          }
          else {
            let resPolicyTxnIdExit = sortedMovements.find((data: any) => data.status === 'Pending' && (data.type == 'EN' || data.type == 'RE'));
            if (resPolicyTxnIdExit != undefined) {
              this.buttonPolicyVisable = true;
              this.buttonText = 'Continue Revision';
            }
          }
        }
        else {
          policyMovement = sortedMovements.find((data: any) => data.status === 'Pending' && (data.type == 'EN' || data.type == 'RE'));
          this.isDisabled = false;
          this.buttonPolicyVisable = true;
          this.buttonText = 'Continue Revision';
          if (policyMovement == undefined) {
            let movement = sortedMovements[sortedMovements.length - 1];
            policyMovement = policyMovement = sortedMovements.find((data: any) => data.movementId == movement.movementId);
            this.buttonText = 'Update Policy';
            this.buttonPolicyVisable = false;
          }
          if (policyMovement && policyMovement.type == 'RE' && policyMovement.transactionStatus === 'PR' && policyMovement.status !== 'Completed') {
            if (this.iscancelrewrite == false) {
              // this.fetchCancelRewrite(policyData.TxnEff);
            }
          }
          else if (updateButtonClicked) {
            this.isDisabled = false;
            await this.createEndorsementCall();
          }
        }
        if (policyMovement == undefined && updateButtonClicked == false) {
          this.isDisabled = true;
          this.buttonText = 'Update Policy';
        }
      }
      else {
        if (updateButtonClicked) {
          await this.createEndorsementCall();
        }
      }
    }
    else {

      let reActivatePolicyTxnIdExit = sortedMovements.find((data: any) =>
        (data.type === 'RE' && data.transactionStatus === 'IN') ||
        (data.type === 'RE' && data.transactionStatus === 'PE') ||
        (data.type === 'RE' && data.transactionStatus === 'EX'),
      );

      if (reActivatePolicyTxnIdExit == undefined) {
        let resCanxelPolicyTxnIdExit = sortedMovements.find((data: any) => data.transactionStatus === 'CN' || data.transactionStatus === 'EX',);

        if (resCanxelPolicyTxnIdExit != undefined) {
          let resCanxelPolicyTxnId = sortedMovements[sortedMovements.length - 1];
          sessionStorage.setItem('movementGuid', resCanxelPolicyTxnId.movementId.toString(),);
        }
      } else {
        sessionStorage.setItem('movementGuid', reActivatePolicyTxnIdExit.movementId.toString(),);
      }
    }

    if (this.buttonPolicyVisable == false) {
      this.isDisabled = true;
      this.buttonText = 'Update Policy';
    } else if (policyMovement.transactionStatus === 'PE' && policyMovement.type === 'RE' && !this.userRoleDetails?.IsUW) {
      this.isHideCancel = true;
    }

    if (updateButtonClicked) {
      this.isDisabled = false;
      this.isUpdateRevisionDisabled = true;
    }
  }
}
