import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

@Directive()
@Injectable({
  providedIn: 'root',
})
export class EventEmitterService {
  @Output() onTravellerUpdated: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() onPolicyPaymentsIssueCall: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() onManageRefundIssueCall: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() onPaymentSummaryIssueCall: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() onPaymentGatewayIssueCall: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() onDiscardCall: EventEmitter<boolean> = new EventEmitter<boolean>();

  onTrvlUpdated(IsUpdated: any) {
    this.onTravellerUpdated.emit(IsUpdated);
  }

  onPolicyPaymentsIssue(IsUpdated: any) {
    this.onPolicyPaymentsIssueCall.emit(IsUpdated);
  }

  onManageRefundIssue(IsUpdated: any) {
    this.onManageRefundIssueCall.emit(IsUpdated);
  }

  onPaymentSummaryIssue(IsUpdated: any) {
    this.onPaymentSummaryIssueCall.emit(IsUpdated);
  }

  onPaymentGatewayIssue(IsUpdated: any) {
    this.onPaymentGatewayIssueCall.emit(IsUpdated);
  }

  onDiscard(IsUpdated: any) {
    this.onDiscardCall.emit(IsUpdated);
  }
}
