<app-loader></app-loader>
<section class="mb-5">
  <div class="container">
    <div class="row">
      <div class="col-sm-4">
        <h2 class="mt-4 mb-4 box-label">Summary of Changes</h2>
      </div>
      <div class="col-sm-4">
        <h2 class="mt-4 mb-4 box-label">Policy# {{ PolicyNum }}</h2>
      </div>
      <div class="col-sm-4 text-right mt-3">
        <a class="btn btn-primary btn-full-sm min-width-190" href="/policy-detail">Policy Summary</a>
      </div>
    </div>
    <div class="card bg-light">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table borderless">
            <thead>
              <tr>
                <th class="header-table">TRIP DETAILS</th>
                <th class="header-table">PREVIOUS PLAN</th>
                <th class="header-table">UPDATED PLAN</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="">Destination</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <!-- {{previousCountryName.split(',').length - 1}} -->
                  <span *ngIf="isLoadeing === 'No'">
                    <!-- {{(previousCountryName && previousCountryName !=undefined ? previousCountryName : 'NA')}} -->

                    <div class="" *ngIf="previousCountryName.split(',').length - 1 > 0"
                      (click)="toggleMultipleCountry()">
                      <strong>Multiple {{ toggleMultiple ? '-' : '+' }}</strong>
                      <div [ngStyle]="{
                          display: toggleMultiple == false ? 'none' : 'block'
                        }" *ngFor="
                          let destination of previousCountryName.split(',')
                        ">
                        <div class="left"></div>
                        <div class="right">
                          <strong>{{ destination }}</strong>
                        </div>
                      </div>
                    </div>

                    <div class="">
                      <div *ngIf="previousCountryName.split(',').length - 1 == 0">
                        <strong>{{
                          previousCountryName &&
                          previousCountryName != undefined
                          ? previousCountryName
                          : 'NA'
                          }}</strong>
                      </div>
                    </div>
                  </span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">
                    <!-- {{(currentCountryName && currentCountryName !=undefined ? currentCountryName : 'NA')}} -->
                    <div class="" *ngIf="currentCountryName.split(',').length - 1 > 0"
                      (click)="toggleMultipleCountry2()">
                      <strong>Multiple {{ toggleMultiple2 ? '-' : '+' }}</strong>
                      <div [ngStyle]="{
                          display: toggleMultiple2 == false ? 'none' : 'block'
                        }" *ngFor="
                          let destination of currentCountryName.split(',')
                        ">
                        <div class="left"></div>
                        <div class="right">
                          <strong>{{ destination }}</strong>
                        </div>
                      </div>
                    </div>

                    <div class="">
                      <div *ngIf="currentCountryName.split(',').length - 1 == 0">
                        <strong>{{
                          currentCountryName && currentCountryName != undefined
                          ? currentCountryName
                          : 'NA'
                          }}</strong>
                      </div>
                    </div>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="">Leaving On</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>

                  <span *ngIf="isLoadeing === 'No'">
                    {{
                    summaryChangePreviousData &&
                    summaryChangePreviousData.fromDate &&
                    summaryChangePreviousData.fromDate != undefined
                    ? (summaryChangePreviousData.fromDate| date: 'dd/MM/yyyy')
                    : 'NA'
                    }}
                  </span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">
                    {{
                    summaryChangeCurrentData &&
                    summaryChangeCurrentData.fromDate &&
                    summaryChangeCurrentData.fromDate != undefined
                    ? (summaryChangeCurrentData.fromDate| date: 'dd/MM/yyyy')
                    : 'NA'
                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="">Returning On</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">
                    {{
                    summaryChangePreviousData &&
                    summaryChangePreviousData.toDate &&
                    summaryChangePreviousData.toDate != undefined
                    ? (summaryChangePreviousData.toDate| date: 'dd/MM/yyyy')
                    : 'NA'
                    }}
                  </span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">
                    {{
                    summaryChangeCurrentData &&
                    summaryChangeCurrentData.toDate &&
                    summaryChangeCurrentData.toDate != undefined
                    ?(summaryChangeCurrentData.toDate | date: 'dd/MM/yyyy')
                    : 'NA'

                    }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="">Duration</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{ getNoOfDaysPrevious() }} Days</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{ getNoOfDaysCurrent() }} Days</span>
                </td>
              </tr>
              <tr>
                <td class="">Coverage Options</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangePreviousData?.productAlias
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangeCurrentData?.productAlias
                    }}</span>
                </td>
              </tr>
              <tr>
                <td class="">Travel Premium</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangePreviousData &&
                    summaryChangePreviousData.premiums &&
                    summaryChangePreviousData.premiums.product != undefined
                    ? toFixed(summaryChangePreviousData.premiums.product, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangeCurrentData &&
                    summaryChangeCurrentData.premiums &&
                    summaryChangeCurrentData.premiums.product != undefined
                    ? toFixed(summaryChangeCurrentData.premiums.product, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <tr *ngIf="
                  (maxTripLengthCurrentValue &&
                    maxTripLengthCurrentValue !== undefined) ||
                  (maxTripLengthPreviousDiscrip &&
                    maxTripLengthPreviousDiscrip !== undefined)
                ">
                <td class="">Max Trip Length</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    maxTripLengthPreviousValue &&
                    maxTripLengthPreviousValue != undefined
                    ? toFixed(maxTripLengthPreviousValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    maxTripLengthCurrentValue &&
                    maxTripLengthCurrentValue != undefined
                    ? toFixed(maxTripLengthCurrentValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <tr>
                <td class="">Excess</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangePreviousData &&
                    summaryChangePreviousData.premiums &&
                    summaryChangePreviousData.premiums.excess !== undefined
                    ? toFixed(summaryChangePreviousData.premiums.excess, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangeCurrentData &&
                    summaryChangeCurrentData.premiums &&
                    summaryChangeCurrentData.premiums.excess !== undefined
                    ? toFixed(summaryChangeCurrentData.premiums.excess, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <tr>
                <td class="">Cruise</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    cruisePreviousValue && cruisePreviousValue !== undefined
                    ? toFixed(cruisePreviousValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    cruiseCurrentValue && cruiseCurrentValue !== undefined
                    ? toFixed(cruiseCurrentValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <tr>
                <td class="">Skiing</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    skingPreviousValue && skingPreviousValue !== undefined
                    ? toFixed(skingPreviousValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    skingCurrentValue && skingCurrentValue !== undefined
                    ? toFixed(skingCurrentValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <tr>
                <td class="">Medical Premium</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangePreviousData &&
                    summaryChangePreviousData.premiums &&
                    summaryChangePreviousData.premiums != undefined &&
                    summaryChangePreviousData.premiums.medical &&
                    summaryChangePreviousData.premiums.medical != undefined
                    ? toFixed(summaryChangePreviousData.premiums.medical, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangeCurrentData &&
                    summaryChangeCurrentData.premiums &&
                    summaryChangeCurrentData.premiums != undefined &&
                    summaryChangeCurrentData.premiums.medical &&
                    summaryChangeCurrentData.premiums.medical != undefined
                    ? toFixed(summaryChangeCurrentData.premiums.medical, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <tr>
                <td class="">Membership Discount</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    memberShipDiscountPreviousValue &&
                    memberShipDiscountPreviousValue !== undefined
                    ? toFixed(memberShipDiscountPreviousValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    memberShipDiscountCurrentValue &&
                    memberShipDiscountCurrentValue !== undefined
                    ? toFixed(memberShipDiscountCurrentValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <tr>
                <td class="">Promo Code</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    promoCodeDiscountPreviousValue &&
                    promoCodeDiscountPreviousValue !== undefined
                    ? toFixed(promoCodeDiscountPreviousValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    promoCodeDiscountCurrentValue &&
                    promoCodeDiscountCurrentValue !== undefined
                    ? toFixed(promoCodeDiscountCurrentValue, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <!--<ng-template [ngIf]="summaryChangeCurrentData && summaryChangeCurrentData.MedicalConditions && summaryChangeCurrentData.MedicalConditions !== undefined">
                <tr>
                  <td class="">Medical Conditions</td>
                  <td class="">
                    {{(summaryChangePreviousData && summaryChangePreviousData.MedicalConditions !=='' ? summaryChangePreviousData.MedicalConditions : 'NA') }}
                  </td>
                  <td class="">
                    {{(summaryChangeCurrentData && (summaryChangeCurrentData.MedicalConditions !== undefined && summaryChangeCurrentData.MedicalConditions !=='') ? summaryChangeCurrentData.MedicalConditions : 'NA')}}
                  </td>
                </tr>
              </ng-template>-->
              <tr>
                <td class="">Stamp Duty</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangePreviousData &&
                    summaryChangePreviousData.premiums &&
                    summaryChangePreviousData.premiums != undefined &&
                    summaryChangePreviousData.premiums.stampDuty !== undefined
                    ? toFixed(summaryChangePreviousData.premiums.stampDuty, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangeCurrentData &&
                    summaryChangeCurrentData.premiums &&
                    summaryChangeCurrentData.premiums != undefined &&
                    summaryChangeCurrentData.premiums.stampDuty !== undefined
                    ? toFixed(summaryChangeCurrentData.premiums.stampDuty, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <tr>
                <td class="">GST</td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangePreviousData &&
                    summaryChangePreviousData.premiums &&
                    summaryChangePreviousData.premiums !== undefined &&
                    summaryChangePreviousData.premiums.gst !== undefined
                    ? toFixed(summaryChangePreviousData.premiums.gst, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangeCurrentData &&
                    summaryChangeCurrentData.premiums &&
                    summaryChangeCurrentData.premiums !== undefined &&
                    summaryChangeCurrentData.premiums.gst !== undefined
                    ? toFixed(summaryChangeCurrentData.premiums.gst, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>

              <tr>
                <td colspan="3" class="header-table">TRAVELLERS</td>
              </tr>
              <tr>
                <td class="">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span class="wrape-word" *ngIf="isLoadeing === 'No'">{{
                    summaryChangePreviousData &&
                    summaryChangePreviousData.PrimaryTravellerName !== undefined
                    ? summaryChangePreviousData.PrimaryTravellerName
                    : 'NA'
                    }}</span>
                </td>
                <td class="">NA</td>
                <td class="">NA</td>
              </tr>
              <tr *ngIf="
                  summaryChangeCurrentData &&
                  summaryChangeCurrentData.SecondaryTravellerName &&
                  summaryChangeCurrentData.SecondaryTravellerName !== undefined
                ">
                <td class="">
                  <span class="wrape-word" *ngIf="isLoadeing === 'No'">{{
                    summaryChangeCurrentData?.SecondaryTravellerName
                    }}</span>
                </td>
                <td class="">NA</td>
                <td class="">NA</td>
              </tr>
              <ng-container *ngIf="
                  summaryChangeCurrentData &&
                  summaryChangeCurrentData.DependentTravellers &&
                  summaryChangeCurrentData.DependentTravellers.length > 0
                ">
                <tr>
                  <td colspan="3" class="header-table">DEPENDENTS</td>
                </tr>
                <tr *ngFor="
                    let item of summaryChangeCurrentData.DependentTravellers
                  ">
                  <td class="">
                    <span class="wrape-word" *ngIf="isLoadeing === 'No'">{{
                      item.firstName + ' '+item.lastName
                      }}</span>
                  </td>
                  <td class="">NA</td>
                  <td class="">NA</td>
                </tr>
              </ng-container>

              <tr>
                <td class="font-md">Total</td>
                <td class="font-md">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangePreviousData &&
                    summaryChangePreviousData.premiums &&
                    summaryChangePreviousData.premiums!== undefined &&
                    summaryChangePreviousData.premiums.totalCost!== undefined
                    ? toFixed(summaryChangePreviousData.premiums.totalCost, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
                <td class="font-md">
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    summaryChangeCurrentData &&
                    summaryChangeCurrentData.premiums &&
                    summaryChangeCurrentData.premiums!== undefined &&
                    summaryChangeCurrentData.premiums.totalCost!== undefined
                    ? toFixed(summaryChangeCurrentData.premiums.totalCost, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
              <tr class="card-total">
                <td class="">Additional Amount</td>
                <td class=""></td>
                <td class="">
                  <!--{{(additionalWrittenPrem && additionalWrittenPrem !==undefined ? additionalWrittenPrem | currency}}-->
                  <span class="animated-background d-block" *ngIf="isLoadeing === 'Yes'"></span>
                  <span *ngIf="isLoadeing === 'No'">{{
                    additionalAmount && additionalAmount !== undefined
                    ? toFixed(additionalAmount, 2)
                    : toFixed(0, 2)
                    }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4">
        <div class="custom-control custom-checkbox mb-3">
          <form [formGroup]="summaryForm">
            <input type="checkbox" formControlName="checkCoi" class="custom-control-input" id="customCheck"
              name="example1" />
            <label class="custom-control-label" for="customCheck">Send a copy of COI to Email</label>
          </form>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 mt-4 text-center btn-footer">
        <ng-container *ngIf="isPendingRewrite">
          <button [disabled]="isDisabledDiscard" class="btn btn-secondary btn-full-sm"
            (click)="checkAndDiscardChanges()">
            Discard Changes
          </button>
        </ng-container>
        <ng-container *ngIf="isCheckRefundAdditional">
          <manage-refunds [paymentRefundsData]="refundData"></manage-refunds>
        </ng-container>
        <ng-container *ngIf="isCheckAdditional">
          <button class="btn btn-primary mr-3" type="button" (click)="checkCoi()">
            Continue to Payment
          </button>
        </ng-container>
        <ng-container *ngIf="isCheckIssueEndorsement">
          <button class="btn btn-primary mr-3" type="button" (click)="endorsementClose()">
            <span [ngClass]="{
                'spinner-border spinner-border-sm': isIssueEndorsementLoader
              }" role="status" aria-hidden="false"></span>
            Issue Endorsement
          </button>
        </ng-container>
      </div>
    </div>
  </div>
  <!-- <button [disabled]="isCheckAdditional" class="btn btn-primary mr-3" type="button" (click)= 'additionalPayment(); checkCoi()'>Continue to Payment</button> -->
</section>

<ng-template #discardAlertTemplate>
  <div *ngIf="isLoader">
    <div class="clearfix"></div>
    <div class="overlay-custom-container"></div>
    <div class="loader"></div>
  </div>
  <div class="" id="myModal-11">
    <div class="modal-body">
      <div class="col-md-5 mx-auto text-center">
        <div *ngIf="discardMessageSuccess" class="alert alert-success my-4">
          {{ discardMessageSuccess }}
        </div>
        <div *ngIf="discardMessageFail" class="alert alert-warning my-4 text-break">
          {{ discardMessageFail }}
        </div>
      </div>
    </div>
    <div class="text-center mb-4 mt-2 btn-footer">
      <button type="button" class="btn btn-primary btn-full-sm" (click)="closeAlertChildModal()">
        OK
      </button>
    </div>
  </div>
</ng-template>

<!-- Confirmation Box POP UP --->
<ng-template #warningBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModalConfirmation()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Error }} ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModalConfirmation()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>

<!-- Confirmation Box POP UP --->
<ng-template #confirmationBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-thumbs-up" aria-hidden="true"></i> Confirmation
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModalConfirmation()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{ modalData.Msg }} ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModalConfirmation()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>