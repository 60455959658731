<button class="btn btn-primary mr-3" type="button" (click)="initiateRefund()">
  Issue Endorsement
</button>
<!--manage Refunds Template popup-->
<ng-template #manageRefundsTemplate>
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">Manage Refunds</h4>
    <button type="button" class="close" data-dismiss="modal"
      (click)="closeModalConfirmation()">&times;</button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <table *ngIf="paymentRefundsData && paymentRefundsData.length > 0" id="datatableRefunds"
      class="table table-striped table-bordered table-dashboard" style="width: 100%">
      <thead>
        <tr>
          <th>Transactions</th>
          <th>Transactions Type</th>
          <th>Refund Amount</th>
          <!-- <th class="text-center"></th> -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of paymentRefundsData; let i = index;">
          <td>{{item.displayPaymentRefNumber}}</td>
          <td>
            {{item.method=="CC"?'Credit
            Card':item.method=="CP"?'Credit Card':'Club Payment'}}
          </td>
          <td>{{formatprice(item.amount)}}</td>
          <!-- <td>
            <button
              *ngIf="item.method=='CC' ||  item.method=='PC';"
              type="button"
              class="btn btn-primary btn-sm"
              (click)="confirmRefundPay(item, i)"
              [disabled]="getDisabledStatus(i)"
            >
              {{setRefundText(item,i)}}
            </button>
            <ng-template #abc>
              <span class="text-success">{{item.PaymentStatus}}</span>
            </ng-template>
          </td> -->
        </tr>
      </tbody>
    </table>
    <div class="card-body"
      *ngIf="paymentRefundsData && paymentRefundsData.PaymentData && paymentRefundsData.PaymentData.length==0">
      No data avilable
    </div>
    <div class="text-center mb-4 mt-2 btn-footer">
      <div *ngIf="cancelCashRefundMessageEror && !CancelAllPolicyMessage">
        <h6 class="alert-danger alert">{{cancelCashRefundMessageEror}}</h6>
      </div>
      <div *ngIf="CancelAllPolicyMessage">
        <h6 class="alert-success alert">{{CancelAllPolicyMessage}}</h6>
      </div>
      <div *ngIf="cashReciptError">
        <h6 class="alert-danger alert">{{cashReciptError}}</h6>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary btn-full-sm" (click)="refundAllConfirmation()" data-toggle="modal"
      [disabled]="isRefundClicked" data-target="#myModal-15">
      <span *ngIf="isRefundAllClicked" class="spinner-border text-primary" style="width: 1rem; height: 1rem;"
        role="status" aria-hidden="false"></span>
      <span *ngIf="!isRefundAllClicked">{{refundAllText}}</span>
    </button>
  </div>
</ng-template>

<!--refund CashPayment RefundcontentTemplate Refund poup-->
<ng-template #cancelCashPaymentRefundcontentTemplate>
  <div class="" id="myModal-13">
    <form [formGroup]="cancelCashPaymentForm" (ngSubmit)="cashItemRefund()">
      <div class="modal-body">
        <div class="col-md-8 mx-auto text-center">
          <div class="text-center mb-4 mt-4">
            <h5 class="mb-4">Cash</h5>
          </div>
          <div class="form-group mx-auto col-8">
            <div class="input-group date-field nopadding required">
              <input type="text" class="form-control input-sm col-12" placeholder="Receipt Number"
                formControlName="ReceiptNumber" />
            </div>
          </div>
          <div *ngIf="cancelCashRefundMessageEror">
            {{cancelCashRefundMessageEror}}
          </div>
        </div>
      </div>
      <div class="text-center mb-4 mt-2 btn-footer">
        <button type="button" class="btn btn-secondary btn-full-sm" (click)="closeCashRefundModel()">
          Close
        </button>
        <button type="submit" class="btn btn-primary btn-full-sm" [disabled]="!cancelCashPaymentForm.valid"
          data-toggle="modal" data-target="#myModal-13">
          Continue
        </button>
      </div>
    </form>
  </div>
</ng-template>

<!-- warningBox Box POP UP --->
<ng-template #warningBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Warning
    </h4>
    <button type="button" class="close" data-dismiss="modal" (click)="closeModal()">
      &times;
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{modalData.Error}} ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModal()" data-dismiss="modal">
      Close
    </button>
  </div>
</ng-template>

<!-- Confirmation Box POP UP --->
<ng-template #confirmationBox let-modal let-c="close" let-d="dismiss" data-backdrop="static" data-keyboard="false">
  <!-- Modal Header -->
  <div class="modal-header">
    <h4 class="modal-title">
      <i class="fa fa-thumbs-up" aria-hidden="true"></i> Confirmation
    </h4>
    <button type="button" class="close" data-dismiss="modal">&times;</button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div>{{modalData.Msg}} ...</div>
      </div>
    </div>
  </div>

  <!-- Modal footer -->
  <!-- <div class="modal-footer" style="justify-content: flex-end">
    <button type="submit" class="btn btn-primary pull-right" (click)="closeModalConfirmation()" data-dismiss="modal">
      Close
    </button>
  </div> -->
</ng-template>