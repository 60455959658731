import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonParams } from '../core/commonparams';
import { EndPoint } from '../core/services/endpoint';
import { SearchBranch } from '../core/model/app.model';

@Injectable({
  providedIn: 'root'
})
export class BranchesService {

  constructor(private http: HttpClient) { }
  httpHeaders = {
    headers: new HttpHeaders({
    }),
  };

  // getBranches(req?: SearchBranch) {
  //   if (req != undefined) {
  //     req.loginAgentGuid = UserRoleDetails.AgentContactId
  //   }
  //   return this.http.post<any>(
  //     `${EndPoint.LTX_ENDPOINT}api/pas/branch/search`, req, this.httpHeaders,
  //   );
  // }
  getBranchDetail(agentContactId: any, autoClubGuid?: any) {

    return this.http.get<any>(
      `${EndPoint.LTX_ENDPOINT + EndPoint.getBranchDetail}${agentContactId}&autoClubGuid=${autoClubGuid}`, this.httpHeaders,
    );
  }
}
