import { Injectable } from '@angular/core';
import { HttpCoreService } from '../../../../../src/app/core/services/http-core.service';
import { Utility } from '../shared/utility';
import { AppConfig } from '../shared/config/app.config';
import { CommonParams } from 'src/app/core/commonparams';
import { environment } from 'src/environments/environment';
import { PortalCodeService } from 'src/app/core/portalcode/portalcode';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SitecoreItemService {
  public baseUrl!: string;
  public siteName!: string;
  constructor(
    private httpService: HttpCoreService,
    private config: AppConfig,
    private portalCodeService: PortalCodeService,
  ) {}

  // getCommonMedicalValueFromSiteCore() {
  //   let url = `${environment.sitecoreBaseAddress}/GTPItemService/GetItemData?itemId=${this.getItemServiceID(
  //     'StepThreeAPICall',
  //   )}/children/?includeStandardTemplateFields=false&fields=ItemId,ItemName,Key,Phrase`;
  //   return this.httpService.getRequest(url, null, '');
  // }

  // //Called
  // getItemServiceForGenericSettings() {
  //   let url = `${environment.sitecoreBaseAddress}/GTPItemService/GetItemData?itemId=${this.getItemServiceID('GenericSettings')}`;
  //   return this.httpService.getRequest(url, null, '');
  // }
  // // for medical score visible or not
  // getMedicalScoreSettings() {
  //   let url = `${environment.sitecoreBaseAddress}/GTPItemService/GetItemData?itemId=${this.getItemServiceID('PASURL')}`;
  //   return this.httpService.getRequest(url, null, '');
  // }

  // //Called
  // getTripTypeLebeling() {
  //   let url = `${environment.sitecoreBaseAddress}/GTPItemService/GetItemData?itemId=${this.getItemServiceID('TripTypeLebeling')}`;
  //   return this.httpService.getRequest(url, null, '');
  // }

  // //Called
  // getThemeColorSetting() {
  //   let url = `${environment.sitecoreBaseAddress}/GTPItemService/GetItemData?itemId=${this.getItemServiceID('ThemeColor')}`;
  //   return this.httpService.getRequest(url, null, '');
  // }

  // //Called
  // //getStep2CommonSetting() {
  // //  let url = `${environment.sitecoreBaseAddress}/GTPItemService/GetItemData?itemId=${this.getItemServiceID("Step2CommonSetting")}`;
  // //  return this.httpService.getRequest(url, null, null);
  // //}

  // //Called
  // getDefaultSettings() {
  //   let url = `${environment.sitecoreBaseAddress}/GTPItemService/GetItemData?itemId=${this.getItemServiceID('CoverageDefaultSettings')}`;
  //   return this.httpService.getRequest(url, null, '');
  // }
  // //Called
  // getDataByItemId(itemId: any) {
  //   let url = `${environment.sitecoreBaseAddress}/GTPItemService/GetItemData?itemId=${itemId}`;
  //   return this.httpService.getRequest(url, null, '');
  // }

  // getStatesFromSiteCoreNew() {
  //   let url = `${environment.sitecoreBaseAddress}/GTPItemService/GetItemData?itemId=${this.getItemServiceID(
  //     'StateList',
  //   )}/children/?includeStandardTemplateFields=false&fields=ItemId,ItemName,Key,Phrase`;
  //   return this.httpService.getRequest(url, null, '');
  // }

  // getItemServiceID(itemName: any) {
  //   let _itemId = '';
  //   let baseUrl = Utility.getBaseUrl();
  //   //console.log("Get Item Base Url: " + baseUrl);
  //   try {
  //     this.config.getConfig('sitecore_services').forEach((element: any) => {
  //       if (this.siteName == element.name) {
  //         element.item_services.forEach((subElement: any) => {
  //           if (subElement.Name == itemName) {
  //             _itemId = subElement.ItemID;
  //             throw 'break-inner-foreach'; //ugly but will reduce unneccesary looping.
  //           }
  //         });
  //       }
  //       //throw "break-foreach";
  //     });
  //   } catch (e) {}
  //   //console.log("Item Id: " + _itemId);
  //   return _itemId;
  // }

  // getDataByScItemService(itemIdData: any) {
  //   const url = `${environment.sitecoreBaseAddress}/GTPItemService/GetScItem`;
  //   return this.httpService.postRequest(url, itemIdData);
  // }

  // getPandemicDataByScItemService(itemId: any) {
  //   const itemIdData = {
  //     step: 'pandemicData' + itemId,
  //     data: [
  //       {
  //         Name: 'Pandemic',
  //         ItemId: itemId,
  //       },
  //     ],
  //   };
  //   const url = `${environment.sitecoreBaseAddress}/GTPItemService/GetScItem`;
  //   return this.httpService.postRequest(url, itemIdData);
  // }

  // getLabellingDataByScItemService(itemId: any) {
  //   const itemIdData = {
  //     step: 'LabellingData' + itemId,
  //     data: [
  //       {
  //         Name: 'StepThreeAPICall',
  //         ItemId: itemId,
  //         IsChildren: true,
  //       },
  //     ],
  //   };
  //   const url = `${environment.sitecoreBaseAddress}/GTPItemService/GetScItem`;
  //   return this.httpService.postRequest(url, itemIdData);
  // }
}
