import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
//import {FeedPolicySummaryListData} from './policy-summary'
//import { Utility } from 'src/app/utility/utility';
//import { CommonParams } from 'src/app/core/commonparams';
import { EndPoint } from 'src/app/core/services/endpoint';
import { HttpCoreService } from 'src/app/core/services/http-core.service';
//import { HttpCoreService } from 'src/app/core/services/http-core.service';
//import { HttpCoreService } from 'src/app/core/http-services/http-core.service';
import { SetQueryParamsService } from 'src/app/core/role/setqueryparams';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  policyTxnId!: string;
  private _apiUrl!: string;
  RESEND_COI: any = '10719';

  constructor(
    private http: HttpClient,
    private httpService: HttpCoreService,
    private setQueryParamsService: SetQueryParamsService,
  ) { }
  httpHeaders = {
    headers: new HttpHeaders({
    }),
  };

  public differenceInDays(firstDate: any, secondDate: any) {
    let dateDiff =
      new Date(secondDate).getTime() - new Date(firstDate).getTime();
    return Math.round(dateDiff / (1000 * 60 * 60 * 24));
  }

  // getPolicyCompairDataOld(id: any): Observable<any> {
  //   return this.httpService
  //     .getRequest(
  //       `${EndPoint.OCTAAPI_ENDPOINT}GetSummaryOfChangeGTA/${id}`,
  //       this.httpHeaders,
  //     )
  //     .pipe(catchError((e) => throwError(e)));
  // }

  // getPolicyCompairData(id: any) {
  //   return this.httpService.getRequest(
  //     EndPoint.OCTAAPI_ENDPOINT + 'GetSummaryOfChangeGTA/' + id,
  //   );
  // }

  resendCoi(movementGuid: any): Observable<any> {
    return this.httpService
      .getRequest(`${EndPoint.LTX_ENDPOINT}${EndPoint.ResendCOI}${movementGuid}`)
      .pipe(catchError((e) => throwError(e)));
  }

  // getCountryData(CountryCode: string): Observable<any> {
  //   return this.httpService
  //     .getRequest(`${EndPoint.OCTAAPI_ENDPOINT}GetCountryList`)
  //     .pipe(catchError((e) => throwError(e)));
  // }

  // issueEndorsementGTA(objData: object): Observable<any> {
  //   return this.httpService
  //     .getRequest(`${EndPoint.OCTAAPI_ENDPOINT}IssueEndorsementGTA`, objData)
  //     .pipe(catchError((e) => throwError(e)));
  // }
  // discardChange(params: any): Observable<any> {
  //   return this.httpService.putRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}DeleteTransaction`,
  //     params,
  //   );
  // }

  // getPolicyDetailbyId(policyTxnId: any) {
  //   return this.http.get<any>(
  //     EndPoint.OCTAAPI_ENDPOINT + 'GetPolicy/' + policyTxnId.toString(),
  //     this.httpHeaders,
  //   );
  // }

  // GetGTARefundDetails(postData: any) {
  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}GetGTARefundDetails`,
  //     postData,
  //   );
  // }

  // savePaymentRecieptRewriteGTARequest(policyTxnId: any): Observable<any> {
  //   let issueRewriteGTA = new IssueRewriteGTA();
  //   issueRewriteGTA.PolicyTxnId = policyTxnId.toString();
  //   issueRewriteGTA.FormSetId = '10728';
  //   issueRewriteGTA.CreateActivityLogItem = {
  //     PolicyTxnId: policyTxnId.toString(),
  //     ActivityType: 'issueRewriteGTA',
  //     ActivityDate: CommonParams.ActivityDate,
  //     Description: 'issueRewriteGTA',
  //     LoggedInUser: UserProfile.LoggedUser,
  //   };

  //   issueRewriteGTA.PaymentData = [];

  //   return this.http.put<any>(
  //     EndPoint.OCTAAPI_ENDPOINT + 'IssueRewriteGTA',
  //     issueRewriteGTA,
  //     this.httpHeaders,
  //   );
  // }

  savePaymentRecieptRequest(policyEndorsemrnt: any): Observable<any> {
    return this.http.post<any>(
      EndPoint.LTX_ENDPOINT + EndPoint.BindEndorsment,
      policyEndorsemrnt,
      this.httpHeaders,
    );
  }

  // saveRefundPaymentRecieptRequest(
  //   issueEndorsementGTA: IssueEndorsementGTA,
  // ): Observable<any> {
  //   let agentDetailsObj =
  //     this.setQueryParamsService.getAgentDetailsForAPIRequest();
  //   issueEndorsementGTA.CreateActivityLogItem = {
  //     PolicyTxnId: issueEndorsementGTA.PolicyTxnId.toString(),
  //     ActivityType: 'issueEndorsementGTANonFinancial',
  //     ActivityDate: CommonParams.ActivityDate,
  //     Description: 'issueEndorsementGTANonFinancial',
  //     LoggedInUser: UserProfile.LoggedUser,
  //   };
  //   issueEndorsementGTA.AgentDetails = agentDetailsObj.AgentDetails;

  //   return this.http.put<any>(
  //     EndPoint.OCTAAPI_ENDPOINT + 'IssueEndorsementGTA',
  //     issueEndorsementGTA,
  //     this.httpHeaders,
  //   );
  // }

  // callEndorsementOrRewrite(
  //   issueEndorsementGTAObj: any,
  //   isRewrite: any,
  // ): Observable<any> {
  //   if (isRewrite) {
  //     return this.savePaymentRecieptRewriteGTARequest(
  //       issueEndorsementGTAObj.PolicyTxnId.toString(),
  //     );
  //   } else {
  //     return this.saveRefundPaymentRecieptRequest(issueEndorsementGTAObj);
  //   }
  // }

  // closePaybleGTA(postData: any) {
  //   return this.httpService.postRequest(
  //     `${EndPoint.OCTAAPI_ENDPOINT}ClosePaybleGTA`,
  //     postData,
  //   );
  // }

  // getPolicyStatus(policyId: string, policyTxnNum: Number) {
  //   return this.http.get<any>(
  //     EndPoint.OCTAAPI_ENDPOINT +
  //     'GetPolicyStatus/' +
  //     policyId +
  //     '/' +
  //     policyTxnNum,
  //     this.httpHeaders,
  //   );
  // }
}

//1890249
//1900849
